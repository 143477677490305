import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  loaderContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loader: {
    ...theme.spinnerAnimation,
    color: theme.palette.primary.main,
    fontSize: 40,
    height: 44,
    padding: 2
  }
}));
