import useKeys from '@flowsn4ke/usekeys';
import Dialog from 'components/Dialog';
import { useAuth } from 'hooks/useAuth';
import useNotifications from 'hooks/useNotifications';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dateToLocalFormat } from 'utils/dates';
import VersionSelector from '../VersionSelector';
import getInitials from 'utils/getInitials';
import { can_preview_file } from '../utils';
import useFileViewer from 'hooks/useFileViewer';

export default function DocumentInfo({ document, setDocument, setDocumentToEdit, getRevisions }) {
  const { t } = useTranslation();
  const auth = useAuth();
  const notify = useNotifications();
  const [isLoadingRevisions, setIsLoadingRevisions] = useState(false);
  const [revisions, setRevisions] = useState(null);

  const user_has_owner_role = document?.owner_ids?.includes(auth?.interface?._role?._id);
  const user_has_uploaded_the_document = auth.user._id === document?.created_by?._id;
  const can_edit_and_delete = user_has_owner_role || user_has_uploaded_the_document;

  const { openFiles } = useFileViewer();

  const company_id = auth?.interface?._company?._id;

  useEffect(() => {
    if (document && !revisions) {
      setIsLoadingRevisions(true);
      getRevisions({ document_id: document._id })
        .then(({ data }) => {
          const versions = [document, ...data.revisions].sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          );
          setRevisions(versions);
          setIsLoadingRevisions(false);
        })
        .catch(() => {
          notify.error(t('couldNotLoadDocumentRevisions'));
          setIsLoadingRevisions(false);
        });
    }
  }, [document]);

  const show_uploaded_by = String(document?.company_id) === String(company_id);

  return document ? (
    <>
      <Dialog
        className="z-[1300]"
        icon="circle-info"
        isOpen={!!document}
        title={t('informations')}
        titleRight={
          <VersionSelector
            isLoading={isLoadingRevisions}
            revisions={revisions}
            disabled={!revisions?.length}
            document={document}
            setDocument={setDocument}
          />
        }
        width="lg"
        onClose={() => [setDocument(null), setRevisions(null)]}
      >
        <div className="mt-2 flex flex-col">
          <div className="flex flex-col mt-4 mb-6 space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">{t('fileName')}</label>
              <p className="text-base">{document.name || '-'}</p>
            </div>
            <DateInfo
              date={document.deadline}
              label="dueDateLabel"
            />
            <Description description={document.description} />
            {Array.isArray(document.tags) && (
              <TagCloud
                label="tagsTitle"
                tags={document.tags || []}
              />
            )}
            {Array.isArray(document.metadata?.locations) && (
              <TagCloud
                label="locations"
                tags={document.metadata.locations || []}
              />
            )}
            {Array.isArray(document.metadata?.equipments) && (
              <TagCloud
                label="equipmentsTitle"
                tags={document.metadata.equipments || []}
              />
            )}
            {Array.isArray(document.metadata?.preventifs) && (
              <TagCloud
                label="maintenanceLabel"
                tags={document.metadata.preventifs || []}
              />
            )}
            {Array.isArray(document.metadata?.companies) &&
              document.metadata?.companies?.length > 0 && (
                <TagCloud
                  label="societiesLabel"
                  tags={document.metadata.companies}
                />
              )}

            <ShareList
              document={document}
              company_id={company_id}
            />
            <div className="italic font-normal text-sm">
              {t('loadedOn')} {dateToLocalFormat(document.created_at, 'PPPPp')}
              {show_uploaded_by && (
                <span className="ml-1">
                  {document?.created_by?.name ? t('by') + ' ' + document?.created_by?.name : 'n/c'}
                </span>
              )}
            </div>
          </div>
          <div className="flex justify-end">
            {can_preview_file(document) && (
              <button
                onClick={() => openFiles([document])}
                className="disabled:opacity-50 mr-2 text-sky-900 border border-sky-900 px-3 py-2 rounded-full"
              >
                {t('openVerb')}
              </button>
            )}
            {can_edit_and_delete && !Boolean(document.revision_for) && (
              <button
                onClick={() => setDocumentToEdit(document)}
                className="bg-sky-900 disabled:opacity-50 text-white px-3 py-2 rounded-full"
              >
                {t('edit')}
              </button>
            )}
          </div>
        </div>
      </Dialog>
    </>
  ) : null;
}

function Description({ description }) {
  const { t } = useTranslation();

  return description ? (
    <div>
      <label className="block text-sm font-medium text-gray-700">{t('description')}</label>
      <p className="text-base">{description}</p>
    </div>
  ) : null;
}

function DateInfo({ date, label }) {
  const { t } = useTranslation();

  return date ? (
    <div>
      <label className="block text-sm font-medium text-gray-700">{t(label)}</label>
      <div className="mt-1 text-base">{dateToLocalFormat(date)}</div>
    </div>
  ) : null;
}

function ShareList({ document, company_id }) {
  const share_list = document.metadata.shared_with || [];
  const connected_idx = share_list?.findIndex((c) => c.company_id === company_id);

  const prev = connected_idx > 0 ? share_list[connected_idx - 1] : null;
  const next = connected_idx < share_list.length - 1 ? share_list[connected_idx + 1] : null;

  return (
    <div className="flex flex-col">
      {prev && (
        <ShareItem
          company={prev}
          connected_company_id={company_id}
          document_company_id={document.company_id}
        />
      )}
      {next && (
        <ShareItem
          company={next}
          connected_company_id={company_id}
          document_company_id={document.company_id}
        />
      )}
    </div>
  );
}

function ShareItem({ company, document_company_id, connected_company_id }) {
  const { t } = useTranslation();

  const label = document_company_id !== connected_company_id ? t('sharedBy') : t('sharedWith');

  return (
    <div>
      <p className="text-sm font-medium">{label}</p>
      <div className="flex items-center">
        {company.company_avatar ? (
          <>
            <img
              className="mr-2 h-8 w-8 rounded"
              src={company.company_avatar}
              alt={company.company_name}
            />
          </>
        ) : (
          <>
            <span className="inline-flex mr-2 h-8 w-8 items-center justify-center rounded-full bg-gray-500">
              <span className="text-xs font-medium leading-none text-white">
                {getInitials(company.company_name)}
              </span>
            </span>
          </>
        )}
        <div>
          <p className="text-sm font-normal">{company.company_name}</p>
          <p>{company.company_email}</p>
        </div>
      </div>
    </div>
  );
}

export function TagCloud({ label, tags = [] }) {
  const { t } = useTranslation();
  const k = useKeys();
  tags = tags.filter((tag) => tag.name || tag.label);

  return tags.length ? (
    <div>
      <label className="block text-sm font-medium text-gray-700">{t(label)}</label>
      <div className="flex flex-wrap w-full">
        {tags.map((tag, i) => (
          <span
            key={k(i)}
            className="bg-slate-200 text-slate-600 w-max text-md py-1 px-3 rounded-full flex items-center text-center h-max mr-2 my-1"
          >
            {tag.color && (
              <span
                className="w-2 h-2 rounded-full mr-2"
                style={{ backgroundColor: tag.color }}
              />
            )}
            {tag.name || tag.label}
          </span>
        ))}
      </div>
    </div>
  ) : null;
}
