import { createSlice } from '@reduxjs/toolkit';
import { generateEntityBoilerplate } from 'utils/generateEntitySlice';
import generateAsyncThunk from 'utils/generateAsyncThunk';

export const createElement = generateAsyncThunk({ type: 'POST', endpoint: 'locations' });
export const updateElement = generateAsyncThunk({ type: 'PUT', endpoint: 'locations/${id}' });
export const getElement = generateAsyncThunk({ type: 'GET', endpoint: 'locations/${id}' });
export const getElements = generateAsyncThunk({ type: 'POST', endpoint: 'locations/list' });
export const getPublicElements = generateAsyncThunk({
  type: 'POST',
  endpoint: 'locations/publicList'
});
export const deleteElement = generateAsyncThunk({ type: 'DELETE', endpoint: 'locations/${id}' });
export const restoreElement = generateAsyncThunk({
  type: 'POST',
  endpoint: 'locations/recycle/${id}'
});
export const uploadCover = generateAsyncThunk({ type: 'FILE', endpoint: 'image' });

const entityBoilerplate = generateEntityBoilerplate({
  createElement,
  updateElement,
  getElement,
  getElements,
  getPublicElements,
  deleteElement,
  restoreElement,
  createElementNext: (state, action) => {
    const { element } = action.payload.data;
    const { parentLocation } = action.meta.arg;

    if (parentLocation && state.db[parentLocation]) {
      state.db[parentLocation]._locations.push(element._id);
    }
  },
  updateElementNext: (state, action) => {
    const { element } = action.payload.data;

    if (element._locations.length > 0) {
      const recursive_update_address = (nested_locations) => {
        if (nested_locations?.length > 0) {
          nested_locations.forEach((loc) => {
            state.db[loc._id] = {
              ...state.db[loc._id],
              address: element?.address
            };
          });

          recursive_update_address(nested_locations._locations);
        }
      };

      recursive_update_address(element._locations);
    }
  },
  deleteElementNext: (state, action) => {
    const { element } = action.payload.data;
    const { parentLocation } = action.meta.arg;

    if (parentLocation && state.db[parentLocation]) {
      state.db[parentLocation]._locations = state.db[parentLocation]._locations.filter((l) => l !== element._id);
    }
  }
});

const { initialState, reducers, extraReducers } = entityBoilerplate;

export const locationsSlice = createSlice({
  name: 'locations',
  initialState: { ...initialState },
  reducers: {
    ...reducers
  },
  extraReducers: {
    ...extraReducers
  }
});

export const { flushElements, flushElement } = locationsSlice.actions;
export default locationsSlice.reducer;
