import {
  Box,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText
} from '@material-ui/core';
import { FileCopy } from '@material-ui/icons';
import clsx from 'clsx';
import FAIcon from 'components/ui/FAIcon';
import useClipboard from 'hooks/useClipboard';
import { useIsBelowMd } from 'hooks/useMQ';
import useStyles from 'layouts/entities/View.styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'utils/classNames';

export default function ViewItem({
  icon = false,
  iconAction = false,
  collection = false,
  secondaryIcon = false,
  primary = '',
  secondary = '',
  className,
  actions = false,
  copy: copyOption,
  ...rest
}) {
  const c = useStyles();
  const isBelowMd = useIsBelowMd();
  const [isHover, setIsHover] = useState(false);
  const { copy, copied } = useClipboard();
  const { t } = useTranslation();

  return (
    <ListItem
      className={classNames(c.listItem, className)}
      dense
      onMouseEnter={(e) => setIsHover(true)}
      onMouseLeave={(e) => setIsHover(false)}
      {...rest}
    >
      <ListItemIcon>
        {icon && (
          <FAIcon
            icon={icon}
            collection={collection || (secondaryIcon ? 'fas' : 'fal')}
            className={clsx(c.listIcon, {
              [c.listIconSecondary]: secondaryIcon
            })}
          />
        )}
        {!!iconAction && iconAction}
      </ListItemIcon>
      <ListItemText
        primary={!primary ? null : <strong>{primary}</strong>}
        secondary={
          <Box
            component="span"
            display="flex"
            alignItems="center"
          >
            {secondary}
            {copyOption && isHover && !isBelowMd && (
              <span
                style={{
                  display: 'inline-flex',
                  height: 0,
                  alignItems: 'center'
                }}
              >
                <IconButton
                  style={{ marginLeft: 6 }}
                  size={'small'}
                  onClick={() => copy(secondary)}
                >
                  <FileCopy style={{ fontSize: 13, width: 13, height: 13 }} />
                </IconButton>
                {copied && (
                  <strong
                    style={{
                      color: '#27ae60',
                      fontSize: 11,
                      marginLeft: 6,
                      position: 'relative',
                      top: 2
                    }}
                  >
                    {t('copiedToClipBoard')}
                  </strong>
                )}
              </span>
            )}
          </Box>
        }
      />
      {!!actions && <ListItemSecondaryAction>{actions}</ListItemSecondaryAction>}
    </ListItem>
  );
}
