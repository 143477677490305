import { Button, TextField } from '@material-ui/core';
import DateField from 'components/inputs/DateField';
import { Field, Form, Reset, Submit } from 'frmx';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import useNotifications from 'hooks/useNotifications';
import { useTranslation } from 'react-i18next';
import { add_reading } from 'store/metersSlice';

export default function AddReadingForm({ meter_id, last_reading, onClose }) {
  const { t } = useTranslation();

  const { dispatch, requestStatus } = useAsyncDispatch();

  const notify = useNotifications();
  const dispatchCallbacks = {
    onSuccess: () => notify.success(t('readingAddedSuccessfully')),
    onError: () => notify.error()
  };

  const handleSubmit = (data) => {
    dispatch(add_reading, data, dispatchCallbacks, { id: meter_id }).then(() => {
      onClose();
    });
  };

  return (
    <>
      <Form
        initialValues={{
          value: undefined,
          date: new Date()
        }}
        schemaValidation={{
          value: (value) => !!value?.length && value >= 0
        }}
        disableIfInvalid
        onSubmit={(data) => handleSubmit(data)}
        clearAfterSubmit={true}
      >
        <label className="my-2 block text-sm font-medium text-gray-700">
          {t('valueOfReading')}
        </label>
        <Field
          path="value"
          type="number"
          isErrorProp="error"
        >
          <TextField
            className="w-full"
            variant="outlined"
            type="number"
            inputProps={{
              min: last_reading?.value || 0
            }}
          />
        </Field>
        <div className="mt-4">
          <label className="mb-2 block text-sm font-medium text-gray-700">
            {t('dateOfReading')}
          </label>
          <DateField
            disableFuture
            clearable={false}
            path="date"
          />
        </div>
        <div className="flex justify-end items-center mt-3">
          <Reset onClick={onClose}>
            <Button
              variant="contained"
              color="secondary"
              size="small"
            >
              {t('cancel')}
            </Button>
          </Reset>
          <div className="ml-2">
            <Submit disabled={requestStatus === 'loading'}>
              <Button
                variant="contained"
                color="primary"
                size="small"
              >
                {t('add')}
              </Button>
            </Submit>
          </div>
        </div>
      </Form>
    </>
  );
}
