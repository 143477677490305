import { Box, Tooltip } from '@material-ui/core';
import FAIcon from 'components/ui/FAIcon';
import useStyles from 'layouts/entities/Preview.styles';
import { useTranslation } from 'react-i18next';

export default function InterfaceIndicator({ _user }) {
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <>
      {
        <Box className={classes.indicator}>
          {!_user && (
            <Tooltip
              placement={'right'}
              title={t('withoutABobDeskAccount')}
            >
              <Box>
                <FAIcon
                  collection="fas"
                  icon={'circle'}
                  className={classes.indicatorOFF}
                />
              </Box>
            </Tooltip>
          )}
          {_user && !_user?.last_connect && (
            <Tooltip
              placement={'right'}
              title={t('pendingBobDeskAccount')}
            >
              <Box>
                <FAIcon
                  collection="fas"
                  icon={'circle'}
                  className={classes.indicatorIDLE}
                />
              </Box>
            </Tooltip>
          )}
          {_user && !!_user?.last_connect && (
            <Tooltip
              placement={'right'}
              title={t('activeBobDeskAccount')}
            >
              <Box>
                <FAIcon
                  collection="fas"
                  icon={'circle'}
                  className={classes.indicatorON}
                />
              </Box>
            </Tooltip>
          )}
        </Box>
      }
    </>
  );
}
