import { format, set } from 'date-fns';
import { useUI } from 'hooks/useUI';
import { nanoid } from 'nanoid';
import {
  DAYS_HEADER_HEIGHT,
  HOUR_HEIGHT,
  HOURS_HEIGHT_OFFSET,
  HOURS_COLUMN_WIDTH,
  HOURS_LEFT_SPACING,
  HALF_HOURS_IN_A_DAY,
  HOURS_IN_A_DAY,
  FULL_DAY_HEIGHT,
  HALF_HOUR_HEIGHT
} from '../config';

const random_day = new Date();

export default function WeekGrid({ numberOfDaysToDisplay, dayWidth }) {
  const ui = useUI();
  const locale = ui.get_date_time_locale();

  return (
    <div className="flex divide-x">
      <div style={{ width: HOURS_COLUMN_WIDTH }}>
        {/* HOURS COLUMNS */}
        {new Array(HOURS_IN_A_DAY).fill(null).map((_, i) => {
          const hour = set(random_day, { hours: i, minutes: 0, seconds: 0, milliseconds: 0 });
          const text = format(hour, 'p', { locale });
          return i < 1 ? null : (
            <div
              key={nanoid()}
              style={{
                position: 'absolute',
                top: DAYS_HEADER_HEIGHT + i * HOUR_HEIGHT - HOURS_HEIGHT_OFFSET,
                left: HOURS_LEFT_SPACING
              }}
              className="text-stone-400"
            >
              {text}
            </div>
          );
        })}
      </div>
      {/* DAYS COLUMNS */}
      {new Array(numberOfDaysToDisplay).fill(null).map((_, day) => {
        return (
          <div
            key={nanoid()}
            className="divide-y"
            style={{ height: FULL_DAY_HEIGHT, width: dayWidth }}
          >
            {new Array(HALF_HOURS_IN_A_DAY).fill(null).map(() => {
              return (
                <div
                  key={nanoid()}
                  style={{ height: HALF_HOUR_HEIGHT }}
                />
              );
            })}
          </div>
        );
      })}
    </div>
  );
}
