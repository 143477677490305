import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: ({ inline }) => ({
    background: inline ? 'transparent' : 'white',
    zIndex: 1,
    display: inline ? 'inline-flex' : 'flex',
    alignItems: 'center',
    padding: inline ? '0px 0px' : '6px 16px',
    width: inline ? 'auto' : '100%',
    borderBottom: inline ? 'none' : '1px solid #eaeaea',
    ['& .MuiBreadcrumbs-separator']: {
      marginLeft: inline ? 0 : '8px',
      marginRight: inline ? 0 : '8px'
    }
  }),
  breadcrumb: {},
  icon: {
    color: '#bfc6ce',
    marginRight: 13
  }
}));
