import { FormHelperText } from '@material-ui/core';
import { useField } from 'frmx';
import { useTranslation } from 'react-i18next';
import { DateTimePicker } from '@material-ui/pickers';

export default function CustomDateTime({ customField, path, required }) {
  const { label } = customField;
  const { value, setValue, error, disabled } = useField(path);
  const { t } = useTranslation();

  return (
    <>
      <DateTimePicker
        value={value ? new Date(value) : null}
        onChange={(d) => setValue(d)}
        inputVariant="outlined"
        label={required ? t(label) + ' *' : t(label)}
        invalidLabel=""
        ampm={false}
        clearable
        invalidDateMessage={<></>}
        style={{ width: '100%' }}
        format="PPPp"
        disabled={disabled}
      />
      <FormHelperText style={{ color: 'red' }}>
        {error ? t('pleaseFillTheField') : ''}
      </FormHelperText>
    </>
  );
}
