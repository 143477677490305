import {
  IconButton,
  MenuItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Button,
  Typography,
  Box,
  makeStyles
} from '@material-ui/core';

import { EditOutlined, RadioButtonUnchecked, CheckCircle, Add } from '@material-ui/icons';

import useKeys from '@flowsn4ke/usekeys';
import FAIcon from 'components/ui/FAIcon';
import { useTranslation } from 'react-i18next';
import { useEntity } from 'contexts/entities/entityContext';

export default function FilterSetList({
  classes,
  setManageFilterBar,
  setAppliedFilters,
  buildFilters,
  activeIndex,
  closeFilterMenu,
  isLoading,
  sets,
  newSet,
  setSets,
  setSet,
  setsKey
}) {
  const { tab } = useEntity();
  const { t } = useTranslation();
  const k = useKeys();

  return (
    <Box>
      {sets?.map((set, i) => {
        return (
          <Box key={k(i)}>
            <FilterItem
              activeIndex={activeIndex}
              setSet={setSet}
              index={i}
              sets={sets}
              setSets={setSets}
              classes={classes}
              setManageFilterBar={setManageFilterBar}
              closeFilterMenu={closeFilterMenu}
              set={set}
              setsKey={setsKey}
            />
          </Box>
        );
      })}

      <Button
        endIcon={
          isLoading ? (
            <FAIcon
              icon="spinner"
              className="fa-spin"
              collection="fas"
              size="medium"
            />
          ) : (
            <Add />
          )
        }
        disabled={isLoading}
        variant="outlined"
        onClick={() => {
          setManageFilterBar(newSet());
          setAppliedFilters(buildFilters({ tab }));
          closeFilterMenu();
        }}
        style={{ marginTop: 6, justifyContent: 'space-between', display: 'flex', borderRadius: 8 }}
        fullWidth
      >
        {t('createSetFilter')}
      </Button>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  moreMenuText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
    paddingRight: 16
  }
}));

const FilterItem = ({
  setManageFilterBar,
  closeFilterMenu,
  activeIndex,
  set,
  setSets,
  setSet,
  setsKey,
  index,
  sets
}) => {
  const classes = useStyles();

  return (
    <MenuItem
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%'
      }}
      dense
      onClick={() => {
        if (activeIndex !== set.index) {
          const nextSets = sets.map((s) => ({ ...s, active: s.index === set.index }));
          localStorage.setItem(setsKey, JSON.stringify(nextSets));
          setSets(nextSets);
          const nextSet = nextSets.find((s) => s.index === set.index);
          setSet(nextSet);
        }
        closeFilterMenu();
      }}
    >
      <ListItemIcon>
        {activeIndex === set.index ? <CheckCircle /> : <RadioButtonUnchecked />}
      </ListItemIcon>

      <ListItemText
        primary={
          <Typography
            component="span"
            className={classes.moreMenuText}
            style={{ fontWeight: 400 }}
          >
            {String.fromCharCode(index + 65) + ' - ' + set.title}
          </Typography>
        }
      />
      {
        <ListItemSecondaryAction style={{ display: 'flex', alignItems: 'center', right: 0 }}>
          <IconButton onClick={() => [setManageFilterBar(set), closeFilterMenu()]}>
            <EditOutlined />
          </IconButton>
        </ListItemSecondaryAction>
      }
    </MenuItem>
  );
};
