const TicketTranslations = (config, { scheduler }) => ({
  pick: config.isBlachere ? 'blachEntityTicketPick' : 'entityTicketPick',
  loading: config.isBlachere ? 'blachEntityTicketLoading' : 'entityTicketLoading',
  createDialog: config.isBlachere ? 'blachEntityTicketCreateDialog' : 'entityTicketCreateDialog',
  updateDialog: config.isBlachere ? 'blachEntityTicketUpdateDialog' : 'entityTicketUpdateDialog',
  create: config.isBlachere ? 'blachEntityTicketCreate' : 'entityTicketCreate',
  update: config.isBlachere ? 'blachEntityTicketUpdate' : 'entityTicketUpdate',
  entityName: config.isBlachere ? 'blachEntityTicketName' : 'entityTicketName',
  noResultLabel:
    scheduler && config.isBlachere
      ? 'blachEntityTicketnoResultLabel1'
      : !scheduler && config.isBlachere
        ? 'blachEntityTicketnoResultLabel2'
        : scheduler
          ? 'entityTicketnoResultLabel1'
          : 'entityTicketnoResultLabel2',
  searchText: config.isBlachere ? 'blachEntityTicketSearchText' : 'entityTicketSearchText'
});

export default TicketTranslations;
