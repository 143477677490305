const ManagerTranslations = (configuration) => ({
  pick: 'entityManagerPick',
  createDialog: 'entityManagerCreateDialog',
  updateDialog: 'entityManagerUpdateDialog',
  create: 'entityManagerCreate',
  update: 'entityManagerUpdate',
  entityName: 'entityManagerName',
  noResultLabel: 'entityManagernoResultLabel'
});

export default ManagerTranslations;
