import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
  Typography,
  useTheme
} from '@material-ui/core';
import { DeleteForever, DragIndicator } from '@material-ui/icons';
import clsx from 'clsx';
import BlurredProgress from 'components/BlurredProgress';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import { useState } from 'react';
import { Container, Draggable } from 'react-smooth-dnd';
import FieldEditor from './FieldEditor';
import { fieldTypes } from 'fieldSections/fieldTypes';

import { deleteField, updateFieldPosition } from 'store/fieldSectionsSlice';
import { useTranslation } from 'react-i18next';
import FAIcon from 'components/ui/FAIcon';

const useStyles = makeStyles((theme) => ({
  dragHandle: {
    '&:hover': {
      cursor: 'move'
    }
  }
}));

export default function FieldsManager({
  fields,
  setFields,
  sectionId,
  sectionEntity,
  editFrom = 0
}) {
  const { dispatch, requestStatus } = useAsyncDispatch({ virtualized: true });
  const [editorField, setEditorField] = useState(null);

  const { t } = useTranslation();
  const classes = useStyles();

  const handleDrop = async (dropResult) => {
    try {
      const { removedIndex, addedIndex } = dropResult;
      if (removedIndex < editFrom || addedIndex < editFrom) return;
      if (typeof removedIndex !== 'number' || typeof addedIndex !== 'number') return;

      // traiter les champs persos comme si pas de champs par defaut

      let newFields = [...fields.slice(editFrom, fields.length)];
      const [removed] = newFields.splice(removedIndex - editFrom, 1);
      newFields.splice(addedIndex - editFrom, 0, removed);
      newFields = newFields.map((r, i) => ({ ...r, position: i }));
      setFields(newFields);
      dispatch(
        updateFieldPosition,
        {
          removedIndex: removedIndex - editFrom,
          addedIndex: addedIndex - editFrom
        },
        undefined,
        { id: newFields[addedIndex]._id }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const isEditable = (i) => (typeof i === 'number' ? i > editFrom - 1 : false);

  const theme = useTheme();

  return (
    <>
      <FieldEditor
        element={editorField}
        onClose={() => setEditorField(null)}
        fields={fields}
        setFields={setFields}
        sectionId={sectionId}
        sectionEntity={sectionEntity}
        editFrom={editFrom}
      />
      <List
        style={{ margin: 0, padding: 0 }}
        dense={true}
      >
        <Container
          dragHandleSelector=".column-drag-handle"
          onDrop={handleDrop}
        >
          {fields &&
            fields?.length > 0 &&
            fields.map((field, i) => {
              const isTitle = field.valueType === 'title';

              const type = fieldTypes[field.valueType].label;
              const icon = fieldTypes[field.valueType].icon;

              return (
                <Draggable key={field._id}>
                  <Paper style={{ margin: '.3em' }}>
                    <ListItem
                      button={isEditable(i)}
                      style={{
                        paddingLeft: 10,
                        borderRadius: 8,
                        border: isTitle ? `2px solid ${theme.palette.primary.main}` : ''
                      }}
                      onClick={() => isEditable(i) && setEditorField(field)}
                    >
                      <BlurredProgress in={requestStatus === 'loading'} />
                      {isEditable(i) && (
                        <ListItemAvatar style={{ minWidth: 0, marginRight: 5 }}>
                          <DragIndicator
                            className={clsx(classes.dragHandle, 'column-drag-handle')}
                          />
                          <FAIcon
                            collection="fal"
                            icon={icon}
                            style={{
                              margin: '0 0.2em',
                              color: isTitle ? theme.palette.primary.main : ''
                            }}
                          />
                        </ListItemAvatar>
                      )}
                      <ListItemText
                        style={{ margin: '.8em 0' }}
                        primary={
                          <span
                            style={
                              isTitle
                                ? { fontWeight: 'bold', color: theme.palette.primary.main }
                                : {}
                            }
                          >
                            {t(field.label)}{' '}
                            {field.required && <sup style={{ color: 'red' }}>*</sup>}
                          </span>
                        }
                        secondary={t(type)}
                      />

                      {isEditable(i) && (
                        <ListItemSecondaryAction style={{ right: 22 }}>
                          <IconButton
                            edge="end"
                            onClick={() => {
                              dispatch(deleteField, undefined, undefined, { id: field._id }).then(
                                ({ error }) => {
                                  if (!error) {
                                    const next = fields
                                      .slice(editFrom)
                                      .filter((f) => f._id !== field._id);
                                    setFields(next);
                                  }
                                }
                              );
                            }}
                          >
                            <DeleteForever />
                          </IconButton>
                        </ListItemSecondaryAction>
                      )}
                    </ListItem>
                  </Paper>
                </Draggable>
              );
            })}
        </Container>
      </List>
      <Box style={{ width: 'full', textAlign: 'center', margin: '1em 0' }}>
        {fields?.length < 1 && (
          <Typography style={{ margin: '1em 0' }}>{t('youHaveNotYetCreatedFields')}</Typography>
        )}
        <Button
          variant="contained"
          onClick={() => setEditorField('new')}
        >
          {t('addAField')}
        </Button>
      </Box>
    </>
  );
}
