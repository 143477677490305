import { useCallback, useRef, cloneElement } from 'react';

export default function UploadButton({ multiple = true, children, disabled, onUpload = () => {} }) {
  const uploadRef = useRef();

  const onClickUploadBtn = useCallback(() => {
    uploadRef.current.click();
  }, []);

  return (
    <>
      <input
        ref={uploadRef}
        onChange={onUpload}
        type="file"
        style={{ display: 'none' }}
        multiple={multiple}
        disabled={disabled}
      />
      {cloneElement(children, { onClick: onClickUploadBtn, disabled })}
    </>
  );
}
