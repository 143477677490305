import { makeStyles, Badge, IconButton, Tooltip } from '@material-ui/core';
import { FindInPageOutlined } from '@material-ui/icons';
import FAIcon from 'components/ui/FAIcon';

import { states } from 'constants/states';
import { useAuth } from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';

import DevisHelper from 'utils/devis';
import { formatCurrency } from 'utils/formatCurrency';

const useStyles = makeStyles((_) => ({
  badgeCircle: {
    width: '28px',
    height: '28px',
    borderRadius: '50%',
    zIndex: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& div': {
      background: 'white',
      width: '24px',
      height: '24px',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  }
}));

export default function QuoteColumn({ ticket, setDocumentViewerConfig }) {
  const { t } = useTranslation();
  const auth = useAuth();
  const company = auth.interface._company;

  return ticket.parentId || !ticket._facture_tickets.length ? (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        {DevisHelper.getValidateds({ devis: ticket.devis }).length > 0 && (
          <strong>
            REF:{' '}
            {DevisHelper.getValidateds({ devis: ticket.devis })
              .map((d) => d.reference)
              .join(', ')}
          </strong>
        )}
        <div>
          {formatCurrency({
            number: ticket.devis_price,
            locale: company?.currency?.locale,
            currency: company?.currency?.code
          })}
        </div>
      </div>
      <Tooltip title={t('seeDocumentsTypeOfQuotes')}>
        <IconButton
          onClick={(ev) =>
            setDocumentViewerConfig({
              isOpen: true,
              ticketId: ticket._id,
              type: 'devis',
              anchor: ev.currentTarget,
              lock: ticket.facture_state.lock
            })
          }
          disabled={!ticket.devis.length}
        >
          <Badge
            badgeContent={DevisHelper.getAll({ devis: ticket.devis }).length}
            color="primary"
          >
            <FindInPageOutlined style={{ width: '100%', height: '1.2em' }} />
          </Badge>
        </IconButton>
      </Tooltip>
      {
        <DevisBadge
          devis={ticket.devis}
          transparent
        />
      }
    </div>
  ) : (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
      <span style={{ fontSize: '14px', position: 'relative', right: '14px' }}>
        {formatCurrency({
          number: ticket.total_devis_price,
          locale: company?.currency?.locale,
          currency: company?.currency?.code
        })}
      </span>
      {
        <DevisBadge
          devis={[...ticket.devis, ...ticket._facture_tickets.flatMap((ticket) => ticket.devis)]}
          transparent
        />
      }
    </div>
  );
}

function DevisBadge({ devis = [], intervenerId = null, transparent = false }) {
  const classes = useStyles();
  const { t } = useTranslation();

  if (DevisHelper.getWaitings({ devis, intervenerId }).length)
    return (
      <Tooltip
        placement="left"
        title={t('awaitValidationQuotes')}
      >
        <div
          style={{
            cursor: 'pointer',
            marginRight: 8,
            marginLeft: 6,
            background: states.validation.color
          }}
          className={classes.badgeCircle}
        >
          <div>
            <FAIcon
              collection="fas"
              icon="gavel"
              className="text-[#ef7a1f]"
            />
          </div>
        </div>
      </Tooltip>
    );

  if (DevisHelper.getValidateds({ devis, intervenerId }).length)
    return (
      <div
        style={{
          cursor: 'pointer',
          marginRight: 8,
          marginLeft: 6,
          background: states.validated.color
        }}
        className={classes.badgeCircle}
      >
        <div>
          <FAIcon
            collection="far"
            icon="check"
            className="text-[#2ecc71]"
          />
        </div>
      </div>
    );

  if (DevisHelper.getDroppeds({ devis, intervenerId }).length)
    return (
      <div
        style={{
          cursor: 'pointer',
          marginRight: 8,
          marginLeft: 6,
          background: states.dropped.color
        }}
        className={classes.badgeCircle}
      >
        <div>
          <FAIcon
            collection="far"
            icon="minus"
            className="text-[#e74c3c]"
          />
        </div>
      </div>
    );

  return transparent ? <div style={{ width: '40px' }} /> : null;
}
