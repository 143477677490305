import FAIcon from 'components/ui/FAIcon';
import { useTranslation } from 'react-i18next';
import { truncateText } from 'utils/uiUtils';
import { Box, Tooltip } from '@material-ui/core';
import preventAll from 'utils/preventAll';
import useStyles from 'layouts/entities/Preview.styles';
import { ChatBubbleLeftEllipsisIcon, UserCircleIcon } from '@heroicons/react/24/solid';
/*
    - look todo location
    - si le delai est passer faut afficher un cercle rouge a cote de la balise a gauche de ("haut , regrouper , moyen, critique") leur couleurs selon le type de text voici son code :  <FAIcon style={{ marginLeft: 6, color: '#e63d53' }} icon="circle" collection="fas" size="small"/>
    - seul le lieu et le titre exist tjr
    - Si les notes existe faut afficher un petit carrer bleu (V ou I ou G) comme log bleu et le text en blanc et quand on hover dessus y'a une petit pop op qui montre le message
    - Icon horloge qui est afficher si ?????

    what i need :
    - Je trouve ou les variables : etat du ticket = intervenant affecte , ect.. / prioriter = ("haut , regrouper , moyen, critique")
*/

export default function TicketPreviewNew({ ticket }) {
  // Get the data :
  /*------------------------------- */
  const { _id, p: parentTicket, i: ticketId, c: company, data, t: title, d: description, n: ticketNumber, m } = ticket;

  const { t } = useTranslation();
  const pastDeadLine = true; //Boolean(Math.floor(n * 2)); // generate random bool
  const alertExist = true;
  const classes = useStyles();

  /*------------------------------- */
  const equipments = ticket.data.equipments || [];
  const skills = ticket.data.skills || [];
  const locations = ticket.data.locations || [];

  /*------------------------------- */
  const notesObject = [
    {
      //notes: element.notes,
      letter: 'V',
      title: /*element?.notes?.length > 1 ? t('notesVisibleByEveryone'),:*/ t('noteVisibleByEveryone'),
      color: '#297BD7'
    },
    {
      //notes: element.viewNotes,
      letter: 'I',
      title: /*element?.viewNotes?.length > 1 ? t('internalNotes') :*/ t('internalNote'),
      color: '#1F60A7'
    },
    /* config.isManager &&*/ {
      //notes: element.viewNotesManagers,
      letter: 'G',
      title: /* element?.viewNotesManagers?.length > 1 ? t('managersNotes') :*/ t('managersNote'),
      color: '#174578'
    }
  ];

  /*------------------------------- */
  // Front design :
  /*------------------------------- */
  return (
    <div className="border w-full h-full p-4 bg-white">
      {/*------------ 1er ligne : Nbr ticket ect..  */}
      <div className="flex justify-between items-center text-xs">
        <div className="flex items-center space-x-2">
          <span className="text-black font-semibold">#{ticketNumber}</span>
          {/* TODO: mettre une variable ici !*/}
          <span className="text-orange-500 font-semibold">Intervenant affecté</span>
        </div>

        <div className="flex items-center space-x-2">
          {/* Les notes dans la nouvelle prevue du ticket */}
          <div className="flex items-center ">
            {notesObject.map(({ /*notes, */ letter, title, color }, i) => {
              return (
                <Tooltip
                  classes={{ tooltip: classes.tooltip }}
                  {...preventAll}
                  title={
                    <>
                      <div
                        className={`mb-5 p-2 text-center text-sm text-white rounded-t-lg `}
                        style={{ backgroundColor: color }}
                      >
                        {title}
                      </div>
                      <div className="flow-root px-4">
                        <ul>
                          {/* {sortByLatestDateFirst(notes).map((note, noteIdx) => {
                                                const first_name_or_last_name_known =
                                                    !!note._user?.firstName || !!note._user?.lastName;
                                                const note_from_api = !!note?.fromApi;

                                                const author_note = note_from_api
                                                    ? t('fromApi')
                                                    : first_name_or_last_name_known
                                                    ? note?._user?.firstName + ' ' + note?._user?.lastName
                                                    : note?._user?.email; */}
                          {/* return ( */}
                          {/* <li key={k2(noteIdx)}> */}
                          <div className="relative pb-4">
                            {/* Here if the number of notes is only one no need for the gray line */}
                            {false ? (
                              <span
                                className="absolute top-0 left-4 -ml-px h-full w-0.5 bg-gray-200"
                                aria-hidden="true"
                              />
                            ) : null}
                            <div className="relative flex items-start space-x-3">
                              <div className="relative">
                                <div className="h-5 w-5 bg-gray-100 rounded-full ring-4 ring-white flex items-center justify-center">
                                  <UserCircleIcon
                                    className="h-5 w-5 text-gray-500"
                                    aria-hidden="true"
                                  />
                                </div>
                                <span className="absolute -bottom-0.5 -right-1 bg-white rounded-tl px-0.5 py-px">
                                  <ChatBubbleLeftEllipsisIcon
                                    className="h-3 w-3 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </span>
                              </div>
                              <div className="group min-w-0 flex-1">
                                <div className="text-sm font-normal flex justify-between">
                                  <p className="whitespace-pre-wrap inline-flex">{'hello miss hiba'}</p>
                                </div>
                                <div className="mt-2">
                                  <p className="text-right text-xs text-gray-400">
                                    {'author_note'}, {/* {dateToLocalFormat(note.created_at, 'Pp')} */}
                                    {', 05/11/2024, 12:20'}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/*   );
                                                  })}*/}
                        </ul>
                      </div>
                    </>
                  }
                  placement="top"
                  arrow
                >
                  <Box
                    {...preventAll}
                    style={{
                      marginRight: '.5em',
                      backgroundColor: color,
                      borderRadius: 8,
                      color: 'white',
                      fontSize: '.7em',
                      height: '2.5em',
                      width: '2.5em',
                      display: 'flex',
                      justifyContent: 'center',
                      textAlign: 'center',
                      alignItems: 'center'
                    }}
                  >
                    {letter}
                  </Box>
                </Tooltip>
              );
            })}
          </div>

          {/* Indicateur de Hors delai & Hover*/}
          {pastDeadLine && (
            <Tooltip
              placement="left"
              title={t('afterDeadline')}
            >
              <Box>
                <FAIcon
                  style={{ marginLeft: 1, color: '#e63d53' }}
                  icon="circle"
                  collection="fas"
                  size="small"
                />
              </Box>
            </Tooltip>
          )}

          {/* TODO : mettre la variable ici ! et si l'utilisateur n'as rien selectioner je mets rien */}
          <span className="text-orange-500 border border-orange-500 px-2 py-1 rounded-xl text-xs">Haute</span>
        </div>
      </div>

      {/*------------ 2eme ligne : Titre et Metier  */}
      <div className="flex flex-col md:flex-row justify-between items-start text-sm text-slate-600 mt-2">
        <span className="font-bold max-w-sm">{title}</span>
        <span className="font-normal text-xs flex items-center md:ml-4 mt-1 md:mt-0 ">
          {Boolean(skills.length) && (
            <>
              <FAIcon
                icon="toolbox"
                size="small"
                className="ml-[-4px]"
              />
              {/* TODO: Display skills number instead of name if many */}
              {skills[0].name}
            </>
          )}
        </span>
      </div>

      {/*------------ 3eme ligne : Description  */}
      <p className="text-gray-600 text-xs mt-2 mr-2">{truncateText(description, 160)}</p>

      {/*------------ 4eme ligne : Lieu et Equipement  */}
      <div className="flex flex-col md:flex-row justify-between items-start text-xs text-slate-600 mt-2">
        <span className="flex items-center max-w-[22rem]">
          {Boolean(locations.length) && (
            <>
              <FAIcon
                icon="map-marker-alt"
                size="small"
                className="ml-[-4px]"
              />
              {/* TODO: Display lcoations number instead of name if many */}
              {locations[0].name}
            </>
          )}
        </span>
        <span className="flex items-center md:ml-4 mt-1 md:mt-0">
          {Boolean(equipments.length) && (
            <>
              <FAIcon
                icon="wrench"
                size="small"
                className="ml-[-4px]"
              />
              {/* TODO: Display equipments number instead of name if many */}
              {equipments[0].name}
            </>
          )}
        </span>
      </div>
    </div>
  );
}
