import {
  Box,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Tooltip
} from '@material-ui/core';
import clsx from 'clsx';
import FAIcon from 'components/ui/FAIcon';
import DevisPrimary from './documents/DevisPrimary';
import FacturePrimary from './documents/FacturePrimary';
import Skeleton from '@material-ui/lab/Skeleton';
import { CloudUploadOutlined, Lock } from '@material-ui/icons';
import useStyles from 'layouts/entities/View.styles';
import useKeys from '@flowsn4ke/usekeys';
import { formatCurrency } from 'utils/formatCurrency';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'hooks/useAuth';

export const QuotesAndInvoices = ({
  isLoading,
  documents,
  isFacturesLinked,
  setIsListOpen,
  canUpload,
  createDocument,
  type,
  isParent,
  accounting_check_paid_invoices_active,
  invoice_is_being_processed
}) => {
  const classes = useStyles();
  const k2 = useKeys();
  const { t } = useTranslation();
  const auth = useAuth();
  const company = auth.interface._company;
  const accountingIcon = type === 'devis' ? 'file-search' : company?.currency?.icon || 'euro-sign';

  const renderDocument = (document) => {
    return (
      <span>
        {[
          document.reference,
          formatCurrency({
            number: document.price,
            locale: company?.currency?.locale,
            currency: company?.currency?.code
          })
        ]
          .filter((o) => !!o)
          .join(' / ')}{' '}
        {accounting_check_paid_invoices_active && document?.paid ? `- ${t('paid')}` : ''}
      </span>
    );
  };

  return (
    <ListItem
      disabled={isLoading}
      dense
      button
      className={clsx(classes.listItemSecondary, classes.listItem)}
      onClick={() =>
        !!documents?.length || isFacturesLinked ? setIsListOpen(true) : canUpload ? createDocument() : null
      }
    >
      <ListItemIcon>
        <FAIcon
          icon={accountingIcon}
          collection={'fal'}
          className={classes.listIcon}
        />
      </ListItemIcon>
      <ListItemText
        primary={
          <Box
            display="flex"
            alignItems="center"
            fontWeight={600}
          >
            {type === 'devis' && (
              <DevisPrimary
                documents={documents}
                isParent={isParent}
              />
            )}
            {type === 'facture' && (
              <FacturePrimary
                documents={documents}
                isParent={isParent}
              />
            )}
          </Box>
        }
        secondary={
          isLoading ? (
            <Skeleton
              animation="wave"
              variant="text"
              width={64}
              height={10}
            />
          ) : (
            <Box>
              {!documents?.length && t('noDocument')}
              {documents.map((document, i) => (
                <Box key={k2(i)}>{renderDocument(document, formatCurrency, accounting_check_paid_invoices_active)}</Box>
              ))}
            </Box>
          )
        }
      />
      {
        <ListItemSecondaryAction className={classes.listItemSecondary}>
          {canUpload && (
            <IconButton
              onClick={() => createDocument()}
              className="hover"
            >
              {<CloudUploadOutlined />}
            </IconButton>
          )}

          {invoice_is_being_processed && (
            <Tooltip
              placement={'left'}
              title={
                <>
                  {t('lineCannotBeEditedInProgress')}
                  <br />
                  {t('pleaseApproachClientOrManager')}.
                </>
              }
            >
              <IconButton>
                <Lock />
              </IconButton>
            </Tooltip>
          )}
        </ListItemSecondaryAction>
      }
    </ListItem>
  );
};
