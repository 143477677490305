const TechnicianTranslations = (configuration) => ({
  pick: 'entityTechnicianPick',
  create: 'entityTechnicianCreate',
  update: 'entityTechnicianUpdate',
  entityName: 'entityTechnicianName',
  noResultLabel: 'entityTechniciannoResultLabel',
  searchText: 'entityTechnicianSearchText',
  createDialog: 'entityTechnicianCreateDialog',
  updateDialog: 'entityTechnicianUpdateDialog'
});

export default TechnicianTranslations;
