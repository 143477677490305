import { Box } from '@material-ui/core';
import FAIcon from 'components/ui/FAIcon';
import useStyles from '../../layouts/entities/Preview.styles';

import { add, isBefore, isAfter } from 'date-fns';
import { dateToLocalFormat } from 'utils/dates';
import { useTranslation } from 'react-i18next';

export function isGuaranteeOn(element, from = new Date()) {
  return (
    element.start_date &&
    isAfter(from, new Date(element.start_date)) &&
    isBefore(from, add(new Date(element.start_date), { [element.duration_type]: element.duration }))
  );
}

export function endGuarantee(element) {
  return dateToLocalFormat(
    add(new Date(element.start_date), { [element.duration_type]: element.duration }),
    'PP'
  );
}

export function startGuarantee(element) {
  return dateToLocalFormat(element.start_date, 'PP');
}

export default function GuaranteePreview({ element, payload: { from = new Date() } }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const isOn = isGuaranteeOn(element);

  return (
    <>
      <Box
        display={'flex'}
        alignItems={'center'}
      >
        {isOn && (
          <FAIcon
            collection={'fas'}
            icon={'shield-check'}
            className={classes.success}
            style={{ marginRight: 16 }}
          />
        )}
        {!isOn && (
          <FAIcon
            collection={'fas'}
            icon={'ban'}
            className={classes.error}
            style={{ marginRight: 16 }}
          />
        )}

        <Box
          display={'flex'}
          alignItems={'center'}
          flexDirection={'column'}
        >
          {
            <span className={classes.title}>
              {element.name} -
              {
                <span
                  className={classes.reference}
                  style={{ marginLeft: 4 }}
                >
                  {element.duration}{' '}
                  {element.duration_type !== 'years'
                    ? t('months')
                    : element.duration === 1
                      ? t('guaranteeDurationYear')
                      : t('guaranteeDurationYears')}
                </span>
              }
            </span>
          }
          {element.start_date && (
            <Box
              display={'flex'}
              alignItems={'center'}
              className={classes.l}
              marginTop={'4px'}
            >
              {isOn && (
                <Box style={{ fontWeight: 400 }}>
                  {t('guaranteeStartedAt')} {startGuarantee(element)} <br />
                  <span style={{ color: 'green' }}>
                    {t('guaranteeActiveAt')} {dateToLocalFormat(from, 'PP')}
                    <br />
                  </span>
                  {t('guaranteeExpireAt')} {endGuarantee(element)}
                </Box>
              )}
              {!isOn && (
                <Box style={{ fontWeight: 400 }}>
                  {t('guaranteeStartedAt')} {startGuarantee(element)} <br />
                  {t('guaranteeExpireAt')} {endGuarantee(element)}
                  <br />
                  <span style={{ color: 'red' }}>
                    {t('guaranteeInactiveAt')} {dateToLocalFormat(from, 'PP')}
                  </span>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}
