import { Box, Grid } from '@material-ui/core';
import { CONTRACT_OPENED } from 'lists/contractStates';
import { useContract } from 'contexts/entities/contractContext';
import { useIsBelowMd } from 'hooks/useMQ';

import ContractDate from './ContractDate';
import ContractAccounting from './ContractAccounting';

export default function Contract({ isParent }) {
  const { contractParent, contract: contractOwn } = useContract();
  const isBelowMd = useIsBelowMd();

  const contract = isParent ? contractParent : contractOwn;

  return (
    ![CONTRACT_OPENED].includes(contract.state) && (
      <Box
        position="relative"
        width="100%"
        paddingTop="6px"
        paddingLeft={isBelowMd ? '0px' : '42px'}
        marginBottom="16px"
      >
        <Grid container>
          {
            <>
              <ContractDate
                type="visit"
                isParent={isParent}
                contract={contract}
              />
              <ContractDate
                type="intervention"
                isParent={isParent}
                contract={contract}
              />
            </>
          }

          {
            <>
              <ContractAccounting
                type="devis"
                isParent={isParent}
                contract={contract}
              />
              <ContractAccounting
                type="facture"
                isParent={isParent}
                contract={contract}
              />
            </>
          }
        </Grid>
      </Box>
    )
  );
}
