import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

export default function initSentry(env) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN.trim(),
    environment: env,
    integrations: [new BrowserTracing()],
    ignoreErrors: ['ResizeObserver loop'],
    tracesSampleRate: 0.5
  });
}
