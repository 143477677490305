import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: theme.shadows[3],
    backgroundImage: `linear-gradient(to right, ${theme.palette.primary.main} 0%, ${theme.palette.primary.light} 100%)`
  },
  interfacesList: {
    marginTop: 0,
    flexGrow: 1,
    overflow: 'auto',
    minHeight: 222
  },

  avatarBox: {
    position: 'relative'
  },

  search: {
    width: '100%',
    padding: '6px 10px',
    backgroundColor: 'white',
    marginTop: 4,
    marginBottom: -8
  },

  unlinkAction: {
    position: 'relative',
    right: 10
  },

  h3: {
    fontWeight: 500,
    color: '#ffffff',
    fontSize: 16,
    marginBottom: 10
  },
  h5: {
    fontWeight: 500,
    fontSize: 14,
    color: 'white'
  },
  h6: {
    fontWeight: 500,
    color: '#ffffff',
    fontSize: 13,
    lineHeight: 'inherit',
    cursor: 'pointer'
  },
  h6Icon: {
    fontSize: 13,
    color: 'white',
    marginRight: 6
  },
  fullWidth: {
    width: '100%'
  },

  profileCard: ({ isFirstConnect }) => ({
    display: 'flex',
    alignItems: 'center',
    height: 100,
    marginBottom: 15,
    position: 'relative',
    margin: 12,
    ...(isFirstConnect
      ? {
          width: '50%',
          marginLeft: 'auto',
          marginTop: '-54px',
          marginBottom: '28px'
        }
      : {})
  }),

  userAvatar: {
    transform: 'translateY(30%) translateX(-30%)',
    position: 'absolute',
    bottom: 0,
    left: 0
  },

  profileInfo: {
    flexGrow: 1,
    padding: '4px 8px 0px 8px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },

  paperInterface: {
    background: 'transparent',
    boxShadow: 'none',
    overflow: 'visible'
  },
  interfacesListContainer: {
    background: 'white',
    maxHeight: 264,
    overflow: 'auto',
    borderRadius: '0px 0px 8px 8px'
  },
  actions: {
    marginTop: 16,
    borderRadius: `0px 0px ${theme.radius}px ${theme.radius}px`,
    backgroundColor: theme.palette.primary.dark,
    padding: 12,
    display: 'flex',
    alignItems: 'center'
  },
  actionButton: {
    justifyContent: 'space-between',
    flexGrow: 1
  },
  actionsSettings: {
    borderRadius: theme.radius,
    backgroundColor: theme.palette.primary.light,
    ['&:hover']: {
      backgroundColor: theme.palette.primary.main
    }
  },
  actionsLogout: {
    position: 'relative',
    left: 4
  },
  actionsList: {
    position: 'relative',
    left: 8
  },
  logoutButton: {
    color: 'white'
  },
  listButton: {
    color: 'white'
  }
}));
