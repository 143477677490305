import generateAsyncThunk from 'utils/generateAsyncThunk';
import { createSlice } from '@reduxjs/toolkit';
import { CONTRACT_DECLINED } from 'lists/contractStates';
import { generateEntityBoilerplate } from 'utils/generateEntitySlice';

import { formatElement as formatTicket } from './ticketsSlice';

export const getElement = generateAsyncThunk({
  type: 'GET',
  endpoint: 'tickets/propositions/${id}'
});
export const getElements = generateAsyncThunk({ type: 'POST', endpoint: 'tickets/propositions' });
export const countElements = generateAsyncThunk({
  type: 'GET',
  endpoint: 'tickets/propositions/count'
});

export const acceptContractProposition = generateAsyncThunk({
  type: 'PUT',
  endpoint: 'tickets/${id}/contractor/response'
});

const entityBoilerplate = generateEntityBoilerplate({
  extraInitialStateState: { count: 0 },
  getElement,
  getElements,
  getElementNext: (state, action) => {
    const { element } = action.payload.data;
    const element_db = state.db[element._id];

    if (!element_db?.seen) {
      state.count--;
      state.db[element._id].seen = true;
    }
  },
  formatElement: (element) => {
    if (element._ticket) {
      const _ticket = formatTicket(element._ticket);
      return {
        ...element,
        _ticket: {
          ..._ticket,
          contract: {
            ..._ticket.contract,
            state: element.state
          }
        }
      };
    }
    return element;
  }
});

const { initialState, reducers, extraReducers } = entityBoilerplate;

export const contractPropositionsSlice = createSlice({
  name: 'contractPropositions',
  initialState,
  reducers,
  extraReducers: {
    ...extraReducers,
    [countElements.fulfilled]: (state, action) => {
      state.count = action.payload.data.count;
    },
    [acceptContractProposition.fulfilled]: (state, action) => {
      const {
        response,
        params: { id }
      } = action.meta.arg;
      if (!response) {
        const propositionId = Object.keys(state.db).find((k) => state.db[k]._ticket._id === id);
        if (state.db[propositionId]) {
          state.db[propositionId]._ticket.contract.state = CONTRACT_DECLINED;
        }
      }
    }
  }
});

export const { flushElements, flushElement } = contractPropositionsSlice.actions;
export default contractPropositionsSlice.reducer;
