export default function formatAnalyticsDatasetsAndTimeSort(data) {
  const datasetsLabels = [];
  let months = [];

  Object.keys(data).forEach((key) => {
    datasetsLabels.push(key);

    data[key] = data[key]
      .sort((a, b) => (a.month < b.month) * 1 + (a.month > b.month) * -1)
      .sort((a, b) => (a.year < b.year) * 1 + (a.year > b.year) * -1);

    data[key].forEach((dp) => {
      if (!months.find((m) => m === dp.month)) months.push(dp.month);
    });
  });

  months = months.reverse();

  const datasets = Object.keys(data).map((key) => {
    const datapoints = new Array(months.length);
    const d = data[key];

    for (let i = 0; i < d.length; i++) datapoints[months.indexOf(d[i].month)] = d[i].count;

    for (let i = 0; i < datapoints.length; i++) datapoints[i] = datapoints[i] || 0;

    return datapoints;
  });

  const labels = new Array(months.length);

  Object.keys(data).forEach((key) => {
    data[key].forEach((dp) => {
      const monthIndex = months.indexOf(dp.month);
      const label = `${dp.month}/${dp.year}`;
      labels[monthIndex] = labels[monthIndex] || label;
    });
  });

  return {
    datasets,
    datasetsLabels,
    labels
  };
}
