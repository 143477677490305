import { Chip, Box, IconButton, Badge } from '@material-ui/core';
import { Phone } from '@material-ui/icons';

import Avatar from 'components/Avatar';
import {
  INTERFACE_USER,
  INTERFACE_CLIENT,
  INTERFACE_MASTER,
  INTERFACE_CONTRACTOR,
  INTERFACE_TECHNICIAN
} from 'constants/roles';
import { withStyles } from '@material-ui/core/styles';
import { useState } from 'react';

import Intervener from 'entities/Intervener/Intervener';
import Manager from 'entities/Manager/Manager';
import User from 'entities/User/User';
import PopoverDrawer from 'components/ui/PopoverDrawer';

const wrappers = {
  [INTERFACE_USER]: User,
  [INTERFACE_CLIENT]: Manager,
  [INTERFACE_CONTRACTOR]: Manager,
  [INTERFACE_MASTER]: Manager,
  [INTERFACE_TECHNICIAN]: Intervener
};

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    right: -2,
    top: 4,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""'
    }
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0
    }
  }
}))(Badge);

export default function UserChip({ user, type, modal = false, ...rest }) {
  const [isCardOpen, setIsCardOpen] = useState(false);
  const [isOnline] = useState(false);

  const Wrapper = wrappers[type] || (({ children }) => <>{children}</>);
  const chip = (
    <Chip
      avatar={
        <Avatar
          isUser
          round
          size={'chip'}
          entity={user}
        />
      }
      clickable
      onClick={
        !modal
          ? (e) => [setIsCardOpen(e.currentTarget), e.stopPropagation(), e.preventDefault()]
          : null
      }
      label={
        <>
          {`${user?.firstName || ''} ${(user?.lastName || '').substring(0, 1)?.toUpperCase()}.`}{' '}
          {isOnline && (
            <StyledBadge
              overlap="circle"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              variant="dot"
              style={{ marginLeft: 6, marginRight: 4, bottom: 12 }}
            ></StyledBadge>
          )}
        </>
      }
      size="small"
      variant="outlined"
      {...rest}
    />
  );

  return modal ? (
    <Wrapper childrenId={user._id}>{chip}</Wrapper>
  ) : (
    <>
      {chip}
      <UserCard
        user={user}
        open={isCardOpen}
        onClose={(e) => [setIsCardOpen(false), e.stopPropagation(), e.preventDefault()]}
      />
    </>
  );
}

export function UserCard({ user, open, onClose }) {
  const [isOnline] = useState(false);

  const call = () => window.open(`tel: ${user?.phone}`, '_self');

  return (
    <PopoverDrawer
      anchorEl={open}
      open={!!open}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      keepMounted={false}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      PaperProps={{ style: { borderRadius: '16px' } }}
      transitionDuration={0}
      onClose={onClose}
      style={{ width: 360, borderRadius: '16px' }}
    >
      <Box
        borderRadius="16px"
        width="360px"
        onClick={(e) => [e.stopPropagation(), e.preventDefault()]}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          padding="14px"
          fontWeight={500}
          position="relative"
        >
          <Box
            display="flex"
            alignItems="center"
            fontWeight={500}
            position="relative"
          >
            <Avatar
              style={{ marginRight: 24 }}
              isUser
              round
              entity={user}
              size="medium"
            />
            {user?.firstName || ''} {user?.lastName || ''}
            {isOnline && (
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                variant="dot"
                style={{ marginLeft: 18, position: 'relative', bottom: 8 }}
              ></StyledBadge>
            )}
          </Box>

          <Box>
            {!!user?.phone?.length && (
              <IconButton
                style={{ marginRight: 8 }}
                size="small"
                onClick={() => call()}
              >
                <Phone />
              </IconButton>
            )}
          </Box>
        </Box>
      </Box>
    </PopoverDrawer>
  );
}
