export const states = {
  draft: {
    name: 'Brouillon',
    color: 'black',
    name_short: 'Brouillon'
  },
  opened: {
    name: 'En attente de validation',
    color: '#7f8c8d',
    name_short: 'Att.Val',
    types: ['client']
  },
  waiting: {
    name: "En attente d'assignation",
    color: '#2c3e50',
    name_short: 'att. assign.',
    types: ['client']
  },
  proposed: {
    name: 'Proposition envoyée',
    color: '#827696',
    name_short: 'Props'
  },
  toreplan: {
    name: 'À replanifier',
    color: '#ce4413',
    name_short: 'a. replan.',
    types: ['contractor', 'client']
  },
  toplan: {
    name: 'À planifier',
    color: '#ee754a',
    name_short: 'a. plan.',
    types: ['contractor']
  },
  waitingplan_: {
    name: 'En attente de planification',
    color: '#ee754a',
    name_short: 'att. pl.',
    types: ['contractor']
  },
  waitingplan: {
    name: 'En attente de planification',
    color: '#2c3e50',
    name_short: 'att. pl.',
    types: ['contractor']
  },
  waiting_contractor: {
    name: 'À planifier / assigner',
    color: '#2c3e50',
    name_short: 'att. pl.',
    types: ['contractor']
  },
  assigned: {
    name: 'Intervenant affecté',
    color: '#ee754a',
    name_short: 'Affec.',
    types: ['client']
  },
  visit: {
    name: 'Passage programmé',
    color: '#1fadda',
    name_short: 'P.Prog'
  },
  visit_devis: {
    name: 'En attente de retour - Passage',
    color: '#2288a8',
    name_short: 'Att.R'
  },
  validation: {
    name: 'Devis en attente de validation',
    color: '#ef7a1f',
    name_short: 'Att.Val'
  },
  validated: {
    name: 'Devis validé',
    color: '#2ecc71',
    name_short: 'D.Val'
  },
  dropped: {
    name: 'Devis décliné',
    color: '#e74c3c',
    name_short: 'D.Déc'
  },
  intervention: {
    name: 'Intervention programmée',
    color: '#16a085',
    name_short: 'I.Prog'
  },
  intervention_finish: {
    name: 'En attente de retour - Intervention',
    color: '#399472',
    name_short: 'Att.R'
  },
  finished: {
    name: 'Intervention terminée',
    color: '#31b55a',
    name_short: 'I.Term'
  },
  closed: {
    name: 'Ticket cloturé',
    color: '#2c3e50',
    name_short: 'T.Clot'
  },
  canceled: {
    name: 'Ticket annulé',
    color: '#e63d53',
    name_short: 'T.Ann'
  },
  declined: {
    name: 'Ticket refusé',
    color: '#e74c3c',
    name_short: 'T.Ref'
  },
  cashedby: {
    name: "En attente d'affectation de la charge",
    color: '#1c3962',
    name_short: '.'
  },
  removed: {
    name: 'Ticket supprimé',
    color: '#c0392b',
    name_short: '.'
  }
};
