import {
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  TextField,
  InputAdornment,
  MenuItem,
  Select,
  Checkbox,
  FormGroup
} from '@material-ui/core';
import { useField } from 'frmx';

import EntityX from 'components/inputs/EntityX';

import { Euro, Fingerprint } from '@material-ui/icons';
import Manager from 'entities/Manager/Manager';
import { useTranslation } from 'react-i18next';
import { useEntity } from 'contexts/entities/entityContext';
import { useConfiguration } from 'hooks/useConfiguration';
import { useAuth } from 'hooks/useAuth';

//!TODO : defaultValue is not used here
export default function AccountingFilter({ filter }) {
  const { value, setValue } = useField(filter.key);

  const type = filter.params.type;
  const { t } = useTranslation();

  const auth = useAuth();
  const company = auth?.interface?._company;

  const { entity } = useEntity();
  const configuration = useConfiguration();

  return (
    <Box
      width="100%"
      height="100%"
      padding="16px"
      display="flex"
      flexDirection="column"
    >
      <Grid
        container
        spacing={3}
      >
        <Grid item>
          <Box>
            <FormControl component="fieldset">
              <FormLabel component="legend">
                {type === 'devis' && t('quotes')}
                {type === 'factures' && t('invoices')}
              </FormLabel>
              <RadioGroup
                style={{
                  marginBottom: 10,
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
                value={value.documents}
                onChange={(e) => setValue({ ...value, isDirty: true, documents: +e.target.value })}
              >
                <FormControlLabel
                  value={0}
                  control={<Radio />}
                  label="Avec / Sans"
                />
                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  label="Avec"
                />
                <FormControlLabel
                  value={2}
                  control={<Radio />}
                  label="Sans"
                />
              </RadioGroup>
            </FormControl>

            {/* SHow radiogroup incoice paid only if is accounting entity and the functionnality is active */}
            {type === 'factures' && entity === 'accounting' && configuration?.comptability?.check_paid_invoices && (
              <Box>
                <FormControl component="fieldset">
                  <FormLabel component="legend">{t('invoicesPaid')}</FormLabel>
                  <RadioGroup
                    style={{
                      marginBottom: 10,
                      flexDirection: 'row',
                      alignItems: 'center'
                    }}
                    value={value.invoicePaid || undefined}
                    onChange={(e) => {
                      setValue({ ...value, isDirty: true, invoicePaid: e.target.value });
                    }}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label={t('yes')}
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label={t('no')}
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            )}

            {type === 'devis' && value.documents === 1 && (
              <FormGroup
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <FormControlLabel
                  control={value.validated ? <Checkbox checked /> : <Checkbox />}
                  label="Validé"
                  value={value.validated}
                  onChange={() => {
                    setValue({ ...value, isDirty: true, validated: !value.validated });
                  }}
                />
                <FormControlLabel
                  control={value.no_validated ? <Checkbox checked /> : <Checkbox />}
                  label={t('waitingForValidation')}
                  value={value.no_validated}
                  onChange={() => {
                    setValue({ ...value, isDirty: true, no_validated: !value.no_validated });
                  }}
                />
              </FormGroup>
            )}
          </Box>
        </Grid>

        {value.documents !== 2 && (
          <>
            {type === 'devis' && (
              <Grid
                item
                xs={12}
              >
                <EntityX
                  path="devis._users"
                  entityName="managers"
                  entity={Manager}
                  placeholder={t('validatedBy')}
                />
              </Grid>
            )}

            <Grid
              item
              xs={12}
            >
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Fingerprint />
                    </InputAdornment>
                  )
                }}
                variant={'outlined'}
                fullWidth
                autoFocus
                placeholder={t('reference')}
                label={t('reference')}
                value={value.reference}
                onChange={(e) => setValue({ ...value, isDirty: true, reference: e.target.value })}
              />
            </Grid>
            <Grid
              item
              xs={6}
            >
              <Select
                fullWidth
                variant={'outlined'}
                value={value.price.op}
                onChange={(e) => {
                  setValue({
                    ...value,
                    isDirty: true,
                    price: { ...value.price, op: e.target.value }
                  });
                }}
              >
                <MenuItem value="eq">{t('equalTo')}</MenuItem>
                <MenuItem value="lt">{t('lessThan')}</MenuItem>
                <MenuItem value="gt">{t('greaterThan')}</MenuItem>
                <MenuItem value="bt">{t('between')}</MenuItem>
              </Select>
            </Grid>
            <Box
              padding="12px"
              width="50%"
              display="flex"
              flexDirection="column"
            >
              <Grid
                item
                xs={12}
                style={{ marginBottom: 10 }}
              >
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="end">
                        <span className="text-xl">{company?.currency?.symbol || '€'}</span>
                      </InputAdornment>
                    )
                  }}
                  variant="outlined"
                  fullWidth
                  inputProps={{ type: 'number', min: 0 }}
                  type="number"
                  placeholder={t('noVATAmount')}
                  marginBottom="10px"
                  value={value.price.value1}
                  onChange={(e) => {
                    setValue({
                      ...value,
                      isDirty: true,
                      price: { ...value.price, value1: e.target.value }
                    });
                  }}
                />
              </Grid>
              {value.price.op === 'bt' && (
                <Grid
                  item
                  xs={12}
                >
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="end">
                          <span className="text-xl">{company?.currency?.symbol || '€'}</span>
                        </InputAdornment>
                      )
                    }}
                    variant="outlined"
                    fullWidth
                    inputProps={{ type: 'number', min: 0 }}
                    type={'number'}
                    placeholder={t('noVATAmount')}
                    value={value.price.value2}
                    onChange={(e) => {
                      setValue({
                        ...value,
                        isDirty: true,
                        price: { ...value.price, value2: e.target.value }
                      });
                    }}
                  />
                </Grid>
              )}
            </Box>
          </>
        )}
      </Grid>
    </Box>
  );
}
