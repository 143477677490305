import { Accordion, AccordionSummary, Box } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import FAIcon from 'components/ui/FAIcon';

import { useState } from 'react';
import clsx from 'clsx';
import { useIsBelowLg } from 'hooks/useMQ';
import { Form } from 'frmx';

import useStyles from './AccordionForm.styles';
import { useTranslation } from 'react-i18next';

const placeholderSubmit = (obj) => alert(JSON.stringify(obj, null, 2));

export default function AccordionForm(props) {
  const {
    section,
    children,
    initialValues,
    schemaValidation = {},
    className,
    allwaysExpanded,
    defaultClose,
    disabled,
    expandedOnOpen = true,
    onSubmit = placeholderSubmit,
    noForm,
    hide,
    secondaryActionButton,
    thirdActionButton,
    autoCompleteOff,
    ...rest
  } = props;
  const [expanded, setExpanded] = useState(expandedOnOpen && !defaultClose);
  const [isHovered, setIsHovered] = useState(false);
  const isBelowLg = useIsBelowLg();

  const { t } = useTranslation();

  const classes = useStyles();

  return (
    !hide && (
      <Box className={clsx(className, classes.root)}>
        <Accordion
          disabled={disabled}
          defaultExpanded={!isBelowLg}
          expanded={allwaysExpanded || expanded}
          onChange={() => setExpanded(!expanded)}
          TransitionProps={{ unmountOnExit: true }}
        >
          <AccordionSummary
            expandIcon={allwaysExpanded ? false : <ExpandMoreIcon />}
            className={classes.accordionSummary}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <Box className={classes.summary}>
              <FAIcon
                collection="fal"
                icon={section.icon}
                className={classes.icon}
                size="medium"
              />
              {t(section.label)}
            </Box>

            {isHovered && thirdActionButton && thirdActionButton}
            {isHovered && secondaryActionButton && secondaryActionButton}
          </AccordionSummary>
          {noForm ? (
            children
          ) : (
            <Form
              refresh
              initialValues={initialValues}
              onSubmit={onSubmit}
              schemaValidation={schemaValidation}
              disableIfNoUpdates
              {...rest}
            >
              {children}
            </Form>
          )}
        </Accordion>
      </Box>
    )
  );
}
