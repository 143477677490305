const MuiTableCell = {
  root: {
    borderBottom: `1px solid #DFE3E8`,
    padding: '14px 40px 14px 24px'
  },
  head: {
    fontSize: '14px',
    color: '#4f5b62'
  }
};

export default MuiTableCell;
