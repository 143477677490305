import {
  addDays,
  addMonths,
  differenceInMilliseconds,
  isAfter,
  isBefore,
  startOfMonth
} from 'date-fns';

export function linear_interpolation({ x, x0, x1, y0, y1 }) {
  const y = y0 + ((y1 - y0) * (x - x0)) / (x1 - x0);
  return y;
}

export function interpolate_meter_readings_to_12_months_data_points(readings) {
  const meter_readings = readings.sort((a, b) => new Date(a.at) - new Date(b.at));

  const first_reading_date = meter_readings[0] ? new Date(meter_readings[0].at) : new Date();
  const last_reading_date = meter_readings[meter_readings.length - 1]
    ? new Date(meter_readings[meter_readings.length - 1].at)
    : new Date();

  const dates = [];
  const start = startOfMonth(meter_readings[0] ? new Date(meter_readings[0].at) : new Date());

  for (let i = 0; i < 13; i++) {
    dates.push(addMonths(start, i));
  }

  const interpolated_readings = [];

  dates_loop: for (let i = 0; i < dates.length; i++) {
    const interpolated_date = dates[i];
    let reading_1, reading_2;

    for (let j = 0; j < meter_readings.length - 1; j++) {
      const date_next = meter_readings[j].at;
      const difference_prev = reading_1
        ? Math.abs(differenceInMilliseconds(new Date(interpolated_date), new Date(reading_1.at)))
        : Infinity;
      const difference_next = Math.abs(
        differenceInMilliseconds(new Date(interpolated_date), new Date(date_next))
      );
      const has_value = meter_readings[j]?.value;

      if (difference_next === 0 && has_value) {
        meter_readings[j].at = interpolated_date;
        interpolated_readings.push(meter_readings[j]);
        continue dates_loop;
      }

      const should_swap = has_value && (!reading_1 || difference_next < difference_prev);

      should_swap && (reading_1 = meter_readings[j]);
    }

    for (let j = 0; j < meter_readings.length - 1; j++) {
      const date_next = meter_readings[j].at;
      const difference_prev = reading_2
        ? Math.abs(differenceInMilliseconds(new Date(interpolated_date), new Date(reading_2.at)))
        : Infinity;
      const difference_next = Math.abs(
        differenceInMilliseconds(new Date(interpolated_date), new Date(date_next))
      );
      const has_value = meter_readings[j]?.value;

      const should_swap =
        has_value &&
        (!reading_2 || difference_next < difference_prev) &&
        new Date(reading_1.at).getTime() !== new Date(date_next).getTime();

      should_swap && (reading_2 = meter_readings[j]);
    }

    const timestamp_1 = reading_1 ? new Date(reading_1.at).getTime() : null;
    const timestamp_2 = reading_2 ? new Date(reading_2.at).getTime() : null;
    const interpolated_date_timestamp = interpolated_date.getTime();

    const date_is_outside_range =
      isBefore(interpolated_date, addDays(first_reading_date, -15)) ||
      isAfter(interpolated_date, addDays(last_reading_date, 15));
    const no_readings_to_interpolate = !reading_1 || !reading_2;

    const y_value =
      date_is_outside_range || no_readings_to_interpolate
        ? 0
        : linear_interpolation({
            x: interpolated_date_timestamp,
            x0: timestamp_1,
            x1: timestamp_2,
            y0: reading_1.value,
            y1: reading_2.value
          });

    interpolated_readings.push({
      ...reading_1,
      at: interpolated_date,
      value: Math.round(y_value)
    });
  }

  return interpolated_readings;
}
