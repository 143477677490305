import { useField } from 'frmx';
import { DatePicker } from '@material-ui/pickers';
import { isDate } from 'lodash-es';

export default function DateField({ path, afterChange, placeholder = '', ...rest }) {
  const fldx = useField(path);

  return (
    <DatePicker
      value={!!fldx?.value && isDate(new Date(fldx.value)) ? new Date(fldx?.value) : null}
      onChange={(d) => [fldx?.setValue(d), afterChange && afterChange()]}
      inputVariant="outlined"
      invalidLabel=""
      clearable
      placeholder={placeholder}
      invalidDateMessage={<></>}
      style={{ width: '100%' }}
      format="PPP"
      {...rest}
    />
  );
}
