import Location from 'entities/Location/Location';

const ScopeTabs = (configuration, element) => {
  if (!element) {
    return [];
  }

  return element.type === 'sectors'
    ? [
        {
          label: 'locations',
          icon: 'map-marker-alt',
          count: 0,
          show: true,
          class: 'tabNoPadding'
        }
      ].filter((e) => e.show)
    : element.type === 'ticketTva'
      ? [].filter((e) => e.show)
      : element.type === 'locationCategory'
        ? [
            {
              label: 'locations',
              icon: 'map-marker-alt',
              count: 0,
              show: true,
              render: () => (
                <Location
                  defaultForm={{ _sector: element }}
                  defaultFilters={{ types: [element] }}
                />
              ),
              class: 'tabNoPadding'
            }
          ].filter((e) => e.show)
        : element.type === 'clientAcquisition'
          ? [].filter((e) => e.show)
          : [].filter((e) => e.show);
};

export default ScopeTabs;
