const periodicities = {
  day: 'periodicitiesDay',
  week: 'periodicitiesWeek',
  '2week': 'periodicitiesBiWeekly',
  '3week': 'periodicitiesThreeWeeks',
  '6week': 'periodicitiesSixWeeks',
  month: 'periodicitiesMonthly',
  '2month': 'periodicitiesBiMonthly',
  trim: 'periodicitiesTrim',
  qrim: 'periodicitiesQuadri',
  semestre: 'periodicitiesSemester',
  year: 'periodicitiesYearly',
  reduit: 'periodicitiesReduit',
  complet: 'periodicitiesComplet',
  '2year': 'periodicitiesBiYearly',
  '3year': 'periodicitiesTriYearly',
  '4year': 'periodicitiesQuadriYearly',
  '5year': 'periodicitiesQuinYearly',
  '10year': 'periodicitiesTenYearly'
};

export default periodicities;
