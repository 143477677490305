import { Box } from '@material-ui/core';
import FAIcon from 'components/ui/FAIcon';
import useStyles from '../../layouts/entities/Preview.styles';

import { add, isBefore, isAfter } from 'date-fns';
import { dateToLocalFormat } from 'utils/dates';

export function isLeaseOn(element) {
  return (
    element.start_date &&
    isAfter(new Date(), new Date(element.start_date)) &&
    isBefore(
      new Date(),
      add(new Date(element.start_date), { [element.duration_type]: element.duration })
    )
  );
}

export function endLease(element) {
  return dateToLocalFormat(
    add(new Date(element.start_date), { [element.duration_type]: element.duration }),
    'PP'
  );
}

export function startLease(element) {
  return dateToLocalFormat(element.start_date, 'PP');
}

export default function LeasePreview({ element }) {
  const classes = useStyles();

  const isOn = isLeaseOn(element);

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
      >
        <FAIcon
          collection="fal"
          icon="house"
          className={isOn ? classes.success : ''}
          style={{ marginRight: 16 }}
        />

        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
        >
          <span className={classes.title}>
            {element.name} -
            <span
              className={classes.reference}
              style={{ marginLeft: 4 }}
            >
              {element.duration} {element.duration_type === 'years' ? 'ans' : 'mois'}
            </span>
          </span>
          {element.start_date && (
            <Box
              display="flex"
              alignItems="center"
              className={classes.l}
              marginTop="4px"
            >
              <Box>
                Commence le {startLease(element)} <br />
                Prend fin le {endLease(element)}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}
