import axios from 'axios';
import { ACCESS_TOKEN, INTERFACE_ID } from 'constants/localStorage';
import {
  COMPANY_CONTRACTOR,
  COMPANY_CLIENT,
  INTERFACE_USER,
  INTERFACE_MASTER,
  INTERFACE_CONTRACTOR,
  INTERFACE_TECHNICIAN,
  INTERFACE_CLIENT
} from 'constants/roles';
import { setInterfaceCrisp, setSplashAvatar, setCrispUser } from 'utils/uiUtils';

export function get_company_id_from_auth(auth) {
  return auth.interface._company._id;
}

export function setPublicHeaders({ clientId, contractorId }) {
  clientId && (axios.defaults.headers.common['public-client'] = clientId);
  contractorId && (axios.defaults.headers.common['public-contractor'] = contractorId);
}

export function unsetPublicHeaders() {
  delete axios.defaults.headers.common['client'];
  delete axios.defaults.headers.common['contractor'];
}

export const setInterface = (state, _interface) => {
  state.interface = _interface;

  if (_interface?._id) {
    localStorage.setItem(INTERFACE_ID, _interface._id);
    axios.defaults.headers.common['client'] = _interface._id;
  }

  setInterfaceCrisp(_interface);
  setSplashAvatar(_interface);
};

export const cleanupUser = (state) => {
  localStorage?.removeItem(ACCESS_TOKEN);
  state.accessToken = null;
  state.user = null;

  delete axios.defaults.headers.common['client'];
  delete axios.defaults.headers.common['x-auth'];
};

export const transformInterface = (_interface, user) => {
  const _company = _interface._client || _interface._contractor;

  const is = {
    isContractor: !!_interface._contractor,
    isClient: !!_interface._client,
    isUser: _interface.type === INTERFACE_USER,
    isManager: [INTERFACE_CLIENT, INTERFACE_CONTRACTOR].includes(_interface.type),
    isTechnician: _interface.type === INTERFACE_TECHNICIAN,
    isMaster: _interface.type === INTERFACE_MASTER,
    isBlachere: _company?._id === '5d91c909038b500034223bfc',
    isPublic: _interface.isPublic
  };

  const type = _interface._client ? COMPANY_CLIENT : COMPANY_CONTRACTOR;

  return {
    ..._interface,
    ...is,
    _company: {
      ..._company,
      ...is,
      type,
      _configuration: {
        ...(_company?._configuration || {}),
        ...is,
        type
      },
      name: _interface?._client?.name || _interface?._contractor?.companyName,
      companyName: null
    },
    _client: null,
    _contractor: null
  };
};

export const transformUser = (user) => {
  setCrispUser(user);
  return {
    ...user,
    interfaces: user._clients.map((i) => transformInterface(i, user)),
    _clients: null
  };
};

export const setToken = (state, token) => {
  localStorage.setItem(ACCESS_TOKEN, token);
  axios.defaults.headers.common['x-auth'] = token;
  state.accessToken = token;
};
