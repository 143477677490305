import { useEffect, useState } from 'react';
import { Grid, MenuItem, TextField } from '@material-ui/core';
import Role from 'entities/Role/Role';
import Location from 'entities/Location/Location';
import { Field, useField } from 'frmx';
import PhoneField from 'components/inputs/PhoneField';
import EntityX from 'components/inputs/EntityX';
import InterfaceForm from 'components/Interface/InterfaceForm';
import { useTranslation } from 'react-i18next';
import { isUserFeatureActivated } from './UserFormValues';
import { useConfiguration } from 'hooks/useConfiguration';
import { useAuth } from 'hooks/useAuth';
import { languages } from 'config/languages';
import Flag from 'react-flagkit';
import { flag_keys } from 'constants/locales';
import useKeys from '@flowsn4ke/usekeys';

export default function UserForm({ isCreate, element }) {
  const { value: email, setValue: setEmail, error: emailError } = useField('email');

  const { t } = useTranslation();
  const config = useConfiguration();

  return (
    <>
      <Grid
        item
        xs={12}
        sm={6}
      >
        <Field path="firstName">
          <TextField
            autoFocus
            variant="outlined"
            label={t('firstName')}
            fullWidth
          />
        </Field>
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
      >
        <Field path="lastName">
          <TextField
            variant="outlined"
            label={t('lastName')}
            fullWidth
          />
        </Field>
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
      >
        <TextField
          error={emailError}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          variant="outlined"
          label={t('email')}
          helperText={emailError ? t('noEmailForAccountHelperText') : ''}
          fullWidth
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
      >
        <LangSelect
          path="lang"
          label={t('lang')}
        />
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
      >
        <PhoneField
          path="phone"
          label={t('cellphoneNumber')}
          variant="outlined"
          placeholder={t('cellphoneNumber')}
        />
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
      >
        <PhoneField
          path="fix"
          label={t('landlineNumber')}
          variant="outlined"
          placeholder={t('landlineNumber')}
        />
      </Grid>

      <Grid
        item
        xs={12}
      >
        <EntityX
          errorHelperText={t('pleaseSelectARole')}
          path="_role"
          entity={Role}
          entityName="roles"
          entityProps={{ defaultFilters: { types: ['user'] } }}
          placeholder={t('role')}
          pickerUniq
        />
      </Grid>

      <div className="mx-4 p-2 text-gray-600 text-xs w-full border-b my-2">{t('visibility-scope')}</div>

      <Grid
        item
        xs={12}
      >
        <EntityX
          path="_locations"
          entityName={'locations'}
          entity={Location}
          placeholder={t('locations')}
        />
      </Grid>

      {isUserFeatureActivated(config) && (
        <Grid
          item
          xs={12}
        >
          <InterfaceForm
            isCreate={isCreate}
            element={element}
          />
        </Grid>
      )}
    </>
  );
}

export function LangSelect({ path, label }) {
  const { value, setValue } = useField(path);
  const auth = useAuth();
  const k = useKeys();
  const defaultLang = value || auth.interface?._company?.lang;

  useEffect(() => {
    if (!value) {
      setValue(defaultLang);
    }
  }, [defaultLang]);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <TextField
      select
      variant="outlined"
      label={label}
      fullWidth
      defaultValue={defaultLang}
      onChange={handleChange}
    >
      {languages.map((language, i) => (
        <MenuItem
          key={k(i)}
          value={language.key}
          className="p-3 hover:bg-gray-100 cursor-pointer rounded-md text-base"
        >
          <div className="flex items-center gap-2">
            <Flag
              country={flag_keys[language.key]}
              className="h-3"
            />
            {language.label}
          </div>
        </MenuItem>
      ))}
    </TextField>
  );
}
