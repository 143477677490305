import isEmail from 'validator/lib/isEmail';

const lexer = (input = '') => {
  const tokens = input?.split(/(\s+)/gm);
  return tokens;
};

const parser = (tokens = []) => {
  let html = '';

  for (const token of tokens) {
    const regex_url =
      /\b((https?:\/\/)?((?:www|\S+)\.[^\s]+|\b(localhost|local)\b|\b(?:\d{1,3}\.){3}\d{1,3}\b))[^\s]*\b/gi;
    const is_url = regex_url.test(token);

    if (isEmail(token)) {
      html += `<a href="mailto:${token}" style="color: #003366; text-decoration: underline;">${token}</a>`;
    } else if (is_url) {
      const href = token.startsWith('http') ? token : 'http://' + token;
      html += `<a href="${href}" target="_blank" rel="noopener noreferrer" style="color: #003366; text-decoration: underline;">${token}</a>`;
    } else {
      html += `<span>${token}</span>`;
    }
  }

  return html;
};

export default function linkify_text(input = '') {
  const tokens = lexer(input);
  const html = parser(tokens);

  return html;
}
