const ContractorExport = (configuration) =>
  [
    { label: 'Nom de la société', key: 'companyName', show: true },
    { label: 'Prénom', key: 'firstName', show: true },
    { label: 'Nom', key: 'lastName', show: true },
    { label: 'Numéro de Téléphone', key: 'phone', show: true },
    { label: 'Email', key: 'email', show: true },
    { label: 'Métiers', key: '_jobs', show: true },
    { label: 'Interface activé', key: 'userInterface', show: true },
    { label: 'Description', key: 'description', show: true },
    { label: 'SIRET', key: 'siret', show: true },
    { label: "Présent dans l'annuaire", key: 'marketplace', show: true }
  ].filter((e) => e.show);

export default ContractorExport;
