import ContractorTabs from 'entities/Contractor/ContractorTabs';
import AutoAssignation from 'entities/Intervener/Tabs/AutoAssignation';
import Maintenance from 'entities/Maintenance/Maintenance';
import Ticket from 'entities/Ticket/Ticket';

const TechnicianTabs = (configuration, element, role, auth) =>
  !!element &&
  (element.isContractor
    ? ContractorTabs(configuration, element, role, auth)
    : [
        {
          label: 'entityTicketName',
          icon: 'files',
          count: element.tabs?.tickets,
          show: true,
          render: () => (
            <Ticket
              disableGutters
              defaultFilters={{ interveners: [element] }}
            />
          )
        },
        {
          label: 'menuItemMaintenances',
          icon: 'heart-pulse',
          count: element.tabs?.maintenances,
          show: configuration.feature.preventif,
          render: () => (
            <Maintenance
              disableGutters
              defaultFilters={{ interveners: [element] }}
            />
          )
        },
        {
          label: 'automatisationsTitle',
          icon: 'bolt',
          iconCollection: 'fas',
          count: element?.autoassigns?.length || 0,
          show: role?.permission('automatisations', 'read'),
          render: () => (
            <AutoAssignation
              intervener={element}
              kind="Technician"
            />
          )
        }
      ].filter((e) => e.show));

export default TechnicianTabs;
