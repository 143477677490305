import { Box, List, Fab } from '@material-ui/core';
import { Empty } from 'layouts/entities/List';
import { useEffect, useState } from 'react';
import FAIcon from 'components/ui/FAIcon';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import { useTranslation } from 'react-i18next';
import { getAutomations } from 'store/managersSlice';
import ModalAutomation from './ModalAutomation';
import { useRole } from 'hooks/useRole';
import { AutomationActions } from './AutomationActions';

const AutomationManager = ({ manager, company_type }) => {
  const [openModal, setOpenModal] = useState(false);
  const [currentAutomation, setCurrentAutomation] = useState(null);
  const isContractor = company_type === 'contractor';

  const { t } = useTranslation();
  const { dispatch } = useAsyncDispatch();

  useEffect(() => {
    dispatch(getAutomations, {}, {}, { id: manager._id });
  }, [dispatch, manager._id]);

  const handleOpenModal = (automation = null) => {
    setCurrentAutomation(automation);
    setOpenModal(true);
  };

  const renderAutomations = () => (
    <>
      <List style={{ paddingTop: 0 }}>
        {manager?.automations?.map((automation, i) => (
          <AutomationActions
            key={i}
            automation={automation}
            setCurrentAutomation={setCurrentAutomation}
            setOpenModal={setOpenModal}
            manager={manager}
            isContractor={isContractor}
          />
        ))}
      </List>
      <AddButton onClick={() => handleOpenModal()} />
    </>
  );

  const renderEmptyState = () => (
    <>
      <Empty
        icon="bolt"
        collection="fas"
        translations={{
          noResultLabel: t('empty-automatisations'),
          noResultText: t('empty-text-automatisation')
        }}
      />
      <AddButton onClick={() => handleOpenModal()} />
    </>
  );

  return (
    <Box style={{ backgroundColor: 'white', height: '100%' }}>
      <ModalAutomation
        open={openModal}
        onClose={() => setOpenModal(false)}
        manager={manager}
        isContractor={isContractor}
        automation={currentAutomation}
      />
      {manager?.automations?.length > 0 ? renderAutomations() : renderEmptyState()}
    </Box>
  );
};

export default AutomationManager;

const AddButton = ({ onClick }) => {
  const { permission } = useRole();

  return (
    <Fab
      disabled={!permission('automatisations', 'create')}
      color="primary"
      size="small"
      style={{
        position: 'absolute',
        bottom: 16,
        right: 16
      }}
      onClick={onClick}
    >
      <FAIcon icon="plus" />
    </Fab>
  );
};
