const MuiLinearProgress = {
  root: {
    borderRadius: '3px',
    overflow: 'hidden'
  },
  colorPrimary: {
    backgroundColor: '#E4E7EB'
  }
};

export default MuiLinearProgress;
