import FAIcon from 'components/ui/FAIcon';
import { VpnKey, Visibility } from '@material-ui/icons';
import { resetPassword } from 'utils/generateEntitySlice';

import { attachClient } from 'store/authSlice';

const ClientsMenu = (
  element,
  auth,
  dispatch,
  role,
  companyPermission,
  listId,
  setIsMenuLoading,
  notify
) => {
  // * We need to check if the element is contractor, it's only way to know if we are on client or contractor
  const parentType = element?.isContractor ? '_contractor' : '_client';
  const type = element?.isContractor ? 'contractor' : 'client';

  return [
    {
      label: 'resendConnectionLink',
      action: () =>
        dispatch(
          resetPassword,
          { email: element._user.email },
          { onSuccess: () => notify.success('Email envoyé'), onError: () => notify.error() },
          { id: element._id }
        ),
      icon: <VpnKey />,
      show: !!element._user && (auth.user.isBob || element.companyPermission)
    },
    {
      label: 'reinitializationLink',
      // TODO: Refactor to allow translation
      action: () =>
        prompt(
          'Lien de réinitialisation',
          'https://bob-desk.com/set-password/' + element._user.resetPasswordToken
        ),
      icon: <Visibility />,
      show: !!element._user && auth.user.isBob && !!element._user.resetPasswordToken
    },
    {
      label: 'attachToClient',
      action: () =>
        dispatch(attachClient, { attach: true, parentType, type }, {}, { id: element._id }),
      icon: (
        <FAIcon
          collection={'fas'}
          icon={'portal-enter'}
        />
      ),
      show:
        !!element._user &&
        auth.user.isBob &&
        !auth.user.interfaces.find((i) => i._company._id === element._id)
    },
    {
      label: 'unattachFromClient',
      action: () =>
        dispatch(attachClient, { attach: false, parentType, type }, {}, { id: element._id }),
      icon: (
        <FAIcon
          collection={'fas'}
          icon={'portal-exit'}
        />
      ),
      show:
        !!element._user &&
        auth.user.isBob &&
        auth.user.interfaces.find((i) => i._company._id === element._id)
    }
  ].filter((e) => e.show);
};

export default ClientsMenu;
