import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'utils/classNames';

const iconClasses = {
  '2xs': 'h-[6px] text-[6px]',
  xs: 'h-[9px] text-[9px]',
  sm: 'h-[14px] text-[14px]',
  small: 'h-[14px] text-[14px]',
  md: 'h-[18px] text-[18px]',
  medium: 'h-[18px] text-[18px]',
  lg: 'h-[22px] text-[22px]',
  large: 'h-[22px] text-[22px]',
  xl: 'h-[32px] text-[32px]',
  '2xl': 'h-[48px] text-[48px]',
  '3xl': 'h-[64px] text-[64px]'
};

export default function FAIcon({
  collection = 'fal',
  icon = 'asterisk',
  className: userClassName,
  size = 'medium',
  noIconClass,
  ...rest
}) {
  return (
    <FontAwesomeIcon
      className={classNames(!noIconClass ? 'p-[2px]' : '', iconClasses[size], userClassName)}
      fixedWidth
      icon={[collection, icon || 'asterisk']}
      {...rest}
    />
  );
}
