import MuiButton from './MuiButton';
import MuiChip from './MuiChip';
import MuiFab from './MuiFab';
import MuiLinearProgress from './MuiLinearProgress';
import MuiListItemIcon from './MuiListItemIcon';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiSvgIcon from './MuiSvgIcon';
import MuiTab from './MuiTab';
import MuiTableCell from './MuiTableCell';
import MuiTableRow from './MuiTableRow';
import MuiTabs from './MuiTabs';
import MuiAccordionDetails from './MuiAccordionDetails';
import { secondary } from 'config/palettes';

const overrides = (theme) => ({
  MuiButton,
  MuiChip,
  MuiFab,
  // MuiFilledInput,
  // MuiInputBase,
  MuiLinearProgress,
  MuiListItemIcon,
  MuiOutlinedInput,
  MuiSvgIcon,
  MuiTab,
  MuiTableCell,
  MuiTableRow,
  MuiTabs,
  MuiTabScrollButton: {
    root: {
      ['&.Mui-disabled']: {
        opacity: 0.3
      }
    }
  },
  MuiBadge: {
    root: {
      ['&.right']: {
        ['& > span']: {
          top: 10,
          right: -18
        }
      },
      ['&.relativeRight']: {
        position: 'inherit',
        ['& > span']: {
          top: 24,
          right: 58
        }
      }
    }
  },
  MuiSelect: {
    iconOutlined: {
      right: 12
    }
  },
  MuiAutocomplete: {
    paper: {
      border: '1px solid #c4c4c4'
    }
  },
  MuiList: {
    root: {
      ['&.MuiMenu-list']: {
        padding: 6,
        ['& .MuiListItem-root']: {
          margin: '.2em 0em',
          borderRadius: theme.radius
        }
      }
    }
  },
  MuiListItemSecondaryAction: {
    root: {
      right: 6
    }
  },
  MuiAccordionSummary: {
    content: {
      margin: 0,
      ['&.Mui-expanded']: {
        margin: 0
      }
    }
  },
  MuiPickersStaticWrapper: {
    staticWrapperRoot: {
      width: '100%'
    }
  },
  MuiPickersBasePicker: {
    pickerView: {
      width: '100%',
      maxWidth: 'auto'
    }
  },
  MuiMenu: {
    paper: {
      ['& svg']: {
        fontSize: 14,
        cursor: 'pointer'
      },
      ['& .MuiListItem-root']: {
        paddingLeft: 6
      }
    }
  },
  MuiCssBaseline: {
    '@global': {
      '.MuiAccordion-rounded:last-child': {
        borderRadius: theme.radius
      },
      '.MuiAccordion-rounded:first-child': {
        borderRadius: theme.radius
      }
    }
  },
  MuiListItemText: {
    primary: {
      minHeight: 16
    }
  },
  MuiAccordionDetails,
  MuiAppBar: {
    root: {
      borderRadius: 0
    }
  },
  MuiBottomNavigationAction: {
    root: {
      maxWidth: 'auto'
    }
  },
  MuiPaper: {
    root: {
      borderRadius: theme.radius
    },
    rounded: {
      borderRadius: theme.radius
    }
  },
  MuiToggleButtonGroup: {
    root: {
      borderRadius: theme.radius
    }
  },
  MuiToggleButton: {
    root: {
      borderRadius: 16,
      ['&.Mui-selected']: {
        color: 'white',
        backgroundColor: secondary.main
      },
      ['&:hover']: {
        opacity: 0.9
      }
    }
  },
  MuiAccordion: {
    root: {
      ['&.Mui-expanded']: {
        ['& .relativeRight']: {
          position: 'inherit',
          ['& > span']: {
            top: '30px !important',
            right: '58px !important'
          }
        }
      },
      ['&.outlined']: {
        boxShadow: 'none',
        borderRadius: theme.radius,
        border: '1px solid #c4c4c4'
      },
      borderRadius: theme.radius,
      ['&.rounded:first-child']: {
        borderRadius: theme.radius
      }
    },
    ['rounded']: {
      borderRadius: theme.radius,
      ['&:first-child']: {
        borderBottomLeftRadius: 0,
        borderRadius: theme.radius
      }
    }
  },
  MuiDrawer: {
    root: {
      borderRadius: 0
    },
    paper: {
      borderRadius: 0
    }
  }
});

export default overrides;
