import { DialogTitle, Drawer, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import FAIcon from 'components/ui/FAIcon';
import { useEntity } from 'contexts/entities/entityContext';

import useStyles from 'components/dialogs/Dialog.styles';
import { useTranslation } from 'react-i18next';
import EntityForm from './EntityForm';
import classNames from 'utils/classNames';

export default function EntityFormDialog({ open, onClose, element, isCreate }) {
  const { icon, translations } = useEntity();
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Drawer
      open={!!open}
      onClose={onClose}
      anchor="right"
      elevation={0}
      classes={{
        paper: classNames(classes.drawer, classes.drawerContainer)
      }}
    >
      <DialogTitle
        disableTypography
        className="h-16 flex items-center text-black font-medium text-sm bg-white pr-4 drop-shadow rounded-t-lg"
      >
        <FAIcon
          icon={icon}
          collection="fad"
          className="text-black mr-2"
        />
        {isCreate ? t(translations.createDialog) : t(translations.updateDialog)}
        <IconButton
          id="close-form-button"
          onClick={onClose}
          className={classes.titleClose}
          style={{ color: 'black' }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {!!open && (
        <EntityForm
          element={element}
          isCreate={isCreate}
          onSuccess={onClose}
        />
      )}
    </Drawer>
  );
}
