import {
  getElement,
  getElements,
  createElement,
  updateElement,
  flushElements,
  deleteElement,
  restoreElement
} from 'store/rolesSlice';

import RoleMenus from './RoleMenus';
import RolePreview from './RolePreview';
import RoleSkeleton from './RoleSkeleton';
import RoleView from './RoleView';
import RoleTabs from './RoleTabs';
import RoleFilters, { formatFilter } from './RoleFilters';
import RoleFormValues, { RoleValidation } from './RoleFormValues';
import RoleTranslations from './RoleTranslations';
import RoleExport from './RoleExport';
import RoleForm from './RoleForm';
import RoleSorts from './RoleSorts';

import EntityWrapper from 'entities/EntityWrapper';

export default function Role(props) {
  return (
    <EntityWrapper
      entity={'roles'}
      updateOnClick
      skeletonComponent={RoleSkeleton}
      previewComponent={RolePreview}
      viewComponent={RoleView}
      formComponent={RoleForm}
      formatFilter={formatFilter}
      getFilters={RoleFilters}
      getInitialValues={RoleFormValues}
      getFormValidation={RoleValidation}
      getTabs={RoleTabs}
      getTables={() => []}
      getMenus={RoleMenus}
      getExportColumns={RoleExport}
      getTranslations={RoleTranslations}
      getSorts={RoleSorts}
      getElement={getElement}
      getElements={getElements}
      createElement={createElement}
      updateElement={updateElement}
      flushElements={flushElements}
      deleteElement={deleteElement}
      restoreElement={restoreElement}
      {...props}
    />
  );
}
