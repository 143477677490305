import { createSlice } from '@reduxjs/toolkit';
import generateAsyncThunk from 'utils/generateAsyncThunk';
import { generateEntityBoilerplate } from 'utils/generateEntitySlice';
import { trigger } from 'react-events-utils';

export const createElement = generateAsyncThunk({ type: 'POST', endpoint: 'clients' });
export const updateElement = generateAsyncThunk({ type: 'PUT', endpoint: 'clients/${id}' });
export const getElement = generateAsyncThunk({ type: 'GET', endpoint: 'clients/${id}' });
export const getElements = generateAsyncThunk({ type: 'POST', endpoint: 'clients/list' });
export const deleteElement = generateAsyncThunk({ type: 'DELETE', endpoint: 'clients/${id}' });
export const restoreElement = generateAsyncThunk({
  type: 'POST',
  endpoint: 'clients/recycle/${id}'
});

const entityBoilerplate = generateEntityBoilerplate({
  createElement,
  updateElement,
  getElement,
  getElements,
  deleteElement,
  restoreElement,
  createElementNext: (state, action) => {
    const { element } = action.payload.data;
    const { parentLocation } = action.meta.arg;

    if (parentLocation && state.db[parentLocation]) {
      state.db[parentLocation]._locations.push(element._id);
    }

    if (!element.address) {
      trigger('clientCreateWithAddress');
    }
  }
});

const { initialState, reducers, extraReducers } = entityBoilerplate;

export const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers,
  extraReducers: {
    ...extraReducers,
  }
});

export const { flushElements, flushElement } = clientsSlice.actions;
export default clientsSlice.reducer;
