import { useState } from 'react';

export default function useClipboard() {
  const [copied, setCopied] = useState(false);

  const copy = (text) => {
    if (navigator.clipboard) {
      setCopied(true);
      navigator.clipboard.writeText(text);
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    }
  };

  return { copy, copied };
}
