import classNames from 'classnames';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';

export default function ButtonGroup({ value, onChange = () => {}, options = [] }) {
  const { t } = useTranslation();

  return (
    <span className="isolate inline-flex rounded-md shadow-sm">
      {options.map((option, idx) => {
        return (
          <button
            key={nanoid()}
            type="button"
            onClick={() => {
              const next = value.includes(option.value)
                ? value.filter((o) => o !== option.value)
                : [...value, option.value];
              onChange(next);
            }}
            className={classNames(
              'relative inline-flex items-center px-3 py-2 text-sm',
              idx === 0 ? 'rounded-l-md' : '-ml-px',
              idx === options.length - 1 && 'rounded-r-md',
              value.includes(option.value)
                ? 'bg-gray-200 hover:bg-gray-300 text-gray-900 ring-1 ring-inset ring-gray-300 focus:z-10'
                : 'bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10'
            )}
          >
            {/* <input type="checkbox" className="mr-1.5 w-4 h-4 text-sky-600 bg-gray-100 border-gray-300 rounded" /> */}
            {t(option.label)}
          </button>
        );
      })}
    </span>
  );
}
