import FieldBase from './fields/FieldBase';
import EmptyField from './fields/EmptyField';
import PreFilledValuesFields from './fields/PreFilledValuesField';
import TextField from './fields/TextField';
import TextAreaField from './fields/TextAreaField';
import OptionsField from './fields/OptionsField';
// import ImageField from './ImageField'
import TitleField from './fields/TitleField';
import CustomCheckbox from 'fieldSections/fieldComponents/CustomCheckbox';
import CustomText from 'fieldSections/fieldComponents/CustomText';
import CustomNumber from 'fieldSections/fieldComponents/CustomNumber';
import CustomDateTime from 'fieldSections/fieldComponents/CustomDateTime';
import CustomDate from 'fieldSections/fieldComponents/CustomDate';
import CustomTextArea from 'fieldSections/fieldComponents/CustomTextArea';
import CustomMultipleSelect from 'fieldSections/fieldComponents/CustomMultipleSelect';
import CustomRadio from 'fieldSections/fieldComponents/CustomRadio';
import CustomUniqueSelect from 'fieldSections/fieldComponents/CustomUniqueSelect';
import CustomNotation from 'fieldSections/fieldComponents/CustomNotation';
import CustomTitle from './fieldComponents/CustomTitle';
// import CustomNote from 'fieldSections/fieldComponents/CustomNote'

// TODO: REMOVE DUPLICATES (3 SELECTS => 2, 3 TEXT AREAS => 1)
export const fieldTypesReport = {
  autofill: {
    label: 'preFilled',
    component: <PreFilledValuesFields />,
    fieldComponent: null,
    icon: 'pen-field',
    reportOnly: true,
    custom: true
  },
  date: {
    label: 'date',
    component: (
      <FieldBase
        lineSizeOption
        mandatoryOption
      />
    ),
    fieldComponent: CustomDate,
    icon: 'calendar-week',
    custom: true
  },
  date_begin: {
    label: 'arrivalDateAndTime',
    component: (
      <FieldBase
        lineSizeOption
        mandatoryOption
      />
    ),
    fieldComponent: CustomDateTime,
    icon: 'calendar-week',
    custom: true
  },
  date_finish: {
    label: 'departureDateAndTime',
    component: (
      <FieldBase
        lineSizeOption
        mandatoryOption
      />
    ),
    fieldComponent: CustomDateTime,
    icon: 'calendar-week',
    custom: true
  },
  datetime: {
    label: 'dateAndTime',
    component: (
      <FieldBase
        lineSizeOption
        mandatoryOption
      />
    ),
    fieldComponent: CustomDateTime,
    icon: 'calendar-week',
    custom: true
  },
  empty_space: {
    label: 'emptySpace',
    component: <EmptyField />,
    fieldComponent: null,
    icon: 'distribute-spacing-vertical',
    reportOnly: true,
    custom: true
  },
  // TODO: Handle images
  // image: {
  //   label: 'Image(s)',
  //   component: <ImageField />,

  //   fieldComponent: null,
  // custom: true,
  // reportOnly: true,
  // },
  multipleSelect: {
    label: 'optionMultipleChoice',
    component: <OptionsField mandatoryOption />,
    fieldComponent: CustomMultipleSelect,
    icon: 'ballot-check',
    custom: true
  },
  select: {
    label: 'optionUniqueChoice',
    component: <OptionsField mandatoryOption />,
    fieldComponent: CustomUniqueSelect,
    icon: 'list-ul',
    custom: true
  },
  radio: {
    label: 'radioSelection',
    component: (
      <OptionsField
        mandatoryOption
        lineSizeOption
      />
    ),
    fieldComponent: CustomRadio,
    icon: 'list-ul',
    custom: true
  },
  checkbox: {
    label: 'checkbox',
    component: <FieldBase lineSizeOption />,
    fieldComponent: CustomCheckbox,
    icon: 'square-check',
    custom: true
  },
  number: {
    label: 'number',
    component: (
      <FieldBase
        lineSizeOption
        mandatoryOption
      />
    ),
    fieldComponent: CustomNumber,
    icon: 'arrow-up-1-9',
    custom: true
  },
  rating: {
    label: 'rating',
    component: (
      <FieldBase
        lineSizeOption
        mandatoryOption
      />
    ),
    fieldComponent: CustomNotation,
    icon: 'star',
    custom: true
  },
  string: {
    label: 'text',
    component: <TextField lineSizeOption />,
    fieldComponent: CustomText,
    icon: 'text',
    custom: true
  },
  textarea: {
    label: 'textArea',
    component: <TextAreaField lineSizeOption />,
    fieldComponent: CustomTextArea,
    icon: 'text',
    custom: true
  },
  title: {
    label: 'title',
    component: <TitleField />,
    fieldComponent: CustomTitle,
    icon: 'heading',
    custom: true
  }
};
