import {
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@material-ui/core';
import { useField } from 'frmx';

import { useTranslation } from 'react-i18next';

export default function ReportFilter({ filter }) {
  const { value: field, setValue: setField } = useField(filter.key);

  const { t } = useTranslation();

  return (
    <Box
      width="100%"
      height="100%"
      padding="16px"
      display="flex"
      flexDirection="column"
    >
      <FormControl component="fieldset">
        <FormLabel component="legend">Rapports</FormLabel>
        <RadioGroup
          style={{ marginBottom: 10, flexDirection: 'row', alignItems: 'center' }}
          value={field.ir}
          onChange={(e) => setField({ ...field, ir: +e.target.value })}
        >
          <FormControlLabel
            value={0}
            control={<Radio />}
            label={t('withOrWithout')}
          />
          <FormControlLabel
            value={1}
            control={<Radio />}
            label={t('with')}
          />
          <FormControlLabel
            value={2}
            control={<Radio />}
            label={t('without')}
          />
        </RadioGroup>
      </FormControl>

      {field.ir === 1 && (
        <FormControl component="fieldset">
          <FormLabel component="legend">Réserves</FormLabel>
          <RadioGroup
            style={{ flexDirection: 'row', alignItems: 'center' }}
            value={field.reserve}
            onChange={(e) => setField({ ...field, reserve: +e.target.value })}
          >
            <FormControlLabel
              value={0}
              control={<Radio />}
              label={t('withOrWithout')}
            />
            <FormControlLabel
              value={1}
              control={<Radio />}
              label={t('with')}
            />
            <FormControlLabel
              value={2}
              control={<Radio />}
              label={t('without')}
            />
          </RadioGroup>
        </FormControl>
      )}
    </Box>
  );
}
