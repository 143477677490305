import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({

  item: {
    background: 'white !important',
    border: '2px solid ' + theme.palette.secondary.main + ' !important',
    borderRadius: `${theme.radius}px !important`,
    outline: 'none !important',
    color: '#4f5b62 !important'
  },

  itemAlert: {
    border: `2px solid #d35400 !important`
  },

  itemLate: {
    border: `2px solid #c0392b !important`
  },

  itemClosed: {
    outline: 'none !important',
    border: `2px solid ${theme.palette.secondary.light} !important`,
    backgroundColor: `${theme.palette.secondary.dark} !important`
  },

  itemReserveUrgency1: {
    outline: 'none !important',
    backgroundColor: `#FBB034 !important`
  },
  itemReserveUrgency2: {
    outline: 'none !important',
    backgroundColor: `#EE744A !important`
  },
  itemReserveUrgency3: {
    outline: 'none !important',
    backgroundColor: `#E63D53 !important`
  },

  itemChecked: {
    border: `2px solid ${'#2eff75'} !important`
  },

  timeline: {
    position: 'relative',
    height: '100%',
    ['& .rct-dateHeader:not(.rct-dateHeader-primary) > span']: {
      fontSize: '11 !important'
    },
    ['& .rct-dateHeader']: {
      borderTop: '1px solid #bbbbbb !important'
    },
    ['& .rct-sidebar > div:nth-of-type(1)']: {
      borderRight: '1px solid #bbbbbb !important'
    },
    ['& .rct-header-root > div:nth-of-type(1)']: {
      borderRight: '2px solid #bbbbbb !important'
    },
    ['& .rct-calendar-header']: {
      border: 'none !important'
    },
    ['& .rct-sidebar']: {
      border: 'none !important'
    },
    ['& .rct-scroll']: {
      overflowX: 'hidden !important'
    },
    ['& .rct-sidebar-row']: {
      padding: '0px !important',
      borderRight: '1px solid #bbbbbb !important'
    },
    ['& .rct-header-root']: {
      background: 'white !important'
    },
    ['& .rct-dateHeader-primary']: {
      border: 'none !important',
      fontWeight: '500 !important',
      fontSize: '12 !important',
      color: '#4f5b62 !important',
      textTransform: 'capitalize !important'
    }
  }
}));
