import { Button, TextField, Typography, Box } from '@material-ui/core';
import FAIcon from 'components/ui/FAIcon';

import { isEmail } from 'validator';

import useAsyncDispatch from 'hooks/useAsyncDispatch';
import { useIsBelowSm } from 'hooks/useMQ';
import { Form, Submit, Field } from 'frmx';

import Check from 'libs/swal/Check';

import usePortalStyles from './Portal.styles';
import { useTranslation } from 'react-i18next';
import { resetPassword } from 'utils/generateEntitySlice';
import classNames from 'utils/classNames';

export default function OldResetPasswordPage() {
  const { dispatch, requestStatus } = useAsyncDispatch();
  const isBelowSm = useIsBelowSm();

  const classes = usePortalStyles({ isBelowSm });
  const { t } = useTranslation();

  return (
    <Form
      render="form"
      initialValues={{
        email: ''
      }}
      schemaValidation={{
        email: (val, formData) => isEmail(val)
      }}
      onSubmit={(formData) => dispatch(resetPassword, formData)}
      disableIfInvalid
    >
      <Typography
        variant="subtitle1"
        className={classes.message}
      >
        {requestStatus === 'success' ? t('checkYourInbox') : t('resetPasswordPageSubtitle')}
      </Typography>

      {requestStatus === 'success' ? (
        <Check />
      ) : (
        <Field
          path="email"
          type="email"
          required
        >
          <TextField
            required
            variant="outlined"
            label={t('emailInputLabel')}
            className={classes.spacing}
          />
        </Field>
      )}

      <Submit disabled={requestStatus === 'loading' || requestStatus === 'success'}>
        <Button
          className={classNames(classes.spacing, classes.submitButtonContainer)}
          variant="contained"
          color="primary"
          disableElevation
        >
          <Typography>
            {t(
              requestStatus === 'success'
                ? 'successResetPasswordLinkButton'
                : 'sendResetPasswordLinkButton'
            )}
          </Typography>

          <FAIcon
            collection={'fas'}
            icon={requestStatus === 'loading' ? 'spinner' : 'mailbox'}
            className={requestStatus === 'loading' && classes.spinner}
            size="medium"
          />
        </Button>
      </Submit>

      {requestStatus === 'error' && (
        <Box>
          <Typography
            variant="body2"
            color="error"
            className={classes.messageError}
          >
            {t('resetPasswordUnknownEmailMsg')}
          </Typography>
        </Box>
      )}
    </Form>
  );
}
