import { useState, useMemo, useCallback } from 'react';
import { MenuItem, TextField } from '@material-ui/core';
import { useForm } from 'frmx';
import { useTranslation } from 'react-i18next';
import countries from 'constants/countries';
import Flag from 'react-flagkit';

const nameFields = ['streetNumber', 'route', 'postalCode', 'city'];

export default function ManualLocationField({ autoFocus, path, setValue: setAddress }) {
  const { getOneField } = useForm();
  const { t } = useTranslation();

  const initialFieldValue = useMemo(() => {
    const field = getOneField(path);
    return field?.fullAddress?.length > 0 ? field : {};
  }, [path, getOneField]);

  const [value, setValue] = useState(initialFieldValue);

  const updateFullAddress = (updatedValue) => {
    return `${updatedValue?.streetNumber || ''} ${updatedValue?.route || ''}, ${updatedValue?.postalCode || ''} ${
      updatedValue?.city || ''
    }, ${updatedValue?.country || ''}`;
  };

  const handleInputChange = useCallback(
    (e) => {
      const { name, value: fieldValue } = e.target;

      setValue((prev) => {
        const updatedValue = { ...prev, [name]: fieldValue, lng: '', lat: '' };
        return { ...updatedValue, fullAddress: updateFullAddress(updatedValue) };
      });

      setAddress((prev) => {
        const updatedAddress = { ...prev, [name]: fieldValue, lng: '', lat: '' };
        return { ...updatedAddress, fullAddress: updateFullAddress(updatedAddress) };
      });
    },
    [setAddress]
  );

  return (
    <div className="grid grid-cols-3 gap-4">
      {nameFields.map((field) => (
        <TextField
          key={field}
          name={field}
          autoFocus={field === 'streetNumber' && autoFocus}
          variant="outlined"
          value={value?.[field] || ''}
          label={t(field)}
          onChange={handleInputChange}
          className={field === 'route' ? 'col-span-2' : ''}
        />
      ))}
      <TextField
        select
        name="country"
        variant="outlined"
        value={value?.country || ''}
        onChange={handleInputChange}
        label={t('country')}
        className="flex"
      >
        {countries.map((country, i) => (
          <MenuItem
            key={i}
            value={country.name}
            className="flex items-center gap-2"
          >
            <div className="flex items-center gap-2">
              <Flag country={country.code} />
              {t(country.name)}
            </div>
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
}
