import MeterPricing from './Tabs/MeterPricing';
import MeterReadings from './Tabs/MeterReadings';

export default function MeterTabs(configuration, element, role, auth) {
  const tabs = [
    // TODO add charts for the future
    // {
    //   label: 'charts',
    //   icon: 'chart-mixed',
    //   show: true,
    //   render: () => <div>TODO STATS</div>,
    //   class: 'tabNoPadding',
    // },
    {
      label: 'readingsTitle',
      icon: 'book-open',
      show: role.permission('meters', 'read-reading'),
      render: (element) => <MeterReadings meter_id={element?._id} />,
      class: 'tabNoPadding'
    },
    {
      label: 'pricing',
      icon: 'wallet',
      show: role.permission('meters', 'read-pricing'),
      render: () => <MeterPricing meter_id={element?._id} />,
      class: 'tabNoPadding'
    }
  ].filter((e) => e.show);

  return tabs;
}
