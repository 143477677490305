export const defaultTicketFields = [
  { path: '_client.name', label: 'clientName', valueType: 'string' },
  { path: '_location', label: 'location', valueType: 'select' },
  { path: 'number', label: 'ticketNumber', valueType: 'number' },
  { path: 'title', label: 'ticketTitle', valueType: 'string' },
  { path: 'description', label: 'description', valueType: 'textarea' },
  { path: '_job', label: 'job', valueType: 'select' },
  { path: '_users_client', label: 'managers', valueType: 'multipleSelect' },
  { path: 'visit_date', label: 'visitDate', valueType: 'datetime' },
  { path: 'intervention_date', label: 'interventionDate', valueType: 'datetime' }
];
export const defaultLocationFields = [
  {
    path: 'type',
    label: 'type',
    valueType: 'select',
    extra: { options: ['Lieu', 'Immeuble', 'Appartement', 'Parking', 'Boutique', 'Objet'] }
  },
  { path: 'address.fullAddress', label: 'address', valueType: 'string' },
  { path: 'address.postalCode', label: 'zipCode', valueType: 'string' },
  { path: 'name', label: 'locationName', valueType: 'string' },
  { label: 'additionalInformation', valueType: 'textarea' }, //TODO: Missing path property
  { path: '_facturation.address.fullAddress', label: 'billingAddress', valueType: 'checkbox' },
  { path: 'isSameFacturation', label: 'identicalBillingAddress', valueType: 'checkbox' },
  { path: 'isPrio', label: 'priorityLocation', valueType: 'checkbox' }
];

// TODO: Missing path
export const defaulteEquipmentFields = [
  { path: 'name', label: 'equipmentName', valueType: 'name' },
  { label: 'category', valueType: '_category' },
  { label: 'locations', valueType: '_locations' },
  { label: 'jobs', valueType: '_jobs' },
  {
    path: 'state',
    label: 'state',
    valueType: 'select',
    extra: {
      options: ['Neuf', 'Tres bon état général', 'Moyen', 'Dégradé', 'Tres dégradé', "Hors d'usage"]
    }
  },
  { path: 'brand', label: 'brand', valueType: 'string' },
  { path: 'model', label: 'model', valueType: 'string' },
  { path: 'serial', label: 'serialNumber', valueType: 'serial' },
  { path: 'revision_date', label: 'revisionDate', valueType: 'date' },
  { path: 'year', label: 'year', valueType: 'string' },
  { path: 'buy_date', label: 'purchaseDate', valueType: 'date' }
];

// TODO: Path is missing
export const defaultTechnicianFields = [
  { label: 'firstName', valueType: 'string' },
  { label: 'lastName', valueType: 'string' },
  { label: 'phoneNumber', valueType: 'phone' },
  { label: 'email', valueType: 'string' },
  { label: 'description', valueType: 'string' },
  { label: 'particularity', valueType: 'string' },
  { label: 'jobs', valueType: '_jobs' }
];

export const defaultContractorFields = [
  { path: 'companyName', label: 'companyName', valueType: 'string' },
  { path: 'siret', label: 'siret', valueType: 'string' },
  { path: 'email', label: 'email', valueType: 'string' },
  { path: 'phone', label: 'cellphoneNumber', valueType: 'string' },
  { path: 'fix', label: 'landlineNumber', valueType: 'string' },
  { path: 'address', label: 'address', valueType: 'string' }
];

export const defaultUserFields = [
  { path: 'gender', label: 'gender', valueType: 'select' },
  { path: 'lastName', label: 'lastName', valueType: 'string' },
  { path: 'firstName', label: 'firstName', valueType: 'string' },
  { path: 'email', label: 'email', valueType: 'string' },
  { path: 'phone', label: 'phoneNumber', valueType: 'phone' },
  { label: 'role', valueType: 'multipleSelect' },
  { path: '_location', label: 'location', valueType: 'select' },
  { label: 'userInterface', valueType: 'checkbox' }
];

export const defaultClientFields = [
  { label: 'reference', path: 'ref', valueType: 'string' },
  { label: 'lastName', path: 'lastName', valueType: 'string' },
  { label: 'firstName', path: 'firstName', valueType: 'string' },
  { label: 'professionalName', path: 'name', valueType: 'string' },
  { label: 'email', path: 'email', valueType: 'string' },
  { label: 'phoneNumber', path: 'phone', valueType: 'phone' },
  { label: 'landlineNumber', path: 'fix', valueType: 'phone' },
  { label: 'description', path: 'description', valueType: 'textarea' }
];

export const defaultJobFields = [{ path: 'name', label: 'jobName', valueType: 'name' }];
export const defaultReportFields = [{ path: 'name', label: 'jobName', valueType: 'name' }];

export const defaultManagerFields = [
  { path: 'firstName', label: 'firstName', valueType: 'string' },
  { path: 'lastName', label: 'lastName', valueType: 'string' },
  { path: 'email', label: 'email', valueType: 'string' },
  { path: 'phone', label: 'cellphoneNumber', valueType: 'string' },
  { path: 'fix', label: 'landlineNumber', valueType: 'string' },
  { path: '_role', label: 'role', valueType: 'select' },
  { path: '_locations', label: 'location', valueType: 'multipleSelect' }
];

export const defaultGuaranteeFields = [
  { path: 'name', label: 'entityGuaranteeName', valueType: 'string' },
  { path: 'duration', label: 'duration', valueType: 'number' },
  {
    path: 'duration_type',
    label: 'durationType',
    valueType: 'select',
    options: ['months', 'years']
  },
  { path: 'start_date', label: 'start', valueType: 'datetime' }
];

export const defaultContractFields = [
  { path: 'name', label: 'entityContractName', valueType: 'string' },
  { path: 'duration', label: 'duration', valueType: 'number' },
  {
    path: 'duration_type',
    label: 'durationType',
    valueType: 'select',
    options: ['months', 'years']
  },
  { path: 'start_date', label: 'start', valueType: 'datetime' }
];

export const defaultLeaseFields = [
  { path: 'name', label: 'entityLeaseName', valueType: 'string' },
  { path: 'duration', label: 'duration', valueType: 'number' },
  {
    path: 'duration_type',
    label: 'durationType',
    valueType: 'select',
    options: ['months', 'years']
  },
  { path: 'start_date', label: 'start', valueType: 'datetime' }
];

export const defaultMaintenanceFields = [];

const defaultFields = {
  ticket: defaultTicketFields,
  location: defaultLocationFields,
  equipment: defaulteEquipmentFields,
  technician: defaultTechnicianFields,
  contractor: defaultContractorFields,
  user: defaultUserFields,
  maintenance: defaultMaintenanceFields,
  client: defaultClientFields,
  job: defaultJobFields,
  report: defaultReportFields,
  manager: defaultManagerFields,
  guarantee: defaultGuaranteeFields,
  contract: defaultContractFields,
  lease: defaultLeaseFields
};

export default defaultFields;
