import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 542,
    userSelect: 'none',
    [theme.breakpoints.down(542 + 10)]: {
      width: '100%',
      padding: 16
    }
  },
  hidden: {
    display: 'none'
  },
  avatar: {
    position: 'absolute',
    top: 10,
    left: 10,
    [theme.breakpoints.down(542 + 10)]: {
      display: 'none'
    }
  },
  paper: {
    width: '100%',
    backgroundColor: 'white',
    position: 'relative',
    color: 'white',
    borderBottomLeftRadius: theme.radius,
    borderBottomRightRadius: theme.radius,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center'
  },
  top: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 0,
    marginTop: 59,
    [theme.breakpoints.down(542 + 10)]: {
      marginTop: 24,
      flexDirection: 'column'
    }
  },
  spinner: {
    ...theme.spinnerAnimation,
    color: theme.palette.secondary.dark,
    position: 'absolute',
    right: 8,
    bottom: 8
  },
  logo_boomerang: {
    width: 64,
    position: 'absolute',
    top: -33,
    right: -14
  },
  logo_text: {
    marginLeft: 24,
    height: 64,
    position: 'relative',
    bottom: 10,
    [theme.breakpoints.down(542 + 10)]: {
      marginTop: 42
    }
  },
  splash: {
    width: '100%'
  },
  splash_mobile: {
    position: 'relative',
    bottom: 24
  },
  loaderContainer: {
    padding: '10px 24px'
  },
  loader: {
    boxShadow: theme.shadows[1],
    borderRadius: theme.radius
  },
  logo_mobile: {
    height: 86
  },
  loader_mobile: {
    marginTop: 26
  },
  label_mobile: {
    color: 'white',
    fontSize: 14,
    fontWeight: 500,
    marginTop: 16,
    textAlign: 'center'
  },
  label: {
    margin: '0px 0px 60px 0px',
    fontSize: 14,
    fontWeight: 500,
    width: '100%',
    textAlign: 'center',
    color: theme.palette.secondary.light
  },
  version: {
    right: 0,
    bottom: -22,
    color: 'white',
    fontSize: 10,
    fontWeight: 400,
    lineHeight: '11px',
    textAlign: 'right',
    fontFamily: 'Arial',
    position: 'absolute'
  }
}));
