import {
  SwipeableDrawer,
  Box,
  Slide,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Badge,
  Toolbar,
  Collapse,
  IconButton,
  ListItemSecondaryAction
} from '@material-ui/core';
import FAIcon from 'components/ui/FAIcon';

import { ExpandMore, ExpandLess, OpenInNew } from '@material-ui/icons';

import clsx from 'clsx';

import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAuth } from 'store/authSlice';
import { useIsBelowMd } from 'hooks/useMQ';

import { INTERFACE_MASTER } from 'constants/roles';
import { SIDEBAR_IS_LOCKED } from 'constants/localStorage';
import { masterExtraMenus } from 'config/menus';

import useStyles from './Sidebar.styles';
import { useTranslation } from 'react-i18next';
import { Back } from 'components/ui/Arrow';
import useKeys from '@flowsn4ke/usekeys';
import classNames from 'utils/classNames';
import { nanoid } from 'nanoid';

export default function Sidebar({
  sidebarIsOpen,
  setSidebarIsOpen,
  sidebarIsLocked,
  setSidebarIsLocked,
  sidebarIsMorphing,
  setSidebarIsMorphing,
  userMenus,
  contractPropositions
}) {
  const auth = useSelector(selectAuth);
  const history = useHistory();
  const isBelowMd = useIsBelowMd();

  const [secondaryMenuIsOpen, setSecondaryMenuIsOpen] = useState(false);

  const classes = useStyles({ isOpen: sidebarIsOpen, isBelowMd });
  const { t } = useTranslation();

  const k1 = useKeys();
  const k2 = useKeys();

  return (
    <SwipeableDrawer
      // disableBackdropTransition={!iOS}
      // disableDiscovery={iOS}
      open={sidebarIsOpen}
      onClose={() => setSidebarIsOpen(false)}
      onOpen={() => setSidebarIsOpen(true)}
      variant={isBelowMd ? 'temporary' : 'permanent'}
      className={clsx({
        [classes.drawerOpen]: sidebarIsOpen,
        [classes.drawerClose]: !sidebarIsOpen,
        [classes.drawer]: true
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: !isBelowMd && sidebarIsOpen,
          [classes.drawerClose]: !isBelowMd && !sidebarIsOpen,
          [classes.drawer]: true
        })
      }}
      onMouseEnter={() => {
        if (!sidebarIsLocked && !sidebarIsMorphing) {
          setSidebarIsMorphing(true);
          setSidebarIsOpen(true);
          setSidebarIsMorphing(false);
        }
      }}
      onMouseLeave={() => {
        if (!sidebarIsLocked && !sidebarIsMorphing) {
          setSidebarIsMorphing(true);
          setSidebarIsOpen(false);
          setSidebarIsMorphing(false);
        }
      }}
    >
      <Box className={classes.relativeContainer}>
        {isBelowMd ? (
          <Box
            boxShadow={2}
            className={classes.mobileSidebarTopBar}
          >
            <Toolbar className={classes.toolbar}>
              <IconButton
                edge="start"
                onClick={() => {
                  localStorage.removeItem(SIDEBAR_IS_LOCKED);
                  setSidebarIsLocked(false);
                  setSidebarIsOpen(false);
                }}
                className={classes.drawerButton}
              >
                {<Back className={classes.drawerButtonIcon} />}
              </IconButton>
              <div>
                <div className={classes.sidebarLogo}>
                  <img
                    alt="bob! desk logo"
                    src={'/assets/images/logo.svg'}
                    className={classes.logo}
                  />
                </div>
              </div>
            </Toolbar>
          </Box>
        ) : (
          <div className={classes.toolbarMargin}></div>
        )}

        <Slide
          in
          direction={'right'}
        >
          <List className={classes.list}>
            <Box>
              {auth.user &&
                userMenus
                  .filter((m, i) => !isBelowMd || !m.mobile)
                  .map((menuItem, i) => {
                    if (menuItem?.submenus?.length) {
                      return (
                        <NestedMenus
                          contractPropositions={contractPropositions}
                          sidebarIsOpen={sidebarIsOpen}
                          key={k1(i)}
                          menuItem={menuItem}
                          classes={classes}
                          t={t}
                          isBelowMd={isBelowMd}
                          history={history}
                          setSidebarIsOpen={setSidebarIsOpen}
                        />
                      );
                    } else {
                      return (
                        <MenuElement
                          contractPropositions={contractPropositions}
                          sidebarIsOpen={sidebarIsOpen}
                          key={k1(i)}
                          menuItem={menuItem}
                          classes={classes}
                          t={t}
                          isBelowMd={isBelowMd}
                          history={history}
                          setSidebarIsOpen={setSidebarIsOpen}
                        />
                      );
                    }
                  })}
              {auth?.interface?.type === INTERFACE_MASTER && (
                <>
                  {secondaryMenuIsOpen && <Divider />}
                  <Collapse in={secondaryMenuIsOpen}>
                    {masterExtraMenus.map((menuItem, i) => (
                      <MenuElement
                        key={k2(i)}
                        menuItem={menuItem}
                        classes={classes}
                        t={t}
                        isBelowMd={isBelowMd}
                        history={history}
                        setSidebarIsOpen={setSidebarIsOpen}
                      />
                    ))}
                  </Collapse>
                  <Box className={classes.secondaryMenuButton}>
                    <IconButton onClick={() => setSecondaryMenuIsOpen(!secondaryMenuIsOpen)}>
                      {!secondaryMenuIsOpen && <ExpandMore />}
                      {secondaryMenuIsOpen && <ExpandLess />}
                    </IconButton>
                  </Box>
                </>
              )}
            </Box>
          </List>
        </Slide>
      </Box>
    </SwipeableDrawer>
  );
}

function MenuElement({
  menuItem,
  classes,
  t,
  isBelowMd,
  history,
  setSidebarIsOpen,
  sidebarIsOpen,
  contractPropositions
}) {
  return (
    <ListItem
      button
      onClick={() => {
        if (menuItem.blank) {
          window.open(menuItem.url, '_blank');
        } else {
          history.push(menuItem.url);
        }
        if (isBelowMd) setSidebarIsOpen(false);
      }}
      className={clsx({
        [classes.listItem]: true,
        [classes.listItemActive]: history.location.pathname.includes(menuItem.url)
      })}
    >
      <ListItemIcon className={classes.listItemIcon}>
        <Badge
          className={classes.listItemBadge}
          badgeContent={menuItem.entity === 'contractproposition' ? contractPropositions.count : 0}
        >
          {menuItem.icon && (
            <FAIcon
              className={classNames(classes.icon, 'icon')}
              collection="fad"
              icon={menuItem.icon}
              size="medium"
            />
          )}
          {menuItem.image && (
            <img
              className={classNames(classes.icon, 'icon')}
              style={{ width: 24 }}
              src={menuItem.image}
              alt=""
            />
          )}
        </Badge>
      </ListItemIcon>

      <ListItemText>
        <Typography
          component="span"
          className={classNames(classes.listItemText, 'listItemText')}
        >
          {t(menuItem.label)}
        </Typography>
      </ListItemText>
      {menuItem.blank && sidebarIsOpen && (
        <ListItemSecondaryAction>
          <OpenInNew
            style={{ position: 'relative', right: 8, fontSize: 16, width: 16, height: 16 }}
          />
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
}

function NestedMenus({
  menuItem,
  classes,
  t,
  isBelowMd,
  history,
  setSidebarIsOpen,
  sidebarIsOpen,
  contractPropositions
}) {
  const [open, setOpen] = useState(history.location.pathname.includes(menuItem.url));

  useEffect(() => {
    setOpen(history.location.pathname.includes(menuItem.url));
  }, [history.location.pathname]);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem
        button
        onClick={handleClick}
        className={clsx({
          [classes.listItem]: true,
          [classes.listItemActive]: history.location.pathname.includes(menuItem.url)
        })}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <Badge
            className={classes.listItemBadge}
            badgeContent={
              menuItem.entity === 'contractproposition' ? contractPropositions.count : 0
            }
          >
            {menuItem.icon && (
              <FAIcon
                className={classNames(classes.icon, 'icon')}
                collection="fad"
                icon={menuItem.icon}
                size="medium"
              />
            )}
            {menuItem.image && (
              <img
                className={classNames(classes.icon, 'icon')}
                style={{ width: 24 }}
                src={menuItem.image}
                alt=""
              />
            )}
          </Badge>
        </ListItemIcon>
        <ListItemText>
          <Typography
            component="span"
            className={classNames(classes.listItemText, 'listItemText')}
          >
            {t(menuItem.label)}
          </Typography>
          <span className="ml-8">{open ? <ExpandLess /> : <ExpandMore />}</span>
        </ListItemText>
      </ListItem>
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
      >
        <List
          component="div"
          disablePadding
        >
          {menuItem.submenus.map((submenu, i) => (
            <ListItem
              key={nanoid()}
              button
              onClick={() => {
                if (submenu.blank) {
                  window.open(submenu.url, '_blank');
                } else {
                  history.push(submenu.url);
                }
                if (isBelowMd) setSidebarIsOpen(false);
              }}
              style={{ marginLeft: 16 }}
              className={clsx({
                [classes.listSubItem]: true,
                [classes.listSubItemActive]: history.location.pathname.includes(submenu.url)
              })}
            >
              <ListItemIcon>
                {submenu.icon && (
                  <FAIcon
                    className={classNames(classes.icon, 'icon')}
                    collection="fad"
                    icon={submenu.icon}
                    size="medium"
                  />
                )}
              </ListItemIcon>
              <ListItemText>
                <Typography
                  component="span"
                  className={classNames(classes.listSubItemText, 'listItemText')}
                >
                  {t(submenu.label)}
                </Typography>
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
}
