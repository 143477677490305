export function isValidated(devis = null) {
  if (!devis) return false;
  return devis.validated.state;
}

export function isActive(devis = null) {
  if (!devis) return false;
  return devis.isActive;
}

export function isDropped(devis = null) {
  if (!devis) return false;
  return devis.dropped.state;
}

export function isDeleted(devis = null) {
  if (!devis) return true;
  return devis.deleted.state;
}

export function isIntervener(devis = null, intervenerId = null) {
  if (!devis || !intervenerId) return false;
  return devis._intervener === intervenerId;
}

export function getDroppeds({ devis = null, intervenerId }) {
  return devis.filter(
    (d) =>
      isDropped(d) && !isDeleted(d) && (intervenerId ? isIntervener(d, intervenerId) : isActive(d))
  );
}

export function getWaitings({ devis = [], intervenerId }) {
  return devis.filter(
    (d) =>
      !isValidated(d) &&
      !isDropped(d) &&
      !isDeleted(d) &&
      (intervenerId ? isIntervener(d, intervenerId) : isActive(d))
  );
}

export function getValidateds({ devis = [], intervenerId }) {
  return devis.filter(
    (d) =>
      isValidated(d) &&
      !isDeleted(d) &&
      (intervenerId ? isIntervener(d, intervenerId) : isActive(d))
  );
}

export function getTotalPrice({ devis, intervenerId }) {
  return getValidateds({ devis, intervenerId }).reduce((total, devis) => total + devis.price, 0);
}

export function getAll({ devis, intervenerId }) {
  return devis.filter(
    (d) => !isDeleted(d) && (intervenerId ? isIntervener(d, intervenerId) : isActive(d))
  );
}

const DevisHelper = {
  isValidated,
  isDropped,
  isDeleted,
  isIntervener,
  isActive,
  getDroppeds,
  getWaitings,
  getValidateds,
  getTotalPrice,
  getAll
};

export default DevisHelper;
