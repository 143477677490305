import { useRef, useState, useLayoutEffect } from 'react';
import FAIcon from 'components/ui/FAIcon';
import ScrollContainer from 'react-indiana-drag-scroll';
import classNames from 'classnames';
import Menu from 'components/menu/Menu';
import { useField } from 'frmx';
import MenuItem from 'components/menu/MenuItem';
import useKeys from '@flowsn4ke/usekeys';
import { IconButton } from '@material-ui/core';

export default function DragScrollContainer({ children, className, sortingOrders }) {
  const [isBeginning, setIsBeginning] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const containerRef = useRef(null);
  useLayoutEffect(() => {
    const scrollContainerNode = containerRef.current?.container?.current;
    if (!scrollContainerNode) return;

    const updateScrollState = () => {
      setIsBeginning(scrollContainerNode.scrollLeft <= 4);
      setIsEnd(
        Math.floor(scrollContainerNode.scrollLeft + scrollContainerNode.clientWidth) >=
          scrollContainerNode.scrollWidth - 4
      );
    };

    // Initial state check
    updateScrollState();

    // Event listener for resizing (in case container size changes)
    window.addEventListener('resize', updateScrollState);

    return () => {
      window.removeEventListener('resize', updateScrollState);
    };
  }, [children]);

  const handleScroll = () => {
    const scrollContainerNode = containerRef.current?.container?.current;
    if (!scrollContainerNode) return;

    setIsBeginning(scrollContainerNode.scrollLeft <= 4);
    setIsEnd(
      Math.floor(scrollContainerNode.scrollLeft + scrollContainerNode.clientWidth) >=
        scrollContainerNode.scrollWidth - 4
    );
  };

  return (
    <div className={classNames('isolate flex', className)}>
      {sortingOrders.length > 0 && <SortingMenu sortingOrders={sortingOrders} />}
      <div className="w-full relative">
        {!isBeginning && (
          <button
            className="w-8 h-full cursor-pointer absolute top-0 left-0 z-20 rounded-r-2xl backdrop-blur-sm hover:backdrop-blur transition"
            onClick={() => {
              const scrollContainerNode = containerRef.current?.container?.current;
              scrollContainerNode?.scrollBy({ left: -250, behavior: 'smooth' });
            }}
          >
            <FAIcon
              collection="far"
              icon="angles-left"
              size="small"
            />
          </button>
        )}
        <ScrollContainer
          className="scroll-container scroll-smooth flex space-x-2 p-1 cursor-grab active:cursor-grabbing scrollbar-hide"
          ref={containerRef}
          onScroll={handleScroll}
          onEndScroll={handleScroll}
          hideScrollbars={false}
        >
          {children}
        </ScrollContainer>
        {!isEnd && (
          <button
            className="w-8 h-full cursor-pointer absolute top-0 right-0 z-10 rounded-l-2xl backdrop-blur-sm hover:backdrop-blur transition"
            onClick={() => {
              const scrollContainerNode = containerRef.current?.container?.current;
              scrollContainerNode?.scrollBy({ left: 250, behavior: 'smooth' });
            }}
          >
            <FAIcon
              collection="far"
              icon="angles-right"
              size="small"
            />
          </button>
        )}
      </div>
    </div>
  );
}

function SortingMenu({ sortingOrders }) {
  const k = useKeys();
  const { value: order, setValue: setOrder } = useField('order'); // creation or update
  const { value: sort, setValue: setSort } = useField('sort'); // asc or desc

  return (
    <Menu
      button={() => (
        <IconButton size="small">
          <FAIcon
            collection="fas"
            icon="sort"
            size="medium"
          />
        </IconButton>
      )}
      items={sortingOrders.map((sortingOrder, sortingOrderIdx) => (
        <MenuItem
          key={k(sortingOrderIdx)}
          icon={sortingOrder.icon}
          label={sortingOrder.label}
          onClick={() => {
            setOrder(sortingOrder.sortKey);
            setSort(sortingOrder.labelKey);
          }}
          className={order === sortingOrder.sortKey && sort === sortingOrder.labelKey ? 'bg-gray-200' : ''}
        />
      ))}
    />
  );
}
