import { FormHelperText, makeStyles, TextField } from '@material-ui/core';
import { useField } from 'frmx';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  errorColor: {
    color: theme.palette.error.main
  }
}));

export default function CustomTextArea({ onChange, valueControlled, customField, path, required }) {
  const { label } = customField;
  const { value, setValue, error, disabled } = useField(path);
  const c = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <TextField
        disabled={disabled}
        variant="outlined"
        value={valueControlled || value || ''}
        onChange={(e) => (onChange ? onChange(e.target.value) : setValue(e.target.value))}
        error={error}
        label={t(label)}
        fullWidth
        multiline
        rows={4}
        required={required}
      />
      {error && <FormHelperText className={c.errorColor}>{t('pleaseFillTheField')}</FormHelperText>}
    </>
  );
}
