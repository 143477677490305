import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

export default function useNotifications() {
  const { t } = useTranslation();

  const success = useRef((successMessage) => {
    toast.success(t(successMessage || 'savedModifications'), { duration: 1000 });
  });

  const error = useRef((errorMessage) => {
    toast.error(t(errorMessage || 'errorOccured'), { duration: 5000, closeButton: true });
  });

  return {
    success: success.current,
    error: error.current
  };
}
