import { useTranslation } from 'react-i18next';
import { isAfter, isDate } from 'date-fns';
import { Field, Form, Reset, Submit } from 'frmx';
import { Button, TextField } from '@material-ui/core';
import Dialog from 'components/Dialog';
import DateTimeField from 'components/inputs/DateTimeField';
import { useEffect, useState } from 'react';
import useConfirm from 'hooks/useConfirm';

export default function UnavailabilityEditionDialog({
  unavailability_id_to_edit,
  onClose,
  unavailabilities,
  handle_edit_unavailability,
  handle_delete_unavailability,
  is_submit_unavailability
}) {
  const { t } = useTranslation();
  const confirm = useConfirm();
  const [unavailability_to_edit, set_unavailability_to_edit] = useState(null);

  useEffect(() => {
    if (unavailability_id_to_edit) {
      const unavailability = unavailabilities.find(
        ({ unavailability_id }) => String(unavailability_id) === String(unavailability_id_to_edit)
      );
      set_unavailability_to_edit(unavailability);
    }
  }, [unavailability_id_to_edit]);

  const schemaValidation = {
    start: (value) => !!value && isDate(value),
    end: (value, data) => !!value && isDate(value) && isAfter(value, data.start)
  };

  return (
    <Dialog
      icon="edit"
      title={t('editUnavailabilityOf') + ' : ' + unavailability_to_edit?.title}
      width="2xl"
      isOpen={!!unavailability_id_to_edit}
      onClose={onClose}
    >
      <div className="mt-4">
        <Form
          initialValues={{
            start: unavailability_to_edit?.start,
            end: unavailability_to_edit?.end,
            description: unavailability_to_edit?.description
          }}
          onSubmit={(data) => [handle_edit_unavailability(data), onClose()]}
          disableIfInvalid
          schemaValidation={schemaValidation}
        >
          {/* <div className="mb-1 pt-2 text-lg">
          {t('technicianName')}: {unavailability_to_edit?.title}
        </div> */}
          <div className="mb-1 pt-2 shadow-sm flex justify-between">
            <DateTimeField
              path="start"
              label={t('startUnavailability')}
              fullWidth
              clearable={false}
              required
            />
            <DateTimeField
              path="end"
              label={t('endUnavailability')}
              clearable={false}
              fullWidth
              required
              style={{ marginLeft: '1rem' }}
              errorHelperText={t('endUnavailabilityError')}
            />
          </div>
          <div className="mb-1 pt-2 shadow-sm">
            <Field
              path="description"
              isErrorProp="error"
            >
              <TextField
                variant="outlined"
                label={t('reasonUnavailability')}
                fullWidth
                multiline
                minRows={2}
              />
            </Field>
          </div>
          <div className="mt-2 flex justify-end">
            <div className="w-full">
              <Button
                onClick={() =>
                  confirm({
                    onConfirm: () => [
                      handle_delete_unavailability(unavailability_id_to_edit),
                      onClose()
                    ]
                  })
                }
                style={{ color: '#f44336' }}
              >
                {t('delete')}
              </Button>
            </div>
            <Reset onClick={() => onClose()}>
              <Button>{t('cancel')}</Button>
            </Reset>
            <Submit disabled={is_submit_unavailability}>
              <Button color="primary">{t('edit')}</Button>
            </Submit>
          </div>
        </Form>
      </div>
    </Dialog>
  );
}
