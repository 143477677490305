import { useAuth } from 'hooks/useAuth';
import Documents from 'modules/documents';

export default function DocumentPage() {
  const auth = useAuth();

  return (
    <Documents
      tab_id={null}
      with_filters={true}
      hiddenFilters={{ locations: auth?.interface?._locations, clients: auth?.interface?._scope_clients }}
    />
  );
}
