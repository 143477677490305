import { nanoid } from 'nanoid';
import { useMemo } from 'react';
import { compute_cells_events } from '../utils';
import Cell from './Cell';

export default function Cells({
  start,
  end,
  set_events,
  set_unavailabilities,
  selected_range,
  compute_initial_position,
  set_open_dialog_actions
}) {
  const cells = useMemo(() => compute_cells_events({ start, end }), [start, end]);

  return (
    <ol>
      {cells
        .map((cell) => ({ ...cell, ...compute_initial_position(cell) }))
        .map((cell, idx) => (
          <Cell
            {...cell}
            idx={idx}
            key={nanoid()}
            set_events={set_events}
            set_unavailabilities={set_unavailabilities}
            set_open_dialog_actions={set_open_dialog_actions}
            selected_range={selected_range}
          />
        ))}
    </ol>
  );
}
