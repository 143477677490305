import { Box } from '@material-ui/core';
import useStyles from '../../layouts/entities/Preview.styles';

export default function ScopePreview({ element }) {
  const classes = useStyles();
  return (
    <>
      <Box>
        <Box className={classes.l}>{<span className={classes.title}>{element.name}</span>}</Box>
      </Box>
    </>
  );
}
