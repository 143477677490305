import { Box } from '@material-ui/core';

import useStyles from 'layouts/entities/View.styles';

export default function ScopeView({ element }) {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.l1}>
        <Box className={classes.l1left}>{element.name}</Box>
      </Box>
    </>
  );
}
