import { Dialog, DialogTitle, DialogActions, DialogContent, Button } from '@material-ui/core';
import FAIcon from 'components/ui/FAIcon';
import { Check as CheckIcon } from '@material-ui/icons';

import { pick } from 'lodash-es';

import { useEffect, useState } from 'react';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import { firstConnect, setInfo } from 'store/authSlice';
import { Form, Submit, useFieldObserver } from 'frmx';

import logo_secondary from 'images/logo_secondary.svg';
import { UserForm } from 'settings/UserInfo';
import { ProfileCard } from 'layouts/dashboard/InterfacePopover';

import useStyles from './FirstConnect.styles';
import { useAuth } from 'hooks/useAuth';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function FirstConnectForm({ user_is_root_account }) {
  const firstName = useFieldObserver('firstName');
  const lastName = useFieldObserver('lastName');

  return (
    <>
      {/* TODO: R | Upload avatar on click */}
      <ProfileCard userInfos={{ firstName, lastName }} />

      <DialogContent>
        <UserForm user_is_root_account={user_is_root_account} />
      </DialogContent>
    </>
  );
}

export default function FirstConnect() {
  const syncDispatch = useDispatch();
  const { dispatch, requestStatus } = useAsyncDispatch();
  const auth = useAuth();
  const history = useHistory();

  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const user_is_root_account = auth.user?.isRoot;
  const is_created_by_signup = useSelector((state) => state?.auth?.is_created_by_signup);

  useEffect(() => {
    if (auth.user && !auth.user.last_connect) {
      setIsOpen(true);
      syncDispatch(firstConnect());
    }
  }, [auth.user?.last_connect, dispatch]);

  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <DialogTitle id="form-dialog-title">
        <img
          className={classes.logo_secondary}
          src={logo_secondary}
          alt="Boomerang Logo"
        />
      </DialogTitle>
      <Form
        initialValues={{
          ...pick(auth.user, ['firstName', 'lastName', 'phone', 'email', 'lang']),
          ...(user_is_root_account && is_created_by_signup ? { fake_data: true } : {})
        }}
        onSubmit={(updates) => {
          dispatch(setInfo, updates, { onSuccess: () => setIsOpen(false) }).then(() => {
            if (updates?.fake_data) {
              // * Need to reload page to get new data
              const pathname = history.location.pathname;
              history.push(pathname.replace('/tickets', '/'));
            }
          });
        }}
      >
        <FirstConnectForm user_is_root_account={user_is_root_account} />

        <DialogActions>
          <Submit disabled={requestStatus === 'loading'}>
            <Button
              variant="contained"
              color="primary"
              endIcon={
                requestStatus === 'loading' ? (
                  <FAIcon
                    collection={'fas'}
                    icon={'spinner'}
                    className={'fa-spin'}
                  />
                ) : (
                  <CheckIcon />
                )
              }
            >
              {t('save')}
            </Button>
          </Submit>
        </DialogActions>
      </Form>
    </Dialog>
  );
}
