import {
  Box,
  Grid,
  Tooltip,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction
} from '@material-ui/core';

import { CheckBoxOutlineBlank, CheckBox } from '@material-ui/icons';
import CalendarPopover from 'components/ui/CalendarPopover';

import { dateToLocalFormat } from 'utils/dates';
import { Ecart } from 'entities/Maintenance/PeriodPreview';
import FAIcon from 'components/ui/FAIcon';
import Maintenance from 'entities/Maintenance/Maintenance';
import periodicities from 'constants/periodicities';
import { useContract } from 'contexts/entities/contractContext';
import { useState, useRef } from 'react';

import { updateElement as periodUpdateElement } from 'store/periodsSlice';
import { updateElement, ticketPeriodUpdateElement } from 'store/ticketsSlice';

import moment from 'moment';

import useStyles from 'layouts/entities/View.styles';
import { useRole } from 'hooks/useRole';
import { useAuth } from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

///!\ COPY preventifService back
export const typeToEnd = (config) =>
  ({
    day: [1, 'day'],
    week: [7, 'day'],
    '2week': [14, 'day'],
    '3week': [21, 'day'],
    month: [1, 'month'],
    '2month': [2, 'month'],
    trim: [3, 'month'],
    qrim: [4, 'month'],
    semestre: [6, 'month'],
    year: [1, 'year'],
    reduit: [1, 'year'],
    complet: [6, 'month'],
    '2year': [2, 'year'],
    '3year': [3, 'year'],
    '5year': [5, 'year'],
    '10year': [10, 'year']
  })[config.type];

export default function MaintenanceItems() {
  const {
    order: { period, _id },
    dispatch,
    isLoading,
    companyCreator,
    contract
  } = useContract();

  const auth = useAuth();
  const role = useRole();
  const syncDispatch = useDispatch();

  const { t } = useTranslation();

  const [isHoverStart, setIsHoverStart] = useState(false);
  const [isHoverEnd, setIsHoverEnd] = useState(false);
  const [dateToUpdate, setDateToUpdate] = useState(false);
  const [typeToUpdate, setTypeToUpdate] = useState(false);
  const [isPickerOpen, setIsPickerOpen] = useState(false);

  const listItemRefStart = useRef(null);
  const listItemRefEnd = useRef(null);

  const ticketClientId = contract._client._id;
  const interfaceClientId = auth.interface._company._id;

  const canUpdateDate =
    role.permission('maintenances', 'dates') && companyCreator._id === interfaceClientId;
  const canValidPreventif =
    role.permission('maintenances', 'validation') &&
    (companyCreator._id === interfaceClientId || interfaceClientId === ticketClientId);

  const classes = useStyles();
  return (
    <Grid container>
      <Grid
        xs={12}
        item
      >
        <Maintenance childrenId={period._preventif?._id}>
          <ListItem
            disabled={isLoading}
            button
            dense
            className={classes.listItem}
          >
            <ListItemIcon style={{ marginRight: -3 }}>
              <Tooltip
                placement="right"
                title={canValidPreventif ? t('validateMaintenance') : ''}
              >
                <IconButton
                  disabled={!canValidPreventif}
                  style={{ position: 'relative', right: 9 }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (canValidPreventif) {
                      dispatch(
                        updateElement,
                        { preventif_checked: !period.checked },
                        {},
                        { id: _id }
                      );
                    }
                  }}
                >
                  {!period.checked && <CheckBoxOutlineBlank />}
                  {period.checked && <CheckBox className={classes.successColor} />}
                </IconButton>
              </Tooltip>
            </ListItemIcon>
            <ListItemText
              primary={
                <strong style={{ fontSize: 14 }}>
                  <span style={{ marginRight: 6 }}>#{period.index}</span>
                  {period._preventif?.name}
                  {period && (!period.current || moment().isAfter(period.end_time)) && (
                    <Ecart period={period} />
                  )}
                </strong>
              }
              secondary={t(periodicities[period.config?.type])}
            />
          </ListItem>
        </Maintenance>
      </Grid>

      <Grid
        ref={listItemRefStart}
        item
        xs={12}
        md={6}
        onMouseEnter={(e) => setIsHoverStart(true)}
        onMouseLeave={(e) => setIsHoverStart(false)}
      >
        <ListItem
          disabled={isLoading}
          dense
          button
          className={classes.listItem}
          onClick={() =>
            !canUpdateDate
              ? null
              : [
                  setIsPickerOpen(listItemRefStart),
                  setTypeToUpdate('start'),
                  setDateToUpdate(period.start_time)
                ]
          }
        >
          <ListItemIcon>
            <Box position="relative">
              <FAIcon
                icon="calendar"
                collection="fal"
                className={classes.listIcon}
              />
              <FAIcon
                icon="heart-rate"
                collection="fal"
                style={{ position: 'absolute', left: 5, top: 3, fontSize: 10 }}
              />
            </Box>
          </ListItemIcon>
          <ListItemText
            primary={<strong>{t('periodStart')}</strong>}
            secondary={dateToLocalFormat(period.start_time, 'PPPP')}
          />
          {isHoverStart && <ListItemSecondaryAction></ListItemSecondaryAction>}
        </ListItem>
      </Grid>

      <Grid
        ref={listItemRefEnd}
        item
        xs={12}
        md={6}
        onMouseEnter={(e) => setIsHoverEnd(true)}
        onMouseLeave={(e) => setIsHoverEnd(false)}
      >
        <ListItem
          disabled={isLoading}
          dense
          button
          className={classes.listItem}
          onClick={() =>
            !canUpdateDate
              ? null
              : [
                  setIsPickerOpen(listItemRefEnd),
                  setTypeToUpdate('end'),
                  setDateToUpdate(period.end_time)
                ]
          }
        >
          <ListItemIcon>
            <Box position="relative">
              <FAIcon
                icon="calendar"
                collection="fal"
                className={classes.listIcon}
              />
              <FAIcon
                icon="heart-rate"
                collection="fal"
                style={{ position: 'absolute', left: 5, top: 3, fontSize: 10 }}
              />
            </Box>
          </ListItemIcon>
          <ListItemText
            primary={<strong>{t('periodEnd')}</strong>}
            secondary={dateToLocalFormat(period.end_time, 'PPPP')}
          />
          {isHoverEnd && <ListItemSecondaryAction></ListItemSecondaryAction>}
        </ListItem>
      </Grid>

      <CalendarPopover
        closeOnChange
        onChange={({ date, message }) => [
          setIsPickerOpen(false),
          (() => {
            let end_time = typeToUpdate === 'end' ? date : period.end_time;
            const start_time = typeToUpdate === 'start' ? date : period.start_time;

            if (typeToUpdate === 'start') {
              // We need to read the specific config of the period otherwise we read the default config of the maintenance generator
              const tte = typeToEnd(period?.config || period?._preventif?.defaults?.config);

              end_time = moment(new Date(date)).add(...tte);

              if (period._preventif.compute === 'calendar') {
                end_time = end_time.startOf(tte[1]).add(tte[1] === 'day' ? 0 : -1, 'day');
              }
            }

            dispatch(
              periodUpdateElement,
              {
                type: 'times',
                value: { start_time, end_time },
                ticket: _id
              },
              {
                onSuccess: () => {
                  syncDispatch(
                    ticketPeriodUpdateElement({
                      value: { start_time, end_time },
                      ticket: _id
                    })
                  );
                }
              },
              { id: period._id }
            );
          })()
        ]}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        date={dateToUpdate}
        style={{ width: isPickerOpen?.current?.offsetWidth }}
        anchorEl={isPickerOpen?.current}
        open={!!isPickerOpen}
        onClose={() => [setIsPickerOpen(false)]}
      />

      <Box
        marginBottom="16px"
        width="100%"
      />
    </Grid>
  );
}
