import { Box, ButtonBase } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import useStyles from 'layouts/entities/Preview.styles';

export default function GuaranteeSkeleton() {
  const classes = useStyles();

  return (
    <Box className={classes.preview}>
      <ButtonBase className={classes.previewButton}>
        <Box className={classes.previewContainer}>
          <Skeleton
            animation="wave"
            variant="text"
            width={200}
            height={18}
          />
        </Box>
      </ButtonBase>
    </Box>
  );
}
