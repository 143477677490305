import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

export default function Portal({ children }) {
  const el = useRef(document.createElement('div'));

  useEffect(() => {
    const portal = document.getElementById('portal');
    portal.appendChild(el.current);

    return () => portal.removeChild(el.current);
  }, [children]);

  return createPortal(children, el.current);
}

export function PortalDiv() {
  return <div id="portal" />;
}
