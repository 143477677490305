import { Box, Chip } from '@material-ui/core';

import Avatar from 'components/Avatar';
import InterfaceIndicator from 'components/Interface/InterfaceIndicator';
import FAIcon from 'components/ui/FAIcon';
import { useEntity } from 'contexts/entities/entityContext';

import useStyles from 'layouts/entities/Preview.styles';
import { useTranslation } from 'react-i18next';

export default function TechnicianPreview({ element, isActive }) {
  const classes = useStyles();
  const { scheduler } = useEntity();
  const { t } = useTranslation();

  return (
    <>
      <Box
        display={'flex'}
        style={{ width: '100%' }}
      >
        {
          <Box className={classes.avatar}>
            <Box position={'relative'}>
              <Avatar
                entity={{
                  name: '',
                  avatar: element.avatar || element._user?.avatar
                }}
                round={true}
                isUser={true}
                shadow={false}
                size={'small2'}
              />
              <InterfaceIndicator _user={element._user} />
            </Box>
          </Box>
        }

        <Box
          flexGrow={1}
          display={'flex'}
          alignItems={'center'}
        >
          <Box className={classes.l}>
            <Box
              display={'flex'}
              flexDirection={'column'}
              flexGrow={1}
              minWidth={0}
            >
              <span
                className={classes.title}
                style={scheduler ? { color: element.color } : {}}
              >
                {(element._user || element).firstName} {(element._user || element).lastName}
                <FAIcon
                  collection="fas"
                  icon="circle"
                  size="xxs"
                  style={{ color: element.color, marginLeft: '.7em' }}
                />
                {/* {!scheduler && configuration.feature.agenda && <Button
                variant={"outlined"}
                size={"small"}
                style={{ marginLeft: 4 }}
                className={classes.subButton}
                onClick={(e) => history.push('/calendar/technician/' + element._id)}
              >
                <FAIcon style={{ fontSize: 10 }} icon={"calendar"} collection={"fal"} size={"small"} />
              </Button>} */}
              </span>
              {!!element._jobs?.length && (
                <strong className={classes.reference}>
                  {t(element._jobs[0].name)}
                  {element._jobs?.length > 1 ? `, ${element._jobs?.length - 1} autres` : ''}
                </strong>
              )}
            </Box>

            <Box
              display={'flex'}
              alignItems={'center'}
            >
              {!!element._role && (
                <Chip
                  className={classes.chip}
                  color="secondary"
                  variant={isActive ? 'default' : 'outlined'}
                  size="small"
                  label={element._role.name}
                />
              )}
            </Box>
          </Box>
        </Box>

        {/* {element._user?.last_connect && <Tooltip title={"Actif sur Bob! Desk"} placement={"left"}><Box boxShadow={1} className={classes.online}></Box></Tooltip>} */}
      </Box>
    </>
  );
}

export function TechnicianItemPreview({ technician }) {
  const classes = useStyles();

  return (
    <>
      {technician && (
        <Box
          display={'flex'}
          alignItems={'center'}
        >
          <strong>
            {technician.firstName} {technician.lastName}
          </strong>
          {
            <Avatar
              className={classes.avatarSmall}
              entity={{
                name: (technician._user || technician).firstName + ' ' + (technician._user || technician).lastName,
                avatar: technician._user?.avatar
              }}
              shadow={false}
              size={'xsmall'}
            />
          }
        </Box>
      )}
    </>
  );
}
