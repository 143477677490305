import { Button, TextField, Fade, Typography } from '@material-ui/core';
import FAIcon from 'components/ui/FAIcon';

import { isEmail } from 'validator';
import { signup } from 'store/authSlice';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import { Form, Submit, Field } from 'frmx';
import PasswordField from 'components/inputs/PasswordField';
import useStyles from './Portal.styles';
import { useTranslation } from 'react-i18next';
import { useRef, useState } from 'react';
import classNames from 'utils/classNames';
import PhoneField from 'components/inputs/PhoneField';
import { isValidPhoneNumber } from 'libphonenumber-js';

export default function SignUpPage() {
  const { dispatch, requestStatus } = useAsyncDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const [
    error_user_already_known_but_wrong_credentials,
    set_error_user_already_known_but_wrong_credentials
  ] = useState(false);
  const [mandatory, set_mandatory] = useState(false);

  let initialValues = useRef({
    firstName: '',
    lastName: '',
    companyName: '',
    email: '',
    password: '',
    phone: ''
  });
  initialValues = initialValues.current;

  let schemaValidation = useRef({
    email: (val) => isEmail(val),
    password: (str) => str?.length > 0,
    firstName: (str) => str?.length > 0,
    lastName: (str) => str?.length > 0,
    companyName: (str) => str?.length > 0,
    phone: (str) => str?.length > 0 && isValidPhoneNumber(str)
  });
  schemaValidation = schemaValidation.current;

  return (
    <Form
      render="form"
      initialValues={initialValues}
      schemaValidation={schemaValidation}
      onInvalidSubmit={() => {
        set_mandatory(true);
      }}
      onSubmit={(data) => {
        dispatch(signup, data, {
          onError: (err) =>
            err?.message?.includes('401') &&
            set_error_user_already_known_but_wrong_credentials((prev) => !prev)
        });
      }}
      className={classes.formContainer}
      disableIfInvalid
    >
      <Field
        path="companyName"
        isErrorProp="error"
      >
        <TextField
          required
          variant="outlined"
          label={t('companyName')}
          className={classes.spacing}
        />
      </Field>

      <Field
        path="lastName"
        isErrorProp="error"
      >
        <TextField
          required
          variant="outlined"
          label={t('lastName')}
          className={classes.spacing}
        />
      </Field>

      <Field
        path="firstName"
        isErrorProp="error"
      >
        <TextField
          required
          variant="outlined"
          label={t('firstName')}
          className={classes.spacing}
        />
      </Field>

      <PhoneField
        className={classes.spacing}
        path="phone"
        label={t('cellphoneNumber')}
        variant="outlined"
        placeholder={t('cellphoneNumber')}
        mandatory={mandatory}
      />

      <Field
        path="email"
        type="email"
        isErrorProp="error"
      >
        <TextField
          required
          variant="outlined"
          label={t('emailInputLabel')}
          className={classes.spacing}
        />
      </Field>

      <PasswordField
        required
        path="password"
        className={classes.spacing}
      />

      <Submit disabled={requestStatus === 'loading'}>
        <Button
          className={classNames(classes.spacing, classes.submitButtonContainer)}
          variant="contained"
          color="primary"
          disableElevation
        >
          <Typography>{t('signUpButtonLabel')}</Typography>
          <FAIcon
            icon={requestStatus === 'loading' ? 'spinner' : 'power-off'}
            className={requestStatus === 'loading' && classes.spinner}
            collection={'fas'}
            size="medium"
          />
        </Button>
      </Submit>

      <Fade in={requestStatus === 'error'}>
        <Typography
          variant="body2"
          color="error"
          className={classes.messageError}
        >
          {error_user_already_known_but_wrong_credentials
            ? t('userAlreadyKnownButWrongCredentials')
            : t('anErrorHasOccurredPleaseTryAgainLater')}
        </Typography>
      </Fade>
    </Form>
  );
}
