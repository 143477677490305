import { createSlice } from '@reduxjs/toolkit';
import { generateEntityBoilerplate } from 'utils/generateEntitySlice';
import generateAsyncThunk from 'utils/generateAsyncThunk';
import { omit } from 'lodash-es';
import { finishElement as ticketFinishElement } from './ticketsSlice';

export const updateElement = generateAsyncThunk({
  type: 'PUT',
  endpoint: 'preventifs/periods/${id}'
});
export const getElements = generateAsyncThunk({
  type: 'POST',
  endpoint: 'preventifs/periods/list'
});

const entityBoilerplate = generateEntityBoilerplate({
  getElements
});

const { initialState, reducers, extraReducers } = entityBoilerplate;

const updateList = (state, id) => {
  state.lists = Object.keys(state.lists).reduce((acc, curr) => {
    const count = state.lists[curr].count;
    const tab = state.lists[curr].map((el, i) =>
      el._id === id
        ? {
            ...el,
            update: state.lists[curr][i].update ? state.lists[curr][i].update + 1 : 1
          }
        : el
    );
    tab.count = count;
    return { ...acc, [curr]: tab };
  }, {});
};

export const periodsSlice = createSlice({
  name: 'maintenances',
  initialState,
  reducers,
  extraReducers: {
    ...extraReducers,
    [ticketFinishElement.fulfilled]: (state, action) => {
      const { element } = action.payload.data;
      const { id } = action.meta;

      if (element.period && state.db[element.period.periodId]) {
        updateList(state, element.period.periodId);
        state.db[element.period.periodId].periods = [
          ...state.db[element.period.periodId].periods.map((period) =>
            period._ticket._id === id
              ? {
                  ...period,
                  ...omit(element.period, ['_ticket', 'next_period'])
                }
              : period
          ),
          ...(element.period.next_period ? [element.period.next_period] : [])
        ];
      }
    },
    [updateElement.fulfilled]: (state, action) => {
      const { value, ticket, type } = action.meta.arg;
      const { id } = action.meta;
      const { period } = action.payload.data;

      if (state.db[id]) {
        updateList(state, id);
        if (type === 'times') {
          const currentPeriod = period.periods.find((p) => p.current);

          state.db[id].periods = state.db[id].periods.map((p) => {
            return p._ticket._id === ticket
              ? {
                  ...p,
                  start_time: value.start_time,
                  end_time: value.end_time,
                  alert_date: currentPeriod.alert_date,
                  planning_date: currentPeriod.planning_date
                }
              : p;
          });

          state.db[id].stop_time = period.stop_time;
        } else if (type === 'config') {
          const currentPeriod = period.periods.find((p) => p.current);

          state.db[id].config = { ...state.db[id].config, ...value };
          state.db[id].periods = state.db[id].periods.map((p, i) => {
            if (p.current) {
              return {
                ...p,
                start_time: currentPeriod.start_time,
                end_time: currentPeriod.end_time,
                alert_date: currentPeriod.alert_date,
                planning_date: currentPeriod.planning_date
              };
            } else {
              return p;
            }
          });
        } else if (type === '_intervener') {
          state.db[id]._intervener = action.payload?.data?.period?._intervener;
        }
      }
    }
  }
});

export const { flushElements, flushElement } = periodsSlice.actions;
export default periodsSlice.reducer;
