import { useSize } from 'hooks/useSize';

export function KeepInScreen(ref, context) {
  const { height, width } = useSize(ref);
  const HEIGHT_NAVBAR = 68;

  const maxHeight = window.innerHeight - HEIGHT_NAVBAR;

  let position_top =
    context?.top + height > window.innerHeight ? context?.top - height : context?.top;

  const position_left =
    context?.left + width > window.innerWidth ? context?.left - width : context?.left;

  if (position_top < HEIGHT_NAVBAR) position_top = HEIGHT_NAVBAR;

  return {
    position_top,
    position_left,
    maxHeight
  };
}
