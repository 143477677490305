import { Box, Chip, IconButton } from '@material-ui/core';
import { attachClient } from 'store/authSlice';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import Avatar from 'components/Avatar';
import InterfaceIndicator from 'components/Interface/InterfaceIndicator';
import FAIcon from 'components/ui/FAIcon';
import { useAuth } from 'hooks/useAuth';
import useStyles from 'layouts/entities/Preview.styles';

export default function ClientsPreview({ element, isActive }) {
  const auth = useAuth();

  const classes = useStyles();
  const isPro = element.regime === 'pro';
  const { requestStatus, dispatch } = useAsyncDispatch();
  const portalLoading = requestStatus === 'loading';
  const connect = (e, attach) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(
      attachClient,
      { attach, parentType: '_client', type: 'client' },
      {},
      { id: element._id }
    );
  };

  const portal = auth.interface.isMaster
    ? !auth.user.interfaces.find((i) => i._company._id === element._id)
      ? 1
      : 2
    : null;

  return (
    <>
      <Box
        display={'flex'}
        style={{ width: '100%' }}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Box
          flexGrow={1}
          display={'flex'}
          alignItems={'center'}
        >
          <Box
            className={classes.avatar}
            marginRight={'10px'}
          >
            <Box position={'relative'}>
              <Avatar
                entity={{
                  name: element.name,
                  avatar: element.avatar
                }}
                round={!isPro}
                isUser={!isPro}
                shadow={false}
                size={'small2'}
              />
              <InterfaceIndicator _user={element._user} />
            </Box>
          </Box>

          <Box
            flexGrow={1}
            display={'flex'}
            alignItems={'center'}
          >
            <Box className={classes.l}>
              <Box
                display={'flex'}
                flexDirection={'column'}
                flexGrow={1}
                minWidth={0}
              >
                {element.ref && <span className={classes.reference}>#{element.ref}</span>}
                {!!(isPro ? element.name : element.lastName) && (
                  <span className={classes.title}>
                    {isPro ? element.name : (element.firstName + ' ' + element.lastName)?.trim()}
                  </span>
                )}
              </Box>

              <Box
                display={'flex'}
                alignItems={'center'}
              >
                {element._category && (
                  <Chip
                    className={classes.chip}
                    color="secondary"
                    variant={isActive ? 'default' : 'outlined'}
                    size="small"
                    label={element._category.name}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box>
          {!portalLoading && portal === 1 && (
            <IconButton onClick={(e) => connect(e, true)}>
              <FAIcon
                collection={'fas'}
                icon={'portal-enter'}
              />
            </IconButton>
          )}
          {!portalLoading && portal === 2 && (
            <IconButton
              onClick={(e) => connect(e, false)}
              className={classes.successLight}
            >
              <FAIcon
                collection={'fas'}
                icon={'portal-exit'}
              />
            </IconButton>
          )}
          {portalLoading && (
            <IconButton>
              <FAIcon
                icon={'spinner'}
                className={['fa-spin']}
                collection={'fad'}
              />
            </IconButton>
          )}
        </Box>
      </Box>
    </>
  );
}

export function ClientItemPreview({ client }) {
  const classes = useStyles();
  const auth = useAuth();

  return (
    <>
      {auth.interface.isContractor && client && (
        <Box
          display={'flex'}
          alignItems={'center'}
        >
          <strong>
            {client.regime === 'pro' ? client.name : client.firstName + ' ' + client.lastName}
          </strong>
          {
            <Avatar
              className={classes.avatarSmall}
              entity={client}
              shadow={false}
              size={'xsmall'}
            />
          }
        </Box>
      )}
    </>
  );
}
