import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import FAIcon from './ui/FAIcon';

export default function DialogMUI({
  icon = 'asterisk',
  width,
  className,
  isOpen,
  onClose,
  title,
  titleRight,
  children
}) {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle className="text-lg font-medium leading-6 text-sky-900">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <FAIcon
              icon={icon}
              size="md"
              className="mr-2"
              collection="fas"
            />
            {title}
          </div>
          {titleRight}
        </div>
      </DialogTitle>
      <DialogContent className={className}>{children}</DialogContent>
    </Dialog>
  );
}
