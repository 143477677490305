import useKeys from '@flowsn4ke/usekeys';

export default function DataTableSkeleton({ columns }) {
  const k1 = useKeys();
  const k2 = useKeys();
  const k3 = useKeys();

  return (
    <div className="flex flex-col">
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  {columns.map((_, columnsIdx) => {
                    return (
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        key={k1(columnsIdx)}
                      >
                        <div className="animate-pulse flex space-x-4">
                          <div className="flex-1 space-y-6 py-1">
                            <div className="h-2 bg-slate-200 rounded"></div>
                          </div>
                        </div>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="bg-white">
                {columns.map((_, columnsIdx) => {
                  return (
                    <tr
                      key={k2(columnsIdx)}
                      className={columnsIdx % 2 === 0 ? undefined : 'bg-gray-50'}
                    >
                      {columns.map((_, rowsIdx) => {
                        return (
                          <td
                            className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                            key={k3(rowsIdx)}
                          >
                            <div className="animate-pulse flex space-x-4">
                              <div className="flex-1 space-y-6 py-1">
                                <div className="h-2 bg-slate-200 rounded"></div>
                              </div>
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
