import useAsyncDispatch from 'hooks/useAsyncDispatch';
import useNotifications from 'hooks/useNotifications';
import { fieldTypes } from 'fieldSections/fieldTypes';
import { fieldTypesReport } from 'fieldSections/fieldTypesReport';
import { createField, updateField } from 'store/fieldSectionsSlice';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';
import { Submit, Field, Form, Reset, useField, useFieldObserver } from 'frmx';
import useKeys from '@flowsn4ke/usekeys';
import { useAuth } from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { useConfiguration } from 'hooks/useConfiguration';
import DomainsSelect from './DomainsSelect';

export default function FieldEditor({
  onClose,
  element,
  editFrom,
  fields,
  setFields,
  sectionId,
  sectionEntity
}) {
  const isClient = useAuth().interface?.isClient;

  const { t } = useTranslation();

  const notify = useNotifications();
  const { dispatch } = useAsyncDispatch();
  const dispatchCallbacks = {
    onSuccess: () => notify.success(),
    onError: () => notify.error()
  };

  const valueType = useFieldObserver('valueType');

  const isCreate = element === 'new';

  return (
    <Dialog
      open={!!element}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
    >
      <Form
        initialValues={{
          label: element?.label ? t(element.label) : '',
          fullWidth: element?.fullWidth || false,
          ...(isClient ? { isContractorVisible: element?.isContractorVisible || false } : {}),
          isHeader: element?.isHeader || false,
          position: isCreate ? fields?.length : element?.position || null,
          required: element?.required || false,
          valueType: element?.valueType || 'string',
          domain_id: element?.domain_id || null,
          extra: {
            ...(valueType === 'autofill' ? { entity: element?.extra?.entity || '' } : {}),
            defaultValue: element?.extra?.defaultValue || '',
            multiple: element?.extra?.multiple || false,
            size: element?.extra?.size || 3,
            lines: element?.extra?.lines || 1,
            options: element?.extra?.options || [],
            path: element?.extra?.path || 'number',
            entity: element?.extra?.entity || null
          },
          ...(isCreate ? { _section: sectionId } : {})
        }}
        onSubmit={(field) => {
          dispatch(isCreate ? createField : updateField, field, dispatchCallbacks, {
            id: !isCreate ? element._id : null
          }).then(({ data, error }) => {
            if (!error) {
              field = isCreate ? { _id: data.element._id, ...field } : { ...element, ...field };
              const next = isCreate
                ? [...fields.slice(editFrom), field]
                : fields.slice(editFrom).map((el) => (el._id === element._id ? field : el));
              setFields(next);
              onClose();
            }
          });
        }}
        schemaValidation={{ label: (str) => !!str && str.length > 0 }}
        onInvalidSubmit={() => notify.error(t('requiredFieldNameError'))}
      >
        <DialogTitle>{t(isCreate ? 'newField' : 'editField')}</DialogTitle>

        <FieldContent sectionEntity={sectionEntity} />

        <DialogActions>
          <Reset onClick={onClose}>
            <Button>{t('cancel')}</Button>
          </Reset>
          <Submit>
            <Button>{t(isCreate ? 'add' : 'save')}</Button>
          </Submit>
        </DialogActions>
      </Form>
    </Dialog>
  );
}

function FieldContent({ sectionEntity }) {
  const { value: valueType, setValue: setValueType } = useField('valueType');
  const configuration = useConfiguration();
  const k = useKeys();
  const { t } = useTranslation();

  const fieldTypesWithContext = sectionEntity === 'report' ? fieldTypesReport : fieldTypes;

  return (
    <DialogContent>
      <Grid
        container
        spacing={2}
      >
        {valueType !== 'empty_space' && (
          <Grid
            item
            xs={12}
          >
            <Field
              path="label"
              isErrorProp="error"
            >
              <TextField
                label={t('fieldName')}
                variant="outlined"
                fullWidth
              />
            </Field>
          </Grid>
        )}

        <Grid
          item
          xs={12}
        >
          <FormControl
            variant="outlined"
            fullWidth
          >
            <InputLabel
              htmlFor="outlined-age-native-simple"
              className="bg-white"
            >
              {t('fieldType')}
            </InputLabel>
            <Select
              fullWidth
              value={valueType}
              onChange={(e) => setValueType(e.target.value)}
            >
              {Object.keys(fieldTypesWithContext)
                .filter((type) => {
                  return (
                    sectionEntity === 'report' ||
                    (sectionEntity !== 'report' &&
                      type !== 'autofill' &&
                      type !== 'empty_space' &&
                      type !== 'image')
                  );
                })
                .map((type, i) => {
                  return (
                    <MenuItem
                      key={k(i)}
                      value={type}
                    >
                      {t(fieldTypesWithContext[type].label)}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Grid>

        {configuration.job_domains.length > 0 && sectionEntity === 'ticket' && (
          <DomainsSelect domains={configuration.job_domains} />
        )}

        {valueType && fieldTypesWithContext[valueType].component}
      </Grid>
    </DialogContent>
  );
}
