import React from 'react';
import { Box, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useStyles from 'layouts/entities/View.styles';
import FAIcon from 'components/ui/FAIcon';
import classNames from 'utils/classNames';

const ContractValidation = ({ actions, isLoading }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box marginTop={'10px'}>
      <Button
        disabled={isLoading}
        onClick={() => actions.contract_validation.action(false)}
        endIcon={
          <FAIcon
            collection={'fal'}
            icon={'thumbs-down'}
            className={classes.viewActionIcon}
          />
        }
        color="secondary"
        variant="contained"
        className={classNames(classes.viewAction, classes.viewActionError)}
      >
        {t('decline')}
      </Button>

      <Button
        disabled={isLoading}
        onClick={() => actions.contract_validation.action(true)}
        endIcon={
          <FAIcon
            collection="fal"
            icon="thumbs-up"
            className={classes.viewActionIcon}
          />
        }
        color="secondary"
        variant="contained"
        className={classNames(classes.viewAction, classes.viewActionSuccess)}
      >
        {t('accept')}
      </Button>
    </Box>
  );
};

export default ContractValidation;
