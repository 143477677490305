import { Badge, IconButton, Tooltip } from '@material-ui/core';

import FactureHelper from 'utils/invoice';
import { formatCurrency } from 'utils/formatCurrency';
import { factureStates } from 'constants/invoiceStates';
import { useTranslation } from 'react-i18next';
import FAIcon from 'components/ui/FAIcon';
import { DescriptionOutlined } from '@material-ui/icons';
import { useAuth } from 'hooks/useAuth';

export default function InvoiceColumn({
  factures,
  facturesPrice,
  interventionPrice,
  parentId,
  ticketId,
  stateOfInvoice,
  invoicesFromTicket,
  setDocumentViewerConfig
}) {
  const { t } = useTranslation();
  const auth = useAuth();
  const company = auth.interface._company;

  const totalInterventionPrice =
    interventionPrice + invoicesFromTicket.reduce((total, t) => total + t.intervention_price, 0);

  const toComplete = stateOfInvoice.key === 'to_complete' ? (interventionPrice = 0) : interventionPrice;

  const isEquals =
    (facturesPrice > 0 && facturesPrice.toFixed(2) === totalInterventionPrice.toFixed(2)) ||
    (totalInterventionPrice === 0 && facturesPrice === 0);

  return parentId ? null : (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
      <Tooltip title={isEquals ? t(factureStates.to_complete.labelSuccess) : t(factureStates.to_complete.labelError)}>
        <FAIcon
          icon={isEquals && toComplete ? 'equals' : 'not-equal'}
          collection="fas"
          size="small"
          className={isEquals && toComplete ? 'text-[#31b559]' : 'text-[#e63d53]'}
        />
      </Tooltip>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          minWidth: '80px'
        }}
      >
        <span style={{ fontSize: '14px' }}>
          {formatCurrency({
            number: facturesPrice,
            locale: company?.currency?.locale,
            currency: company?.currency?.code
          })}
        </span>
      </div>
      <Tooltip title={t('seeDocumentsTypeOfInvoices')}>
        <IconButton
          className="fa-ib"
          onClick={(ev) =>
            setDocumentViewerConfig({
              isOpen: true,
              ticketId: ticketId,
              type: 'factures',
              anchor: ev.currentTarget,
              lock: stateOfInvoice.lock
            })
          }
          disabled={!factures.length || !factures[0].isActive}
        >
          <Badge
            badgeContent={FactureHelper.getAll({ factures }).length}
            color="secondary"
          >
            <DescriptionOutlined style={{ width: '100%', height: '1.2em' }} />
          </Badge>
        </IconButton>
      </Tooltip>
    </div>
  );
}
