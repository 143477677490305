import useKeys from '@flowsn4ke/usekeys';
import { Box, Button, IconButton, useTheme } from '@material-ui/core';
import { ToggleButton } from '@material-ui/lab';

import CalendarPopover from 'components/ui/CalendarPopover';
import FAIcon from 'components/ui/FAIcon';
import { useField, useForm } from 'frmx';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import useConfirm from 'hooks/useConfirm';

import DELAYS from 'lists/delays';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { deleteDelay } from 'store/ticketsSlice';
import { dateToLocalFormat } from 'utils/dates';

export default function DelayForm({ type_of, ticket_created_at, ticket_id, handle_close_delay_popover }) {
  const { t } = useTranslation();
  const k = useKeys();

  const confirm = useConfirm();

  const theme = useTheme();
  const bg_color = `bg-[${theme.palette.primary.main}]`;

  const [open_calendar_popover, set_open_calendar_popover] = useState(false);
  const ref_button_add_date = useRef(null);

  const { handleSubmit, getOneField, setOneField } = useForm();

  const is_type_of_intervention = String(type_of).toLowerCase() === 'intervention';

  const path_unit_delay = is_type_of_intervention ? 'intervention_delay' : 'resolution_delay';
  const path_deadline = is_type_of_intervention ? 'intervention_deadline' : 'resolution_deadline';

  const { value: unit_delay, setValue: set_unit_delay } = useField(path_unit_delay);
  const { value: deadline, setValue: set_deadline } = useField(path_deadline);

  const order_has_deadline_settled = getOneField(path_deadline) ? new Date(getOneField(path_deadline)) : null;

  const { dispatch } = useAsyncDispatch();

  const handle_close_calendar_popover = () => {
    handleSubmit();
    set_open_calendar_popover(false);
    handle_close_delay_popover();
  };

  useEffect(() => {
    if (deadline || unit_delay) {
      setOneField('is_created_delay', false);
    }
  }, [deadline, unit_delay]);

  return (
    <>
      <Box>
        <Box
          className={`shadow-md h-[40px] text-white text-center text-base flex items-center justify-center ${bg_color}`}
        >
          {t(`followUp${type_of}`)}
        </Box>
        <Box className="flex flex-col items-center">
          <Box className="grid grid-cols-3 px-14 py-2">
            {DELAYS.map((delay, delay_idx) => {
              const delay_value_in_ms = delay.value * 60 * 60 * 1000;

              return (
                <ToggleButton
                  key={k(delay_idx)}
                  value={getOneField(path_unit_delay) || delay.value}
                  selected={delay_value_in_ms === getOneField(path_unit_delay)}
                  onChange={() => {
                    set_unit_delay(delay.value);
                    setOneField(path_unit_delay, delay_value_in_ms);
                    // * If you select a delay, we remove the date
                    setOneField(path_deadline, null);

                    handleSubmit();
                    handle_close_delay_popover();
                  }}
                  style={{ margin: 6, padding: 6, width: '50px' }}
                >
                  {delay.label}
                </ToggleButton>
              );
            })}
          </Box>
        </Box>

        <Box className="py-2 shadow-sm flex justify-center">
          <Button
            variant="outlined"
            onClick={(event) => {
              set_open_calendar_popover((prev) => !prev);
              ref_button_add_date.current = event.currentTarget;
            }}
          >
            {t('chooseADateAndTime')}
          </Button>
        </Box>
        {(order_has_deadline_settled || deadline) && (
          <Box className="ml-2 flex items-center justify-center">
            <span>
              {t('limitDate')} : Le {dateToLocalFormat(order_has_deadline_settled || deadline, 'PPPp')}
            </span>
            <IconButton
              onClick={() =>
                confirm({
                  helperText: t('deleteDeadlineHelperText'),
                  onConfirm: () => {
                    dispatch(deleteDelay, { type_of_delay: String(type_of).toLowerCase() }, {}, { id: ticket_id }).then(
                      () => {
                        setOneField(path_unit_delay, null);
                        setOneField(path_deadline, null);
                        set_deadline(null);
                        handle_close_delay_popover();
                      }
                    );
                  }
                })
              }
            >
              <FAIcon
                icon="trash"
                size="small"
                collection="fas"
              />
            </IconButton>
          </Box>
        )}
      </Box>
      <CalendarPopover
        time
        open={open_calendar_popover}
        onClose={handle_close_calendar_popover}
        anchorEl={ref_button_add_date.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: -65
        }}
        date={order_has_deadline_settled || deadline || new Date()}
        onChange={({ date }) => {
          const format_date = new Date(date).getTime(); // ! Be careful, this is in ms

          setOneField(path_deadline, format_date);
          // * If you select a date, we remove the delay
          setOneField(path_unit_delay, null);
          set_deadline(format_date);
        }}
        // We cannot set a date before the order creation date
        minDate={new Date(ticket_created_at)}
      />
    </>
  );
}
