import {
  getElement,
  getElements,
  createElement,
  updateElement,
  flushElements,
  deleteElement,
  restoreElement
} from 'store/clientsSlice';

import ClientMenus from './ClientMenus';
import ClientPreview from './ClientPreview';
import ClientSkeleton from './ClientSkeleton';
import ClientView from './ClientView';
import ClientTabs from './ClientTabs';
import ClientFilters, { formatFilter } from './ClientFilters';
import ClientFormValues, { ClientValidation, formatForm } from './ClientFormValues';
import ClientTranslations from './ClientTranslations';
import ClientExport from './ClientExport';
import ClientForm from './ClientForm';
import ClientSorts from './ClientSorts';

import { Image } from '@material-ui/icons';

import EntityWrapper from 'entities/EntityWrapper';
import ContractorPreview from 'entities/Contractor/ContractorPreview';
import ContractorView from 'entities/Contractor/ContractorView';
import { useTranslation } from 'react-i18next';
import ClientTable from './ClientTable';

export default function Client(props) {
  const { t } = useTranslation();
  return (
    <EntityWrapper
      entity="clients"
      entityFieldsSlice="client"
      skeletonComponent={ClientSkeleton}
      previewComponentFn
      viewComponentFun
      previewComponent={({ element }) =>
        element?.isContractor ? ContractorPreview : ClientPreview
      }
      viewComponent={({ element }) => (element?.isContractor ? ContractorView : ClientView)}
      formComponent={ClientForm}
      formatForm={formatForm}
      // formDisabledIf={formDisabledIf}
      formatFilter={formatFilter}
      getFilters={ClientFilters}
      getInitialValues={ClientFormValues}
      getFormValidation={ClientValidation}
      getTabs={ClientTabs}
      getTables={ClientTable}
      getMenus={ClientMenus}
      getExportColumns={ClientExport}
      getTranslations={ClientTranslations}
      getSorts={ClientSorts}
      getElement={getElement}
      getElements={getElements}
      createElement={createElement}
      updateElement={updateElement}
      flushElements={flushElements}
      deleteElement={deleteElement}
      restoreElement={restoreElement}
      formAttachment
      formAttachmentIcon={<Image />}
      formAttachmentLabel={t('companyLogo')}
      {...props}
    />
  );
}
