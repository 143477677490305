import PopoverDrawer from 'components/ui/PopoverDrawer';
import { DatePicker, DateTimePicker } from '@material-ui/pickers';
import { useEffect, useState } from 'react';
import { Box, Button, TextField, InputAdornment } from '@material-ui/core';
import { Save, EventNote } from '@material-ui/icons';
import { isDate, isSameYear } from 'date-fns';
import { useTranslation } from 'react-i18next';

export default function CalendarPopover({
  time = false,
  anchorEl,
  open,
  onClose,
  date,
  onChange,
  style = {},
  withMessage = false,
  anchorOrigin,
  closeOnChange = false,
  ...rest
}) {
  const [value, setValue] = useState(null);
  const [message, setMessage] = useState('');
  const PickerElement = time ? DateTimePicker : DatePicker;

  const { t } = useTranslation();

  useEffect(() => {
    setValue(date ? (isDate(date) ? date : new Date(date)) : new Date());
  }, [date]);

  const close = () => {
    setMessage('');
    onClose();
  };

  return (
    <PopoverDrawer
      anchorEl={anchorEl}
      open={open}
      anchorOrigin={anchorOrigin}
      transitionDuration={0}
      onClose={onClose}
      style={style}
    >
      <Box width={'100%'}>
        <PickerElement
          variant="static"
          openTo="date"
          value={value}
          onChange={(newDate) => {
            setValue(newDate);
            // Add condition if closeOnChange is true and
            // if the date is the same year as the previous one
            // do not close the popover await to select a new date
            if (closeOnChange && isSameYear(value, newDate)) {
              onChange({ date: newDate, message });
              close();
            }
          }}
          {...(time
            ? {
                ampm: false
              }
            : {
                orientation: 'landscape'
              })}
          {...rest}
        />
        {!closeOnChange && (
          <Box
            display="flex"
            justifyContent="flex-end"
            bgcolor="white"
            flexDirection="column"
            width="100%"
          >
            {withMessage && (
              <Box
                padding="0px 10px"
                display="flex"
                alignItems="center"
                width="100%"
              >
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EventNote />
                      </InputAdornment>
                    ),
                    style: {
                      borderRadius: '8px 0px 0px 8px'
                    }
                  }}
                  fullWidth
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  variant="outlined"
                  placeholder={t('possibleRemarks')}
                  style={{ marginBottom: 6, flexGrow: 1 }}
                />
                <Button
                  color="primary"
                  variant="contained"
                  endIcon={<Save />}
                  style={{
                    borderRadius: '0px 8px 8px 0px',
                    height: 54,
                    position: 'relative',
                    bottom: 4,
                    width: 180
                  }}
                  onClick={() => [onChange({ date: value, message }), close()]}
                >
                  Enregistrer
                </Button>
              </Box>
            )}
            {!withMessage && (
              <Box
                padding="0px 10px"
                display="flex"
                alignItems="center"
                width="100%"
              >
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  endIcon={<Save />}
                  style={{ position: 'relative', bottom: 4 }}
                  onClick={() => [onChange({ date: value, message }), close()]}
                >
                  Enregistrer
                </Button>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </PopoverDrawer>
  );
}
