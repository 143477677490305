import i18n from 'i18next';
import { createSlice } from '@reduxjs/toolkit';
import { initCrisp } from 'utils/uiUtils';
import { FRENCH } from 'config/languages';
import { SPLASH_AVATAR } from 'constants/localStorage';
import { PREFERRED_PALETTE } from 'constants/localStorage';
import { palettes } from 'config/palettes';

const preferredPalette = localStorage.getItem(PREFERRED_PALETTE) || 'blue';
const preferredLanguage = i18n.language || localStorage.getItem('i18nextLng') || FRENCH.key;

initCrisp();

const initialState = {
  splashAvatar: localStorage.getItem(SPLASH_AVATAR) || undefined,
  palette: preferredPalette,
  paletteColors: palettes[preferredPalette],
  lang: preferredLanguage,
  portalIsLoading: false,
  version: localStorage.getItem('version') || ''
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    switchPalette: (state, action) => {
      const newPalette = action.payload.name;
      state.palette = newPalette;
      state.paletteColors = palettes[newPalette];
      localStorage.setItem(PREFERRED_PALETTE, newPalette);
    },
    switchLanguage: (state, action) => {
      const newLanguage = action.payload.key;
      state.lang = newLanguage;
      i18n.changeLanguage(newLanguage);
    }
  }
});

export const { switchPalette, switchLanguage } = uiSlice.actions;

export const selectUI = (state) => state.ui;

export default uiSlice.reducer;
