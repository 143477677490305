// TODO: USE WITH THE t() FUNCTION FROM i18Next
const ClientsExport = (configuration) =>
  [
    { label: 'clientName', key: 'client_name', show: configuration.isContractor ? true : false },
    { label: 'managers', key: '_managers', show: configuration.isContractor ? true : false },
    { label: 'description', key: 'description', show: true },
    { label: 'phoneNumber', key: 'phone', show: true },
    { label: 'landlineNumber', key: 'fix', show: true },
    { label: 'email', key: 'email', show: true },
    { label: 'siret', key: 'siret', show: true }
  ].filter((e) => e.show);

export default ClientsExport;
