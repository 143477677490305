import isValidPhoneNumber from 'validation/isValidPhoneNumber';
import isEmail from 'validator/lib/isEmail';

const LocationFormValues = (configuration, { sublistParent, facturation, warehouse }) => ({
  _sector: null,
  isFacturation: facturation,
  isWarehouse: warehouse,
  parentLocation: sublistParent?._id,
  address: sublistParent ? sublistParent.address : null,
  _type: sublistParent ? sublistParent._type : null,
  number: '',
  name: '',
  _client: sublistParent ? sublistParent._client : null,
  isSameFacturation: !configuration._facturation,
  _facturation: configuration._facturation,
  isPrio: false,
  contact_email: '',
  accounting_email: '',
  contact_phone: '',
  informations: '',
});

export const LocationValidation = (configuration) => ({
  contact_email: (value) => (!!value?.length ? isEmail(value) : true),
  accounting_email: (value) => (!!value?.length ? isEmail(value) : true),
  contact_phone: (value) => (!!value?.length ? isValidPhoneNumber(value) : true)
});

export const formatForm = (dataFromForm, isCreate, attachments) => ({
  ...dataFromForm,
  _cover: attachments[0]?.url
});

export default LocationFormValues;
