import {
  Button,
  Dialog,
  DialogContent,
  Collapse,
  DialogActions,
  TextField,
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
  FormHelperText
} from '@material-ui/core';

import useStyles from 'components/dialogs/Dialog.styles';
import AttachmentField from 'components/inputs/AttachmentField';
import { Form, Field, useFieldObserver, Submit, useField } from 'frmx';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import useNotifications from 'hooks/useNotifications';
import { newUploadReport } from 'store/ticketsSlice';
import { pick } from 'lodash-es';
import BlurredProgress from 'components/BlurredProgress';
import { useTranslation } from 'react-i18next';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

import DialogTitle from 'components/dialogs/DialogTitle';
import { KINGFISHER_DEMO_ACCOUNT_ID } from 'index';

export default function UploadReportDialog({
  client_id,
  open,
  onClose,
  ticket,
  ticketId,
  ticketNumber
}) {
  const { t } = useTranslation();

  const { dispatch, requestStatus } = useAsyncDispatch();
  const notify = useNotifications();
  const dispatchCallbacks = {
    onSuccess: () => notify.success(t('reportWasUploaded')),
    onError: () => notify.error()
  };

  const c = useStyles();
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="xs"
        fullWidth
      >
        <BlurredProgress in={requestStatus === 'loading'} />
        <Form
          initialValues={{
            reserve: null,
            urgency: 0,
            createTicket: null,
            submit_quote: false,
            title: t('reserveLiftNumberX', { reserve: ticketNumber }),
            description: t('interventionRequestedFollowingMaintenanceIntervention', {
              ticketTitle: ticket.title,
              ticketNumber: ticketNumber
            }),
            file: null,
            report: null
          }}
          onSubmit={(data) => {
            const queryData = pick(data, [
              'reserve',
              'createTicket',
              'urgency',
              'description',
              'title',
              'submit_quote'
            ]);

            const formData = new FormData();
            formData.append('file_0', data.report[0]);

            for (const k in queryData) {
              // That case occurs when the user select urgency and unselect it
              // avoid to send null value to the backend
              if (queryData['urgency'] === null) {
                queryData['urgency'] = 0;
              }

              formData.append(k, queryData[k]);
            }

            // TODO : ADD new dispatch for new GED (only in staging for now, in prod we will use the old one)
            // we need documents_tab_id
            formData.append('documents_tab_id', ticket?.documents_tab_id);

            // ! New Version
            dispatch(newUploadReport, { formData }, dispatchCallbacks, {
              id: ticketId
            }).then(() => onClose());

            // ! Old Version
            // dispatch(
            //   uploadReport,
            //   { formData },
            //   dispatchCallbacks,
            //   { id: ticketId }
            // ).then(() => onClose())
          }}
        >
          <DialogTitle
            title={t('uploadReport')}
            className={c.title}
            onClose={onClose}
          />

          <DialogContent>
            {ticket.period && <UploadReportFields client_id={client_id} />}
          </DialogContent>

          <UploadReportActions requestStatus={requestStatus} />
        </Form>
      </Dialog>
    </>
  );
}

function UploadReportActions({ requestStatus }) {
  const { t } = useTranslation();
  const report = useFieldObserver('report');

  return (
    <DialogActions>
      <AttachmentField
        path="report"
        disabled={requestStatus === 'loading'}
        label={t('uploadReport')}
      />

      <Submit disabled={!report?.length || requestStatus === 'loading'}>
        <Button>{t('validate')}</Button>
      </Submit>
    </DialogActions>
  );
}

function UploadReportFields({ client_id }) {
  const reserve = useFieldObserver('reserve');
  const createTicket = useFieldObserver('createTicket');

  const { value: hasReserves, setValue: setHasReserves } = useField('reserve');
  const { value: urgency, setValue: setUrgency } = useField('urgency');
  const { value: lift, setValue: setLift } = useField('createTicket');

  const submit_quote = useFieldObserver('submit_quote');

  const { t } = useTranslation();
  return (
    <>
      <Typography
        variant="subtitle2"
        style={{ marginTop: '1em', marginBottom: '1em' }}
      >
        {t('stipulatesReserves')}
      </Typography>

      <ToggleButtonGroup
        style={{ width: '100%' }}
        exclusive
        value={hasReserves}
        onChange={(e, next) => setHasReserves(next)}
      >
        <ToggleButton
          style={{ width: '100%' }}
          value={false}
        >
          {t('no')}
        </ToggleButton>
        <ToggleButton
          style={{ width: '100%' }}
          value={true}
        >
          {t('yes')}
        </ToggleButton>
      </ToggleButtonGroup>

      <Collapse in={reserve}>
        <Typography
          variant="subtitle2"
          style={{ marginTop: '1.7em', marginBottom: '1em' }}
        >
          {t('emergencyLevel')}
        </Typography>

        <ToggleButtonGroup
          style={{ width: '100%' }}
          exclusive
          value={urgency}
          onChange={(e, next) => setUrgency(next)}
        >
          <ToggleButton
            style={{
              width: '100%',
              color: urgency === '1' ? 'white' : '#FBB034',
              backgroundColor: urgency === '1' ? '#FBB034' : ''
            }}
            value="1"
          >
            {t('weak')}
          </ToggleButton>
          <ToggleButton
            style={{
              width: '100%',
              color: urgency === '2' ? 'white' : '#EE744A',
              backgroundColor: urgency === '2' ? '#EE744A' : ''
            }}
            value="2"
          >
            {t('notSoWeak')}
          </ToggleButton>
          <ToggleButton
            style={{
              width: '100%',
              color: urgency === '3' ? 'white' : '#E63D53',
              backgroundColor: urgency === '3' ? '#E63D53' : ''
            }}
            value="3"
          >
            {t('critical')}
          </ToggleButton>
        </ToggleButtonGroup>

        <Typography
          style={{ marginTop: '1.7em', marginBottom: '1em' }}
          variant="subtitle2"
        >
          {t('createLiftingTicket')}
        </Typography>

        <ToggleButtonGroup
          style={{
            width: '100%',
            marginBottom: '2em'
          }}
          exclusive
          value={lift}
          onChange={(e, next) => setLift(next)}
        >
          <ToggleButton
            style={{ width: '100%' }}
            value={false}
          >
            {t('no')}
          </ToggleButton>
          <ToggleButton
            style={{ width: '100%' }}
            value={true}
          >
            {t('yes')}
          </ToggleButton>
        </ToggleButtonGroup>

        <Collapse in={createTicket}>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <Field path="title">
                <TextField
                  variant="outlined"
                  fullWidth
                />
              </Field>
            </Grid>

            <Grid
              item
              xs={12}
            >
              <Field path="description">
                <TextField
                  variant="outlined"
                  label={t('description')}
                  fullWidth
                  multiline
                  rows={4}
                />
              </Field>
            </Grid>

            {Boolean(client_id === KINGFISHER_DEMO_ACCOUNT_ID) && (
              <Grid
                item
                xs={12}
              >
                <FormControlLabel
                  control={
                    <Field
                      path="submit_quote"
                      type="checkbox"
                    >
                      <Checkbox />
                    </Field>
                  }
                  label={t('Je souhaite proposer un devis de remise en état')}
                />
                {submit_quote && (
                  <FormHelperText>
                    Lorsque cette case est cochée, le ticket de levées de réserves/remise en état
                    qui sera créé <b>vous sera automatiquement attribué</b>, vous pourrez ainsi
                    charger un devis de remise en état. Pour le retrouver, allez dans le menu
                    "Interventions".
                  </FormHelperText>
                )}
              </Grid>
            )}
          </Grid>
        </Collapse>
      </Collapse>
    </>
  );
}
