import {
  getElement,
  getElements,
  createElement,
  updateElement,
  flushElements,
  deleteElement,
  restoreElement
} from 'store/intervenersSlice';

import ContractorMenus from '../Contractor/ContractorMenus';
import ContractorPreview from '../Contractor/ContractorPreview';
import ContractorSkeleton from '../Contractor/ContractorSkeleton';
import ContractorView from '../Contractor/ContractorView';
import ContractorTabs from '../Contractor/ContractorTabs';
import ContractorFormValues, {
  ContractorValidation,
  formatForm as formatFormContractor
} from '../Contractor/ContractorFormValues';
import ContractorTranslations from '../Contractor/ContractorTranslations';
import ContractorExport from '../Contractor/ContractorExport';
import ContractorForm from '../Contractor/ContractorForm';
import ContractorSorts from '../Contractor/ContractorSorts';

import TechnicianMenus from '../Technician/TechnicianMenus';
import TechnicianPreview from '../Technician/TechnicianPreview';
// import TechnicianSkeleton from "../Technician/TechnicianSkeleton"
import TechnicianView from '../Technician/TechnicianView';
import TechnicianTabs from '../Technician/TechnicianTabs';
import TechnicianFormValues, {
  TechnicianValidation,
  formatForm as formatFormTechnician,
  injectFormValues as injectFormValuesTechnician
} from '../Technician/TechnicianFormValues';
import TechnicianTranslations from '../Technician/TechnicianTranslations';
import TechnicianExport from '../Technician/TechnicianExport';
import TechnicianForm from '../Technician/TechnicianForm';
import TechnicianSorts from '../Technician/TechnicianSorts';

import IntervenerFilter, { formatFilter } from './IntervenerFilter';

import EntityWrapper from 'entities/EntityWrapper';
import { useState, useMemo } from 'react';
import { useDocumentListener } from 'react-events-utils';
import { useConfiguration } from 'hooks/useConfiguration';
import EntityCreate from 'layouts/entities/EntityCreate';
import FAIcon from 'components/ui/FAIcon';
import { useAuth } from 'hooks/useAuth';

import MarketplaceActivation from 'settings/MarketplaceActivation';
import MarketplaceDetails from 'settings/MarketplaceDetails';

import { Image, PhotoCamera } from '@material-ui/icons';
import { Dialog, Box, DialogActions, Button, DialogContent } from '@material-ui/core';

import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import TechnicianTable from 'entities/Technician/TechnicianTable';
import ContractorTable from 'entities/Contractor/ContractorTable';
import DialogTitle from 'components/dialogs/DialogTitle';

export default function Intervener(props) {
  const { type, intervenerPickerType, tabViewAll, defaultTab: pDefaultTab } = props;
  const configuration = useConfiguration();
  const auth = useAuth();

  const { t } = useTranslation();

  const defaultTab = useMemo(() => {
    return type === 'marketplace'
      ? 'public'
      : type === 'contractor'
        ? 'mine'
        : type === 'technician'
          ? 'collaborator'
          : intervenerPickerType === 'technician'
            ? 'collaborator'
            : tabViewAll
              ? 'all'
              : pDefaultTab ||
              (configuration.isMaster || !configuration.feature.collaborator
                ? 'mine'
                : ['mine', 'collaborator'].includes(configuration.default_intervener_tab)
                  ? configuration.default_intervener_tab
                  : 'collaborator');
    // TODO: default_intervener_tab
  }, [configuration, props.tabViewAll, props.intervenerPickerType]);

  const [tab, setTab] = useState(defaultTab);
  const [marketplaceOpen, setMarketplaceOpen] = useState(false);

  const tabChangedId = useMemo(() => nanoid());

  useDocumentListener('tabChanged' + tabChangedId, (e) => setTab(e.detail));

  const isTechnician = tab === 'collaborator' || props.type === 'technician';
  const isInMarketplace = auth.interface._company.marketplace;

  const [hasJobsOrZoneIds, setHasJobsOrZoneIds] = useState({
    state: false,
    handleSubmit: null
  });

  const beforeCreateOrEdit = ({ getOneField, setOneField, handleSubmit, isCreate }) => {
    // if _jobs or zone_ids is empty, show modal to add jobs or zones
    const jobs = getOneField('_jobs');
    const zones = getOneField('zone_ids');

    if (jobs?.length <= 0 || zones?.length <= 0) {
      setHasJobsOrZoneIds({
        state: true,
        handleSubmit
      });
    } else {
      return handleSubmit();
    }
  }

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="sm"
        disableEnforceFocus
        open={hasJobsOrZoneIds.state}
        onClose={(e) => setHasJobsOrZoneIds({ ...hasJobsOrZoneIds, state: false })}
      >
        <DialogTitle
          title={t('advice')}
        />
        <DialogContent className='mt-4 text-sm'>
          {t('text-jobs-zone-ids-empty')}
          {/* {t('Il est préférable de renseigner à minima une zone d’intervention ainsi qu’un métier afin que vos intervenants ressortent au moment de l’assignation ou proposition')} */}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => setHasJobsOrZoneIds({ ...hasJobsOrZoneIds, state: false })}
            color="primary"
          >
            {t('edit-form')}
            {/* {t('Modifier la fiche')} */}
          </Button>
          <Button
            onClick={() => [hasJobsOrZoneIds.handleSubmit(), setHasJobsOrZoneIds({ ...hasJobsOrZoneIds, state: false })]}
            color="primary"
          >
            {t('create-anyway')}
            {/* {t('Créer tout de même')} */}
          </Button>
        </DialogActions>
      </Dialog>

      {tab === 'public' && configuration.isContractor && !isInMarketplace && (
        <EntityCreate
          in={true}
          onClick={() => setMarketplaceOpen(true)}
          label={t('getAcquaintedWithMarketplace')}
          icon={(className) => (
            <FAIcon
              collection="fal"
              icon="info-circle"
              className={className}
            />
          )}
        />
      )}

      <Dialog
        fullWidth
        maxWidth="sm"
        disableEnforceFocus
        open={marketplaceOpen}
        onClose={(e) => setMarketplaceOpen(false)}
      >
        <DialogTitle>
          <Box
            display="flex"
            alignItems="center"
          >
            <FAIcon
              collection="fas"
              icon="book-spells"
              style={{ marginRight: 10 }}
            />
            {t('marketplace')}
          </Box>
        </DialogTitle>
        <DialogContent>
          <MarketplaceActivation
            section={{
              key: 'marketplace-cta',
              label: t('activateMarketplace'),
              icon: 'book-spells'
            }}
          />
          <MarketplaceDetails
            allwaysExpanded
            section={{
              key: 'marketplace-details-cta',
              label: t('companyInfo'),
              icon: 'address-card'
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => [setMarketplaceOpen(false)]}
            color="primary"
          >
            {t('close')}
          </Button>
        </DialogActions>
      </Dialog>

      <EntityWrapper
        entitySlice={'interveners'}
        entityFieldsSlice={isTechnician ? 'technician' : 'contractor'}
        entity={isTechnician ? 'technicians' : 'contractors'}
        tabChangedId={tabChangedId}
        disableCreate={!['collaborator', 'mine'].includes(tab)}
        disableDelete={['mine'].includes(tab)}
        skeletonComponent={ContractorSkeleton}
        previewComponentFn
        viewComponentFun
        previewComponent={({ element }) =>
          element?.isCollaborator ? TechnicianPreview : ContractorPreview
        }
        viewComponent={({ element }) => (element?.isCollaborator ? TechnicianView : ContractorView)}
        tab={tab}
        formatFilter={formatFilter}
        getFilters={IntervenerFilter}
        injectFormValues={isTechnician ? injectFormValuesTechnician : null}
        formColor
        formatForm={isTechnician ? formatFormTechnician : formatFormContractor}
        formComponent={isTechnician ? TechnicianForm : ContractorForm}
        getInitialValues={isTechnician ? TechnicianFormValues : ContractorFormValues}
        getFormValidation={isTechnician ? TechnicianValidation : ContractorValidation}
        getTabs={isTechnician ? TechnicianTabs : ContractorTabs}
        getTables={isTechnician ? TechnicianTable : ContractorTable}
        getMenus={isTechnician ? TechnicianMenus : ContractorMenus}
        getExportColumns={isTechnician ? TechnicianExport : ContractorExport}
        getTranslations={isTechnician ? TechnicianTranslations : ContractorTranslations}
        getSorts={isTechnician ? TechnicianSorts : ContractorSorts}
        deleteElementPayload={{ intervenerType: tab === 'mine' ? 'contractor' : 'technician' }}
        restoreElementPayload={{ intervenerType: tab === 'mine' ? 'contractor' : 'technician' }}
        getElement={getElement}
        getElements={getElements}
        createElement={createElement}
        updateElement={updateElement}
        flushElements={flushElements}
        deleteElement={deleteElement}
        restoreElement={restoreElement}
        {...(tab === 'collaborator'
          ? {
            formAttachment: true,
            formAttachmentIcon: <PhotoCamera />,
            formAttachmentLabel: t('technicianProfilePic')
          }
          : {
            formAttachment: true,
            formAttachmentIcon: <Image />,
            formAttachmentLabel: t('companyLogo')
          })}
        beforeCreateOrEdit={beforeCreateOrEdit}
        {...props}
      />
    </>
  );
}
