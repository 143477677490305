const ContractPropositionTranslations = (configuration) => ({
  pick: 'entityContractPropositionPick',
  create: 'entityContractPropositionCreate',
  update: 'entityContractPropositionUpdate',
  entityName: 'entityContractPropositionName',
  noResultLabel: 'entityContractPropositionnoResultLabel',
  searchText: 'entityContractPropositionSearchText',
  createDialog: 'entityContractPropositionCreateDialog',
  updateDialog: 'entityContractPropositionUpdateDialog'
});

export default ContractPropositionTranslations;
