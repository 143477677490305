import { Button, Dialog, DialogContent, Typography, DialogActions, Grid, Box } from '@material-ui/core';
import { createInterventionReport, updateInterventionReport } from 'store/ticketsSlice';

import useKeys from '@flowsn4ke/usekeys';
import { Form, useFieldObserver, useForm } from 'frmx';
import { useMemo, useState, useRef, useEffect } from 'react';
import SignatureField from 'components/inputs/SignatureField';
import FAIcon from 'components/ui/FAIcon';
import { fieldTypes } from 'fieldSections/fieldTypes';
import { isBase64 } from 'validation/isBase64';

import DialogTitle from 'components/dialogs/DialogTitle';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import useNotifications from 'hooks/useNotifications';
import EndInterventionDialog from './EndInterventionDialog';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'hooks/useAuth';
import { useConfiguration } from 'hooks/useConfiguration';
import AttachmentField from 'components/inputs/AttachmentField';
import { useIsBelowMd } from 'hooks/useMQ';

export default function ReportEditor({ formIsOpen, onClose, template, report, ticket }) {
  const [closeTicketDialogIsOpen, setCloseTicketDialogIsOpen] = useState(false);
  const [dates, setDates] = useState({ date_begin: null, date_finish: null });
  const dateIds = useRef({ date_begin: null, date_finish: null });

  const auth = useAuth();
  const { dispatch, requestStatus } = useAsyncDispatch({ virtualized: true });
  const notify = useNotifications();
  const config = useConfiguration();
  const { t } = useTranslation();
  const k = useKeys();
  const deviceIsMobile = useIsBelowMd();

  const isEditable = !(report?.signatureDate?.contractor || report?.signatureDate?.client);
  const [canEdit, setCanEdit] = useState(isEditable);
  const [canSign, setCanSign] = useState(!isEditable);

  const irIsMandatory = ticket.companyCreator?._configuration?.ticket_ir_mandatory;
  const clientSignatureContractorAccess = !!template?.client_signature_contractor_access;
  const clientSignatureMandatory = !!template?.client_signature_mandatory;
  if (template) {
    // sort fields by position in template.fields[i].position
    template = {
      ...template,
      fields: [...template.fields].sort((a, b) => a.position - b.position) // clone fields before sorting
    };
  }

  const initialFields = useMemo(
    () =>
      template?.fields.reduce((acc, field) => {
        const value = report?.fields?.find((r) => r._field === field._id)?.value || field?.extra?.defaultValue || null;
        return (field.valueType === 'date' || field.valueType === 'date_finish') && !value
          ? { ...acc, [field._id]: new Date() }
          : field.valueType !== 'autofill'
          ? { ...acc, [field._id]: value }
          : acc;
      }, {}),
    [template, report]
  );

  const mandatoryFields = useMemo(
    () =>
      template?.fields.reduce(
        (acc, field) =>
          field.valueType !== 'autofill' && field.required ? { ...acc, [field._id]: (v) => v !== null } : acc,
        {}
      ),
    [template]
  );

  const clientSuggestion = auth.interface.isClient
    ? auth.interface?._company?.name
    : ticket.contractParent?._company?.name;

  const techniciansSuggestion =
    ticket?.contract?._summons?.map((s) => s?.isCollaborator && `${s.firstName} ${s.lastName}`) || [];

  const contractorSuggestion = auth.interface.isClient
    ? ticket?.contract?._summons?.map((s) => (s.isContractor ? s.companyName : `${s.firstName} ${s.lastName}`)) || []
    : ticket?.contract?._summons?.every((s) => s.isCollaborator)
    ? techniciansSuggestion
    : [];

  if (!auth.interface.isClient && auth.interface?._company?.name) {
    contractorSuggestion.push(auth.interface?._company?.name);
  }

  const handleSubmit = async ({ data, draft }) => {
    // data.fields is { id: value } but we need [{ _field: id, value: value }]
    const fields = Object.keys(data.fields).map((fieldId) => ({
      _field: fieldId,
      value: data.fields[fieldId]
    }));

    const formattedData = {
      ...data,
      _template: template._id,
      _ticket: ticket._id,
      fields,
      isFinish: !draft,
      attachments: data.attachments?.filter((f) => !(f instanceof File))
    };

    const formData = new FormData();
    formData.append('interventionReport', JSON.stringify(formattedData));

    data.attachments?.forEach((f, i) => {
      if (f instanceof File) formData.append(`file_${i}`, f);
    });

    const action = report ? updateInterventionReport : createInterventionReport;
    const params = report ? { id: report._id, ticketId: ticket._id } : undefined;

    dispatch(
      action,
      { formData },
      { onSuccess: () => notify.success(t('savedReport')), onError: () => notify.error() },
      params
    ).then(({ data, error }) => {
      if (!error) {
        const dateBegin = data?.fields.find((f) => f._field === dateIds.current.date_begin);
        const dateFinish = data?.fields.find((f) => f._field === dateIds.current.date_finish);

        setDates({ date_begin: dateBegin?.value, date_finish: dateFinish?.value });

        if (irIsMandatory && !draft) {
          setCloseTicketDialogIsOpen(true);
        } else {
          onClose();
        }
      }
    });
  };

  return template ? (
    <Form
      disabled={!canEdit || requestStatus === 'loading'}
      initialValues={{
        signature: {
          contractor: report?.signature?.contractor || '',
          client: report?.signature?.client || ''
        },
        signatureDate: {
          contractor: report?.signatureDate?.contractor || null,
          client: report?.signatureDate?.client || null
        },
        signatureName: {
          contractor: report?.signatureName?.contractor || '',
          client: report?.signatureName?.contractor || ''
        },
        fields: initialFields,
        attachments: report?.attachments || [],
        isFinish: null
      }}
      schemaValidation={{
        signature: {
          contractor: template.contractor_signature_mandatory ? (v) => isBase64(v) : {},
          client: clientSignatureMandatory ? (v) => isBase64(v) : {}
        },
        fields: mandatoryFields
      }}
      afterChange={(fields, updatedFieldPath, hasErrors, errors) => {
        setCanSign(!Array.from(errors).some((k) => !k.startsWith('sign')));
      }}
      onSubmit={(data) => handleSubmit({ data, draft: false })}
    >
      <EndInterventionDialog
        ticket={ticket}
        open={closeTicketDialogIsOpen}
        onClose={() => [setCloseTicketDialogIsOpen(false), onClose()]}
        dates={dates}
      />

      <Dialog
        open={formIsOpen}
        scroll="paper"
        maxWidth={deviceIsMobile ? undefined : 'md'}
        fullWidth={!deviceIsMobile}
        fullScreen={deviceIsMobile}
        onClose={onClose}
      >
        <DialogTitle
          title={template?.label}
          onClose={onClose}
        />
        <DialogContent dividers>
          <Grid
            container
            spacing={2}
          >
            {template.fields.map((f, i) => {
              const FieldComponent = fieldTypes[f.valueType]?.fieldComponent;

              if (f.valueType === 'date_begin') dateIds.current.date_begin = f._id;
              if (f.valueType === 'date_finish') dateIds.current.date_finish = f._id;

              if (f.valueType !== 'autofill' && FieldComponent) {
                return (
                  <Grid
                    item
                    xs={12}
                    key={k(i)}
                  >
                    <FieldComponent
                      customField={f}
                      path={`fields.${f._id}`}
                      required={f.required}
                    />
                  </Grid>
                );
              }
              return null;
            })}

            {template.client_signature && (
              <Grid
                item
                xs={12}
                sm={6}
              >
                <Typography
                  variant="subtitle2"
                  style={{ marginBottom: 4, marginLeft: 4 }}
                >
                  {t('client')}
                </Typography>
                <SignatureField
                  suggestion={clientSuggestion}
                  signaturePath="signature.client"
                  datePath="signatureDate.client"
                  namePath="signatureName.client"
                  disabled={
                    !canSign ||
                    requestStatus === 'loading' ||
                    (config?.isContractor && !clientSignatureContractorAccess)
                  }
                />
              </Grid>
            )}

            {template.contractor_signature && (
              <Grid
                item
                xs={12}
                sm={6}
              >
                <Typography
                  variant="subtitle2"
                  style={{ marginBottom: 4, marginLeft: 4 }}
                >
                  {t('intervener')}
                </Typography>
                <SignatureField
                  suggestion={contractorSuggestion}
                  signaturePath="signature.contractor"
                  datePath="signatureDate.contractor"
                  namePath="signatureName.contractor"
                  disabled={!canSign || requestStatus === 'loading'}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>

        <DialogActions>
          <ReportSubmitButtons
            irIsMandatory={irIsMandatory}
            setCanSign={setCanSign}
            requestStatus={requestStatus}
            canEdit={canEdit}
            setCanEdit={setCanEdit}
            mandatoryFields={mandatoryFields}
            handleSubmit={handleSubmit}
            clientSignatureMandatory={clientSignatureMandatory}
          />
        </DialogActions>
      </Dialog>
    </Form>
  ) : null;
}

function ReportSubmitButtons({
  handleSubmit,
  setCanSign,
  canEdit,
  setCanEdit,
  irIsMandatory,
  requestStatus,
  clientSignatureMandatory
}) {
  const { getFields, getErrors, handleSubmit: frmxSubmit } = useForm();
  const contractorSignature = useFieldObserver('signatureName.contractor');
  const clientSignature = useFieldObserver('signatureName.client');

  const { t } = useTranslation();

  const [canDraft, setCanDraft] = useState();

  useEffect(() => {
    if (getErrors && !!Array.from(getErrors()).find((k) => !k.startsWith('signature'))) setCanSign(false);
    else setCanSign(true);
  }, []);

  useEffect(() => {
    setCanEdit(!(contractorSignature || clientSignature));
    setCanDraft(
      !!(
        (!contractorSignature && clientSignature) ||
        (contractorSignature && !clientSignature) ||
        (!contractorSignature && !clientSignature)
      )
    );
  }, [contractorSignature, clientSignature]);

  return (
    <Box>
      <AttachmentField
        path="attachments"
        disabled={requestStatus === 'loading'}
        multiple
        accept="image/*"
        icon="images"
      />

      <Button
        disabled={(!canEdit && !canDraft) || requestStatus === 'loading'}
        onClick={() => handleSubmit({ data: getFields(), draft: true })}
        startIcon={
          <FAIcon
            icon="save"
            collection="fad"
          />
        }
      >
        {t('save2')}
      </Button>

      <Button
        disabled={
          requestStatus === 'loading' ||
          (irIsMandatory && ((!clientSignature && clientSignatureMandatory) || !contractorSignature))
        }
        onClick={() => frmxSubmit()}
        startIcon={
          <FAIcon
            icon={requestStatus === 'loading' ? 'spinner' : 'vote-yea'}
            collection={requestStatus === 'loading' ? 'fas' : 'fad'}
            className={requestStatus === 'loading' ? 'fa-spin' : ''}
          />
        }
      >
        {t('endVerb')}
      </Button>
    </Box>
  );
}
