import { Box, makeStyles, CircularProgress, IconButton, Tooltip } from '@material-ui/core';

import { useEntity } from 'contexts/entities/entityContext';

import View from './View';
import useStyles from './Element.styles';
import { useEffect } from 'react';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import FAIcon from 'components/ui/FAIcon';
import BlurredProgress from 'components/BlurredProgress';
import { useTranslation } from 'react-i18next';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';

export default function Element({ isDialog, onClose, setIsOpenForm }) {
  const {
    fromTimeline,
    getElement,
    elements,
    element,
    entity,
    icon,
    on_click_left_arrow,
    on_click_right_arrow,
    page,
    period_ids,
    selectedId,
    setSelectedId,
    tabs,
    translations
  } = useEntity();

  const { t } = useTranslation();
  const { dispatch, requestStatus } = useAsyncDispatch();
  const fetch = () =>
    selectedId &&
    dispatch(getElement, { fetchAllData: !!(!element && selectedId) }, {}, { id: selectedId });

  useEffect(fetch, [selectedId]);

  const classes = useStyles({ page, isDialog, noTabs: !tabs?.length });

  const show_swiper_periods =
    entity === 'tickets' && isDialog && fromTimeline && on_click_left_arrow && on_click_right_arrow;

  const disabled_arrow_left =
    period_ids?.findIndex((period) => period.ticket_id === selectedId) === 0;
  const disabled_arrow_right =
    period_ids?.findIndex((period) => period.ticket_id === selectedId) === period_ids?.length - 1;

  return (
    <Box className={classes.container}>
      {/* Swiper for previous periods */}
      {show_swiper_periods && (
        <Box className="flex justify-center">
          <IconButton
            onClick={() => {
              const ticket_id = on_click_left_arrow();
              setSelectedId(ticket_id);
            }}
            style={{ padding: '0', margin: '0', color: disabled_arrow_left ? 'grey' : 'white' }}
            disabled={disabled_arrow_left}
            className="h=full"
          >
            <Tooltip
              title={t('previousPeriod')}
              placement="top"
              className={`${disabled_arrow_left ? 'hidden' : ''}`}
            >
              <KeyboardArrowLeft style={{ width: '3.5em', height: '3.5em' }} />
            </Tooltip>
          </IconButton>
        </Box>
      )}
      {/* End Swiper for previous periods */}

      {element && (
        <Box className={classes.element}>
          <View
            dispatch={dispatch}
            isLoading={requestStatus === 'loading'}
            refresh={fetch}
            isDialog={isDialog}
            setIsOpenForm={setIsOpenForm}
            onClose={onClose}
          />
        </Box>
      )}

      {isDialog && !element && !elements && (
        <Box
          borderRadius="8px"
          width="200px"
          height="200px"
          position="relative"
        >
          <BlurredProgress
            in
            border
          />
        </Box>
      )}
      {!element && !!elements?.length && requestStatus !== 'loading' && (
        <Pick
          icon={icon}
          translations={translations}
        />
      )}
      {!element && requestStatus === 'loading' && !isDialog && (
        <Pick
          loading
          icon={icon}
          translations={translations}
        />
      )}

      {/* Swiper for next periods */}
      {show_swiper_periods && (
        <Box className="flex justify-center">
          <IconButton
            onClick={() => {
              const ticket_id = on_click_right_arrow();
              setSelectedId(ticket_id);
            }}
            style={{ padding: '0', color: disabled_arrow_right ? 'grey' : 'white' }}
            disabled={disabled_arrow_right}
          >
            <Tooltip
              title={t('nextPeriod')}
              placement="top"
              className={`${disabled_arrow_right ? 'hidden' : ''}`}
            >
              <KeyboardArrowRight
                style={{ width: '3.5em', height: '3.5em' }}
                className="rounded"
              />
            </Tooltip>
          </IconButton>
        </Box>
      )}
      {/* End Swiper for next periods */}
    </Box>
  );
}

const useStylesPick = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    color: '#b1b1b1',
    userSelect: 'none'
  },
  icon: {
    fontSize: 64,
    height: 64,
    width: 64
  },
  label: {
    marginTop: 20,
    fontSize: 14,
    fontWeight: 500
  }
}));

export const Pick = ({ icon, translations, loading }) => {
  const classes = useStylesPick();
  const { t } = useTranslation();

  return (
    <Box className={classes.root}>
      {
        <FAIcon
          noIconClass
          className={classes.icon}
          collection={'fal'}
          icon={icon}
          size="small"
        />
      }
      {!loading && <strong className={classes.label}>{t(translations.pick)}</strong>}
      {loading && (
        <strong
          style={{ display: 'flex', alignItems: 'center' }}
          className={classes.label}
        >
          {
            <CircularProgress
              style={{ marginRight: 10 }}
              size={20}
              color="secondary"
            />
          }
          {/* {t(translations.loading)} */}
        </strong>
      )}
    </Box>
  );
};
