import { Popover, Box, Slide, Popper, Drawer } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import clsx from 'clsx';
import { useIsBelowSm } from 'hooks/useMQ';
import useStyles from './PopoverDrawer.styles';

export default function PopoverDrawer({
  anchorEl,
  drawer,
  id = 'qAzYNKPRQA_9VZTSlyDfe',
  open,
  onOpen,
  popper,
  onClose,
  children,
  style,
  offset,
  keepMounted,
  fromBottom,
  transformOrigin,
  transitionDuration,
  anchorPosition,
  anchorOrigin,
  popoverStyle,
  PaperProps,
  fullWidth
}) {
  const classes = useStyles();
  const isBelowSm = useIsBelowSm();

  if (!keepMounted && !open) return null;

  if (!isBelowSm && !drawer) {
    if (popper) {
      return (
        <Popper
          id={id}
          anchorEl={anchorEl}
          open={open}
        >
          <Box
            style={fullWidth && anchorEl ? { width: anchorEl.offsetWidth } : {}}
            className={clsx({
              [classes.popoverContainerOffset]: offset,
              [classes.popoverContainer]: true
            })}
          >
            {children}
          </Box>
        </Popper>
      );
    }
    return (
      <Popover
        transformOrigin={transformOrigin}
        anchorOrigin={anchorOrigin}
        anchorPosition={anchorPosition}
        id={id}
        keepMounted={keepMounted}
        transitionDuration={transitionDuration}
        TransitionComponent={Slide}
        TransitionProps={{ direction: 'left' }}
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        PaperProps={PaperProps}
        anchorReference={anchorPosition ? 'anchorPosition' : 'anchorEl'}
      >
        <Box
          className={clsx({
            [classes.popoverContainerOffset]: offset,
            [classes.popoverContainer]: true
          })}
          style={
            popoverStyle
              ? popoverStyle
              : style
                ? style
                : fullWidth && anchorEl
                  ? { width: anchorEl.offsetWidth }
                  : {}
          }
        >
          {children}
        </Box>
      </Popover>
    );
  } else {
    return (
      <Drawer
        open={open}
        keepMounted={keepMounted}
        onClose={onClose}
        onOpen={onOpen}
        anchor={fromBottom ? 'bottom' : 'top'}
        elevation={0}
        PaperProps={style || {}}
        classes={{ paper: classes.underlyingComponent }}
      >
        <Box
          className={classes.drawerContainer}
          style={style || {}}
        >
          {isBelowSm && (
            <Clear
              onClick={onClose}
              style={{ position: 'absolute', top: 0, right: 0, margin: 10, color: 'white' }}
            />
          )}
          {children}
        </Box>
      </Drawer>
    );
  }
}
