const ManagerExport = (configuration) =>
  [
    { label: 'Prénom', key: 'firstName', show: true },
    { label: 'Nom', key: 'lastName', show: true },
    { label: 'E-mail', key: 'email', show: true },
    { label: 'Numéro de Téléphone', key: 'phone', show: true },
    { label: 'Métiers', key: '_jobs', show: true },
  ].filter((e) => e.show);

export default ManagerExport;
