import { makeStyles } from '@material-ui/core/styles';
import { contractStates, CONTRACT_VALIDATION } from 'lists/contractStates';

export default makeStyles((theme) => ({
  view: ({ hasTabs }) => ({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    width: '100%',
    padding: '26px 16px 16px 26px',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      paddingRight: 26,
      marginBottom: hasTabs ? 82 : 0,
      flexGrow: 1,
      borderRadius: theme.radius
    },
    [theme.breakpoints.up('md')]: {
      borderRadius: '16px 16px 8px 8px',
      boxShadow: theme.shadows[1]
    },
    zIndex: 6
  }),
  cover: {
    borderRadius: theme.radius,
    width: 300,
    height: 300,
    boxShadow: theme.shadows[1]
  },
  closeView: {
    position: 'fixed',
    bottom: 110,
    right: 26
  },
  menuClose: {
    zIndex: 6,
    right: 24,
    top: 24,
    position: 'absolute'
  },
  menu: ({ isDialog }) => ({
    zIndex: 6,
    right: isDialog ? 9 : 18,
    top: isDialog ? 10 : 16,
    [theme.breakpoints.down('sm')]: {
      right: 6,
      top: 6
    },
    position: 'absolute'
  }),
  avatarl1: {
    marginRight: 10
  },
  menul1: {
    zIndex: 6,
    left: 16,
    top: 16,
    position: 'absolute'
  },
  back: {
    zIndex: 6,
    left: 16,
    top: 16,
    position: 'absolute'
  },
  menuLoading: {
    color: theme.palette.primary.dark
  },
  menuIcon: {
    width: 30,
    height: 30,
    fontSize: 30
  },
  l1: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative'
  },
  l1left: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
    fontSize: 16,
    fontWeight: 600
  },
  l1reference: {
    color: theme.palette.primary.main,
    fontSize: 12,
    marginBottom: 6,
    display: 'flex',
    alignItems: 'center'
  },
  listChip: {
    marginLeft: 6,
    height: 16,
    position: 'relative',
    bottom: 2,
    fontWeight: 400
  },
  l1chip: {
    marginRight: 6
  },
  listItemPrimaryBadge: {
    ['& span']: {
      top: 6,
      right: -15,
      height: 13,
      fontSize: 9
    }
  },
  listItemPrimaryIcon: {
    fontSize: 14,
    height: 14,
    width: 14,
    marginLeft: 26
  },
  deleted: {
    color: theme.palette.error.main,
    fontSize: 11,
    marginTop: 6,
    display: 'flex'
  },
  listItemSecondaryMenu: {
    right: 0
  },
  listItemSecondary: {
    ['& .hover']: {
      visibility: 'hidden'
    },
    [theme.breakpoints.down('sm')]: {
      ['& .hover']: {
        visibility: 'visible'
      }
    },
    ['&:hover .hover']: {
      visibility: 'visible'
    }
  },
  list: {
    width: '100%',
    overflowY: 'auto',
    marginTop: 16,
    padding: 0,
    ['& .MuiButtonBase-root']: {
      borderRadius: 8,
      [theme.breakpoints.down('sm')]: {
        marginLeft: '4px',
        padding: 0
      }
    }
  },
  listIcon: {
    marginRight: 16
  },
  listIconsSecondary: {
    marginRight: 16
  },
  listAvatar: {
    width: 32,
    height: 32,
    marginRight: 10,
    position: 'relative',
    left: 0
  },
  listItem: {
    paddingLeft: 8
  },
  viewActions: {
    marginTop: 24,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  contentEditable: {
    ['&[contenteditable="true"]:focus']: {
      border: `1px solid ${theme.palette.primary.main}`,
      outline: `none`,
      borderRadius: theme.radius
    }
  },
  viewActionsLeft: {
    display: 'flex',
    alignItems: 'center'
  },
  viewActionsRight: {
    display: 'flex',
    alignItems: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      ['&> div']: {
        width: '100%'
      }
    }
  },
  viewAction: {
    width: '240px',
    padding: '5px 12px 5px 16px',
    ['& > span']: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    borderRadius: 16,
    marginLeft: 10,
    [theme.breakpoints.down('sm')]: {
      ['& > span']: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between'
      },
      marginLeft: 0,
      width: '100%',
      marginTop: 10
    }
  },
  viewActionGroup: {},
  listPopover: {
    width: '100%',
    padding: 0,
    maxHeight: 360,
    ['& > :nth-child(odd)']: {
      background: '#ebebeb'
    }
  },
  viewActionVisitDate: {
    borderRadius: theme.radius,
    ['& > span']: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between'
    }
  },
  successColor: {
    color: theme.palette.success.dark
  },
  viewActionInfo: {
    color: 'white',
    backgroundColor: '#0ea5e9',
    ['&:hover']: {
      backgroundColor: '#0284c7'
    }
  },
  viewActionSuccess: {
    color: 'white',
    backgroundColor: theme.palette.success.main,
    ['&:hover']: {
      backgroundColor: theme.palette.success.dark
    }
  },
  viewActionContainedSuccess: {
    backgroundColor: theme.palette.success.main,
    color: 'white',
    ['&:hover']: {
      backgroundColor: theme.palette.success.dark
    }
  },
  viewActionError: {
    color: 'white',
    backgroundColor: theme.palette.error.main,
    ['&:hover']: {
      backgroundColor: theme.palette.error.dark
    }
  },
  viewActionValidation: {
    backgroundColor: contractStates[CONTRACT_VALIDATION].color
  },
  listIconSecondary: {
    height: 18,
    width: 18,
    fontSize: 18,
    color: '#c2c9d0'
  },
  tooltip: {
    padding: 0,
    maxWidth: '400px',
    boxShadow: theme.shadows[3],
    borderRadius: theme.radius,
    overflow: 'hidden'
  },
  outlined: {
    color: 'red',
    backgroundColor: 'red'
  }
}));
