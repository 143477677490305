import { Box, Chip, Button, Breadcrumbs, Typography, Tooltip } from '@material-ui/core';
import { NavigateNext as NavigateNextIcon } from '@material-ui/icons';
import FAIcon from 'components/ui/FAIcon';
import { makeStyles } from '@material-ui/core/styles';
import { useEntity } from 'contexts/entities/entityContext';

import useStyles from 'layouts/entities/Preview.styles';
import { ClientItemPreview } from 'entities/Client/ClientPreview';
import { MaintenanceItemPreview } from 'entities/Maintenance/MaintenancePreview';
import { useConfiguration } from 'hooks/useConfiguration';
import { PeriodsMenu } from '../../components/timeline/Timeline';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isArray } from 'lodash-es';
import useKeys from '@flowsn4ke/usekeys';
import { operational_states_equipment } from 'lists/operational_states_equipment';

export default function EquipmentPreview({
  element: { maintenance, ...element },
  isActive,
  payload: { timeline }
}) {
  const { setSublistParent } = useEntity();
  const classes = useStyles();
  const configuration = useConfiguration();
  const [isHover, setIsHover] = useState(false);
  const { t } = useTranslation();

  const operational_state = element?.operational_state;

  const operational = operational_states_equipment.find(
    (e) => String(e.value) === String(operational_state)
  );

  return (
    <>
      <Box
        style={{ width: '100%' }}
        onMouseEnter={(e) => setIsHover(true)}
        onMouseLeave={(e) => setIsHover(false)}
      >
        {timeline && isHover && <PeriodsMenu maintenance={maintenance} />}
        <Box className={classes.l}>
          {(element.number || element.name) && (
            <Box
              display={'flex'}
              flexDirection={'column'}
              flexGrow={1}
              minWidth={0}
            >
              {element.name && (
                <span className={classes.title}>
                  {!!maintenance && (
                    <FAIcon
                      style={{ marginRight: 4 }}
                      icon={'wrench-simple'}
                      collection={'fal'}
                      size={'small'}
                    />
                  )}
                  {element.name}
                  {!maintenance && !!element._equipments?.length && (
                    <Button
                      variant={'outlined'}
                      size={'small'}
                      style={{ marginLeft: 4 }}
                      className={classes.subButton}
                      onClick={(e) => [
                        e.stopPropagation(),
                        e.preventDefault(),
                        setSublistParent(element)
                      ]}
                    >
                      {element._equipments?.length}
                      <FAIcon
                        style={{ marginLeft: 4 }}
                        icon={'wrench-simple'}
                        collection={'fal'}
                        size={'small'}
                      />
                    </Button>
                  )}
                  {!timeline && operational_state && (
                    <>
                      <Tooltip title={t(operational.label)}>
                        <div
                          className={`${operational.color} ml-auto mb-auto w-[12px] h-[12px] rounded-full`}
                        />
                      </Tooltip>
                    </>
                  )}
                </span>
              )}
            </Box>
          )}

          {!timeline && (
            <Box
              marginLeft={'auto'}
              display={'flex'}
              alignItems={'center'}
              position={'relative'}
              bottom={6}
            >
              <ClientItemPreview client={element._client} />
            </Box>
          )}
        </Box>

        {!timeline && element._location && (
          <BreadcrumbsLocation
            element={element._location}
            sublistParentKey={'_parentLocations'}
          />
        )}

        <Box className={classes.l}>
          <Box>{element._location?.address?.fullAddress || element._location?.name}</Box>
          <Box>
            {/* TODO: Dispplay hors contrat for everyone? */}
            {element.isContract && <strong>{t('subContract')}</strong>}
            {element._category && (
              <Chip
                className={classes.chip}
                color="secondary"
                variant={isActive ? 'default' : 'outlined'}
                size="small"
                label={element._category.name}
              />
            )}
            {element._subcategory && (
              <Chip
                className={classes.chip}
                color="secondary"
                variant={isActive ? 'default' : 'outlined'}
                size="small"
                label={element._subcategory.name}
              />
            )}
          </Box>
        </Box>

        {configuration.isClient && maintenance?._id && (
          <MaintenanceItemPreview maintenance={maintenance} />
        )}
      </Box>
    </>
  );
}

function BreadcrumbsLocation({ element, sublistParentKey }) {
  const list = useMemo(
    () => (!isArray(element[sublistParentKey]) ? [] : [...element[sublistParentKey]].reverse()),
    [element]
  );
  const k = useKeys();
  const classes = stylesBreadCrumbsLocation();

  return (
    !!element.name?.trim()?.length && (
      <Box className={classes.root}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {list.map((_element, i) => (
            <Typography
              style={{ fontSize: 13 }}
              key={k(i)}
            >
              {_element.name}
            </Typography>
          ))}

          <Typography
            color="textPrimary"
            style={{ fontSize: 13, fontWeight: 'bold' }}
          >
            {element.name}
          </Typography>
        </Breadcrumbs>
      </Box>
    )
  );
}

const stylesBreadCrumbsLocation = makeStyles((_) => ({
  root: () => ({
    display: 'inline-flex',
    alignItems: 'center',
    padding: '0px 0px',
    width: 'auto',
    borderBottom: 'none',
    ['& .MuiBreadcrumbs-separator']: {
      marginLeft: 0,
      marginRight: 0
    }
  }),
  breadcrumb: {},
  icon: {
    color: '#bfc6ce',
    marginRight: 13
  }
}));
