const MuiTab = {
  root: {
    fontWeight: 400,
    textTransform: 'none',
    fontSize: '14px',
    '@media (min-width: 960px)': {
      minWidth: '100px'
    },
    '&$selected': {
      fontWeight: 500
    },
    fullWidth: {
      maxWidth: 'initial'
    }
  },
  textColorPrimary: {
    color: '#66788A'
  }
};

export default MuiTab;
