const UserTranslations = (configuration) => ({
  pick: 'entityUserPick',
  createDialog: 'entityUserCreateDialog',
  updateDialog: 'entityUserUpdateDialog',
  create: 'entityUserCreate',
  update: 'entityUserUpdate',
  entityName: 'entityUserName',
  noResultLabel: 'entityUsernoResultLabel',
  searchText: 'entityUserSearchText'
});

export default UserTranslations;
