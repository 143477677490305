import { Box, Chip } from '@material-ui/core';

import Avatar from 'components/Avatar';
import useStyles from 'layouts/entities/Preview.styles';
import InterfaceIndicator from 'components/Interface/InterfaceIndicator';

export default function ManagerPreview({ element, isActive }) {
  const classes = useStyles();

  return (
    <>
      <Box
        display={'flex'}
        style={{ width: '100%' }}
      >
        {
          <Box className={classes.avatar}>
            <Box position={'relative'}>
              <Avatar
                entity={{
                  name: '',
                  avatar: element.avatar
                }}
                round={true}
                isUser={true}
                shadow={false}
                size={'small2'}
              />
              <InterfaceIndicator _user={element} />
            </Box>
          </Box>
        }

        <Box
          flexGrow={1}
          display={'flex'}
          alignItems={'center'}
        >
          <Box className={classes.l}>
            {
              <Box
                display={'flex'}
                flexDirection={'column'}
                flexGrow={1}
                minWidth={0}
              >
                {
                  <span className={classes.title}>
                    {element.firstName} {element.lastName?.toUpperCase()}
                  </span>
                }
              </Box>
            }

            <Box
              display={'flex'}
              alignItems={'center'}
            ></Box>
          </Box>
          <Box
            display={'flex'}
            alignItems={'center'}
          >
            {!!element._role && (
              <Chip
                className={classes.chip}
                color="secondary"
                variant={isActive ? 'default' : 'outlined'}
                size="small"
                label={element._role.name}
              />
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}
