import { add, sub } from 'date-fns';
import { addToDate, count_days_between_two_dates } from 'utils/dates';

const ContractFormValues = (configuration) => ({
  name: '',
  unit_price_schedule: '',
  intervention_delay: '',
  travelling_expenses: '',
  hourly_rate: '',
  standing_by: '',
  duration: 1,
  duration_type: 'years',
  start_date: null,
  alert_deadline: 0
});

export const ContractValidation = (configuration) => ({});

export const formatForm = (dataFromForm, isCreate, attachments) => {
  // * We need to format the start_date if it's set
  if (dataFromForm.start_date) {
    const end_date = add(new Date(dataFromForm.start_date), {
      [dataFromForm.duration_type]: Number(dataFromForm.duration)
    });

    dataFromForm.alert_deadline =
      dataFromForm.alert_deadline === 0
        ? end_date
        : sub(end_date, { days: Number(dataFromForm.alert_deadline) });
  }

  return { ...dataFromForm, attachments };
};

export const injectFormValues = (initialValues, contract) => {
  const start_date = new Date(contract?.start_date);
  const alert_deadline = new Date(contract?.alert_deadline);

  if (contract?.alert_deadline) {
    const duration = contract?.duration;
    const duration_type = contract?.duration_type;

    const end_date = addToDate(start_date, duration, duration_type);
    const alert_deadline_count = count_days_between_two_dates(end_date, alert_deadline);

    initialValues = {
      ...initialValues,
      alert_deadline: alert_deadline_count
    };
  }
  return initialValues;
};

export default ContractFormValues;
