import Ticket from 'entities/Ticket/Ticket';
import TicketFile from 'router/Corentin/TicketFile';

export const Corentin = () => {
  return (
    <div className="w-full h-full m-2 flex flex-wrap gap-6 justify-center align-center">
      <PreviousTicketList />
      <NewTicketList />
    </div>
  );
};

const PreviousTicketList = () => {
  return (
    <div className="h-[550px] w-[600px] bg-red-200">
      Ancienne liste des tickets
      <Ticket />
    </div>
  );
};

const NewTicketList = () => {
  return (
    <div className="h-[550px] w-[600px] bg-green-200">
      Nouvelle liste des tickets
      <TicketFile />
    </div>
  );
};
