import { createElement, useState } from 'react';
// import Popover from "components/ui/Popover";
import FAIcon from 'components/ui/FAIcon';
import { useTranslation } from 'react-i18next';
import classNames from 'utils/classNames';
import NotEmptyIndicator from './NotEmptyIndicator';
import { Popover as MuiPopover } from '@material-ui/core';

export default function EntityPopoverFilter({ label, entity, path, className, entityProps }) {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [is_open_popover_filter, set_is_open_popover_filter] = useState(false);

  const handle_click = (event) => {
    event.stopPropagation();
    set_is_open_popover_filter(!is_open_popover_filter);
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <div
        onClick={(event) => handle_click(event)}
        className="hover:bg-gray-50 transition relative cursor-pointer border border-gray-300 inline-flex items-center pl-3 h-8 rounded-full text-xs text-gray-500 font-medium"
      >
        <NotEmptyIndicator path={path} />
        {t(label)}
        <div className="h-[30px] w-[30px] ml-1 rounded-full flex justify-center items-center">
          <FAIcon
            icon="caret-down"
            collection="fas"
            size="xs"
            className="text-gray-600"
          />
        </div>
      </div>
      <MuiPopover
        open={is_open_popover_filter}
        transitionDuration={250}
        anchorEl={anchorEl}
        onClose={() => set_is_open_popover_filter(!is_open_popover_filter)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: -150
        }}
      >
        <div className={classNames('z-40 h-[450px] w-[30vw] min-w-[400px] max-w-[550px]', className)}>
          {createElement(entity, { filter: true, picker: true, pickerField: path, ...entityProps })}
        </div>
      </MuiPopover>
    </>
  );
}
