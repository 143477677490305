import { Button } from '@material-ui/core';
import FAIcon from 'components/ui/FAIcon';
import useStyles from 'layouts/entities/View.styles';
import classNames from 'utils/classNames';

const ActionButton = ({ onClick, icon, label, variant }) => {
  const classes = useStyles();
  return (
    <Button
      onClick={onClick}
      endIcon={
        <FAIcon
          collection="fal"
          icon={icon}
        />
      }
      variant="contained"
      size="large"
      className={
        variant === 'success'
          ? classNames('w-36', classes.viewActionSuccess)
          : classNames('w-36', classes.viewActionError)
      }
    >
      {label}
    </Button>
  );
};

export default ActionButton;
