import { Box, List } from '@material-ui/core';

import useStyles from 'layouts/entities/View.styles';

// TODO: Delete if unused
export default function ReportView({ element }) {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.l1}>
        <Box
          alignItems={'center'}
          display={'flex'}
        >
          <Box
            flexDirection={'column'}
            alignItems={'flex-start'}
          >
            <Box className={classes.l1left}>{element.label}</Box>
          </Box>
        </Box>
      </Box>

      <List className={classes.list}>
        {/* <ListItem dense button className={classes.listItem}>
        <ListItemIcon>
          <FAIcon icon={"map-marker-alt"} collection={"fal"} className={classes.listIcon} />
        </ListItemIcon>
        <ListItemText primary={<strong>Primary</strong>} secondary={"Secondary"} />
      </ListItem> */}
      </List>
    </>
  );
}
