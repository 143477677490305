import { createSlice } from '@reduxjs/toolkit';
import { filterElementFromLists, generateEntityBoilerplate } from 'utils/generateEntitySlice';
import generateAsyncThunk from 'utils/generateAsyncThunk';

import { createElement as createContract, deleteElement as deleteContract } from './contractsSlice';
import { assign } from '@recursive/assign';

export const createElement = generateAsyncThunk({ type: 'POST', endpoint: 'interveners' });
export const updateElement = generateAsyncThunk({ type: 'PUT', endpoint: 'interveners/${id}' });
export const getElement = generateAsyncThunk({ type: 'GET', endpoint: 'interveners/${id}' });
export const getElements = generateAsyncThunk({ type: 'POST', endpoint: 'interveners/list' });
export const deleteElement = generateAsyncThunk({ type: 'DELETE', endpoint: 'interveners/${id}' });
export const restoreElement = generateAsyncThunk({
  type: 'POST',
  endpoint: 'interveners/recycle/${id}'
});

export const createAutoAssign = generateAsyncThunk({
  type: 'POST',
  endpoint: 'interveners/${id}/autoassign'
});
export const updateAutoAssign = generateAsyncThunk({
  type: 'PUT',
  endpoint: 'interveners/${id}${automatisationIdWithSlash}/autoassign'
});
export const deleteAutoAssign = generateAsyncThunk({
  type: 'DELETE',
  endpoint: 'interveners/${id}/autoassign'
});

export const contractorPartner = generateAsyncThunk({
  type: 'POST',
  endpoint: 'contractors/${id}'
});
export const setPartnership = generateAsyncThunk({
  type: 'PUT',
  endpoint: 'interveners/${id}/setPartnership'
});

const formatElement = (element) => {
  if (element.isCollaborator && !!element._user) {
    element.phone = element._user.phone || element.phone;
    element.email = element._user.email || element.email;
    element.firstName = element._user.firstName || element.firstName;
    element.lastName = element._user.lastName || element.lastName;
  }

  return element;
};

const entityBoilerplate = generateEntityBoilerplate({
  createElement,
  updateElement,
  getElement,
  getElements,
  deleteElement,
  restoreElement,
  formatElement
});

const { initialState, reducers, extraReducers } = entityBoilerplate;

export const intervenersSlice = createSlice({
  name: 'interveners',
  initialState,
  reducers,
  extraReducers: {
    ...extraReducers,
    [contractorPartner.fulfilled]: (state, action) => {
      const { element } = action.payload.data;
      const { id } = action.meta;

      if (!element.partner) {
        filterElementFromLists(state, id);
      }

      state.db[id] = element;
    },
    [createAutoAssign.fulfilled]: (state, action) => {
      if (!action.payload.data.autoassign?.conflict) {
        const newEl = action.payload.data.autoassign;
        const { id: intervenerId } = action.meta;

        const prev = state.db[intervenerId]?.autoassigns;
        const next = [...(prev ? prev : []), newEl];

        state.db[intervenerId].autoassigns = next;
      }
    },
    [updateAutoAssign.fulfilled]: (state, action) => {
      const { id: intervenerId, automatisationIdWithSlash } = action.meta;
      const automatisationId = automatisationIdWithSlash.slice(1);

      const next = state.db[intervenerId].autoassigns.map((a) => {
        return a._id !== automatisationId ? a : { ...a, ...action.payload.data.autoassign };
      });

      state.db[intervenerId].autoassigns = next;
    },
    [deleteAutoAssign.fulfilled]: (state, action) => {
      const { id: elId, intervenerId } = action.meta;
      const next = state.db[intervenerId].autoassigns.filter((a) => a._id !== elId);
      state.db[intervenerId].autoassigns = next;
    },
    [createContract.fulfilled]: (state, action) => {
      ++state.db[action.meta.arg._intervener].tabs.contracts;
    },
    [deleteContract.fulfilled]: (state, action) => {
      --state.db[action.payload.data.element._intervener].tabs.contracts;
    },
    [setPartnership.fulfilled]: (state, action) => {
      const { id } = action.meta;
      const { element } = action.payload.data;

      state.db[id] = assign(state.db[id], element);
    }
  }
});

export const { flushElements, flushElement } = intervenersSlice.actions;
export default intervenersSlice.reducer;
