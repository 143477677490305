import { createSlice } from '@reduxjs/toolkit';
import { generateEntityBoilerplate } from 'utils/generateEntitySlice';
import generateAsyncThunk from 'utils/generateAsyncThunk';

export const createElement = generateAsyncThunk({ type: 'POST', endpoint: 'suppliers' });
export const updateElement = generateAsyncThunk({ type: 'PUT', endpoint: 'suppliers/${id}' });
export const getElement = generateAsyncThunk({ type: 'GET', endpoint: 'suppliers/${id}' });
export const getElements = generateAsyncThunk({ type: 'POST', endpoint: 'suppliers/list' });
export const deleteElement = generateAsyncThunk({ type: 'DELETE', endpoint: 'suppliers/${id}' });
export const restoreElement = generateAsyncThunk({
  type: 'POST',
  endpoint: 'suppliers/recycle/${id}'
});

const entityBoilerplate = generateEntityBoilerplate({
  createElement,
  updateElement,
  getElement,
  getElements,
  deleteElement,
  restoreElement
});

const { initialState, reducers, extraReducers } = entityBoilerplate;

export const suppliersSlice = createSlice({
  name: 'suppliers',
  initialState,
  reducers,
  extraReducers
});

export const { flushElements, flushElement, updateDocuments } = suppliersSlice.actions;
export default suppliersSlice.reducer;
