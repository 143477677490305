import DMS from 'modules/documents';

export default function GEDPage() {
  return (
    <>
      <div className="h-[600px]">
        <DMS />
      </div>
    </>
  );
}
