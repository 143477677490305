import axios from 'axios';
import { template } from 'lodash-es';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { nanoid } from 'nanoid';
import { apiBaseURL } from 'index';
import { ACCESS_TOKEN } from 'constants/localStorage';
import { supabase } from './supabaseClient';

async function thunkReducer({ type, endpoint, params, formData, rest, headers }) {
  const compiledURL = template(endpoint);
  const apiURL = `${apiBaseURL}/${compiledURL(params)}`;

  switch (type) {
    case 'GET':
      return await axios.get(apiURL, { params: { ...rest } });
    case 'GET-BLOB':
      return await axios.get(apiURL, { params: { ...rest }, responseType: 'blob' });
    case 'PUT':
      return await axios.put(apiURL, { ...rest });
    case 'DELETE':
      return await axios.delete(apiURL, { params: { ...rest } });
    case 'FILE':
      return await axios.post(apiURL, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
    case 'FILE-PUT':
      return await axios.put(apiURL, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
    default:
      return await axios.post(apiURL, { ...rest }, { ...headers });
  }
}

const generateAsyncThunk = ({ type = 'POST', endpoint, headers = {} }) => {
  return createAsyncThunk(
    // We use nanoid to avoid collisions between different thunks with identical names
    `TYPE=${type}&ENDPOINT=${endpoint}&ID=${nanoid()}`,
    async (args = {}, { signal, fulfillWithValue, rejectWithValue }) => {
      const source = axios.CancelToken.source();

      // TODO: Listen for cancel events and handle them on the server.
      //  Ex: https://stackoverflow.com/questions/35198208/handling-cancelled-request-with-express-node-js-and-angular
      signal.addEventListener('abort', () => source.cancel());

      const { params, formData, ...rest } = args;

      return await thunkReducer({ type, endpoint, params, formData, rest, headers })
        .then(({ data, status }) => fulfillWithValue({ data, status }, params))
        .catch(async ({ response }) => {
          if (response?.status === 401) {
            await axios.post(`${apiBaseURL}/auth/logout`, {}).then(async () => {
              localStorage?.removeItem(ACCESS_TOKEN);
              await supabase.auth.signOut();
              window.location.href = '/';
            });
          }
          return rejectWithValue(response, params);
        });
    }
  );
};

export default generateAsyncThunk;
