import { Checkbox, FormControlLabel, Slider } from '@material-ui/core';
import DateField from 'components/inputs/DateField';
import { differenceInCalendarDays, sub } from 'date-fns';
import { Field, Form, Submit, useFieldObserver, useForm } from 'frmx';
import { pick } from 'lodash-es';
import { nanoid } from 'nanoid';
import { useMemo, createElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'utils/classNames';
import TagPicker from '../TagPicker';
import { TagCloud } from './DocumentInfo';
import DialogMUI from 'components/DialogMUI';
import EntityX from 'components/inputs/EntityX';
import Manager from 'entities/Manager/Manager';

// TODO: name should be at least 3 characters long
export default function EditDocumentDialog({ document, setDocument, editDocument }) {
  const { t } = useTranslation();
  const { tags } = useSelector((state) => state.tags);

  const handle_submit = (values) => {
    const convert_alert_deadline_into_date = values?.deadline
      ? values.alert_deadline === 0
        ? new Date(values?.deadline)
        : sub(new Date(values?.deadline), { days: Number(values.alert_deadline) })
      : null;

    editDocument({
      _id: document._id,
      ...values,
      alert_deadline: convert_alert_deadline_into_date
    });
    setDocument(null);
  };

  const count_days_between_two_dates = (date1, date2) => {
    if (typeof date1 === 'undefined' || typeof date2 === 'undefined') return 0;

    return differenceInCalendarDays(new Date(date1), new Date(date2));
  };

  return document ? (
    <>
      <DialogMUI
        icon="pen-to-square"
        isOpen={!!document}
        title={t('editDocument')}
        className="w-[500px] z-[1400]"
        onClose={() => setDocument(null)}
      >
        <Form
          disableIfNoUpdates
          initialValues={{
            ...pick(document, [
              'name',
              'deadline',
              'description',
              'tags',
              'metadata',
              'watcher_ids'
            ]),
            alert_deadline:
              count_days_between_two_dates(document?.deadline, document?.alert_deadline) || 0
          }}
          onSubmit={(values) => handle_submit(values)}
        >
          <div className="flex flex-col mt-4 mb-6 space-y-4">
            <Input
              path="name"
              label={t('name')}
              placeholder={t('myDocument')}
            />
            <Input
              path="description"
              label={t('description')}
              placeholder={t('myDocumentDescription')}
              tag="textarea"
              resize="vertical"
              rows={4}
            />
            <TagPicker
              options={tags}
              path="tags"
            />

            <ExpiredDateDocument />

            {Array.isArray(document.metadata?.locations) &&
              document.metadata?.locations?.length > 0 && (
                <TagCloud
                  label="locations"
                  tags={document.metadata.locations}
                />
              )}
            {Array.isArray(document.metadata?.equipments) &&
              document.metadata?.equipments?.length > 0 && (
                <TagCloud
                  label="equipmentsTitle"
                  tags={document.metadata.equipments}
                />
              )}
            {Array.isArray(document.metadata?.preventifs) &&
              document.metadata?.preventifs?.length > 0 && (
                <TagCloud
                  label="maintenanceLabel"
                  tags={document.metadata.preventifs}
                />
              )}

            {Array.isArray(document.metadata?.companies) &&
              document.metadata?.companies?.length > 0 && (
                <TagCloud
                  label="societiesLabel"
                  tags={document.metadata.companies}
                />
              )}
          </div>
          <div className="flex justify-end space-x-2 mb-5">
            <button
              className="border border-red-500 text-red-500 px-3 py-2 rounded-full"
              onClick={() => setDocument(null)}
            >
              {t('cancel')}
            </button>
            <Submit>
              <button className="bg-sky-900 disabled:opacity-50 text-white px-3 py-2 rounded-full">
                {t('save2')}
              </button>
            </Submit>
          </div>
        </Form>
      </DialogMUI>
    </>
  ) : null;
}

function Input({ tag = 'input', label, placeholder = '', type = 'text', path, ...rest }) {
  const id = useMemo(() => nanoid(), []);

  return (
    <div>
      {label && (
        <label
          htmlFor={id}
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
      )}
      <div className="mt-1">
        <Field path={path}>
          {createElement(tag, {
            type,
            id,
            className: classNames(
              'pl-2 py-2 w-full text-base shadow-sm focus:ring-sky-500 focus:border-slate-400 border border-gray-300 rounded-md',
              tag === 'input' && type === 'text' && 'h-14'
            ),
            placeholder,
            ...rest
          })}
        </Field>
      </div>
    </div>
  );
}

function ExpiredDateDocument() {
  const { t } = useTranslation();
  const alert_deadline = useFieldObserver('alert_deadline');
  const has_deadline = useFieldObserver('deadline');
  const watcher_ids = useFieldObserver('watcher_ids');
  const has_watchers = Array.isArray(watcher_ids) && watcher_ids.length > 0;

  const { setOneField } = useForm();
  const [checked_notifications, set_checked_notifications] = useState(has_watchers);

  const handle_check_notifications = (value) => {
    set_checked_notifications(value);
    if (!value) setOneField('watcher_ids', []);
  };

  return (
    <>
      <div>
        <label className="block mb-1 text-sm font-medium text-gray-700">{t('dueDateLabel')}</label>
        <DateField
          path="deadline"
          placeholder="Cliquez pour sélectionner une date"
        />
        {has_deadline && (
          <div className="w-full pt-2">
            <>
              <span>
                {t('toMonitorXDays', { count: alert_deadline })}{' '}
                {alert_deadline > 1 ? `${t('days')}` : `${t('day')}`} {t('beforeDeadline')}
              </span>
              <Field
                disabled={!has_deadline}
                path="alert_deadline"
                type="range"
                getValueFromArgs={(args) => args[1]}
              >
                <Slider
                  min={0}
                  max={90}
                  step={1}
                  valueLabelDisplay="auto"
                />
              </Field>
              <div className="w-full mb-2">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked_notifications}
                      onChange={(e) => handle_check_notifications(e.target.checked)}
                    />
                  }
                  label={
                    <ul className="mb-2">
                      <li className="text-xs">{t('notifyWatchersWhenDocumentIsExpired')}</li>
                      <li className="text-xs">{t('notifyWatchersWhenDocumentIsAlertExpired')}</li>
                    </ul>
                  }
                />
              </div>

              {checked_notifications && (
                <EntityX
                  path="watcher_ids"
                  entityName="managers"
                  placeholder={t('managers')}
                  entity={Manager}
                />
              )}
            </>
          </div>
        )}
      </div>
    </>
  );
}
