import {
  TextField,
  AccordionActions,
  AccordionDetails,
  Grid,
  Box,
  Button,
  MenuItem,
  FormHelperText
} from '@material-ui/core';

import { useRef } from 'react';
import { useAuth } from 'hooks/useAuth';
import useNotifications from 'hooks/useNotifications';
import { setCompanyAvatar } from 'store/authSlice';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import { setCompanyInfo } from 'store/authSlice';
import { Field, Submit, Reset } from 'frmx';

import AccordionForm from 'components/forms/AccordionForm';
import PhoneField from 'components/inputs/PhoneField';
import Avatar from 'components/Avatar';

import useFormStyles from 'styles/Form.styles';
import useStylesAvatar from './AvatarInfo.styles';
import { useTranslation } from 'react-i18next';
import { useRole } from 'hooks/useRole';
import currency from 'constants/currency';
import { languages } from 'config/languages';
import Flag from 'react-flagkit';
import { flag_keys } from 'constants/locales';
import useKeys from '@flowsn4ke/usekeys';

export default function CompanyInfo(props) {
  const { section } = props;
  const auth = useAuth();
  const company = auth.interface._company;
  const { dispatch, requestStatus } = useAsyncDispatch();
  const notify = useNotifications();
  const dispatchCallbacks = {
    onSuccess: () => notify.success(),
    onError: () => notify.error()
  };

  const refFile = useRef(null);

  const { t } = useTranslation();

  const selectAvatar = () => requestStatus !== 'loading' && refFile.current.click();
  const onChangeFile = (e) => {
    if (e.target.files?.length && e.target.files[0]) {
      const formData = new FormData();
      formData.append('avatar', e.target.files[0]);

      uploadAvatar(formData);
    }
  };

  const uploadAvatar = (formData = new FormData()) => {
    dispatch(setCompanyAvatar, { formData }, dispatchCallbacks);
  };

  const k = useKeys();

  const formClasses = useFormStyles();
  const classesAvatar = useStylesAvatar();

  const clientType = auth.interface.isClient ? 'client' : 'contractor';
  const nameKey = clientType === 'client' ? 'name' : 'companyName';

  const { permission } = useRole();

  const userCanModifyCompanyInfo = permission('bobdesk', 'update_company_info');

  return (
    <AccordionForm
      className={classesAvatar.accordionAvatar}
      section={section}
      initialValues={{
        [clientType]: {
          [nameKey]: company?.name || '',
          phone: company?.phone || '',
          email: company?.email || '',
          siret: company?.siret || '',
          currency: company?.currency || '',
          currencyCode: company?.currency?.code || 'EUR',
          lang: company?.lang || 'fr'
        }
      }}
      onSubmit={async (updates) => {
        const currencyData = currency.find((c) => c.code === updates[clientType].currencyCode);
        updates[clientType].currency = currencyData;
        await dispatch(setCompanyInfo, updates, dispatchCallbacks);
      }}
      allwaysExpanded
    >
      <AccordionDetails>
        <Box className={classesAvatar.avatar}>
          <Avatar
            isLoading={requestStatus === 'loading'}
            isUpdate
            onClick={selectAvatar}
            button
            entity={auth.interface._company}
            size={'large2'}
          />
        </Box>

        <input
          type="file"
          ref={refFile}
          className={classesAvatar.file}
          onChange={onChangeFile}
        />

        <Grid
          container
          spacing={2}
          style={{ paddingTop: 42, width: '100%' }}
        >
          <Grid
            item
            xs={12}
          >
            <Field
              path={`${clientType}.${nameKey}`}
              disabled={!userCanModifyCompanyInfo}
            >
              <TextField
                variant="outlined"
                fullWidth
                label={t('companyName')}
              />
            </Field>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <Field
              path={`${clientType}.email`}
              disabled={!userCanModifyCompanyInfo}
            >
              <TextField
                variant="outlined"
                fullWidth
                label={t('email')}
              />
            </Field>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <PhoneField
              path={`${clientType}.phone`}
              variant="outlined"
              label={t('phoneNumber')}
              placeholder={t('phoneNumber')}
              disabled={!userCanModifyCompanyInfo}
            />
          </Grid>

          <Grid
            item
            xs={12}
          >
            <Field
              path={`${clientType}.siret`}
              disabled={!userCanModifyCompanyInfo}
            >
              <TextField
                variant="outlined"
                fullWidth
                label={t('siret')}
              />
            </Field>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <Field
              path={`${clientType}.currencyCode`}
              disabled={!userCanModifyCompanyInfo}
            >
              {/* selectionner une devise default devise euros */}
              <TextField
                select
                variant="outlined"
                fullWidth
                label={t('currency')}
              >
                {currency.map((opt, opt_idx) => (
                  <option
                    key={opt_idx}
                    value={opt.code}
                    className="p-3 hover:bg-gray-100 cursor-pointer rounded-md text-base"
                  >
                    {opt.symbol} - {opt.code}
                  </option>
                ))}
              </TextField>
            </Field>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <Field
              path={`${clientType}.lang`}
              disabled={!userCanModifyCompanyInfo}
            >
              <TextField
                select
                variant="outlined"
                fullWidth
                label={t('globalLang')}
              >
                {languages.map((language, i) => (
                  <MenuItem
                    key={k(i)}
                    value={language.key}
                    className="p-3 hover:bg-gray-100 cursor-pointer rounded-md text-base"
                  >
                    <div className="flex items-center gap-2">
                      <Flag
                        country={flag_keys[language.key]}
                        className="h-3"
                      />
                      {language.label}
                    </div>
                  </MenuItem>
                ))}
              </TextField>
            </Field>
            <FormHelperText>{t('global-lang-helper')}</FormHelperText>
          </Grid>
        </Grid>
      </AccordionDetails>
      {/* <Divider /> */}
      <AccordionActions>
        <Reset>
          <Button size="small">{t('cancel')}</Button>
        </Reset>
        <Submit disabled={requestStatus === 'loading'}>
          <Button
            className={formClasses.success}
            size="small"
            color="primary"
          >
            {t('save')}
          </Button>
        </Submit>
      </AccordionActions>
    </AccordionForm>
  );
}
