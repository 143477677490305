import { Box, Chip, List } from '@material-ui/core';

import ViewItem from 'components/views/ViewItem';
import { DescriptionItem } from 'components/views/ViewItems';
import periodicities from 'constants/periodicities';

import useStyles from 'layouts/entities/View.styles';
import CustomFields from '../CustomFields';
import { useTranslation } from 'react-i18next';

export default function MaintenanceView({ element }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Box className={classes.l1}>
        <Box
          alignItems="center"
          display="flex"
          flexGrow={1}
          width="100%"
        >
          <Box
            flexDirection="column"
            alignItems="flex-start"
            flexGrow={1}
            width="100%"
          >
            <Box className={classes.l1left}>{element.name}</Box>
            {
              <Box
                height="52px"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box>
                  {element?.compute && (
                    <Chip
                      className={classes.l1chip}
                      color="secondary"
                      size="small"
                      style={{
                        backgroundColor: element.compute === 'regulatory' ? '#c0392b' : '#2980b9'
                      }}
                      label={element.compute === 'regulatory' ? 'Réglementaire' : 'Calendaire'}
                    />
                  )}
                </Box>

                <Box
                  display={'flex'}
                  alignItems={'center'}
                  position={'relative'}
                  left={12}
                >
                  {/* {!!element._managers && <>
                <Box>
                  {!!element._managers.length && element._managers.map((manager, i) => (
                    <UserChip key={k(i)} user={manager} type={auth.interface.type} />
                  ))}
                </Box>

                {auth.interface.isManager && !element._managers.find(manager => manager._id === auth.user._id) && <Tooltip placement={"left"} title={"M'attacher a la maintenance"}>
                  <IconButton size="small" onClick={() => alert('ATTACH')}>
                    <FAIcon collection="fal" icon="user-headset" style={{ color: '#a9afb5' }} />
                  </IconButton>
                </Tooltip>}
              </>} */}
                </Box>
              </Box>
            }
          </Box>
        </Box>
      </Box>

      <List className={classes.list}>
        <CustomFields element={element}>
          <DescriptionItem description={element?.description} />

          {element?.defaults?.autoAssign && (
            <ViewItem
              icon="bolt"
              secondaryIcon
              primary="Auto-assignation"
              secondary={t('activatedF')}
            />
          )}

          <ViewItem
            icon="infinity"
            secondaryIcon
            primary={t('periodicity')}
            secondary={t(periodicities[element.defaults.config.type])}
          />

          <ViewItem
            icon="calendar-day"
            secondaryIcon
            primary={t('plannification')}
            secondary={t('planXDaysBeforePeriodEnd', { count: element.defaults.planning.day })}
          />

          <ViewItem
            icon="bell-on"
            secondaryIcon
            primary={t('reminder')}
            secondary={t('notificationBeforeXDays', { count: element.defaults.alert.day })}
          />
        </CustomFields>
      </List>
    </>
  );
}
