import { IconButton, Tooltip } from '@material-ui/core';
import FilterSetsManager from 'components/filters/FilterSetsManager';
import Menu from 'components/menu/Menu';
import FAIcon from 'components/ui/FAIcon';
import { useForm, useResetListener } from 'frmx';
import useDebouncedState from 'hooks/useDebouncedState';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function SearchBar({
  totalResults,
  filterSetsManager,
  setFilterSetsManager,
  path,
  menuItems,
  downloadLoading,
  resizeable,
  setResize,
  setInitialValues,
  appliedFilters,
  dataFilters
}) {
  const { t } = useTranslation();
  const { resetForm, getOneField, setOneField } = useForm();

  const [search, setSearch, debouncedSearch] = useDebouncedState(getOneField(path));

  useEffect(() => {
    getOneField(path) !== debouncedSearch && setOneField(path, debouncedSearch);
  }, [debouncedSearch]);
  useResetListener(() => setSearch(''));

  return (
    <div className="w-full flex gap-1 items-center justify-around h-12 px-2">
      <Menu
        button={() => (
          <IconButton size="small">
            <FAIcon
              collection="fas"
              icon={downloadLoading ? 'spinner-third' : 'ellipsis-vertical'}
              size="medium"
              className={`${downloadLoading && 'animate-spin'}`}
            />
          </IconButton>
        )}
        items={menuItems}
      />
      <input
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        className="block w-full sm:text-sm md:text-md p-1 outline-none"
        placeholder={`Rechercher ${typeof totalResults === 'number' ? `parmi les ${totalResults} résultats` : ''}`}
        focus="true"
      />
      <div className="flex items-center justify-center gap-2 mr-2">
        <Tooltip
          title={t('resetFilters')}
          placement="left"
        >
          <IconButton
            button
            onClick={() => resetForm()}
            className="!p-1.5"
          >
            <FAIcon
              collection="far"
              icon="circle-xmark"
              size="small"
            />
          </IconButton>
        </Tooltip>
        {resizeable && (
          <IconButton
            button
            onClick={() => setResize((prev) => !prev)}
          >
            <FAIcon
              collection="far"
              icon="chevron-left"
              size="sm"
            />
          </IconButton>
        )}
        {filterSetsManager && (
          <FilterSetsManager
            dataFilters={dataFilters}
            appliedFilters={appliedFilters}
            setInitialValues={setInitialValues}
            filterSetsManager={filterSetsManager}
            setFilterSetsManager={setFilterSetsManager}
          />
        )}
      </div>
    </div>
  );
}
