import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import enTranslations from 'translations/en/translation.json';
import frTranslations from 'translations/fr/translation.json';
import itTranslations from 'translations/it/translation.json';
import deTranslations from 'translations/de/translation.json';
import esTranslations from 'translations/es/translation.json';
import ptTranslations from 'translations/pt/translation.json';
import jaTranslations from 'translations/ja/translation.json';
import koTranslations from 'translations/ko/translation.json';
import viTranslations from 'translations/vi/translation.json';
import arTranslations from 'translations/ar/translation.json';
import zhTranslations from 'translations/zh/translation.json';
import idTranslations from 'translations/id/translation.json';

import { enGB, fr, it, de, es, pt, ja, ko, vi, ar, zhCN, id } from 'date-fns/locale';

const availableLanguages = ['fr', 'en', 'it', 'de', 'es', 'pt', 'ja', 'ko', 'vi', 'ar', 'zh', 'id'];

export const locales = { it, fr, en: enGB, de, es, pt, ja, ko, vi, ar, zh: zhCN, id };

const options = {
  order: ['localStorage', 'navigator', 'htmlTag'],
  lookupLocalStorage: 'i18nextLng',
  caches: ['localStorage'],
  htmlTag: document.documentElement,
  checkWhitelist: true
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    whitelist: availableLanguages,
    detection: options,
    debug: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    },
    resources: {
      en: { translation: enTranslations },
      fr: { translation: frTranslations },
      it: { translation: itTranslations },
      de: { translation: deTranslations },
      es: { translation: esTranslations },
      pt: { translation: ptTranslations },
      ja: { translation: jaTranslations },
      ko: { translation: koTranslations },
      vi: { translation: viTranslations },
      ar: { translation: arTranslations },
      zh: { translation: zhTranslations },
      id: { translation: idTranslations }
    }
  })
  .then(() => (window.__localeId__ = i18n.language));

i18n.on('languageChanged', (lng) => (window.__localeId__ = lng));

export default i18n;
