import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { Virtuoso } from 'react-virtuoso';
import GroupedVirtuosoHiba from './GroupedVirtuosoHiba';
import TicketPreviewNew from './TicketPreviewNew';

/* --------------------------------------------------------------------------------- virtuoso  */

export default function HibaPreviewComponent({ entity, data }) {
  // return (
  //   <Virtuoso
  //   data={arr}
  //   itemContent={(_,element) => (
  //     <TicketPreviewNew ticketData={element} />
  //   )}
  // />
  // );
  const location = useLocation();
  const ticketPaths = ['calendar'];
  const isTicketUrl = entity === 'tickets' && ticketPaths.some((path) => !location.pathname.includes(path));

  // if (isTicketUrl) return <GroupedVirtuosoHiba
  //   arrTicket={arr}
  //   loading={false}
  // />

  if (isTicketUrl)
    return (
      <GroupedVirtuosoHiba
        arrTicket={data}
        loading={false}
      />
    );

  // return <Virtuoso
  //   data={arr}
  //   itemContent={(_, element) => (
  //     <TicketPreviewNew ticketData={element} />
  //   )}
  // />

  return (
    <Virtuoso
      data={data}
      itemContent={(_, element) => <TicketPreviewNew ticketData={element} />}
    />
  );

  // return (
  //   <>
  //   {arr && arr.map(element => {
  //     return <TicketPreviewNew ticketData={element}/>
  //   })}
  //   </>
  // );
}

function getRandomTimestamp() {
  const now = new Date().getTime();
  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
  const oneYearAgoTime = oneYearAgo.getTime();

  const randomTimestamp = oneYearAgoTime + Math.random() * (now - oneYearAgoTime);
  return Math.floor(randomTimestamp);
}

const metadata = [
  {
    k: 'location',
    t: '1924874jhksfdh981734',
    f: 'Magasin Paris; le meilleur du monde::4, avenue de la République 75004 Paris France::Magasin'
  },
  {
    k: 'location',
    t: '298374ksjdhfi27834',
    f: 'Bureau Lyon; siège social::10, rue de la Liberté 69003 Lyon France::Bureau'
  },
  {
    k: 'location',
    t: '39847kjdhf8234',
    f: "Entrepôt Marseille; distribution::15, boulevard de l'Europe 13010 Marseille France::Entrepôt"
  },
  {
    k: 'equipment',
    t: '3487fhjkshd9032',
    f: 'Plomberie::Outils::Maintenance'
  },
  {
    k: 'equipment',
    t: '67834jhfks87234',
    f: 'Électricité::Câbles::Installation'
  },
  {
    k: 'equipment',
    t: '12834kjdhf98234',
    f: 'Mécanique::Machines::Réparation'
  },
  {
    k: 'job',
    t: '9834hfskdjhf8273',
    f: 'Technicien::Installation::Réparations'
  },
  {
    k: 'job',
    t: '2938fhsdkjfh8273',
    f: 'Ingénieur::Développement::Projet'
  },
  {
    k: 'job',
    t: '4892hfjkshdf9234',
    f: 'Gestionnaire::Logistique::Supervision'
  }
];

const meta = [
  {
    k: 'location',
    t: '1924874jhksfdh981734',
    f: 'Magasin Paris; le meilleur du monde::4, avenue dela Republique 75004 Paris France::Magasin'
  },
  {
    k: 'equipment',
    t: '3487fhjkshd9032',
    f: 'climatisation::Outils::Maintenance'
  },
  {
    k: 'job',
    t: '9834hfskdjhf8273',
    f: 'Technicien'
  }
];

// --------------------------------------------------------------------------------------------------------
const ticketData = {
  _id: 'tfvduze-èçyè',
  p: 'parent',
  i: '1563938239373gytz6372936',
  c: 'hibadesk',
  t: 'Levée de réserves #1976',
  d: "Intervention demandée suite à l'intervention de maintenance en #Maintenance climatisation - #1976. Nous vous sollicitons pour procéder à la levée de réserves en pièce-jointe.",
  n: '2532',
  m: meta
};
const ticketData2 = {
  _id: 'tfvduze-èçyè',
  p: 'parent',
  i: '1563938239373gytz6372936',
  c: 'hibadesk',
  t: 'Test hiba long tiltle text to see the outcome of the codeeeeeee howww does it behave is it going to take all the char or ??? today is pmonday i had a hard time waking up because of the weekend',
  d: 'subdzbhy byeti zdve ydivgzvydzgyvdizeyzyegd , Nous vous sollicitons pour procéder à la levée de réserves en pièce-jointe.',
  n: '9832',
  m: metadata
};
