import { FormHelperText, ListItem, ListItemSecondaryAction, TextField } from '@material-ui/core';
import IconButton from 'components/IconButton';
import DateField from 'components/inputs/DateField';
import FAIcon from 'components/ui/FAIcon';
import { Field, Form, Reset, Submit } from 'frmx';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import useNotifications from 'hooks/useNotifications';
import { useTranslation } from 'react-i18next';
import { update_reading } from 'store/metersSlice';

export default function UpdateReadingForm({ meter_id, reading_to_update, on_close }) {
  const { t } = useTranslation();

  const { dispatch, requestStatus } = useAsyncDispatch();

  const notify = useNotifications();
  const dispatchCallbacks = {
    onSuccess: () => notify.success(t('readingUpdated')),
    onError: () => notify.error()
  };

  const handle_edit_reading = (data) => {
    dispatch(
      update_reading, // action
      data, // args
      dispatchCallbacks, // callbacks
      {
        // params
        id: meter_id,
        reading_id: reading_to_update._id
      }
    ).then(() => on_close());
  };

  return (
    <Form
      initialValues={{
        value: reading_to_update.value,
        date: new Date(reading_to_update.at)
      }}
      onSubmit={(data) => handle_edit_reading(data)}
    >
      <ListItem>
        <div className="flex">
          <div>
            <Field
              path="value"
              type="number"
            >
              <TextField
                variant="outlined"
                type="number"
                inputProps={{
                  min: 0
                }}
              />
            </Field>
            <FormHelperText>{t('valueOfReading')}</FormHelperText>
          </div>
          <div className="ml-2">
            <DateField
              path="date"
              clearable={false}
              disableFuture
              minDate={new Date(reading_to_update.at)}
            />
            <FormHelperText>{t('dateOfReading')}</FormHelperText>
          </div>
        </div>
      </ListItem>
      <ListItemSecondaryAction className="flex">
        <Reset onClick={on_close}>
          <IconButton className="text-red-600">
            <FAIcon
              icon="xmark"
              collection="fad"
              size="small"
            />
          </IconButton>
        </Reset>
        <Submit disabled={requestStatus === 'loading'}>
          <IconButton className="text-green-500">
            <FAIcon
              icon="check"
              collection="fad"
              size="small"
            />
          </IconButton>
        </Submit>
      </ListItemSecondaryAction>
    </Form>
  );
}
