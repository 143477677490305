import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  header: {
    background: '#f1f3f4',
    position: 'relative',
    alignItems: 'center',
    fontSize: 12,
    height: 32,
    minHeight: 32,
    paddingLeft: 16,
    fontWeight: 500,
    ['& svg']: {
      marginRight: 16,
      fontSize: 10
    }
  },
  filter: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  entity: {
    maxHeight: 320,
    height: 320
  },
  list: {
    maxHeight: 200,
    backgroundColor: 'white',
    overflowY: 'auto'
  },
  listItem: {
    paddingLeft: 4
  },
  listItemIn: {
    backgroundColor: theme.palette.grey['100']
  },
  listSearch: {
    zIndex: 1,
    height: 38,
    padding: '4px 12px 4px 16px',
    minHeight: 'auto',
    background: 'white'
  },
  listEmpty: {
    fontWeight: 500,
    textAlign: 'center',
    padding: '24px 0px'
  },
  listTabs: ({ page, indicatorColor }) => ({
    zIndex: 500,
    display: 'flex',
    background: 'white',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0
    },
    ['& .MuiTabs-indicator']: {
      backgroundColor: !!indicatorColor ? indicatorColor : theme.palette.primary.main,
      borderRadius: '8px 8px 0px 0px'
    }
  }),
  listTabsTab: () => ({
    color: '#4f5b62',
    ['&.Mui-selected']: {
      color: theme.palette.primary.main
    }
  }),
  listTabsBox: {
    fontSize: 12,
    color: 'inherit',
    fontWeight: 500,
    alignItems: 'center',
    display: 'flex',
    padding: '0px 10px',
    ['& svg']: {
      color: 'inherit',
      fontSize: 14
    }
  },
  listTabsLabel: {
    marginLeft: 10
  }
}));
