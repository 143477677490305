import {
  getElement,
  getElements,
  // createElement,
  updateElement,
  flushElements,
  deleteElement,
  restoreElement
} from 'store/reportsSlice';

import ReportMenus from './ReportMenus';
import ReportPreview from './ReportPreview';
import ReportSkeleton from './ReportSkeleton';
import ReportView from './ReportView';
import ReportTabs from './ReportTabs';
import ReportFilters, { formatFilter } from './ReportFilters';
import ReportFormValues, { ReportValidation } from './ReportFormValues';
import ReportTranslations from './ReportTranslations';
import ReportExport from './ReportExport';
import ReportSorts from './ReportSorts';

import EntityWrapper from 'entities/EntityWrapper';

export default function Report(props) {
  return (
    <EntityWrapper
      entity="reports"
      entityFieldsSlice="report"
      skeletonComponent={ReportSkeleton}
      previewComponent={ReportPreview}
      viewComponent={ReportView}
      formatFilter={formatFilter}
      getFilters={ReportFilters}
      getInitialValues={ReportFormValues}
      getFormValidation={ReportValidation}
      getTabs={ReportTabs}
      getMenus={ReportMenus}
      getExportColumns={ReportExport}
      getTranslations={ReportTranslations}
      getSorts={ReportSorts}
      getTables={() => []}
      getElement={getElement}
      getElements={getElements}
      //  Due to the structure of the fieldsSection collection
      //  We have to manually create the element
      //  Therefore the create modal actually needs the update element action
      createElement={updateElement}
      updateElement={updateElement}
      flushElements={flushElements}
      deleteElement={deleteElement}
      restoreElement={restoreElement}
      updateOnClick
      {...props}
    />
  );
}
