import Client from 'entities/Client/Client';
import Intervener from 'entities/Intervener/Intervener';
import FAIcon from 'components/ui/FAIcon';

const CustomerFilter = (configuration, { double }) => [
  {
    type: 'Tab',
    icon: (
      <FAIcon
        icon={'house-building'}
        collection={'fad'}
      />
    ),
    key: 'clients',
    label: 'clientsTitle',
    render: () => (
      <Client
        disableGutters
        page
        list
        double={double}
      />
    ),
    disableSearch: true,
    default: true,
    show: true
  },
  {
    type: 'Tab',
    icon: (
      <FAIcon
        icon={'user-hard-hat'}
        collection={'fad'}
      />
    ),
    key: 'contractors',
    render: () => (
      <Intervener
        disableGutters
        page
        list
        type="contractor"
        double={double}
      />
    ),
    disableSearch: true,
    label: 'contractorsTitle',
    default: false,
    show: true
  }
];

export default CustomerFilter;
