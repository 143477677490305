import { VpnKey, Visibility } from '@material-ui/icons';
import { resetPassword } from 'utils/generateEntitySlice';

const UserMenus = (
  element,
  auth,
  dispatch,
  role,
  companyPermission,
  listId,
  setIsMenuLoading,
  notify
) =>
  [
    {
      label: 'resendConnectionLink',
      action: () =>
        dispatch(
          resetPassword,
          { email: element._user.email },
          { onSuccess: () => notify.success('Email envoyé'), onError: () => notify.error() },
          { id: element._id }
        ),
      icon: <VpnKey />,
      show: element._user?.email?.length
    },
    {
      label: 'reinitializationLink',
      action: () =>
        prompt(
          'Lien de réinitialisation',
          'https://bob-desk.com/set-password/' + element._user.resetPasswordToken
        ),
      icon: <Visibility />,
      show: auth.interface.isManager && !!element._user && !!element._user.resetPasswordToken
    }
  ].filter((e) => e.show);

export default UserMenus;
