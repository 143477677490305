import { TextField as MuiTextField } from '@material-ui/core';
import { Field } from 'frmx';
import { useTranslation } from 'react-i18next';

import FieldBase from './FieldBase';

export default function TextField({ lineSizeOption }) {
  const { t } = useTranslation();
  return (
    <FieldBase
      lineSizeOption={lineSizeOption}
      mandatoryOption
    >
      <Field path="extra.defaultValue">
        <MuiTextField
          label={t('valueByDefault')}
          fullWidth
          variant="outlined"
        />
      </Field>
    </FieldBase>
  );
}
