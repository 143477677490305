import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  fileViewer: {
    width: '100vw',
    position: 'relative',
    display: 'flex',
    backgroundColor: '#00000099',
    flexDirection: 'row'
  },
  actionsFileViewer: {
    position: 'absolute',
    bottom: 30,
    right: 30,
    zIndex: 130
  },
  fileViewerContent: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    position: 'relative',
    paddingTop: '0px',
    backgroundColor: 'rgba(255,255,255, 0)'
  },
  content: {
    flexGrow: 1
  },
  pdfViewer: {
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(255,255,255, 0)'
  },
  iconFileViewer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'absolute',
    left: 0,
    top: 0
  },
  iconFileViewerRight: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'absolute',
    right: 0,
    top: 0
  },
  buttonFileViewer: {
    width: '64px',
    height: '64px',
    color: 'white'
  },
  buttonBack: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
    padding: '17px',
    margin: '10px 0px 0px 10px',
    paddingLeft: '20px'
  },
  iconViewer: {
    width: '30px',
    height: '30px'
  },
  boxArrowSlideViewer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minWidth: '150px',
    alignItems: 'center'
  }
}));
