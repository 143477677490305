import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => {
  return {
    '@global': {
      body: {
        overflow: 'hidden'
      },
      '*::-webkit-scrollbar': {
        width: '2px',
        height: '2px'
      },
      '*::-webkit-scrollbar-track': {
        backgroundColor: theme.palette.grey['100'],
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0, 0, 0, 0.3)'
      },
      '*::-webkit-scrollbar-thumb': {
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
        backgroundColor: '#bfc6ce'
      },
      '@keyframes swal2-animate-success-line-tip': {
        '0%': { top: '1.1875em', left: '.0625em', width: '0' },
        '54%': { top: '1.0625em', left: '.125em', width: '0' },
        '70%': { top: '2.1875em', left: '-.375em', width: '3.125em' },
        '84%': { top: '3em', left: '1.3125em', width: '1.0625em' },
        '100%': { top: '2.8125em', left: '.8125em', width: '1.5625em' }
      },
      '@keyframes swal2-animate-success-line-long': {
        '0%': { top: '3.375em', right: '2.875em', width: '0' },
        '65%': { top: '3.375em', right: '2.875em', width: '0' },
        '84%': { top: '2.1875em', right: '0', width: '3.4375em' },
        '100%': { top: '2.375em', right: '.5em', width: '2.9375em' }
      },
      '@keyframes swal2-rotate-success-circular-line': {
        '0%': { transform: 'rotate(-45deg)' },
        '5%': { transform: 'rotate(-45deg)' },
        '12%': { transform: 'rotate(-405deg)' },
        '100%': { transform: 'rotate(-405deg)' }
      },
      '*::-webkit-keyframes Gradient': {
        '0%': { backgroundPosition: '0% 50%' },
        '50%': { backgroundPosition: '100% 50%' },
        '100%': { backgroundPosition: '0% 50%' }
      },
      '*::-moz-keyframes Gradient': {
        '0%': { backgroundPosition: '0% 50%' },
        '50%': { backgroundPosition: '100% 50%' },
        '100%': { backgroundPosition: '0% 50%' }
      },
      '*::-o-keyframes Gradient': {
        '0%': { backgroundPosition: '0% 50%' },
        '50%': { backgroundPosition: '100% 50%' },
        '100%': { backgroundPosition: '0% 50%' }
      },
      '@keyframes Gradient': {
        '0%': { backgroundPosition: '0% 50%' },
        '50%': { backgroundPosition: '100% 50%' },
        '100%': { backgroundPosition: '0% 50%' }
      },
      '@keyframes spin': {
        from: { transform: 'rotate(0deg)' },
        to: { transform: 'rotate(360deg)' }
      },
      'input:-webkit-autofill,\ninput:-webkit-autofill:hover,\ninput:-webkit-autofill:focus,\ninput:-webkit-autofill:active':
        {
          transition: 'background-color 5000s ease-in-out 0s'
        }
    }
  };
});
