import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  popoverContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    justifyContent: 'center'
  },
  popoverContainerOffset: {
    bottom: 8,
    left: 8
  },
  underlyingComponent: {
    background: 'transparent',
    padding: 10
  },
  drawerContainer: {
    borderRadius: theme.radius,
    overflow: 'hidden',
    boxShadow: 'none',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    justifyContent: 'flex-start'
  },
  closeButton: {
    position: 'absolute',
    zIndex: '10000',
    top: 3,
    right: 0
  },
  icon: {
    fontSize: 18,
    width: 18
  }
}));
