import { Button, Collapse, Box } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function DisplayMore(props) {
  const { display = false, children, secondaryActionButton, thirdActionButton, ...rest } = props;
  const [displayMore, setDisplayMore] = useState(display);
  const [isHovered, setIsHovered] = useState(false);

  const { t } = useTranslation();
  const { label = t(displayMore ? 'displayLess' : 'displayMore') } = rest;

  return (
    <>
      <Button
        style={{
          marginTop: 6,
          marginBottom: 6,
          height: '50px',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => setDisplayMore((d) => !d)}
      >
        {label}
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          {isHovered && thirdActionButton && thirdActionButton}
          {isHovered && secondaryActionButton && secondaryActionButton}
          {displayMore ? <ExpandLess /> : <ExpandMore />}
        </Box>
      </Button>

      <Collapse
        in={displayMore}
        style={{ width: '100%' }}
      >
        {children}
      </Collapse>
    </>
  );
}
