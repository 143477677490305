import Api from 'entities/Api/Api';
import Client from 'entities/Client/Client';
import ContractProposition from 'entities/ContractProposition/ContractProposition';
import Equipment from 'entities/Equipment/Equipment';
import Guarantee from 'entities/Guarantee/Guarantee';
import Intervener from 'entities/Intervener/Intervener';
import Job from 'entities/Job/Job';
import Location from 'entities/Location/Location';
import Maintenance from 'entities/Maintenance/Maintenance';
import Manager from 'entities/Manager/Manager';
import Report from 'entities/Report/Report';
import Role from 'entities/Role/Role';
import Scope from 'entities/Scope/Scope';
import Ticket from 'entities/Ticket/Ticket';
import User from 'entities/User/User';
import Lease from 'entities/Lease/Lease';
import Contract from 'entities/Contract/Contract';
import ReportFile from 'entities/ReportFile/ReportFile';
import Supplier from 'entities/Suppliers/Supplier';
import Stock from 'entities/StockPage/Stock';
import Meter from 'entities/Meter/Meter';

const entities = {
  tickets: {
    icon: 'files',
    name: 'interventionTitle',
    entity: Ticket,
    exportEndpoint: 'ticket'
  },
  roles: {
    icon: 'key',
    name: 'rolesTitle',
    entity: Role
  },
  guarantees: {
    icon: 'shield-check',
    name: 'guaranteesTitle',
    entity: Guarantee
  },
  scopes: {
    icon: 'asterisk',
    name: 'valueTitle',
    entity: Scope
  },
  reports: {
    icon: 'file-chart-pie',
    name: 'reportsTitle',
    entity: Report
  },
  reportFiles: {
    icon: 'file-chart-pie',
    name: 'reportsTitle',
    entity: ReportFile
  },
  leases: {
    icon: 'house',
    name: 'leasesTitle',
    entity: Lease
  },
  contracts: {
    icon: 'file-contract',
    name: 'contractsTitle',
    entity: Contract
  },
  //TODO: TO RENAME ACCOUNTING
  accounting: {
    icon: 'calculator',
    name: 'accountingTitle'
  },
  locations: {
    icon: 'map-marker-alt',
    name: 'locationsTitle',
    chipKeys: ['name', 'address.fullAddress'],
    entity: Location,
    exportEndpoint: 'location'
  },
  equipments: {
    icon: 'wrench-simple',
    name: 'equipmentsTitle',
    entity: Equipment,
    exportEndpoint: 'equipment'
  },
  maintenances: {
    icon: 'heartbeat',
    name: 'maintenancesTitle',
    entity: Maintenance
  },
  periods: {
    icon: 'heartbeat',
    name: 'maintenancesTitle',
    entity: Maintenance
  },
  team: {
    icon: 'comment-alt',
    name: 'chatTitle'
  },
  users: {
    icon: 'user',
    name: 'usersTitle',
    chipKeys: ['name', 'lastName', 'firstName'],
    entity: User,
    exportEndpoint: 'contact'
  },
  technicians: {
    icon: 'user-check',
    name: 'techniciansTitle',
    chipKeys: ['companyName', 'lastName', 'firstName'],
    entity: Intervener,
    entityProps: { defaultTab: 'collaborator' },
    exportEndpoint: 'collaborator'
  },
  contractors: {
    icon: 'user-hard-hat',
    name: 'contractorsTitle',
    chipKeys: ['companyName', 'lastName', 'firstName'],
    entity: Intervener,
    entityProps: { defaultTab: 'mine' },
    exportEndpoint: 'contractor'
  },
  automatisations: {
    icon: 'bolt',
    name: 'automatisationsTitle',
    chipKeys: []
  },
  clients: {
    icon: 'house-building',
    name: 'clientsTitle',
    chipKeys: ['name', 'lastName', 'firstName'],
    entity: Client,
    exportEndpoint: 'client'
  },
  contractPropositions: {
    icon: 'mailbox',
    name: 'proposalsTitle',
    chipKeys: ['title'],
    entity: ContractProposition
  },
  managers: {
    icon: 'user-headset',
    name: 'managersTitle',
    chipKeys: ['lastName', 'firstName'],
    entity: Manager,
    exportEndpoint: 'user'
  },
  jobs: {
    icon: 'toolbox',
    name: 'jobsTitle',
    entity: Job,
    exportEndpoint: 'job'
  },
  bobdesk: {
    icon: 'cog',
    name: 'settingsTitle'
  },
  stocks: {
    icon: 'box-taped',
    name: 'inventoryTitle',
    entity: Stock,
    exportEndpoint: 'product'
  },
  apis: {
    icon: 'charging-station',
    name: 'apiTitle',
    entity: Api
  },
  suppliers: {
    icon: 'truck',
    name: 'suppliers',
    entity: Supplier,
    chipKeys: ['companyName']
  },
  documents: {
    icon: 'file-alt',
    name: 'documentsTitle'
  },
  meters: {
    icon: 'meter',
    name: 'metersTitle',
    entity: Meter
  }
};

export default entities;
