import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useEffect } from 'react';

export default function SystemsStatus() {
  const [isLoading, setIsLoading] = useState(true);
  const [systemsStatus, setSystemsStatus] = useState(true);

  const { t } = useTranslation();

  const statusCake = () => {
    fetch(`${process.env.REACT_APP_API_URL.replace('/api', '')}/statuscake`)
      .then((res) => {
        setSystemsStatus(Boolean(res.status === 200));
      })
      .catch(() => {
        setSystemsStatus(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    statusCake();
  }, []);

  if (isLoading)
    return (
      <div className="flex w-full mt-3 cursor-pointer justify-center items-center text-gray-400">
        <p>{t('loading...')}</p>
      </div>
    );

  return (
    <div
      className="flex w-full mt-3 cursor-pointer justify-center items-center text-gray-400"
      onClick={() => window.open('https://status.bob-desk.com')}
    >
      <svg
        className="w-3 h-3 mx-2"
        viewBox="0 0 10 10"
      >
        <circle
          cx="5"
          cy="5"
          r="5"
          className={systemsStatus ? 'fill-green-500' : 'fill-red-500'}
        />
      </svg>
      <p>{t(systemsStatus ? 'allSystemsOperational' : 'NotAllSystemsOperational')}</p>
    </div>
  );
}
