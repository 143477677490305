export const priorities = [
  { label: 'weak', value: '1' },
  { label: 'notSoWeak', value: '2' },
  { label: 'high', value: '3' },
  { label: 'critical', value: '4' }
];

export const priorities_options = [
  { label: 'weak', _id: '1' },
  { label: 'notSoWeak', _id: '2' },
  { label: 'high', _id: '3' },
  { label: 'critical', _id: '4' }
];
