import { getElement, getElements, flushElements } from 'store/contractPropositionsSlice';

import ContractPropositionFilters, { formatFilter } from './ContractPropositionFilters';
import ContractPropositionTranslations from './ContractPropositionTranslations';
import ContractPropositionSorts from './ContractPropositionSorts';

import EntityWrapper from 'entities/EntityWrapper';
import TicketSkeleton from 'entities/Ticket/TicketSkeleton';
import TicketPreview from 'entities/Ticket/TicketPreview';
import TicketView from 'entities/Ticket/TicketView';

export default function ContractProposition(props) {
  return (
    <EntityWrapper
      entity="contractPropositions"
      disableCreate
      skeletonComponent={TicketSkeleton}
      previewComponent={TicketPreview}
      viewComponent={TicketView}
      formatFilter={formatFilter}
      getFilters={ContractPropositionFilters}
      getTabs={() => []}
      getTables={() => []}
      getMenus={() => []}
      getExportColumns={() => []}
      getTranslations={ContractPropositionTranslations}
      getSorts={ContractPropositionSorts}
      getElement={getElement}
      getElements={getElements}
      flushElements={flushElements}
      {...props}
    />
  );
}
