const ContractTranslations = (configuration, { type }) => ({
  pick: 'entityContractPick',
  entityName: 'entityContractName',
  createDialog: 'entityContractCreateDialog',
  updateDialog: 'entityContractUpdateDialog',
  create: 'entityContractCreate',
  update: 'entityContractUpdate',
  noResultLabel: 'entityContractnoResultLabel',
  searchText: 'entityContractSearchText'
});

export default ContractTranslations;
