const MeterTranslations = (configuration) => ({
  pick: 'entityMeterPick',
  createDialog: 'entityMeterCreateDialog',
  updateDialog: 'entityMeterUpdateDialog',
  create: 'entityMeterCreate',
  update: 'entityMeterUpdate',
  entityName: 'entityMeterName',
  noResultLabel: 'entityMeternoResultLabel'
});

export default MeterTranslations;
