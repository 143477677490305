import { Cancel, FileCopy, PictureAsPdf, AccessTime, LockOpen } from '@material-ui/icons';

import axios from 'axios';
import { CONTRACT_CANCELED, CONTRACT_CLOSED, CONTRACT_FINISHED } from 'lists/contractStates';
import { duplicateElement, cancelElement, openElement } from 'store/ticketsSlice';
import { trigger } from 'react-events-utils';

import { deleteDeadline } from 'store/ticketsSlice';
import TicketAlertEditor from './TicketAlertEditor';
import { saveAs } from 'file-saver';
import { apiBaseURL } from 'index';

const createCardPDF = async (element, setIsMenuLoading, notify) => {
  setIsMenuLoading(true);

  return axios
    .get(`${apiBaseURL}/miscs/export/ticket/${element}`, { responseType: 'blob' })
    .then((res) => {
      setIsMenuLoading(false);
      const pdfExport = new Blob([res.data], { type: 'application/pdf' });
      saveAs(pdfExport, `${element}.pdf`);
      notify.success('Fichier Téléchargé');
    })
    .catch((err) => {
      setIsMenuLoading(false);
      notify.error();
    });
};

const TicketMenus = (
  element,
  auth,
  dispatch,
  role,
  companyPermission,
  listId,
  setIsMenuLoading,
  notify
) => {
  return [
    {
      label: 'duplicate',
      action: () => dispatch(duplicateElement, {}, {}, { id: element._id, listId }),
      icon: <FileCopy />,
      show: role.permission('tickets', 'update') && companyPermission
    },
    {
      label: 'createCard',
      action: () => createCardPDF(element._id, setIsMenuLoading, notify),
      icon: <PictureAsPdf />,
      show: true
    },
    {
      label: 'openTicket',
      action: () => dispatch(openElement, {}, {}, { id: element._id }),
      icon: <LockOpen />,
      show:
        (element?.contract?.state === CONTRACT_FINISHED &&
          companyPermission &&
          role.permission('tickets', 'finish_ticket')) ||
        (element?.contract?.state === CONTRACT_CLOSED && role.permission('tickets', 'close')) ||
        (element?.contract?.state === CONTRACT_CANCELED && role.permission('tickets', 'cancel'))
    },
    {
      label: !element.contract?.deadline ? 'addAlertPermission' : 'editAlert',
      action: () => [
        trigger(`ticket-modal-action-open-${element._id}`, {
          id: element._id,
          content: (
            <TicketAlertEditor
              ticket={element}
              alert={element.contract?.deadline}
              isCreate={!element.contract?.deadline}
            />
          )
        })
      ],
      icon: <AccessTime />,
      show: role.permission('tickets', 'create_deadline')
    },
    {
      label: 'deleteTheAlert',
      action: () => dispatch(deleteDeadline, undefined, undefined, { id: element._id }),
      icon: <AccessTime />,
      show: !!element.contract?.deadline && role.permission('tickets', 'create_deadline')
    },
    {
      label: 'cancel',
      action: (reason) => dispatch(cancelElement, { reason }, {}, { id: element._id }),
      icon: <Cancel />,
      show:
        role.permission('tickets', 'cancel') &&
        companyPermission &&
        ![CONTRACT_CANCELED].includes(element?.contract?.state),
      reason: true
    }
    //TODO
    // {
    //   label: 'Anciennes assignation',
    //   action: () => alert('Cancel'),
    //   icon: <Cancel />,
    //   show: true
    // },
  ].filter((e) => e.show);
};

export default TicketMenus;
