import {
  Box,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';
import { useForm } from 'frmx';
import useStyles from './Filter.styles';
import useKeys from '@flowsn4ke/usekeys';

import { useField } from 'frmx';

import DateField from 'components/inputs/DateField';
import { useTranslation } from 'react-i18next';
import { isArray } from 'lodash-es';

export default function FilterDate({ filter }) {
  const { datas } = filter.params;

  const classes = useStyles();

  const k1 = useKeys();

  return (
    <Box
      padding="16px"
      className={classes.filter}
    >
      <Grid
        container
        spacing={2}
        direction="row"
        alignItems="flex-end"
      >
        {datas.map((field, i) => (
          <CustomInputDate
            key={k1(i)}
            label={field.label}
            value={field.value}
          />
        ))}
      </Grid>
    </Box>
  );
}

function CustomInputDate({ label, value: key }) {
  const { getOneField, setOneField } = useForm();

  const k2 = useKeys();

  const { t } = useTranslation();

  const comparisonOperator = [
    // { value: 'eq', label: t("equalTo") },
    // { value: 'ne', label: t("notEqualTo") },
    { value: 'gt', label: t('greaterThan') },
    { value: 'gte', label: t('greaterThanOrEqualTo') },
    { value: 'lt', label: t('lessThan') },
    { value: 'lte', label: t('lessThanOrEqualTo') },
    { value: 'between', label: t('between') }
    // { value: 'exists', label: t("isFilled") },
    // { value: 'not_exists', label: t("isNotFilled") }
  ];

  // const selectedComparaisonOperator = useFieldObserver(`dates.${value}.comparisonOperator`)

  const operatorPath = `dates.${key}.comparisonOperator`;
  const rootValuePath = `dates.${key}.value`;
  const isDirtyPath = `dates.isDirty`;

  const { value: isDirty, setValue: setIsDirty } = useField(isDirtyPath);
  const { value: operator, setValue: setOperator } = useField(operatorPath);

  return (
    <>
      <Grid
        item
        xs={12}
        style={{ marginBottom: -15 }}
      >
        <Typography
          style={{ padding: '16px' }}
          variant="subtitle2"
        >
          {t(label)}
        </Typography>
      </Grid>
      <Grid
        item
        xs={4}
      >
        <FormControl
          fullWidth
          variant="outlined"
        >
          <InputLabel>{t('comparisonOperator')}</InputLabel>
          <Select
            value={operator}
            onChange={(e) => {
              !isDirty && setIsDirty(true);
              const next = e.target.value;
              const prevRootValue = getOneField(rootValuePath);
              setOperator(next);
              next === 'between' && setOneField(rootValuePath, [null, null]);
              next !== 'between' &&
                setOneField(rootValuePath, !isArray(prevRootValue) ? prevRootValue : null);
            }}
            fullWidth
            variant="outlined"
            label={t('comparisonOperator')}
          >
            {comparisonOperator.map((op, i) => (
              <MenuItem
                key={k2(i)}
                value={op.value}
              >
                {op.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      {['exists', 'not_exists'].includes(operator) ? (
        <Grid
          item
          xs={8}
        ></Grid>
      ) : operator === 'between' ? (
        <>
          <Grid
            item
            xs={4}
          >
            <DateField path={`dates.${key}.value.0`} />
          </Grid>
          <Grid
            item
            xs={4}
          >
            <DateField path={`dates.${key}.value.1`} />
          </Grid>
        </>
      ) : (
        <Grid
          item
          xs={8}
        >
          <DateField path={`dates.${key}.value`} />
        </Grid>
      )}
    </>
  );
}
