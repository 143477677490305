export default function TabPanel({ children, value, index, noPosition }) {
  const isVisible = value === index;
  return (
    <div
      style={{
        visibility: isVisible ? 'visible' : 'hidden',
        height: isVisible ? '100%' : 0,
        ...(noPosition ? {} : { position: isVisible ? 'relative' : 'absolute' })
      }}
    >
      {children}
    </div>
  );
}
