import { selectUI, switchPalette, switchLanguage } from 'store/uiSlice';
import { useSelector, useDispatch } from 'react-redux';
import { locale_keys } from 'constants/locales';

export const useUI = () => {
  const ui = useSelector(selectUI);
  const dispatch = useDispatch();

  const setPalette = (palette) => dispatch(switchPalette(palette));
  const setLang = (lang) => dispatch(switchLanguage(lang));
  const getPalette = () => ui.palette;
  const getPaletteColors = () => ui.paletteColors;
  const getLang = () => ui.lang;
  const getAvatar = () => ui.splashAvatar;
  const get_date_time_locale = () => locale_keys[ui.lang] || locale_keys.en;

  return {
    setPalette,
    setLang,
    getPalette,
    getLang,
    getPaletteColors,
    getAvatar,
    get_date_time_locale,
    ...ui
  };
};
