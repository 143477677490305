import {
  AccordionActions,
  AccordionDetails,
  FormControlLabel,
  Divider,
  Checkbox,
  Grid,
  Button
} from '@material-ui/core';

import { useAuth } from 'hooks/useAuth';
import useNotifications from 'hooks/useNotifications';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import { setConfiguration } from 'store/authSlice';
import { Submit, Field, Reset } from 'frmx';

import AccordionForm from 'components/forms/AccordionForm';
import { useTranslation } from 'react-i18next';

export default function SupportConfiguration({ section }) {
  const auth = useAuth();
  const configuration = auth.interface._company._configuration;
  const { dispatch, requestStatus } = useAsyncDispatch();
  const notify = useNotifications();
  const { t } = useTranslation();

  const dispatchCallbacks = {
    onSuccess: () => notify.success(),
    onError: () => notify.error()
  };

  return (
    <AccordionForm
      defaultClose
      section={section}
      initialValues={{
        support_chat: configuration?.support_chat || false,
        support_calendar: configuration?.support_calendar || false
      }}
      onSubmit={(updates) => dispatch(setConfiguration, updates, dispatchCallbacks)}
    >
      <AccordionDetails>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <FormControlLabel
              control={
                <Field
                  path="support_chat"
                  type="checkbox"
                >
                  <Checkbox />
                </Field>
              }
              label={t('activateHelpChat')}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
      <Divider />
      <AccordionActions>
        <Reset>
          <Button size="small">{t('cancel')}</Button>
        </Reset>

        <Submit disabled={requestStatus === 'loading'}>
          <Button
            size="small"
            color="primary"
          >
            {t('save')}
          </Button>
        </Submit>
      </AccordionActions>
    </AccordionForm>
  );
}
