import {
  getElement,
  getElements,
  createElement,
  updateElement,
  flushElements,
  deleteElement,
  restoreElement
} from 'store/usersSlice';

import UserMenus from './UserMenus';
import UserPreview from './UserPreview';
import UserSkeleton from './UserSkeleton';
import UserView from './UserView';
import UserTabs from './UserTabs';
import UserFilters, { formatFilter } from './UserFilters';
import UserFormValues, { UserValidation, formatForm } from './UserFormValues';
import UserTranslations from './UserTranslations';
import UserExport from './UserExport';
import UserForm from './UserForm';
import UserSorts from './UserSorts';

import { PhotoCamera } from '@material-ui/icons';

import EntityWrapper from 'entities/EntityWrapper';
import { useTranslation } from 'react-i18next';
import UserTable from './UserTable';

export default function User(props) {
  const { t } = useTranslation();
  return (
    <EntityWrapper
      entity="users"
      entityFieldsSlice="user"
      skeletonComponent={UserSkeleton}
      previewComponent={UserPreview}
      viewComponent={UserView}
      formComponent={UserForm}
      formatFilter={formatFilter}
      getFilters={UserFilters}
      getInitialValues={UserFormValues}
      getFormValidation={UserValidation}
      getTabs={UserTabs}
      getTables={UserTable}
      getMenus={UserMenus}
      getExportColumns={UserExport}
      getTranslations={UserTranslations}
      getSorts={UserSorts}
      formatForm={formatForm}
      getElement={getElement}
      getElements={getElements}
      createElement={createElement}
      updateElement={updateElement}
      flushElements={flushElements}
      deleteElement={deleteElement}
      restoreElement={restoreElement}
      formAttachment
      formAttachmentIcon={<PhotoCamera />}
      formAttachmentLabel={t('userProfilePic')}
      {...props}
    />
  );
}
