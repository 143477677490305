const MuiButton = {
  root: {
    textTransform: 'none',
    borderRadius: 16
  },
  text: {
    padding: '6px 16px'
  },
  textSizeSmall: {
    padding: '3px 14px'
  },
  contained: {
    backgroundColor: '#ffffff',
    '&:hover': {
      backgroundColor: '#E4E7EB'
    }
  }
};

export default MuiButton;
