import { FileCopy } from '@material-ui/icons';

import { duplicateElement } from 'store/equipmentsSlice';

const EquipmentMenus = (element, auth, dispatch, role, companyPermission, listId) => {
  return [
    {
      label: 'duplicate',
      action: () => dispatch(duplicateElement, {}, {}, { id: element._id, listId }),
      icon: <FileCopy />,
      show: role.permission('equipments', 'update') && companyPermission
    }
  ].filter((e) => e.show);
};

export default EquipmentMenus;
