const ContractPropositionFilters = (configuration, defaultFilters) =>
  [
    {
      type: 'Section',
      label: 'waiting',
      color: '#827696',
      key: 'open',
      default: true,
      show: true
    },
    {
      type: 'Section',
      label: 'accepted',
      color: '#31b55a',
      key: 'closed',
      default: false,
      show: true
    },
    {
      type: 'Section',
      label: 'declined',
      color: '#e63d53',
      key: 'closed',
      default: false,
      show: true
    },
    {
      type: 'Section',
      label: 'expired',
      color: 'black',
      key: 'closed',
      default: false,
      show: true
    }
  ].filter((e) => e.show);

export const formatFilter = (filters) => filters;

export default ContractPropositionFilters;
