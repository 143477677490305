import {
  getElement,
  getElements,
  createElement,
  updateElement,
  flushElements,
  deleteElement,
  restoreElement
} from 'store/jobsSlice';

import { useMemo } from 'react';

import JobMenus from './JobMenus';
import JobPreview from './JobPreview';
import JobSkeleton from './JobSkeleton';
import JobView from './JobView';
import JobTabs from './JobTabs';
import JobFilters, { formatFilter } from './JobFilters';
import JobFormValues, { JobValidation } from './JobFormValues';
import JobTranslations from './JobTranslations';
import JobExport from './JobExport';
import JobForm from './JobForm';
import JobSorts from './JobSorts';

import EntityWrapper from 'entities/EntityWrapper';

export default function Job(props) {
  const _hiddenFilters = useMemo(() => {
    return {
      ...props.hiddenFilters
    };
  }, [props.hiddenFilters]);

  return (
    <EntityWrapper
      entity="jobs"
      skeletonComponent={JobSkeleton}
      previewComponent={JobPreview}
      viewComponent={JobView}
      formComponent={JobForm}
      formColor
      formatFilter={formatFilter}
      getFilters={JobFilters}
      getInitialValues={JobFormValues}
      getFormValidation={JobValidation}
      getTabs={JobTabs}
      getMenus={JobMenus}
      getExportColumns={JobExport}
      getTranslations={JobTranslations}
      getSorts={JobSorts}
      getTables={() => []}
      getElement={getElement}
      getElements={getElements}
      createElement={createElement}
      updateElement={updateElement}
      flushElements={flushElements}
      deleteElement={deleteElement}
      restoreElement={restoreElement}
      hiddenFilters={_hiddenFilters}
      {...props}
    />
  );
}
