import Role from 'entities/Role/Role';
import Location from 'entities/Location/Location';
import Job from 'entities/Job/Job';

const ManagerFilters = (configuration, { customFields }) =>
  [
    {
      label: 'role',
      key: 'roles',
      type: 'Entity',
      params: {
        entity: Role,
        entityProps: { defaultFilters: { types: 'user' } }
      },
      disabled: false,
      show: true,
      default: []
    },
    {
      label: 'location',
      key: 'locations',
      type: 'Entity',
      params: {
        entity: Location,
        entityProps: {
          type: 'locations',
          hiddenFilters: {
            section: {
              location: true,
              facturation: false
            }
          }
        }
      },
      show: true,
      disabled: false,
      default: []
    },
    {
      label: 'job',
      key: 'jobs',
      type: 'Entity',
      params: {
        entity: Job,
        entityProps: {
          type: 'jobs',
          hiddenFilters: {
            section: {
              mine: true,
              bobdesk: true,
              bobdepannage: true
            }
          }
        }
      },
      show: true,
      disabled: false,
      default: []
    },
    {
      label: 'field',
      key: 'fields',
      type: 'Field',
      params: {},
      show: customFields?.some((section) => !!section.fields?.length),
      disabled: false,
      default: []
    }
  ].filter((e) => e.show);

export const formatFilter = (filters) => filters;

export default ManagerFilters;
