import { Box, List, ListItem, ListItemIcon, Button, ListItemText, Typography } from '@material-ui/core';
import Avatar from 'components/Avatar';
import yellowpages from 'assets/svg/yellowpages.svg';
import linkedin from 'assets/svg/linkedin.svg';
import { dateToLocalFormat } from 'utils/dates';
import useStyles from 'layouts/entities/View.styles';
import { useConfiguration } from 'hooks/useConfiguration';
import { useAuth } from 'hooks/useAuth';

import useNotifications from 'hooks/useNotifications';

import { AddCircleOutline } from '@material-ui/icons';

import {
  PhoneItem,
  EmailItem,
  ZoneItem,
  ManagerItem,
  ClientItem,
  ContractorItem
} from 'components/views/ViewItems';
import ViewItem from 'components/views/ViewItem';
import { contractorPartner } from 'store/intervenersSlice';
import { useTranslation } from 'react-i18next';
import FAIcon from 'components/ui/FAIcon';
import { IconButton } from 'react-file-utils';
import { fetchInterfaceData } from 'store/authSlice';
import classNames from 'utils/classNames';
import Flag from 'react-flagkit';
import { countryLangage } from 'config/languages';

export default function ContractorView({ element, dispatch, isLoading }) {
  const auth = useAuth();
  const configuration = useConfiguration();
  const notifications = useNotifications();

  const _company = element._client || element._contractor;
  const companyPermission =
    auth.interface.isMaster || _company?._id === auth.interface._company._id;

  const lang = (element || element._user)?.lang || 'fr';

  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <>
      <Box className={classes.l1}>
        <Box
          alignItems={'center'}
          display={'flex'}
        >
          <Avatar
            className={classes.avatarl1}
            entity={{
              name: element.companyName,
              avatar: element.avatar
            }}
            shadow={false}
            size={'small'}
          />
          <Box
            flexDirection={'column'}
            alignItems={'flex-start'}
          >
            <Box className="text-base font-bold flex gap-2 items-center">
              {element.companyName}

              <Flag
                country={countryLangage[lang]}
                className="mb-[2px] rounded-sm"
                size={20}
              />
            </Box>
            {element.partner &&
              auth.interface._company._id === (element._client || element._contractor)._id && (
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  marginTop={'10px'}
                >
                  {element.isExclu !== null && element.isExclu === true && (
                    <strong style={{ marginLeft: 6 }}>{t('exclusiveTitle')}</strong>
                  )}
                  {element.isExclu !== null && element.isExclu === false && (
                    <strong style={{ marginLeft: 6 }}>{t('recommendedTitle')}</strong>
                  )}
                </Box>
              )}
          </Box>
          {element.ratings?.length > 0 && (
            <>
              <FAIcon
                collection="fas"
                icon="star-sharp"
                style={{ color: '#ffc107' }}
                size="xs"
              />
              <span
                style={{
                  color: '#ff9100',
                  marginRight: 4,
                  fontWeight: 500,
                  fontSize: '0.75em',
                  letterSpacing: '0.1em'
                }}
              >
                {element?.ratings_avg.toPrecision(3)}
              </span>
              <span
                style={{
                  color: '#ff9100',
                  marginRight: 6,
                  fontWeight: 500,
                  fontSize: '0.75em',
                  letterSpacing: '0.1em'
                }}
              >
                ({element.ratings?.length})
              </span>
            </>
          )}
        </Box>
        {element.score > 0 && (
          <Box
            justifySelf="flex-end"
            style={{ marginRight: 40, display: 'flex', alignItems: 'center' }}
          >
            <FAIcon
              collection="fas"
              icon="crown"
              size="small"
              style={{
                color: 'rgb(49, 181, 89)'
              }}
            />
            <span
              style={{
                color: 'rgb(49, 181, 89)',
                marginLeft: 16,
                fontWeight: 500,
                fontSize: '1em',
                letterSpacing: '0.1em'
              }}
            >
              {t('recommendedTitle').toLocaleUpperCase()}
            </span>
          </Box>
        )}
      </Box>

      <List className={classes.list}>
        {configuration.isMaster && element._client && (
          <ClientItem
            client={element._client}
            primary={t('creatorOfContractor')}
          />
        )}
        {configuration.isMaster && element._contractor && (
          <ContractorItem
            contractor={element._contractor}
            primary={t('creatorOfContractor')}
          />
        )}

        {companyPermission && (
          <>
            {element.userInterface && <ManagerItem user={element._user} />}

            {(!element.userInterface || !element._user) &&
              (!!element.firstName?.length || !!element.lastName?.length) && (
                <ListItem
                  dense
                  className={classes.listItem}
                >
                  <ListItemIcon>
                    <Avatar
                      className={classes.listIcon}
                      isUser
                      round
                      entity={{ avatar: '' }}
                      shadow={false}
                      size={'xsmall'}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={<strong>{t('seniorManager')}</strong>}
                    secondary={element.firstName + ' ' + element.lastName}
                  />
                </ListItem>
              )}
          </>
        )}

        {!!element.zone_ids?.length && <ZoneItem element={element} />}

        {!!element._jobs?.length && (
          <ViewItem
            icon="toolbox"
            secondaryIcon
            primary={t('jobs')}
            secondary={element._jobs.map((j) => t(j.name)).join(', ')}
          />
        )}

        {!!element.description?.length && (
          <ViewItem
            icon="quote-left"
            secondaryIcon
            collection="fas"
            className="whitespace-pre-wrap"
            secondary={element.description}
          />
        )}

        {!!element.phone?.length && <PhoneItem element={element} />}
        {!!element.fix?.length && (
          <PhoneItem
            element={element}
            fix
          />
        )}
        {!!element.email?.length && <EmailItem element={element} />}

        {!!element.siret?.length && (
          <ViewItem
            icon="info-circle"
            secondaryIcon
            primary="SIRET"
            secondary={element.siret}
          />
        )}

        {configuration.isMaster && (
          <ViewItem
            icon="calendar"
            secondaryIcon
            primary="Date de création"
            secondary={dateToLocalFormat(element.created_at, 'PP')}
          />
        )}
      </List>

      <Box className={classes.viewActions}>
        <Box className={classes.viewActionsLeft}>
          {(!!element.web?.length || !!element.jaune?.length || !!element.linkedin?.length) && (
            <div style={{ marginLeft: 12 }}>
              <Typography style={{ fontWeight: 600, fontSize: 12 }}>
                {t('furtherInformations')}
              </Typography>
              <Box
                display="flex"
                alignItems="center"
              >
                {!!element.web?.length && (
                  <IconButton onClick={() => window.open(element.web, '_blank')}>
                    <FAIcon icon="globe" />
                  </IconButton>
                )}

                {!!element.jaune?.length && (
                  <>
                    <IconButton onClick={() => window.open(element.jaune, '_blank')}>
                      <img
                        src={yellowpages}
                        style={{ height: 24 }}
                        alt="Pages Jaunes Logo"
                      />
                    </IconButton>
                  </>
                )}

                {!!element.linkedin?.length && (
                  <>
                    <IconButton onClick={() => window.open(element.linkedin, '_blank')}>
                      <img
                        src={linkedin}
                        style={{ height: 24 }}
                        alt="LinkedIn Logo"
                      />
                    </IconButton>
                  </>
                )}
              </Box>
            </div>
          )}
        </Box>
        {!configuration.isMaster && !element.partner && (
          <Box className={classes.viewActionsRight}>
            <Button
              onClick={() => {
                /**
                 ** When we integrate a company from the market place into our network,
                 ** we redispatch an action to update the hasIntervener key which
                 ** we use to conditionally display the 'Assign' button in the actions of the ticket
                 */
                return dispatch(
                  contractorPartner,
                  {},
                  { onSuccess: () => notifications.success() },
                  { id: element._id }
                ).then(() => dispatch(fetchInterfaceData));
              }}
              disabled={isLoading}
              variant={'contained'}
              endIcon={<AddCircleOutline className={classes.viewActionIcon} />}
              className={classNames(classes.viewAction, classes.viewActionContainedSuccess)}
              style={{ width: 242 }}
            >
              {t('joinMyNetworkAction')}
              {/*auth.interface.isContractor ? "sous-traitants" : "entreprises"*/}
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
}
