import { AccordionDetails } from '@material-ui/core';

import AccordionForm from 'components/forms/AccordionForm';
import Role from 'entities/Role/Role';
import { useIsBelowSm } from 'hooks/useMQ';

export default function Roles({ section }) {
  const isBelowSm = useIsBelowSm();
  return (
    <AccordionForm
      section={section}
      noForm
      allwaysExpanded
    >
      <AccordionDetails style={{ margin: 0, padding: 0, height: isBelowSm ? '50vh' : '70vh' }}>
        <Role settings />
      </AccordionDetails>
    </AccordionForm>
  );
}
