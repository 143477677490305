import { factureStates } from 'constants/invoiceStates';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { useConfiguration } from 'hooks/useConfiguration';
import { useRole } from 'hooks/useRole';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import { setFactureState } from 'store/accountingSlice';
import useNotifications from 'hooks/useNotifications';
import { useTranslation } from 'react-i18next';

export default function CheckColumn({
  facture_state,
  parentId,
  _id,
  state,
  viewId,
  type,
  interventionPrice,
  devisPrice
}) {
  const config = useConfiguration();
  const role = useRole();
  const { t } = useTranslation();

  const { dispatch } = useAsyncDispatch();
  const notify = useNotifications();

  const handleChange = (_, value) => {
    const messageSuccess = (value) => {
      switch (value) {
        case 0:
          return t('registeredReserve');
        case 1:
          return t('registeredCheck');
        case 2:
          return t('recordedHold');
        default:
          return t('resetCheck');
      }
    };

    const dispatchCallbacks = {
      onSuccess: () => notify.success(messageSuccess(value)),
      onError: () => notify.error('Something wrong with')
    };

    dispatch(
      setFactureState,
      {
        type,
        value: value === null ? -1 : value
      },
      dispatchCallbacks,
      { id: _id }
    );
  };

  return parentId ? null : (
    <div>
      <ToggleButtonGroup
        style={{ width: '100%', justifyContent: 'center', whiteSpace: 'nowrap' }}
        size="small"
        value={facture_state[type]}
        exclusive
        onChange={handleChange}
      >
        {[
          {
            label: config.comptability.check_2
              ? t(factureStates.to_check.labelTitle)
              : t(factureStates.to_valid.labelAction),
            value: 1,
            backgroundColor: '#31b559'
          },
          {
            label: t(factureStates.to_waiting.labelAction),
            value: 2,
            backgroundColor: '#9c27b0'
          },
          !config.comptability.check_2
            ? {
                label: t(factureStates.analyse.labelAction),
                value: 0,
                backgroundColor: '#827596'
              }
            : // * ELSE
              {
                label: t(factureStates.reserve.labelAction),
                value: 0,
                backgroundColor: '#f57e20'
              }
        ].map(({ label, value, backgroundColor }) => (
          <ToggleButton
            disabled={
              !['finished', 'closed'].includes(state) ||
              ['to_complete'].includes(facture_state.key) ||
              !role.permission('accounting', 'check_1') ||
              facture_state.lock /* || interventionPrice !== devisPrice*/
            }
            key={`check_1-${label}-${_id}`}
            value={value}
            style={{
              backgroundColor:
                facture_state.check_1 < 0 || value !== facture_state.check_1 ? 'initial' : backgroundColor,
              color: facture_state.check_1 < 0 || value !== facture_state.check_1 ? 'initial' : 'white',
              opacity:
                !['finished', 'closed'].includes(state) ||
                ['to_complete'].includes(facture_state.key) ||
                !role.permission('accounting', 'check_1') /* || interventionPrice !== devisPrice */ ||
                facture_state.lock
                  ? 0.6
                  : 1,
              textTransform: 'initial'
            }}
          >
            {label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  );
}
