import {
  TextField,
  AccordionActions,
  AccordionDetails,
  InputAdornment,
  Grid,
  Button
} from '@material-ui/core';
import FAIcon from 'components/ui/FAIcon';
import yellowpages from 'assets/svg/yellowpages.svg';
import linkedin from 'assets/svg/linkedin.svg';

import useNotifications from 'hooks/useNotifications';
import { useAuth } from 'hooks/useAuth';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import { setCompanyInfo } from 'store/authSlice';
import { Submit, Field, Reset } from 'frmx';

import AccordionForm from 'components/forms/AccordionForm';
import { useTranslation } from 'react-i18next';

export default function MarketplaceDetails({ section, allwaysExpanded = false }) {
  const notify = useNotifications();
  const auth = useAuth();
  const company = auth.interface._company;
  const { t } = useTranslation();

  const dispatchCallbacks = {
    onSuccess: () => notify.success(),
    onError: () => notify.error()
  };

  const { dispatch, requestStatus } = useAsyncDispatch();

  const clientType = auth.interface.isClient ? 'client' : 'contractor';

  return (
    <AccordionForm
      defaultClose
      hide={!company?.marketplace}
      section={section}
      initialValues={{
        [clientType]: {
          description: company.description || '',
          jaune: company.jaune || '',
          linkedin: company.linkedin || '',
          web: company.web || ''
        }
      }}
      onSubmit={(updates) => dispatch(setCompanyInfo, updates, dispatchCallbacks)}
    >
      <AccordionDetails>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <Field path={`${clientType}.description`}>
              <TextField
                fullWidth
                multiline
                variant="outlined"
                rows={4}
                label={t('companyIntro')}
                placeholder={t('companyIntroPlaceholder')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FAIcon
                        size={'small'}
                        collection="fal"
                        icon="pen"
                      />
                    </InputAdornment>
                  )
                }}
              />
            </Field>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <Field path={`${clientType}.web`}>
              <TextField
                fullWidth
                variant="outlined"
                label={t('website')}
                placeholder={t('websiteLink')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FAIcon
                        size="small"
                        collection="fal"
                        icon="link"
                      />
                    </InputAdornment>
                  )
                }}
              />
            </Field>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <Field path={`${clientType}.jaune`}>
              <TextField
                fullWidth
                variant="outlined"
                label={t('yellowPages')}
                placeholder={t('yellowPagesLink')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        src={yellowpages}
                        style={{ height: 16 }}
                        alt={t('yellowPages')}
                      />
                    </InputAdornment>
                  )
                }}
              />
            </Field>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <Field path={`${clientType}.linkedin`}>
              <TextField
                fullWidth
                variant="outlined"
                label={t('linkedIn')}
                placeholder={t('linkedInLink')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        src={linkedin}
                        style={{ height: 16 }}
                        alt={t('linkedIn')}
                      />
                    </InputAdornment>
                  )
                }}
              />
            </Field>
          </Grid>
        </Grid>
      </AccordionDetails>

      <AccordionActions>
        <Reset>
          <Button size="small">{t('cancel')}</Button>
        </Reset>

        <Submit disabled={requestStatus === 'loading'}>
          <Button
            size="small"
            color="primary"
          >
            {t('save')}
          </Button>
        </Submit>
      </AccordionActions>
    </AccordionForm>
  );
}
