import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    width: '100%',
    [theme.breakpoints.up(1100)]: {
      marginBottom: 10
    },
    [theme.breakpoints.down(1100)]: {
      ['& .MuiAccordion-root']: {
        boxShadow: 'none',
        borderRadius: 0
      }
    }
  },
  summary: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    fontWeight: '500',
    fontSize: 14
  },
  icon: {
    marginRight: 10
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  }
}));
