const { default: Intervener } = require('entities/Intervener/Intervener');

export const IntervenerShortCut = ({ type, children }) => {
  //TODO: Open tech / contractor form

  return (
    <Intervener
      defaultTab={type}
      openFormOnClick
    >
      <span
        style={{
          cursor: 'pointer',
          textDecoration: 'underline',
          fontWeight: 500,
          margin: '0px 4px'
        }}
      >
        {children}
      </span>
    </Intervener>
  );
};
