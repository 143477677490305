import { omit } from 'lodash-es';
import { useSelector } from 'react-redux';
import { computeState } from 'store/ticketsSlice';
import { TimelineRenderItem } from './TimelineRenderItem';
import useStyles from './Timeline.styles';
import clsx from 'clsx';

export function TimelineElement({ item, itemContext, getItemProps, setTicketId, set_period }) {
  const classes = useStyles();
  const dbTickets = useSelector((state) => state['tickets']).db;

  // * Ticket Data to display
  const ticket = dbTickets[item.period._ticket._id] || {
    ...item.period._ticket,
    period: {
      ...omit(item.period, ['_ticket']),
      checked: item.period._ticket.preventif_checked,
      hasIR: item.period._ticket.hasIR,
      hasReserve: item.period._ticket.hasReserveUrgency
    },
    contract: {
      state: computeState(item.period._ticket, item.period._ticket),
      visit_date: item.period._ticket.visit_date || item.period._ticket?._parent?.intervention_date,
      intervention_date: item.period._ticket.intervention_date
    }
  };

  return (
    <div
      {...getItemProps({
        onDoubleClick: () => [setTicketId(ticket._id), set_period(item)]
      })}
      className={clsx(classes.item, {
        [classes.itemClosed]: !ticket.period.current,
        [classes.itemChecked]: ticket.period.checked,
        [classes.itemAlert]: ticket.period.isAlert,
        [classes.itemLate]: ticket.period.isLate,
        [classes['itemReserveUrgency' + ticket.period.hasReserve]]: ticket.period.hasReserve > 0
      })}
    >
      <div
        style={{
          height: itemContext.dimensions.height,
          overflow: 'hidden',
          paddingLeft: 3,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        }}
      >
        <TimelineRenderItem
          ticket={ticket}
          itemContext={itemContext}
          item={item}
        />
      </div>
    </div>
  );
}
