import useNotifications from 'hooks/useNotifications';
import Feed from 'modules/comments/Feed';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import VersionSelector from '../VersionSelector';
import DialogMUI from 'components/DialogMUI';

export default function DocumentComments({ document, setDocument, getRevisions }) {
  const { t } = useTranslation();
  const notify = useNotifications();
  const [isLoadingVersions, setIsLoadingVersions] = useState(false);
  const [revisions, setRevisions] = useState(null);

  useEffect(() => {
    if (document && !revisions) {
      setIsLoadingVersions(true);
      getRevisions({ document_id: document._id })
        .then(({ data }) => {
          const versions = [document, ...data.revisions].sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          );
          setRevisions(versions);
          setIsLoadingVersions(false);
        })
        .catch(() => {
          notify.error(t('couldNotLoadDocumentRevisions'));
          setIsLoadingVersions(false);
        });
    }
  }, [document]);

  const readOnly = useMemo(() => {
    return document && document['revision_for'] ? true : false;
  }, [document]);

  // TODO: Switching to an older version effectively just uses the setDocument function
  return document ? (
    <>
      <DialogMUI
        icon="message-lines"
        isOpen={!!document}
        title={t('comments')}
        titleRight={
          <VersionSelector
            isLoading={isLoadingVersions}
            revisions={revisions}
            disabled={!revisions?.length}
            document={document}
            setDocument={setDocument}
          />
        }
        className="w-[500px] z-[1400]"
        onClose={() => [setDocument(null), setRevisions(null)]}
      >
        <Feed
          readOnly={readOnly}
          comments_tab_id={document.comments_tab_id}
        />
      </DialogMUI>
    </>
  ) : null;
}
