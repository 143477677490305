import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
  Button
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export default function ConfirmDialog({ open, onClose, onConfirm, text }) {
  const close = (e) => [e.preventDefault(), e.stopPropagation(), onClose()];

  const { t } = useTranslation();

  return (
    <Dialog
      disableEnforceFocus
      open={!!open}
      onClose={(e) => close(e)}
      onMouseDown={(e) => e.stopPropagation()}
      onClick={(e) => e.stopPropagation()}
    >
      <DialogTitle>{t('confirmation')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={close}
          color="secondary"
        >
          Annuler
        </Button>
        <Button
          onClick={(e) => [onConfirm(), close(e)]}
          color="primary"
        >
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
