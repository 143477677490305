import { Breadcrumbs, Box, Link, Typography } from '@material-ui/core';
import { NavigateNext as NavigateNextIcon } from '@material-ui/icons';
import { useEntity } from 'contexts/entities/entityContext';
import useKeys from '@flowsn4ke/usekeys';

import { isArray } from 'lodash-es';

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useStyles from './EntityBreadcrumbs.styles';

export default function EntityBreadcrumbs({
  element,
  sublistParentKey,
  setSublistParent,
  newElement,
  page,
  inline,
  setItemId
}) {
  const list = useMemo(
    () => (!isArray(element[sublistParentKey]) ? [] : [...element[sublistParentKey]].reverse()),
    [element]
  );
  const { entity } = useEntity();
  const classes = useStyles({ inline });
  const k = useKeys();
  const { t } = useTranslation();

  return (
    !!element.name?.trim()?.length && (
      <Box className={classes.root}>
        {
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {page && (
              <Link
                color="inherit"
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  if (setSublistParent) {
                    setSublistParent(null);
                  }
                }}
              >
                {entity === 'equipments' ? t('equipmentsTitle') : t('entityLocName')}
              </Link>
            )}

            {list.map((_element, i) => (
              <Link
                key={k(i)}
                color="inherit"
                href="/"
                onMouseDown={(e) => [e.preventDefault(), e.stopPropagation()]}
                onClick={(e) => [
                  e.stopPropagation(),
                  e.preventDefault(),
                  setSublistParent &&
                    setSublistParent({
                      ..._element,
                      [sublistParentKey]: element[sublistParentKey].slice(0, i)
                    }),
                  setItemId && setItemId(_element._id)
                ]}
              >
                {_element.name}
              </Link>
            ))}

            <Typography
              color="textPrimary"
              style={{ fontWeight: 'bold' }}
              onMouseDown={(e) => [e.preventDefault(), e.stopPropagation()]}
              onClick={(e) => [
                e.stopPropagation(),
                e.preventDefault(),
                setItemId && setItemId(element._id)
              ]}
            >
              {element.name}
            </Typography>

            {!!newElement && (
              <Link
                color="inherit"
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  if (setSublistParent) {
                    setSublistParent(null);
                  }
                }}
              >
                {newElement}
              </Link>
            )}
          </Breadcrumbs>
        }
      </Box>
    )
  );
}
