import { Translation as NativeTranslation } from 'react-i18next';

function Translation({ children }) {
  return <NativeTranslation>{(t) => children(t)}</NativeTranslation>;
}

const historyEntries = {
  consumption: {
    label: <Translation>{(t) => <span>{t('updatedCons')}</span>}</Translation>,
    icon: 'inventory'
  },
  relance: {
    label: <Translation>{(t) => <span>{t('remindedIntervener')}</span>}</Translation>,
    icon: 'bells'
  },
  opened: {
    label: <Translation>{(t) => <span>{t('openedTicket')}</span>}</Translation>,
    icon: 'file-plus'
  },
  declined_user: {
    label: <Translation>{(t) => <span>{t('declinedTicket')}</span>}</Translation>,
    icon: 'thumbs-down'
  },
  accepted_user: {
    label: <Translation>{(t) => <span>{t('acceptedTicket')}</span>}</Translation>,
    icon: 'thumbs-up'
  },
  location: {
    label: <Translation>{(t) => <span>{t('changedLocation')}</span>}</Translation>,
    icon: 'map-marker-alt'
  },
  seuil: {
    label: <Translation>{(t) => <span>{t('changedMaxAllowedAmount')}</span>}</Translation>,
    icon: 'money-check-edit'
  },
  urgency: {
    label: <Translation>{(t) => <span>{t('changedInterventionDelay')}</span>}</Translation>,
    icon: 'siren-on'
  },
  job: {
    label: <Translation>{(t) => <span>{t('changedJob')}</span>}</Translation>,
    icon: 'toolbox'
  },
  typology: {
    label: <Translation>{(t) => <span>{t('changedTypology')}</span>}</Translation>,
    icon: 'sitemap'
  },
  duration: {
    label: <Translation>{(t) => <span>{t('changedInterventionDuration')}</span>}</Translation>,
    icon: 'clock'
  },
  tva: {
    label: <Translation>{(t) => <span>{t('changedVATRate')}</span>}</Translation>,
    icon: 'euro-sign'
  },
  title: {
    label: <Translation>{(t) => <span>{t('changedTitle')}</span>}</Translation>,
    icon: 'file-alt'
  },
  description: {
    label: <Translation>{(t) => <span>{t('changedDescription')}</span>}</Translation>,
    icon: 'file-alt'
  },
  cancel: {
    label: <Translation>{(t) => <span>{t('canceledTicket')}</span>}</Translation>,
    icon: 'ban'
  },
  open: {
    label: <Translation>{(t) => <span>{t('reopenedTicket')}</span>}</Translation>,
    icon: 'unlock'
  },
  accepted: {
    label: <Translation>{(t) => <span>{t('acceptedTicket')}</span>}</Translation>,
    icon: 'thumbs-up'
  },
  proposed: {
    label: <Translation>{(t) => <span>{t('proposedTicketToInterveners')}</span>}</Translation>,
    icon: 'rocket'
  },
  renotice: {
    label: <Translation>{(t) => <span>{t('resentProposal')}</span>}</Translation>,
    icon: 'bells'
  },
  collaborator_change: {
    label: <Translation>{(t) => <span>{t('modifiedInterveners')}</span>}</Translation>,
    icon: 'user-hard-hat'
  },
  manager: {
    label: ({ payload }) => (
      <Translation>
        {(t) => (
          <span>
            {t('editedTicketManagers')} <strong>{payload.managers}</strong>
          </span>
        )}
      </Translation>
    ),
    icon: 'user-headset'
  },
  assigned: {
    label: ({ payload }) =>
      payload?.summon ? (
        <Translation>
          {(t) => (
            <span>
              {t('assignedCompany')} <strong>{payload.summon}</strong> {t('toTicket')}
            </span>
          )}
        </Translation>
      ) : (
        <Translation>{(t) => <span>{t('assignedIntervenerToTicket')}</span>}</Translation>
      ),
    icon: 'user-hard-hat'
  },
  autoassigned: {
    label: ({ payload }) =>
      payload?.summon ? (
        <Translation>
          {(t) => <span>{t('autoAssignedIntervenerToTicket', { intervener: payload.summon })}</span>}
        </Translation>
      ) : (
        <Translation>{(t) => <span>{t('autoAssignedIntervenerToTicket')}</span>}</Translation>
      ),
    icon: 'thunderstorm'
  },
  autoproposed: {
    label: <Translation>{(t) => <span>{t('autoProposedTicketToInterveners')}</span>}</Translation>,
    icon: 'thunderstorm'
  },
  declined_ticket: {
    label: <Translation>{(t) => <span>{t('declinedTicket')}</span>}</Translation>,
    icon: 'thumbs-down'
  },
  unassigned: {
    label: ({ payload }) => (
      <Translation>
        {(t) => (
          <span>
            {t('dissociatedIntervenerFromTicket')} {payload?.reason && <i>({payload.reason})</i>}
          </span>
        )}
      </Translation>
    ),
    icon: 'window-close'
  },
  unproposed: {
    label: <Translation>{(t) => <span>{t('canceledProposals')}</span>}</Translation>,
    icon: 'window-close'
  },
  validated: {
    label: <Translation>{(t) => <span>{t('validatedQote')}</span>}</Translation>,
    icon: 'thumbs-up'
  },
  declined: {
    label: <Translation>{(t) => <span>{t('declinedQuote')}</span>}</Translation>,
    icon: 'thumbs-down'
  },
  declined_contractor: {
    label: <Translation>{(t) => <span>{t('declinedQuote')}</span>}</Translation>,
    icon: 'thumbs-down'
  },
  date_replan: {
    label: <Translation>{(t) => <span>{t('replanedTicket')}</span>}</Translation>,
    icon: 'calendar-edit'
  },
  date_visit: {
    label: <Translation>{(t) => <span>{t('changedVisitDate')}</span>}</Translation>,
    icon: 'calendar-edit'
  },
  date_intervention: {
    label: <Translation>{(t) => <span>{t('addedRealisationDate')}</span>}</Translation>,
    icon: 'calendar-plus'
  },
  date_intervention_update: {
    label: <Translation>{(t) => <span>{t('changedRealisationDate')}</span>}</Translation>,
    icon: 'calendar-edit'
  },
  attachments_remove_devis: {
    label: <Translation>{(t) => <span>{t('deletedQuote')}</span>}</Translation>,
    icon: 'trash-alt'
  },
  attachments_remove_facture: {
    label: <Translation>{(t) => <span>{t('deletedInvoice')}</span>}</Translation>,
    icon: 'trash-alt'
  },
  attachments_document: {
    label: <Translation>{(t) => <span>{t('uploadedDocument')}</span>}</Translation>,
    icon: 'paperclip'
  },
  attachments_price_facture: {
    label: <Translation>{(t) => <span>{t('changedInvoicePrice')}</span>}</Translation>,
    icon: 'euro-sign'
  },
  attachments_reference_facture: {
    label: <Translation>{(t) => <span>{t('changedInvoiceReference')}</span>}</Translation>,
    icon: 'fingerprint'
  },
  attachments_price_devis: {
    label: <Translation>{(t) => <span>{t('changedQuotePrice')}</span>}</Translation>,
    icon: 'euro-sign'
  },
  attachments_reference_devis: {
    label: <Translation>{(t) => <span>{t('changedQuoteReference')}</span>}</Translation>,
    icon: 'fingerprint'
  },
  attachments_facture: {
    label: <Translation>{(t) => <span>{t('uploadedInvoice')}</span>}</Translation>,
    icon: 'paperclip'
  },
  attachments_devis: {
    label: <Translation>{(t) => <span>{t('uploadedQuote')}</span>}</Translation>,
    icon: 'paperclip'
  },
  finished: {
    label: <Translation>{(t) => <span>{t('endedIntervention')}</span>}</Translation>,
    icon: 'clipboard-check'
  },
  rating: {
    label: ({ payload }) =>
      payload && payload.message ? (
        <Translation>
          {(t) => (
            <span>
              {t('ratedIntervener')} <i>{payload.message}</i>
            </span>
          )}
        </Translation>
      ) : (
        <Translation>{(t) => <span>{t('ratedIntervener')}</span>}</Translation>
      ),
    icon: 'star'
  },
  closed: {
    label: <Translation>{(t) => <span>{t('closedTicket')}</span>}</Translation>,
    icon: 'archive'
  },
  'ir-created': {
    label: <Translation>{(t) => <span>{t('createdInterventionReport')}</span>}</Translation>,
    icon: 'file-invoice'
  },
  'ir-updated': {
    label: <Translation>{(t) => <span>{t('editedInterventionReport')}</span>}</Translation>,
    icon: 'file-invoice'
  },
  'ir-deleted': {
    label: <Translation>{(t) => <span>{t('deletedIr')}</span>}</Translation>,
    icon: 'file-invoice'
  },
  'ir-signed': {
    label: <Translation>{(t) => <span>{t('signedIr')}</span>}</Translation>,
    icon: 'file-invoice'
  },
  'ir-finish': {
    label: <Translation>{(t) => <span>{t('closedIr')}</span>}</Translation>,
    icon: 'file-invoice'
  },
  deleted: {
    label: <Translation>{(t) => <span>{t('hasDeletedTicket')}</span>}</Translation>,
    icon: 'trash-alt'
  },
  recycled: {
    label: <Translation>{(t) => <span>{t('recycledTicket')}</span>}</Translation>,
    icon: 'recycle'
  },
  'remove-deadline': {
    label: <Translation>{(t) => <span>{t('deletedAlert')}</span>}</Translation>,
    icon: 'alarm-exclamation'
  },
  'create-deadline': {
    label: <Translation>{(t) => <span>{t('createdAlert')}</span>}</Translation>,
    icon: 'alarm-plus'
  },
  'update-deadline': {
    label: <Translation>{(t) => <span>{t("a modifié l'alerte")}</span>}</Translation>,
    icon: 'alarm-exclamation'
  },
  'update-facture-state-check_1': {
    label: <Translation>{(t) => <span>{t('checkedInvoice')}</span>}</Translation>,
    icon: 'calculator'
  },
  'update-facture-state-check_1-to_waiting': {
    label: <Translation>{(t) => <span>{t('invoiceOnHold')}</span>}</Translation>,
    icon: 'calculator'
  },
  'update-facture-state-check_2': {
    label: <Translation>{(t) => <span>{t('validatedInvoice')}</span>}</Translation>,
    icon: 'calculator'
  },
  'update-facture-state-check_1-init': {
    label: <Translation>{(t) => <span>{t('reinitializedChecked')}</span>}</Translation>,
    icon: 'calculator'
  },
  'update-facture-state-check_2-init': {
    label: <Translation>{(t) => <span>{t('reinitializedValidation')}</span>}</Translation>,
    icon: 'calculator'
  },
  'update-facture-state-check_1-no': {
    label: <Translation>{(t) => <span>{t('notSureAboutInvoice')}</span>}</Translation>,
    icon: 'calculator'
  },
  'update-facture-state-check_2-no': {
    label: <Translation>{(t) => <span>{t('updatedInvoiceToCurrent')}</span>}</Translation>,
    icon: 'calculator'
  },
  'update-facture-state-sent': {
    label: <Translation>{(t) => <span>{t('sentInvoiceToAccounting')}</span>}</Translation>,
    icon: 'calculator'
  },
  'update-facture-state-sent-re': {
    label: <Translation>{(t) => <span>{t('resentInvoiceToAccounting')}</span>}</Translation>,
    icon: 'calculator'
  },
  'update-facture-state-sent-mark': {
    label: <Translation>{(t) => <span>{t('markedInvoiceAsSentToAccounting')}</span>}</Translation>,
    icon: 'calculator'
  },
  'update-facture-state-lock-on': {
    label: <Translation>{(t) => <span>{t('lockedEditsOnThisInvoice')}</span>}</Translation>,
    icon: 'calculator'
  },
  'update-facture-state-lock-off': {
    label: <Translation>{(t) => <span>{t('openedEditsOnThisInvoice')}</span>}</Translation>,
    icon: 'calculator'
  },
  'update-facture-comment': {
    label: <Translation>{(t) => <span>{t('commentedInvoice')}</span>}</Translation>,
    icon: 'calculator'
  },
  'update-intervention-price': {
    label: <Translation>{(t) => <span>{t('updatedInterventionPrice')}</span>}</Translation>,
    icon: 'calculator'
  },
  'update-devis-price': {
    label: <Translation>{(t) => <span>{t('updatedQuotePrice')}</span>}</Translation>,
    icon: 'calculator'
  },
  'update-facture-price': {
    label: <Translation>{(t) => <span>{t('updatedSmthngInvoice')}</span>}</Translation>,
    icon: 'calculator'
  },
  'link-facture-ticket': {
    label: <Translation>{(t) => <span>{t('linkedAnotherTicketToInvoice')}</span>}</Translation>,
    icon: 'link'
  },
  'link-ticket-facture': {
    label: <Translation>{(t) => <span>{t('linkedInvoiceToTicket')}</span>}</Translation>,
    icon: 'link'
  },
  'link-ticket': {
    // TODO: Use the payload
    label: ({ payload }) => (
      <Translation>
        {(t) => (
          <span>
            {t('justLinkedTicket')} <strong>#</strong> {t('toThisTicket')}
          </span>
        )}
      </Translation>
    ),
    icon: 'link'
  },
  'remove-facture-attachment': {
    label: <Translation>{(t) => <span>{t('deletedInvoiceFile')}</span>}</Translation>,
    icon: 'paperclip'
  },
  contacts: {
    label: <Translation>{(t) => <span>{t('changedContacts')}</span>}</Translation>,
    icon: 'user'
  },
  equipments: {
    label: <Translation>{(t) => <span>{t('changedEquipments')}</span>}</Translation>,
    icon: 'wrench'
  },
  preventif_checked: {
    label: <Translation>{(t) => <span>{t('hasValidatedPeriod')}</span>}</Translation>,
    icon: 'heartbeat'
  },
  preventif_checked_off: {
    label: <Translation>{(t) => <span>{t('invalidatedPeriod')}</span>}</Translation>,
    icon: 'heartbeat'
  },
  preventif_times: {
    label: <Translation>{(t) => <span>{t('changedPeriodDate')}</span>}</Translation>,
    icon: 'heartbeat'
  },
  preventif_config: {
    label: <Translation>{(t) => <span>{t('changedMaintenancePeriodicity')}</span>}</Translation>,
    icon: 'heartbeat'
  },
  availability: {
    label: <Translation>{(t) => <span>{t('sentAvailabilityMaking')}</span>}</Translation>,
    icon: 'paper-plane'
  },
  email: {
    label: <Translation>{(t) => <span>{t('sentAnEmail')}</span>}</Translation>,
    icon: 'paper-plane'
  },
  'ir-created-reserve': {
    label: <Translation>{(t) => <span>{t('сrreatedALiftingTicket')}</span>}</Translation>,
    icon: 'heartbeat'
  },
  'ir-reserve': {
    //@TRANSLATE
    label: <Translation>{(t) => <span>{'Reserve levée'}</span>}</Translation>,
    icon: 'heartbeat'
  },
  draft_changed: {
    label: <Translation>{(t) => <span>{t('draftChanged')}</span>}</Translation>,
    icon: 'file-text'
  },
  draft_saved: {
    label: <Translation>{(t) => <span>{t('draftSaved')}</span>}</Translation>,
    icon: 'file-text'
  },
  send_ir_to_client: {
    label: ({ payload }) => (
      <Translation>
        {(t) => (
          <span>
            {t('sentReportByEmailToClient')} <strong>{payload?.name}</strong>
          </span>
        )}
      </Translation>
    ),
    icon: 'paper-plane'
  },
  send_ir_to_location: {
    label: ({ payload }) => (
      <Translation>
        {(t) => (
          <span>
            {t('sentReportByEmailLocation')}{' '}
            <strong>{payload?.location_names?.map((location) => location).join(', ')}</strong>
          </span>
        )}
      </Translation>
    ),
    icon: 'paper-plane'
  },
  send_ir_to_email: {
    label: ({ payload }) => (
      <Translation>
        {(t) => (
          <span>
            {t('sentReportByEmail')} <strong>{payload?.emails?.map((email) => email).join(', ')}</strong>
          </span>
        )}
      </Translation>
    ),
    icon: 'paper-plane'
  },
  checked_paid_invoices: {
    label: ({ payload }) => {
      const { invoice_references } = payload;
      const references = invoice_references.map((reference) => `#${reference}`).join(', ');

      if (invoice_references?.length <= 0) {
        return <Translation>{(t) => <span>{t('checkedPaidInvoiceWithoutReference')}</span>}</Translation>;
      } else if (invoice_references?.length === 1) {
        return <Translation>{(t) => <span>{t('checkedPaidInvoice', { references })}</span>}</Translation>;
      } else {
        return <Translation>{(t) => <span>{t('checkedPaidInvoices', { references })}</span>}</Translation>;
      }
    },
    icon: 'calculator'
  },
  unchecked_paid_invoices: {
    label: ({ payload }) => {
      const { invoice_references } = payload;
      const references = invoice_references.map((reference) => `#${reference}`).join(', ');

      if (invoice_references?.length <= 0) {
        return <Translation>{(t) => <span>{t('uncheckedPaidInvoiceWithoutReference')}</span>}</Translation>;
      } else if (invoice_references?.length === 1) {
        return <Translation>{(t) => <span>{t('uncheckedPaidInvoice', { references })}</span>}</Translation>;
      } else {
        return <Translation>{(t) => <span>{t('uncheckedPaidInvoices', { references })}</span>}</Translation>;
      }
    },
    icon: 'calculator'
  },
  create_intervention_delay: {
    label: <Translation>{(t) => <span>{t('createdInterventionDelay')}</span>}</Translation>,
    icon: 'alarm-plus'
  },
  create_resolution_delay: {
    label: <Translation>{(t) => <span>{t('createdResolutionDelay')}</span>}</Translation>,
    icon: 'alarm-plus'
  },
  update_intervention_delay: {
    label: <Translation>{(t) => <span>{t('changedInterventionDelay')}</span>}</Translation>,
    icon: 'alarm-exclamation'
  },
  update_resolution_delay: {
    label: <Translation>{(t) => <span>{t('changedResolutionDelay')}</span>}</Translation>,
    icon: 'alarm-exclamation'
  },
  delete_intervention_delay: {
    label: <Translation>{(t) => <span>{t('deletedInterventionDelay')}</span>}</Translation>,
    icon: 'alarm-exclamation'
  },
  delete_resolution_delay: {
    label: <Translation>{(t) => <span>{t('deletedResolutionDelay')}</span>}</Translation>,
    icon: 'alarm-exclamation'
  }
};

export default historyEntries;
