import { Box, CircularProgress } from '@material-ui/core';

import useStyles from './SuspenseLoader.styles.js';

export default function SuspenseLoader() {
  const classes = useStyles();
  return (
    <Box className={classes.loaderContainer}>
      <CircularProgress size={60} />
    </Box>
  );
}
