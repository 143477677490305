import FAIcon from 'components/ui/FAIcon';
import {
  ClientItem,
  DescriptionItem,
  EquipmentItem,
  LocationItem
} from 'components/views/ViewItems';
import { useConfiguration } from 'hooks/useConfiguration';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddReading from './Dialogs/AddReading';
import { useRole } from 'hooks/useRole';
import { Box, Chip, List } from '@material-ui/core';
import ViewItem from 'components/views/ViewItem';

export default function MeterView({ element }) {
  const { t } = useTranslation();
  const config = useConfiguration();
  const [open_modal, set_open_modal] = useState(false);

  const role = useRole();
  const has_permission_to_add_reading = role.permission('meters', 'create-reading');

  return (
    <>
      <div>
        <div className="text-base font-semibold mb-[16px]">{element?.name}</div>
        {(element.use_id || element.tag_id) && (
          <Box
            display="flex"
            alignItems="center"
            marginTop="10px"
          >
            {element.use_id && (
              <Chip
                className="mr-[6px]"
                color="secondary"
                variant="outlined"
                size="small"
                label={element.use_id.name}
              />
            )}
            {element.tag_id && (
              <Chip
                className="mr-[6px]"
                color="secondary"
                variant="outlined"
                size="small"
                label={element.tag_id.name}
              />
            )}
          </Box>
        )}
      </div>

      <div className="mt-[16px]">
        <List>
          {config.isContractor && element._client && <ClientItem client={element._client} />}

          <DescriptionItem description={element?.description} />

          {element._location && <LocationItem location={element._location} />}

          {element._equipment && <EquipmentItem equipment={element._equipment} />}

          {element?.unit && (
            <ViewItem
              icon="ruler"
              primary={t('unit')}
              secondary={element?.unit}
            />
          )}

          {has_permission_to_add_reading && (
            <div className="flex justify-end">
              <button
                className="flex items-center btn btn-primary rounded-full px-5 bg-[#003366] text-white py-2"
                onClick={() => set_open_modal(true)}
              >
                <FAIcon
                  icon="pen"
                  className="mr-2"
                />
                {t('makeAReading')}
              </button>
            </div>
          )}
          <AddReading
            isOpen={open_modal}
            onClose={() => set_open_modal(false)}
            element={element}
          />
        </List>
      </div>
    </>
  );
}
