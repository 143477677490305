import { Box } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { DataGrid } from '@mui/x-data-grid';
import BlurredProgress from 'components/BlurredProgress';
import { useEntity } from 'contexts/entities/entityContext';
import { elementPushHistory } from 'layouts/entities/Preview';
import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

export default function VirtuosoTable(props) {
  const history = useHistory();

  const { elements, fetchElements, isLoading, setIsOpenForm } = props;

  const { selectedId, tables, db, keyToOpen, disableOpen, updateOnClick } = useEntity();

  const [page, setPage] = useState(1);

  const fetchPage = (page) => {
    setPage(page);
    fetchElements(null, true, page * 30 - 30, 30);
  };

  const count = useMemo(() => (!!elements ? Math.ceil(elements.count / 30) : 0), [elements?.count]);

  const rows = useMemo(() => {
    return elements?.map((e) => {
      return tables.reduce(
        (acc, currentValue) => {
          return {
            ...acc,
            [currentValue.field]: currentValue.value(db[e._id])
          };
        },
        {
          id: e._id,
          keyToOpen: db[e._id][keyToOpen || '_id'],
          element: db[e._id]
        }
      );
    });
  }, [db]);

  return (
    <Box
      style={{
        height: '100%',
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-end',
        alignContent: 'space-between'
      }}
    >
      <Box
        style={{
          height: count > 1 ? 'calc(100% - 54px)' : '100%',
          width: '100%',
          position: 'relative'
        }}
      >
        <BlurredProgress in={isLoading} />
        {rows && (
          <DataGrid
            rows={rows}
            columns={tables}
            disableColumnFilter
            disableColumnMenu
            hideFooter
            hideFooterPagination
            hideFooterSelectedRowCount
            onRowDoubleClick={(e) => {
              if (updateOnClick) {
                setIsOpenForm(e.row.keyToOpen);
              } else {
                if (!disableOpen) {
                  elementPushHistory(history, selectedId, e.row.keyToOpen);
                }
              }
            }}
          />
        )}
      </Box>

      {count > 1 && (
        <Box
          style={{
            height: '54px',
            display: 'flex',
            alignItems: 'center',
            padding: '16px',
            borderTop: '1px solid #eaeaea',
            width: '100%',
            justifyContent: 'flex-end'
          }}
        >
          {
            <Pagination
              count={count}
              page={page}
              onChange={(_, page) => fetchPage(page)}
              color="primary"
            />
          }
        </Box>
      )}
    </Box>
  );
}
