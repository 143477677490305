const StockFormValues = (configuration, payload, user, isCreate, _interface, auth) => {
  const { db, element } = payload;

  return {
    name: '',
    referenceMaker: '',
    comments: '',
    supplierItemCode: '',
    warehouses: isCreate ? [] : [db[element._id]],
    cover: null
  };
};
export default StockFormValues;

export const StockValidation = (configuration) => ({
  name: (str) => !!str,
  referenceMaker: (str) => !!str && str.length,
  warehouses: {
    locationPrecision: (v) => !!v.length
  }
});

export const formatForm = (dataFromForm, isCreate, attachments, _interface) => {
  const formattedData =
    !isCreate &&
    dataFromForm.warehouses.reduce((acc, cur) => {
      return {
        emailAlert: cur.emailAlert,
        orderStock: cur.orderStock,
        stock: cur.stock,
        targetStock: cur.targetStock,
        unitValuationPrice: cur.unitValuationPrice,
        locationPrecision: cur.locationPrecision,
        supplierItemCode: cur.supplierItemCode,
        _equipments: cur._equipments,
        _locations: cur._locations,
        _managers: cur._managers,
        _suppliers: cur._suppliers
      };
    }, {});

  return isCreate
    ? { ...dataFromForm, cover: attachments[0]?.url }
    : { ...dataFromForm, warehouses: [], ...formattedData, cover: attachments[0]?.url };
};
