import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SystemsStatus from 'components/SystemsStatus';
import { OldLoginForm } from './OldLoginForm';

export const LoginPage = () => {
  const { t } = useTranslation();

  return (
    <>
      {/* À décommenter pour le login Google une fois que nous serons entièrement passés à Supabase ! */}
      {/* <div className="flex flex-col">
        <LoginGoogle />
      </div>
      <div className="flex items-center my-4">
        <div className="border-t border-gray-300 flex-grow"></div>
        <div className="mx-4 text-gray-500">{t('or')}</div>
        <div className="border-t border-gray-300 flex-grow"></div>
      </div>
      <LoginForm /> */}

      <OldLoginForm />
      <div className="flex flex-col items-center mb-4">
        <Link
          to="/sso-login"
          className="underline px-1"
        >
          <Typography>{t('continue-with-SSO')}</Typography>
        </Link>
      </div>
      <SystemsStatus />
    </>
  );
};
