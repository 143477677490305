import { MenuItem, Select } from '@material-ui/core';
import { useField } from 'frmx';
import { useConfiguration } from 'hooks/useConfiguration';
import { useTranslation } from 'react-i18next';
import useKeys from '@flowsn4ke/usekeys';

export default function AutoCompleteSelect({ path, placeholder }) {
  const config = useConfiguration();

  const { t } = useTranslation();

  const options = [
    { label: config.isContractor ? 'subcontractorsOption' : 'outsideCompanyOption', value: 'mine' },
    { label: 'techniciansOption', value: 'collaborator' }
  ];

  const { value, setValue } = useField(path);

  const k = useKeys();

  return (
    <Select
      fullWidth
      variant="outlined"
      value={value}
      onChange={(e) => setValue(e.target.value)}
    >
      {options.map((o, i) => (
        <MenuItem
          key={k(i)}
          value={o.value}
        >
          {t(o.label)}
        </MenuItem>
      ))}
    </Select>
  );
}
