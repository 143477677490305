import classNames from 'classnames';
import { endOfDay, isSameDay } from 'date-fns';
import { HOURS_COLUMN_WIDTH, DAYS_HEADER_HEIGHT, INTERVENTION_TYPE } from 'modules/calendar/config';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';

export default function DaysHeader({ days, events, day_width }) {
  const { t } = useTranslation();

  return (
    <div
      style={{ height: DAYS_HEADER_HEIGHT }}
      className="z-10 sticky top-0 bg-white drop-shadow divide-x flex border-b"
    >
      <div style={{ width: HOURS_COLUMN_WIDTH }} />
      {days.map((day) => {
        const is_today = isSameDay(day.date, new Date());
        const start = day.date;
        const end = endOfDay(day.date);

        const daily_events_count = events.filter(
          (event) => event.start >= start && event.start <= end && event.type === INTERVENTION_TYPE
        ).length;

        return (
          <div
            key={nanoid()}
            style={{ width: day_width }}
            className="flex flex-col items-center justify-center py-3 relative"
          >
            <div>
              <span className="flex items-baseline text-base">
                {day.label}{' '}
                <span
                  className={classNames(
                    'ml-1.5 flex items-center justify-center rounded-full font-semibold',
                    is_today ? 'h-6 w-6 bg-red-600 text-white' : 'text-gray-900'
                  )}
                >
                  {day.date.getDate()}
                </span>
              </span>
            </div>
            {daily_events_count > 0 && (
              <div
                style={{ fontSize: '0.7rem' }}
                className="font-medium absolute bottom-1"
              >
                {daily_events_count}{' '}
                {daily_events_count > 1 ? t('interventionsLowerCase') : t('interventionLowerCase')}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}
