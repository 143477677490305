import { Button, TextField, Fade, Typography } from '@material-ui/core';
import FAIcon from 'components/ui/FAIcon';
import { isEmail } from 'validator';
import { Link } from 'react-router-dom';
import { loginUser } from 'store/authSlice';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import { Form, Submit, Field } from 'frmx';
import PasswordField from 'components/inputs/PasswordField';
import useStyles from './Portal.styles';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';
import classNames from 'utils/classNames';
import { INTERFACE_ID } from 'constants/localStorage';

export function OldLoginForm() {
  const { dispatch, requestStatus } = useAsyncDispatch();
  const classes = useStyles();
  const { t } = useTranslation();

  let initialValues = useRef({
    email: '',
    password: '',
    interface_id: localStorage.getItem(INTERFACE_ID),
    screen_width: window.screen.width,
    screen_height: window.screen.height
  });

  initialValues = initialValues.current;

  let schemaValidation = useRef({
    email: (val) => isEmail(val),
    password: (str) => str?.length > 0
  });
  schemaValidation = schemaValidation.current;

  return (
    <Form
      render="form"
      initialValues={initialValues}
      schemaValidation={schemaValidation}
      onSubmit={(data) => dispatch(loginUser, data)}
      className={classes.formContainer}
      disableIfInvalid
    >
      <Field
        path="email"
        type="email"
        isErrorProp="error"
      >
        <TextField
          required
          variant="outlined"
          label={t('emailInputLabel')}
          className={classes.spacing}
        />
      </Field>

      <div className="relative w-full">
        <Link
          to="/reset-password"
          className="absolute right-2 -top-1.5 p-2"
        >
          <Typography variant="body2">{t('resetPasswordLink')}</Typography>
        </Link>
        <PasswordField
          path="password"
          className={classes.spacing}
          style={{ marginTop: 20 }}
        />
      </div>

      <Submit disabled={requestStatus === 'loading'}>
        <Button
          className={classNames(classes.spacing, classes.submitButtonContainer)}
          variant="contained"
          color="primary"
          disableElevation
        >
          <Typography>{t('signInButtonLabel')}</Typography>
          <FAIcon
            icon={requestStatus === 'loading' ? 'spinner' : 'power-off'}
            className={requestStatus === 'loading' && classes.spinner}
            collection={'fas'}
            size="medium"
          />
        </Button>
      </Submit>

      <Fade in={requestStatus === 'error'}>
        <Typography
          variant="body2"
          color="error"
          className={classes.messageError}
        >
          {t('loginWrongDetailsErrorMsg')}
        </Typography>
      </Fade>
    </Form>
  );
}
