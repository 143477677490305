import {
  getElement,
  getElements,
  createElement,
  updateElement,
  flushElements,
  deleteElement,
  restoreElement
} from 'store/stocksSlice';

import StockMenus from './StockMenus';
import StockPreview from './StockPreview';
import StockSkeleton from './StockSkeleton';
import StockView from './StockView';
import StockTabs from './StockTabs';
import StockFilters, { formatFilter } from './StockFilters';
import StockFormValues, { formatForm, StockValidation } from './StockFormValues';
import StockTranslations from './StockTranslations';
import StockExport from './StockExport';
import StockForm from './StockForm';
import StockSorts from './StockSorts';

import EntityWrapper from 'entities/EntityWrapper';
import { Image } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

export default function Stock(props) {
  const { t } = useTranslation();

  return (
    <EntityWrapper
      entity="stocks"
      skeletonComponent={StockSkeleton}
      previewComponent={StockPreview}
      viewComponent={StockView}
      formComponent={StockForm}
      formatFilter={formatFilter}
      getFilters={StockFilters}
      getInitialValues={StockFormValues}
      getFormValidation={StockValidation}
      getTabs={StockTabs}
      formatForm={formatForm}
      getTables={() => []}
      getMenus={StockMenus}
      getExportColumns={StockExport}
      getTranslations={StockTranslations}
      getSorts={StockSorts}
      getElement={getElement}
      getElements={getElements}
      createElement={createElement}
      updateElement={updateElement}
      flushElements={flushElements}
      deleteElement={deleteElement}
      restoreElement={restoreElement}
      formAttachment
      formAttachmentIcon={<Image />}
      formAttachmentLabel={t('coverPicture')}
      {...props}
    />
  );
}
