import {
  INTERFACE_MASTER,
  INTERFACE_CLIENT,
  INTERFACE_CONTRACTOR,
  INTERFACE_USER,
  INTERFACE_TECHNICIAN
} from 'constants/roles';

const MAINTENANCES_MENU = {
  label: 'menuItemMaintenances',
  icon: 'heartbeat',
  url: '/maintenances',
  feature: 'preventif',
  entity: 'maintenance',
  customFields: true,
  check: { entity: 'maintenances', permission: 'planning_view' }
};
const MAINTENANCES_USERS_MENU = {
  label: 'menuItemMaintenances',
  icon: 'heartbeat',
  url: '/maintenances-users',
  feature: 'preventif',
  entity: 'maintenance',
  customFields: false,
  check: { entity: 'maintenances', permission: 'planning_view' }
};
const EQUIPMENTS_MENU = {
  label: 'menuItemEquipments',
  icon: 'wrench-simple',
  url: '/equipments-list',
  feature: 'equipment',
  entity: 'equipment',
  customFields: true
};
const MANAGERS_MENU = {
  label: 'menuItemManagers',
  icon: 'user-headset',
  url: '/managers',
  entity: 'manager',
  customFields: true
};
const ALL_MANAGERS_MENU = {
  label: 'menuItemManagers',
  icon: 'user-headset',
  url: '/master/managers'
};
const CONTRACTORS_MENU = {
  label: 'menuItemContractors',
  icon: 'user-hard-hat',
  url: '/interveners',
  entity: 'technician',
  customFields: true
};
const CUSTOMERS_MENU = {
  label: 'menuItemCompanies',
  icon: 'funnel-dollar',
  url: '/customers',
  mobile: false
};
const DOCUMENTS_MENU = {
  label: 'menuItemDocuments',
  icon: 'clouds',
  url: '/documents'
};
const DASHBOARD_MENU = {
  label: 'menuItemDashboard',
  icon: 'chart-line',
  url: '/desk',
  check: { entity: 'bobdesk', permission: 'dashboard' },
  feature: 'dashboard'
};
const IMPORT_MENU = {
  label: 'menuItemBobImport',
  icon: 'download',
  url: '/import'
};
const INTERVENTIONS_MENU = {
  label: 'menuItemInterventions',
  icon: 'files',
  url: '/tickets',
  entity: 'ticket',
  customFields: true,
  mobile: false
};
const CALENDAR_MENU = {
  label: 'agenda',
  icon: 'calendar',
  url: '/calendar',
  feature: 'agenda',
  mobile: false
};
const INVENTORY_MENU = {
  label: 'menuItemInventory',
  icon: 'box-taped',
  url: '/stocks',
  feature: 'inventory'
};
const ACCOUNTING_MENU = {
  label: 'menuItemComptability',
  icon: 'calculator',
  url: '/accounting',
  feature: 'comptability',
  permission: true,
  check: { entity: 'accounting', permission: 'view' }
};
const USERS_MENU = {
  label: 'menuItemUsers',
  icon: 'user',
  url: `/users`,
  feature: 'contact',
  entity: 'user',
  customFields: true
};
const MARKETPLACE_MENU = {
  label: 'menuItemMarketplace',
  icon: 'book-spells',
  url: '/marketplace'
};
const LOCATIONS_MENU = {
  label: 'menuItemLocations',
  icon: 'map-marker-alt',
  url: '/locations-list',
  entity: 'location',
  customFields: true
};
const PROPOSITIONS_MENU = {
  label: 'menuItemPropositions',
  icon: 'mailbox',
  url: '/propositions',
  entity: 'contractproposition',
  customFields: false,
  mobile: false
};
const CLIENTS_MENU = {
  label: 'menuItemClients',
  icon: 'house-building',
  url: '/clients',
  entity: 'client',
  customFields: true
};
const API_MENU = {
  label: 'menuItemAPI',
  icon: 'charging-station',
  url: '/apis'
};

const BOB_TEAM_MENU = {
  label: 'menuItemBobTeam',
  icon: 'user-crown',
  url: `/managers`
};
const TECHNICIANS_MENU = {
  label: 'menuItemTechnicians',
  icon: 'user-check',
  url: '/master/technicians'
};
const GUARANTEES_MENU = {
  label: 'guaranteesTitle',
  icon: 'file-contract',
  url: `/guarantees`,
  feature: 'guarantee',
  entity: 'guarantee',
  customFields: true
};
const CONTRACTS_MENU = {
  label: 'contractsTitle',
  icon: 'file-contract',
  url: `/contracts`,
  feature: 'contract',
  entity: 'contract',
  customFields: true
};
const LEASES_MENU = {
  label: 'leasesTitle',
  icon: 'house',
  url: `/leases`,
  feature: 'lease',
  entity: 'lease',
  customFields: true
};

const REPORTS_FILE_MENU = {
  label: 'reportsFileTitle',
  icon: 'tasks',
  url: `/safety`,
  check: { entity: 'maintenances', permission: 'showSafety' },
  feature: 'safety_committee'
};

const METERS_MENU = {
  label: 'metersTitle',
  icon: 'meter',
  url: `/meters`,
  feature: 'meters'
};

const clientMenus = [
  DASHBOARD_MENU,
  INTERVENTIONS_MENU,
  MAINTENANCES_MENU,
  MAINTENANCES_USERS_MENU,
  REPORTS_FILE_MENU,
  LOCATIONS_MENU,
  CALENDAR_MENU,
  CONTRACTORS_MENU,
  EQUIPMENTS_MENU,
  INVENTORY_MENU,
  METERS_MENU,
  ACCOUNTING_MENU,
  DOCUMENTS_MENU,
  USERS_MENU,
  MANAGERS_MENU
];

const userMenus = [
  DASHBOARD_MENU,
  INTERVENTIONS_MENU,
  MAINTENANCES_MENU,
  MAINTENANCES_USERS_MENU,
  LOCATIONS_MENU,
  EQUIPMENTS_MENU,
  CALENDAR_MENU,
  ACCOUNTING_MENU
];

const collaboratorMenus = [
  PROPOSITIONS_MENU,
  INTERVENTIONS_MENU,
  MAINTENANCES_MENU,
  MAINTENANCES_USERS_MENU,
  CALENDAR_MENU
];

const contractorMenus = [
  DASHBOARD_MENU,
  CLIENTS_MENU,
  PROPOSITIONS_MENU,
  INTERVENTIONS_MENU,
  MAINTENANCES_MENU,
  LOCATIONS_MENU,
  CALENDAR_MENU,
  CONTRACTORS_MENU,
  EQUIPMENTS_MENU,
  INVENTORY_MENU,
  METERS_MENU,
  DOCUMENTS_MENU,
  USERS_MENU,
  MANAGERS_MENU
];

const masterMenus = [CUSTOMERS_MENU, MARKETPLACE_MENU, INTERVENTIONS_MENU, IMPORT_MENU, API_MENU, BOB_TEAM_MENU];

export const masterExtraMenus = [
  ALL_MANAGERS_MENU,
  TECHNICIANS_MENU,
  LOCATIONS_MENU,
  EQUIPMENTS_MENU,
  MAINTENANCES_MENU,
  DOCUMENTS_MENU
];

export const additionalCustomFielsMenus = [GUARANTEES_MENU, CONTRACTS_MENU, LEASES_MENU];

const getDefaultMenus = (type) => {
  switch (type) {
    case INTERFACE_MASTER:
      return masterMenus;
    case INTERFACE_CLIENT:
      return clientMenus;
    case INTERFACE_CONTRACTOR:
      return contractorMenus;
    case INTERFACE_USER:
      return userMenus;
    case INTERFACE_TECHNICIAN:
      return collaboratorMenus;
    default:
      throw new Error('No interface role was defined');
  }
};

export const getMenus = (_interface, user) => {
  const defaultMenus = getDefaultMenus(_interface.type);

  const blach = defaultMenus.map((m) => (m.label === 'menuItemInterventions' ? { ...m, label: 'tickets' } : m));

  const selectedMenus = (_interface.isBlachere ? blach : defaultMenus).filter((menuItem) => {
    if (menuItem.label === 'menuItemMaintenances' && _interface._company._configuration.feature.preventif) {
      const is_user_maintenance_view_item = menuItem.url === '/maintenances-users';
      const is_full_maintenance_view_item = menuItem.url === '/maintenances';
      const has_permission_to_see_maintenances_planning_view = _interface._role.permissions[
        menuItem.check.entity
      ].includes(menuItem.check.permission);

      if (is_full_maintenance_view_item && has_permission_to_see_maintenances_planning_view) return true;
      if (is_user_maintenance_view_item && !has_permission_to_see_maintenances_planning_view) return true;
      return false;
    }

    return (
      ((!menuItem.tech || ['5d8b85dddd13c740ace4dcce'].includes(user?._id)) &&
        !!(!menuItem.feature || _interface._company._configuration.feature[menuItem.feature]) &&
        !!(
          !menuItem.permission ||
          _interface._role.permissions[menuItem.check.entity].includes(menuItem.check.permission)
        )) ||
      menuItem.label === 'divider'
    );
  });

  return selectedMenus;
};

export const STARTUP = {
  [INTERFACE_MASTER]: CUSTOMERS_MENU,
  [INTERFACE_CLIENT]: INTERVENTIONS_MENU,
  [INTERFACE_CONTRACTOR]: CLIENTS_MENU,
  [INTERFACE_USER]: INTERVENTIONS_MENU,
  [INTERFACE_TECHNICIAN]: INTERVENTIONS_MENU
};

export const STARTUP_MOBILE = {
  [INTERFACE_MASTER]: CUSTOMERS_MENU,
  [INTERFACE_CLIENT]: INTERVENTIONS_MENU,
  [INTERFACE_CONTRACTOR]: INTERVENTIONS_MENU,
  [INTERFACE_USER]: INTERVENTIONS_MENU,
  [INTERFACE_TECHNICIAN]: INTERVENTIONS_MENU
};
