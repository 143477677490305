import { AccordionActions, AccordionDetails, Grid, Button } from '@material-ui/core';

import useNotifications from 'hooks/useNotifications';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import { setNewPassword } from 'store/authSlice';
import { Submit, Reset } from 'frmx';

import AccordionForm from 'components/forms/AccordionForm';
import PasswordField from 'components/inputs/PasswordField';

import useFormStyles from 'styles/Form.styles';
import { useTranslation } from 'react-i18next';

export default function ChangePassword({ section }) {
  const { dispatch, requestStatus } = useAsyncDispatch();
  const notify = useNotifications();
  const { t } = useTranslation();

  const dispatchCallbacks = {
    onSuccess: () => notify.success(t('passwordSaved')),
    onError: () => notify.error()
  };

  const formClasses = useFormStyles();
  return (
    <AccordionForm
      section={section}
      initialValues={{
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: ''
      }}
      schemaValidation={{
        newPassword: (password) => password?.length > 1
      }}
      disableIf={(formData) => formData.newPassword !== formData.confirmNewPassword}
      disableIfInvalid
      onSubmit={(updates) =>
        dispatch(setNewPassword, { password: updates.newPassword }, dispatchCallbacks)
      }
    >
      <AccordionDetails>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            {/* TODO: Implement current password verification before updating on the backend */}
            <PasswordField
              autoComplete="new-password"
              fullWidth
              path="currentPassword"
              label={t('password')}
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <PasswordField
              autoComplete="new-password"
              fullWidth
              path="newPassword"
              label={t('newPassword')}
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <PasswordField
              autoComplete="new-password"
              fullWidth
              path="confirmNewPassword"
              label={t('confirmPassword')}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
      {/* <Divider /> */}
      <AccordionActions>
        <Reset>
          <Button size="small">{t('cancel')}</Button>
        </Reset>

        <Submit disabled={requestStatus === 'loading'}>
          <Button
            className={formClasses.success}
            size="small"
            color="primary"
          >
            {t('save')}
          </Button>
        </Submit>
      </AccordionActions>
    </AccordionForm>
  );
}
