import { makeStyles } from '@material-ui/core/styles';

const avatarSizes = {
  xsxsmall: 20,
  xsmall: 26,
  small2: 28,
  small3: 32,
  small: 42,
  medium: 48,
  large2: 80,
  large: 100,
  chip: 16
};

export default makeStyles((theme) => ({
  isUpdateBox: ({ size, round }) => ({
    width: '100%',
    height: '100%',
    backgroundColor: 'rgb(255 255 255 / 80%)',
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
    borderRadius: round ? '50%' : 8
  }),
  isUpdate: {
    color: theme.palette.info.light
  },
  isDelete: {
    color: theme.palette.error.light
  },
  isLoading: {
    ...theme.spinnerAnimation,
    color: theme.palette.secondary.light
  },
  isUpdateScale: {
    transform: 'scale(1.3)'
  },
  avatarButton: ({ size, round }) => ({
    borderRadius: round ? '50%' : 8,
    height: avatarSizes[size],
    color: theme.palette.info.main,
    width: avatarSizes[size]
  }),
  image: ({ round, size }) => ({
    height: avatarSizes[size],
    width: avatarSizes[size],
    borderRadius: round ? '50%' : 4,
    objectFit: 'contain'
  }),
  avatar: ({ hasAvatar, avatar, shadow, size, round, border, isUser = false, white }) => ({
    ...theme.transitionTheme,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    boxShadow: shadow && theme.shadows[3],
    borderRadius: round ? '50%' : 8,
    height: avatarSizes[size],
    width: avatarSizes[size],
    backgroundSize: 'contain',
    color: hasAvatar ? 'transparent' : white && theme.palette.primary.main,
    backgroundImage: 'url(' + avatar + ')',
    backgroundColor: hasAvatar || white ? 'white' : theme.palette.primary[isUser ? 'dark' : 'main'],
    boxSizing: 'content-box'
  })
}));
