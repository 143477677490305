import { Button, Box, Typography } from '@material-ui/core';
import FAIcon from 'components/ui/FAIcon';

import { useParams } from 'react-router-dom';
import { setPassword, loginUser } from 'store/authSlice';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import { Form, Submit } from 'frmx';

import Check from 'libs/swal/Check';
import PasswordField from 'components/inputs/PasswordField';

import useStyles from './Portal.styles';
import { useTranslation } from 'react-i18next';
import classNames from 'utils/classNames';

export default function SetPasswordPage() {
  const { dispatch, requestStatus, setRequestStatus } = useAsyncDispatch();
  const { token } = useParams();

  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Form
      render="form"
      initialValues={{
        password: '',
        confirmedPassword: ''
      }}
      schemaValidation={{
        password: (str) => str?.length > 0,
        confirmedPassword: (str) => str?.length > 0
      }}
      disableIf={(data) => data.password !== data.confirmedPassword || data.password < 1}
      onSubmit={(formData) => {
        dispatch(setPassword, { password: formData.password }, {}, { token }).then(
          ({ data, error }) => {
            if (!error && data?.email)
              dispatch(loginUser, { email: data.email, password: formData.password });
            else setRequestStatus('error');
          }
        );
      }}
      disableIfInvalid
    >
      <Typography
        variant="subtitle1"
        className={classes.message}
      >
        {requestStatus === 'success'
          ? t('congratsWereConnectingYou')
          : t('setPasswordPageSubtitle')}
      </Typography>

      {requestStatus === 'success' ? (
        <Check />
      ) : (
        <>
          <PasswordField
            path="password"
            label={t('newPasswordInputLabel')}
            variant="outlined"
            className={classes.spacing}
            disabled={requestStatus === 'loading'}
            required
          />

          <PasswordField
            path="confirmedPassword"
            label={t('confirmedPasswordInputLabel')}
            variant="outlined"
            className={classes.spacing}
            disabled={requestStatus === 'loading'}
            required
          />
        </>
      )}

      <Submit disabled={requestStatus === 'loading'}>
        <Button
          className={classNames(classes.spacing, classes.submitButtonContainer)}
          variant="contained"
          color="primary"
          disableElevation
        >
          <Typography>{t('resetPasswordButtonLabel')}</Typography>
          <FAIcon
            collection={'fas'}
            icon={requestStatus === 'loading' ? 'spinner' : 'key'}
            className={requestStatus === 'loading' && classes.spinner}
            size="medium"
          />
        </Button>
      </Submit>

      {requestStatus === 'error' && (
        <Box>
          <Typography
            variant="body2"
            color="error"
            className={classes.messageError}
          >
            {t('genericError')}
          </Typography>
        </Box>
      )}
    </Form>
  );
}
