import { roleTypes } from 'config/role';

const RoleFilters = (configuration, defaultFilters) =>
  [
    {
      label: 'type',
      key: 'types',
      type: 'List',
      params: {
        icon: 'asterisk',
        datas: roleTypes[configuration.type]
      },
      disabled: false,
      show: true,
      default: []
    }
  ].filter((e) => e.show);

export const formatFilter = (filters) => filters;

export default RoleFilters;
