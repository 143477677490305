export const equipmentWear = [
  {
    label: 'new',
    value: '0'
  },
  {
    label: 'goodGeneralState',
    value: '1'
  },
  {
    label: 'notSoGood',
    value: '2'
  },
  {
    label: 'deteriorated',
    value: '3'
  },
  {
    label: 'veryDeteriorated',
    value: '4'
  }
];
