import DragScrollContainer from 'components/ui/DragScrollContainer';
import { useField } from 'frmx';
import { useTranslation } from 'react-i18next';
import { getColor } from 'router/Corentin/utils';

export default function BookmarkBar({ bookmarkState }) {
  const { t } = useTranslation();
  const { value = [], setValue } = useField('bookmarks');

  if (!bookmarkState?.bookmarks?.length) return null;
  const { bookmarks = [], isLoading = false } = bookmarkState;

  if (isLoading) {
    return (
      <DragScrollContainer
        className="relative py-1 overflow-hidden"
        sortingOrders={[]}
      >
        {Array.from({ length: 5 }).map((_, idx) => (
          <div
            key={idx}
            className="whitespace-nowrap border-2 rounded-full py-0.5 px-10 bg-gray-200 animate-pulse"
          >
            &nbsp; {/* Empty space for skeleton loading */}
          </div>
        ))}
      </DragScrollContainer>
    );
  }

  const toggleBookmark = ({ key }) => {
    if (value.includes(key)) {
      setValue(value.filter((cle) => cle !== key));
    } else {
      setValue([...value, key]);
    }
  };

  return (
    <DragScrollContainer
      className="relative py-1 overflow-hidden"
      sortingOrders={[]}
    >
      {bookmarks.map((bookmark, bookmarkIdx) => {
        const { inactiveTextColor, inactiveBorderColor, activeTextColor, activeBorderColor } = getColor(bookmark.color);
        const isActive = value.includes(bookmark.key); // Check if the bookmark is active

        return (
          <div
            key={bookmarkIdx}
            className={`whitespace-nowrap border-2 rounded-full py-0.5 px-3 cursor-pointer ${
              isActive ? activeBorderColor : inactiveBorderColor
            } ${isActive ? activeTextColor : inactiveTextColor}  transition`}
            onClick={() => toggleBookmark({ key: bookmark.key })}
          >
            {t(bookmark.label)}
          </div>
        );
      })}
    </DragScrollContainer>
  );
}
