import EntityWrapper from 'entities/EntityWrapper';
import MeterSkeleton from './MeterSkeleton';
import MeterPreview from './MeterPreview';
import MeterView from './MeterView';
import MeterForm from './MeterForm';
import MeterFormValues, { MeterValidation, formatForm } from './MeterFormValues';
import MeterFilters, { formatFilter } from './MeterFilters';
import MeterTranslations from './MeterTranslations';
import {
  get_meters,
  get_meter_by_id,
  create_meter,
  update_meter,
  delete_meter,
  restore_meter,
  flushElements
} from 'store/metersSlice';
import MeterMenus from './MeterMenus';
import MeterTabs from './MeterTabs';

export default function Meter({ ...rest }) {
  return (
    <EntityWrapper
      entity="meters"
      skeletonComponent={MeterSkeleton}
      previewComponent={MeterPreview}
      viewComponent={MeterView}
      formComponent={MeterForm}
      formatForm={formatForm}
      formatFilter={formatFilter}
      getFilters={MeterFilters}
      getInitialValues={MeterFormValues}
      getFormValidation={MeterValidation}
      getTabs={MeterTabs}
      getSorts={() => []}
      getExportColumns={() => []}
      getTranslations={MeterTranslations}
      getMenus={MeterMenus}
      getElements={get_meters}
      getElement={get_meter_by_id}
      createElement={create_meter}
      updateElement={update_meter}
      deleteElement={delete_meter}
      restoreElement={restore_meter}
      flushElements={flushElements}
      {...rest}
    />
  );
}
