import {
  FULL_DAY_HEIGHT,
  WEEK_VIEW_TYPE,
  DAY_VIEW_TYPE,
  HOURS_COLUMN_WIDTH,
  MINUTE_HEIGHT,
  DAYS_HEADER_HEIGHT,
  MAGIC_PIXEL,
  EVENT_PADDING,
  EVENT_MIN_HEIGHT
} from 'modules/calendar/config';

import Events from 'modules/calendar/daily/Events';
import WeekGrid from 'modules/calendar/daily/WeekGrid';
import DaysHeader from 'modules/calendar/daily/DaysHeader';

import Cells from './Cells';
import Now from './Now';
import classNames from 'utils/classNames';
import { get_event_y_position_in_minutes, get_displayed_days } from '../utils';
import { useMemo } from 'react';
import { isSameDay } from 'date-fns';
import { useUI } from 'hooks/useUI';

export default function DailyView({
  now,
  start,
  end,
  events,
  set_events,
  set_unavailabilities,
  view_type,
  day_width_px,
  selected_range,
  events_container_ref,
  number_of_days_displayed,
  set_ticket_in_modal,
  set_unavailability_id_to_edit,
  set_open_dialog_actions
}) {
  const ui = useUI();
  const locale = ui.get_date_time_locale();
  const days_of_the_week = useMemo(
    () => get_displayed_days(start, end, locale),
    [start, end, locale]
  );
  const is_visible = view_type === WEEK_VIEW_TYPE || view_type === DAY_VIEW_TYPE;
  const getDayIdx = (date) => days_of_the_week.findIndex((day) => isSameDay(day.date, date));

  const compute_initial_position = (event, { use_padding = true } = {}) => {
    const start = new Date(event.start);
    const end = new Date(event.end);

    const computed_top = get_event_y_position_in_minutes(start) * MINUTE_HEIGHT;
    const top_offset = DAYS_HEADER_HEIGHT + MAGIC_PIXEL;
    let computed_height =
      (get_event_y_position_in_minutes(end) - get_event_y_position_in_minutes(start)) *
      MINUTE_HEIGHT;
    computed_height = computed_height < EVENT_MIN_HEIGHT ? EVENT_MIN_HEIGHT : computed_height;
    const height_offset = -(EVENT_PADDING * 2 * use_padding);
    const computed_left = day_width_px * getDayIdx(start);
    const left_offset = HOURS_COLUMN_WIDTH;
    const computed_width = day_width_px;
    const width_offset = -(EVENT_PADDING * 2 * use_padding);

    const top = top_offset + computed_top;
    const height = height_offset + computed_height;
    const left = left_offset + computed_left;
    const width = width_offset + computed_width;

    return { top, height, left, width };
  };

  const compute_cell_initial_position = (cell) =>
    compute_initial_position(cell, { use_padding: false });

  return (
    <div
      ref={events_container_ref}
      className={classNames(
        'w-full h-full overflow-auto relative rounded isolate',
        is_visible ? '' : 'hidden'
      )}
      style={{ height: FULL_DAY_HEIGHT }}
    >
      <DaysHeader
        events={events}
        days={days_of_the_week}
        day_width={day_width_px}
      />
      <Now
        now={now}
        compute_initial_position={compute_cell_initial_position}
      />
      <WeekGrid
        numberOfDaysToDisplay={number_of_days_displayed}
        dayWidth={day_width_px}
      />
      <Cells
        end={end}
        start={start}
        set_events={set_events}
        set_unavailabilities={set_unavailabilities}
        compute_initial_position={compute_cell_initial_position}
        set_open_dialog_actions={set_open_dialog_actions}
        selected_range={selected_range}
      />
      <Events
        events={events}
        compute_initial_position={compute_initial_position}
        set_ticket_in_modal={set_ticket_in_modal}
        set_unavailability_id_to_edit={set_unavailability_id_to_edit}
      />
    </div>
  );
}
