import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  card: {
    height: '100%',
    width: '100%',
    background: 'white',
    borderRadius: '8px'
  },
  icon: {
    color: theme.palette.secondary.main
  },
  title: {
    color: theme.palette.primary.main
  }
}));
