import { createSlice } from '@reduxjs/toolkit';
import generateAsyncThunk from 'utils/generateAsyncThunk';

export const getBudgets = generateAsyncThunk({ type: 'GET', endpoint: 'budgets' });
export const createBudget = generateAsyncThunk({ type: 'POST', endpoint: 'budgets' });
export const updateBudgets = generateAsyncThunk({
  type: 'PUT',
  endpoint: 'budgets/update-budgets'
});
export const deleteBudget = generateAsyncThunk({
  type: 'DELETE',
  endpoint: 'budgets/${budget_id}'
});
export const updateBudgetsUse = generateAsyncThunk({
  type: 'PUT',
  endpoint: 'budgets/update-budgets-use'
});
export const addBudgetOnTicket = generateAsyncThunk({
  type: 'POST',
  endpoint: 'budgets/add-budget-on-ticket'
});

const initialState = [];

export const budgetsSlice = createSlice({
  name: 'budgets',
  initialState,
  reducers: {
    updateBudget: (state, action) => {
      const { budget } = action.payload;
      const index = state.findIndex((b) => b._id === budget._id);
      state[index] = { ...state[index], ...budget };
    }
  },
  extraReducers: {
    [getBudgets.fulfilled]: (state, action) => action.payload.data.budgets,
    [createBudget.fulfilled]: (state, action) => [...state, action.payload.data.budget],
    [deleteBudget.fulfilled]: (state, action) =>
      state.filter((budget) => budget._id !== action.meta.budget_id)
  }
});
export const { updateBudget } = budgetsSlice.actions;
export const selectBudgets = (state) => state.budgets;
export default budgetsSlice.reducer;
