import { Chip } from '@material-ui/core';
import FAIcon from 'components/ui/FAIcon';
import { ClientItemPreview } from 'entities/Client/ClientPreview';

export default function MeterPreview({ element, isActive }) {
  return (
    <div className="w-full">
      <div className="text-sm font-semibold flex justify-between items-center">
        {element.name}
        {element._client && <ClientItemPreview client={element._client} />}
      </div>
      <div className="mt-2">{element.description}</div>

      <div className="flex items-center justify-between mt-2">
        {element._location && (
          <div className="flex items-center">
            <FAIcon
              icon="map-marker-alt"
              collection="fal"
              size="small"
              className="ml-[-4px]"
            />
            {element._location.name}
          </div>
        )}

        {element._equipment && (
          <div className="flex items-center">
            <FAIcon
              icon="wrench-simple"
              collection="fal"
              size="small"
              className="ml-[-4px]"
            />
            {element._equipment.name}
          </div>
        )}

        <div>
          {element?.use_id && (
            <Chip
              className="text-[10px] ml-[6px]"
              color="secondary"
              variant={isActive ? 'default' : 'outlined'}
              size="small"
              label={element?.use_id?.name}
            />
          )}
          {element?.tag_id && (
            <Chip
              className="text-[10px] ml-[6px]"
              color="secondary"
              variant={isActive ? 'default' : 'outlined'}
              size="small"
              label={element?.tag_id.name}
            />
          )}
        </div>
      </div>
      <div></div>
    </div>
  );
}
