const ApiTranslations = (configuration) => ({
  pick: 'entityAPIPick',
  createDialog: 'entityAPICreateDialog',
  updateDialog: 'entityAPIUpdateDialog',
  create: 'entityAPICreate',
  update: 'entityAPIUpdate',
  entityName: 'entityAPIName',
  noResultLabel: 'entityAPInoResultLabel',
  searchText: 'entityAPISearchText'
});

export default ApiTranslations;
