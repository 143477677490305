import useNotifications from 'hooks/useNotifications';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'utils/classNames';

export default function DropZone({
  maxFileSize = 150,
  maxFiles = 12,
  disabled,
  children,
  onDropFiles = () => {},
  className: userClassname = '',
  ...rest
}) {
  const dropRef = useRef();
  const notify = useNotifications();
  const [isDragovered, setIsDragovered] = useState();

  const { t } = useTranslation();

  const maxFileSizeInBytes = maxFileSize * 1024 * 1024;

  const handleDragover = (event) => {
    event.preventDefault();
  };

  const handleDragleave = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    const has_files = event.dataTransfer.files.length > 0;
    if (!has_files) return;

    setIsDragovered(false);
    event.preventDefault();
    event.stopPropagation();
    event.dataTransfer.dropEffect = 'copy';

    const has_too_big_file = Array.from(event.dataTransfer.files).some(
      (file) => file.size > maxFileSizeInBytes
    );

    if (has_too_big_file) {
      notify.error(t('fileTooBig', { maxFileSize }));
      return;
    }

    const has_too_many_files = event.dataTransfer.files.length > maxFiles;

    const drop_has_folder = Array.from(event.dataTransfer.items).some(
      (item) => item.kind === 'file' && item.webkitGetAsEntry().isDirectory
    );

    if (drop_has_folder) {
      notify.error(t('folderUploadNotSupported'));
      return;
    }

    if (has_too_many_files) {
      notify.error(t('tooManyFiles', { maxFiles }));
      return;
    }

    onDropFiles(event.dataTransfer.files);
  };

  useEffect(() => {
    if (!disabled) {
      dropRef.current.addEventListener('dragover', handleDragover);
      dropRef.current.addEventListener('dragleave', handleDragleave);
      dropRef.current.addEventListener('drop', handleDrop);

      return () => {
        dropRef.current?.removeEventListener('dragover', handleDragover);
        dropRef.current?.removeEventListener('dragleave', handleDragleave);
        dropRef.current?.removeEventListener('drop', handleDrop);
      };
    }
  }, []);

  if (disabled) return children;

  return (
    <div
      ref={dropRef}
      className={classNames(
        'w-full h-full',
        userClassname,
        isDragovered ? 'bg-sky-500 opacity-50 z-30' : ''
      )}
      {...rest}
    >
      {children}
    </div>
  );
}
