const MuiTableRow = {
  root: {
    height: '56px',
    '&$selected': {
      backgroundColor: '#f1f3f4'
    },
    '&$hover': {
      '&:hover': {
        backgroundColor: '#f1f3f4'
      }
    }
  }
};

export default MuiTableRow;
