import { Box, Dialog, DialogContent, IconButton, Tooltip } from '@material-ui/core';
import { CloudDownload, Print, Close, KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';

import useNotifications from 'hooks/useNotifications';
import Avatar from 'components/Avatar';
import useStyles from './FileViewer.styles';

import { dateToLocalFormat } from 'utils/dates';
import clsx from 'clsx';
import { useEvent } from 'react-events-utils';
import axios from 'axios';
import { apiBaseURL } from 'index';
import { useMemo, createElement } from 'react';

export default function FileViewer({ actions, onClose, openIndex, setOpenIndex, files }) {
  const can_swipe_left = files?.length > 1 && openIndex > 0;
  const can_swipe_right = files?.length > 1 && openIndex < files?.length - 1;

  useEvent(window, 'keydown', (event) => {
    if (event.key === 'Escape') onClose();
    else if (event.key === 'ArrowLeft' && can_swipe_left) setOpenIndex(openIndex - 1);
    else if (event.key === 'ArrowRight' && can_swipe_right) setOpenIndex(openIndex + 1);
  });

  const printImage = (file) => {
    const win = window.open(file, '_blank', 'fullscreen=yes');
    win.document.write(
      `<iframe src="${file}" frameborder="0" style="width: 100vw; height: 100vh;" onLoad="window.print();window.close();"></iframe>`
    );
    win.focus();
  };

  const printPDF = (file) => {
    const win = window.open(file, '_blank', 'fullscreen=yes');
    win.document.write(
      `<embed src="${file}" type="application/pdf" style="width: 100vw; height: 100vh;" onLoad="window.print();"></embed>`
    );
    win.focus();
  };

  const classes = useStyles();
  const notify = useNotifications();
  const file = useMemo(() => files[openIndex] || {}, [files, openIndex]);


  const mimetype = file?.mimetype || (file?.url?.split('.').at(-1) === 'pdf' ? 'application/pdf' : null);
  const is_image = mimetype?.includes('image');
  const is_video = mimetype?.includes('video');
  const is_pdf = mimetype?.includes('pdf');

  if (!mimetype) {
    // Handle the case where mimetype is not defined
    console.error("Mimetype is not defined for the file. The file viewer won't open");
    //Show an error popup
    notify.error();
    onClose();
  }

  const downloadFile = async (file) => {
    const document_id = file?._id;

    return await axios
      .get(`${apiBaseURL}/ged/download/${document_id}`, {
        responseType: 'blob'
      })
      .then((res) => {
        const file_name = res.headers['content-disposition'].match(/filename="(.+)"/)[1];
        const file_blob = res.data;

        const url = window.URL.createObjectURL(new Blob([file_blob]));
        const link = document.createElement('a');

        link.setAttribute('href', url);
        link.setAttribute('download', file_name);

        document.body.appendChild(link);

        link.click();
        link.remove();
      })
      .catch((err) => {
        notify.error();
        throw new Error(err);
      });
  };

  const prevent = (e) => [e?.preventDefault(), e?.stopPropagation()];

  return (
    <Dialog
      classes={{
        paper: classes.fileViewer
      }}
      open={!!files?.length}
      transitionDuration={0}
      onClose={onClose}
      fullScreen={true}
    >
      <DialogContent
        style={{ padding: 0 }}
        classes={{ root: classes.fileViewerContent }}
      >
        <Box className={classes.boxArrowSlideViewer}>
          {can_swipe_left && (
            <IconButton
              className={classes.buttonFileViewer}
              onClick={() => setOpenIndex(openIndex - 1)}
              style={{ padding: '0' }}
            >
              <KeyboardArrowLeft
                className={classes.iconViewer}
                style={{ width: '1.5em', height: '1.5em' }}
              />
            </IconButton>
          )}

          <Box className={classes.iconFileViewer}>
            <IconButton
              className={clsx(classes.buttonFileViewer, classes.buttonBack)}
              onClick={onClose}
            >
              <Close className={classes.iconViewer} />
            </IconButton>
          </Box>
        </Box>

        <Box className={classes.content}>
          <Box
            id="file-viewer-box"
            className="h-screen w-full flex justify-center items-center"
          >
            {is_pdf && (
              <iframe
                // src="https://dv3fy0v0.simplePDF.eu/editor?open"
                src={file.url + '#toolbar=0&navpanes=0&page=1'}
                height="100%"
                width="100%"
                title={file.name}
              />
            )}
            {Boolean(file?.views?.length) &&
              file?.views?.map((view, id) => {
                return (
                  <iframe
                    key={id}
                    // src="https://dv3fy0v0.simplePDF.eu/editor?open"
                    src={view.url + '#toolbar=0&navpanes=0&page=1'}
                    height="100%"
                    width="100%"
                    title={file.name}
                  />
                );
              })}

            {is_image && !Boolean(file?.views?.length) && (
              <img
                onClick={prevent}
                className="max-h-full max-w-full"
                src={file.url}
                alt={file.value}
              />
            )}
            {is_video && (
              <video
                key={file.url}
                controls
                className="max-h-full max-w-full"
              >
                <source
                  src={file.url}
                  type={mimetype}
                />
              </video>
            )}
          </Box>
        </Box>

        {!!actions && <Box className={classes.actionsFileViewer}>{createElement(actions)}</Box>}

        <Box className={classes.boxArrowSlideViewer}>
          {can_swipe_right && (
            <IconButton
              className={classes.buttonFileViewer}
              onClick={() => setOpenIndex(openIndex + 1)}
              style={{ padding: '0' }}
            >
              <KeyboardArrowRight
                className={classes.iconViewer}
                style={{ width: '1.5em', height: '1.5em' }}
              />
            </IconButton>
          )}
          {!!file && (
            <Box
              className={classes.iconFileViewerRight}
              onClick={prevent}
              style={{ margin: '0.7em 0.4em 0 0' }}
            >
              {file._user && (
                <Tooltip
                  placement="left"
                  title={
                    <ul style={{ padding: 0, margin: 0, listStyleType: 'none' }}>
                      <li>{file.value}</li>
                      {file._user && (
                        <li>
                          {file._user.firstName} {file._user.lastName}
                        </li>
                      )}
                      {file.date && <li>Le {dateToLocalFormat(file.date, 'PPPp')}</li>}
                    </ul>
                  }
                >
                  <IconButton className={classes.buttonFileViewer}>
                    <Avatar
                      isUser
                      round
                      entity={{ name: file._user }}
                      white
                    />
                  </IconButton>
                </Tooltip>
              )}
              <IconButton
                onClick={() => downloadFile(file)}
                className={classes.buttonFileViewer}
              >
                <CloudDownload className={classes.iconViewer} />
              </IconButton>
              <IconButton
                onClick={() => (is_pdf ? printPDF(file.url) : printImage(file.url))}
                className={classes.buttonFileViewer}
              >
                <Print className={classes.iconViewer} />
              </IconButton>
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
