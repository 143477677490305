import { Box, Button, IconButton } from '@material-ui/core';

import { useEntity } from 'contexts/entities/entityContext';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useRef, useState } from 'react';
import 'swiper/swiper-bundle.min.css';
import useStyles from './FilterBar.styles';
import {
  NavigateBefore as NavigateBeforeIcon,
  NavigateNext as NavigateNextIcon
} from '@material-ui/icons';
import useKeys from '@flowsn4ke/usekeys';
import { useTranslation } from 'react-i18next';
import classNames from 'utils/classNames';

export default function Bookmarks({ setAppliedBookmarks, appliedBookmarks, visibleBookmarks }) {
  const classes = useStyles();
  const swiper = useRef(null);
  const { bookmarks } = useEntity();

  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const handleSwipe = (s) => [setIsBeginning(s.isBeginning), setIsEnd(s.isEnd)];

  const k = useKeys();
  const { t } = useTranslation();

  const filter_bookmarks = (bookmarks) =>
    bookmarks.filter((bookmark) =>
      visibleBookmarks ? visibleBookmarks.includes(bookmark.key) : true
    );

  if (!filter_bookmarks(bookmarks).length) return null;

  return (
    <Box
      className={classes.root}
      bgcolor={'white'}
      padding={'6px 16px 10px 16px'}
      width={'100%'}
      position={'relative'}
    >
      {!isBeginning && (
        <Box
          className={classNames(classes.navFiltersIconsLeft, classes.navFiltersIconsLeftBookmarks)}
        >
          <IconButton
            className={classes.navFiltersIconButton}
            onClick={() => swiper.current.swiper.slidePrev()}
          >
            <NavigateBeforeIcon />
          </IconButton>
        </Box>
      )}
      {!isEnd && (
        <Box
          className={classNames(
            classes.navFiltersIconsRight,
            classes.navFiltersIconsRightBookmarks
          )}
        >
          <IconButton
            className={classes.navFiltersIconButton}
            onClick={() => swiper.current.swiper.slideNext()}
          >
            <NavigateNextIcon />
          </IconButton>
        </Box>
      )}
      <Swiper
        spaceBetween={0}
        slidesPerView="auto"
        onSwiper={handleSwipe}
        onSlideChange={handleSwipe}
        onInit={handleSwipe}
        ref={swiper}
        centerInsufficientSlides={false}
        centeredSlides={false}
        style={{ width: '100%' }}
      >
        {filter_bookmarks(bookmarks).map(({ key, color, label }, i) => {
          const isActive =
            appliedBookmarks.includes(key) || (key === 'reset' && appliedBookmarks.length === 0);
          return (
            <SwiperSlide
              key={k(i)}
              className={classes.slide}
            >
              <Button
                onClick={() => {
                  if (key === 'reset') {
                    setAppliedBookmarks([]);
                  } else {
                    const next = appliedBookmarks.filter((b) => b !== 'reset');
                    setAppliedBookmarks(isActive ? next.filter((b) => b !== key) : [...next, key]);
                  }
                }}
                size={'small'}
                style={{
                  fontWeight: 400,
                  fontSize: 12,
                  height: 20,
                  padding: '0px 10px',
                  color: isActive ? 'white' : color,
                  backgroundColor: isActive ? color : 'white',
                  border: `1px solid ${color}`
                }}
              >
                {t(label)}
              </Button>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Box>
  );
}
