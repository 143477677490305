import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faBars,
  faTimes,
  faCircle,
  faUnlink,
  faAnglesLeft,
  faAnglesRight,
  faMagnifyingGlass,
  faSlidersUp,
  faDeleteLeft,
  faFloppyDisk,
  faCircleXmark,
  faCalendarClock,
  faDownload,
  faEquals,
  faNotEqual,
  faFileInvoice,
  faCheck,
  faMinus,
  faSquareCheck,
  faSquare,
  faMoneyBillTransfer,
  faWallet,
  faChevronLeft,
  faChevronRight,
  faMeter,
  faChartMixed,
  faBookOpen,
  faXmark,
  faRuler,
  faCashRegister
} from '@fortawesome/pro-regular-svg-icons';

library.add(
  faAnglesRight,
  faMagnifyingGlass,
  faAnglesLeft,
  faSquareCheck,
  faSquare,
  faCircle,
  faBars,
  faTimes,
  faUnlink,
  faSlidersUp,
  faDeleteLeft,
  faFloppyDisk,
  faCircleXmark,
  faCalendarClock,
  faDownload,
  faEquals,
  faNotEqual,
  faFileInvoice,
  faCheck,
  faMinus,
  faMoneyBillTransfer,
  faWallet,
  faChevronLeft,
  faChevronRight,
  faMeter,
  faChartMixed,
  faBookOpen,
  faXmark,
  faRuler,
  faCashRegister
);
