const LocationTranslations = (config, { sublistParent, facturation }) =>
  sublistParent
    ? {
        pick: 'entitySubLocPick',
        createDialog: 'entitySubLocCreateDialog',
        updateDialog: 'entitySubLocUpdateDialog',
        createSuccess: 'entitySubLocCreateSuccess',
        updateSuccess: 'entitySubLocUpdateSuccess',
        create: 'entitySubLocCreate',
        update: 'entitySubLocUpdate',
        entityName: 'entitySubLocName',
        noResultText: config.isBlachere
          ? 'blachEntitySubLocnoResultText'
          : 'entitySubLocnoResultText',
        noResultLabel: 'entitySubLocnoResultLabel',
        searchText: 'entitySubLocSearchText'
      }
    : facturation
      ? {
          pick: 'entityFacLocPick',
          createSuccess: 'entityFacLocCreateSuccess',
          updateSuccess: 'entityFacLocUpdateSuccess',
          createDialog: 'entityFacLocCreateDialog',
          updateDialog: 'entityFacLocUpdateDialog',
          create: 'entityFacLocCreate',
          update: 'entityFacLocUpdate',
          entityName: 'entityFacLocName',
          noResultLabel: 'entityFacLocnoResultLabel',
          searchText: 'entityFacLocSearchText',
          noResultText: config.isBlachere
            ? 'blachEntityFacLocnoResultText'
            : 'entityFacLocnoResultText',
          noSelectText: 'entityFacLocnoSelectText'
        }
      : {
          pick: 'entityLocPick',
          createSuccess: 'entityLocCreateSuccess',
          updateSuccess: 'entityLocUpdateSuccess',
          createDialog: 'entityLocCreateDialog',
          updateDialog: 'entityLocUpdateDialog',
          create: 'entityLocCreate',
          update: 'entityLocUpdate',
          entityName: 'entityLocName',
          noResultLabel: 'entityLocnoResultLabel',
          noResultText: config.isBlachere ? 'blachEntityLocnoResultText' : 'entityLocnoResultText',
          searchText: 'entityLocSearchText',
          noSelectText: 'entityLocnoSelectText'
        };

export default LocationTranslations;
