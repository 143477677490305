import { Accordion, makeStyles, Box, Switch, AccordionSummary, useTheme } from '@material-ui/core';
import FAIcon from 'components/ui/FAIcon';

import useNotifications from 'hooks/useNotifications';
import { useAuth } from 'hooks/useAuth';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import { setCompanyInfo } from 'store/authSlice';

import ContractorPreview from 'entities/Contractor/ContractorPreview';
import Preview from 'layouts/entities/Preview';

import useStyles from 'components/forms/AccordionForm.styles';
import Intervener from 'entities/Intervener/Intervener';
import { useTranslation } from 'react-i18next';

const useStyles2 = makeStyles((theme) => ({
  root: ({ marketplace }) => ({
    color: 'white',
    backgroundColor: theme.palette[marketplace ? 'primary' : 'secondary'].main,
    cursor: 'pointer'
  })
}));

export default function MarketplaceDetails({ section }) {
  const notify = useNotifications();
  const auth = useAuth();
  const theme = useTheme();
  const { dispatch } = useAsyncDispatch();

  const { t } = useTranslation();

  const nameOfCompany = auth.interface._company.name || auth.interface._company.companyName || t('enterprise');

  const putCompanyNameOnTextMarketplace = () => {
    const text = t('putCompanyInMarketplace');
    return `${text.split(' ').slice(0, 1).join(' ')} ${nameOfCompany} ${text.split(' ').slice(1).join(' ')}`;
  };

  const marketplace = auth.interface._company.marketplace;

  const dispatchCallbacks = {
    onSuccess: () => notify.success(),
    onError: () => notify.error()
  };

  const clientType = auth.interface.isClient ? 'client' : 'contractor';

  const toggle = () => dispatch(setCompanyInfo, { [clientType]: { marketplace: !marketplace } }, dispatchCallbacks);

  const classes = useStyles();
  const c2 = useStyles2({ marketplace });

  return (
    <Box className={classes.root}>
      <Accordion
        onClick={toggle}
        className={c2.root}
        expanded={true}
      >
        <AccordionSummary
          aria-controls={`${section.key}bh-content`}
          className={classes.accordionSummary}
          id={`${section.key}bh-header`}
          style={{ cursor: 'default' }}
        >
          <Box
            className={classes.summary}
            display="flex"
          >
            <FAIcon
              collection="fal"
              icon={section.icon}
              className={classes.icon}
              size="medium"
            />
            {!marketplace ? putCompanyNameOnTextMarketplace() : t('myMarketplace')}
            <Box marginLeft={'auto'}>
              <Switch
                checked={marketplace}
                onChange={toggle}
                color="default"
              />
            </Box>
          </Box>
        </AccordionSummary>
      </Accordion>

      {marketplace && (
        <Intervener styleChildren={{ background: 'white', borderRadius: 8, boxShadow: theme.shadows[1] }}>
          <Preview
            // onClick={() => console.log('TODO')}
            previewComponent={() => ContractorPreview}
            element={auth.interface._company}
          />
        </Intervener>
      )}
    </Box>
  );
}
