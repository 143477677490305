import { assign } from '@recursive/assign';
import { createSlice } from '@reduxjs/toolkit';
import generateAsyncThunk from 'utils/generateAsyncThunk';
import { generateEntityBoilerplate } from 'utils/generateEntitySlice';

export const create_meter = generateAsyncThunk({ type: 'POST', endpoint: 'meters/create' });
export const get_meters = generateAsyncThunk({ type: 'POST', endpoint: 'meters' });
export const get_meter_by_id = generateAsyncThunk({ type: 'GET', endpoint: 'meters/${id}' });
export const get_meter_by_location_or_equipment_id = generateAsyncThunk({
  type: 'GET',
  endpoint: 'meters/by_location_or_equipment_id'
});
export const update_meter = generateAsyncThunk({
  type: 'PUT',
  endpoint: 'meters/update-meter/${id}'
});
export const delete_meter = generateAsyncThunk({
  type: 'DELETE',
  endpoint: 'meters/delete-meter/${id}'
});
export const restore_meter = generateAsyncThunk({
  type: 'PUT',
  endpoint: 'meters/restore-meter/${id}'
});

// * Readings actions
export const get_readings = generateAsyncThunk({ type: 'GET', endpoint: 'meters/${id}/readings' });
export const get_last_reading = generateAsyncThunk({
  type: 'GET',
  endpoint: 'meters/${id}/readings/last'
});
export const add_reading = generateAsyncThunk({
  type: 'POST',
  endpoint: 'meters/${id}/readings/add'
});
export const update_reading = generateAsyncThunk({
  type: 'PUT',
  endpoint: 'meters/${id}/readings/${reading_id}/update'
});
export const delete_reading = generateAsyncThunk({
  type: 'DELETE',
  endpoint: 'meters/${id}/readings/${reading_id}/delete'
});

// * Pricing actions
export const get_pricings = generateAsyncThunk({ type: 'GET', endpoint: 'meters/${id}/pricings' });
export const add_pricing = generateAsyncThunk({
  type: 'POST',
  endpoint: 'meters/${id}/pricings/add'
});
export const update_pricing = generateAsyncThunk({
  type: 'PUT',
  endpoint: 'meters/${id}/pricings/${pricing_id}/update'
});
export const delete_pricing = generateAsyncThunk({
  type: 'DELETE',
  endpoint: 'meters/${id}/pricings/${pricing_id}/delete'
});

const entityBoilerplate = generateEntityBoilerplate({
  createElement: create_meter,
  updateElement: update_meter,
  deleteElement: delete_meter,
  restoreElement: restore_meter,
  getElements: get_meters,
  getElement: get_meter_by_id
});

const { initialState, reducers, extraReducers } = entityBoilerplate;

export const metersSlice = createSlice({
  name: 'meters',
  initialState,
  reducers,
  extraReducers: {
    ...extraReducers,
    [get_meter_by_location_or_equipment_id.fulfilled]: (state, action) => {
      // TODO to be implemented
    },
    [get_readings.fulfilled]: (state, action) => {
      const { id } = action.meta;
      const { elements: readings } = action.payload.data;
      state.db[id] = assign(state.db[id], { readings });
    },
    [add_reading.fulfilled]: (state, action) => {
      const { id } = action.meta;
      const { value, date } = action.meta.arg;
      const { reading_id } = action.payload.data;

      const new_reading = {
        _id: reading_id,
        value,
        at: new Date(date).toISOString() // serialize date (avoid error redux)
      };

      // * Array of readings is not known
      if (!state.db[id].readings) state.db[id].readings = [new_reading];

      // * Array of readings is known
      // * Add new_reading to readings array
      // * Sort readings array by date
      state.db[id].readings = [...state.db[id].readings, new_reading].sort((a, b) => new Date(b.at) - new Date(a.at));
    },
    [update_reading.fulfilled]: (state, action) => {
      const { id, reading_id } = action.meta;
      const { value, date } = action.meta.arg;

      const reading_updated = {
        value,
        at: new Date(date).toISOString() // serialize date (avoid error redux)
      };

      state.db[id].readings = state.db[id].readings.map((reading) => {
        if (String(reading._id) === String(reading_id)) return reading_updated;
        return reading;
      });
    },
    [delete_reading.fulfilled]: (state, action) => {
      const { id, reading_id } = action.meta;
      state.db[id].readings = state.db[id].readings.filter((reading) => String(reading._id) !== String(reading_id));
    },
    [get_pricings.fulfilled]: (state, action) => {
      const { id } = action.meta;
      const { elements: pricings } = action.payload.data;

      state.db[id] = assign(state.db[id], { pricings });
    },
    [add_pricing.fulfilled]: (state, action) => {
      const { id } = action.meta;
      const { price, currency, start, end } = action.meta.arg;
      const { pricing_id } = action.payload.data;

      const new_pricing = {
        _id: pricing_id,
        price,
        currency,
        start: new Date(start).toISOString(), // serialize date (avoid error redux)
        end: end === null ? new Date().toISOString() : new Date(end).toISOString() // serialize date (avoid error redux)
      };

      // * Array of pricings is not known
      if (!state.db[id].pricings) state.db[id].pricings = [new_pricing];

      // * Array of pricings is known
      // * Add new_pricing to pricings array
      // * Sort pricings array by start_date
      state.db[id].pricings = [...state.db[id].pricings, new_pricing].sort(
        (a, b) => new Date(b.start) - new Date(a.start)
      );
    },
    [update_pricing.fulfilled]: (state, action) => {
      const { id, pricing_id } = action.meta;
      const { price, currency, country_code, start, end } = action.meta.arg;

      const pricing_updated = {
        price,
        currency,
        country_code,
        start: new Date(start).toISOString(), // serialize date (avoid error redux)
        end: end === null ? new Date().toISOString() : new Date(end).toISOString() // serialize date (avoid error redux)
      };

      state.db[id].pricings = state.db[id].pricings.map((pricing) => {
        if (String(pricing._id) === String(pricing_id)) return pricing_updated;
        return pricing;
      });
    },
    [delete_pricing.fulfilled]: (state, action) => {
      const { id, pricing_id } = action.meta;
      state.db[id].pricings = state.db[id].pricings.filter((pricing) => String(pricing._id) !== String(pricing_id));
    }
  }
});

export const { flushElements, flushElement } = metersSlice.actions;
export default metersSlice.reducer;
