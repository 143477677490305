import { useState, useRef, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import useFileViewer from 'hooks/useFileViewer';
import { useRole } from 'hooks/useRole';
import useStyles from 'layouts/entities/View.styles';
import { useContract } from 'contexts/entities/contractContext';
import { useDocumentListener } from 'react-events-utils';

import { removeQuote, removeInvoice } from 'store/ticketsSlice';
import { isArray, isObject } from 'lodash-es';
import { useConfiguration } from 'hooks/useConfiguration';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'hooks/useAuth';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useEntity } from 'contexts/entities/entityContext';
import AddAccountingDocument from 'modules/accounting/documents/AddAccountingDocument';
import { tActions } from 'modules/tickets/actions';
import { formatCurrency } from 'utils/formatCurrency';
import { QuotesAndInvoices } from 'modules/accounting/QuotesAndInvoices';
import { InvoicesDrawer } from 'modules/accounting/documents/InvoicesDrawer';

const ContractAccounting = ({ type, isParent, contract }) => {
  const con = useContract();
  const reports = con.order?.reports || [];
  const company_id = con.contractContractor?._company?._id;
  const role = useRole();
  const configuration = useConfiguration();
  const [isListOpen, setIsListOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const listItemRef = useRef(null);
  const [isTrashOpen, setIsTrashOpen] = useState(false);

  useDocumentListener('openDevis', (e) => {
    if (type === 'devis') {
      setIsListOpen(true);
    }
  });

  let isFacturesLinked = false;

  const accounting_check_paid_invoices_active = configuration?.comptability?.check_paid_invoices;
  const classes = useStyles();

  const { t } = useTranslation();

  const auth = useAuth();
  const company = auth.interface._company;

  let contract_accounting = contract[type === 'devis' ? 'devis' : 'factures'];
  !isArray(contract_accounting) && (contract_accounting = []);

  let documents = contract_accounting.filter((document) => !document.deleted.state);
  const documentsDeleted = contract_accounting.filter((document) => document.deleted.state);
  if (type === 'facture' && !!contract._facture_ticket) {
    isFacturesLinked = true;
    documents = contract._facture_ticket.factures;
  }

  const invoice_is_being_processed = Boolean(
    contract.factures?.length &&
      contract.facture_state &&
      (contract.facture_state.lock ||
        [contract.facture_state.check_1, contract.facture_state.check_2].some((v) => v !== 2 && v > -1))
  );
  const canUpload = role.permission('tickets', 'send_' + type) && !invoice_is_being_processed && !isFacturesLinked;

  const createDocument = () => setIsModalOpen(true);

  const renderDocument = (document) => {
    return (
      <span>
        {[
          document.reference,
          formatCurrency({
            number: document.price,
            locale: company?.currency?.locale,
            currency: company?.currency?.code
          })
        ]
          .filter((o) => !!o)
          .join(' / ')}{' '}
        {accounting_check_paid_invoices_active && document?.paid ? `- ${t('paid')}` : ''}
      </span>
    );
  };

  const {
    isProposition,
    contract: { state, _summons, devis, summonsIsCollaborator, summonsIsContractor },
    contractParent,
    order: { _jobs, _id, _equipments },
    companyCreator,
    dispatch,
    isLoading
  } = con;

  const { order } = con;

  const display =
    (configuration.isContractor || (configuration.isClient && contract.summonsIsContractor)) &&
    !isProposition &&
    role.permission('tickets', 'display_' + type);
  const parent_has_interface = contractParent && contractParent._company && contractParent._company.userInterface;
  const parent_quotes = contractParent && contractParent.devis;

  const history = useHistory();
  const { closeFiles } = useFileViewer();

  const { afterDialogClose, fromTimeline, setSelectedId } = useEntity();

  const unvalidated_quotes = !isArray(devis)
    ? []
    : (parent_has_interface ? devis : devis.concat(parent_quotes || [])).filter(
        (devis) => !devis.deleted.state && !devis.validated.state && !devis.dropped.state
      );

  const all_quotes_were_rejected =
    devis?.length > 0 && devis.filter((devis) => !devis.dropped.state || devis.deleted.state).length === 0;

  const actions = tActions({
    state,
    dispatch,
    isProposition,
    history,
    _id,
    _equipments,
    _jobs,
    _summons,
    role,
    closeFiles,
    auth,
    summonsIsCollaborator,
    summonsIsContractor,
    unvalidated_quotes,
    parent_has_interface,
    all_quotes_were_rejected,
    companyCreator,
    fromTimeline,
    afterDialogClose,
    setSelectedId,
    reports,
    contractParent
  });

  const totalQuotes = useMemo(
    () =>
      contract?.devis
        ?.filter((devis) => !devis.deleted.state && !devis.dropped.state)
        .reduce((acc, devis) => acc + devis.price, 0),
    [contract?.devis]
  );

  return (
    <Grid
      ref={listItemRef}
      item
      xs={12}
      sm={6}
      className={classes.listItemSecondary}
    >
      {display && (
        <QuotesAndInvoices
          isLoading={isLoading}
          documents={documents}
          isFacturesLinked={isFacturesLinked}
          setIsListOpen={setIsListOpen}
          canUpload={canUpload}
          createDocument={createDocument}
          type={type}
          isParent={isParent}
          accounting_check_paid_invoices_active={accounting_check_paid_invoices_active}
          invoice_is_being_processed={invoice_is_being_processed}
        />
      )}

      {!!isModalOpen && (
        <AddAccountingDocument
          order={order}
          open={!!isModalOpen}
          onClose={() => setIsModalOpen(false)}
          update={isObject(isModalOpen) && isModalOpen}
          type={type}
          isParent={isParent}
          dispatch={dispatch}
        />
      )}

      <InvoicesDrawer
        type={type}
        contract={contract}
        order={order}
        company_id={company_id}
        actions={actions}
        documents={documents}
        totalQuotes={totalQuotes}
        isListOpen={isListOpen}
        setIsListOpen={setIsListOpen}
        setIsModalOpen={setIsModalOpen}
        listItemRef={listItemRef}
        isParent={isParent}
        isLoading={isLoading}
        isTrashOpen={isTrashOpen}
        invoice_is_being_processed={invoice_is_being_processed}
        isFacturesLinked={isFacturesLinked}
        canUpload={canUpload}
        documentsDeleted={documentsDeleted}
        setIsTrashOpen={setIsTrashOpen}
        createDocument={createDocument}
        renderDocument={renderDocument}
        removeInvoice={removeInvoice}
        removeQuote={removeQuote}
        dispatch={dispatch}
      />
    </Grid>
  );
};

export default ContractAccounting;
