export function addListener(component) {
  component._resizeEventListener = {
    handleEvent: () => {
      component.resize();
    }
  };

  window.addEventListener('resize', component._resizeEventListener);
}

export function removeListener(component) {
  window.removeEventListener('resize', component._resizeEventListener);
}
