const bookmarkColors = {
  toplan: 'slate',
  toreplan: 'lime',
  waitingplan: 'blue',
  opened: 'slate',
  waiting: 'yellow',
  validation: 'purple',
  assigned: 'pink',
  visit: 'indigo',
  finished: 'teal',
  validation_waiting: 'orange',
  validation_plan: 'red',
  opened_alert: 'slate',
  technicians: 'blue'
};

export const getBookmarks = (scheduler, calendar, auth) => {
  if (scheduler) {
    return [
      { key: 'toplan', color: bookmarkColors.toplan, label: 'toPlan', value: false },
      { key: 'toreplan', color: bookmarkColors.toreplan, label: 'toReplan', value: false },
      { key: 'waitingPlan', color: bookmarkColors.waitingplan, label: 'waitingToBeReplaned', value: false }
    ];
  } else if (calendar) {
    return [{ key: 'reset', color: bookmarkColors.technicians, label: 'allTechnicians', value: true }];
  } else {
    return [
      auth.interface.isClient ? { key: 'opened', color: bookmarkColors.opened, label: 'toValid', value: false } : {},
      { key: 'waiting', color: bookmarkColors.waiting, label: 'toBeAssignated', value: false },
      { key: 'toreplan', color: bookmarkColors.toreplan, label: 'toBeReplaned', value: false },
      { key: 'validation', color: bookmarkColors.validation, label: 'quoteToBeValidated', value: false },
      auth.interface.isContractor
        ? { label: 'quoteAtt', color: bookmarkColors.validation_waiting, key: 'validation_waiting' }
        : {},
      auth.interface.isContractor
        ? { label: 'quotePl', color: bookmarkColors.validation_plan, key: 'validation_plan' }
        : {},
      { key: 'assigned', color: bookmarkColors.assigned, label: 'waitingForInfo', value: false },
      { key: 'visit', color: bookmarkColors.visit, label: 'inProgress', value: false },
      { key: 'finished', color: bookmarkColors.finished, label: 'toBeClosed', value: false }
    ].filter((bookmark) => Object.keys(bookmark).length > 0);
  }
};

export const sortLabel = [
  'creationDateMostRecent',
  'creationDateLeastRecent',
  'updateDateMostRecent',
  'updateDateLeastRecent',
  'ascendingAlphabetical',
  'descendingAlphabetical'
];

export function getColor(color) {
  switch (color) {
    case 'red':
      return {
        activeIconColor: 'text-red-500',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-gray-500',
        activeTextColor: 'text-red-600',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-red-500'
      };
    case 'green':
      return {
        activeIconColor: 'text-green-500',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-gray-500',
        activeTextColor: 'text-green-600',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-green-500'
      };
    case 'sky':
      return {
        activeIconColor: 'text-sky-500',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-gray-500',
        activeTextColor: 'text-sky-600',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-sky-500'
      };
    case 'blue':
      return {
        activeIconColor: 'text-blue-500',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-gray-500',
        activeTextColor: 'text-blue-600',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-blue-500'
      };
    case 'yellow':
      return {
        activeIconColor: 'text-yellow-500',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-gray-500',
        activeTextColor: 'text-yellow-600',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-yellow-500'
      };
    case 'orange':
      return {
        activeIconColor: 'text-orange-500',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-gray-500',
        activeTextColor: 'text-orange-600',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-orange-500'
      };
    case 'purple':
      return {
        activeIconColor: 'text-purple-500',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-gray-500',
        activeTextColor: 'text-purple-600',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-purple-500'
      };
    case 'pink':
      return {
        activeIconColor: 'text-pink-500',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-gray-500',
        activeTextColor: 'text-pink-600',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-pink-500'
      };
    case 'teal':
      return {
        activeIconColor: 'text-teal-500',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-gray-500',
        activeTextColor: 'text-teal-600',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-teal-500'
      };
    case 'slate':
      return {
        activeIconColor: 'text-slate-500',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-gray-500',
        activeTextColor: 'text-slate-600',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-slate-500'
      };
    case 'indigo':
      return {
        activeIconColor: 'text-indigo-500',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-gray-500',
        activeTextColor: 'text-indigo-600',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-indigo-500'
      };
    case 'lime':
      return {
        activeIconColor: 'text-lime-500',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-gray-500',
        activeTextColor: 'text-lime-600',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-lime-500'
      };
    case 'black':
      return {
        activeIconColor: 'text-black',
        inactiveIconColor: 'text-gray-400',
        inactiveTextColor: 'text-gray-500',
        activeTextColor: 'text-black',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-black'
      };

    default:
      return {
        activeIconColor: 'text-amber-500',
        inactiveIconColor: 'text-gray-300',
        inactiveTextColor: 'text-gray-500',
        activeTextColor: 'text-amber-600',
        inactiveBorderColor: 'border-gray-300',
        activeBorderColor: 'border-amber-500'
      };
  }
}

// exemple:
// before quotes:
// {"key":"quotes","val":{"with":true,"without":true,"amount":{"values":[23,null],"op":"equals"},"reference":"","validated":false,"noValidated":false,"validatedBy":[]},"op":"object"}
// expected result:
// {key:"has_quote", val:true, op:"equals"}
// {key:"quote_amount", val:["12", "24"], op:"range"} \ OR if only one value at [0] {key:"quote_amount", val:"23", op:"equals"}
// {key:"quote_validated_by", val:["user_id"], op:"in"}
// {key:"quote_reference", val:"#234sfsjdk234", op:"equals"}
// {key:"unvalidated_quote", val:true, op:"equals"}

// exemple 2:
// before quotes:
// {"key":"invoices","val":{"with":true,"without":true,"amount":{"values":[12,null],"op":"equals"},"reference":""},"op":"object"}
// expected result:
// {key:"has_invoice", val:"true", op:"equals"}
// {key:"invoice_amount", val:["12", "24"], op:"range"} \ OR if only one value at [0] {key:"invoice_amount", val:"12", op:"equals"}
// {key:"invoice_reference", val:"#234sfsjdk234", op:"equals"}

// exemple 3:
// before reports:
// {"key":"reports","val":{"ir":{"with":true,"without":false},"reserve":{"with":true,"without":false}},"op":"object"}
// expected result:
// {key:"has_report", val:true, op:"equals"}
// {key:"has_reserve", val:true, op:"equals"}

// exemple 4:
// before dates:
// {"key":"dates","val":{"creationAt":{"values":[12,null],"op":"gt"},"flagFinished":{"values":[12,null],"op":"gte"},"interventionDate":{"values":[12,46],"op":"range"},"visitDate":{"values":'12',"op":"lte"}},"op":"object"}
// expected result:
// {key:"creation_at", val:'12', op:"gt"}
// {key:"flag_finished", val:'12', op:"gte"}
// {key:"intervention_date", val:[12, 46], op:"range"}
// {key:"visit_date", val:'12', op:"lte"}

// exemple 5:
// before customFields:
// {"key":"customFields","val":[{"field":"field_id","value":["value1","value2"],"op":"in"}],"op":"object"}
// expected result:
// {key:"field_id", val:["value1", "value2"], op:"in"}

// exemple 5:
// before validatedPeriod:
// {"key":"validatedPeriod","val":{"with":true,"without":true},"op":"object"}
// expected result:
// {key:"validated_period", val:true, op:"equals"}

// function which transform { key: value, ... } to [{ key: "key", value: "value", op:"op"}, ...]
export const transformOldSystemToNew = ({ filters }) => {
  if (Array.isArray(filters)) return filters;

  const result = [];

  Object.entries(filters).forEach(([key, value]) => {
    if (!isValidValue(value)) return; // Exclure les valeurs non valides directement
    if (key === 'quotes' || key === 'invoices') {
      result.push(...transformSpecialType(key, value));
    } else if (key === 'reports') {
      result.push(...transformReports(value));
    } else if (key === 'dates') {
      result.push(...transformDates(value));
    } else if (key === 'validatedPeriod') {
      result.push(...transformValidedPeriod(value));
    } else if (key === 'customFields') {
      result.push(...transformCustomFields(value));
    } else {
      result.push({
        key,
        val:
          Array.isArray(value) && value.every((item) => typeof item === 'object' && item._id)
            ? value.map((item) => item._id)
            : value,
        op: key === 'search' ? 'fuzzy' : Array.isArray(value) ? 'in' : typeof value === 'object' ? 'object' : 'equals'
      });
    }
  });

  return result;
};

// Helper function to check if a value is valid (not falsy or empty array)
const isValidValue = (value) => {
  if (Array.isArray(value)) {
    return value.length > 0; // Exclure les tableaux vides
  }
  return Boolean(value); // Exclure les valeurs falsy (null, undefined, 0, "", false)
};

const transformSpecialType = (key, value) => {
  const prefix = key.slice(0, -1); // "quote" ou "invoice"
  const results = [];

  // Ajouter has_<prefix> seulement si "with" ou "without" est activé, mais pas les deux
  if (value.with && !value.without) {
    results.push({ key: `has_${prefix}`, val: true, op: 'equals' });
  } else if (!value.with && value.without) {
    results.push({ key: `has_${prefix}`, val: false, op: 'equals' });
    return results;
  }

  if (value.amount && Array.isArray(value.amount.values)) {
    const [min, max] = value.amount.values;
    if (min !== null && max !== null) {
      results.push({ key: `${prefix}_amount`, val: [min, max], op: value.amount.op });
    } else if (min !== null && value.amount.op !== 'range') {
      const nonNullValue = min !== null ? min : max;
      results.push({ key: `${prefix}_amount`, val: nonNullValue, op: value.amount.op });
    }
  }

  if (value.validatedBy && Array.isArray(value.validatedBy)) {
    const ids = value.validatedBy.map((item) => item._id || item); // Extraire `_id` si objets
    if (ids.length > 0) {
      results.push({ key: `${prefix}_validated_by`, val: ids, op: 'in' });
    }
  }

  if (value.reference && value.reference.trim()) {
    results.push({ key: `${prefix}_reference`, val: value.reference.trim(), op: 'equals' });
  }

  if (value.validated) {
    results.push({ key: `validated_${prefix}`, val: value.validated, op: 'equals' });
  }

  if (value.noValidated) {
    results.push({ key: `unvalidated_${prefix}`, val: value.noValidated, op: 'equals' });
  }

  return results;
};

const transformReports = (value) => {
  const results = [];

  if (value.ir?.with && !value.ir?.without) {
    results.push({ key: 'has_report', val: true, op: 'equals' });
  } else if (!value.ir?.with && value.ir?.without) {
    results.push({ key: 'has_report', val: false, op: 'equals' });
  }

  if (value.reserve?.with && !value.reserve?.without) {
    results.push({ key: 'has_reserve', val: true, op: 'equals' });
  } else if (!value.reserve?.with && value.reserve?.without) {
    results.push({ key: 'has_reserve', val: false, op: 'equals' });
  }

  return results;
};

const transformValidedPeriod = (value) => {
  const results = [];

  if (value?.with && !value?.without) {
    results.push({ key: 'has_validated_period', val: true, op: 'equals' });
  } else if (!value?.with && value?.without) {
    results.push({ key: 'has_validated_period', val: false, op: 'equals' });
  }

  return results;
};

const transformCustomFields = (values) => {
  // values = [{_field: "field_id", value: ["value1", "value2"], op: "in"}]
  const results = [];
  console.log(values);

  if (values?.length) {
    values.forEach((value) => {
      results.push({ key: value._field, val: value.value, op: value.op || 'equals' });
    });
  }

  return results;
};

const transformDates = (value) => {
  const results = [];

  Object.entries(value).forEach(([dateKey, dateValue]) => {
    if (dateValue?.values) {
      const [min, max] = dateValue.values;

      if (min !== null && max !== null) {
        results.push({ key: convertToSnakeCase(dateKey), val: [min, max], op: 'range' });
      } else if (min !== null && dateValue.op !== 'range') {
        const singleValue = min !== null ? min : max;
        results.push({ key: convertToSnakeCase(dateKey), val: singleValue, op: dateValue.op });
      }
    }
  });

  return results;
};

const convertToSnakeCase = (str) => str.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();

// Function to transform [{ key: "key", value: "value", op: "op" }, ...] back to { key: value, ... }
export const transformNewSystemToOld = ({ filters }) => {
  if (!Array.isArray(filters)) return filters;

  const result = {};

  filters.forEach(({ key, val, op }) => {
    // Si la valeur est un tableau et l'opérateur est 'in', transformer en objets avec _id
    if (Array.isArray(val) && op === 'in') {
      result[key] = val.map((id) => ({ _id: id }));
    } else {
      // Sinon, assigner la valeur directement
      result[key] = val;
    }
  });

  return result;
};
