import { makeStyles } from '@material-ui/core';

const settingsWidth = (theme) => ({
  [theme.breakpoints.up(1542)]: {
    margin: 'auto'
  },
  [theme.breakpoints.down(1542)]: {
    margin: 'auto'
  },
  [theme.breakpoints.down(1100)]: {
    margin: 'auto'
  }
});

export default makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.secondary.main
  },
  appBarSearch: {
    backgroundColor: 'white',
    padding: '8px 24px'
  },
  appBarSearchFixed: {
    position: 'fixed',
    width: '100%',
    top: 64,
    [theme.breakpoints.down(600)]: {
      top: 55
    },
    zIndex: 2
  },
  appBarClose: {
    zIndex: 1,
    marginLeft: 0
  },
  toolbarMargin: {
    ...theme.mixins.toolbar
  },
  title: {
    marginRight: 'auto',
    fontSize: 16
  },
  langs: {
    marginLeft: 16,
    marginRight: 'auto'
  },
  langsFixed: {
    position: 'absolute',
    left: -54
  },
  searchBarContainer: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    left: 0,
    top: 0
  },
  searchBar: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    ...settingsWidth(theme)
  },
  searchBarInput: {
    flexGrow: 1
  },
  searchBarPaper: {
    width: '100%',
    display: 'flex'
  },
  settingsContainer: {
    paddingTop: 16,
    [theme.breakpoints.down(1100)]: {
      paddingTop: 0
    },
    overflowX: 'hidden',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexGrow: 1
  },
  searchBarIconMobile: {
    color: 'white'
  },
  content: {
    display: 'flex',
    ...settingsWidth(theme),
    [theme.breakpoints.down(1100)]: {
      flexDirection: 'column'
    },
    height: '100%'
  },
  noResult: {
    marginTop: 16,
    fontWeight: 'bold',
    fontSize: 16,
    textAlign: 'center'
  },
  drawerButton: {
    marginRight: 16
  },
  drawerButtonIcon: {
    color: '#ffffff',
    width: '1em',
    height: '1em',
    fontSize: '1em'
  },
  paletteContainer: {
    ['& a']: {
      textDecoration: 'none',
      color: theme.palette.primary.main
    },
    width: '100%',
    marginTop: 16,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 6,
    paddingBottom: 6,
    [theme.breakpoints.down(1100)]: {
      padding: 10,
      paddingLeft: 18
    }
  }
  // paletteButton: {
  //   height: 20,
  //   color: 'white',
  //   width: 20,
  //   fontSize: 10,
  //   boxShadow: theme.shadows[1],
  //   borderRadius: 34,
  //   margin: "8px 3px",
  //   ['& svg']: {
  //     fontSize: 14,
  //     height: 14,
  //     width: 14,
  //   }
  // },
}));
