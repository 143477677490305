const MuiChip = {
  sizeSmall: {
    height: 21,
    fontSize: 10
  },
  avatarSmall: {
    alignItems: 'center',
    display: 'flex'
  }
};

export default MuiChip;
