import { Box, Badge, Chip, Tooltip } from '@material-ui/core';
import { ContractorItemPreview } from 'entities/Contractor/ContractorPreview';
import { TechnicianItemPreview } from 'entities/Technician/TechnicianPreview';

import periodicities from 'constants/periodicities';

import useStyles from 'layouts/entities/Preview.styles';
import FAIcon from 'components/ui/FAIcon';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { dateToLocalFormat } from 'utils/dates';

export default function MaintenancePreview({ element, isActive }) {
  const c = useStyles();
  const { t } = useTranslation();

  return (
    <Box
      style={{ width: '100%' }}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box width={'100%'}>
        <Box className={c.l}>
          {element.name && (
            <span
              className={c.title}
              style={{ whiteSpace: 'pre-wrap' }}
            >
              {element.name}
            </span>
          )}
        </Box>
        <Box className={c.l}>
          <Box
            display={'inline-flex'}
            alignItems={'center'}
          >
            {element?.compute && (
              <Chip
                color="secondary"
                size="small"
                variant={isActive ? 'default' : 'outlined'}
                style={{
                  marginTop: 6,
                  borderColor: element.compute === 'regulatory' ? '#c0392b' : '#2980b9',
                  backgroundColor: !isActive
                    ? 'inherit'
                    : element.compute === 'regulatory'
                      ? '#c0392b'
                      : '#2980b9',
                  color: isActive
                    ? 'white'
                    : element.compute === 'regulatory'
                      ? '#c0392b'
                      : '#2980b9'
                }}
                label={element.compute === 'regulatory' ? 'Réglementaire' : 'Calendaire'}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'flex-end'}
      >
        <Box
          display={'flex'}
          alignItems={'center'}
        >
          {!!element.countAlert && (
            <Box style={{ marginLeft: 6, color: '#d35400' }}>
              <Tooltip
                placement={'left'}
                title={t('toMonitor')}
              >
                <Badge badgeContent={element.countAlert}>
                  <FAIcon
                    style={{ fontSize: 13 }}
                    collection={'fas'}
                    icon={'exclamation-triangle'}
                  />
                </Badge>
              </Tooltip>
            </Box>
          )}
          {!!element.countLate && (
            <Box style={{ marginLeft: 6, color: '#c0392b' }}>
              <Tooltip
                placement={'left'}
                title={t('late')}
              >
                <Badge badgeContent={element.countLate}>
                  <FAIcon
                    collection={'fas'}
                    icon={'siren-on'}
                  />
                </Badge>
              </Tooltip>
            </Box>
          )}
        </Box>

        {<span className={c.reference}>{t(periodicities[element.defaults.config.type])}</span>}
      </Box>
    </Box>
  );
}

export function MaintenanceItemPreview({ maintenance }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const dbPeriods = useSelector((state) => state['periods']).db;

  const endDatePeriodicity = dbPeriods[maintenance._id]?.stop_time;

  const formattedDate =
    typeof endDatePeriodicity !== 'undefined'
      ? dateToLocalFormat(endDatePeriodicity, 'dd/M/yy')
      : null;

  return (
    <Box className={classes.l}>
      {endDatePeriodicity ? (
        <span className="text-red-600 font-[500]">
          {t('endLabel {{formattedDate}}', { formattedDate })}
        </span>
      ) : (
        <Box fontWeight={500}>{t(periodicities[maintenance.config.type])}</Box>
      )}
      {maintenance?._intervener?.kind === 'Collaborator' && (
        <TechnicianItemPreview technician={maintenance._intervener} />
      )}
      {maintenance?._intervener?.kind === 'Contractor' && (
        <ContractorItemPreview contractor={maintenance._intervener} />
      )}
    </Box>
  );
}
