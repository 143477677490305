import { FormHelperText, makeStyles, Typography } from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import { useField } from 'frmx';
import { nanoid } from 'nanoid';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  errorColor: {
    color: theme.palette.error.main
  }
}));

export default function CustomNotation({ valueControlled, onChange, path, customField }) {
  const { t } = useTranslation();
  const { value, setValue, error, disabled } = useField(path);
  const c = useStyles();
  const id = useRef(nanoid());
  return (
    <>
      <Typography component="legend">{t(customField.label)}</Typography>
      <Rating
        disabled={disabled}
        name={id.current}
        value={valueControlled || value || 0}
        onChange={(e, v) => (onChange ? onChange(v) : setValue(v))}
      />
      {error && <FormHelperText className={c.errorColor}>{t('pleaseLeaveANote')}</FormHelperText>}
    </>
  );
}
