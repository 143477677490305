export const dptmts = [
  {
    zone_id: '634572ead9a00d8e3d032da1',
    departmentCode: '00',
    departmentName: 'International',
    regionCode: '00',
    regionName: 'International'
  },
  {
    zone_id: '634580400765c66927c3b159',
    departmentCode: '00',
    departmentName: 'National',
    regionCode: '00',
    regionName: 'National',
    children_ids: [
      '634580528224e30b2b7fadba',
      '634580654b0de44a370a6583',
      '6345806c519a7117e26d7fe4',
      '634580722faa407ffaebc968',
      '63458078d3bb94db4c0d2e27',
      '6345807f6d1049e32e2e5354',
      '6345808997bc1f71ace9b7ba',
      '6345808f8851c9e5ecdc3816',
      '634580950acf94535caed33b',
      '6345809bcd140b65ef0b32d9',
      '634580a23f017708f6705cdd',
      '634580a9a66d6a5688f4993e',
      '634580aef8bf06f8a2823f2d',
      '634580ca2b179bb7af7cb613',
      '634580d44e37f5e1918ae561',
      '634580dc47b5023d5145f80a',
      '634580e21f0b228dca5ec7d4',
      '634580ebac45c81126b9a955',
      '634580f2cf6df3e7d9ea3f82',
      '634580f651b97905b48a5652',
      '634580fc3cc5bff1c00259ee',
      '63458102c8175ad6c1d52e3c',
      '63458107b7401f4c942ce8d5',
      '63458111e0eedd978945d9b6',
      '63458119872ac1e36201bcc8',
      '6345811eef88bb103eaaf2e7',
      '634581251d4a6b9bd63b8132',
      '6345812e0ad61cd47c587430',
      '63458134e0642b63c8371939',
      '6345813910dd29ec0ae843a4',
      '63458141e12487bacb6f4d52',
      '634581473e1114cac957b8cf',
      '6345814ca23fc3dbc11c222d',
      '6345815231dbce7026d98e68',
      '6345815823252c5818e374fa',
      '6345815e07334c45631a888c',
      '634581647e1d33e29c5081dd',
      '6345838bddd625ae99516b84',
      '63458176f97c0fe94e688f68',
      '6345817b014af7c9b5560591',
      '6345818139834ddc697512d7',
      '63458187a95de3009329694f',
      '6345818d1b5ab9c3478666b9',
      '63458192ccd54328a86a2d1b',
      '634581972ffea04c8b8c3282',
      '6345819c02317c41757b108d',
      '634581a2d8e16c7111d6cb8c',
      '634581aa4e30b8e1c966879a',
      '634581af0c52f578fe52a44d',
      '634581b5f7e8d355eff28035',
      '634581ba67aac63946b00350',
      '634581c04770d9928c603833',
      '634581c5a6251edcfdd422ff',
      '634581d0cafc62a20cb94c2f',
      '634581dc8334cdf5110631a6',
      '634581e2b75ef8dd9aab04ff',
      '634581eadaeafca13a51034f',
      '634581f1aa42de220a606b5c',
      '634581fb10dd2cba028e84c8',
      '63458205612c8f19ed991536',
      '6345820b6eb869e0f330f64f',
      '63458210673f491871919dc4',
      '634582157f155e36ebbf07de',
      '6345821d56bdb0ca0dfd2755',
      '634582262e50948765b541b1',
      '6345822ca4b26bfdcff3c2fa',
      '63458234aeb282e160f4eeec',
      '63458239a2ee3795d7a7ce71',
      '6345823e1d3b8d289284948a',
      '634582441f955e230a84a945',
      '6345824903bfe30adafc8501',
      '634582543949ba7f2e4bdd7a',
      '6345825d15e80f6057802765',
      '6345826271dfe128bc426ed1',
      '634582678e61447f15073d64',
      '6345826d89f36bf4389bfb52',
      '634582792f46bb3b8131f1aa',
      '6345828002532a13a9fe0316',
      '63458285572130f14282bdf6',
      '6345828bc9373bad87df26d9',
      '63458291f00dbdaece69fa4c',
      '6345829a09afd7ba63b21243',
      '6345829f543e95adc55e01cf',
      '634582a69197873dee60c64c',
      '634582acff140381b5a7279c',
      '634582b3ccec465117669875',
      '634582b924623f0398ec8ca7',
      '634582c0e68a78744693e969',
      '634582c68984e6eb02bb56a4',
      '634582d1534e7d912aa7dfaa',
      '634582d723cf002b4942ef53',
      '634582dd13b436e5b21da325',
      '634582e376447b4c9180dcf7',
      '634582ea65be0c660f6f086f',
      '634582efee04d4e60d193763',
      '634582f83245036b0807c8b9',
      '634582fd56388ad2d4114e56',
      '6345830216debede3626f315',
      '63458307e854ced8616c550a',
      '6345830c9c7f5c4e72a316c4',
      '634583112d75293bae0c7235',
      '63458f4a844263a9efe3f627',
      '63458fefa4dfb8a43d00c52f'
    ]
  },
  {
    zone_id: '634580528224e30b2b7fadba',
    departmentCode: '01',
    departmentName: 'Ain',
    regionCode: '84',
    regionName: 'Auvergne-Rhône-Alpes'
  },
  {
    zone_id: '634580654b0de44a370a6583',
    departmentCode: '02',
    departmentName: 'Aisne',
    regionCode: '32',
    regionName: 'Nord-Pas-de-Calais-Picardie'
  },
  {
    zone_id: '6345806c519a7117e26d7fe4',
    departmentCode: '03',
    departmentName: 'Allier',
    regionCode: '84',
    regionName: 'Auvergne-Rhône-Alpes'
  },
  {
    zone_id: '634580722faa407ffaebc968',
    departmentCode: '04',
    departmentName: 'Alpes-de-Haute-Provence',
    regionCode: '93',
    regionName: "Provence-Alpes-Côte d'Azur"
  },
  {
    zone_id: '63458078d3bb94db4c0d2e27',
    departmentCode: '05',
    departmentName: 'Hautes-Alpes',
    regionCode: '93',
    regionName: "Provence-Alpes-Côte d'Azur"
  },
  {
    zone_id: '6345807f6d1049e32e2e5354',
    departmentCode: '06',
    departmentName: 'Alpes-Maritimes',
    regionCode: '93',
    regionName: "Provence-Alpes-Côte d'Azur"
  },
  {
    zone_id: '6345808997bc1f71ace9b7ba',
    departmentCode: '07',
    departmentName: 'Ardèche',
    regionCode: '84',
    regionName: 'Auvergne-Rhône-Alpes'
  },
  {
    zone_id: '6345808f8851c9e5ecdc3816',
    departmentCode: '08',
    departmentName: 'Ardennes',
    regionCode: '44',
    regionName: 'Alsace-Champagne-Ardenne-Lorraine'
  },
  {
    zone_id: '634580950acf94535caed33b',
    departmentCode: '09',
    departmentName: 'Ariège',
    regionCode: '76',
    regionName: 'Languedoc-Roussillon-Midi-Pyrénées'
  },
  {
    zone_id: '6345809bcd140b65ef0b32d9',
    departmentCode: '10',
    departmentName: 'Aube',
    regionCode: '44',
    regionName: 'Alsace-Champagne-Ardenne-Lorraine'
  },
  {
    zone_id: '634580a23f017708f6705cdd',
    departmentCode: '11',
    departmentName: 'Aude',
    regionCode: '76',
    regionName: 'Languedoc-Roussillon-Midi-Pyrénées'
  },
  {
    zone_id: '634580a9a66d6a5688f4993e',
    departmentCode: '12',
    departmentName: 'Aveyron',
    regionCode: '76',
    regionName: 'Languedoc-Roussillon-Midi-Pyrénées'
  },
  {
    zone_id: '634580aef8bf06f8a2823f2d',
    departmentCode: '13',
    departmentName: 'Bouches-du-Rhône',
    regionCode: '93',
    regionName: "Provence-Alpes-Côte d'Azur"
  },
  {
    zone_id: '634580ca2b179bb7af7cb613',
    departmentCode: '14',
    departmentName: 'Calvados',
    regionCode: '28',
    regionName: 'Normandie'
  },
  {
    zone_id: '634580d44e37f5e1918ae561',
    departmentCode: '15',
    departmentName: 'Cantal',
    regionCode: '84',
    regionName: 'Auvergne-Rhône-Alpes'
  },
  {
    zone_id: '634580dc47b5023d5145f80a',
    departmentCode: '16',
    departmentName: 'Charente',
    regionCode: '75',
    regionName: 'Aquitaine-Limousin-Poitou-Charentes'
  },
  {
    zone_id: '634580e21f0b228dca5ec7d4',
    departmentCode: '17',
    departmentName: 'Charente-Maritime',
    regionCode: '75',
    regionName: 'Aquitaine-Limousin-Poitou-Charentes'
  },
  {
    zone_id: '634580ebac45c81126b9a955',
    departmentCode: '18',
    departmentName: 'Cher',
    regionCode: '24',
    regionName: 'Centre-Val de Loire'
  },
  {
    zone_id: '634580f2cf6df3e7d9ea3f82',
    departmentCode: '19',
    departmentName: 'Corrèze',
    regionCode: '75',
    regionName: 'Aquitaine-Limousin-Poitou-Charentes'
  },
  {
    zone_id: '634580f651b97905b48a5652',
    departmentCode: '21',
    departmentName: "Côte-d'or",
    regionCode: '27',
    regionName: 'Bourgogne-Franche-Comté'
  },
  {
    zone_id: '634580fc3cc5bff1c00259ee',
    departmentCode: '22',
    departmentName: "Côtes-d'armor",
    regionCode: '53',
    regionName: 'Bretagne'
  },
  {
    zone_id: '63458102c8175ad6c1d52e3c',
    departmentCode: '23',
    departmentName: 'Creuse',
    regionCode: '75',
    regionName: 'Aquitaine-Limousin-Poitou-Charentes'
  },
  {
    zone_id: '63458107b7401f4c942ce8d5',
    departmentCode: '24',
    departmentName: 'Dordogne',
    regionCode: '75',
    regionName: 'Aquitaine-Limousin-Poitou-Charentes'
  },
  {
    zone_id: '63458111e0eedd978945d9b6',
    departmentCode: '25',
    departmentName: 'Doubs',
    regionCode: '27',
    regionName: 'Bourgogne-Franche-Comté'
  },
  {
    zone_id: '63458119872ac1e36201bcc8',
    departmentCode: '26',
    departmentName: 'Drôme',
    regionCode: '84',
    regionName: 'Auvergne-Rhône-Alpes'
  },
  {
    zone_id: '6345811eef88bb103eaaf2e7',
    departmentCode: '27',
    departmentName: 'Eure',
    regionCode: '28',
    regionName: 'Normandie'
  },
  {
    zone_id: '634581251d4a6b9bd63b8132',
    departmentCode: '28',
    departmentName: 'Eure-et-Loir',
    regionCode: '24',
    regionName: 'Centre-Val de Loire'
  },
  {
    zone_id: '6345812e0ad61cd47c587430',
    departmentCode: '29',
    departmentName: 'Finistère',
    regionCode: '53',
    regionName: 'Bretagne'
  },
  {
    zone_id: '63458134e0642b63c8371939',
    departmentCode: '2a',
    departmentName: 'Corse-du-Sud',
    regionCode: '94',
    regionName: 'Corse'
  },
  {
    zone_id: '6345813910dd29ec0ae843a4',
    departmentCode: '2b',
    departmentName: 'Haute-Corse',
    regionCode: '94',
    regionName: 'Corse'
  },
  {
    zone_id: '63458141e12487bacb6f4d52',
    departmentCode: '30',
    departmentName: 'Gard',
    regionCode: '76',
    regionName: 'Languedoc-Roussillon-Midi-Pyrénées'
  },
  {
    zone_id: '634581473e1114cac957b8cf',
    departmentCode: '31',
    departmentName: 'Haute-Garonne',
    regionCode: '76',
    regionName: 'Languedoc-Roussillon-Midi-Pyrénées'
  },
  {
    zone_id: '6345814ca23fc3dbc11c222d',
    departmentCode: '32',
    departmentName: 'Gers',
    regionCode: '76',
    regionName: 'Languedoc-Roussillon-Midi-Pyrénées'
  },
  {
    zone_id: '6345815231dbce7026d98e68',
    departmentCode: '33',
    departmentName: 'Gironde',
    regionCode: '75',
    regionName: 'Aquitaine-Limousin-Poitou-Charentes'
  },
  {
    zone_id: '6345815823252c5818e374fa',
    departmentCode: '34',
    departmentName: 'Hérault',
    regionCode: '76',
    regionName: 'Languedoc-Roussillon-Midi-Pyrénées'
  },
  {
    zone_id: '6345815e07334c45631a888c',
    departmentCode: '35',
    departmentName: 'Ille-et-Vilaine',
    regionCode: '53',
    regionName: 'Bretagne'
  },
  {
    zone_id: '634581647e1d33e29c5081dd',
    departmentCode: '36',
    departmentName: 'Indre',
    regionCode: '24',
    regionName: 'Centre-Val de Loire'
  },
  {
    zone_id: '6345838bddd625ae99516b84',
    departmentCode: '37',
    departmentName: 'Indre-et-Loire',
    regionCode: '24',
    regionName: 'Centre-Val de Loire'
  },
  {
    zone_id: '63458176f97c0fe94e688f68',
    departmentCode: '38',
    departmentName: 'Isère',
    regionCode: '84',
    regionName: 'Auvergne-Rhône-Alpes'
  },
  {
    zone_id: '6345817b014af7c9b5560591',
    departmentCode: '39',
    departmentName: 'Jura',
    regionCode: '27',
    regionName: 'Bourgogne-Franche-Comté'
  },
  {
    zone_id: '6345818139834ddc697512d7',
    departmentCode: '40',
    departmentName: 'Landes',
    regionCode: '75',
    regionName: 'Aquitaine-Limousin-Poitou-Charentes'
  },
  {
    zone_id: '63458187a95de3009329694f',
    departmentCode: '41',
    departmentName: 'Loir-et-Cher',
    regionCode: '24',
    regionName: 'Centre-Val de Loire'
  },
  {
    zone_id: '6345818d1b5ab9c3478666b9',
    departmentCode: '42',
    departmentName: 'Loire',
    regionCode: '84',
    regionName: 'Auvergne-Rhône-Alpes'
  },
  {
    zone_id: '63458192ccd54328a86a2d1b',
    departmentCode: '43',
    departmentName: 'Haute-Loire',
    regionCode: '84',
    regionName: 'Auvergne-Rhône-Alpes'
  },
  {
    zone_id: '634581972ffea04c8b8c3282',
    departmentCode: '44',
    departmentName: 'Loire-Atlantique',
    regionCode: '52',
    regionName: 'Pays de la Loire'
  },
  {
    zone_id: '6345819c02317c41757b108d',
    departmentCode: '45',
    departmentName: 'Loiret',
    regionCode: '24',
    regionName: 'Centre-Val de Loire'
  },
  {
    zone_id: '634581a2d8e16c7111d6cb8c',
    departmentCode: '46',
    departmentName: 'Lot',
    regionCode: '76',
    regionName: 'Languedoc-Roussillon-Midi-Pyrénées'
  },
  {
    zone_id: '634581aa4e30b8e1c966879a',
    departmentCode: '47',
    departmentName: 'Lot-et-Garonne',
    regionCode: '75',
    regionName: 'Aquitaine-Limousin-Poitou-Charentes'
  },
  {
    zone_id: '634581af0c52f578fe52a44d',
    departmentCode: '48',
    departmentName: 'Lozère',
    regionCode: '76',
    regionName: 'Languedoc-Roussillon-Midi-Pyrénées'
  },
  {
    zone_id: '634581b5f7e8d355eff28035',
    departmentCode: '49',
    departmentName: 'Maine-et-Loire',
    regionCode: '52',
    regionName: 'Pays de la Loire'
  },
  {
    zone_id: '634581ba67aac63946b00350',
    departmentCode: '50',
    departmentName: 'Manche',
    regionCode: '28',
    regionName: 'Normandie'
  },
  {
    zone_id: '634581c04770d9928c603833',
    departmentCode: '51',
    departmentName: 'Marne',
    regionCode: '44',
    regionName: 'Alsace-Champagne-Ardenne-Lorraine'
  },
  {
    zone_id: '634581c5a6251edcfdd422ff',
    departmentCode: '52',
    departmentName: 'Haute-Marne',
    regionCode: '44',
    regionName: 'Alsace-Champagne-Ardenne-Lorraine'
  },
  {
    zone_id: '634581d0cafc62a20cb94c2f',
    departmentCode: '53',
    departmentName: 'Mayenne',
    regionCode: '52',
    regionName: 'Pays de la Loire'
  },
  {
    zone_id: '634581dc8334cdf5110631a6',
    departmentCode: '54',
    departmentName: 'Meurthe-et-Moselle',
    regionCode: '44',
    regionName: 'Alsace-Champagne-Ardenne-Lorraine'
  },
  {
    zone_id: '634581e2b75ef8dd9aab04ff',
    departmentCode: '55',
    departmentName: 'Meuse',
    regionCode: '44',
    regionName: 'Alsace-Champagne-Ardenne-Lorraine'
  },
  {
    zone_id: '634581eadaeafca13a51034f',
    departmentCode: '56',
    departmentName: 'Morbihan',
    regionCode: '53',
    regionName: 'Bretagne'
  },
  {
    zone_id: '634581f1aa42de220a606b5c',
    departmentCode: '57',
    departmentName: 'Moselle',
    regionCode: '44',
    regionName: 'Alsace-Champagne-Ardenne-Lorraine'
  },
  {
    zone_id: '634581fb10dd2cba028e84c8',
    departmentCode: '58',
    departmentName: 'Nièvre',
    regionCode: '27',
    regionName: 'Bourgogne-Franche-Comté'
  },
  {
    zone_id: '63458205612c8f19ed991536',
    departmentCode: '59',
    departmentName: 'Nord',
    regionCode: '32',
    regionName: 'Nord-Pas-de-Calais-Picardie'
  },
  {
    zone_id: '6345820b6eb869e0f330f64f',
    departmentCode: '60',
    departmentName: 'Oise',
    regionCode: '32',
    regionName: 'Nord-Pas-de-Calais-Picardie'
  },
  {
    zone_id: '63458210673f491871919dc4',
    departmentCode: '61',
    departmentName: 'Orne',
    regionCode: '28',
    regionName: 'Normandie'
  },
  {
    zone_id: '634582157f155e36ebbf07de',
    departmentCode: '62',
    departmentName: 'Pas-de-Calais',
    regionCode: '32',
    regionName: 'Nord-Pas-de-Calais-Picardie'
  },
  {
    zone_id: '6345821d56bdb0ca0dfd2755',
    departmentCode: '63',
    departmentName: 'Puy-de-Dôme',
    regionCode: '84',
    regionName: 'Auvergne-Rhône-Alpes'
  },
  {
    zone_id: '634582262e50948765b541b1',
    departmentCode: '64',
    departmentName: 'Pyrénées-Atlantiques',
    regionCode: '75',
    regionName: 'Aquitaine-Limousin-Poitou-Charentes'
  },
  {
    zone_id: '6345822ca4b26bfdcff3c2fa',
    departmentCode: '65',
    departmentName: 'Hautes-Pyrénées',
    regionCode: '76',
    regionName: 'Languedoc-Roussillon-Midi-Pyrénées'
  },
  {
    zone_id: '63458234aeb282e160f4eeec',
    departmentCode: '66',
    departmentName: 'Pyrénées-Orientales',
    regionCode: '76',
    regionName: 'Languedoc-Roussillon-Midi-Pyrénées'
  },
  {
    zone_id: '63458239a2ee3795d7a7ce71',
    departmentCode: '67',
    departmentName: 'Bas-Rhin',
    regionCode: '44',
    regionName: 'Alsace-Champagne-Ardenne-Lorraine'
  },
  {
    zone_id: '6345823e1d3b8d289284948a',
    departmentCode: '68',
    departmentName: 'Haut-Rhin',
    regionCode: '44',
    regionName: 'Alsace-Champagne-Ardenne-Lorraine'
  },
  {
    zone_id: '634582441f955e230a84a945',
    departmentCode: '69',
    departmentName: 'Rhône',
    regionCode: '84',
    regionName: 'Auvergne-Rhône-Alpes'
  },
  {
    zone_id: '6345824903bfe30adafc8501',
    departmentCode: '70',
    departmentName: 'Haute-Saône',
    regionCode: '27',
    regionName: 'Bourgogne-Franche-Comté'
  },
  {
    zone_id: '634582543949ba7f2e4bdd7a',
    departmentCode: '71',
    departmentName: 'Saône-et-Loire',
    regionCode: '27',
    regionName: 'Bourgogne-Franche-Comté'
  },
  {
    zone_id: '6345825d15e80f6057802765',
    departmentCode: '72',
    departmentName: 'Sarthe',
    regionCode: '52',
    regionName: 'Pays de la Loire'
  },
  {
    zone_id: '6345826271dfe128bc426ed1',
    departmentCode: '73',
    departmentName: 'Savoie',
    regionCode: '84',
    regionName: 'Auvergne-Rhône-Alpes'
  },
  {
    zone_id: '634582678e61447f15073d64',
    departmentCode: '74',
    departmentName: 'Haute-Savoie',
    regionCode: '84',
    regionName: 'Auvergne-Rhône-Alpes'
  },
  {
    zone_id: '6345826d89f36bf4389bfb52',
    departmentCode: '75',
    departmentName: 'Paris',
    regionCode: '11',
    regionName: 'Ile-de-France'
  },
  {
    zone_id: '634582792f46bb3b8131f1aa',
    departmentCode: '76',
    departmentName: 'Seine-Maritime',
    regionCode: '28',
    regionName: 'Normandie'
  },
  {
    zone_id: '6345828002532a13a9fe0316',
    departmentCode: '77',
    departmentName: 'Seine-et-Marne',
    regionCode: '11',
    regionName: 'Ile-de-France'
  },
  {
    zone_id: '63458285572130f14282bdf6',
    departmentCode: '78',
    departmentName: 'Yvelines',
    regionCode: '11',
    regionName: 'Ile-de-France'
  },
  {
    zone_id: '6345828bc9373bad87df26d9',
    departmentCode: '79',
    departmentName: 'Deux-Sèvres',
    regionCode: '75',
    regionName: 'Aquitaine-Limousin-Poitou-Charentes'
  },
  {
    zone_id: '63458291f00dbdaece69fa4c',
    departmentCode: '80',
    departmentName: 'Somme',
    regionCode: '32',
    regionName: 'Nord-Pas-de-Calais-Picardie'
  },
  {
    zone_id: '6345829a09afd7ba63b21243',
    departmentCode: '81',
    departmentName: 'Tarn',
    regionCode: '76',
    regionName: 'Languedoc-Roussillon-Midi-Pyrénées'
  },
  {
    zone_id: '6345829f543e95adc55e01cf',
    departmentCode: '82',
    departmentName: 'Tarn-et-Garonne',
    regionCode: '76',
    regionName: 'Languedoc-Roussillon-Midi-Pyrénées'
  },
  {
    zone_id: '634582a69197873dee60c64c',
    departmentCode: '83',
    departmentName: 'Var',
    regionCode: '93',
    regionName: "Provence-Alpes-Côte d'Azur"
  },
  {
    zone_id: '634582acff140381b5a7279c',
    departmentCode: '84',
    departmentName: 'Vaucluse',
    regionCode: '93',
    regionName: "Provence-Alpes-Côte d'Azur"
  },
  {
    zone_id: '634582b3ccec465117669875',
    departmentCode: '85',
    departmentName: 'Vendée',
    regionCode: '52',
    regionName: 'Pays de la Loire'
  },
  {
    zone_id: '634582b924623f0398ec8ca7',
    departmentCode: '86',
    departmentName: 'Vienne',
    regionCode: '75',
    regionName: 'Aquitaine-Limousin-Poitou-Charentes'
  },
  {
    zone_id: '634582c0e68a78744693e969',
    departmentCode: '87',
    departmentName: 'Haute-Vienne',
    regionCode: '75',
    regionName: 'Aquitaine-Limousin-Poitou-Charentes'
  },
  {
    zone_id: '634582c68984e6eb02bb56a4',
    departmentCode: '88',
    departmentName: 'Vosges',
    regionCode: '44',
    regionName: 'Alsace-Champagne-Ardenne-Lorraine'
  },
  {
    zone_id: '634582d1534e7d912aa7dfaa',
    departmentCode: '89',
    departmentName: 'Yonne',
    regionCode: '27',
    regionName: 'Bourgogne-Franche-Comté'
  },
  {
    zone_id: '634582d723cf002b4942ef53',
    departmentCode: '90',
    departmentName: 'Territoire de Belfort',
    regionCode: '27',
    regionName: 'Bourgogne-Franche-Comté'
  },
  {
    zone_id: '634582dd13b436e5b21da325',
    departmentCode: '91',
    departmentName: 'Essonne',
    regionCode: '11',
    regionName: 'Ile-de-France'
  },
  {
    zone_id: '634582e376447b4c9180dcf7',
    departmentCode: '92',
    departmentName: 'Hauts-de-Seine',
    regionCode: '11',
    regionName: 'Ile-de-France'
  },
  {
    zone_id: '634582ea65be0c660f6f086f',
    departmentCode: '93',
    departmentName: 'Seine-Saint-Denis',
    regionCode: '11',
    regionName: 'Ile-de-France'
  },
  {
    zone_id: '634582efee04d4e60d193763',
    departmentCode: '94',
    departmentName: 'Val-de-Marne',
    regionCode: '11',
    regionName: 'Ile-de-France'
  },
  {
    zone_id: '634582f83245036b0807c8b9',
    departmentCode: '95',
    departmentName: "Val-d'oise",
    regionCode: '11',
    regionName: 'Ile-de-France'
  },
  {
    zone_id: '634582fd56388ad2d4114e56',
    departmentCode: '971',
    departmentName: 'Guadeloupe',
    regionCode: '01',
    regionName: 'Guadeloupe'
  },
  {
    zone_id: '6345830216debede3626f315',
    departmentCode: '972',
    departmentName: 'Martinique',
    regionCode: '02',
    regionName: 'Martinique'
  },
  {
    zone_id: '63458307e854ced8616c550a',
    departmentCode: '973',
    departmentName: 'Guyane',
    regionCode: '03',
    regionName: 'Guyane'
  },
  {
    zone_id: '6345830c9c7f5c4e72a316c4',
    departmentCode: '974',
    departmentName: 'La Réunion',
    regionCode: '04',
    regionName: 'La Réunion'
  },
  {
    zone_id: '634583112d75293bae0c7235',
    departmentCode: '976',
    departmentName: 'Mayotte',
    regionCode: '06',
    regionName: 'Mayotte'
  },
  {
    zone_id: '63458f4a844263a9efe3f627',
    departmentCode: '987',
    departmentName: 'Polynésie française',
    regionCode: '98',
    regionName: 'Polynésie française'
  },
  {
    zone_id: '63458fefa4dfb8a43d00c52f',
    departmentCode: '988',
    departmentName: 'Nouvelle-Calédonie',
    regionCode: '98',
    regionName: 'Nouvelle-Calédonie'
  }
];

export const regions = dptmts.reduce((a, b) => {
  if (!a.find((c) => c.regionCode === b.regionCode)) {
    a.push({ regionCode: b.regionCode, regionName: b.regionName });
  }

  return a;
}, []);

export const departmentsForFilters = dptmts.map((d) => ({
  label: `${d.departmentCode} - ${d.departmentName}`,
  value: d.departmentCode
}));
