import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  toolbarMargin: {
    ...theme.mixins.toolbar
  },
  title: {
    flexGrow: 1
  },
  toolbar: {
    paddingRight: 0
  },
  listItemBadge: {
    ['& span']: {
      color: 'white',
      backgroundColor: '#cc0000'
    }
  },
  drawer: {
    background: 'transparent',
    display: 'flex',
    position: 'fixed',
    justifyContent: 'space-between',
    overflowX: 'hidden',
    zIndex: theme.zIndex.appBar - 1,
    border: 'none'
  },
  drawerClose: {
    transition: `width ${theme.sidebar.transition}`,
    width: theme.sidebar.closedSidebarWidth
  },
  drawerOpen: {
    transition: `width ${theme.sidebar.transition}`,
    width: theme.sidebar.openSidebarWidth
  },
  icon: {
    fontSize: 16,
    ...theme.transitionTheme
  },
  list: {
    borderRight: '1px solid #DFE3E8',
    background: 'white',
    paddingTop: 0,
    overflowX: 'hidden',
    height: '100%',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  listItem: {
    paddingLeft: 16,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 8
    },
    paddingTop: 8,
    paddingBottom: 8,
    width: theme.sidebar.openSidebarWidth,
    borderRadius: 0,
    '&:hover': {
      '& .listItemText': {
        color: theme.palette.primary.light
      },
      '& .icon': {
        color: theme.palette.primary.light
      }
    }
  },
  listSubItem: {
    paddingLeft: 16,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 8
    },
    paddingTop: 4,
    paddingBottom: 4,
    width: theme.sidebar.openSidebarWidth,
    borderRadius: 0,
    '&:hover': {
      '& .listItemText': {
        color: theme.palette.primary.light
      },
      '& .icon': {
        color: theme.palette.primary.light
      }
    }
  },
  listSubItemActive: {
    '& .listItemText': {
      color: theme.palette.primary.main,
      fontWeight: 600
    },
    color: theme.palette.primary.dark,
    '&:hover': {
      fontWeight: 600
    }
  },
  listItemActive: {
    backgroundColor: theme.palette.grey['100'],
    '& .listItemText': {
      color: theme.palette.primary.main,
      fontWeight: 600
    },
    '& .icon': {
      color: theme.palette.primary.dark
    },
    '&:hover': {
      '& .icon': {
        color: theme.palette.primary.dark
      }
    }
  },
  listItemText: {
    fontWeight: 500,
    ...theme.transitionTheme
  },
  listSubItemText: {
    fontWeight: 400,
    marginLeft: 8,
    ...theme.transitionTheme
  },
  listItemIcon: {
    width: 42,
    height: 42,
    marginRight: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  secondaryMenuButton: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    height: 58
  },
  footerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0px 10px',
    width: '100%',
    marginBottom: 20
  },
  footerHeartColor: {
    color: theme.palette.primary.main
  },
  relativeContainer: {
    position: 'relative',
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  mobileSidebarTopBar: ({ isBelowMd }) => ({
    backgroundColor: theme.palette.primary.main,
    height: isBelowMd ? 57 : 64,
    display: 'flex',
    alignItems: 'center',
    position: 'sticky',
    top: 0,
    width: '100%',
    zIndex: theme.zIndex.appBar
  }),
  sidebarLogo: {
    userSelect: 'none'
  },
  logo: {
    height: 32,
    position: 'relative',
    bottom: 3
  },
  drawerButton: {
    marginRight: 16
  },
  drawerButtonIcon: {
    color: '#ffffff',
    width: '1em',
    height: '1em',
    fontSize: '1em'
  },
  databaseIcon: {
    opacity: 0.4
  }
}));
