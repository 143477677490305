import { Box } from '@material-ui/core';
import bobdepannage from 'assets/svg/bob-depannage.svg';
import { useConfiguration } from 'hooks/useConfiguration';

import useStyles from 'layouts/entities/Preview.styles';
import { useTranslation } from 'react-i18next';

export default function JobPreview({ element }) {
  const classes = useStyles();
  const config = useConfiguration();
  const { t } = useTranslation();

  return (
    <Box style={{ width: '100%' }}>
      <Box className={classes.l}>
        <Box
          boxShadow={1}
          bgcolor={element.color}
          borderRadius="50%"
          width="10px"
          height="10px"
          marginRight="6px"
        />
        <span className={classes.title}>{t(element.name)}</span>
        {element.isBob && config?.feature?.bobdepannage && (
          <img
            style={{ height: 20 }}
            src={bobdepannage}
            alt=""
          />
        )}
      </Box>
    </Box>
  );
}
