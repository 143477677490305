import {
  Button,
  Dialog,
  DialogContent,
  Box,
  CircularProgress,
  Typography,
  DialogActions
} from '@material-ui/core';

import { useState } from 'react';
import { useAuth } from 'hooks/useAuth';

import { finishElement, setDate } from 'store/ticketsSlice';

import moment from 'moment';

import DialogTitle from 'components/dialogs/DialogTitle';
import { DateTimePicker } from '@material-ui/pickers';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import { useTranslation } from 'react-i18next';

export default function EndInterventionDialog(props) {
  const auth = useAuth();
  const { open, onClose, ticket, dates } = props;
  const [step, setStep] = useState(0);
  const [visitDate, setVisitDate] = useState(0);

  const { t } = useTranslation();

  const { dispatch, requestStatus } = useAsyncDispatch();
  const isLoading = requestStatus === 'loading';

  const xhrVisit = (cb) => {
    if (visitDate) {
      dispatch(
        setDate,
        {
          type: 'visit',
          compute: false,
          date: visitDate,
          message: '',
          isParent: auth.interface.isContractor,
          coords: false
        },
        {
          onSuccess: () => cb()
        },
        { id: ticket._id }
      );
    } else {
      cb();
    }
  };

  const xhrIntervention = (cb) => {
    if (dates.date_begin && dates.date_finish) {
      dispatch(
        setDate,
        {
          type: 'intervention',
          compute: false,
          date: dates.date_begin,
          message: '',
          duration: moment(dates.date_finish).diff(moment(dates.date_begin), 'minutes'),
          isParent: auth.interface.isContractor,
          coords: false
        },
        {
          onSuccess: () => cb()
        },
        { id: ticket._id }
      );
    } else {
      cb();
    }
  };

  const xhrFinish = (cb) => {
    dispatch(
      finishElement,
      {},
      {
        onSuccess: () => cb()
      },
      { id: ticket._id }
    );
  };

  return (
    <Dialog
      open={open}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle title={t('confirmResiliation')} />
      {
        [
          <>
            <DialogContent>
              <Box
                style={{
                  textAlign: 'center',
                  margin: '1em 0'
                }}
              >
                <Typography variant="h4">
                  {t(
                    auth.interface.isBlachere ? 'blachHasInterventionEnded' : 'hasInterventionEnded'
                  )}
                </Typography>
              </Box>
              <Typography>
                {t(
                  auth.interface.isBlachere
                    ? 'blachNoMoreVisitRequiredCloseThat'
                    : 'noMoreVisitRequiredCloseThat'
                )}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                disabled={isLoading}
                onClick={() => [setStep(1)]}
              >
                {t('no')}
              </Button>
              <Button
                startIcon={isLoading && <CircularProgress size={14} />}
                disabled={isLoading}
                onClick={() => {
                  xhrIntervention(() => {
                    xhrFinish(() => {
                      onClose();
                    });
                  });
                }}
              >
                {t('yes')}
              </Button>
            </DialogActions>
          </>,
          <>
            <DialogContent>
              <Box
                style={{
                  textAlign: 'center',
                  margin: '1em 0'
                }}
              >
                <Typography variant="h4">{t('doYouKnowNextVisitDate')}</Typography>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setStep(0)}
                style={{ marginRight: 'auto' }}
              >
                {t('back')}
              </Button>
              <Button
                disabled={isLoading}
                onClick={() => {
                  xhrIntervention(() => {
                    onClose();
                  });
                }}
              >
                {t('no')}
              </Button>
              <Button
                startIcon={isLoading && <CircularProgress size={14} />}
                disabled={isLoading}
                onClick={() => setStep(2)}
              >
                {t('yes')}
              </Button>
            </DialogActions>
          </>,
          <>
            <DialogContent style={{ margin: 0, padding: 0 }}>
              <DateTimePicker
                variant="static"
                openTo="date"
                value={visitDate}
                ampm={false}
                onChange={(date) => [setVisitDate(date)]}
              />
            </DialogContent>
            <DialogActions>
              <Button
                disabled={isLoading}
                onClick={() => setStep(1)}
                style={{ marginRight: 'auto' }}
              >
                {t('back')}
              </Button>
              <Button
                startIcon={isLoading && <CircularProgress size={14} />}
                disabled={isLoading}
                onClick={() => {
                  xhrVisit(() => {
                    xhrIntervention(() => {
                      onClose();
                    });
                  });
                }}
              >
                {t('validate')}
              </Button>
            </DialogActions>
          </>
        ][step]
      }
    </Dialog>
  );
}
