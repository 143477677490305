import { useAuth } from './useAuth';

export const useRole = () => {
  const auth = useAuth();

  const permission = (entity, permission) => {
    if (entity === 'scopes') {
      return true;
    }

    const permissions = auth.interface._role?.permissions[entity];
    return permissions?.includes(permission);
  };

  return { permission };
};
