const MaintenanceMenu = (
  element,
  auth,
  dispatch,
  role,
  companyPermission,
  listId,
  setIsMenuLoading,
  notify
) => [].filter((e) => e.show);

export default MaintenanceMenu;
