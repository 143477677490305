import { createSlice } from '@reduxjs/toolkit';
import { generateEntityBoilerplate } from 'utils/generateEntitySlice';
import generateAsyncThunk from 'utils/generateAsyncThunk';

export const createElement = generateAsyncThunk({ type: 'POST', endpoint: 'guarantees2' });
export const updateElement = generateAsyncThunk({ type: 'PUT', endpoint: 'guarantees2/${id}' });
export const getElement = generateAsyncThunk({ type: 'GET', endpoint: 'guarantees2/${id}' });
export const getElements = generateAsyncThunk({ type: 'POST', endpoint: 'guarantees2/list' });
export const deleteElement = generateAsyncThunk({ type: 'DELETE', endpoint: 'guarantees2/${id}' });
export const restoreElement = generateAsyncThunk({
  type: 'POST',
  endpoint: 'guarantees2/recycle/${id}'
});

const entityBoilerplate = generateEntityBoilerplate({
  createElement,
  updateElement,
  getElement,
  getElements,
  deleteElement,
  restoreElement
});

const { initialState, reducers, extraReducers } = entityBoilerplate;

export const guaranteesSlice = createSlice({
  name: 'guarantees',
  initialState: { ...initialState },
  reducers: { ...reducers },
  extraReducers: { ...extraReducers }
});

export const { flushElements, flushElement } = guaranteesSlice.actions;
export default guaranteesSlice.reducer;
