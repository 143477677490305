import { Box, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: ({ border, backgroundColor }) => ({
    position: 'absolute',
    zIndex: 999999,
    borderRadius: border ? 8 : 0,
    backgroundColor: backgroundColor ? backgroundColor : 'rgba(255,255,255,.4)',
    backdropFilter: 'blur(4px)',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden'
  })
}));

export default function BlurredProgress({ in: enter, border, backgroundColor, children }) {
  const c = useStyles({ border, backgroundColor });

  return enter ? (
    <Box
      bgcolor={'white'}
      className={c.root}
      onMouseDown={(e) => [e?.stopPropagation(), e?.preventDefault()]}
      onClick={(e) => [e?.stopPropagation(), e?.preventDefault()]}
    >
      {children ? children : <CircularProgress color="secondary" />}
    </Box>
  ) : null;
}
