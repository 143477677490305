import {
  AccordionActions,
  Divider,
  AccordionDetails,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  Grid,
  Button,
  Collapse
} from '@material-ui/core';

import { useAuth } from 'hooks/useAuth';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import useNotifications from 'hooks/useNotifications';
import { setConfiguration } from 'store/authSlice';
import { Submit, Reset, useField } from 'frmx';

import Scope from 'entities/Scope/Scope';
import EntityX from 'components/inputs/EntityX';
import AccordionForm from 'components/forms/AccordionForm';
import { useTranslation } from 'react-i18next';

export default function ClientsConfiguration({ section }) {
  const company = useAuth().interface._company;

  const notify = useNotifications();
  const { dispatch, requestStatus } = useAsyncDispatch();
  const { t } = useTranslation();

  const dispatchCallbacks = { onSuccess: () => notify.success(), onError: () => notify.error() };

  return (
    <AccordionForm
      section={section}
      defaultClose
      initialValues={{
        clients_settings: {
          acquisitionChannelMonitoring:
            company.clients_settings?.acquisitionChannelMonitoring || false,
          _acquisitionChannelScopes: company.clients_settings?._acquisitionChannelScopes || []
        }
      }}
      onSubmit={(updates) => dispatch(setConfiguration, updates, dispatchCallbacks)}
    >
      <ClientsConfigContents />
      <Divider />

      <AccordionActions>
        <Reset>
          <Button size="small">{t('cancel')}</Button>
        </Reset>

        <Submit disabled={requestStatus === 'loading'}>
          <Button
            size="small"
            color="primary"
          >
            {t('save')}
          </Button>
        </Submit>
      </AccordionActions>
    </AccordionForm>
  );
}

function ClientsConfigContents(props) {
  const { value: checked, setValue: setChecked } = useField(
    'clients_settings.acquisitionChannelMonitoring'
  );
  const { t } = useTranslation();

  return (
    <AccordionDetails>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={() => setChecked((prev) => !prev)}
              />
            }
            label={t('activateAcquisitionChannels')}
          />
          <FormHelperText>{t('activateAcquisitionChannelsHelperText')}</FormHelperText>
        </Grid>

        <Collapse
          in={checked}
          style={{ width: '100%' }}
        >
          <Grid
            item
            xs={12}
            style={{
              padding: '8px 8px 16px 8px',
              width: '100%'
            }}
          >
            <EntityX
              path="clients_settings._acquisitionChannelScopes"
              entity={Scope}
              entityProps={{ type: 'clientAcquisition' }}
              placeholder={t('acquisitionChannels')}
            />
          </Grid>
        </Collapse>
      </Grid>
    </AccordionDetails>
  );
}
