import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  Grow,
  Box,
  TextField,
  Button
} from '@material-ui/core';

import Rating from '@material-ui/lab/Rating';

import useNotifications from 'hooks/useNotifications';

import { useState } from 'react';
import { rateIntervener } from 'store/ticketsSlice';

export default function RateDialog({ open, onClose, order, intervener, dispatch }) {
  const close = (e) => [e.preventDefault(), e.stopPropagation(), onClose()];

  const [rate, setRate] = useState(0);
  const [message, setMessage] = useState('');

  const name = intervener.isContractor
    ? intervener.companyName
    : intervener.firstName + ' ' + intervener.lastName;

  const notify = useNotifications();

  return (
    <Dialog
      disableEnforceFocus
      open={open}
      onClose={(e) => close(e)}
      onMouseDown={(e) => e.stopPropagation()}
      onClick={(e) => e.stopPropagation()}
    >
      <DialogContent>
        <DialogContentText>
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
          >
            <Box padding="0px 16px">
              Noter <strong>{name}</strong> pour l'intervention #{order.number} {order.title}
            </Box>
            <Grow in>
              <Box
                display="flex"
                justifyContent="center"
                marginTop="24px"
                marginBottom="34px"
              >
                <Rating
                  size="large"
                  value={rate}
                  style={{ color: '#fbb034', transform: 'scale(1.5)' }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(event, rate) => setRate(rate)}
                />
              </Box>
            </Grow>
            <TextField
              value={message}
              variant="outlined"
              placeholder="Remarques ..."
              multiline
              rows={5}
              onChange={(e) => setMessage(e.target.value)}
            />
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={close}
          color="secondary"
        >
          Annuler
        </Button>
        <Button
          disabled={!rate}
          onClick={(e) => [
            dispatch(
              rateIntervener,
              { message, rate, _intervener: intervener._id },
              { onSuccess: () => notify.success() },
              { id: order._id }
            ),
            close(e)
          ]}
          color="primary"
        >
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
