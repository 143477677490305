const currency = [
  { icon: 'euro-sign', name: 'Euro', code: 'EUR', symbol: '€', locale: 'fr-FR' },
  { icon: 'dollar-sign', name: 'Dollar', code: 'USD', symbol: '$', locale: 'en-US' },
  { icon: 'sterling-sign', name: 'Livre sterling', code: 'GBP', symbol: '£', locale: 'en-GB' },
  { icon: 'yen-sign', name: 'Yen', code: 'JPY', symbol: '¥', locale: 'ja-JP' },
  { icon: 'franc-sign', name: 'Franc CFP (franc pacifique)', code: 'XPF', symbol: 'FCFP', locale: 'fr-FR' },
  { icon: 'chf-sign', name: 'Franc Suisse', code: 'CHF', symbol: 'CHF', locale: 'fr-CH' },
];

export default currency;
