import {
  getElement,
  getElements,
  getPublicElements,
  createElement,
  updateElement,
  flushElements,
  deleteElement,
  restoreElement
} from 'store/equipmentsSlice';

import EquipmentMenus from './EquipmentMenus';
import EquipmentPreview from './EquipmentPreview';
import EquipmentSkeleton from './EquipmentSkeleton';
import EquipmentView from './EquipmentView';
import EquipmentTabs from './EquipmentTabs';
import EquipmentFilters, { formatFilter } from './EquipmentFilters';
import EquipmentFormValues, { formatForm, EquipmentValidation } from './EquipmentFormValues';
import EquipmentTranslations from './EquipmentTranslations';
import EquipmentExport from './EquipmentExport';
import EquipmentForm from './EquipmentForm';
import EquipmentSorts from './EquipmentSorts';

import EntityWrapper from 'entities/EntityWrapper';

import { Image } from '@material-ui/icons';
import EquipmentTable from './EquipmentTable';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'hooks/useAuth';

export default function Equipment(props) {
  const auth = useAuth();
  const { t } = useTranslation();

  return (
    <EntityWrapper
      entity="equipments"
      sublistParentKey="_parentEquipments"
      entityFieldsSlice="equipment"
      skeletonComponent={EquipmentSkeleton}
      previewComponent={EquipmentPreview}
      viewComponent={EquipmentView}
      formComponent={EquipmentForm}
      formatForm={formatForm}
      formatFilter={formatFilter}
      getFilters={EquipmentFilters}
      getInitialValues={EquipmentFormValues}
      getFormValidation={EquipmentValidation}
      getTabs={EquipmentTabs}
      getTables={EquipmentTable}
      getMenus={EquipmentMenus}
      getExportColumns={EquipmentExport}
      getTranslations={EquipmentTranslations}
      getSorts={EquipmentSorts}
      getElement={getElement}
      getElements={auth.interface.isPublic ? getPublicElements : getElements}
      createElement={createElement}
      updateElement={updateElement}
      flushElements={flushElements}
      deleteElement={deleteElement}
      restoreElement={restoreElement}
      formAttachment
      formAttachmentIcon={<Image />}
      formAttachmentLabel={t('coverPicture')}
      {...props}
    />
  );
}
