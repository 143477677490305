import { Box, Typography } from "@material-ui/core";
import formatPhoneNumber from "utils/formatPhoneNumber";

export default function SupplierPreview({ element }) {
  return (
    <Box className="flex w-full justify-between gap-1">
      <Box className="flex flex-col">
        <Box className="flex flex-col font-semibold text-sm gap-2">
          {element.reference && <p className="text-[#363640] text-xs">#{element.reference}</p>}
          <p className="font-medium">{element.companyName}</p>
        </Box>
        <Typography variant="body2">{(element.firstName || '') + " " + (element.lastName || '')}</Typography>
      </Box>
      <Box
        style={{
          marginRight: '1em',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'space-between'
        }}>
        <Typography variant="body2">{formatPhoneNumber(element.phone)}</Typography>
        <Typography variant="body2">{element.email}</Typography>
      </Box>
    </Box>
  );
}
