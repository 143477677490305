import { Typography, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import FAIcon from 'components/ui/FAIcon';

import clsx from 'clsx';
import { useAuth } from 'hooks/useAuth';

import useStyles from './SettingsDrawerMenu.styles';
import useKeys from '@flowsn4ke/usekeys';
import { useTranslation } from 'react-i18next';

export function SettingsDrawerMenuMobile(props) {
  const { setSelectedMenu, selectedMenu, menus } = props;

  const auth = useAuth();

  const classes = useStyles();
  const { t } = useTranslation();
  const k = useKeys();

  return (
    <List className={classes.list}>
      {menus
        .filter((menu) => selectedMenu.key !== menu.key)
        .map((menu, i) => (
          <ListItem
            style={{ paddingLeft: 24 }}
            key={k(i)}
            button
            onClick={() => setSelectedMenu(menu)}
          >
            <ListItemIcon style={{ marginRight: 20 }}>
              {menu.component && menu.component}
              {!menu.component && (
                <FAIcon
                  collection="fal"
                  icon={menu.icon || ''}
                  className={classes.menuMobileIcon}
                  size="medium"
                />
              )}
            </ListItemIcon>
            <ListItemText className={classes.listItemText}>
              {menu.label && typeof menu.label === 'function'
                ? menu.label(auth.interface)
                : t(menu.label)}
            </ListItemText>
          </ListItem>
        ))}
    </List>
  );
}

export function SettingsDrawerMenu(props) {
  const { setSelectedMenu, selectedMenu, menus } = props;

  const auth = useAuth();
  const classes = useStyles();
  const { t } = useTranslation();
  const k = useKeys();

  return (
    <List
      className={classes.menu}
      style={{
        width: '100%',
        maxWidth: 200,
        position: 'sticky',
        top: 0
      }}
    >
      {menus.map((menu, i) => (
        <ListItem
          key={k(i)}
          button
          className={clsx(classes.menuItem, {
            [classes.menuItemActive]: selectedMenu.key === menu.key
          })}
          onClick={() => setSelectedMenu(menu)}
        >
          <ListItemIcon className={classes.listItemIcon}>
            {menu.component && menu.component}
            {!menu.component && (
              <FAIcon
                collection={selectedMenu.key === menu.key ? 'fas' : 'fal'}
                icon={menu.icon}
                size="medium"
                className={clsx({
                  [classes.activeTab]: selectedMenu.key === menu.key
                })}
              />
            )}
          </ListItemIcon>

          <ListItemText>
            <Typography
              variant="subtitle2"
              className={clsx({
                [classes.activeTab]: selectedMenu.key === menu.key
              })}
            >
              {menu.label && typeof menu.label === 'function'
                ? menu.label(auth.interface)
                : t(menu.label)}
            </Typography>
          </ListItemText>
        </ListItem>
      ))}
    </List>
  );
}
