import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { getFileType, getPlaceholder, isPlaceholder } from './helpers';

const RETRY_INTERVAL = 5000; //ms
const MAX_RETRY_COUNT = 3;

const ImageWithRetryFallback = ({ previewUrl, fileDisplayName, document, isSelected, mimetype }) => {
  const [src, setSrc] = useState(previewUrl);
  const [isLoaded, setIsLoaded] = useState(false);
  const [retryCount, setRetryCount] = useState(0);

  useEffect(() => {
    let timer;

    if (!isLoaded && retryCount <= MAX_RETRY_COUNT) {
      timer = setTimeout(() => {
        setSrc(`${previewUrl}?timestamp=${new Date().getTime()}`); //Adding a timestamp at the end of the request to fool the browser's cache
        setRetryCount(retryCount + 1);
      }, RETRY_INTERVAL);
    }

    return () => clearTimeout(timer);
  }, [retryCount, previewUrl]);

  const handleImageError = () => {
    setIsLoaded(false);
    setSrc(getPlaceholder(mimetype));
  };

  const handleImageLoad = () => {
    if (isPlaceholder(src, mimetype) && retryCount <= MAX_RETRY_COUNT) return;
    setIsLoaded(true);
    setRetryCount(0);
  };

  return (
    <div className="relative flex h-[100px] w-[90px] text-center text-white overflow-hidden justify-center items-center">
      <img
        src={src}
        draggable="false"
        className={classNames(
          isPlaceholder(src, mimetype) ? 'w-[3em] h-[3.6em]' : 'w-full h-full ',
          isSelected(document._id) ? 'border-4 border-sky-500' : 'text-slate-500 border-4 border-transparent',
          'rounded-lg object-cover'
        )}
        alt={fileDisplayName}
        onError={handleImageError}
        onLoad={handleImageLoad}
      />
      {isLoaded && !isPlaceholder(src, mimetype) ? (
        <div className="absolute bottom-1 right-1 opacity-70 text-[0.6em] bg-black bg-opacity-70 px-1 rounded-tl">
          {getFileType(mimetype)}
        </div>
      ) : null}
    </div>
  );
};

export default ImageWithRetryFallback;
