import { Box, AppBar, Tabs, Tab, Tooltip } from '@material-ui/core';

import useStyles from './Filter.styles';
import useKeys from '@flowsn4ke/usekeys';
import { useEntity } from 'contexts/entities/entityContext';
import { useMemo } from 'react';
import { trigger } from 'react-events-utils';
import { useTranslation } from 'react-i18next';

export default function FilterTab({ tabs, setAppliedFilters, appliedFilters }) {
  const { page, tabChangedId, tabViewAll } = useEntity();

  const { t } = useTranslation();

  const k = useKeys();

  const selected = useMemo(() => {
    let s;
    if (Object.keys(appliedFilters.tab).every((tab) => appliedFilters.tab[tab])) {
      s = 'all';
    } else {
      s = Object.keys(appliedFilters.tab).find((tab) => appliedFilters.tab[tab]);
    }

    return s;
  }, [appliedFilters.tab]);

  const tabSelected = useMemo(() => tabs.find((t) => t.key === selected), [selected]);

  const classes = useStyles({ page, indicatorColor: tabSelected?.color });

  return (
    <AppBar
      position="static"
      className={classes.listTabs}
      elevation={1}
    >
      <Tabs
        onChange={(e, tab) => {
          e.stopPropagation();
          if (tabChangedId) {
            trigger('tabChanged' + tabChangedId, tab);
          }
        }}
        value={selected}
      >
        {tabs.map((tab, i) => (
          <Tab
            onClick={(e) => e.stopPropagation()}
            style={tab.label ? {} : { minWidth: 42 }}
            className={classes.listTabsTab}
            value={tab.key}
            key={k(i)}
            label={
              <Box
                className={classes.listTabsBox}
                style={selected === tab.key && tab.color ? { color: tab.color } : {}}
              >
                {tab.icon}{' '}
                <span
                  className={classes.listTabsLabel}
                  style={tab.label ? {} : { marginLeft: 0 }}
                >
                  {t(tab.label)}
                </span>
              </Box>
            }
          />
        ))}

        {tabViewAll && (
          <Tab
            style={{ minWidth: 'auto', marginLeft: 'auto' }}
            className={classes.listTabsTab}
            value="all"
            label={
              <Tooltip
                placement="left"
                title={t('seeAll')}
              >
                <Box
                  className={classes.listTabsBox}
                  style={{ fontSize: 11 }}
                >
                  TOUT
                </Box>
              </Tooltip>
            }
          />
        )}
      </Tabs>
    </AppBar>
  );
}
