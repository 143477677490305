import { createSlice } from '@reduxjs/toolkit';
import generateAsyncThunk from 'utils/generateAsyncThunk';
import { fetchPublicData } from './authSlice';

export const createFieldSection = generateAsyncThunk({ type: 'POST', endpoint: 'fieldSections' });
export const updateFieldSection = generateAsyncThunk({
  type: 'PUT',
  endpoint: 'fieldSections/${id}'
});
export const getFieldSection = generateAsyncThunk({
  type: 'GET',
  endpoint: 'fieldSections/${entity}/${id}'
});
export const getFieldSections = generateAsyncThunk({
  type: 'GET',
  endpoint: 'fieldSections/${entity}'
});
export const deleteFieldSection = generateAsyncThunk({
  type: 'DELETE',
  endpoint: 'fieldSections/${id}'
});
export const restoreFieldSection = generateAsyncThunk({
  type: 'POST',
  endpoint: 'fieldSections/recycle/${id}'
});
export const duplicateFieldSection = generateAsyncThunk({
  type: 'GET',
  endpoint: 'fieldSections/${id}/duplicate'
});

export const createField = generateAsyncThunk({
  type: 'POST',
  endpoint: 'fields',
  prefix: 'reports'
});
export const updateField = generateAsyncThunk({
  type: 'PUT',
  endpoint: 'fields/${id}',
  prefix: 'reports'
});
export const updateFieldPosition = generateAsyncThunk({
  type: 'PUT',
  endpoint: 'fields/${id}/position',
  prefix: 'reports'
});
export const deleteField = generateAsyncThunk({
  type: 'DELETE',
  endpoint: 'fields/${id}',
  prefix: 'reports'
});

const mapEndpointToState = (endpoint) => {
  switch (endpoint) {
    case 'collaborator':
      return 'technician';
    case 'contact':
      return 'user';
    case 'preventif':
      return 'maintenance';
    case 'interventionReport':
      return 'report';
    default:
      return endpoint;
  }
};

const initialState = {
  ticket: [],
  location: [],
  equipment: [],
  technician: [],
  contractor: [],
  user: [],
  manager: [],
  maintenance: [],
  client: [],
  report: [],
  guarantee: [],
  contract: [],
  lease: [],
  supplier: []
};

export const fieldSectionsSlice = createSlice({
  name: 'fieldSections',
  initialState,
  reducers: {
    setFields: (state, action) => {
      state[mapEndpointToState(action.payload.entity)][action.payload.index].fields =
        action.payload.next;
    }
  },
  extraReducers: {
    [createFieldSection.fulfilled]: (state, action) => {
      state[mapEndpointToState(action.meta.arg.entity)].push(action.payload.data.element);
    },
    [updateFieldSection.fulfilled]: (state, action) => {
      const key = mapEndpointToState(action.payload.data.entity);
      state[key] = state[key].map((el, i) => {
        return el._id !== action.meta.id ? el : { ...el, ...action.meta.arg };
      });
    },
    [getFieldSections.fulfilled]: (state, action) => {
      state[mapEndpointToState(action.meta.entity)] = action.payload.data.elements;
    },
    [fetchPublicData.fulfilled]: (state, action) => {
      const ticketsSections = action.payload.data.ticketsSections;
      ticketsSections && (state['ticket'] = ticketsSections);
    },
    [deleteFieldSection.fulfilled]: (state, action) => {
      const entity = action.meta.arg.params.entity;
      const id = action.meta.arg.params.id;
      state[entity] = state[entity].filter((section) => section._id !== id);
    },
    [duplicateFieldSection.fulfilled]: (state, action) => {
      const next = action.payload.data;
      state.report.push(next);
    }
  }
});

export const selectFieldSections = (state) => state.fieldSections;
export const { setFields } = fieldSectionsSlice.actions;
export default fieldSectionsSlice.reducer;
