import { Box } from '@material-ui/core';

import useStyles from 'layouts/entities/Preview.styles';

export default function ReportPreview({ element }) {
  const classes = useStyles();

  return (
    <Box style={{ width: '100%' }}>
      <Box className={classes.l}>
        <span className={classes.title}>{element.label}</span>
      </Box>
    </Box>
  );
}
