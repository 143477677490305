import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  searchBar: () => ({
    padding: `2px 12px 0px 0px`,
    minHeight: 'auto',
    backgroundColor: 'white',
    zIndex: 1,
    boxShadow: theme.shadows[0],
    userSelect: 'none'
  }),
  counter: {
    padding: '0px 0px 0px 6px',
    fontSize: 11,
    textTransform: 'lowercase'
  },
  searchIcon: {
    color: '#bfc6ce',
    padding: 5
  },
  searchInput: {
    marginLeft: 8,
    flexGrow: 1
  },
  statusCount: {
    display: 'none',
    alignItems: 'center',
    fontWeight: 500,
    marginRight: 4,
    color: '#7e7e7e',
    fontSize: 8,
    position: 'absolute',
    right: 1,
    bottom: 0,
    ['& .MuiButtonBase-root']: {
      marginLeft: 4
    },
    ['& svg']: {
      fontSize: 16,
      height: 16,
      width: 16
    }
  },
  statusSearch: {
    zIndex: 2,
    marginRight: 10
  },
  statusRight: {
    marginLeft: 'auto'
  },
  statusClearFilters: {
    marginRight: 0
  },
  statusRefresh: ({ loading }) => ({
    ...(loading ? theme.spinnerAnimation : {})
  }),
  errorColor: {
    color: theme.palette.error.light
  },
  moreMenu: {
    padding: 8,
    minWidth: 180
  }
}));
