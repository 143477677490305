import Client from 'entities/Client/Client';
import Scope from 'entities/Scope/Scope';

import { zones, zonesForFilters } from 'lists/zones';

export const formatFilter = (filters, auth) => {
  if (!!auth.interface._locations.length) {
    filters['only'] = auth.interface._locations;
  }

  if (!filters.clients?.length && auth.interface._scope_clients) {
    filters['clients'] = auth.interface._scope_clients;
  }

  return Object.keys(filters).reduce((acc, curr) => {
    const filter = filters[curr];

    if (curr === 'regions') {
      filters[curr] = filter?.reduce((acc, curr) => {
        return acc.concat(zones.filter((d) => d.regionCode === curr).map((d) => d.departmentCode));
      }, []);
    }

    return {
      ...acc,
      [curr]: filter
    };
  }, []);
};

const locationFilters = (config, { customFields, lockClients }) =>
  [
    {
      label: 'client',
      key: 'clients',
      type: 'Entity',
      params: {
        entity: Client,
        entityProps: {}
      },
      disabled: false,
      show: config.isContractor,
      default: [],
      lock: lockClients
    },
    {
      label: config.isBlachere ? 'blachCategories' : 'categories',
      key: 'types',
      type: 'Entity',
      params: {
        entity: Scope,
        entityProps: { type: 'locationCategory' }
      },
      show: true,
      disabled: false,
      default: []
    },
    {
      label: 'entityScoSecName',
      key: 'sectors',
      type: 'Entity',
      params: {
        entity: Scope,
        entityProps: { type: 'sectors' }
      },
      disabled: false,
      show: config.location_sectors,
      default: []
    },
    // ! Do NOT DELETE THIS !!!!!!
    // {
    //   label: 'region',
    //   key: 'regions',
    //   type: 'List',
    //   params: {
    //     icon: 'map-marker-alt',
    //     datas: regions.map(r => ({ label: r.regionName, value: r.regionCode }))
    //   },
    //   disabled: false,
    //   show: true,
    //   default: []
    // },
    // {
    //   label: 'department',
    //   key: 'postalCodes',
    //   type: 'List',
    //   params: {
    //     icon: 'map-marker-alt',
    //     datas: zones.map(d => ({ label: d.departmentCode + ' - ' + d.departmentName, value: d.departmentCode }))
    //   },
    //   disabled: false,
    //   show: true,
    //   default: []
    // },
    {
      label: 'zonesTitle',
      key: 'zone_ids',
      type: 'List',
      params: {
        datas: zonesForFilters
      },
      show: true,
      disabled: false,
      default: []
    },
    {
      label: 'field',
      key: 'fields',
      type: 'Field',
      params: {},
      show: customFields?.some((section) => !!section.fields?.length),
      disabled: false,
      default: []
    }
  ].filter((e) => e.show);

export default locationFilters;
