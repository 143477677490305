import { cloneElement, useCallback, useMemo, useRef } from 'react';
import useOutsideClick from 'hooks/useOutsideClick';
import { DIR_MIME_TYPE } from '../constants';
import ContextMenuItem from './ContextMenuItem';
import ContextMenuUploadItem from './ContextMenuUploadItem';
import useConfirm from 'hooks/useConfirm';
import { useAuth } from 'hooks/useAuth';
import { nanoid } from 'nanoid';
import { KeepInScreen } from 'utils/keepInScreen';

export default function ContextFileMenu({
  downloadDocument,
  getDocuments,
  viewTrash,
  setDocumentToConsult,
  setDocumentToRename,
  setDocumentToEdit,
  setDocumentCommentsToConsult,
  restoreDocument,
  setFolderNameDialogIsOpen,
  trashDocument,
  deleteDocument,
  contextMenuData,
  setContextMenuData,
  uploadFiles,
  uploadRevision,
  read_only_mode
}) {
  const contextMenuRef = useRef();
  useOutsideClick(contextMenuRef, () => setContextMenuData(null));
  const closeMenu = useCallback(() => {
    setContextMenuData(null);
  }, []);

  const confirm = useConfirm();

  const auth = useAuth();
  const user_has_owner_role = contextMenuData?.document?.owner_ids.includes(
    auth?.interface?._role?._id
  );
  const user_has_uploaded_the_document =
    auth.user._id === contextMenuData?.document?.created_by?._id;
  const can_edit_and_delete = user_has_owner_role || user_has_uploaded_the_document;
  const is_read_only = Boolean(
    read_only_mode || !user_has_owner_role || !user_has_uploaded_the_document
  );

  const menuItems = useMemo(() => {
    const fileMenuItems = [
      <ContextMenuItem
        menu={{
          icon: 'info',
          label: 'informations',
          onClick: () => setDocumentToConsult(contextMenuData?.document)
        }}
      />,
      ...(!can_edit_and_delete
        ? []
        : [
            <ContextMenuItem
              menu={{
                icon: 'pen-to-square',
                label: 'edit',
                onClick: () => setDocumentToEdit(contextMenuData?.document)
              }}
            />,
            <ContextMenuItem
              menu={{
                icon: 'i-cursor',
                label: 'rename',
                onClick: () => setDocumentToRename(contextMenuData?.document)
              }}
            />
          ]),
      <ContextMenuItem
        menu={{
          icon: 'arrow-down-to-bracket',
          label: 'download',
          onClick: () => downloadDocument({ document_id: contextMenuData?.document._id })
        }}
      />,
      <ContextMenuItem
        menu={{
          icon: 'message-lines',
          label: 'comments',
          onClick: () => setDocumentCommentsToConsult(contextMenuData?.document)
        }}
        content_badge={contextMenuData?.document?.has_comments}
      />,
      // <ContextMenuItem
      //   menu={{ icon: "share", label: "Partager", onClick: () => { } }}
      // />,
      ...(is_read_only
        ? []
        : [
            <ContextMenuItem
              menu={{
                icon: 'trash',
                label: 'putInTrash',
                onClick: () => trashDocument(contextMenuData?.document._id)
              }}
            />
          ]),
      ...(!can_edit_and_delete
        ? []
        : [
            <ContextMenuUploadItem
              label="downloadNewVersion"
              onUpload={(event) => {
                uploadRevision({
                  file: event.target.files[0],
                  document_id: contextMenuData?.document._id
                });
                closeMenu();
              }}
            />,
            <ContextMenuItem
              menu={{
                icon: 'trash-can-slash',
                label: 'deleteForever',
                onClick: () =>
                  confirm({
                    onConfirm: () => deleteDocument(contextMenuData?.document._id),
                    message: 'sureYouWantDeleteForeverDocument',
                    confirmText: 'permanentlyDelete'
                  })
              }}
            />
          ])
    ];

    const folderMenuItems = [
      ...(is_read_only
        ? []
        : [
            <ContextMenuItem
              menu={{
                icon: 'trash',
                label: 'putInTrash',
                onClick: () => trashDocument(contextMenuData?.document._id)
              }}
            />
          ]),
      ...(!can_edit_and_delete
        ? []
        : [
            <ContextMenuItem
              menu={{
                icon: 'i-cursor',
                label: 'rename',
                onClick: () => setDocumentToRename(contextMenuData?.document)
              }}
            />,
            <ContextMenuItem
              menu={{
                icon: 'trash-can-slash',
                label: 'deleteForever',
                onClick: () =>
                  confirm({
                    onConfirm: () => deleteDocument(contextMenuData?.document._id),
                    title: 'sureYouWantDeleteForeverDocumentAndContents',
                    confirmText: 'permanentlyDelete'
                  })
              }}
            />
          ])
    ];

    const trashedDocumentMenuItems = [
      ...(!can_edit_and_delete
        ? []
        : [
            <ContextMenuItem
              menu={{
                icon: 'rotate-left',
                label: 'restore',
                onClick: () => restoreDocument(contextMenuData?.document._id)
              }}
            />,
            <ContextMenuItem
              menu={{
                icon: 'trash-can-slash',
                label: 'deleteForever',
                onClick: () =>
                  confirm({
                    onConfirm: () => deleteDocument(contextMenuData?.document._id),
                    title: 'sureYouWantDeleteForeverDocument',
                    confirmText: 'permanentlyDelete'
                  })
              }}
            />
          ])
    ];

    const noDocumentMenuItems = [
      ...(read_only_mode
        ? [
            <ContextMenuItem
              menu={{ icon: 'rotate-right', label: 'refresh', onClick: () => getDocuments() }}
              closeMenu={closeMenu}
            />
          ]
        : [
            // <ContextMenuUploadItem label="Charger un fichier privé" onUpload={event => [uploadFiles(event.target.files), closeMenu()]} />,
            <ContextMenuUploadItem
              label="upload"
              onUpload={(event) => [uploadFiles(event.target.files), closeMenu()]}
            />,
            <ContextMenuItem
              menu={{
                icon: 'folder-plus',
                label: 'createFolder',
                onClick: () => setFolderNameDialogIsOpen(true)
              }}
              closeMenu={closeMenu}
            />,
            <ContextMenuItem
              menu={{ icon: 'rotate-right', label: 'refresh', onClick: () => getDocuments() }}
              closeMenu={closeMenu}
            />
          ])
    ];

    const clicked_on_document_in_trash = viewTrash && contextMenuData?.document;
    const clicked_on_folder =
      contextMenuData?.document && contextMenuData?.document.mimetype === DIR_MIME_TYPE;
    const clicked_on_file = contextMenuData?.document;
    const clicked_on_background = !viewTrash && !contextMenuData?.document;

    return clicked_on_document_in_trash
      ? trashedDocumentMenuItems
      : clicked_on_folder
        ? folderMenuItems
        : clicked_on_file
          ? fileMenuItems
          : clicked_on_background
            ? noDocumentMenuItems
            : [];
  }, [contextMenuData]);

  const { position_top, position_left, maxHeight } = KeepInScreen(contextMenuRef, contextMenuData);

  if (!Boolean(menuItems?.length > 0)) return null;

  return (
    <div
      ref={contextMenuRef}
      style={{
        display: contextMenuData ? 'flex' : 'none',
        top: position_top || 0,
        left: position_left || 0,
        maxHeight: maxHeight || 'auto',
        overflowY: 'auto'
      }}
      className="z-[1400] min-w-[200px] isolate bg-white p-1 rounded shadow-lg w-max h-auto fixed flex flex-col"
    >
      {menuItems.map((menu) => cloneElement(menu, { key: nanoid(), closeMenu }))}
    </div>
  );
}
