const LeaseTranslations = (configuration, { type }) => ({
  pick: 'entityLeasePick',
  entityName: 'entityLeaseName',
  createDialog: 'entityLeaseCreateDialog',
  updateDialog: 'entityLeaseUpdateDialog',
  create: 'entityLeaseCreate',
  update: 'entityLeaseUpdate',
  noResultLabel: 'entityLeasenoResultLabel',
  searchText: 'entityLeaseSearchText'
});

export default LeaseTranslations;
