import useIsVisible from 'hooks/useIsVisible';
import { useRef, useEffect } from 'react';

export default function InfiniteLoader({
  fetchedCount = 0,
  fetchMore = () => {},
  isLoading,
  totalCount
}) {
  const ref = useRef();
  const isVisible = useIsVisible(ref);

  useEffect(() => {
    const hasSome = fetchedCount > 0;
    const hasMore = fetchedCount < totalCount;
    const shouldFetchMore = hasSome && hasMore && isVisible && !isLoading;

    if (shouldFetchMore) fetchMore({ skip: fetchedCount });
  }, [fetchedCount, isVisible, totalCount, isLoading]);

  return <div ref={ref} />;
}
