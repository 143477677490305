import CustomerFilter from './CustomerFilter';

import EntityWrapper from 'entities/EntityWrapper';
import { useState, useMemo } from 'react';
import { useDocumentListener } from 'react-events-utils';
import { nanoid } from 'nanoid';

export default function Customer(props) {
  const [tab, setTab] = useState('clients');
  const tabChangedId = useMemo(() => nanoid());

  useDocumentListener('tabChanged' + tabChangedId, (e) => {
    setTab(e.detail);
  });

  return (
    <EntityWrapper
      entity={'clients'}
      tabChangedId={tabChangedId}
      disableCreate={true}
      getFilters={CustomerFilter}
      tab={tab}
      formatForm={() => []}
      formatFilter={() => []}
      getInitialValues={() => []}
      getFormValidation={() => []}
      getTabs={() => []}
      getTables={() => []}
      getMenus={() => []}
      getExportColumns={() => []}
      getTranslations={() => ({})}
      getSorts={() => []}
      {...props}
    />
  );
}
