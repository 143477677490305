const LocationExport = (configuration) =>
  [
    { show: true, label: 'Référence', key: 'number' },
    { show: true, label: 'Nom', key: 'name' },
    { show: true, label: 'Pays', key: 'country' },
    { show: true, label: 'Adresse', key: 'address' },
    { show: configuration.location_sectors, label: 'Secteur', key: '_sector' },
    { show: true, label: 'Ville', key: 'city' },
    { show: true, label: 'Code postal', key: 'postalCode' },
    { show: true, label: 'Numéro de téléphone', key: 'contact_phone' },
    { show: true, label: 'Email', key: 'contact_email' }
  ].filter((e) => e.show);

export default LocationExport;
