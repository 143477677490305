const ReportFilters = (configuration, { customFields }) =>
  [
    {
      key: '_typologies',
      type: 'Entity',
      hidden: true,
      show: true,
      default: []
    },
    {
      key: '_locations',
      type: 'Entity',
      hidden: true,
      show: true,
      default: []
    },
    {
      key: '_jobs',
      type: 'Entity',
      hidden: true,
      show: true,
      default: []
    },
    {
      label: 'field',
      key: 'fields',
      type: 'Field',
      params: {},
      show: customFields?.some((section) => !!section.fields?.length),
      disabled: false,
      default: []
    }
  ].filter((e) => e.show);

export const formatFilter = (filters) => filters;

export default ReportFilters;
