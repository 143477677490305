import Ticket from 'entities/Ticket/Ticket';

const MaintenanceTabs = (configuration, element) =>
  [
    {
      label: 'entityTicketName',
      icon: 'files',
      count: 0,
      show: true,
      render: () => (
        <Ticket
          disableGutters
          defaultFilters={{ preventifs: [element] }}
        />
      ),
      class: 'tabNoPadding'
    }
  ].filter((e) => e.show);

export default MaintenanceTabs;
