import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: ({ page, filter, list, timeline, calendar }) => ({
    borderRadius:
      timeline || calendar
        ? '0px 0px 8px 8px'
        : page
          ? '16px 16px 0px 0px'
          : filter
            ? theme.radius
            : 0,
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 7,
    position: 'relative'
  }),
  listContainer: ({ isLoading }) => ({
    position: 'relative',
    overflow: isLoading ? 'hidden' : 'visible',
    background: 'white',
    flexGrow: 1
  }),
  listLoading: {
    height: '100%',
    maxHeight: '100%',
    position: 'absolute',
    width: '100%',
    overflow: 'hidden'
  },
  noResult: ({ isList, picker }) => ({
    height: '100%',
    background: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  }),
  noResultIconSmall: {
    color: theme.palette.primary.light,
    fontSize: 34,
    marginRight: 10
  },
  noResultIcon: {
    color: theme.palette.primary.light,
    fontSize: 80,
    marginBottom: 24
  },
  noResultLabel: {
    fontSize: 18,
    display: 'flex',
    alignItems: 'center',
    marginTop: 16,
    marginBottom: 16,
    color: theme.palette.primary.dark
  },
  noResultText: {
    fontSize: 12,
    width: 320,
    marginBottom: 24,
    color: theme.palette.grey['500'],
    textAlign: 'center'
  },
  noResultButton: {},
  noResultButtonIcon: {
    marginLeft: 64
  },
  moreMenu: {
    minWidth: 180
  }
}));
