import { MenuItem, Select, Typography } from '@material-ui/core';
import { Field } from 'frmx';
import useKeys from '@flowsn4ke/usekeys';
import { useTranslation } from 'react-i18next';
import FieldBase from './FieldBase';

export default function TitleField() {
  const { t } = useTranslation();
  const k = useKeys();
  return (
    <FieldBase>
      <Field path="extra.size">
        <Select
          variant="outlined"
          fullWidth
        >
          {new Array(6).fill(null).map((_, i) => {
            const value = i + 1;
            return (
              <MenuItem
                key={k(i)}
                value={value}
              >
                <Typography variant={`h${value}`}>
                  {t('size')} {value}
                </Typography>
              </MenuItem>
            );
          })}
        </Select>
      </Field>
    </FieldBase>
  );
}
