const RoleFormValues = (configuration) => ({
  name: '',
  type: null,
  permissions: {},
  notification_preferences: {}
});

export const RoleValidation = (configuration) => ({
  name: (name) => !!name?.trim()?.length,
  type: (type) => type && !!type?.trim()?.length
});

export default RoleFormValues;
