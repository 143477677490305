export default function getFileIconFromMimeType(mimetype) {
  switch (true) {
    case /(epub|ebook)/.test(mimetype):
      return 'book';
    case /image/.test(mimetype):
      return 'file-image';
    case /audio/.test(mimetype):
      return 'file-music';
    case /pdf/.test(mimetype):
      return 'file-pdf';
    case /video/.test(mimetype):
      return 'file-video';
    case /font/.test(mimetype):
      return 'font';
    case /calendar/.test(mimetype):
      return 'calendar';
    case /(zip|rar|tar|freearc|7z|bzip)/.test(mimetype):
      return 'file-zipper';
    case /(csv|sheet|excel)/.test(mimetype):
      return 'file-chart-column';
    case /(presentation|powerpoint)/.test(mimetype):
      return 'file-powerpoint';
    case /(abiword|rtf|text|word|openxml)/.test(mimetype):
      return 'file-lines';
    case /(java|javascript|php|json|octet|csh|xml|html|css|flash)/.test(mimetype):
      return 'file-code';
    case /directory/.test(mimetype):
      return 'folder-closed';
    default:
      return 'file';
  }
}
