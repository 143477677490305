import { Dialog, DialogActions, Box, TextField, Button } from '@material-ui/core';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function ReasonDialog({ open, onClose, onConfirm, text }) {
  const close = (e) => [e.preventDefault(), e.stopPropagation(), onClose()];
  const [reason, setReason] = useState('');

  useEffect(() => setReason(''), [open]);

  const { t } = useTranslation();

  return (
    <Dialog
      fullWidth
      maxWidth={'sm'}
      disableEnforceFocus
      open={open}
      onClose={(e) => close(e)}
      onMouseDown={(e) => e.stopPropagation()}
      onClick={(e) => e.stopPropagation()}
    >
      <Box padding={'16px'}>
        <TextField
          fullWidth
          multiline
          rows={5}
          label={text || t('possibleRemarks')}
          placeholder={text || t('possibleRemarks')}
          value={reason}
          variant="outlined"
          onChange={(e) => setReason(e.target.value)}
        />
      </Box>
      <DialogActions>
        <Button
          onClick={close}
          color="secondary"
        >
          Annuler
        </Button>
        <Button
          onClick={(e) => [onConfirm(reason), close(e)]}
          color="primary"
        >
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
