import {
  AccordionActions,
  AccordionDetails,
  Button,
  Typography,
  TextField,
  InputAdornment,
  Grid,
  makeStyles,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Box
} from '@material-ui/core';

import { useAuth } from 'hooks/useAuth';
import { Submit, Field, FieldArray, useFieldObserver } from 'frmx';

import AccordionForm from 'components/forms/AccordionForm';
import { useTranslation } from 'react-i18next';
import FAIcon from 'components/ui/FAIcon';
import useNotifications from 'hooks/useNotifications';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import { setPricingBenefits } from 'store/authSlice';

import { IconButton } from 'react-file-utils';
import useKeys from '@flowsn4ke/usekeys';
import { useConfiguration } from 'hooks/useConfiguration';

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

export default function MarketplacePricing({ section }) {
  const notify = useNotifications();
  const { t } = useTranslation();
  const auth = useAuth();
  const company = auth.interface._company;

  const config = useConfiguration();

  const dispatchCallbacks = {
    onSuccess: () => notify.success(),
    onError: () => notify.error()
  };

  const { dispatch } = useAsyncDispatch();

  const schemaValidation = {
    pricing: {
      travelWeek: (value) => value >= 0,
      travelEvening: (value) => value >= 0,
      travelWeekend: (value) => value >= 0,
      laborWeek: (value) => value >= 0,
      laborEvening: (value) => value >= 0,
      laborWeekend: (value) => value >= 0
    },
    benefits: {
      job: (value) => !!value && value.length,
      description: (value) => !!value && value.length,
      price: (value) => value >= 0
    }
  };

  return (
    <AccordionForm
      defaultClose
      hide={!company?.marketplace}
      section={section}
      initialValues={{
        isPricingBenefitsActive: config?.isPricingBenefitsActive || false,
        pricing: {
          travelWeek: config.pricing?.travelWeek || 0,
          travelEvening: config.pricing?.travelEvening || 0,
          travelWeekend: config.pricing?.travelWeekend || 0,
          laborWeek: config.pricing?.laborWeek || 0,
          laborEvening: config.pricing?.laborEvening || 0,
          laborWeekend: config.pricing?.laborWeekend || 0
        },
        benefits: config?.benefits || [
          { job: '', description: '', price: 0, travel: false, labor: false, parts: false }
        ]
      }}
      schemaValidation={schemaValidation}
      onSubmit={(data) => dispatch(setPricingBenefits, data, dispatchCallbacks)}
    >
      <AccordionDetails>
        <MarketplacePricingFields />
      </AccordionDetails>

      <AccordionActions>
        <Button size="small">{t('cancel')}</Button>

        <Submit>
          <Button
            size="small"
            color="primary"
          >
            {t('save')}
          </Button>
        </Submit>
      </AccordionActions>
    </AccordionForm>
  );
}

function MarketplacePricingFields() {
  const auth = useAuth();
  const company = auth.interface._company;

  const classes = useStyles();
  const isPricingBenefitsActive = useFieldObserver('isPricingBenefitsActive');
  const { t } = useTranslation();
  const k = useKeys();

  return (
    <Grid
      container
      spacing={2}
    >
      <Grid
        item
        xs={12}
      >
        <FormControlLabel
          control={
            <Field
              path="isPricingBenefitsActive"
              type="checkbox"
            >
              <Checkbox />
            </Field>
          }
          label={t('activatePricingModule')}
        />
        <FormHelperText>{t('pricingModuleHelperText')}</FormHelperText>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Typography
          variant="h5"
          color="primary"
        >
          {t('ratesExcludeVAT')}
        </Typography>
      </Grid>
      <Grid
        item
        xs={4}
        className={classes.title}
      >
        <Typography variant="h6">{t('weekDaysTitle')}</Typography>
      </Grid>
      <Grid
        item
        xs={4}
      >
        <Field
          path="pricing.travelWeek"
          type="number"
          isErrorProp="error"
          disabled={!isPricingBenefitsActive}
        >
          <TextField
            variant="outlined"
            label={t('travelTitle') + ' ' + t('weekTitle')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <FAIcon
                    size={'small'}
                    collection="fal"
                    icon={company?.currency?.icon || 'euro-sign'}
                  />
                </InputAdornment>
              )
            }}
          />
        </Field>
      </Grid>
      <Grid
        item
        xs={4}
      >
        <Field
          path="pricing.laborWeek"
          type="number"
          disabled={!isPricingBenefitsActive}
        >
          <TextField
            variant="outlined"
            label={t('laborTitle') + ' ' + t('weekTitle')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <FAIcon
                    size={'small'}
                    collection="fal"
                    icon={company?.currency?.icon || 'euro-sign'}
                  />
                </InputAdornment>
              )
            }}
          />
        </Field>
      </Grid>
      <Grid
        item
        xs={4}
        className={classes.title}
      >
        <Typography variant="h6">{t('eveningTitle')}</Typography>
      </Grid>
      <Grid
        item
        xs={4}
      >
        <Field
          path="pricing.travelEvening"
          type="number"
          isErrorProp="error"
          disabled={!isPricingBenefitsActive}
        >
          <TextField
            variant="outlined"
            label={t('travelTitle') + ' ' + t('evening')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <FAIcon
                    size={'small'}
                    collection="fal"
                    icon={company?.currency?.icon || 'euro-sign'}
                  />
                </InputAdornment>
              )
            }}
          />
        </Field>
      </Grid>
      <Grid
        item
        xs={4}
      >
        <Field
          path="pricing.laborEvening"
          type="number"
          isErrorProp="error"
          disabled={!isPricingBenefitsActive}
        >
          <TextField
            variant="outlined"
            label={t('laborTitle') + ' ' + t('evening')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <FAIcon
                    size={'small'}
                    collection="fal"
                    icon={company?.currency?.icon || 'euro-sign'}
                  />
                </InputAdornment>
              )
            }}
          />
        </Field>
      </Grid>
      <Grid
        item
        xs={4}
        className={classes.title}
      >
        <Typography variant="h6">{t('weekendAndPublicHolidaysTitle')}</Typography>
      </Grid>
      <Grid
        item
        xs={4}
      >
        <Field
          path="pricing.travelWeekend"
          type="number"
          isErrorProp="error"
          disabled={!isPricingBenefitsActive}
        >
          <TextField
            variant="outlined"
            label={t('travelTitle') + ' ' + t('weekendAndPublicHolidaysTitle')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <FAIcon
                    size={'small'}
                    collection="fal"
                    icon={company?.currency?.icon || 'euro-sign'}
                  />
                </InputAdornment>
              )
            }}
          />
        </Field>
      </Grid>
      <Grid
        item
        xs={4}
      >
        <Field
          path="pricing.laborWeekend"
          type="number"
          isErrorProp="error"
          disabled={!isPricingBenefitsActive}
        >
          <TextField
            variant="outlined"
            label={t('laborTitle') + ' ' + t('weekendAndPublicHolidaysTitle')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <FAIcon
                    size={'small'}
                    collection="fal"
                    icon={company?.currency?.icon || 'euro-sign'}
                  />
                </InputAdornment>
              )
            }}
          />
        </Field>
      </Grid>
      <Grid
        item
        xs={4}
      >
        <Typography
          variant="h5"
          color="primary"
        >
          {t('servicesOffered')}
        </Typography>
      </Grid>
      <FieldArray
        path="benefits"
        model={{ job: '', description: '', price: 0, travel: false, labor: false, parts: false }}
      >
        {({ path, items, addItem, removeItem }) => (
          <>
            {items.map((item, i) => (
              <Grid
                item
                xs={12}
                key={k(i)}
              >
                <Grid
                  container
                  spacing={1}
                >
                  <Grid
                    item
                    xs={8}
                    md={2}
                  >
                    <Field
                      path={`${path}.${i}.job`}
                      isErrorProp="error"
                    >
                      <TextField
                        style={{ marginRight: '1em' }}
                        variant="outlined"
                        fullWidth
                        label={t('job')}
                      />
                    </Field>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={3}
                  >
                    <Field
                      path={`${path}.${i}.description`}
                      isErrorProp="error"
                    >
                      <TextField
                        style={{ marginRight: '1em' }}
                        variant="outlined"
                        fullWidth
                        label={t('description')}
                      />
                    </Field>
                  </Grid>

                  <Grid
                    item
                    xs={2}
                  >
                    <Field
                      path={`${path}.${i}.price`}
                      type="number"
                      isErrorProp="error"
                    >
                      <TextField
                        style={{ marginRight: '1em' }}
                        variant="outlined"
                        label={t('price')}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <FAIcon
                                size={'small'}
                                collection="fal"
                                icon={company?.currency?.icon || 'euro-sign'}
                              />
                            </InputAdornment>
                          )
                        }}
                      />
                    </Field>
                  </Grid>

                  <Grid item>
                    <FormControlLabel
                      control={
                        <Field
                          path={`${path}.${i}.travel`}
                          type="checkbox"
                        >
                          <Checkbox />
                        </Field>
                      }
                      label={t('travelTitle')}
                      labelPlacement="top"
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Field
                          path={`${path}.${i}.labor`}
                          type="checkbox"
                        >
                          <Checkbox />
                        </Field>
                      }
                      label={t('laborTitle')}
                      labelPlacement="top"
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Field
                          path={`${path}.${i}.parts`}
                          type="checkbox"
                        >
                          <Checkbox />
                        </Field>
                      }
                      label={t('partsTitle')}
                      labelPlacement="top"
                    />
                  </Grid>

                  <Grid
                    item
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <IconButton onClick={() => removeItem(i)}>
                      <FAIcon
                        collection="fal"
                        icon="trash-can"
                      />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            ))}

            <Grid
              item
              xs={12}
            >
              <Box style={{ width: 'full', textAlign: 'center', margin: '1em 0' }}>
                <Button
                  endIcon={
                    <FAIcon
                      collection="fal"
                      icon="circle-plus"
                    />
                  }
                  variant="contained"
                  onClick={() => addItem()}
                >
                  {t('addService')}
                </Button>
              </Box>
            </Grid>
          </>
        )}
      </FieldArray>
    </Grid>
  );
}
