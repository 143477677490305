import MenuItem from 'components/menu/MenuItem.js';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import { useEffect } from 'react';
import { getElements as getInterveners } from 'store/intervenersSlice';
import { getBookmarks, newTicketList } from 'store/ticketsSlice.js';
import { formatFilter } from './baseFilters.js';
import { transformOldSystemToNew } from './utils.js';

const KEEP_FILTERS = ['sort', 'order', 'search', 'limit', 'skip', 'bookmarks'];

// renvoyer seulement sort, search, limit, skip;
// TODO: à supprimer après avoir bien testé
const getOnlyNecessaryFilters = (filters) => {
  //filters= {key: value, key2: value2}
  const onlyNecessaryFilters = Object.keys(filters).filter((key) => KEEP_FILTERS.includes(key));
  return onlyNecessaryFilters.reduce((acc, curr) => ({ ...acc, [curr]: filters[curr] }), {});
};

export const fetchTickets = (auth, setData, setLoading, dispatch) => {
  return async (filters) => {
    setLoading(true);
    // TODO: à supprimer apres avoir bien test
    // filters = getOnlyNecessaryFilters(filters);

    const formattedFilters = formatFilter(filters, auth);

    try {
      await dispatch(
        newTicketList,
        { filters: formattedFilters },
        {
          onSuccess: (data) => setData(data.tickets)
        }
      );
    } catch {
      setData([]);
    } finally {
      setLoading(false);
    }
  };
};

export const useFilteredBookmarks = (calendar, appliedFilters, setBookmarkState, bookmarkState) => {
  const { dispatch } = useAsyncDispatch();

  useEffect(() => {
    const fetchBookmarks = async () => {
      setBookmarkState({ ...bookmarkState, isLoading: true });
      const initialBookmarks = bookmarkState.bookmarks;

      if (!calendar) {
        dispatch(
          getBookmarks,
          { filters: appliedFilters },
          {
            onSuccess: (data) => {
              const filteredKeys = Object.keys(data.bookmarks).filter((key) => data.bookmarks[key]);
              setBookmarkState({
                bookmarks: initialBookmarks.filter((bm) => filteredKeys.includes(bm.key)),
                isLoading: false
              });
            }
          }
        );
      } else {
        dispatch(getInterveners, {
          limit: { startIndex: 0, stopIndex: 30 },
          filters: { tab: { collaborator: true }, deleted: false, sort: { sort: 1, value: 'companyName' } }
        }).then(({ data }) => {
          setBookmarkState({
            bookmarks: [
              initialBookmarks[0],
              ...data.elements?.slice(0, 9).map(({ _id, color, firstName, lastName }) => ({
                label: `${firstName} ${lastName ? lastName[0].toUpperCase() + '.' : ''}`,
                key: _id,
                color
              }))
            ],
            isLoading: false
          });
        });
      }
    };

    fetchBookmarks();
  }, []);
};

export const createFilterSetsManager = (appliedFilters) => ({
  filterSets: [
    {
      title: 'defaultFilterSet',
      filters: transformOldSystemToNew({ filters: appliedFilters }),
      _id: '123'
    }
  ],
  selectedSet: {
    title: 'defaultFilterSet',
    filters: transformOldSystemToNew({ filters: appliedFilters }),
    _id: '123'
  },
  orderFilters: Object.keys(appliedFilters)
});

export const generateFilters = (filterSetsManager, showableFilters) =>
  filterSetsManager.orderFilters
    .map((path) => showableFilters.find((f) => f.props.path === path))
    .filter(Boolean)
    .map(({ Component, props }) => (
      <Component
        key={props.label}
        {...props}
      />
    ));

export const createMenuItems = (t, fetchTickets, appliedFilters) => [
  <MenuItem
    icon="rotate-right"
    label="refresh"
    onClick={() => fetchTickets(appliedFilters)}
  />,
  <MenuItem
    icon="download"
    label={t('ExportXEls', { count: 10 })}
    onClick={() => console.log('export tickets')}
  />,
  <MenuItem
    icon="trash"
    label="trash"
    onClick={() => console.log('Show trash')}
  />
];
