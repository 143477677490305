import { ListSubheader, MenuItem, Select } from '@material-ui/core';
import { useField } from 'frmx';
import { useSections } from 'hooks/useSections';
import { useMemo } from 'react';
import FieldBase from './FieldBase';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'hooks/useAuth';

export const preFilledValues = [
  { key: 'section', label: 'ticket' },
  { key: '_client.name', label: 'clientName', entity: 'ticket' },
  { key: 'title', label: 'ticketTitle', entity: 'ticket' },
  { key: 'number', label: 'ticketNumber', entity: 'ticket' },
  { key: 'description', label: 'description', entity: 'ticket' },
  { key: 'visit_date', label: 'visitDate', entity: 'ticket' },
  { key: 'intervention_date', label: 'interventionDate', entity: 'ticket' },
  { key: 'custom', entity: 'ticket' },
  { key: 'section', label: 'location' },
  { key: 'type', label: 'type', entity: 'location' },
  { key: 'address.fullAddress', label: 'address', entity: 'location' },
  { key: 'address.postalCode', label: 'zipCode', entity: 'location' },
  { key: 'name', label: 'locationName', entity: 'location' },
  { key: '_facturation.address.fullAddress', label: 'billingAddress', entity: 'location' },
  { key: 'isSameFacturation', label: 'identicalBillingAddress', entity: 'location' },
  { key: 'isPrio', label: 'priorityLocation', entity: 'location' },
  { key: 'custom', entity: 'location' },
  { key: 'informations', label: 'informations', entity: 'location' },
  { key: 'section', label: 'job' },
  { key: 'name', label: 'jobName', entity: 'job' },
  { key: 'section', label: 'equipment', entity: 'equipment' },
  { key: 'name', label: 'equipmentName', entity: 'equipment' },
  { key: 'state', label: 'state', entity: 'equipment' },
  { key: 'brand', label: 'brand', entity: 'equipment' },
  { key: 'model', label: 'model', entity: 'equipment' },
  { key: 'serial', label: 'serialNumber', entity: 'equipment' },
  { key: 'revision_date', label: 'revisionDate', entity: 'equipment' },
  { key: 'replacement_date', label: 'planned-replacement-date', entity: 'equipment' },
  { key: 'year', label: 'year', entity: 'equipment' },
  { key: 'buy_date', label: 'purchaseDate', entity: 'equipment' },
  { key: 'custom', entity: 'equipment' },
  { key: 'section', label: 'subContractor2' },
  { key: 'companyName', label: 'companyName', entity: 'contractor' },
  { key: 'siret', label: 'siret', entity: 'contractor' },
  { key: 'email', label: 'email', entity: 'contractor' },
  { key: 'phone', label: 'cellphoneNumber', entity: 'contractor' },
  { key: 'fix', label: 'landlineNumber', entity: 'contractor' },
  { key: 'address', label: 'address', entity: 'contractor' },
  { key: 'custom', entity: 'contractor' },
  { key: 'section', label: 'usersTitle' },
  { key: 'lastName', label: 'lastName', entity: 'contact' },
  { key: 'firstName', label: 'firstName', entity: 'contact' },
  { key: 'email', label: 'email', entity: 'contact' },
  { key: 'phone', label: 'phoneNumber', entity: 'contact' },
  { key: 'custom', entity: 'user' },
  { key: 'section', label: 'client' },
  { key: 'ref', label: 'reference', entity: 'client' },
  { key: 'lastName', label: 'personName', entity: 'client' },
  { key: 'firstName', label: 'personFirstName', entity: 'client' },
  { key: 'name', label: 'companyName', entity: 'client' },
  { key: 'email', label: 'email', entity: 'client' },
  { key: 'phone', label: 'cellphoneNumber', entity: 'client' },
  { key: 'fix', label: 'landlineNumber', entity: 'client' },
  { key: 'custom', entity: 'client' },
];

export default function PreFilledValuesFields() {
  const sections = useSections();
  const isClient = useAuth().interface.isClient;

  const { value: extra, setValue: setExtra } = useField('extra');
  const { t } = useTranslation();

  const clientSectionStartIndex = preFilledValues.findIndex(
    (el) => el.key === 'section' && el.label === 'client'
  );
  const clientValues = preFilledValues
    .slice(0, clientSectionStartIndex)
    .filter((el) => el.key !== '_client.name');

  const options = useMemo(
    () =>
      (isClient ? clientValues : preFilledValues).reduce((acc, curr) => {
        if (curr.key === 'section') {
          return { ...acc, [nanoid()]: { path: 'section', label: curr.label } };
        } else if (curr.key === 'custom') {
          // map for curr.entity sections

          const customFields = sections[curr.entity].reduce((fieldsAcc, section) => {
            const next = [...fieldsAcc];
            section.fields.forEach((field, i) => {
              next.push({
                path: field._id,
                label: field.label,
                entity: curr.entity,
                _section: section._id
              });
            });
            return next;
          }, []);

          const next = { ...acc };

          customFields.forEach((formattedField, i) => {
            next[nanoid()] = formattedField;
          });

          return next;
        } else {
          return {
            ...acc,
            [nanoid()]: {
              path: curr.key,
              entity: curr.entity,
              label: curr.label
            }
          };
        }
      }, {}),
    [sections]
  );

  return (
    <FieldBase lineSizeOption>
      <Select
        fullWidth
        variant="outlined"
        value={extra}
        onChange={(e) =>
          setExtra((prev) => {
            const val = e.target.value;
            const next = {
              ...prev,
              path: val?.path,
              entity: val?.entity,
              _section: val?._section ? val._section : '5e3ab9082bf0db004d908642',
              isDefault: val?._section ? false : true
            };

            return next;
          })
        }
        renderValue={(s) => {
          const selected = Object.keys(options).find((k) => {
            const o = options[k];
            return o.path === s.path && o.entity === s.entity;
          });
          return t(options[selected]?.label) || '';
        }}
      >
        {Object.keys(options).map((optionId) => {
          const field = options[optionId];

          return field.path === 'section' ? (
            <ListSubheader
              inset
              disableSticky
              key={optionId}
            >
              {' '}
              {t(field.label)}{' '}
            </ListSubheader>
          ) : (
            <MenuItem
              key={optionId}
              value={field}
            >
              {' '}
              {t(field.label)}{' '}
            </MenuItem>
          );
        })}
      </Select>
    </FieldBase>
  );
}
