import { Box, Button, Divider, Dialog, Checkbox, FormControlLabel } from '@material-ui/core';

import FAIcon from 'components/ui/FAIcon';
import { Form, useForm, Field } from 'frmx';
import { useMemo } from 'react';
import { useContract } from 'contexts/entities/contractContext';
import useStyles from 'layouts/entities/View.styles';

import Intervener from 'entities/Intervener/Intervener';

import { useConfiguration } from 'hooks/useConfiguration';

import { updateElement, assignIntervener, proposeIntervener } from 'store/ticketsSlice';
import { useTranslation } from 'react-i18next';

//Prop locationsPrio used to star the ContractorPreview
export default function IntervenerPicker({ locationsPrio }) {
  const {
    setIntervenerPicker,
    intervenerPicker,
    dispatch,
    order: { _id },
    contract: { _summons }
  } = useContract();

  const { assign_sms, assign_email } = useConfiguration();

  return (
    <Dialog
      maxWidth={'md'}
      fullWidth
      PaperProps={{}}
      disableEnforceFocus
      open={!!intervenerPicker}
      onClose={() => [setIntervenerPicker(false)]}
    >
      {
        <Form
          style={{ width: '100%', height: 600 }}
          disableIfNoUpdates
          initialValues={{
            _summons,
            email: assign_email,
            sms: assign_sms
          }}
          onSubmit={(form) => {
            if (intervenerPicker.technician) {
              dispatch(
                updateElement,
                { _summons: form._summons.map((s) => s._id) },
                {
                  onSuccess: () => setIntervenerPicker(false)
                },
                { id: _id }
              );
            } else if (intervenerPicker.type === 'assign') {
              let _interveners;
              if (form._summons.filter((s) => s.isContractor)?.length > 1) {
                _interveners = [form._summons.filter((s) => s.isContractor)[0]].map((s) => s._id);
              } else {
                _interveners = form._summons.map((s) => s._id);
              }
              const bobdepannage = !!_interveners.find((id) => id === '5d9b53a549192f00350ccdef');
              dispatch(
                assignIntervener,
                {
                  _interveners,
                  ticket: {
                    bobdepannage,
                    fromProposition: false,
                    notice: {
                      email: form.email,
                      sms: form.sms
                    }
                  }
                },
                {
                  onSuccess: () => setIntervenerPicker(false)
                },
                { id: _id }
              );
            } else {
              const bobdepannage = !!form._summons
                .map((s) => s._id)
                .find((id) => id === '5d9b53a549192f00350ccdef');
              dispatch(
                proposeIntervener,
                {
                  _interveners: form._summons
                    .map((s) => s._id)
                    .filter((s) => s !== '5d9b53a549192f00350ccdef'),
                  ticket: {
                    bobdepannage,
                    fromProposition: false,
                    notice: {
                      email: form.email,
                      sms: form.sms
                    }
                  }
                },
                {
                  onSuccess: () => setIntervenerPicker(false)
                },
                { id: _id }
              );
            }
          }}
        >
          <IntervenerPickerForm
            locationsPrio={locationsPrio}
            close={() => setIntervenerPicker(false)}
          />
        </Form>
      }
    </Dialog>
  );
}

function IntervenerPickerForm({ close, locationsPrio }) {
  const { t } = useTranslation();
  const {
    order: { _jobs, _locations },
    isLoading,
    intervenerPicker
  } = useContract();
  const { handleSubmit } = useForm();

  const defaultFilters = useMemo(
    () => ({
      jobs: _jobs,
      zone_ids: _locations.map((l) => l?.zone_ids || []).flat()
    }),
    [_jobs, _locations]
  );

  const classes = useStyles();
  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
    >
      <Box flexGrow={1}>
        <Intervener
          intervenerPicker={intervenerPicker}
          intervenerPickerType={intervenerPicker.technician ? 'technician' : intervenerPicker.type}
          defaultFilters={defaultFilters}
          tabViewAll
          disableShadow
          locationsPrio={locationsPrio}
          picker
          pickerField="_summons"
        />
      </Box>
      <Divider />
      <Box
        display="flex"
        justifyContent="flex-end"
        padding="10px 6px"
      >
        {!intervenerPicker.technician && (
          <>
            <FormControlLabel
              disabled={isLoading}
              control={
                <Field
                  path="sms"
                  type="checkbox"
                >
                  <Checkbox />
                </Field>
              }
              label="SMS"
            />

            <FormControlLabel
              disabled={isLoading}
              control={
                <Field
                  path="email"
                  type="checkbox"
                >
                  <Checkbox />
                </Field>
              }
              label="Email"
            />
          </>
        )}

        {intervenerPicker.type === 'proposition' && (
          <Button
            disabled={isLoading}
            onClick={() => handleSubmit()}
            endIcon={
              <FAIcon
                collection="fal"
                icon="mailbox"
                className={classes.viewActionIcon}
              />
            }
            color="secondary"
            variant="outlined"
            className={classes.viewAction}
          >
            {t('propose')}
          </Button>
        )}

        {intervenerPicker.type === 'assign' && (
          <Button
            disabled={isLoading}
            onClick={() => handleSubmit()}
            endIcon={
              <FAIcon
                collection="fal"
                icon="user-hard-hat"
                className={classes.viewActionIcon}
              />
            }
            color="secondary"
            variant="outlined"
            className={classes.viewAction}
          >
            {t('assign')}
          </Button>
        )}
      </Box>
    </Box>
  );
}
