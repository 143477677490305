import {
  Box,
  Grid,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButton
} from '@material-ui/core';
import { ThumbDownAlt, ThumbUpAlt, Notifications, MoreVertOutlined } from '@material-ui/icons';
import FAIcon from 'components/ui/FAIcon';
import { ContractorItem, TechnicianItem } from 'components/views/ViewItems';
import { useContract } from 'contexts/entities/contractContext';
import { useIsBelowMd } from 'hooks/useMQ';
import { useRole } from 'hooks/useRole';
import { contractStates } from 'lists/contractStates';
import { useState } from 'react';
import { renoticeIntervener, cancelIntervener, assignIntervener } from 'store/ticketsSlice';
import { IntervenerItemPrimary } from './ContractContractor';

import useNotifications from 'hooks/useNotifications';
import ConfirmDialog from 'components/dialogs/ConfirmDialog';
import useKeys from '@flowsn4ke/usekeys';
import { useTranslation } from 'react-i18next';

export default function ContractPropositions() {
  const { t } = useTranslation();
  const {
    contract: { _propositions },
    dispatch,
    order
  } = useContract();
  const isBelowMd = useIsBelowMd();
  const role = useRole();

  const notify = useNotifications();

  const [confirmOpen, setConfirmOpen] = useState(null);
  const [propositionMenuIntervener, setPropositionMenuIntervener] = useState(null);

  const [propositionMenuOpen, setPropositionMenuOpen] = useState(null);
  const propositionActions = (intervener) => (
    <Box
      display="flex"
      alignItems="center"
    >
      <IconButton
        size={'small'}
        style={{ marginLeft: 6 }}
        onClick={(e) => [
          e.preventDefault(),
          e.stopPropagation(),
          setPropositionMenuOpen(e.currentTarget),
          setPropositionMenuIntervener(intervener)
        ]}
      >
        <MoreVertOutlined />
      </IconButton>
    </Box>
  );

  const propositionMenu = (
    <Menu
      anchorEl={propositionMenuOpen}
      transitionDuration={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={!!propositionMenuOpen}
      onClose={() => setPropositionMenuOpen(null)}
    >
      {role.permission('tickets', 'assign_ticket') && (
        <MenuItem
          dense
          onClick={(e) => {
            dispatch(
              assignIntervener,
              {
                _interveners: [propositionMenuIntervener._id],
                ticket: {
                  fromProposition: true,
                  notice: {
                    email: false,
                    sms: false
                  }
                }
              },
              {},
              { id: order._id }
            );
            setPropositionMenuOpen(null);
          }}
        >
          <ListItemIcon>
            <ThumbUpAlt style={{ color: '#31b55a' }} />
          </ListItemIcon>
          <ListItemText primary={t('assign')} />
        </MenuItem>
      )}
      <MenuItem
        dense
        onClick={(e) => [setConfirmOpen(true), setPropositionMenuOpen(null)]}
      >
        <ListItemIcon>
          <ThumbDownAlt style={{ color: '#e63d53' }} />
        </ListItemIcon>
        <ListItemText primary={t('cancelProposal')} />
      </MenuItem>
      {role.permission('tickets', 'relance') && (
        <MenuItem
          dense
          onClick={(e) => [
            dispatch(
              renoticeIntervener,
              { _intervener: propositionMenuIntervener._id },
              { onSuccess: () => notify.success() },
              { id: order._id }
            ),
            setPropositionMenuOpen(null)
          ]}
        >
          <ListItemIcon>
            <Notifications />
          </ListItemIcon>
          <ListItemText primary={t('remindProposal')} />
        </MenuItem>
      )}
    </Menu>
  );

  const k = useKeys();
  return (
    <Box
      paddingTop="6px"
      width="100%"
      paddingLeft={isBelowMd ? '0px' : '42px'}
      position="relative"
    >
      {!isBelowMd && (
        <Box
          color="#a9afb5"
          position="absolute"
          top="18px"
          left="10px"
        >
          <FAIcon
            collection="fal"
            icon="mailbox"
          />
        </Box>
      )}

      <Grid container>
        {_propositions?.length &&
          _propositions.map((proposition, i) => (
            <Grid
              key={k(i)}
              item
              xs={12}
              sm={_propositions?.length === 1 ? 12 : 6}
            >
              {proposition._intervener.isContractor ? (
                <ContractorItem
                  primary={<IntervenerItemPrimary />}
                  contractor={proposition._intervener}
                  //TODO Disable menu for marketplace
                  actionsVisible={
                    <Box
                      display="flex"
                      alignItems="center"
                      fontWeight={500}
                      color={contractStates[proposition.state].color}
                    >
                      {t(contractStates[proposition.state].label)}
                      {propositionActions(proposition._intervener)}
                    </Box>
                  }
                />
              ) : (
                <TechnicianItem
                  actionsVisible={
                    <Box
                      display="flex"
                      alignItems="center"
                      fontWeight={500}
                      color={contractStates[proposition.state].color}
                    >
                      {t(contractStates[proposition.state].label)}
                      {propositionActions(proposition._intervener)}
                    </Box>
                  }
                  primary={<IntervenerItemPrimary />}
                  technician={proposition._intervener}
                />
              )}
            </Grid>
          ))}
        {propositionMenu}

        <ConfirmDialog
          text={<span>{t('doYouReallyWantToCancelProposal')}</span>}
          open={confirmOpen}
          onClose={(e) => [setConfirmOpen(false)]}
          onConfirm={() => dispatch(cancelIntervener, { isParent: false }, {}, { id: order._id })}
        />
      </Grid>
    </Box>
  );
}
