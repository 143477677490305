import { useTranslation } from 'react-i18next';
import DateField from 'components/inputs/DateField';
import { useField } from 'frmx';
import { MenuItem, Select } from '@material-ui/core';

export default function DateFilters({ datas }) {
  return (
    <div className="p-3 flex gap-5 flex-col">
      {datas.map((field, i) => (
        <CustomInputDate
          key={i}
          label={field.label}
          value={field.value}
        />
      ))}
    </div>
  );
}

function CustomInputDate({ label, value: key }) {
  const { t } = useTranslation();
  const opPath = `dates.${key}.op`;
  const { value: operator, setValue: setOperator } = useField(opPath);

  const comparisonOperator = [
    { value: 'gt', label: t('greaterThan') },
    { value: 'gte', label: t('greaterThanOrEqualTo') },
    { value: 'lt', label: t('lessThan') },
    { value: 'lte', label: t('lessThanOrEqualTo') },
    { value: 'range', label: t('between') }
  ];

  const handleOperatorChange = (event) => setOperator(event.target.value);

  return (
    <div>
      <div className="text-sm font-semibold text-gray-700 mb-2">{t(label)}</div>
      <div className="flex gap-2">
        <Select
          fullWidth
          id="comparison-operator-label"
          variant={'outlined'}
          value={operator ? operator : 'range'}
          onChange={handleOperatorChange}
        >
          {comparisonOperator.map((op) => (
            <MenuItem
              key={op.value}
              value={op.value}
            >
              {op.label}
            </MenuItem>
          ))}
        </Select>

        {operator === 'range' ? (
          <>
            <div className="col-span-4">
              <DateField
                path={`dates.${key}.values.0`}
                placeholder={t('startDate')}
              />
            </div>
            <div className="col-span-4">
              <DateField
                path={`dates.${key}.values.1`}
                placeholder={t('endDate')}
              />
            </div>
          </>
        ) : (
          <div className="col-span-8">
            <DateField
              path={`dates.${key}.values.0`}
              placeholder={t('date')}
            />
          </div>
        )}
      </div>
    </div>
  );
}
