import {
  AccordionActions,
  AccordionDetails,
  FormHelperText,
  FormControlLabel,
  Divider,
  Checkbox,
  TextField,
  Grid,
  Button,
  Typography,
  Chip,
  makeStyles
} from '@material-ui/core';

import { useAuth } from 'hooks/useAuth';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import useNotifications from 'hooks/useNotifications';
import { setConfiguration } from 'store/authSlice';
import { Submit, Field, Reset, useField } from 'frmx';

import AccordionForm from 'components/forms/AccordionForm';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@material-ui/lab';
import useKeys from '@flowsn4ke/usekeys';
import { isArray } from 'lodash-es';

const useStyles = makeStyles((t) => ({
  popperHide: {
    display: 'none'
  }
}));

export default function AccountingConfiguration({ section }) {
  const auth = useAuth();
  const config = auth.interface._company._configuration;
  const { dispatch, requestStatus } = useAsyncDispatch();
  const notify = useNotifications();
  const { t } = useTranslation();

  const dispatchCallbacks = {
    onSuccess: () => notify.success(),
    onError: () => notify.error()
  };

  return (
    <AccordionForm
      section={section}
      defaultClose
      initialValues={{
        comptability: {
          email:
            (isArray(config?.comptability?.email) ? config?.comptability?.email : [config?.comptability?.email]) || [],
          check_1: config?.comptability?.check_1 || false,
          check_1_obligatory: config?.comptability?.check_1_obligatory || false,
          check_2: config?.comptability?.check_2 || false,
          check_paid_invoices: config?.comptability?.check_paid_invoices || false,
          close_on_send: config?.comptability?.close_on_send || false,
          fusion: config?.comptability?.fusion || false,
          location_accounting: config?.comptability?.location_accounting || false
        }
      }}
      onSubmit={(updates) => dispatch(setConfiguration, updates, dispatchCallbacks)}
    >
      <AccordionDetails>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <EmailsAccounting />
            {/* <Field path="comptability.email" type="email">
            <TextField
              fullWidth
              variant="outlined"
              label={t("accountingEmail")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">

                    <FAIcon
                      collection="fal"
                      icon="at"
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Field>
            <FormHelperText>{t("accountingEmailHelperText")}</FormHelperText>*/}
          </Grid>

          <Grid
            item
            xs={12}
          >
            <Typography variant="subtitle1">{t('activateActionDisplay')}</Typography>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <FormControlLabel
              control={
                <Field
                  path="comptability.check_1"
                  type="checkbox"
                >
                  <Checkbox />
                </Field>
              }
              label={t('validation')}
            />
            <FormHelperText>{t('validationHelperText')}</FormHelperText>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <FormControlLabel
              control={
                <Field
                  path="comptability.check_2"
                  type="checkbox"
                >
                  <Checkbox />
                </Field>
              }
              label={t('preflightCheck')}
            />
            <FormHelperText>{t('preflightCheckHelperText')}</FormHelperText>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <FormControlLabel
              control={
                <Field
                  path="comptability.check_paid_invoices"
                  type="checkbox"
                >
                  <Checkbox />
                </Field>
              }
              label={t('checkPaidInvoices')}
            />
            <FormHelperText>{t('checkPaidInvoicesHelperText')}</FormHelperText>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <FormControlLabel
              control={
                <Field
                  path="comptability.check_1_obligatory"
                  type="checkbox"
                >
                  <Checkbox />
                </Field>
              }
              label={t('mandatoryPreflightCheck')}
            />
            <FormHelperText>{t('mandatoryPreflightCheckHelperText')}</FormHelperText>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <FormControlLabel
              control={
                <Field
                  path="comptability.close_on_send"
                  type="checkbox"
                >
                  <Checkbox />
                </Field>
              }
              label={t('automaticCloseWhenSentToAccounting')}
            />
            <FormHelperText>
              {t(
                config.isBlachere
                  ? 'blachAutomaticCloseWhenSentToAccountingHelperText'
                  : 'automaticCloseWhenSentToAccountingHelperText'
              )}
            </FormHelperText>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <FormControlLabel
              control={
                <Field
                  path="comptability.fusion"
                  type="checkbox"
                >
                  <Checkbox />
                </Field>
              }
              label={t('fusionPdfAccounting')}
            />
            <FormHelperText>{t('groupAllTheDocumentToTheAccountingIntoOne')}</FormHelperText>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <FormControlLabel
              control={
                <Field
                  path="comptability.location_accounting"
                  type="checkbox"
                >
                  <Checkbox />
                </Field>
              }
              label={t('location-accounting')}
            />
            <FormHelperText>{t('location-accounting-description')}</FormHelperText>
          </Grid>
        </Grid>
      </AccordionDetails>
      <Divider />
      <AccordionActions>
        <Reset>
          <Button size="small">{t('cancel')}</Button>
        </Reset>

        <Submit disabled={requestStatus === 'loading'}>
          <Button
            size="small"
            color="primary"
          >
            {t('save')}
          </Button>
        </Submit>
      </AccordionActions>
    </AccordionForm>
  );
}

const EmailsAccounting = () => {
  const path = 'comptability.email';
  const { value: emailsList, setValue: setEmailsList } = useField(path);
  const { t } = useTranslation();
  const k = useKeys();
  const classes = useStyles();

  return (
    <>
      <Autocomplete
        classes={{ popper: classes.popperHide }}
        multiple
        value={emailsList || []}
        onChange={(e, values) => {
          setEmailsList(values);
        }}
        options={[]}
        freeSolo
        renderTags={(values, getTagProps) =>
          emailsList.map((el, index) => (
            <Chip
              key={k(index)}
              label={el}
              variant="outlined"
              size="small"
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={t('accountingEmail')}
          />
        )}
      />
      <FormHelperText>{t('accountingEmailHelperText')}</FormHelperText>
    </>
  );
};
