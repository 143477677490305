import { Box, IconButton } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';

import '@fullcalendar/react';
import { Draggable } from '@fullcalendar/interaction';

import { useIsBelowMd } from 'hooks/useMQ';
import Ticket from 'entities/Ticket/Ticket';

import { useRef, useState } from 'react';

import useStyles from 'layouts/entities/Entity.styles';

import clsx from 'clsx';
import Calendar from 'components/calendar/Calendar';
import { useAuth } from 'hooks/useAuth';
import { INTERFACE_CONTRACTOR, INTERFACE_CLIENT } from 'constants/roles';
import classNames from 'utils/classNames';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FAIcon from 'components/ui/FAIcon';
import EntityCreate from 'layouts/entities/EntityCreate';

export default function CalendarPage(props) {
  const auth = useAuth();
  const isBelowMd = useIsBelowMd();
  const { t } = useTranslation();

  const [expand, setExpand] = useState(false);
  const [calendarRange, setCalendarRange] = useState({
    start: null,
    end: null
  });

  const canPlan =
    !isBelowMd && [INTERFACE_CLIENT, INTERFACE_CONTRACTOR].includes(auth.interface.type);

  const classes = useStyles({ leftPane: canPlan });

  const headerRef = useRef(null);

  const history = useHistory();

  return (
    <>
      <EntityCreate
        in
        noBelowMd
        label={t('goToNewVersion')}
        icon={(className) => (
          <FAIcon
            collection={'fal'}
            icon={'forward-fast'}
            className={className}
          />
        )}
        onClick={() => history.push('/calendar-new')}
      />

      <Box
        className={classes.container}
        style={{ display: 'flex', background: 'transparent' }}
      >
        {canPlan && (
          <Box
            className={clsx({
              [classes.list]: true,
              [classes.listExpandable]: true,
              ['isExpanded']: expand
            })}
          >
            <Box
              width="100%"
              height="100%"
              position="relative"
              className="listContent"
            >
              {
                <Ticket
                  disableCreate
                  Draggable={Draggable}
                  scheduler
                />
              }
              <IconButton
                className="expandBtn"
                onClick={() => setExpand(!expand)}
              >
                <ChevronLeft />
              </IconButton>
            </Box>
          </Box>
        )}

        <Box
          className={classNames(classes.viewWithHeader, 'CalendarContainer')}
          style={{
            padding: isBelowMd ? '10px 10px 0px 10px' : canPlan ? '10px 10px 10px 0px' : '10px',
            flexGrow: 1,
            maxWidth: 'auto',
            height: '100%'
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            boxShadow={isBelowMd ? 0 : 1}
            height="100%"
            borderRadius={isBelowMd ? 0 : '16px 16px 8px 8px'}
          >
            <Box ref={headerRef}></Box>
            <Ticket
              calendarRange={calendarRange}
              calendar
              enableForm
              disableCreate
              listComponent={({ elements, setIsOpenForm, isLoading }) => {
                return (
                  <Calendar
                    expand={expand}
                    isLoading={isLoading}
                    setCalendarRange={setCalendarRange}
                    headeRef={headerRef}
                    setIsOpenForm={setIsOpenForm}
                    elements={elements}
                  />
                );
              }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}
