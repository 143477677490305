const ReportFormValues = (configuration) => ({
  _id: '',
  entity: 'interventionReport',
  label: '',
  client_signature: false,
  contractor_signature: false,
  client_signature_mandatory: false,
  contractor_signature_mandatory: false,
  client_signature_contractor_access: false,
  contractor_signature_client_access: false,
  header_content: '',
  footer_content: '',
  headerLogo: { url: '' },
  footerLogo: { url: '' },
  fields: [],
  _typologies: [],
  _clients: [],
  _equipmentCategories: [],
  _equipmentSubcategories: [],
  _jobs: [],
  _locations: []
});
export default ReportFormValues;

export const ReportValidation = (configuration) => ({});
