import {
  Dialog,
  Divider,
  List,
  ListItem,
  ListItemText,
  Collapse,
  ListItemAvatar,
  Typography
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

import { useState } from 'react';

import useStyles from 'components/dialogs/Dialog.styles';
import Avatar from 'components/Avatar';
import Report from 'entities/Report/Report';
import { Form } from 'frmx';
import FAIcon from 'components/ui/FAIcon';
import { useMemo } from 'react';
import { useAuth } from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';

import DialogTitle from 'components/dialogs/DialogTitle';

export default function SelectReportTemplateDialog({
  selectIsOpen,
  setSelectIsOpen,
  setUploadIsOpen,
  setSelectedTemplate,
  setFormIsOpen,
  ticket,
  company
}) {
  const auth = useAuth();
  const { t } = useTranslation();

  const { _typology, _locations, _jobs, contractCreator, contractParent } = ticket;

  const creatorHiddenFilters = useMemo(
    () =>
      contractCreator && {
        irId: contractCreator._company._id,
        irType: '_' + contractCreator._company.type
      },
    [ticket]
  );

  const parentHiddenFilters = useMemo(
    () =>
      contractParent && {
        irId: contractParent._company._id,
        irType: '_' + contractParent._company.type
      },
    [ticket]
  );

  const ownHiddenFilters = useMemo(
    () => ({
      irId: company._id,
      irType: '_' + company.type
    }),
    [ticket]
  );

  const defaultFilters = useMemo(
    () => ({
      _typologies: _typology,
      _locations,
      _jobs
    }),
    [ticket]
  );

  const c = useStyles();

  return (
    <Dialog
      open={selectIsOpen}
      onClose={() => setSelectIsOpen(false)}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle
        title={t('reportsTitle')}
        onClose={() => setSelectIsOpen(false)}
        className={c.title}
      />
      <Form
        initialValues={{ report: undefined }}
        afterChange={(data) => [
          setSelectedTemplate(data.report),
          setFormIsOpen(true),
          setSelectIsOpen(false)
        ]}
      >
        <List disablePadding>
          {!!contractCreator && auth.interface._company._id !== contractCreator._company._id && (
            <TemplateSection
              avatar={contractCreator._company.avatar}
              name={contractCreator._company.name}
            >
              <Report
                disableGutters
                disableShadow
                picker
                pickerUniq
                pickerField="report"
                defaultFilters={defaultFilters}
                hiddenFilters={creatorHiddenFilters}
              />
            </TemplateSection>
          )}

          {contractParent && !!contractParent?._company?.name && (
            <TemplateSection
              avatar={contractParent._company.avatar}
              name={contractParent._company.name}
            >
              <Report
                disableGutters
                disableShadow
                picker
                pickerUniq
                pickerField="report"
                defaultFilters={defaultFilters}
                hiddenFilters={parentHiddenFilters}
              />
            </TemplateSection>
          )}

          <TemplateSection
            avatar={company.avatar}
            name={company.name}
          >
            <Report
              disableGutters
              disableShadow
              picker
              pickerUniq
              pickerField="report"
              hiddenFilters={ownHiddenFilters}
            />
          </TemplateSection>

          <ListItem
            button
            onClick={() => [setUploadIsOpen(true), setSelectIsOpen(false)]}
          >
            <ListItemAvatar>
              <FAIcon
                icon="file-chart-pie"
                collection="fal"
                style={{ marginLeft: 7, height: 42 }}
              />
            </ListItemAvatar>

            <ListItemText
              primary={
                <Typography
                  variant="subtitle2"
                  component="span"
                >
                  {t('uploadReport')}
                </Typography>
              }
              secondary={t('uploadReportDes')}
            />
          </ListItem>
        </List>
      </Form>
    </Dialog>
  );
}

function TemplateSection({ children, avatar, name }) {
  const { t } = useTranslation();

  const [sectionIsOpen, setSectionIsOpen] = useState(false);

  return (
    <>
      <ListItem
        button
        onClick={() => setSectionIsOpen((p) => !p)}
      >
        <ListItemAvatar>
          <Avatar
            size="small"
            entity={{ name, avatar }}
          />
        </ListItemAvatar>

        <ListItemText
          primary={
            <Typography
              variant="subtitle2"
              component="span"
            >
              {name}
            </Typography>
          }
          secondary={t('seeReportsPermission')}
        />
        {sectionIsOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse
        in={sectionIsOpen}
        mountOnEnter
        unmountOnExit
        timeout="auto"
        style={{ padding: 0 }}
      >
        <List
          component="div"
          disablePadding
          style={{ height: 300 }}
        >
          {children}
        </List>
        <Divider />
      </Collapse>
    </>
  );
}
