const ClientsTranslations = (configuration) => ({
  pick: 'entityClientPick',
  create: 'entityClientCreate',
  update: 'entityClientUpdate',
  createDialog: 'entityClientCreateDialog',
  updateDialog: 'entityClientUpdateDialog',
  createSuccess: 'entityClientCreateSuccess',
  updateSuccess: 'entityClientUpdateSuccess',
  entityName: 'entityClientName',
  noResultLabel: 'entityClientnoResultLabel',
  searchText: 'entityClientSearchText'
});

export default ClientsTranslations;
