import { Container, Box, Paper } from '@material-ui/core';
import logoText from 'images/logo_text.svg';
import logoBoomerang from 'images/logo_boomerang.svg';
import FAIcon from 'components/ui/FAIcon';

import { useUI } from 'hooks/useUI';
import Avatar from 'components/Avatar';

import { useTranslation } from 'react-i18next';

import useStyles from './Splash.styles';

function Splash({ text, noAvatar }) {
  const { t } = useTranslation();
  const ui = useUI();
  const avatar = ui.getAvatar();

  const classes = useStyles();

  return (
    <Container
      className={classes.root}
      maxWidth={false}
    >
      <>
        <Box className={classes.splash}>
          <Paper
            className={classes.paper}
            elevation={3}
          >
            <Box className={classes.top}>
              {!noAvatar && avatar && (
                <Avatar
                  className={classes.avatar}
                  size="large2"
                  entity={{
                    avatar: avatar,
                    name: 'B'
                  }}
                />
              )}
              <img
                className={classes.logo_text}
                src={logoText}
                alt="Bob! Desk"
              />
            </Box>

            <Box className={classes.label}>{text || t('splashLoadingMessage')}</Box>

            <FAIcon
              icon={'spinner-third'}
              className={classes.spinner}
              collection={'fas'}
              size="large"
            />
            <img
              className={classes.logo_boomerang}
              src={logoBoomerang}
              alt="Boomerang Logo"
            />
          </Paper>
        </Box>
      </>
    </Container>
  );
}

export default Splash;
