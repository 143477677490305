import { Checkbox, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { useField } from 'frmx';
import { isArray } from 'lodash-es';

import { useState } from 'react';

export default function AutoCompleteChips({ path, options, label, transform }) {
  const { value, setValue } = useField(path);

  const [autocompleteValue, setAutocompleteValue] = useState(() => {
    const arr = [];

    for (let i = 0; i < options?.length; i++) {
      const isPicked = value.includes(options[i].zone_id);
      if (isPicked) {
        arr.push(options[i]);
      }
    }
    return arr;
  });

  return (
    <Autocomplete
      multiple
      options={isArray(options) ? options : []}
      disableCloseOnSelect
      value={autocompleteValue}
      onChange={(e, newArray) => {
        setAutocompleteValue(newArray);
        setValue(transform && isArray(newArray) ? newArray.map((val) => transform(val)) : newArray);
      }}
      getOptionLabel={(option) => option.label}
      renderOption={(option, { selected }) => (
        <>
          <Checkbox
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.label}
        </>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
        />
      )}
    />
  );
}
