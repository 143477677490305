import { useTranslation } from 'react-i18next';
import { add, isAfter, isDate } from 'date-fns';
import { Field, Form, Reset, Submit } from 'frmx';
import { Button, TextField } from '@material-ui/core';
import Intervener from 'entities/Intervener/Intervener';
import Dialog from 'components/Dialog';
import EntityX from 'components/inputs/EntityX';
import DateTimeField from 'components/inputs/DateTimeField';
import { isObject } from 'lodash-es';
import { useIsBelowSm } from 'hooks/useMQ';

export default function UnavailabilityCreationDialog({
  open_modal_create_unavailability,
  onClose,
  handle_create_unavailability,
  is_submit_unavailability,
  selected_range
}) {
  const { t } = useTranslation();

  const schemaValidation = {
    start: (value) => !!value && isDate(value),
    end: (value, data) => !!value && isDate(value) && isAfter(value, data.start),
    technician: (value) => !!value && isObject(value)
  };

  const isBelowSm = useIsBelowSm();

  return (
    <Dialog
      icon="edit"
      title={t('addUnavailability')}
      width="2xl"
      isOpen={open_modal_create_unavailability}
      onClose={onClose}
    >
      <div className="mt-4">
        <Form
          initialValues={{
            start: !!selected_range.start ? selected_range?.start : new Date(),
            end: !!selected_range.end ? selected_range?.end : add(new Date(), { hours: 2 }),
            description: '',
            technician: ''
          }}
          onSubmit={(data) => [handle_create_unavailability(data), onClose()]}
          disableIfInvalid
          schemaValidation={schemaValidation}
        >
          <div className="mb-1 pt-2 shadow-sm flex justify-between">
            <DateTimeField
              path="start"
              label={t('startUnavailability')}
              fullWidth
              clearable={false}
              required
            />
            <DateTimeField
              path="end"
              label={t('endUnavailability')}
              clearable={false}
              fullWidth
              required
              style={{ marginLeft: '1rem' }}
              errorHelperText={t('endUnavailabilityError')}
            />
          </div>
          <div className="mb-1 pt-2 shadow-sm">
            <Field
              path="description"
              isErrorProp="error"
            >
              <TextField
                variant="outlined"
                label={t('reasonUnavailability')}
                fullWidth
                multiline
                minRows={2}
              />
            </Field>
          </div>
          <div className="mb-1 pt-2 shadow-sm">
            <EntityX
              path="technician"
              entityName="technicians" // TODO: dynamic value technicians or contractors ?
              entity={Intervener}
              placeholder={t('techniciansTitle')}
              pickerUniq
              required
              errorHelperText={t('pleaseSelectAnIntervener')}
              customStyleDialog={{
                isMobile: isBelowSm,
                height: '95vh'
              }}
            />
          </div>
          <div className="mt-2 flex justify-end">
            <Reset onClick={() => onClose()}>
              <Button>{t('cancel')}</Button>
            </Reset>
            <Submit disabled={is_submit_unavailability}>
              <Button>{t('create')}</Button>
            </Submit>
          </div>
        </Form>
      </div>
    </Dialog>
  );
}
