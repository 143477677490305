import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import EntityX from 'components/inputs/EntityX';
import Manager from 'entities/Manager/Manager';
import { Field, useFieldObserver } from 'frmx';
import { useTranslation } from 'react-i18next';

export default function EmailAlertFormThreshold({ warehouse_index }) {
  const { t } = useTranslation();

  const emailAlert = useFieldObserver(`warehouses.${warehouse_index}.emailAlert`);

  return (
    <>
      <Grid
        item
        style={{ padding: '0 6px', marginTop: 12 }}
      >
        <FormControlLabel
          control={
            <Field
              path={`warehouses.${warehouse_index}.emailAlert`}
              type="checkbox"
            >
              <Checkbox />
            </Field>
          }
          label={t('alertEmailWhenThresholdsReached')}
          style={{ position: 'relative', top: '10px' }}
        />
      </Grid>

      {emailAlert && (
        <Grid
          item
          xs={12}
          style={{ padding: '0 6px', marginTop: 12 }}
        >
          <EntityX
            placeholder="Managers"
            required
            entity={Manager}
            entityName="managers"
            path={`warehouses.${warehouse_index}._managers`}
          />
        </Grid>
      )}
    </>
  );
}
