import { useLayoutEffect, useState } from 'react';
import { useFieldObserver } from 'frmx';

export default function NotEmptyIndicator({ path }) {
  const [hasData, setHasData] = useState();
  const [isNegative, setIsNegative] = useState(false);

  const value = useFieldObserver(path);

  useLayoutEffect(() => {
    setHasData(Boolean(value?.length));
    const isNegative =
      value === false || (value?.length === 1 && (value[0] === false || value[0]?._id === false));
    isNegative && setIsNegative(true);
  }, [value]);

  return hasData ? (
    <svg
      className="absolute -top-1 -right-1 w-3 h-3"
      viewBox="0 0 10 10"
    >
      <circle
        cx="5"
        cy="5"
        r="5"
        className={isNegative ? 'fill-red-500' : 'fill-green-500'}
      />
    </svg>
  ) : null;
}
