import useOutsideClick from 'hooks/useOutsideClick';
import { useState, useRef } from 'react';
import { Check as CheckIcon } from '@material-ui/icons';

export function ColorPickerTags({ tag, setTag, wasEdited }) {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [color, setColor] = useState(tag.color);
  const colors = [
    '#7b1fa2',
    '#212121',
    '#c2185b',
    '#f57f1a',
    '#d32f2f',
    '#003366',
    '#1976d2',
    '#2e7d32',
    '#67645f'
  ];
  const contextRef = useRef();

  useOutsideClick(contextRef, () => setDialogIsOpen(false));

  return (
    <div
      ref={contextRef}
      className="flex flex-col relative"
    >
      <ColorPickerButton
        color={color}
        dialogIsOpen={dialogIsOpen}
        setDialogIsOpen={setDialogIsOpen}
        disabled={tag._system}
      />
      <ColorPickerDialog
        colors={colors}
        setColor={setColor}
        dialogIsOpen={dialogIsOpen}
        setDialogIsOpen={setDialogIsOpen}
        setTag={setTag}
        tag={tag}
        wasEdited={wasEdited}
      />
    </div>
  );
}

function ColorPickerButton({ color, dialogIsOpen, setDialogIsOpen, disabled }) {
  // const icon = dialogIsOpen ? "window-close" : "pencil-alt";
  return (
    <div className="flex">
      <button
        className={`transition rounded-full cursor-pointer w-6 h-6 hover:scale-105 focus:ring-2 focus:ring-offset-1 focus:ring-indigo-300`}
        style={{
          backgroundColor: color,
          opacity: disabled ? 0.7 : 1,
          pointerEvents: disabled ? 'none' : 'auto'
        }}
        onClick={(e) => {
          setDialogIsOpen(!dialogIsOpen);
        }}
      ></button>
    </div>
  );
}

function ColorPickerDialog({
  colors,
  setColor,
  dialogIsOpen,
  setDialogIsOpen,
  setTag,
  wasEdited,
  tag
}) {
  if (!Boolean(dialogIsOpen)) return null;

  return (
    <div
      className={`z-10 min-w-max absolute grid grid-cols-3 gap-3 bg-white pl-2 pr-2 p-1 pt-2 rounded-lg shadow-xl`} // absolute grid grid-cols-3 gap-2 bg-white pl-2 pr-2 p-1 pt-2 rounded-lg shadow-lg top-12
      style={{ top: '-125px' }}
    >
      {colors.map((color) => (
        <label key={color}>
          <button
            value={color}
            style={{ backgroundColor: color }}
            onClick={(e) => {
              setColor(e.target.value);
              wasEdited.current = true;
              setTag((prev) => ({ ...prev, color: e.target.value }));
              setDialogIsOpen(false);
            }}
            className={`transition rounded-full cursor-pointer w-6 h-6 hover:scale-110 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-300`}
          >
            {color === tag.color && (
              <CheckIcon style={{ height: `15px`, width: `15px`, color: `white` }} />
            )}
          </button>
        </label>
      ))}
    </div>
  );
}
