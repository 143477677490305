import {
  Box,
  makeStyles,
  List,
  Button,
  ListItem,
  ListItemText,
  ListItemSecondaryAction
} from '@material-ui/core';
import useKeys from '@flowsn4ke/usekeys';
import { Add } from '@material-ui/icons';
import SuspenseLoader from 'components/SuspenseLoader';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import useConfirm from 'hooks/useConfirm';
import useNotifications from 'hooks/useNotifications';
import { Empty } from 'layouts/entities/List';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { delete_pricing, get_pricings } from 'store/metersSlice';
import PricingDialog from '../Dialogs/PricingDialog';
import IconButton from 'components/IconButton';
import FAIcon from 'components/ui/FAIcon';
import { dateToLocalFormat } from 'utils/dates';
import { useRole } from 'hooks/useRole';

const useStyles = makeStyles((theme) => ({
  list: {
    backgroundColor: 'white',
    minHeight: '100%',
    paddingTop: 0,
    ['& > :nth-child(even)']: {
      background: '#ebebeb'
    }
  }
}));

export default function MeterPricing({ meter_id }) {
  const { dispatch, requestStatus } = useAsyncDispatch();
  const confirm = useConfirm();
  const k = useKeys();
  const notify = useNotifications();
  const classes = useStyles();
  const { t } = useTranslation();
  const role = useRole();

  const dispatchCallbacks = {
    onSuccess: () => notify.success(t('pricingDeleted')),
    onError: () => notify.error()
  };

  const pricings = useSelector((state) => state.meters.db[meter_id]?.pricings);

  useEffect(() => {
    dispatch(get_pricings, {}, {}, { id: meter_id });
  }, []);

  const [open_pricing_form, set_open_pricing_form] = useState(false);
  const handle_close_pricing_form = () => set_open_pricing_form(false);
  const handle_open_pricing_form = () => set_open_pricing_form(true);

  const [pricing_to_edit, set_pricing_to_edit] = useState(null);
  const handle_set_pricing_to_edit = (pricing) => {
    handle_open_pricing_form();
    set_pricing_to_edit(pricing);
  };
  const handle_clear_pricing_to_edit = () => {
    handle_close_pricing_form();
    set_pricing_to_edit(null);
  };

  const has_permission_to_create_pricing = role.permission('meters', 'create-pricing');
  const has_permission_to_update_pricing = role.permission('meters', 'update-pricing');
  const has_permission_to_delete_pricing = role.permission('meters', 'delete-pricing');

  return requestStatus === 'loading' ? (
    <div className="flex items-center justify-center h-full">
      <SuspenseLoader />
    </div>
  ) : requestStatus === 'error' ? (
    <Box>{t('somethingWentWrongSorry')}</Box>
  ) : requestStatus === 'success' && pricings?.length === 0 ? (
    <>
      <Empty
        icon="wallet"
        translations={{
          noResultLabel: t('pricingsTitle'),
          noResultText: t('noResultsPricing')
        }}
        cta={
          has_permission_to_create_pricing && (
            <Button
              variant="outlined"
              onClick={handle_open_pricing_form}
              endIcon={<Add />}
            >
              {t('createPricing')}
            </Button>
          )
        }
      />
      <PricingDialog
        meter_id={meter_id}
        is_open={open_pricing_form}
        on_close={handle_close_pricing_form}
      />
    </>
  ) : (
    <>
      <List className={classes.list}>
        <div className="flex justify-end p-2">
          {has_permission_to_create_pricing && (
            <Button
              variant="outlined"
              onClick={handle_open_pricing_form}
              endIcon={<Add />}
            >
              {t('createPricing')}
            </Button>
          )}
          <PricingDialog
            is_editing={pricing_to_edit !== null}
            meter_id={meter_id}
            is_open={open_pricing_form}
            on_close={handle_clear_pricing_to_edit}
            pricing_to_edit={pricing_to_edit}
          />
        </div>

        {pricings?.map((pricing, pricing_idx) => {
          return (
            <ListItem key={k(pricing_idx)}>
              <ListItemText
                primary={pricing.price + ' ' + pricing.currency}
                secondary={
                  pricing.end === null
                    ? t('tarificationPeriodFrom', { start: dateToLocalFormat(pricing.start) })
                    : t('tarificationPeriodFromTo', {
                        start: dateToLocalFormat(pricing.start),
                        end: dateToLocalFormat(pricing.end)
                      })
                }
              />
              <ListItemSecondaryAction className="flex">
                {has_permission_to_update_pricing && (
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    onClick={() => handle_set_pricing_to_edit(pricing)}
                  >
                    <FAIcon
                      icon="edit"
                      collection="fad"
                      size="small"
                    />
                  </IconButton>
                )}

                {has_permission_to_delete_pricing && (
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() =>
                      confirm({
                        title: t('deletePricingTitle'),
                        helperText: t('deleteReadingHelper', {
                          value: pricing.price + ' ' + pricing.currency
                        }),
                        onConfirm: () =>
                          dispatch(
                            delete_pricing, // action
                            {}, // args
                            dispatchCallbacks, // callbacks
                            {
                              // params
                              id: meter_id,
                              pricing_id: pricing._id
                            }
                          )
                      })
                    }
                  >
                    <FAIcon
                      icon="trash"
                      collection="fad"
                      size="small"
                    />
                  </IconButton>
                )}
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    </>
  );
}
