import EntityOptions from 'components/filters/EntityOptions';
import EntityPopoverFilter from 'components/filters/EntityPopoverFilter';
import EntitySelectCheckbox from 'components/filters/EntitySelectCheckbox';
import SectionPicker from 'components/filters/SectionPicker';
import Client from 'entities/Client/Client';
import Equipment from 'entities/Equipment/Equipment';
import Intervener from 'entities/Intervener/Intervener';
import Job from 'entities/Job/Job';
import Location from 'entities/Location/Location';
import Maintenance from 'entities/Maintenance/Maintenance';
import Manager from 'entities/Manager/Manager';
import Scope from 'entities/Scope/Scope';
import DateFilters from './Filters/DateFilters';
import FieldsFilters from './Filters/FieldsFilters';
import NewAccountingFilters from './Filters/NewAccountingFilters';
import NewReportFilter from './Filters/NewReportFilters';
import ValidatePeriodFilter from './Filters/ValidatePeriodFilter';
import { DomainsPicker, StatesPicker, SubDomainsPicker, UrgenciesPicker } from './TicketPickerFilters';
import { transformOldSystemToNew } from './utils';

export const getTicketFilters = ({
  configuration,
  userMaintenance = false,
  calendar,
  scheduler,
  customFields = []
}) => {
  const filters = [
    {
      show: true,
      Component: SectionPicker,
      props: { label: 'ongoing', path: 'ongoing', color: 'green', initialValue: true }
    },
    {
      show: true,
      Component: SectionPicker,
      props: { label: 'closed', path: 'closed', color: 'black', initialValue: false }
    },
    {
      show: configuration.feature.preventif,
      Component: EntitySelectCheckbox,
      props: {
        label: 'preventifs',
        entity: Maintenance,
        path: 'preventifs',
        color: 'red',
        checkablePath: 'checkedPreventifs',
        initialValue: []
      }
    },
    {
      show: true,
      Component: SectionPicker,
      props: { label: 'withoutContractor', path: 'withoutContractor', color: 'slate', initialValue: false }
    },
    {
      show: true,
      Component: SectionPicker,
      props: { label: 'withoutJob', path: 'withoutJob', color: 'blue', initialValue: false }
    },
    {
      show: configuration?.follow_up_intervention_delay || configuration?.follow_up_resolution_delay,
      Component: SectionPicker,
      props: { label: 'afterDeadline', path: 'afterDeadline', color: 'red', initialValue: false }
    },
    {
      show: configuration.isContractor,
      Component: EntityPopoverFilter,
      props: { label: 'clients', entity: Client, path: 'clients', initialValue: [] }
    },
    {
      show: true,
      Component: EntityPopoverFilter,
      props: { label: 'contractors', entity: Intervener, path: 'contractors', initialValue: [] }
    },
    {
      show: true,
      Component: EntityPopoverFilter,
      props: { label: 'states', entity: StatesPicker, path: 'states', initialValue: [] }
    },
    {
      show: true,
      Component: EntityOptions,
      props: {
        label: 'quotes',
        path: 'quotes',
        entity: NewAccountingFilters,
        entityProps: { type: 'quotes', path: 'quotes' },
        initialValue: {
          with: true,
          without: true,
          amount: { values: [null, null], op: 'equals' },
          reference: '',
          validated: false,
          noValidated: false,
          validatedBy: []
        }
      }
    },
    {
      show: true,
      Component: EntityOptions,
      props: {
        label: 'invoices',
        path: 'invoices',
        entity: NewAccountingFilters,
        entityProps: { type: 'invoices', path: 'invoices' },
        initialValue: { with: true, without: true, amount: { values: [null, null], op: 'equals' }, reference: '' }
      }
    },
    {
      show: !userMaintenance,
      Component: EntityPopoverFilter,
      props: {
        label: 'types',
        entity: Scope,
        entityProps: { type: 'ticketTypology', hiddenFilters: { only: [], parents: [] } },
        path: 'types',
        initialValue: []
      }
    },
    {
      show: !configuration.monolocation,
      Component: EntityPopoverFilter,
      props: {
        label: 'locations',
        entity: Location,
        // entityProps: { type: 'locations', hiddenFilters: { section: { location: true, facturation: false } } },
        path: 'locations',
        initialValue: []
      }
    },
    {
      show: true,
      Component: EntityPopoverFilter,
      props: { label: 'jobs', entity: Job, path: 'jobs', initialValue: [] }
    },
    {
      show: true,
      Component: EntityPopoverFilter,
      props: { label: 'managers', entity: Manager, path: 'managers', initialValue: [] }
    },
    {
      show: configuration.isManager,
      Component: SectionPicker,
      props: { label: 'myInterventions', path: 'myInterventions', color: 'pink', initialValue: false }
    },
    {
      show: configuration.isManager,
      Component: SectionPicker,
      props: { label: 'alertExceeded', path: 'alertExceeded', color: 'purple', initialValue: false }
    },
    {
      show: configuration.isManager,
      Component: SectionPicker,
      props: { label: 'withoutManager', path: 'withoutManager', color: 'orange', initialValue: false }
    },
    {
      show: configuration.isBlachere || !!configuration?.job_domains?.length,
      Component: EntityPopoverFilter,
      props: {
        label: configuration.isBlachere ? 'categories' : 'domains',
        entity: DomainsPicker,
        entityProps: { path: 'domains' },
        path: 'domains',
        initialValue: []
      }
    },
    {
      // exclu blach pour l'instant
      show: !!configuration.isBlachere,
      Component: EntityPopoverFilter,
      props: {
        label: configuration.isBlachere ? 'domains' : 'subDomains',
        entity: SubDomainsPicker,
        entityProps: { path: 'subDomains' },
        path: 'subDomains',
        initialValue: []
      }
    },
    {
      show: configuration.feature.preventif,
      Component: EntityOptions,
      props: {
        label: 'validatedPeriod',
        path: 'validatedPeriod',
        entity: ValidatePeriodFilter,
        entityProps: { path: 'validatedPeriod' },
        initialValue: { with: true, without: true }
      }
    },
    {
      show: true,
      Component: EntityOptions,
      props: {
        label: 'reports',
        path: 'reports',
        entity: NewReportFilter,
        entityProps: { path: 'reports' },
        initialValue: { ir: { with: true, without: true }, reserve: { with: true, without: true } }
      }
    },
    {
      show: true,
      Component: EntityPopoverFilter,
      props: { label: 'urgencies', entity: UrgenciesPicker, path: 'urgencies', initialValue: [] }
    },
    {
      show: true,
      Component: EntityPopoverFilter,
      props: { label: 'equipments', entity: Equipment, path: 'equipments', initialValue: [] }
    },
    {
      show: true,
      Component: EntityOptions,
      props: {
        label: 'dates',
        path: 'dates',
        entity: DateFilters,
        entityProps: {
          datas: [
            {
              label: 'createdDate',
              value: 'creationAt'
            },
            {
              label: 'programmedVisitDate',
              value: 'visitDate'
            },
            {
              label: 'finishingDate',
              value: 'flagFinished'
            },
            {
              label: 'interventionDate',
              value: 'interventionDate'
            }
          ]
        },
        initialValue: {
          creationAt: { values: [null, null], op: 'gte' },
          flagFinished: { values: [null, null], op: 'gte' },
          interventionDate: { values: [null, null], op: 'gte' },
          visitDate: { values: [null, null], op: 'gte' }
        }
      }
    },
    {
      show: customFields[0]?.fields?.length,
      Component: EntityOptions,
      props: {
        label: 'customFields',
        path: 'customFields',
        entity: FieldsFilters,
        entityProps: { path: 'customFields', customFields },
        initialValue: []
      }
    },
    // used to manage the initial value of checkedPreventif (used with the preventifs filter)
    {
      show: configuration.feature.preventif,
      Component: () => null,
      props: { path: 'checkedPreventifs', initialValue: calendar || scheduler || userMaintenance }
    }
  ];

  return filters;
};

export const formatFilter = (filters, auth) => {
  const only_show_user_tickets = auth?.interface?._role?.permissions?.tickets?.includes('show_only_user_tickets');

  let _filters = filters;

  if (!filters.locations?.length && auth.interface._locations?.length)
    _filters['locations'] = auth.interface._locations;

  if (!filters.jobs?.length && auth.interface._jobs?.length) _filters['jobs'] = auth.interface._jobs;

  if (!filters.clients?.length && auth.interface._scope_clients?.length)
    _filters['clients'] = auth.interface._scope_clients;

  if (only_show_user_tickets)
    _filters = {
      ..._filters,
      myInterventions: true
    };

  return transformOldSystemToNew({ filters: _filters });
};

export const ticketSortingOrder = [
  { icon: 'sort-up', label: 'creationDateMostRecent', labelKey: 'creation', sortKey: 'desc' },
  { icon: 'sort-down', label: 'creationDateLeastRecent', labelKey: 'creation', sortKey: 'asc' },
  { icon: 'sort-up', label: 'updateDateMostRecent', labelKey: 'update', sortKey: 'desc' },
  { icon: 'sort-down', label: 'updateDateLeastRecent', labelKey: 'update', sortKey: 'asc' }
];

export const getInitialValuesFromFilters = (filters) => {
  const initialValues = {};

  filters.forEach((filter) => {
    if (filter.show) {
      const path = filter.props.path;
      const initialValue = filter.props.initialValue;
      // En fonction du type de initialValue, on peut l'ajouter directement
      if (Array.isArray(initialValue)) {
        initialValues[path] = [];
      } else if (typeof initialValue === 'object' && initialValue !== null) {
        initialValues[path] = { ...initialValue }; // Cloner l'objet pour éviter la mutation
      } else {
        initialValues[path] = initialValue; // Cas des booléens ou autres valeurs simples
      }
    }
  });

  return { ...initialValues, search: '', bookmarks: [], sort: 'creation', order: 'desc', skip: 0, limit: 30 };
};

// Les données envoyées au backend seront uniquement { key, val, op }.
// Les propriétés title et type sont utilisées uniquement dans le frontend.
// pour les op a la racine on a ['fuzzy', 'equals', 'notEquals', 'in', 'notIn', 'object']
// pour les op dans les amounts : ["range", "gt", "lt", "equals"]
// pour les op dans les Dates : ["range", "gt", "gte", "lt", "lte"]
// pour les op dans les customFields : ['equals', 'notEquals', 'in', 'notIn', 'gt', 'gte', 'lt', 'lte', 'exists', 'notExists']
// exemple d'envoi au back:
export const baseFilters = [
  { key: 'search', value: '', op: 'fuzzy' },
  {
    key: 'sort',
    value: 'creationDateMostRecent',
    op: 'equals'
  },
  {
    key: 'order',
    value: 'desc', // asc or desc
    op: 'equals'
  },
  { key: 'ongoing', value: true, op: 'equals' },
  { key: 'closed', value: false, op: 'equals' },
  // ==================== _preventifs
  {
    key: 'checkedPreventifs',
    value: false,
    op: 'equals'
  },
  {
    key: 'preventifs',
    value: [],
    op: 'in'
  },
  // ====================
  { key: 'deadline', value: false, op: 'equals' },
  { key: 'myIntervention', value: false, op: 'equals' },
  { key: 'withoutManager', value: false, op: 'equals' },
  { key: 'withoutIntervener', value: false, op: 'equals' },
  { key: 'withoutJob', value: false, op: 'equals' },
  { key: 'afterDeadline', value: false, op: 'equals' },
  { key: 'domains', value: [], op: 'in' },
  { key: 'subDomains', value: [], op: 'in' },
  {
    key: 'clients',
    value: [],
    op: 'in'
  },
  { key: 'equipments', value: [], op: 'in' },
  { key: 'interveners', value: [], op: 'in' },
  { key: 'jobs', value: [], op: 'in' },
  { key: 'locations', value: [], op: 'in' },
  { key: 'managers', value: [], op: 'in' },
  {
    key: 'preventifChecked',
    value: { with: true, without: true },
    op: 'object'
  },
  { key: 'states', value: [], op: 'in' },
  { key: 'typologies', value: [], op: 'in' },
  { key: 'urgencies', value: [], op: 'in' },
  { key: 'deleted', value: false, op: 'equals' },
  {
    key: 'dates',
    value: {
      creationAt: { values: [null, null], op: 'range' },
      flagFinished: { values: [null, null], op: 'range' },
      interventionDate: { values: [null, null], op: 'range' },
      visitDate: { values: [null, null], op: 'range' }
    },
    op: 'object'
  },
  {
    key: 'quotes',
    value: {
      with: true,
      without: true,
      amount: { values: [null, null], op: 'range' },
      reference: '',
      validated_by: []
    },
    op: 'object'
  },
  {
    key: 'reports',
    value: { ir: { with: true, without: true }, reserve: { with: true, without: true } },
    op: 'object'
  },
  {
    key: 'invoices',
    value: {
      with: true,
      without: true,
      validated: false,
      amount: { values: [null, null], op: 'range' },
      reference: ''
    },
    op: 'object'
  },
  {
    key: 'customFields',
    value: [
      {
        field: '',
        value: [null],
        op: 'equals'
      }
    ],
    op: 'object'
  },
  { key: 'bookmarks', value: [], op: 'in' },

  // champs cacher qui sont rajouter a la vole (maybe il y en a d'autre je vais continuer de chercher sur le front)
  { key: 'excepts', value: [], op: 'in' }, // Similaires
  { key: 'ids', value: [], op: 'in' } // Associes
];
