const MeterFormValues = (configuration, element) => {
  const is_contractor_interface = configuration?.isContractor;

  return {
    name: '',
    description: '',
    unit: '',
    _location: null,
    _equipment: null,
    ...(is_contractor_interface
      ? {
          _client: null
        }
      : {}),
    use_id: null,
    tag_id: null
  };
};

export const MeterValidation = (configuration, auth, element) => {
  const is_contractor_interface = !auth?.interface.isClient;

  return {
    name: (value) => !!value && value.length > 0,
    unit: (value) => !!value,
    _location: (value, formData) => {
      return Boolean(formData._equipment && !formData._location) || !!value;
    },
    _equipment: (value, formData) => {
      return Boolean(formData._location && !formData._equipment) || !!value;
    },
    ...(is_contractor_interface
      ? {
          _client: (value) => !!value
        }
      : {})
  };
};

export const formatForm = (dataFromForm, isCreate, attachments) => ({
  ...dataFromForm
});

export default MeterFormValues;
