import { useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import axios from 'axios';
import { apiBaseURL } from 'index';
import { formatCurrency } from 'utils/formatCurrency';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'hooks/useAuth';

export default function ConsumptionsBudget({
  loadingConsumptions,
  consumptions,
  budgets,
  budget,
  setLoadingConsumptions,
  setConsumptions
}) {
  const { t } = useTranslation();
  const auth = useAuth();
  const company = auth.interface._company;

  const get_budget_consumptions = async ({ budget_id }) => {
    return await axios.get(`${apiBaseURL}/budgets/${budget_id}`);
  };

  useEffect(async () => {
    setLoadingConsumptions(true);
    Promise.all(budgets.map(({ budget_id }) => get_budget_consumptions({ budget_id }))).then((res) => {
      setConsumptions(res.map(({ data }) => data.budget));
      setLoadingConsumptions(false);
    });
  }, []);

  return loadingConsumptions ? (
    <div className="flex justify-center">
      <CircularProgress />
    </div>
  ) : (
    consumptions
      .filter((c) => c._id === budget.budget_id)
      .map((consumption) => {
        const budget_consummed = consumption.used === null ? 0 : parseFloat(consumption.used);
        const budget_remaining = parseFloat(consumption.total) - budget_consummed;

        return (
          <div>
            <div className={`font-bold ${budget_remaining < 0 ? 'text-red-500' : 'text-green-500'}`}>
              {`${t('remaining')}: ${formatCurrency({
                number: budget_remaining,
                locale: company?.currency?.locale,
                currency: company?.currency?.code
              })}`}
            </div>
            <div className="font-bold">
              {`${t('consummed')} : ${formatCurrency({
                number: budget_consummed,
                locale: company?.currency?.locale,
                currency: company?.currency?.code
              })}`}
            </div>
          </div>
        );
      })
  );
}
