import axios from 'axios';
import FAIcon from 'components/ui/FAIcon';
import { useUI } from 'hooks/useUI';
import { useState } from 'react';

export default function TestPage() {
  const [text, setText] = useState('');
  const [translatedText, setTranslatedText] = useState('');
  const [showTranslation, setShowTranslation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { getLang } = useUI();

  const translateText = () => {
    if (isLoading) return;

    setShowTranslation(true);

    // if (translatedText) return;

    setIsLoading(true);

    axios
      .post(`${process.env.REACT_APP_API_URL}/translate`, {
        text: text,
        target: getLang()
      })
      .then((res) => {
        setTranslatedText(res.data.translation);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  };

  const showOriginal = () => {
    setShowTranslation(false);
  };

  return (
    <div className="m-4 flex flex-col gap-y-4">
      <textarea
        className="w-full p-2 text-lg"
        type="text"
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <p
        className="underline cursor-pointer"
        onClick={showTranslation ? showOriginal : translateText}
      >
        {isLoading && (
          <FAIcon
            className="animate-spin "
            icon="spinner-third"
            collection="fas"
            size="xs"
          />
        )}
        {showTranslation && !isLoading ? 'Original' : 'Translate'}
      </p>
      <p>{showTranslation && !isLoading ? translatedText : text}</p>
    </div>
  );
}
