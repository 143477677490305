import { useEffect, useRef, useState } from 'react';
import useOutsideClick from 'hooks/useOutsideClick';
import classNames from 'utils/classNames';
import FAIcon from 'components/ui/FAIcon';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import { createTag, deleteTag, updateTag } from 'store/documentssSlice';
import { useSelector } from 'react-redux';
import useNotifications from 'hooks/useNotifications';
import useDebouncedState from 'hooks/useDebouncedState';
import { pick } from 'lodash-es';
import { useRole } from 'hooks/useRole';
import { useMemo } from 'react';
import useConfirm from 'hooks/useConfirm';
import { useTranslation } from 'react-i18next';
import { ColorPickerTags } from '../../../components/ColorPickerTags';

export default function TagEditor() {
  const { t } = useTranslation();
  const editorRef = useRef();
  const tagsContainerRef = useRef();
  const [tagEditorIsOpen, setTagEditorIsOpen] = useState(false);
  const { dispatch } = useAsyncDispatch();
  const notify = useNotifications();

  useOutsideClick(editorRef, () => setTagEditorIsOpen(false));

  const { tags } = useSelector((state) => state.tags);
  const role = useRole();

  const canEditAndDeleteTags = useMemo(() => role.permission('documents', 'tag-owner'), []);

  return canEditAndDeleteTags ? (
    <div className="relative">
      <button
        onClick={() => setTagEditorIsOpen((open) => (open ? false : true))}
        className="flex items-center mb-1 text-xs ml-4 text-sky-700"
      >
        {t('editTags')}
        <FAIcon
          collection="fas"
          icon="caret-down"
          size="xs"
          className="text-sky-700"
        />
      </button>
      <div className="relative">
        <div
          ref={editorRef}
          className={classNames(
            tagEditorIsOpen ? 'absolute' : 'hidden',
            'z-20 top-1 right-0 w-80 pb-2 bg-white rounded drop-shadow-2xl'
          )}
        >
          <div className="px-2 mt-2">
            <div className="flex flex-col space-y-2 h-full">
              <div
                ref={tagsContainerRef}
                className="flex flex-col space-y-2 max-h-72 overflow-y-auto"
              >
                {tags.map((tag) => (
                  <TagItem
                    key={tag._id}
                    tag={tag}
                  />
                ))}
              </div>
              <button
                onClick={() => dispatch(createTag).catch(() => notify.error())}
                className="bg-sky-500 text-white rounded text-sm py-1 flex items-center justify-center relative"
              >
                {t('addNewTag')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

function TagItem({ tag: initialValue }) {
  const { t } = useTranslation();
  const { dispatch } = useAsyncDispatch();
  const notify = useNotifications();

  const [tag, setTag, debouncedTag] = useDebouncedState(initialValue, 700);
  const wasEdited = useRef(false);

  const confirm = useConfirm();

  useEffect(() => {
    if (wasEdited.current) {
      dispatch(updateTag, pick(tag, ['_id', 'color', 'label']), undefined, { id: tag._id })
        .then(() => (wasEdited.current = false))
        .catch(() => notify.error());
    }
  }, [debouncedTag]);

  return (
    <div className="h-8 flex-none flex items-center space-x-2">
      <div className="ml-[4px]">
        <ColorPickerTags
          tag={tag}
          setTag={setTag}
          wasEdited={wasEdited}
        />
      </div>
      <input
        disabled={tag._system}
        value={tag.label}
        onChange={(event) => {
          wasEdited.current = true;
          setTag((t) => ({ ...t, label: event.target.value }));
        }}
        placeholder={t('tagName')}
        className="h-full w-full border rounded px-2 text-sm"
      />
      <button
        disabled={tag._system}
        onClick={() =>
          confirm({
            title: 'sureYouWantDeleteTagRemoveFromAllDocuments',
            onConfirm: () =>
              dispatch(deleteTag, undefined, undefined, { id: tag._id }).catch(() =>
                notify.error()
              ),
            confirmText: tag.label
          })
        }
        className={classNames(
          'text-gray-400',
          tag._system ? 'cursor-default' : 'hover:text-gray-500'
        )}
      >
        <FAIcon
          collection="fas"
          icon={tag._system ? 'lock' : 'trash'}
          size="sm"
        />
      </button>
    </div>
  );
}
