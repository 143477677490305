import {
  getElement,
  getElements,
  createElement,
  updateElement,
  flushElements,
  deleteElement,
  restoreElement
} from 'store/apisSlice';

import ApiMenus from './ApiMenus';
import ApiPreview from './ApiPreview';
import ApiSkeleton from './ApiSkeleton';
import ApiView from './ApiView';
import ApiTabs from './ApiTabs';
import ApiFilters, { formatFilter } from './ApiFilters';
import ApiFormValues, { ApiValidation } from './ApiFormValues';
import ApiTranslations from './ApiTranslations';
import ApiExport from './ApiExport';
import ApiForm from './ApiForm';
import ApiSorts from './ApiSorts';

import EntityWrapper from 'entities/EntityWrapper';

export default function Api(props) {
  return (
    <EntityWrapper
      entity="apis"
      skeletonComponent={ApiSkeleton}
      previewComponent={ApiPreview}
      viewComponent={ApiView}
      formComponent={ApiForm}
      formatFilter={formatFilter}
      getFilters={ApiFilters}
      getInitialValues={ApiFormValues}
      getFormValidation={ApiValidation}
      getTabs={ApiTabs}
      getTables={() => []}
      getMenus={ApiMenus}
      getExportColumns={ApiExport}
      getTranslations={ApiTranslations}
      getSorts={ApiSorts}
      getElement={getElement}
      getElements={getElements}
      createElement={createElement}
      updateElement={updateElement}
      flushElements={flushElements}
      deleteElement={deleteElement}
      restoreElement={restoreElement}
      {...props}
    />
  );
}
