const EquipmentExport = (configuration) =>
  [
    { label: "Nom de l'équipement", key: 'name', show: true },
    { label: 'Description', key: 'description', show: true },
    { label: 'Numéro de série', key: 'serial', show: true },
    { label: 'Marque', key: 'brand', show: true },
    { label: 'Modèle', key: 'model', show: true },
    { label: 'État', key: 'state', show: true },
    { label: 'Année', key: 'year', show: true },
    { label: "Date d'achat", key: 'buy_date', show: true },
    { label: 'Date de révision', key: 'revision_date', show: true },
    { label: 'Métiers', key: '_jobs', show: true },
    { label: 'Catégorie', key: '_category', show: true },
    { label: 'Sous-Catégorie', key: '_subcategory', show: true },
    { label: 'Lieu', key: '_location', show: true }, // full address
    { label: 'Nom du client', key: '_client', show: configuration.isContractor } // seulement pour faiseur
  ].filter((e) => e.show);

export default EquipmentExport;
