import './styles.css';
import { Box, Grow } from '@material-ui/core';
import useStyles from './Check.styles';
import clsx from 'clsx';

export default function Check() {
  const classes = useStyles();

  return (
    <Grow in>
      <Box className={clsx('success-checkmark', classes.positioning)}>
        <Box className="check-icon">
          <Box
            component="span"
            className="icon-line line-tip"
          ></Box>
          <Box
            component="span"
            className="icon-line line-long"
          ></Box>
          <Box className="icon-circle"></Box>
          <Box className="icon-fix"></Box>
        </Box>
      </Box>
    </Grow>
  );
}
