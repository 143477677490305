const EquipmentTranslations = (configuration, { sublistParent }) =>
  sublistParent
    ? {
        pick: 'entitySubEqPick',
        create: 'entitySubEqCreate',
        update: 'entitySubEqUpdate',
        entityName: 'entitySubEqName',
        noResultLabel: 'entitySubEqnoResultLabel',
        searchText: 'entitySubEqSearchText',
        createDialog: 'entitySubEqCreateDialog',
        updateDialog: 'entitySubEqUpdateDialog',
        createSuccess: 'entitySubEqCreateSuccess',
        updateSuccess: 'entitySubEqUpdateSuccess'
      }
    : {
        pick: 'entityEqPick',
        create: 'entityEqCreate',
        update: 'entityEqUpdate',
        entityName: 'entityEqName',
        noResultLabel: 'entityEqnoResultLabel',
        searchText: 'entityEqSearchText',
        createDialog: 'entityEqCreateDialog',
        updateDialog: 'entityEqUpdateDialog',
        createSuccess: 'entityEqCreateSuccess',
        updateSuccess: 'entityEqUpdateSuccess'
      };

export default EquipmentTranslations;
