// * OK
import EntityWrapper from 'entities/EntityWrapper';
import AccountingExport from './AccountingExport';
import AccountingSkeleton from './AccountingSkeleton';
import AccountingSorts from './AccountingSorts';
import AccountingTable from './AccountingTable';
import AccountingTranslations from './AccountingTranslations';

import AccountingFilters, { formatFilter } from './AccountingFilters';
import { useMemo } from 'react';
import { useAuth } from 'hooks/useAuth';
import { flushElements, getBookmarks, getElements } from 'store/accountingSlice';
import TicketView from 'entities/Ticket/TicketView';
import TicketTabs from 'entities/Ticket/TicketTabs';

export default function Accounting(props) {
  const auth = useAuth();

  const bookmarks = useMemo(() => {
    return factureStates;
  }, [auth.interface?._id]);

  return (
    <EntityWrapper
      entity="accounting"
      entitySliceView="tickets"
      entityFieldsSlice="ticket"
      entityMenu="tickets"
      keyToOpen="ticketId"
      viewComponent={TicketView}
      skeletonComponent={AccountingSkeleton}
      getTabs={TicketTabs}
      formatFilter={formatFilter}
      getInitialValues={() => []}
      getFormValidation={() => []}
      getTables={AccountingTable}
      getMenus={() => []}
      getFilters={AccountingFilters}
      getExportColumns={AccountingExport}
      getTranslations={AccountingTranslations}
      getSorts={AccountingSorts}
      getElements={getElements}
      getBookmarks={getBookmarks}
      bookmarks={bookmarks}
      flushElements={flushElements}
      // getElements={getElements}
      // getElement={getElement}
      // disableCreate={true}

      {...props}
    />
  );
}

const factureStates = [
  {
    key: 'to_complete',
    label: 'toComplete',
    labelError: 'toCompleteLabelError',
    labelSuccess: 'toCompleteLabelSuccess',
    colorError: '#e63d53',
    colorSuccess: '#31b559',
    color: '#e63d53'
  },
  {
    key: 'to_check',
    label: 'toCheck',
    labelAction: 'Checker',
    labelTitle: 'Check',
    color: '#e63d53'
  },
  {
    key: 'to_waiting',
    label: 'waiting',
    labelAction: 'Mise en attente',
    labelTitle: 'En attente',
    color: '#9c27b0'
  },
  {
    key: 'to_valid',
    label: 'toValid',
    labelAction: 'Valider',
    labelTitle: 'Validation',
    color: '#e63d53'
  },
  {
    key: 'analyse',
    label: 'analyse',
    labelAction: 'analyse',
    color: '#827596'
  },
  {
    key: 'reserve',
    label: 'reserve',
    labelAction: 'reserve',
    color: '#f57e20'
  },
  {
    key: 'to_send',
    label: 'toSend',
    labelAction: 'toSendAction',
    color: '#1facda'
  },
  {
    key: 'sent',
    label: 'transmitted',
    labelAction: 'Marqué commme transmise',
    color: '#31b559'
  },
  {
    key: 'available',
    label: 'available',
    color: '#31b559'
  }
];
