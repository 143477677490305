import { Box, Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import useStyles from 'layouts/entities/View.styles';
import { useAuth } from 'hooks/useAuth';

const OrderPublish = ({ actions, isLoading }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const auth = useAuth();

  return (
    <Box marginTop={'10px'}>
      <Button
        onClick={() => actions.order_publish.action()}
        disabled={isLoading}
        endIcon={<Add className={classes.viewActionIcon} />}
        color="secondary"
        variant="contained"
        className={classes.viewAction}
      >
        {t(!auth.interface.isBlachere ? 'blachEntityTicketCreate' : 'entityTicketCreate')}
      </Button>
    </Box>
  );
};

export default OrderPublish;
