import { Checkbox, FormControlLabel } from '@material-ui/core';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import useNotifications from 'hooks/useNotifications';
import { useState } from 'react';
import { check_paid_invoices } from 'store/accountingSlice';

export default function PaidInvoicesColumn({ ticket_id, invoices, invoice_tickets, parentId }) {
  const { dispatch } = useAsyncDispatch();
  const notify = useNotifications();

  const invoices_has_checked = invoices?.every(({ paid }) => !!paid);
  const invoices_has_all_deleted = invoices?.every(({ deleted }) => deleted.state);

  const [checked, setChecked] = useState(invoices_has_checked);

  const handle_check_paid_invoices = () => {
    dispatch(
      check_paid_invoices,
      {
        checked_paid_invoices: !checked,
        // * invoice_tickets is the array of tickets linked to the invoice
        invoice_ticket_ids: invoice_tickets?.map((ticket) => ticket._id) || []
      },
      {
        onSuccess: () => [setChecked(!checked), notify.success()],
        onError: () => [setChecked(false), notify.error()]
      },
      {
        id: ticket_id
      }
    );
  };

  return parentId ? null : (
    <div className="flex justify-center">
      <FormControlLabel
        control={
          <Checkbox
            disabled={invoices_has_all_deleted}
            style={{ color: checked ? '#31b559' : '' }}
            checked={checked}
            onChange={handle_check_paid_invoices}
          />
        }
      />
    </div>
  );
}
