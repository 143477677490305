import { Box, Button, ButtonGroup, Divider, useTheme } from '@material-ui/core';
import FAIcon from 'components/ui/FAIcon';
import PopoverDrawer from 'components/ui/PopoverDrawer';
import Scope from 'entities/Scope/Scope';
import { Form, Submit } from 'frmx';
import useStyles from 'layouts/entities/View.styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const ReplanButton = ({ actions, isLoading }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [replanOpen, setReplanOpen] = useState(false);
  const theme = useTheme();

  return (
    <Box marginTop={'10px'}>
      <ButtonGroup
        variant="outlined"
        color="secondary"
      >
        <Button
          disabled={isLoading}
          color="secondary"
          className={classes.viewActionGroup}
          style={{ borderRadius: '8px 0px 0px 8px', paddingRight: 102 }}
          onClick={() => [actions.replan.action(), setReplanOpen(false)]}
        >
          {t('reschedule')}
        </Button>
        <Button
          disabled={isLoading}
          className={classes.viewActionGroup}
          style={{
            borderColor: theme.palette.secondary.main,
            borderRadius: '0px 8px 8px 0px'
          }}
          color="primary"
          size="small"
          onClick={(e) => setReplanOpen(e.currentTarget)}
        >
          <FAIcon
            icon="calendar"
            style={{ color: theme.palette.secondary.main }}
            collection="fal"
          />
        </Button>
      </ButtonGroup>

      <PopoverDrawer
        keepMounted
        transitionDuration={0}
        anchorEl={replanOpen}
        open={!!replanOpen}
        onClose={() => [setReplanOpen(false)]}
        style={{ width: '320px' }}
      >
        <Form
          style={{ width: '100%' }}
          initialValues={{ reason: null }}
          onSubmit={(form) => [actions.replan.action(form.reason.name), setReplanOpen(false)]}
          disableIfInvalid
        >
          <Box
            style={{
              width: '100%',
              background: 'white',
              overflow: 'hidden',
              borderRadius: 8
            }}
          >
            {
              <Box height={'300px'}>
                <Scope
                  pickerUniqRadio
                  pickerUniq
                  pickerCreate
                  disableGutters
                  disableShadow
                  type={'reasonReplan'}
                  picker={true}
                  pickerField={'reason'}
                />
              </Box>
            }
            <Divider />
            <Box
              display="flex"
              justifyContent="flex-end"
              padding="4px 6px"
            >
              <Submit>
                <Button
                  color="primary"
                  size="small"
                >
                  {t('validate')}
                </Button>
              </Submit>
            </Box>
          </Box>
        </Form>
      </PopoverDrawer>
    </Box>
  );
};

export default ReplanButton;
