import Dialog from 'components/Dialog';
import { useTranslation } from 'react-i18next';
import PricingForm from '../Forms/PricingForm';

export default function PricingDialog({
  is_open,
  on_close,
  meter_id,
  pricing_to_edit,
  is_editing
}) {
  const { t } = useTranslation();

  return (
    <Dialog
      icon="pen"
      isOpen={is_open}
      onClose={on_close}
      title={is_editing ? t('editPricing') : t('addAPricing')}
      className="max-w-[600px]"
    >
      <div>
        <PricingForm
          meter_id={meter_id}
          on_close={on_close}
          is_editing={is_editing}
          pricing_to_edit={pricing_to_edit}
        />
      </div>
    </Dialog>
  );
}
