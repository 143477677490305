import { useMemo, useState } from 'react';
import { Checkbox, InputBase, List, ListItem, Box, IconButton, Toolbar } from '@material-ui/core';
import { useField } from 'frmx';
import useStyles from './Filter.styles';
import useKeys from '@flowsn4ke/usekeys';

import { Close as CloseIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { isArray } from 'lodash-es';
import { useEffect } from 'react';

export default function FilterList({ filter }) {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const { value: field, setValue: setField } = useField(filter.key);

  // TODO: For some reason the initial value is NOT an array, rather a string
  // This is quick fix but we need to understand why it is not an array from the beginning
  useEffect(() => !isArray(field) && !!field && setField([field]), []);

  const { t } = useTranslation();

  const datas = useMemo(() => {
    return filter.params.datas
      .sort(
        (a, b) =>
          (isArray(field) && !!field?.find((el) => el === b.value) * 1) +
          (isArray(field) && !!field?.find((el) => el === a.value) * -1)
      )
      .filter((data) => !search?.trim()?.length || t(data.label)?.toLowerCase().includes(search?.toLowerCase()));
  }, [search]);

  const k = useKeys();

  return (
    <Box className={classes.filter}>
      <Toolbar className={classes.listSearch}>
        <InputBase
          fullWidth
          placeholder={t('search')}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          endAdornment={
            !!search?.trim()?.length ? (
              <IconButton
                size="small"
                onClick={() => setSearch('')}
                aria-label="search"
              >
                <CloseIcon />
              </IconButton>
            ) : null
          }
        />
      </Toolbar>
      <List className={classes.list}>
        {!datas?.length && <Box className={classes.listEmpty}>{t('noElementWasFound')}</Box>}
        {datas.map((data, i) => {
          const isIn = isArray(field) && !!field?.find((el) => el === data.value);
          return (
            <ListItem
              key={k(i)}
              button
              dense
              onClick={() => {
                setField(isIn && isArray(field) ? field.filter((el) => el !== data.value) : [...field, data.value]);
              }}
              className={clsx(classes.listItem, {
                [classes.listItemIn]: isIn
              })}
            >
              <Checkbox
                style={data.color ? { color: data.color } : {}}
                color="primary"
                checked={isIn}
              />
              <span style={{ fontWeight: isIn ? 500 : 400, color: data.color || 'inherit' }}>{t(data.label)}</span>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
}
