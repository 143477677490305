import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  subSection: {
    ...theme.typography.subtitle1,
    textAlign: 'center',
    width: '100%',
    marginTop: '2em',
    marginBottom: '1em'
  },
  replanReasonLine: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  replaReasonTextField: {
    width: '100%',
    marginRight: '.5em'
  },
  addReasonButton: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginTop: '1em'
  },
  deleteIcon: {
    color: theme.palette.error.main
  },
  interventionDuration: {
    width: '7em',
    marginRight: '1em',
    marginLeft: '1em'
  },
  urgencyField: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  input: {
    margin: '0em 1em',
    flexGrow: 1
  },
  delayType: {
    width: '7em',
    flexShrink: 0
  },
  number: {
    width: '6em',
    flexShrink: 0
  },
  role: {
    flexGrow: 3
  },
  colorPicker: {
    borderRadius: '100%',
    backgroundColor: 'white',
    height: '30px',
    width: '32px',
    border: 'none',
    outline: 'none',
    flexShrink: 0,
    WebkitAppearance: 'none',
    '&::-webkit-color-swatch-wrapper': { padding: '0' },
    '&::-webkit-color-swatch': {
      border: 'none',
      borderRadius: '100%'
    }
  }
}));
