import { Virtuoso } from 'react-virtuoso';
import { Box, LinearProgress } from '@material-ui/core';
import { useEntity } from 'contexts/entities/entityContext';
import Preview from './Preview';
import { useRef, useState } from 'react';
import { useField } from 'frmx';

export default function VirtuosoList({ fetchElements, setIsOpenForm, loading }) {
  const { db, fldx, disabled, elements, pickerField, onClick, fieldPath, Draggable } = useEntity();

  const [draggableObj, setDraggableObj] = useState(null);

  const field = useField(pickerField);

  const virtuosoRef = useRef(null);

  const itemsRendered = (items) => {
    if (!!Draggable && virtuosoRef?.current) {
      if (draggableObj) {
        draggableObj.destroy();
      }

      setDraggableObj(
        new Draggable(virtuosoRef?.current, {
          itemSelector: '.Draggable',
          eventData: (eventEl) => {
            const element = db[eventEl.dataset.id];

            if (element) {
              //TODO: Get color for scheduler api
              // let color = !element._summons.length ? '#dddddd' : element._summons[0].isCollaborator ? element._summons[0].color : element._summons[0].partner ? element._summons[0].partner.color : '#535b7c'
              const color = '#dddddd';

              return {
                backgroundColor: color,
                borderColor: '#dddddd',
                textColor: 'black',
                extendedProps: {
                  ticketId: element._id,
                  index: 0,
                  type: !element.visit_date ? 'visit' : 'intervention'
                }
              };
            }
            return;
          }
        })
      );
    }
  };

  return (
    <Box
      ref={virtuosoRef}
      height="100%"
      width="100%"
    >
      <Virtuoso
        itemsRendered={itemsRendered}
        totalCount={elements?.length}
        itemContent={(index) => (
          <Preview
            disabled={disabled}
            onClick={onClick}
            fldx={pickerField || fieldPath ? (!!fldx ? fldx : field) : null}
            setIsOpenForm={setIsOpenForm}
            element={db[elements[index]._id]}
          />
        )}
        endReached={fetchElements}
        style={{ overflowX: 'hidden' }}
        components={{
          Footer: () => {
            return <>{loading && <LinearProgress />}</>;
          }
        }}
      />
    </Box>
  );
}
