import Intervener from 'entities/Intervener/Intervener';

export default function IntervenerPage(props) {
  return (
    <Intervener
      page
      {...props}
    />
  );
}
