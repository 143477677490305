import { nanoid } from 'nanoid';

export const HALF_HOUR_HEIGHT = 40;
export const HOURS_IN_A_DAY = 24;
export const HOURS_COLUMN_WIDTH = 50;
export const HOURS_HEIGHT_OFFSET = 6;
export const EVENT_PADDING = 5;
export const EVENT_BORDER_WIDTH = 6;
export const MINUTE_HEIGHT = HALF_HOUR_HEIGHT / 30;
export const HOUR_HEIGHT = HALF_HOUR_HEIGHT * 2;
export const HOURS_LEFT_SPACING = 10;
export const HALF_HOURS_IN_A_DAY = HOURS_IN_A_DAY * 2;
export const FULL_DAY_HEIGHT = HALF_HOURS_IN_A_DAY * HALF_HOUR_HEIGHT;
export const DAYS_IN_A_WEEK = 7;
export const EVENT_START = 0;
export const EVENT_END = 1;
export const HEX_BACKGROUND_TRANSPARENCY = 'DD';
export const NOW_CIRCLE_OFFSET = 4;
export const DAYS_HEADER_HEIGHT = 60;
export const TOOLBAR_HEIGHT = 70;
export const MAGIC_PIXEL = 1;
export const CELL_UNIQUE_CLASSNAME = nanoid();
export const WEEK_VIEW_TYPE = 'week';
export const DAY_VIEW_TYPE = 'day';
export const MONTH_VIEW_TYPE = 'month';
export const YEAR_VIEW_TYPE = 'year';
export const DAYS_IN_MONTHLY_VIEW = 42;
export const NUMBER_OF_EVENTS_TO_SHOW_IN_MONTHLY_VIEW = 2;
export const EVENT_MIN_HEIGHT = MINUTE_HEIGHT * 22;
export const ONE_MINUTE_TRIM_TO_AVOID_USELESS_OVERLAP = 120000;
export const VISIT_DATE_TYPE = 'visit';
export const INTERVENTION_DATE_TYPE = 'intervention';
export const INTERVENTION_TYPE = 'intervention';
export const UNAVAILABILITY_TYPE = 'unavailability';
export const PREVIEW_TYPE = 'preview';
export const SET_RANGE_EVENT_ID = nanoid();
