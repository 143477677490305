export const factureStates = {
  available: {
    key: 'available',
    label: 'available',
    color: '#31b559'
  },
  sent: {
    key: 'sent',
    label: 'transmitted',
    labelAction: 'Marqué commme transmise',
    color: '#31b559'
  },
  reserve: {
    key: 'reserve',
    label: 'reserve',
    labelAction: 'Reserve',
    color: '#f57e20'
  },
  to_send: {
    key: 'to_send',
    label: 'toSend',
    labelAction: 'toSendAction',
    color: '#1facda'
  },
  analyse: {
    key: 'analyse',
    label: 'analyse',
    labelAction: 'analyse',
    color: '#827596'
  },
  to_complete: {
    key: 'to_complete',
    label: 'toComplete',
    labelError: 'toCompleteLabelError',
    labelSuccess: 'toCompleteLabelSuccess',
    colorError: '#e63d53',
    colorSuccess: '#31b559',
    color: '#e63d53'
  },
  to_valid: {
    key: 'to_valid',
    label: 'toValid',
    labelAction: 'Valider',
    labelTitle: 'Validation',
    color: '#e63d53'
  },
  to_check: {
    key: 'to_check',
    label: 'toCheck',
    labelAction: 'Checker',
    labelTitle: 'Check',
    color: '#e63d53'
  },
  to_waiting: {
    key: 'to_waiting',
    label: 'waiting',
    labelAction: 'waiting',
    labelTitle: 'waiting',
    color: '#9c27b0'
  }
};
