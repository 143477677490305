import IconButton from 'components/IconButton';
import FAIcon from 'components/ui/FAIcon';
import { useTranslation } from 'react-i18next';

export const ActionCustomFilterSet = ({ label, onEdit, onDelete, isChecked, selectionFilter }) => {
  const { t } = useTranslation();

  return (
    <>
      <div
        className="p-3 flex min-w-[250px] items-center gap-2 justify-between rounded-xl bg-white transition cursor-pointer hover:bg-gray-50"
        onClick={selectionFilter}
      >
        <label className="flex items-center gap-2 cursor-pointer">
          <input
            type="checkbox"
            className="h-4 w-4 text-blue-500 border-gray-300 rounded focus:ring-blue-400 focus:ring-2"
            checked={isChecked}
            readOnly
          />
          <p className="text-sm text-gray-800 font-medium">{label === 'defaultFilterSet' ? t(label) : label}</p>
        </label>
        {label !== 'defaultFilterSet' && (
          <div className="flex gap-2">
            <IconButton
              className="!p-1.5 text-gray-500 hover:text-blue-500 transition-colors"
              onClick={(e) => {
                e.stopPropagation();
                onEdit();
              }}
              aria-label="Edit filter"
            >
              <FAIcon
                collection="fas"
                icon="edit"
                size="small"
              />
            </IconButton>
            <IconButton
              className="!p-1.5 text-gray-500 hover:text-red-500 transition-colors"
              onClick={(e) => {
                e.stopPropagation();
                onDelete();
              }}
              aria-label="Delete filter"
            >
              <FAIcon
                collection="fas"
                icon="trash"
                size="small"
              />
            </IconButton>
          </div>
        )}
      </div>
    </>
  );
};
