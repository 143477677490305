import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

export default function useAsyncDispatch(options) {
  const [requestStatus, setRequestStatus] = useState('idle');
  const [statusCode, setStatusCode] = useState(null);
  const [promise, setPromise] = useState();

  useEffect(() => () => !options?.virtualized && !!promise && promise?.abort(), [promise, options]);

  const syncDispatch = useDispatch();

  const dispatch = useCallback((action, args, callbacks = {}, params = {}) => {
    setRequestStatus('loading');

    const _promise = syncDispatch(action({ ...args, params }));

    setPromise(_promise);

    return _promise
      .unwrap()
      .then(({ data, status }, params) => {
        setStatusCode(status);
        setRequestStatus('success');
        if (callbacks.onSuccess) callbacks.onSuccess(data);
        return { data, status };
      })
      .catch((error) => {
        if (error?.name !== 'AbortError') {
          console.log('%cError', 'background: red;color: white;padding: 10px', error);
          const errorStatus = parseInt(error?.message?.slice(-3));
          setStatusCode(errorStatus);
          setRequestStatus('error');
          if (callbacks.onError) callbacks.onError(error);
          return { error, status: errorStatus };
        } else {
          return {};
        }
      });
  }, []);

  return { dispatch, requestStatus, statusCode, setRequestStatus };
}
