import { isInEurope } from 'utils/isInEurope';
import { enUS, enGB, fr, it, de, es, pt, ja, ko, vi, ar, zhCN, id } from 'date-fns/locale';

export const flag_keys = {
  fr: 'FR',
  en: isInEurope ? 'GB' : 'US',
  it: 'IT',
  de: 'DE',
  es: 'ES',
  pt: 'PT',
  ja: 'JP',
  ko: 'KR',
  vi: 'VN',
  ar: 'SA',
  zh: 'CN',
  id: 'ID'
};

export const locale_keys = {
  en: isInEurope ? enGB : enUS,
  fr,
  it,
  de,
  es,
  pt,
  ja,
  ko,
  vi,
  ar,
  zh: zhCN,
  id
};
