import { FormHelperText } from '@material-ui/core';
import { isDate } from 'date-fns';
import { useField } from 'frmx';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@material-ui/pickers';

export default function CustomDate({ onChange, valueControlled, customField, path, required }) {
  const { label } = customField;
  const { value, setValue, error, disabled } = useField(path);

  const { t } = useTranslation();

  return (
    <>
      <DatePicker
        value={
          (!!value || !!valueControlled) && isDate(new Date(value || valueControlled))
            ? new Date(value || valueControlled)
            : null
        }
        onChange={(d) => (onChange ? onChange(d) : setValue(d))}
        inputVariant="outlined"
        label={required ? t(label) + ' *' : t(label)}
        invalidLabel=""
        clearable
        invalidDateMessage={<></>}
        style={{ width: '100%' }}
        format="PPP"
        disabled={disabled}
      />
      <FormHelperText style={{ color: 'red' }}>
        {error ? t('pleaseFillTheField') : ''}
      </FormHelperText>
    </>
  );
}
