import { Badge, Box, Tooltip } from '@material-ui/core';

import useStyles from 'layouts/entities/View.styles';
import useStylesTicket from '../../../entities/Ticket/TicketView.styles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const DevisPrimary = ({ documents }) => {
  const classesTicket = useStylesTicket();
  const classes = useStyles();

  const { t } = useTranslation();

  const devisValidated = documents.filter((devis) => devis.validated.state);
  const devisDropped = documents.filter((devis) => devis.dropped.state);
  const devisWaiting = documents.filter((devis) => !devis.validated.state && !devis.dropped.state);

  const devisBadgeTitle = !!devisWaiting?.length
    ? t('waitingForValidation')
    : !!devisValidated?.length
      ? t('validated')
      : !!devisDropped?.length
        ? t('rejected')
        : '';

  return (
    <Tooltip
      placement="right"
      title={devisBadgeTitle}
    >
      <Badge
        className={clsx(classes.listItemPrimaryBadge, {
          [classesTicket.listItemPrimaryBadgeOrange]: !!devisWaiting?.length,
          [classesTicket.listItemPrimaryBadgeSuccess]:
            !devisWaiting?.length && !!devisValidated?.length,
          [classesTicket.listItemPrimaryBadgeError]:
            !devisWaiting?.length && !devisValidated?.length && !!devisDropped?.length
        })}
        color="secondary"
        badgeContent={documents?.length}
      >
        <Box>{t('quote')}</Box>
      </Badge>
    </Tooltip>
  );
};

export default DevisPrimary;
