// https://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors

/**
 * Blends two RGB colors using a linear blend based on a blending parameter.
 * @param {number} p - The blending parameter (0-1).
 * @param {string} c0 - The first RGB color string in the format of "r,g,b" or "r,g,b,a".
 * @param {string} c1 - The second RGB color string in the format of "r,g,b" or "r,g,b,a".
 * @returns {string} The blended RGB color string.
 */
// eslint-disable-next-line no-unused-vars
const RGB_Linear_Blend = (p, c0, c1) => {
  const i = parseInt;
  const r = Math.round;
  const P = 1 - p;
  const [a, b, c, d] = c0.split(',');
  const [e, f, g, h] = c1.split(',');

  // Determine the alpha value for the blended color, if present
  const x = d || h;
  const j = x
    ? ',' + (!d ? h : !h ? d : r((parseFloat(d) * P + parseFloat(h) * p) * 1000) / 1000 + ')')
    : ')';

  // Calculate the blended RGB components using a linear blend formula
  const red = r(
    i(a[3] === 'a' ? a.slice(5) : a.slice(4)) * P + i(e[3] === 'a' ? e.slice(5) : e.slice(4)) * p
  );
  const green = r(i(b) * P + i(f) * p);
  const blue = r(i(c) * P + i(g) * p);

  return 'rgb' + (x ? 'a(' : '(') + red + ',' + green + ',' + blue + j;
};

/**
 * Computes a linear shade variation for an RGB color.
 * @param {number} shade - The linear shade variation value.
 * @param {string} c - The RGB color string in the format of "r,g,b" or "r,g,b,a".
 * @returns {string} The resulting RGB color string with the linear shade variation applied.
 */
const RGB_Linear_Shade = (shade, color) => {
  const i = parseInt;
  const r = Math.round;
  // Split the RGB color components
  const [a, b, c, d] = color.split(',');

  // Determine whether the shade variation value is positive or negative
  let P = shade < 0;

  // Calculate the shade factor and adjustment
  const t = P ? 0 : 255 * shade;
  P = P ? 1 + shade : 1 - shade;

  // Calculate the shaded RGB components
  const red = r(i(a[3] === 'a' ? a.slice(5) : a.slice(4)) * P + t);
  const green = r(i(b) * P + t);
  const blue = r(i(c) * P + t);

  return 'rgb' + (d ? 'a(' : '(') + red + ',' + green + ',' + blue + (d ? ',' + d : ')');
};

/**
 * Blends two RGB colors based on a blending parameter.
 * @param {number} p - The blending parameter (0-1).
 * @param {string} c0 - The first RGB color string in the format of "r,g,b" or "r,g,b,a".
 * @param {string} c1 - The second RGB color string in the format of "r,g,b" or "r,g,b,a".
 * @returns {string} The blended RGB color string.
 */
// eslint-disable-next-line no-unused-vars
const RGB_Log_Blend = (p, c0, c1) => {
  const i = parseInt;
  const r = Math.round;
  const P = 1 - p;
  const [a, b, c, d] = c0.split(',');
  const [e, f, g, h] = c1.split(',');

  // Determine the alpha value for the blended color, if present
  const x = d || h;
  const j = x
    ? ',' + (!d ? h : !h ? d : r((parseFloat(d) * P + parseFloat(h) * p) * 1000) / 1000 + ')')
    : ')';

  // Calculate the blended RGB components using the logarithmic blend formula
  const red = r(
    (P * i(a[3] === 'a' ? a.slice(5) : a.slice(4)) ** 2 +
      p * i(e[3] === 'a' ? e.slice(5) : e.slice(4)) ** 2) **
      0.5
  );
  const green = r((P * i(b) ** 2 + p * i(f) ** 2) ** 0.5);
  const blue = r((P * i(c) ** 2 + p * i(g) ** 2) ** 0.5);

  return 'rgb' + (x ? 'a(' : '(') + red + ',' + green + ',' + blue + j;
};

/**
 * Calculates a logarithmic shade variation for an RGB color.
 * @param {number} p - The logarithmic shade variation value.
 * @param {string} c - The RGB color string in the format of "r,g,b" or "r,g,b,a".
 * @returns {string} The resulting RGB color string with the logarithmic shade variation applied.
 */
// eslint-disable-next-line no-unused-vars
const RGB_Log_Shade = (shade, color) => {
  const i = parseInt;
  const r = Math.round;
  const [a, b, c, d] = color.split(',');

  // Determine whether the shade variation value is positive or negative
  const P = shade < 0;

  // Calculate the logarithmic shade variation for each RGB component
  const t = P ? 0 : color * 255 ** 2;
  const shadeFactor = P ? 1 + shade : 1 - shade;
  const red = r((shadeFactor * i(a[3] === 'a' ? a.slice(5) : a.slice(4)) ** 2 + t) ** 0.5);
  const green = r((shadeFactor * i(b) ** 2 + t) ** 0.5);
  const blue = r((shadeFactor * i(c) ** 2 + t) ** 0.5);
  return 'rgb' + (d ? 'a(' : '(') + red + ',' + green + ',' + blue + (d ? ',' + d : ')');
};

/**
 * Converts a single RGB color component to its hexadecimal representation.
 * @param {number} c - The color component value (0-255).
 * @returns {string} The hexadecimal representation of the color component.
 */
function component_to_hex(c) {
  // Convert the decimal component value to hexadecimal
  const hex = c.toString(16).padStart(2, '0');
  return hex;
}

/**
 * Converts RGB color components to a hexadecimal color value.
 * @param {number} r - The red component (0-255).
 * @param {number} g - The green component (0-255).
 * @param {number} b - The blue component (0-255).
 * @returns {string} The hexadecimal color value.
 */
export function rgb_to_hex(r, g, b) {
  const hexR = component_to_hex(r);
  const hexG = component_to_hex(g);
  const hexB = component_to_hex(b);

  return `#${hexR}${hexG}${hexB}`;
}

/**
 * Converts a hexadecimal color value to its RGB representation.
 * @param {string} hex - The hexadecimal color value to be converted.
 * @returns {string|null} The RGB representation of the color, or null if the input is invalid.
 */
export function hex_to_rgb(hex) {
  // Use a regular expression to match and extract color components from the hexadecimal string
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  // If the regular expression did not match, return null
  if (!result) return null;

  // Extract individual color components and convert them to decimal values
  const red = parseInt(result[1], 16);
  const green = parseInt(result[2], 16);
  const blue = parseInt(result[3], 16);

  return `rgb(${red},${green},${blue})`;
}

/**
 * Converts a hexadecimal color value to its RGBA representation.
 * @param {string} hex - The hexadecimal color value to be converted.
 * @returns {string} The RGBA representation of the color.
 * @throws {Error} Thrown when the input is not a valid hexadecimal color.
 */
function hex_to_rgba(hex) {
  // Check if the input is a valid hexadecimal color
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    // Remove '#' and split the color components
    const components = hex.substring(1).split('');

    // Expand short color notation to full notation if necessary
    if (components.length === 3) {
      components.push(...components);
    }

    // Combine components into a single hexadecimal value
    const combinedHex = '0x' + components.join('');

    // Extract individual color components
    const red = (combinedHex >> 16) & 255;
    const green = (combinedHex >> 8) & 255;
    const blue = combinedHex & 255;

    // Construct and return the RGBA color string
    return 'rgba(' + [red, green, blue].join(',') + ',1)';
  }
  // Throw an error for invalid input
  throw new Error('Invalid hexadecimal color');
}

/**
 * Converts an RGBA color value to its hexadecimal representation.
 * @param {string} color - The RGBA color value to be converted.
 * @param {Object} options - Additional options.
 * @param {boolean} options.includeAlpha - Whether to include the alpha value in the hexadecimal output.
 * @returns {string} The hexadecimal representation of the color.
 */
function rgba_to_hex(color, { includeAlpha = false } = {}) {
  // Remove whitespace and extract color components from the RGBA string
  const colorComponents = color.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i);

  const red = parseInt(colorComponents[1], 10) | (1 << 8);
  const green = parseInt(colorComponents[2], 10) | (1 << 8);
  const blue = parseInt(colorComponents[3], 10) | (1 << 8);
  const alpha = ((colorComponents && colorComponents[4]) || '').trim() || '1';

  const alphaHex = ((parseFloat(alpha) * 255) | (1 << 8)).toString(16).slice(1);

  // Convert color components to hexadecimal
  const hex =
    red.toString(16).slice(1) +
    green.toString(16).slice(1) +
    blue.toString(16).slice(1) +
    (includeAlpha ? alphaHex : '');

  return '#' + hex;
}

/**
 * Lightens a hexadecimal color by a specified variation amount.
 * @param {string} color - The hexadecimal color value to be lightened.
 * @param {number} variation - The amount by which to lighten the color.
 * @returns {string} The lightened hexadecimal color value.
 */
export default function lighten_hex_color(color, variation) {
  const rgbaColor = hex_to_rgba(color);
  const newColor = RGB_Linear_Shade(variation, rgbaColor);
  return rgba_to_hex(newColor);
}

/**
 * Calculates the luminance of a hexadecimal color.
 * @param {string} hex - The hexadecimal color value.
 * @returns {number} The luminance value of the color (0-1).
 */
export function get_hex_color_luminance(hex) {
  // Extract the red, green, and blue components from the hexadecimal color
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  const r = parseInt(result[1], 16);
  const g = parseInt(result[2], 16);
  const b = parseInt(result[3], 16);
  // Calculate the luminance using the formula: Y = 0.299*R + 0.587*G + 0.114*B
  const luminance = (r * 0.299 + g * 0.587 + b * 0.114) / 256;
  return luminance;
}
