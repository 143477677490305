import { useEffect, useMemo } from 'react';
import { NOW_CIRCLE_OFFSET } from '../config';

export default function Now({ now = new Date(), compute_initial_position }) {
  // const scroll_to_now = () => {
  //   const now_node = document.getElementById('now')
  //   now_node.scrollIntoView({ behavior: 'smooth', block: 'center' })
  // }

  useEffect(() => {
    const interval = setInterval(() => (now = new Date()), 3000);
    return () => clearInterval(interval);
  }, []);

  const now_position = useMemo(() => {
    return compute_initial_position({ start: now, end: now }, { use_padding: false });
  }, [now, compute_initial_position]);

  return (
    <div
      className="bg-red-500 w-full h-px absolute"
      style={{ top: now_position.top, zIndex: 2 }}
      id="now"
    >
      <div className="relative">
        <svg
          style={{ left: now_position.left - NOW_CIRCLE_OFFSET }}
          className="absolute -top-1 w-2 h-2"
          viewBox="0 0 10 10"
        >
          <circle
            cx="5"
            cy="5"
            r="5"
            className="fill-red-500"
          />
        </svg>
        <time
          className="hidden"
          dateTime={now.toISOString()}
        >
          {now.toLocaleTimeString()}
        </time>
      </div>
    </div>
  );
}
