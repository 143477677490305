export const FRENCH = {
  key: 'fr',
  label: 'Français'
};

export const ENGLISH = {
  key: 'en',
  label: 'English'
};

export const ITALIAN = {
  key: 'it',
  label: 'Italian'
};

export const GERMAN = {
  key: 'de',
  label: 'German'
};

export const SPANISH = {
  key: 'es',
  label: 'Spanish'
};

export const PORTUGUESE = {
  key: 'pt',
  label: 'Portuguese'
};

export const JAPANESE = {
  key: 'ja',
  label: 'Japanese'
};

export const KOREAN = {
  key: 'ko',
  label: 'Korean'
};

export const VIETNAMESE = {
  key: 'vi',
  label: 'Vietnamese'
};

export const ARABIC = {
  key: 'ar',
  label: 'Arabic'
};

export const CHINESE = {
  key: 'zh',
  label: 'Chinese'
};

export const INDONESIAN = {
  key: 'id',
  label: 'Indonesian'
};

export const languages = [
  FRENCH,
  ENGLISH,
  ITALIAN,
  GERMAN,
  SPANISH,
  PORTUGUESE,
  JAPANESE,
  KOREAN,
  VIETNAMESE,
  ARABIC,
  CHINESE,
  INDONESIAN
];

export const languagesObject = {
  fr: FRENCH,
  en: ENGLISH,
  it: ITALIAN,
  de: GERMAN,
  es: SPANISH,
  pt: PORTUGUESE,
  ja: JAPANESE,
  ko: KOREAN,
  vi: VIETNAMESE,
  ar: ARABIC,
  zh: CHINESE,
  id: INDONESIAN
};

export const countryLangage = {
  fr: 'FR',
  en: 'GB',
  it: 'IT',
  de: 'DE',
  es: 'ES',
  pt: 'PT',
  ja: 'JP',
  ko: 'KR',
  vi: 'VN',
  ar: 'SA',
  zh: 'CN',
  id: 'ID'
};
