import { Checkbox, TextField, FormControlLabel, Grid, makeStyles, InputAdornment, Typography } from '@material-ui/core';

import { Field, useField, useFieldObserver } from 'frmx';
import GoogleMapsLocation from 'components/inputs/GMLocationField';
import ManualLocationField from 'components/inputs/LocationField';
import EntityX from 'components/inputs/EntityX';
import Scope from 'entities/Scope/Scope';
import Location from './Location';
import EntityBreadcrumbs from 'layouts/entities/EntityBreadcrumbs';
import { useEntity } from 'contexts/entities/entityContext';
import { useConfiguration } from 'hooks/useConfiguration';
import Client from 'entities/Client/Client';
import DisplayMore from 'components/DisplayMore';
import { useTranslation } from 'react-i18next';
import PhoneField from 'components/inputs/PhoneField';
import FAIcon from 'components/ui/FAIcon';
import { useAuth } from 'hooks/useAuth';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  _facturation: {},
  _facturationCheckbox: {
    marginBottom: 10,
    ['& span']: {
      fontSize: 12,
      fontWeight: 500
    }
  }
}));

export default function LocationForm({ element }) {
  const { location_sectors } = useConfiguration();

  const [manualAddressIsActivated, setManualAddressIsActivated] = useState(false);

  const { value: isSameFacturation, setValue: setIsSameFacturation } = useField('isSameFacturation');
  const { value: address, setValue: setAddress } = useField('address');
  const { value: email, setValue: setEmail, error: emailError } = useField('contact_email');
  const {
    value: accountingEmail,
    setValue: setAccountingEmail,
    error: accountingEmailError
  } = useField('accounting_email');

  const auth = useAuth();
  const company = auth?.interface?._company;

  const config = useConfiguration();
  const location_accounting_feature_is_activated = config.comptability?.location_accounting;

  const name = useFieldObserver('name');
  const isFacturation = useFieldObserver('isFacturation');

  const { t } = useTranslation();

  const { sublistParentKey, sublistParent } = useEntity();

  const classes = useStyles();

  return (
    <>
      {!!sublistParent && (
        <EntityBreadcrumbs
          newElement={name}
          element={sublistParent}
          sublistParentKey={sublistParentKey}
        />
      )}

      {!sublistParent && (config.isContractor || config.isMaster) && (
        <Grid
          item
          xs={12}
        >
          <EntityX
            path="_client"
            entityName="clients"
            entity={Client}
            entityProps={{
              hiddenFilters: {
                authorisations: ['location']
              }
            }}
            placeholder={t('client')}
            pickerUniq
          />
        </Grid>
      )}

      {!sublistParent && (
        <Grid
          item
          xs={12}
        >
          {manualAddressIsActivated ? (
            <ManualLocationField
              autoFocus
              path="address"
              setValue={setAddress}
            />
          ) : (
            <GoogleMapsLocation
              autoFocus
              path="address"
              setValue={setAddress}
            />
          )}
          <div className="flex items-center ml-2 pt-2">
            <Typography
              color="primary"
              variant="p"
              onClick={() => setManualAddressIsActivated(!manualAddressIsActivated)}
              className="cursor-pointer"
            >
              {manualAddressIsActivated ? t('google-address') : t('manual-address')}
            </Typography>
          </div>
        </Grid>
      )}

      <Grid
        item
        xs={12}
      >
        <Field
          path="name"
          isErrorProp="error"
        >
          <TextField
            variant="outlined"
            fullWidth
            label={isFacturation ? t('title2') : sublistParent ? t('sublocationName') : t('locationName')}
          />
        </Field>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <Field path="number">
          <TextField
            variant="outlined"
            fullWidth
            label={t('reference')}
          />
        </Field>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Field
          path="informations"
          isErrorProp="error"
        >
          <TextField
            variant="outlined"
            fullWidth
            label={t('informations')}
          />
        </Field>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <DisplayMore label={t('additionalFields')}>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FAIcon
                        style={{ fontSize: 16, height: 16, width: 16 }}
                        collection="fal"
                        icon="at"
                      />
                    </InputAdornment>
                  )
                }}
                error={emailError}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                variant="outlined"
                label={t('email')}
                placeholder={t('location-email-field-placeholder')}
                helperText={emailError ? t('noEmailSupplierHelperText') : ''}
                fullWidth
              />
            </Grid>

            {location_accounting_feature_is_activated && (
              <Grid
                item
                xs={12}
              >
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FAIcon
                          style={{ fontSize: 16, height: 16, width: 16 }}
                          collection="fal"
                          icon={company?.currency?.icon || 'euro-sign'}
                        />
                      </InputAdornment>
                    )
                  }}
                  error={emailError}
                  value={accountingEmail}
                  onChange={(e) => setAccountingEmail(e.target.value)}
                  variant="outlined"
                  label={t('location-accounting-email-field')}
                  placeholder={t('location-accounting-email-field-placeholder')}
                  helperText={accountingEmailError ? t('noEmailSupplierHelperText') : ''}
                  fullWidth
                />
              </Grid>
            )}

            <Grid
              item
              xs={12}
            >
              <PhoneField
                path="contact_phone"
                label={t('phoneNumber')}
                variant="outlined"
                placeholder={t('phoneNumber')}
              />
            </Grid>

            {
              <Grid
                item
                xs={12}
              >
                <EntityX
                  path={'_type'}
                  entity={Scope}
                  entityProps={{ type: 'locationCategory' }}
                  placeholder={t('category')}
                  pickerUniq
                />
              </Grid>
            }

            {location_sectors && (
              <Grid
                item
                xs={12}
              >
                <EntityX
                  path={'_sector'}
                  entity={Scope}
                  entityProps={{ type: 'sectors' }}
                  placeholder={t('entityScoSecName')}
                  pickerUniq
                />
              </Grid>
            )}

            {!sublistParent && !isFacturation && (
              <Grid
                item
                xs={12}
                className={classes._facturation}
              >
                <FormControlLabel
                  className={classes._facturationCheckbox}
                  control={
                    <Checkbox
                      checked={isSameFacturation}
                      onChange={(e) => setIsSameFacturation(e.target.checked)}
                    />
                  }
                  label={t('billingAddressIsTheSame')}
                />
                {!isSameFacturation && (
                  <EntityX
                    path={isSameFacturation && address ? 'address' : '_facturation'}
                    entity={Location}
                    entityName="locations"
                    entityProps={{
                      facturation: true
                    }}
                    disabled={isSameFacturation}
                    placeholder={t('billingAddress')}
                    pickerUniq
                  />
                )}
              </Grid>
            )}
          </Grid>
        </DisplayMore>
      </Grid>
    </>
  );
}
