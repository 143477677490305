import { add } from 'date-fns';
import { ItemTypes } from 'constants/dnd';
import { useDrop } from 'react-dnd';
import {
  CELL_UNIQUE_CLASSNAME,
  EVENT_PADDING,
  HOUR_HEIGHT,
  PREVIEW_TYPE,
  SET_RANGE_EVENT_ID
} from 'modules/calendar/config';
import classNames from 'utils/classNames';
import axios from 'axios';
import { apiBaseURL } from 'index';
import { Event as EventClass, get_event_color } from '../utils';
import { useAuth } from 'hooks/useAuth';
import { get_company_id_from_auth } from 'utils/authUtils';
import Event from './Event';
import { useEffect, useMemo, useState } from 'react';
import { useConfiguration } from 'hooks/useConfiguration';
import { nanoid } from 'nanoid';
import { trigger } from 'react-events-utils';
import { bubbleDownCells } from 'modules/calendar/utils';

export default function Cell({
  start,
  end,
  idx,
  set_events,
  set_unavailabilities,
  top,
  left,
  width,
  height,
  zIndex,
  set_open_dialog_actions,
  selected_range
}) {
  const auth = useAuth();
  const company_id = get_company_id_from_auth(auth);
  const [preview_event, set_preview_event] = useState(null);
  const config = useConfiguration();

  const cell_id = useMemo(() => nanoid(), []);

  useEffect(() => {
    const cell = document.getElementById(cell_id);

    if (cell) {
      cell.addEventListener('mousedown', () => {
        trigger(SET_RANGE_EVENT_ID, { start, start_idx: idx });
      });
      cell.addEventListener('mouseenter', () => {
        if (selected_range.start_idx && selected_range.end_idx !== idx && idx > selected_range.start_idx) {
          trigger(SET_RANGE_EVENT_ID, { end: end, end_idx: idx });
        }
      });
      cell.addEventListener('mouseup', () => {
        set_open_dialog_actions(true);
      });
    }
  }, []);

  const is_selected = useMemo(() => {
    if (selected_range.start_idx && selected_range.end_idx) {
      return idx >= selected_range.start_idx && idx <= selected_range.end_idx;
    } else {
      return false;
    }
  }, [selected_range]);

  const update_unavailability_date = ({ unavailability_id, event_id, duration_in_minutes }) => {
    const end = add(new Date(start), {
      minutes: duration_in_minutes || config?.default_intervention_date_duration || 60
    });

    return axios
      .put(apiBaseURL + `/unavailabilities/${unavailability_id}`, {
        start,
        end: add(new Date(start), {
          minutes: duration_in_minutes || config?.default_intervention_date_duration || 60
        })
      })
      .then((res) => {
        set_unavailabilities((unavailabilities) =>
          unavailabilities.map((unavailability) => {
            return unavailability.unavailability_id !== unavailability_id
              ? unavailability
              : { ...unavailability, start, end };
          })
        );
      });
  };

  const update_ticket_date = ({ ticket_id, event_id, duration_in_minutes, date_type, done }) => {
    return axios
      .put(apiBaseURL + `/tickets/${ticket_id}/date/prop`, {
        value: start,
        type: date_type,
        prop: 'date',
        isParent: Boolean(config?.isContractor),
        index: 0
      })
      .then((res) => {
        const ticket = res.data.element;

        const event = new EventClass({
          ticket_id,
          start,
          done,
          end: add(new Date(start), {
            minutes: duration_in_minutes || config?.default_intervention_date_duration || 60
          }),
          title: ticket.title,
          description: ticket.description,
          created_at: new Date(),
          color: get_event_color({ ticket, company_id })
        });

        set_events((events) => [...events.filter((e) => e._id !== event_id), event]);
      })
      .catch((err) => {
        console.log({ err });
      })
      .finally(() => {
        set_preview_event(null);
        bubbleDownCells();
      });
  };

  const add_date_to_ticket = ({ ticket_id, start }) => {
    return axios
      .put(apiBaseURL + `/tickets/${ticket_id}/date`, {
        date: start,
        isParent: Boolean(config?.isContractor),
        type: 'visit'
      })
      .then((res) => {
        const ticket = res.data.element;

        const event = new EventClass({
          ticket_id,
          start,
          end: add(new Date(start), { minutes: config?.default_intervention_date_duration || 60 }),
          title: ticket.title,
          description: ticket.description,
          created_at: new Date(),
          color: get_event_color({ ticket, company_id })
        });

        set_events((events) => [...events, event]);
      })
      .catch((err) => {
        console.log({ err });
      })
      .finally(() => {
        set_preview_event(null);
        bubbleDownCells();
      });
  };

  const [{ isOver, item }, drop] = useDrop(() => {
    return {
      accept: [ItemTypes.TICKET_PREVIEW, ItemTypes.CALENDAR_EVENT],
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        item: monitor.getItem()
      }),
      drop: (item) => {
        const is_updated_event = item?.event_id;
        const is_ticket = item?.ticket_id;
        const is_unavailability = item?.unavailability_id;

        if (is_updated_event && is_ticket) {
          update_ticket_date(item);
        } else if (is_ticket) {
          add_date_to_ticket({
            start,
            end: add(new Date(start), {
              minutes: config?.default_intervention_date_duration || 60
            }),
            ticket_id: item.ticket_id
          });
        } else if (is_unavailability) {
          update_unavailability_date(item);
        }
      }
    };
  }, []);

  useEffect(() => {
    set_preview_event(isOver ? item : null);
  }, [item, isOver]);

  return (
    <li
      ref={drop}
      id={cell_id}
      style={{
        position: 'absolute',
        display: 'relative',
        top,
        left,
        width,
        height,
        zIndex
      }}
      className={classNames(
        CELL_UNIQUE_CLASSNAME,
        'w-full hover:bg-sky-200 hover:bg-opacity-30',
        is_selected && 'bg-sky-200 bg-opacity-30'
      )}
    >
      {preview_event && (
        <Event
          {...preview_event}
          component="div"
          start={start}
          type={PREVIEW_TYPE}
          top={0}
          left={0}
          width={`calc(100% - ${EVENT_PADDING * 2}px)`}
          height={
            preview_event?.duration_in_minutes ? (HOUR_HEIGHT / 60) * preview_event.duration_in_minutes : HOUR_HEIGHT
          }
          zIndex={10}
        />
      )}
    </li>
  );
}
