import { useField } from 'frmx';
import FAIcon from 'components/ui/FAIcon';
import { useTranslation } from 'react-i18next';
import { getColor } from 'router/Corentin/utils';

export default function SectionPicker({ path, label, color = 'green' }) {
  const { t } = useTranslation();
  const { value: isActive, setValue: setIsActive } = useField(path);
  const {
    activeIconColor,
    inactiveIconColor,
    inactiveTextColor,
    activeTextColor,
    inactiveBorderColor,
    activeBorderColor
  } = getColor(color);

  return (
    <button
      onClick={() => setIsActive((prev) => !prev)}
      className={`cursor-pointer border ${isActive ? activeBorderColor : inactiveBorderColor}  ${
        isActive ? activeTextColor : inactiveTextColor
      } inline-flex text-gray-500 items-center pl-2 pr-3 h-8 rounded-full text-xs font-medium hover:bg-gray-50 transition`}
    >
      <FAIcon
        collection={isActive ? 'fas' : 'fal'}
        icon={isActive ? 'circle-check' : 'circle'}
        size="small"
        className={`mr-1 ${isActive ? activeIconColor : inactiveIconColor}`}
      />
      <span className="whitespace-nowrap">{t(label)}</span>
    </button>
  );
}
