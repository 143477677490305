import clsx from 'clsx';

import useStyles from './Filter.styles';

export default function FilterEntity({
  filter: {
    key,
    params: { entity: Entity, entityProps = {} }
  }
}) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.filter, classes.entity)}>
      <Entity
        picker
        pickerField={key}
        filter={true}
        {...entityProps}
      />
    </div>
  );
}
