import FAIcon from 'components/ui/FAIcon';
import classNames from 'utils/classNames';
import IconButton from 'components/IconButton';

export default function FolderPath({
  viewTrash,
  setViewTrash,
  folderPath,
  setFolderPath,
  setSelectedDocumentId,
  isLoading,
  getDocuments
}) {
  return (
    <>
      <div className="flex w-full justify-between border-b">
        <div
          onContextMenu={(event) => event.preventDefault()}
          className="flex h-10 grow-0 shrink-0"
        >
          <div
            className="bg-white flex"
            aria-label="Breadcrumb"
          >
            <ol className="w-full mx-auto flex space-x-4">
              <li className="flex">
                <button
                  onClick={() => {
                    setViewTrash(false);
                    setFolderPath((fp) => (fp.length > 0 ? [] : fp));
                  }}
                  className="flex items-center cursor-pointer"
                >
                  <FAIcon
                    collection="fad"
                    icon="folder-open"
                    size="sm"
                    className="flex-shrink-0 text-gray-400 hover:text-gray-500 ml-6 mr-4"
                  />
                </button>
                <NextIcon />
              </li>
              {viewTrash && (
                <li className="flex">
                  <button
                    disabled={folderPath?.length === 0}
                    onClick={() => {
                      const selectedDocument = folderPath.at(0);
                      setSelectedDocumentId(selectedDocument._id);
                      setFolderPath((fp) => (fp.length > 0 ? [] : fp));
                    }}
                    className={`flex items-center ${
                      folderPath.length > 0 ? 'cursor-pointer' : 'cursor-default'
                    }`}
                  >
                    <FAIcon
                      collection="fad"
                      icon="trash-can"
                      size="sm"
                      className={classNames(
                        'flex-shrink-0 text-gray-400 ml-2 mr-4',
                        folderPath.length > 0 ? 'hover:text-gray-500' : 'cursor-default'
                      )}
                    />
                  </button>
                  <NextIcon />
                </li>
              )}
              {folderPath.length > 1 && (
                <li className="flex">
                  <button
                    onClick={() => {
                      const selectedDocument = folderPath.at(-1);
                      setSelectedDocumentId(selectedDocument._id);
                      setFolderPath((fp) => [...fp.slice(0, -1)]);
                    }}
                    className="flex items-center cursor-pointer"
                  >
                    <FAIcon
                      collection="fas"
                      icon="ellipsis"
                      size="sm"
                      className="flex-shrink-0 text-gray-400 hover:text-gray-500 ml-2 mr-6"
                    />
                  </button>
                  <NextIcon />
                </li>
              )}
              {folderPath.length > 0 && (
                <li className="flex">
                  <button className="flex items-center cursor-default">
                    <p className="text-sm text-gray-500 font-medium">{folderPath.at(-1)?.name}</p>
                  </button>
                </li>
              )}
            </ol>
          </div>
        </div>
        <IconButton
          button
          disabled={isLoading}
          className="mx-2 w-7 h-7 border-2 border-gray-400"
          onClick={() => getDocuments()}
        >
          <FAIcon
            collection="fas"
            icon="rotate"
            className={classNames('text-gray-500', isLoading ? 'animate-spin' : '')}
            size="sm"
          />
        </IconButton>
      </div>
    </>
  );
}

function NextIcon() {
  return (
    <svg
      className="flex-shrink-0 w-6 h-full text-gray-200"
      viewBox="0 0 24 44"
      preserveAspectRatio="none"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
    </svg>
  );
}
