import { createSlice } from '@reduxjs/toolkit';
import { pick } from 'lodash-es';
import generateAsyncThunk from 'utils/generateAsyncThunk';

export const getDocumentTags = generateAsyncThunk({ type: 'GET', endpoint: 'ged/tags' });
export const createTag = generateAsyncThunk({ type: 'POST', endpoint: 'ged/tags' });
export const updateTag = generateAsyncThunk({ type: 'PUT', endpoint: 'ged/tags/${id}' });
export const deleteTag = generateAsyncThunk({ type: 'DELETE', endpoint: 'ged/tags/${id}' });

const initialState = {
  tags: []
};

export const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  extraReducers: {
    [getDocumentTags.fulfilled]: (state, action) => {
      state.tags = action.payload.data.tags;
    },
    [createTag.fulfilled]: (state, action) => {
      const tag = action.payload.data.tag;
      state.tags.push(tag);
    },
    [updateTag.fulfilled]: (state, action) => {
      state.tags = state.tags.map((tag) => {
        if (tag._id === action.meta.arg._id) {
          return { ...tag, ...pick(action.meta.arg, ['label', 'color']) };
        }
        return tag;
      });
    },
    [deleteTag.fulfilled]: (state, action) => {
      const { id } = action.meta.arg.params;
      state.tags = state.tags.filter((tag) => tag._id !== id);
    }
  }
});

export const selectTags = (state) => state.tags;
export default tagsSlice.reducer;
