const UserExport = (configuration) =>
  [
    { label: 'firstName', key: 'firstName', show: true },
    { label: 'lastName', key: 'lastName', show: true },
    { label: 'email', key: 'email', show: true },
    { label: 'phoneNumber', key: 'phone', show: true },
    { label: 'locationsTitle', key: '_locations', show: true },
    { label: 'address', key: 'address', show: true },
    { label: 'interfaceActivated', key: 'userInterface', show: true }
  ].filter((e) => e.show);

export default UserExport;
