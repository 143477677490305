const StockTranslations = (configuration) => ({
  pick: 'entityStockPick',
  createDialog: 'entityStockCreateDialog',
  updateDialog: 'entityStockUpdateDialog',
  create: 'entityStockCreate',
  update: 'entityStockUpdate',
  entityName: 'entityStockName',
  noResultLabel: 'entityStocknoResultLabel',
  searchText: 'entityStockSearchText'
});

export default StockTranslations;
