import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  AppBar: {
    // background: `${theme.palette.primary.main}`,
    backgroundImage: `linear-gradient(to right, ${theme.palette.primary.main} 0%, ${theme.palette.primary.light} 100%)`,
    ...theme.transitionTheme,
    ...theme.mixins.toolbar
  },
  listItemBadge: {
    ['& span']: {
      color: 'white',
      backgroundColor: '#cc0000'
    }
  },
  toolbar: {
    paddingLeft: 24,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 20
    },
    paddingRight: 0
  },
  toolbarMargin: {
    ...theme.mixins.toolbar
  },
  drawerButton: {
    marginRight: 16
  },
  teamButton: {
    color: 'white',
    position: 'relative',
    left: 6
  },
  drawerButtonIcon: {
    color: '#ffffff',
    width: '1em',
    height: '1em',
    fontSize: '1em'
  },
  left: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center'
  },
  listItem: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  logoLink: {
    userSelect: 'none'
  },
  logo: {
    height: 32,
    position: 'relative',
    bottom: 3
  },
  wifiIcon: {
    color: 'white',
    margin: '0em 1.5em'
  },
  interfaceButton: {
    margin: '0em 1em'
  }
}));
