import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Error404({ title, primary, secondary }) {
  const { t } = useTranslation();
  return (
    <div className="flex h-screen w-screen items-center justify-center flex-col gap-6 text-black">
      <img
        alt="error"
        className="w-96"
        src="http://bob-desk.fr/wp-content/uploads/2021/01/Bob-Desk-GMAO-connecte-vos-entites-opt-01-01-2.png"
      />
      <h3 className="font-medium text-5xl uppercase">{title || t('ooops')}</h3>
      <div className="text-center">
        <p className="font-medium text-lg">{primary || t('thisPageIsUnavailable')}</p>
        <p className="text-base">{secondary || t('theLinkYouFollowedWasMaybeDeleted')}</p>
      </div>
    </div>
  );
}
