import AutoAssignation from 'entities/Intervener/Tabs/AutoAssignation';
import Maintenance from 'entities/Maintenance/Maintenance';
import TechnicianTabs from 'entities/Technician/TechnicianTabs';
import Ticket from 'entities/Ticket/Ticket';
import Contract from 'entities/Contract/Contract';
import { Box, Button, Card, Drawer, Fab, IconButton, List, ListItem, TextField, Typography } from '@material-ui/core';
import useKeys from '@flowsn4ke/usekeys';
import { useTranslation } from 'react-i18next';
import BoxWrapperScrollable from 'components/BoxWrapperScrollable';
import Documents from 'modules/documents/DMS';
import { useEffect, useState } from 'react';
import { apiBaseURL } from 'index';
import axios from 'axios';
import FAIcon from 'components/ui/FAIcon';
import useStyles from 'layouts/entities/Preview.styles';
import useDrawerStyles from 'components/dialogs/Dialog.styles';
import classNames from 'classnames';
import UploadButton from 'modules/documents/UploadButton';
import { DatePicker } from '@material-ui/pickers';
import { Controller, useForm } from 'react-hook-form';
import { add, isAfter } from 'date-fns';
import { yupResolver } from '@hookform/resolvers/yup';
import { date, object, string } from 'yup';
import { dateToLocalFormat } from 'utils/dates';
import useConfirm from 'hooks/useConfirm';
import useFileViewer from 'hooks/useFileViewer';
import { can_preview_file } from 'modules/documents/utils';
import { Empty } from 'layouts/entities/List';
import { useAuth } from 'hooks/useAuth';
import NotesFeed from 'modules/notes/NotesFeed';

const ContractorTabs = (config, element, role, auth) => {
  return (
    !!element &&
    (element.isCollaborator
      ? TechnicianTabs(config, element, role)
      : [
          {
            label: 'entityTicketName',
            icon: 'files',
            count: element.tabs?.tickets,
            show: !!element.partner,
            render: () => (
              <BoxWrapperScrollable>
                <Ticket
                  disableGutters
                  defaultFilters={{ interveners: [element] }}
                />
              </BoxWrapperScrollable>
            )
          },
          {
            label: 'menuItemMaintenances',
            icon: 'heart-pulse',
            count: element.tabs?.maintenances,
            show: !!element.partner && config.feature.preventif,
            render: () => (
              <BoxWrapperScrollable>
                <Maintenance
                  disableGutters
                  defaultFilters={{ interveners: [element] }}
                />
              </BoxWrapperScrollable>
            )
          },
          {
            label: 'automatisationsTitle',
            icon: 'bolt',
            iconCollection: 'fas',
            count: element?.autoassigns?.length || 0,
            show: !!element.partner && role?.permission('automatisations', 'read'),
            render: () => (
              <AutoAssignation
                intervener={element}
                kind="Contractor"
              />
            )
          },
          {
            label: 'contractsTitle',
            icon: 'file-contract',
            count: element.tabs?.contracts,
            show: config.feature.contract && role?.permission('contracts', 'read'),
            render: () => (
              <Contract
                disableGutters
                defaultForm={{ _intervener: element }}
                hiddenFilters={{ interveners: [element._id] }}
              />
            ),
            class: 'tabNoPadding'
          },
          {
            label: 'bpus-title',
            icon: 'file-contract',
            count: element.tabs?.bpus,
            show: config.feature.contract && role?.permission('contracts', 'read'),
            render: () => <BPUTab company_id={element._id} />,
            class: 'tabNoPadding'
          },
          {
            label: 'menuItemDocuments',
            icon: 'clouds',
            count: element.tabs?.docs || 0,
            show: true, // ? Is this correct? todo check
            render: (element, isLoading) => (
              <Documents
                endpoint_prefix={`intervener_documents/${element._id}`}
                tab_id={element.documents_tab_id}
              />
            )
          },
          {
            label: 'notes',
            icon: 'notes',
            count: element.tabs?.notes || 0,
            show: true,
            render: (element, isLoading) => (
              <div className="w-full h-full bg-white rounded-md p-4">
                <NotesFeed feed_id={element._id} />
              </div>
            )
          },
          {
            label: 'priceAndServicesTitle',
            icon: 'screwdriver-wrench',
            show: element._configuration?.isPricingBenefitsActive,
            render: () => <PriceAndService element={element} />,
            class: 'tabNoPadding'
          }
        ].filter((e) => e.show))
  );
};

function PriceAndService({ element }) {
  const { pricing, benefits } = element._configuration;
  const k = useKeys();
  const { t } = useTranslation();
  const auth = useAuth();
  const company = auth.interface._company;

  return (
    <>
      <Box
        width="100%"
        height="100%"
        maxHeight="100%"
        position="absolute"
        top={0}
        left={0}
        bgcolor="white"
      >
        <List style={{ padding: 12 }}>
          <Card>
            <ListItem>
              <Typography
                variant="h5"
                color="primary"
              >
                {t('ratesExcludeVAT')}
              </Typography>
            </ListItem>
            <ListItem style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography
                variant="subtitle1"
                color="secondary"
              >
                {t('weekDaysTitle')} :{' '}
              </Typography>
              <Typography variant="subtitle1">{`${t('travelTitle')} : ${pricing?.travelWeek} ${
                company?.currency?.symbol || '€'
              } HT - ${t('laborTitle')} : ${pricing?.laborWeek} ${company?.currency?.symbol || '€'} HT`}</Typography>
            </ListItem>
            <ListItem style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography
                variant="subtitle1"
                color="secondary"
              >
                {t('eveningTitle')} :{' '}
              </Typography>
              <Typography variant="subtitle1">{`${t('travelTitle')} : ${pricing.travelEvening} ${
                company?.currency?.symbol || '€'
              } HT - ${t('laborTitle')} : ${pricing.laborEvening} ${company?.currency?.symbol || '€'} HT`}</Typography>
            </ListItem>
            <ListItem style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography
                variant="subtitle1"
                color="secondary"
              >
                {t('weekendAndPublicHolidaysTitle')} :{' '}
              </Typography>
              <Typography variant="subtitle1">{`${t('travelTitle')} : ${pricing.travelWeekend} ${
                company?.currency?.symbol || '€'
              } HT - ${t('laborTitle')} : ${pricing.laborWeekend} ${company?.currency?.symbol || '€'} HT`}</Typography>
            </ListItem>
          </Card>

          {benefits.length > 0 && (
            <Card style={{ marginTop: 12 }}>
              <ListItem>
                <Typography
                  variant="h5"
                  color="primary"
                >
                  {t('servicesOffered')}
                </Typography>
              </ListItem>
              {benefits.map((benefit, i) => {
                const travel = benefit.travel ? `${t('travelTitle')}, ` : '';
                const labor = benefit.labor ? `${t('laborlTitle')}, ` : '';
                const parts = benefit.parts ? `${t('partsTitle')} ${t('included')}` : '';

                return (
                  <ListItem
                    key={k(i)}
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{ marginBottom: 6 }}
                    >{`Métier: ${benefit.job} - ${benefit.description} - ${benefit.price} ${
                      company?.currency?.symbol || '€'
                    } HT`}</Typography>
                    <Typography
                      variant="subtitle2"
                      style={{ marginBottom: 6 }}
                    >{`${travel} ${labor} ${parts}`}</Typography>
                  </ListItem>
                );
              })}
            </Card>
          )}
        </List>
      </Box>
    </>
  );
}

export default ContractorTabs;

const EmptyBpu = ({ title, description }) => {
  return (
    <Empty
      icon="note"
      translations={{
        noResultLabel: title,
        noResultText: description
      }}
    />
  );
};

const AddBpuButton = ({ set_form_is_open }) => {
  return (
    <Fab
      onClick={() => set_form_is_open(true)}
      color="primary"
      size="small"
      style={{
        position: 'absolute',
        bottom: 16,
        right: 16,
        zIndex: 50
      }}
    >
      <FAIcon icon="plus" />
    </Fab>
  );
};

function BPUTab({ company_id }) {
  const [editing_bpu_index, set_editing_bpu_index] = useState(null);
  const [bpus, set_bpus] = useState([]);
  const classes = useStyles();
  const [form_is_open, set_form_is_open] = useState(false);
  const { t } = useTranslation();

  const confirm = useConfirm();
  const { openFiles } = useFileViewer();

  useEffect(() => {
    const bpus_url = `${apiBaseURL}/bpus/${company_id}`;
    axios
      .get(bpus_url)
      .then((res) => {
        set_bpus(res.data.documents);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [company_id]);

  const delete_bpu = async (bpu_id) => {
    const delete_url = `${apiBaseURL}/bpus/${bpu_id}`;

    try {
      await axios.delete(delete_url);
      set_bpus((prev) => prev.filter((bpu) => bpu._id !== bpu_id));
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <BPUForm
        bpu_document={bpus[editing_bpu_index] || undefined}
        set_editing_bpu_index={set_editing_bpu_index}
        form_is_open={form_is_open}
        set_form_is_open={set_form_is_open}
        company_id={company_id}
        editing_bpu_index={editing_bpu_index}
        set_bpus={set_bpus}
      />

      <AddBpuButton set_form_is_open={set_form_is_open} />
      {bpus.length === 0 ? (
        <>
          <EmptyBpu
            title="Aucun BPU"
            description="Vous n'avez pas encore ajouté de Bordereau de Prix Unitaire."
          />
        </>
      ) : (
        <div>
          <ul className="bg-white w-full flex flex-col overflow-scroll h-[400px]">
            {bpus.map((bpu, index) => {
              const today_is_after_start_date = isAfter(new Date(), new Date(bpu.metadata?.start_date));
              const end_date_is_after_today = isAfter(new Date(bpu.metadata?.end_date), new Date());
              const is_active_bpu = today_is_after_start_date && end_date_is_after_today;
              const can_preview_bpu = can_preview_file(bpu);

              return (
                <li
                  key={bpu._id}
                  className="w-full border-b flex items-center justify-between p-5 group"
                >
                  <div className="flex w-full items-center">
                    <FAIcon
                      collection="fal"
                      icon="file-contract"
                      className={is_active_bpu ? classes.success : end_date_is_after_today ? '' : classes.error}
                      style={{ marginRight: 16 }}
                    />
                    <div className="flex flex-col gap-y-2">
                      <p className={classes.title}>{bpu.name}</p>
                      {Boolean(bpu.metadata?.start_date) && (
                        <p className={classes.l}>
                          {t('start-at')} {dateToLocalFormat(new Date(bpu.metadata.start_date), 'PPP')}
                        </p>
                      )}
                      {Boolean(bpu.metadata?.end_date) && (
                        <p className={classes.l}>
                          {t('finish-at')} {dateToLocalFormat(new Date(bpu.metadata.end_date), 'PPP')}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="flex gap-x-2">
                    {can_preview_bpu && (
                      <IconButton
                        onClick={() => openFiles(bpus, index)}
                        className="hidden group-hover:block p-3 text-slate-400 hover:text-slate-600"
                      >
                        <FAIcon
                          icon="eye"
                          collection="fas"
                        />
                      </IconButton>
                    )}
                    <IconButton
                      onClick={() => [set_editing_bpu_index(index), set_form_is_open(true)]}
                      className="hidden group-hover:block p-3 text-slate-400 hover:text-slate-600"
                    >
                      <FAIcon
                        icon="pencil"
                        collection="fas"
                      />
                    </IconButton>
                    <IconButton
                      onClick={() =>
                        confirm({
                          title: t('delete-bpu-comfirmation'),
                          icon: 'trash',
                          onConfirm: () => delete_bpu(bpu._id)
                        })
                      }
                      className="hidden group-hover:block p-3 text-slate-400 hover:text-slate-600"
                    >
                      <FAIcon
                        icon="trash"
                        collection="fas"
                      />
                    </IconButton>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
}

const BPUFormSchema = object({
  _id: string().required('Vous devez charger un fichier.'),
  name: string().min(3, 'Votre nom doit comporter au moins trois lettres.'),
  metadata: object({
    start_date: date().required('Vous devez spécifier une date de début.'),
    end_date: date().required('Vous devez spécifier une date de fin.')
  })
});

function BPUForm({
  set_editing_bpu_index,
  editing_bpu_index,
  bpu_document,
  form_is_open,
  set_form_is_open,
  company_id,
  set_bpus
}) {
  const [uploading, set_uploading] = useState(false);
  const [uploaded_document, set_uploaded_document] = useState(null);
  const { t } = useTranslation();

  const is_editing_bpu = Boolean(bpu_document);

  const defaultValues = {
    _id: null,
    name: '',
    metadata: {
      start_date: new Date(),
      end_date: add(new Date(), { years: 1 })
    }
  };

  const {
    control,
    handleSubmit,
    setValue,
    trigger,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(BPUFormSchema),
    defaultValues
  });

  useEffect(() => {
    reset(bpu_document);
  }, [bpu_document]);

  const drawer_classes = useDrawerStyles();

  const upload_bpu = async (files) => {
    set_uploading(true);

    try {
      const upload_url = `${apiBaseURL}/bpus/upload/${company_id}`;
      const data = new FormData();
      const file = files[0];
      data.append('file', file);
      const res = await axios.post(upload_url, data);
      set_uploaded_document(res.data.document);
      setValue('_id', res.data.document._id);
      trigger('_id');
    } catch (err) {
      console.error(err);
    }

    set_uploading(false);
  };
  const create_bpu = async (data) => {
    const create_url = `${apiBaseURL}/bpus/${data._id}`;

    try {
      const res = await axios.put(create_url, data);
      set_bpus((prev) => [res.data.document, ...prev]);
      set_form_is_open(false);
      reset(defaultValues);
    } catch (err) {
      console.error(err);
    }

    set_uploaded_document(null);
  };

  const edit_bpu = async (data) => {
    const edit_url = `${apiBaseURL}/bpus/${data._id}`;

    try {
      const res = await axios.put(edit_url, data);
      set_bpus((prev) => {
        prev[editing_bpu_index] = res.data.document;
        return prev;
      });
      set_form_is_open(false);
      reset(defaultValues);
    } catch (err) {
      console.error(err);
    }

    set_uploaded_document(null);
  };

  return (
    <Drawer
      open={form_is_open}
      onClose={() => [set_form_is_open(false), reset(defaultValues), set_editing_bpu_index(null)]}
      anchor="right"
      elevation={0}
      classes={{
        paper: classNames(drawer_classes.drawer, drawer_classes.drawerContainer)
      }}
    >
      <form
        onSubmit={handleSubmit(is_editing_bpu ? edit_bpu : create_bpu)}
        className="w-full h-full bg-white rounded-md flex flex-col justify-between"
      >
        <div className="bg-white rounded-t-md drop-shadow flex text-black items-center justify-between py-3 px-5 h-26 w-full">
          <div className="flex items-center text-sm font-medium">
            <FAIcon
              icon="file-contract"
              collection="fad"
              className="mr-2"
            />
            <p>{t('bpu-title')}</p>
          </div>

          <IconButton
            onClick={() => [set_form_is_open(false), reset(defaultValues), set_editing_bpu_index(null)]}
            className="ml-auto"
          >
            <FAIcon
              collection="far"
              icon="xmark"
            />
          </IconButton>
        </div>
        <div className="h-full grow-1 w-full p-4 flex flex-col gap-y-4">
          <Controller
            control={control}
            name="name"
            render={({ fieldState: { error }, field: { onChange, value } }) => (
              <TextField
                label={t('name')}
                variant="outlined"
                onChange={onChange}
                value={value}
                error={Boolean(error)}
                helperText={error?.message}
              />
            )}
          />
          <Controller
            control={control}
            name="metadata.start_date"
            render={({ fieldState: { error }, field: { onChange, value } }) => (
              <DatePicker
                label={t('start-date')}
                format="PPP"
                inputVariant="outlined"
                onChange={onChange}
                value={value}
                error={Boolean(error)}
                helperText={error?.message}
              />
            )}
          />
          <Controller
            control={control}
            name="metadata.end_date"
            render={({ fieldState: { error }, field: { onChange, value } }) => (
              <DatePicker
                label={t('end-date')}
                format="PPP"
                inputVariant="outlined"
                onChange={onChange}
                value={value}
                error={Boolean(error)}
                helperText={error?.message}
              />
            )}
          />
        </div>
        <div
          className={classNames(
            is_editing_bpu ? 'justify-end' : 'justify-between',
            'bg-white rounded-b-md border border-t flex items-center text-md p-3 h-16 w-full'
          )}
        >
          {Boolean(!is_editing_bpu) && (
            <UploadButton
              disabled={uploading}
              multiple={false}
              onUpload={(event) => upload_bpu(event.target.files)}
            >
              <div className="text-sm cursor-pointer select-none">
                <Button
                  color="secondary"
                  startIcon={
                    <FAIcon
                      className={classNames(uploading && 'animate-spin')}
                      style={{
                        color: Boolean(errors._id) ? 'red' : Boolean(uploaded_document) ? 'green' : undefined
                      }}
                      icon={uploading ? 'spinner-third' : Boolean(uploaded_document) ? 'check-circle' : 'file-plus'}
                      collection="fas"
                    />
                  }
                >
                  {/* TODO: This button should upload a revision if a new file is uploaded, not create a new file */}
                  <Typography color={Boolean(errors._id) ? 'error' : 'secondary'}>
                    {Boolean(errors._id)
                      ? errors._id?.message
                      : Boolean(uploaded_document)
                      ? t('file-uploaded')
                      : t('file-to-upload')}
                  </Typography>
                </Button>
              </div>
            </UploadButton>
          )}
          <Button
            type="submit"
            disabled={uploading}
            color="secondary"
            variant="contained"
            endIcon={<FAIcon icon="plus" />}
          >
            {t(is_editing_bpu ? 'update' : 'create')}
          </Button>
        </div>
      </form>
    </Drawer>
  );
}
