import FAIcon from 'components/ui/FAIcon';
import { useTranslation } from 'react-i18next';

export default function ContextMenuItem({ closeMenu, menu, content_badge = false }) {
  const { t } = useTranslation();

  return (
    <div
      onContextMenu={(event) => event.preventDefault()}
      className="rounded hover:bg-sky-500 hover:text-white flex cursor-pointer items-center py-2 pr-8"
      onClick={() => [closeMenu(), menu.onClick()]}
    >
      <span className="relative">
        <FAIcon
          collection="fas"
          icon={menu.icon}
          size="small"
          className="mx-1"
        />
        {content_badge && (
          <span className="absolute top-[-3px] right-1 w-2 h-2 bg-sky-600 rounded-full"></span>
        )}
      </span>
      <span className="ml-1 text-sm ">{t(menu.label)}</span>
    </div>
  );
}
