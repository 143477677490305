import { Box, Chip } from '@material-ui/core';

import Avatar from 'components/Avatar';
import useStyles from 'layouts/entities/Preview.styles';
import InterfaceIndicator from 'components/Interface/InterfaceIndicator';
import { useTranslation } from 'react-i18next';
import useClipboard from '../../hooks/useClipboard';
import { useEntity } from '../../contexts/entities/entityContext';
import isMobile from '../../utils/isMobile';

export default function UserPreview({ element, isActive }) {
  const classes = useStyles();

  const { t } = useTranslation();
  const { copy: copyPhone, copied: phoneCopied } = useClipboard();
  const { copy: copyMail, copied: mailCopied } = useClipboard();
  const { page } = useEntity();

  const mail = (element || element._user)?.email?.toLowerCase() || '';
  const phone = (element || element._user)?.phone?.toLowerCase() || '';
  const mobileDetected = isMobile();

  return (
    <>
      <Box
        display="flex"
        style={{ width: '100%' }}
      >
        <Box
          className={classes.avatar}
          style={{ height: '25px', width: '25px' }}
        >
          <Box position="relative">
            <Avatar
              entity={{
                name: '',
                avatar: element.avatar || element._user?.avatar
              }}
              round={true}
              isUser={true}
              shadow={false}
              size="small2"
            />
          </Box>
          <InterfaceIndicator _user={element._user} />
        </Box>

        <Box
          flexGrow={1}
          display="flex"
          alignItems="center"
        >
          <Box className={classes.l}>
            <Box
              display="flex"
              flexDirection="column"
              flexGrow={1}
              minWidth={0}
            >
              <span className={classes.title}>
                {(element || element._user)?.firstName || ''}{' '}
                {(element || element._user)?.lastName?.toUpperCase() || ''}
              </span>
              {!page && mail && (
                <span
                  className={classes.copyable}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (mobileDetected) {
                      window.open(`mailto:${mail}`);
                    } else {
                      copyMail(mail);
                    }
                  }}
                  onMouseDown={(e) => e.stopPropagation()}
                >
                  {mailCopied ? t('copiedToClipBoard') : mail}
                </span>
              )}
              {!page && phone && (
                <span
                  className={classes.copyable}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (mobileDetected) {
                      window.open(`tel:${phone}`);
                    } else {
                      copyPhone(phone);
                    }
                  }}
                  onMouseDown={(e) => e.stopPropagation()}
                >
                  {phoneCopied ? t('copiedToClipBoard') : phone}
                </span>
              )}
            </Box>
          </Box>

          <Box
            display="flex"
            alignItems="center"
          >
            {!!element._role && (
              <Chip
                className={classes.chip}
                color="secondary"
                variant={isActive ? 'default' : 'outlined'}
                size="small"
                label={element._role.name}
              />
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}
