import { createSlice } from '@reduxjs/toolkit';
import generateAsyncThunk from 'utils/generateAsyncThunk';
import { generateEntityBoilerplate } from 'utils/generateEntitySlice';

export const createElement = generateAsyncThunk({ type: 'POST', endpoint: 'contacts' });
export const updateElement = generateAsyncThunk({ type: 'PUT', endpoint: 'contacts/${id}' });
export const getElement = generateAsyncThunk({ type: 'GET', endpoint: 'contacts/${id}' });
export const getElements = generateAsyncThunk({ type: 'POST', endpoint: 'contacts/list' });
export const deleteElement = generateAsyncThunk({ type: 'DELETE', endpoint: 'contacts/${id}' });
export const restoreElement = generateAsyncThunk({
  type: 'POST',
  endpoint: 'contacts/recycle/${id}'
});

export const updateUser = generateAsyncThunk({ type: 'PUT', endpoint: 'users2/${id}' });

export const setUserFilterSets = generateAsyncThunk({ type: 'PUT', endpoint: 'users2/${id}/filters' });
export const updateUserFilterSets = generateAsyncThunk({ type: 'PUT', endpoint: 'users2/${id}/filters' });
export const getUserFilterSets = generateAsyncThunk({ type: 'GET', endpoint: 'users2/${id}/filters' });
export const deleteUserFilterSets = generateAsyncThunk({
  type: 'DELETE',
  endpoint: 'users2/${id}/filters/${filterId}'
});

const entityBoilerplate = generateEntityBoilerplate({
  createElement,
  updateElement,
  getElement,
  getElements,
  deleteElement,
  restoreElement
});

const { initialState, reducers, extraReducers } = entityBoilerplate;

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers,
  extraReducers
});

export const { flushElements, flushElement } = usersSlice.actions;
export default usersSlice.reducer;
