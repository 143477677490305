import { useState, useEffect } from 'react';

export function useSize(ref, callback = () => {}) {
  const [size, setSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const el = ref.current;
    if (!el) return;

    const resizeObserver = new ResizeObserver((entries) => {
      callback(entries[0].contentRect);
      setSize(entries[0].contentRect);
    });

    resizeObserver.observe(el);

    return () => resizeObserver.unobserve(el);
  }, [ref]);

  return size;
}
