import { Box } from '@material-ui/core';
import Timeline from 'components/timeline/Timeline';
import Maintenance from 'entities/Maintenance/Maintenance';
import { useMemo } from 'react';

export default function TimelineView({ element }) {
  const hiddenFilters = useMemo(
    () => ({
      periods: [element.period._id]
    }),
    [element.period._id]
  );
  return (
    <Box
      boxShadow={3}
      bgcolor={'white'}
      borderRadius={8}
      marginTop={'10px'}
      height={'142px'}
      overflow={'hidden'}
    >
      <Maintenance
        timeline
        disableFilter
        hiddenFilters={hiddenFilters}
        disableCreate
        listComponent={({ elements, setIsOpenForm, fetchElements, isLoading, sort }) => {
          return (
            <Timeline
              sort={sort}
              isLoading={isLoading}
              fetchElements={fetchElements}
              ticketView
              maintenanceId={element.period._preventif._id}
              setIsOpenForm={setIsOpenForm}
              elements={elements}
            />
          );
        }}
      />
    </Box>
  );
}
