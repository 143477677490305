import useOutsideClick from 'hooks/useOutsideClick';
import { useState, useRef } from 'react';
import FAIcon from 'components/ui/FAIcon';

import { switchPalette } from 'store/uiSlice';
import { palettes } from 'config/palettes';
import { useDispatch } from 'react-redux';
import { useUI } from 'hooks/useUI';
import useKeys from '@flowsn4ke/usekeys';
import { Check as CheckIcon } from '@material-ui/icons';

export function ColorPickerSettings() {
  const ui = useUI();

  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const contextColorMenuRef = useRef();
  useOutsideClick(contextColorMenuRef, () => {
    setDialogIsOpen(false);
  });

  return (
    <div
      ref={contextColorMenuRef}
      className="inline-flex relative mr-2"
    >
      <ColorPickerButtonSettings
        dialogIsOpen={dialogIsOpen}
        setDialogIsOpen={setDialogIsOpen}
        ui={ui}
      />
      <ColorPickerDialogSettings
        dialogIsOpen={dialogIsOpen}
        ui={ui}
      />
    </div>
  );
}

function ColorPickerButtonSettings({ dialogIsOpen, setDialogIsOpen, ui }) {
  // const icon = dialogIsOpen ? "window-close" : "pencil-alt";
  return (
    <div className="flex">
      <button
        className={`transition rounded-full cursor-pointer w-8 h-8 hover:scale-105`}
        style={{
          backgroundColor: ui.getPaletteColors().primary.main,
          color: '#fff'
        }}
        onClick={() => setDialogIsOpen(!dialogIsOpen)}
      >
        <FAIcon
          collection="fas"
          icon="pencil-alt"
          size="small"
        />
      </button>
    </div>
  );
}

function ColorPickerDialogSettings({ dialogIsOpen, ui }) {
  const syncDispatch = useDispatch();
  const k1 = useKeys();

  if (!Boolean(dialogIsOpen)) return null;

  return (
    <div
      className={`z-10 min-w-max absolute grid grid-cols-3 gap-3 bg-white p-2 rounded-lg shadow-xl`} // absolute grid grid-cols-3 gap-2 bg-white pl-2 pr-2 p-1 pt-2 rounded-lg shadow-lg top-12
      style={{ left: '-118px', top: '-2px' }}
    >
      {Object.keys(palettes).map((p, i) => (
        <button
          key={k1(i)}
          onClick={() => {
            // setColor(palettes[p].primary.main);
            syncDispatch(switchPalette(palettes[p]));
          }}
          className={`transition rounded-full cursor-pointer w-6 h-6 hover:scale-110 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-300`}
          style={{ backgroundColor: palettes[p].primary.main }}
        >
          {ui.getPalette() === palettes[p].name && (
            <CheckIcon style={{ height: `15px`, width: `15px`, color: `white` }} />
          )}
        </button>
      ))}
    </div>
  );
}
