import useKeys from '@flowsn4ke/usekeys';
import { Grid, Select, TextField, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import EntityX from 'components/inputs/EntityX';
import Client from 'entities/Client/Client';
import Equipment from 'entities/Equipment/Equipment';
import Location from 'entities/Location/Location';
import Scope from 'entities/Scope/Scope';
import { Field, useFieldObserver } from 'frmx';
import { useAuth } from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { createElement as create_scope } from 'store/scopesSlice';

const UNITS_VALUE = [
  'kW',
  'm3',
  'm3/min',
  'm2',
  'cm2',
  'km',
  'm',
  'cm',
  'l',
  'l/min',
  'kg',
  'g',
  'N',
  'K',
  '°C',
  '°F',
  'Lm',
  'm/s'
];

export default function MeterForm() {
  const { t } = useTranslation();
  const auth = useAuth();
  const k = useKeys();

  const is_contractor_interface = !auth?.interface.isClient;
  const has_equipment_selected = useFieldObserver('_equipment');
  const has_location_selected = useFieldObserver('_location');
  const _client = useFieldObserver('_client');

  return (
    <>
      <Grid
        item
        xs={12}
      >
        <Field
          path="name"
          isErrorProp="error"
        >
          <TextField
            autoFocus
            variant="outlined"
            label={t('meterName')}
            fullWidth
          />
        </Field>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <Field
          path="description"
          isErrorProp="error"
        >
          <TextField
            multiline
            minRows={3}
            variant="outlined"
            label={t('description')}
            fullWidth
          />
        </Field>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <FormControl
          variant="outlined"
          fullWidth
        >
          <InputLabel
            htmlFor="outlined-age-native-simple"
            className="bg-white"
          >
            {t('typeofUnit')}
          </InputLabel>
          <Field
            path="unit"
            isErrorProp="error"
          >
            <Select fullWidth>
              {UNITS_VALUE.map((unit, unit_idx) => (
                <MenuItem
                  key={k(unit_idx)}
                  value={unit}
                >
                  {t(unit)}
                </MenuItem>
              ))}
            </Select>
          </Field>
        </FormControl>
      </Grid>

      {is_contractor_interface && (
        <>
          <Grid
            item
            xs={12}
          >
            <EntityX
              path="_client"
              entityName="clients"
              entity={Client}
              pickerUniq
              placeholder={t('client')}
            />
          </Grid>
        </>
      )}

      <Grid
        item
        xs={12}
      >
        <EntityX
          refreshValue={true}
          disabled={Boolean(has_equipment_selected)}
          path="_location"
          entityName="locations"
          entity={Location}
          entityProps={{
            defaultFilters: {
              ...(is_contractor_interface ? { clients: _client ? [_client?._id] : [] } : {})
            }
          }}
          pickerUniq
          placeholder={t('location')}
        />
      </Grid>

      <Grid
        item
        xs={12}
      >
        <EntityX
          refreshValue={true}
          disabled={Boolean(has_location_selected)}
          path="_equipment"
          entityName="equipments"
          entity={Equipment}
          entityProps={{
            defaultFilters: {
              ...(is_contractor_interface ? { clients: _client ? [_client?._id] : [] } : {})
            }
          }}
          pickerUniq
          placeholder={t('equipment')}
        />
      </Grid>

      <Grid
        item
        xs={12}
      >
        <EntityX
          path="use_id"
          pickerUniq
          entityName="scopes"
          entity={Scope}
          entityProps={{
            type: 'useMeter',
            createElement: (scope) => create_scope({ ...scope, type: 'useMeter' }) // need to override type
          }}
          placeholder={t('uses')}
        />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <EntityX
          path="tag_id"
          pickerUniq
          entityName="scopes"
          entity={Scope}
          entityProps={{
            type: 'tagMeter',
            createElement: (scope) => create_scope({ ...scope, type: 'tagMeter' }) // need to override type
          }}
          placeholder={t('typeOfMeter')}
        />
      </Grid>
    </>
  );
}
