import { TextField, InputAdornment, IconButton, Tooltip } from '@material-ui/core';

import { VisibilityOff, Visibility, Delete as DeleteIcon } from '@material-ui/icons';

// copy icon
import FileCopyIcon from '@material-ui/icons/FileCopy';

import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import copyToClipboard from 'utils/copyToClipBoard';

import useAsyncDispatch from 'hooks/useAsyncDispatch';
import useNotifications from 'hooks/useNotifications';

import ConfirmDialog from 'components/dialogs/ConfirmDialog';

import { deleteApiToken, updateApiToken } from 'store/authSlice';
import Toggle from 'components/forms/Toggle';

export default function TokenField({
  value,
  label,
  autoComplete,
  index,
  is_active,
  token_id,
  ...props
}) {
  const [tokenIsVisible, setTokenIsVisible] = useState(false);
  const [confirmDeleteOpen, setConfirmDelete] = useState(false);

  const { dispatch } = useAsyncDispatch();
  const notify = useNotifications();

  const dispatchCallbacks = ({ success, error }) => ({
    onSuccess: () => notify.success(success),
    onError: () => notify.error(error)
  });

  const { t } = useTranslation();

  return (
    <div style={{ position: 'relative', display: 'flex', alignItems: 'center', gap: '5px' }}>
      <TextField
        {...props}
        variant="outlined"
        type={tokenIsVisible ? 'text' : 'password'}
        value={value}
        label={label || t('passwordInputLabel')}
        autoComplete={autoComplete ? autoComplete : 'current-password'}
        InputProps={{
          endAdornment: value?.length && (
            <InputAdornment position="start">
              <Tooltip
                title={t('copy')}
                placement="left"
              >
                <IconButton
                  aria-label="copy"
                  style={{ opacity: 0.8, position: 'relative', left: 10 }}
                  onClick={async () => {
                    const button = document.getElementById('button' + index);

                    await copyToClipboard(value)
                      .then(() => {
                        notify.success(t('copiedToClipboard'));
                        button.style.color = '#4caf50';
                      })
                      .catch(() => {
                        notify.error(t('copyToClipboardError'));
                        button.style.color = '#f44336';
                      });

                    setTimeout(function () {
                      button.style.color = '';
                    }, 2000);

                    clearTimeout();
                  }}
                  id={'button' + index}
                >
                  <FileCopyIcon />
                </IconButton>
              </Tooltip>

              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setTokenIsVisible((prev) => !prev)}
                style={{ opacity: 0.7, position: 'relative', left: 10 }}
              >
                {tokenIsVisible ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />

      {/* Switch token is active */}
      <div className="m-3 relative top-[2px]">
        <Tooltip
          title={is_active ? t('tokenActive') : t('tokenUnactive')}
          placement="top"
        >
          <div className="cursor-pointer">
            <Toggle
              checked={is_active}
              id={'check' + index}
              onChange={() => {
                if (document.getElementById('check' + index).checked) {
                  dispatch(
                    updateApiToken,
                    { is_active: true, token_id: token_id },
                    dispatchCallbacks({
                      success: t('tokenActive'),
                      error: t('tokenActiveError')
                    }),
                    { id: token_id }
                  );
                } else {
                  dispatch(
                    updateApiToken,
                    { is_active: false, token_id: token_id },
                    dispatchCallbacks({
                      success: t('tokenUnactive'),
                      error: t('tokenUnactiveError')
                    }),
                    { id: token_id }
                  );
                }
              }}
            />
          </div>
        </Tooltip>
      </div>
      <div className="h-8 border-l-2 border-gray-300" />
      <div
        onClick={() => {
          setConfirmDelete({
            onConfirm: () => {
              dispatch(
                deleteApiToken,
                { token_id: token_id },
                dispatchCallbacks({
                  success: t('tokenDeleted'),
                  error: t('tokenDeleteError')
                }),
                { id: token_id }
              );
            }
          });
        }}
        className="transition p-3 mr-[-2px] rounded-full hover:bg-gray-100 hover:text-red-400 cursor-pointer text-gray-500"
      >
        <DeleteIcon />
      </div>

      <ConfirmDialog
        text={<span>{t('confirmDeleteToken')}</span>}
        open={!!confirmDeleteOpen}
        onClose={() => [setConfirmDelete(undefined)]}
        onConfirm={confirmDeleteOpen?.onConfirm}
      />
    </div>
  );
}
