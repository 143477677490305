import { createSlice } from '@reduxjs/toolkit';
import { generateEntityBoilerplate } from 'utils/generateEntitySlice';
import generateAsyncThunk from 'utils/generateAsyncThunk';

export const createElement = generateAsyncThunk({ type: 'POST', endpoint: 'jobs' });
export const updateElement = generateAsyncThunk({ type: 'PUT', endpoint: 'jobs/${id}' });
export const getElement = generateAsyncThunk({ type: 'GET', endpoint: 'jobs/${id}' });
export const getElements = generateAsyncThunk({ type: 'POST', endpoint: 'jobs/list' });
export const deleteElement = generateAsyncThunk({ type: 'DELETE', endpoint: 'jobs/${id}' });
export const restoreElement = generateAsyncThunk({ type: 'POST', endpoint: 'jobs/recycle/${id}' });

const entityBoilerplate = generateEntityBoilerplate({
  createElement,
  updateElement,
  getElement,
  getElements,
  deleteElement,
  restoreElement
});

const { initialState, reducers, extraReducers } = entityBoilerplate;

export const jobsSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers,
  extraReducers
});

export const { flushElements, flushElement } = jobsSlice.actions;
export default jobsSlice.reducer;
