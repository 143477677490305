import { Grid, TextField } from '@material-ui/core';
import { Field, useField } from 'frmx';
import PhoneField from 'components/inputs/PhoneField';
import EntityX from 'components/inputs/EntityX';
import InterfaceForm from 'components/Interface/InterfaceForm';
import Job from 'entities/Job/Job';
import { ActivityField } from 'settings/Activity';
import DisplayMore from 'components/DisplayMore';
import { useTranslation } from 'react-i18next';
import { LangSelect } from 'entities/User/UserForm';

export default function ContractorForm({ isCreate, element }) {
  const { value: email, setValue: setEmail, error: emailError } = useField('email');
  const { t } = useTranslation();

  const no_user_interface = element && element._user ? true : false;
  const no_last_connection = element && element._user && !element._user.last_connect ? true : false;

  const should_not_be_modified = !isCreate && (no_user_interface || no_last_connection);

  return (
    <>
      <Grid
        item
        xs={12}
      >
        <Field
          path="companyName"
          disabled={should_not_be_modified}
        >
          <TextField
            autoFocus
            variant="outlined"
            label={t('companyName')}
            fullWidth
          />
        </Field>
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
      >
        <Field
          path="firstName"
          disabled={should_not_be_modified}
        >
          <TextField
            variant="outlined"
            label={t('firstName')}
            fullWidth
          />
        </Field>
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
      >
        <Field
          path="lastName"
          disabled={should_not_be_modified}
        >
          <TextField
            variant="outlined"
            label={t('lastName')}
            fullWidth
          />
        </Field>
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
      >
        <TextField
          error={emailError}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          variant="outlined"
          label="Email"
          helperText={emailError ? t('noEmailForAccountHelperText') : ''}
          fullWidth
          disabled={should_not_be_modified}
        />
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
      >
        <LangSelect
          path="lang"
          label={t('lang')}
        />
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
      >
        <PhoneField
          path="phone"
          label={t('cellphoneNumber')}
          variant="outlined"
          placeholder={t('cellphoneNumber')}
          disabled={should_not_be_modified}
        />
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
      >
        <PhoneField
          path="fix"
          label={t('landlineNumber')}
          variant="outlined"
          placeholder={t('landlineNumber')}
          disabled={should_not_be_modified}
        />
      </Grid>

      <div className="mx-4 p-2 text-gray-600 text-xs w-full border-b my-2">{t('visibility-assign')}</div>
      <Grid
        item
        xs={12}
      >
        <ActivityField path="zone_ids" />
      </Grid>

      <Grid
        item
        xs={12}
      >
        <EntityX
          path="_jobs"
          entity={Job}
          placeholder={t('jobsTitle')}
        />
      </Grid>

      <Grid
        item
        xs={12}
      >
        <DisplayMore>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <Field
                path="siret"
                disabled={should_not_be_modified}
              >
                <TextField
                  variant="outlined"
                  label="Siret"
                  fullWidth
                />
              </Field>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Field
                path="description"
                disabled={should_not_be_modified}
              >
                <TextField
                  multiline
                  rows={3}
                  variant="outlined"
                  label={t('description')}
                  fullWidth
                />
              </Field>
            </Grid>
          </Grid>
        </DisplayMore>
      </Grid>

      {
        <Grid
          item
          xs={12}
        >
          <InterfaceForm
            isCreate={isCreate}
            element={element}
          />
        </Grid>
      }
    </>
  );
}
