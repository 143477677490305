import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  IconButton,
  DialogActions,
  TextField,
  Grid,
  FormHelperText,
  makeStyles
} from '@material-ui/core';
import { CancelOutlined, Close } from '@material-ui/icons';

import { useState, useRef } from 'react';

import useStyles from 'components/dialogs/Dialog.styles';
import { useField } from 'frmx';
import { dateToLocalFormat } from 'utils/dates';
import FAIcon from 'components/ui/FAIcon';
import SignaturePad from 'react-signature-canvas';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@material-ui/lab';
import { isArray } from 'lodash-es';
import { useIsBelowMd } from 'hooks/useMQ';

const useErrorStyles = makeStyles((t) => ({ error: { color: t.palette.error.main } }));

export default function SignatureField({
  signaturePath,
  datePath,
  namePath,
  suggestion,
  disabled
}) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [hovered, setHovered] = useState(false);

  const { t } = useTranslation();

  const {
    value: signature,
    setValue: setSignature,
    error: signatureError
  } = useField(signaturePath);
  const { value: date, setValue: setDate, error: dateError } = useField(datePath);
  const { value: name, setValue: setName, error: nameError } = useField(namePath);

  const c = useErrorStyles();

  // TODO: Show message onOver when field is disabled to inform user

  return (
    <>
      <SignatureDialog
        open={modalIsOpen && !disabled}
        onClose={() => setModalIsOpen(false)}
        setSignature={setSignature}
        setDate={setDate}
        name={name}
        setName={setName}
        suggestion={suggestion}
      />
      <Box
        onClick={() => !disabled && setModalIsOpen(true)}
        style={{
          border: '1px solid rgba(133,133,133, .5)',
          borderRadius: '8px',
          position: 'relative',
          cursor: disabled ? 'not-allowed' : 'pointer',
          width: '100%',
          height: 110,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        onMouseOver={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        {!disabled && (
          <IconButton
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              display: hovered && !!signature ? 'block' : 'none'
            }}
            onClick={(e) => {
              e.stopPropagation();
              setSignature('');
              setName(null);
              setDate(null);
            }}
          >
            <CancelOutlined />
          </IconButton>
        )}
        {!!signature ? (
          <img
            style={{
              width: '100%',
              height: 110
            }}
            src={signature}
            alt=""
          />
        ) : (
          <Typography
            variant="h6"
            style={{ opacity: disabled ? 0.5 : 1 }}
          >
            {disabled ? t('fillReportToSign') : t('clickHereToSign')}
          </Typography>
        )}

        {!!signature && (
          <Typography
            style={{
              position: 'absolute',
              bottom: 0,
              right: 10
            }}
            variant="caption"
          >
            Signé le {dateToLocalFormat(new Date(date))} par {name}
          </Typography>
        )}
      </Box>
      {(nameError || dateError || signatureError) && (
        <FormHelperText className={c.error}>
          Veuillez resigner en indiquant correctement votre nom.
        </FormHelperText>
      )}
    </>
  );
}

function SignatureDialog({ open, onClose, setSignature, setDate, name, setName, suggestion }) {
  const contractorSignature = useRef();

  const { t } = useTranslation();
  const deviceIsMobile = useIsBelowMd();

  const c = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={deviceIsMobile ? undefined : 'xs'}
      fullWidth={!deviceIsMobile}
      fullScreen={deviceIsMobile}
    >
      <DialogTitle
        style={{ marginBottom: '1em' }}
        className={c.title}
      >
        <Box
          display="flex"
          width="100%"
        >
          Signature
          <IconButton
            onClick={onClose}
            style={{
              position: 'absolute',
              top: 6,
              right: 6
            }}
          >
            <Close style={{ color: 'white' }} />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent style={{ overflow: 'hidden' }}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
          >
            <Autocomplete
              disableClearable
              fullWidth
              freeSolo
              value={name}
              onChange={(e, v) => setName(v)}
              options={isArray(suggestion) ? suggestion : suggestion ? [suggestion] : []}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onChange={(e) => setName(e.target.value)}
                  label={t('signatoryDetails')}
                  variant="outlined"
                />
              )}
            />
          </Grid>

          <Grid
            item
            xs={12}
          >
            <div className=" w-full h-48">
              <SignaturePad
                ref={contractorSignature}
                canvasProps={{
                  style: {
                    borderRadius: '8px',
                    width: '100%',
                    height: '100%',
                    border: '1px solid rgba(133,133,133, .5)'
                  }
                }}
              />
            </div>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          disabled={!name}
          onClick={() => contractorSignature.current?.clear()}
          startIcon={
            <FAIcon
              icon="eraser"
              collection="fad"
            />
          }
        >
          {t('erase')}
        </Button>
        <Button
          disabled={!name}
          onClick={() => {
            setSignature(contractorSignature.current?.toDataURL());
            setDate(new Date());
            onClose();
          }}
          startIcon={
            <FAIcon
              icon="signature"
              collection="fad"
            />
          }
        >
          {t('sign')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
