import {
  Dialog,
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Typography,
  Container
} from '@material-ui/core';

import { Close as CloseIcon } from '@material-ui/icons';

import { useState, createElement, useMemo } from 'react';

import { useIsBelowLg } from 'hooks/useMQ';
import { useAuth } from 'hooks/useAuth';

import { getSettingsMenus, getSections } from 'config/settingsMenu';
import { LanguageSwitch } from 'components/LanguageSwitch';
import { SettingsDrawerMenu, SettingsDrawerMenuMobile } from './SettingsDrawerMenu';

import useStyles from './SettingsDrawer.styles';
import { useRole } from 'hooks/useRole';
import { Back } from 'components/ui/Arrow';
import useKeys from '@flowsn4ke/usekeys';
import FAIcon from 'components/ui/FAIcon';
import { useTranslation } from 'react-i18next';

import projectMeta from '../../../package.json';
import { ReactComponent as VersionNumber } from '../../assets/icons/version_number.svg';

import { ColorPickerSettings } from 'components/ColorPickerSettings';

export default function SettingsDrawer({ onClose, isOpen }) {
  const isBelowLg = useIsBelowLg();

  return (
    <DrawerContents
      isBelowLg={isBelowLg}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
}

function DrawerContents({ isBelowLg, onClose, isOpen }) {
  const role = useRole();
  const auth = useAuth();

  const [bookOpen, setBookOpen] = useState(false);
  const [statusOpen, setStatusOpen] = useState(false);

  const { t } = useTranslation();

  const classes = useStyles();

  const menus = useMemo(() => getSettingsMenus(auth.interface, role), [auth.interface]);
  const [selectedMenu, setSelectedMenu] = useState(!isBelowLg && menus[0]);

  const sections = useMemo(
    () => getSections(auth.interface, selectedMenu?.key),
    [auth.interface._id, selectedMenu]
  );

  const k2 = useKeys();

  return (
    <Dialog
      keepMounted
      fullScreen
      transitionDuration={0}
      open={isOpen}
      onClose={onClose}
    >
      <AppBar
        position="static"
        className={classes.appBar}
      >
        {
          <Toolbar>
            {isBelowLg && (
              <IconButton
                edge="start"
                onClick={() => (!selectedMenu ? onClose() : setSelectedMenu(false))}
                className={classes.drawerButton}
              >
                {<Back className={classes.drawerButtonIcon} />}
              </IconButton>
            )}

            <Typography
              variant="h6"
              className={classes.title}
            >
              {t('settingsMenu')}
            </Typography>

            <IconButton
              className={classes.appBarClose}
              edge="end"
              color="inherit"
              onClick={() => onClose()}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        }
      </AppBar>

      {isOpen && (
        <Box
          className={classes.settingsContainer}
          style={{
            backgroundColor: isBelowLg ? 'white' : ''
          }}
        >
          <Container
            className={classes.content}
            disableGutters={isBelowLg}
          >
            <Box style={{ marginRight: '2vmin' }}>
              {isBelowLg && !selectedMenu && (
                <SettingsDrawerMenuMobile
                  menus={menus}
                  selectedMenu={selectedMenu}
                  setSelectedMenu={setSelectedMenu}
                />
              )}
              {!isBelowLg && (
                <SettingsDrawerMenu
                  menus={menus}
                  selectedMenu={selectedMenu}
                  setSelectedMenu={setSelectedMenu}
                />
              )}
            </Box>
            <Box flexGrow={1}>
              {sections.map((section, i) => (
                <Section
                  isBelowLg={isBelowLg}
                  section={section}
                  key={k2(i)}
                />
              ))}
              {!isBelowLg && !sections?.length && (
                <Box className={classes.noResult}>{t('noResultsFound')}</Box>
              )}

              <Box
                className={classes.paletteContainer}
                style={isBelowLg ? { paddingRight: 16 } : {}}
              >
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: 'column'
                  }}
                >
                  <Button
                    style={{ marginBottom: 4 }}
                    startIcon={
                      <FAIcon
                        collection="fas"
                        icon="signal"
                        size="small"
                      />
                    }
                    size="small"
                    onClick={() => {
                      window.open('https://status.bob-desk.com', '_blank');
                    }}
                  >
                    {t('allSystemsOperationals')}
                  </Button>

                  {(auth.user.isRoot || auth.user.isBob) && (
                    <Button
                      style={{ marginBottom: 4 }}
                      startIcon={
                        <FAIcon
                          collection="fas"
                          icon="code"
                          size="small"
                        />
                      }
                      size="small"
                      onClick={() => window.open('https://dev.bob-desk.com/', '_blank')}
                    >
                      {t('docsApi')}
                    </Button>
                  )}

                  {auth.user?.isBob && (
                    <div className="flex items-center ml-4">
                      <VersionNumber className="text-gray-600 h-6" />
                      <div className="ml-2 my-2">
                        <p className="text-xs font-medium">Version {projectMeta.version}</p>
                      </div>
                    </div>
                  )}
                </Box>

                <Dialog
                  maxWidth="md"
                  fullWidth
                  PaperProps={{ style: { height: '90vh' } }}
                  disableEnforceFocus
                  open={statusOpen}
                  onClose={() => setStatusOpen(false)}
                >
                  {statusOpen && (
                    <iframe
                      title="calendly"
                      style={{ width: '100%', height: '100%' }}
                      src="https://bobdesk1.statuspage.io"
                      frameborder="0"
                    />
                  )}
                </Dialog>

                <Dialog
                  maxWidth="md"
                  fullWidth
                  PaperProps={{ style: { height: '90vh' } }}
                  disableEnforceFocus
                  open={bookOpen}
                  onClose={() => setBookOpen(false)}
                >
                  {bookOpen && (
                    <iframe
                      title="calendly"
                      style={{ width: '100%', height: '100%' }}
                      src="https://calendly.com/bob-desk-mj"
                      frameborder="0"
                    />
                  )}
                </Dialog>

                <Box>
                  <ColorPickerSettings />
                  <LanguageSwitch />
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
      )}
    </Dialog>
  );
}

function Section(props) {
  const { section, isBelowLg } = props;
  return useMemo(() => {
    return createElement(section.component, { section, isBelowLg });
  });
}
