import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  filterBarAnchor: {
    left: 10,
    width: '100%',
    bottom: -6,
    padding: 10,
    position: 'absolute',
    paddingTop: 0,
    paddingBottom: 0
  },
  filterIndex: {
    boxShadow: theme.shadows[1],
    backgroundColor: '#c5c9cc'
  },
  filtersBar: ({ isList, settings, page, filterTabs }) => ({
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    backgroundColor: 'white',
    height: 46,
    boxShadow: 0,
    zIndex: 4,
    borderRadius: 0,
    ['& .swiper-container']: {
      height: 46,
      marginLeft: '0'
    },
    paddingLeft: !settings && isList && 10
  }),
  filtersBarSettings: {
    paddingLeft: 0
  },
  savefiltersBar: {
    padding: '4px 8px 4px 18px',
    minHeight: 'auto',
    backgroundColor: 'white',
    zIndex: 3,
    justifyContent: 'flex-end',
    borderTop: `1px solid ${theme.palette.grey[300]}`
  },
  savefiltersCancel: {},
  savefiltersSave: {
    marginRight: 'auto'
  },
  savefiltersFilter: {},

  filter: {
    height: 30,
    borderRadius: 42,
    padding: '4px 16px',
    fontSize: '12px'
  },
  filterOpened: {
    boxShadow: theme.shadows[2],
    backgroundColor: theme.palette.grey[200]
  },
  filterBadge: {
    ['& .MuiBadge-badge']: {
      bottom: 6,
      right: 6,
      background: 'white',
      fontWeight: 500,
      border: '2px solid ' + theme.palette.primary.light,
      color: theme.palette.primary.light
    },
    ['& .MuiBadge-dot']: {
      height: 12,
      width: 12,
      borderRadius: '50%',
      right: 6,
      bottom: 4
    }
  },
  moreMenuElementOn: {
    backgroundColor: '#f5f5f5'
  },
  slideMoreMenu: {
    alignItems: 'center',
    display: 'flex',
    width: 'auto',
    margin: '0em',
    height: '100%'
  },
  slide: {
    alignItems: 'center',
    display: 'flex',
    width: 'auto',
    margin: 'auto .5em auto 0em',
    height: '100%',
    color: 'red'
  },
  navFiltersIconButton: {
    position: 'relative',
    top: 6,
    padding: 16,
    height: 16,
    color: '#a9afb5',
    width: 16,
    backgroundColor: 'white'
  },

  navFiltersIconsLeft: {
    backgroundColor: 'white',
    position: 'absolute',
    top: 0,
    height: 'calc(100% - 1px)',
    left: 0,
    ['&::before']: {
      position: 'absolute',
      top: 0,
      left: 24,
      background: 'linear-gradient(to right, white 20%, rgba(255, 255, 255, 0) 80%)',
      height: '100%',
      width: 50,
      content: '""',
      display: 'block',
      pointerEvents: 'none'
    },
    zIndex: theme.zIndex.mobileStepper
  },
  navFiltersIconsRight: {
    ['&::before']: {
      position: 'absolute',
      top: 0,
      right: 24,
      background: 'linear-gradient(to left, white 20%, rgba(255, 255, 255, 0) 80%)',
      height: '100%',
      width: 50,
      content: '""',
      display: 'block',
      pointerEvents: 'none'
    },
    backgroundColor: 'white',
    height: 'calc(100% - 1px)',
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: theme.zIndex.mobileStepper
  },
  errorColor: {
    color: theme.palette.error.light
  },
  entityMenu: {
    marginLeft: 8,
    padding: 16,
    width: 16,
    height: 16,
    marginRight: 10
  },
  manageFIlter: {
    paddingRight: 32,
    marginRight: '.5em',
    flexShrink: 0,
    position: 'relative'
  },
  removeFilter: {
    position: 'absolute',
    top: 0,
    right: 2
  },

  header: {
    background: theme.palette.secondary.main,
    color: 'white',
    display: 'flex',
    fontSize: 14,
    fontWeight: 500,
    padding: '10px 10px',
    alignItems: 'center',
    borderRadius: `${theme.radius}px ${theme.radius}px 0px 0px`
  },
  headerIcon: {
    marginRight: 16,
    fontSize: 16
  },
  navFiltersIconsLeftBookmarks: {
    top: -6
  },
  navFiltersIconsRightBookmarks: {
    top: -6
  }
}));
