const AccountingTranslations = (configuration) => ({
  // pick: 'entityReportPick',
  // create: 'entityReportCreate',
  // update: 'entityReportUpdate',
  // entityName: 'entityReportName',
  // noResultLabel: 'entityReportnoResultLabel',
  // searchText: 'entityReportSearchText',
  // createDialog: "entityReportCreateDialog",
  // updateDialog: "entityReportUpdateDialog",
});

export default AccountingTranslations;
