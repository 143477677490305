import {
  Box,
  IconButton,
  Grid,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem
} from '@material-ui/core';

import { MoreVertOutlined, Star, Edit, Notifications } from '@material-ui/icons';

import Contract from './Contract';
import { useAuth } from 'hooks/useAuth';
import { useContract } from 'contexts/entities/contractContext';
import { useState } from 'react';
import { TechnicianItem } from 'components/views/ViewItems';
import { IntervenerItemPrimary, RelanceIntervenerMenu } from './ContractContractor';
import RateDialog from 'components/dialogs/RateDialog';
import { useRole } from 'hooks/useRole';
import useKeys from '@flowsn4ke/usekeys';
import { useTranslation } from 'react-i18next';

export default function ContractTechnician({ ticket }) {
  const { t } = useTranslation();
  const auth = useAuth();
  const role = useRole();
  const { contract, contractTechnician, order, dispatch, setIntervenerPicker } = useContract();

  const [isRatingOpen, setIsRatingOpen] = useState(false);
  const [technicianToRate, setTechnicianToRate] = useState(null);

  const [relanceMenuOpen, setRelanceMenuOpen] = useState(null);

  const canUpdate = role.permission('tickets', 'assign_ticket');

  const [technicianMenuOpen, setTechnicianMenuOpen] = useState(false);
  const closeMenu = () => [setTechnicianMenuOpen(false), setTechnicianToRate(null)];
  const technicianActionsVisible = (technician) =>
    contractTechnician && (
      <Box
        display={'flex'}
        alignItems={'center'}
      >
        <IconButton
          size={'small'}
          style={{ marginLeft: 6 }}
          onClick={(e) => [
            e.preventDefault(),
            e.stopPropagation(),
            setTechnicianToRate(technician),
            setTechnicianMenuOpen(e.currentTarget)
          ]}
        >
          <MoreVertOutlined />
        </IconButton>
      </Box>
    );
  const technicianMenu = contractTechnician && (
    <Menu
      anchorEl={technicianMenuOpen}
      transitionDuration={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={!!technicianMenuOpen}
      onClose={() => [closeMenu()]}
    >
      {technicianToRate && (
        <MenuItem
          dense
          onClick={(e) => [setIsRatingOpen(true), setTechnicianMenuOpen(null)]}
        >
          <ListItemIcon>
            <Star />
          </ListItemIcon>
          <ListItemText primary={t('rateIntervener')} />
        </MenuItem>
      )}

      {role.permission('tickets', 'relance') && (
        <MenuItem
          dense
          onClick={(e) => [setRelanceMenuOpen(e.currentTarget)]}
        >
          <ListItemIcon>
            <Notifications />
          </ListItemIcon>

          <ListItemText primary={t('remindTechnician')} />
        </MenuItem>
      )}

      <MenuItem
        dense
        onClick={(e) => [setIntervenerPicker({ type: 'assign', technician: true }), closeMenu()]}
      >
        <ListItemIcon>
          <Edit />
        </ListItemIcon>

        <ListItemText primary={t('editTechnicians')} />
      </MenuItem>
    </Menu>
  );

  const k = useKeys();
  const list = contract._summons || (contract.summonsIsCollaborator ? [undefined] : []);
  return (
    <>
      <Grid container>
        {list.map((technician, i) => (
          <Grid
            item
            xs={12}
            key={k(i)}
            sm={list?.length === 1 ? 12 : 6}
          >
            <TechnicianItem
              rating={technician?.ratings?.find((r) => r._ticket === order._id)}
              primary={<IntervenerItemPrimary />}
              technician={technician}
              actionsVisible={canUpdate && technicianActionsVisible(technician)}
              relances={ticket.relances}
            />
          </Grid>
        ))}

        {technicianToRate && (
          <RateDialog
            dispatch={dispatch}
            intervener={technicianToRate}
            order={order}
            onClose={() => setIsRatingOpen(false)}
            open={isRatingOpen}
          />
        )}

        <RelanceIntervenerMenu
          id={order._id}
          intervener={technicianToRate?._id}
          technician
          anchorEl={relanceMenuOpen}
          dispatch={dispatch}
          onClose={() => [setRelanceMenuOpen(false), closeMenu()]}
        />
      </Grid>
      {technicianMenu}
      <Contract isParent={!auth.interface.isClient} />
    </>
  );
}
