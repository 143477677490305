import {
  NUMBER_OF_EVENTS_TO_SHOW_IN_MONTHLY_VIEW,
  UNAVAILABILITY_TYPE
} from 'modules/calendar/config';
import classNames from 'utils/classNames';
import { format, isSameMonth, isToday } from 'date-fns';
import { useUI } from 'hooks/useUI';

export default function DayLarge({
  day,
  middle_of_the_month,
  set_ticket_in_modal,
  set_unavailability_id_to_edit
}) {
  const ui = useUI();
  const locale = ui.get_date_time_locale();

  const handle_click = (event) => {
    return event.type === UNAVAILABILITY_TYPE
      ? set_unavailability_id_to_edit(event.unavailability_id)
      : set_ticket_in_modal(event.ticket_id);
  };

  return (
    <div
      key={day.date}
      className={classNames(
        isSameMonth(day.date, middle_of_the_month) ? 'bg-white' : 'bg-gray-50 text-gray-500',
        'relative py-2 px-3'
      )}
    >
      <time
        dateTime={day.date}
        className={
          isToday(day.date)
            ? 'flex h-6 w-6 items-center justify-center rounded-full bg-red-600 font-semibold text-white'
            : undefined
        }
      >
        {format(day.date, 'd', { locale })}
      </time>
      {day.events.length > 0 && (
        <ol className="mt-2">
          {day.events.slice(0, NUMBER_OF_EVENTS_TO_SHOW_IN_MONTHLY_VIEW).map((event) => (
            <li
              key={event._id}
              className="flex items-center space-x-1"
            >
              <div
                className="h-1.5 w-1.5 rounded-full shrink-0 grow-0"
                style={{ backgroundColor: event.color }}
              />
              <button
                onClick={() => handle_click(event)}
                className="group flex"
              >
                <p className="flex-auto truncate font-medium text-gray-900">{event.title}</p>
                <time
                  dateTime={event.date}
                  className="ml-3 hidden flex-none text-gray-500 xl:block"
                >
                  {event.date}
                </time>
              </button>
            </li>
          ))}
          {day.events.length > NUMBER_OF_EVENTS_TO_SHOW_IN_MONTHLY_VIEW && (
            <li className="text-gray-500">
              + {day.events.length - NUMBER_OF_EVENTS_TO_SHOW_IN_MONTHLY_VIEW} more
            </li>
          )}
        </ol>
      )}
    </div>
  );
}
