import useKeys from '@flowsn4ke/usekeys';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';

export default function Pagination({
  dataPerPage,
  totalData,
  paginateBack,
  paginateFront,
  currentPage,
  paginate
}) {
  const totalPages = Math.ceil(totalData / dataPerPage);

  const pageNumbers = [];

  const k = useKeys();
  const { t } = useTranslation();

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
    if (i > 4 && totalPages > 4) {
      pageNumbers.push('...');
      pageNumbers.push(totalPages);
      break;
    } else if (currentPage === 5 || (currentPage > 5 && currentPage <= totalPages - 4)) {
      pageNumbers.push('...');
      pageNumbers.push(currentPage - 1);
      pageNumbers.push(currentPage);
      pageNumbers.push(currentPage + 1);
      pageNumbers.push('...');
      pageNumbers.push(totalPages);
      break;
    } else if (
      (currentPage === totalPages && totalPages <= i - 4) ||
      (currentPage === totalPages && totalPages >= i + 4)
    ) {
      pageNumbers.push('...');
      for (let i = totalPages - 4; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
      break;
    } else if (currentPage < totalPages && currentPage !== 1 && totalPages <= i - 4) {
      pageNumbers.push('...');
      for (let i = totalPages - 4; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
      break;
    }
  }

  const disabledFirstOrLastPage = currentPage === totalPages;

  const showingDataBegin = currentPage * dataPerPage - 10;
  const showingDataEnd =
    currentPage * dataPerPage > totalData ? totalData : currentPage * dataPerPage;

  return (
    <div className="px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 lg:static md:relative">
      <div className="flex-1 flex justify-between sm:hidden">
        <button
          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          onClick={() => {
            paginateBack();
          }}
          disabled={currentPage === 1}
        >
          {t('previous')}
        </button>
        <button
          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          onClick={() => {
            paginateFront();
          }}
          disabled={disabledFirstOrLastPage}
        >
          {t('next')}
        </button>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            {t('showing')} <span className="font-medium">{showingDataBegin}</span> to{' '}
            <span className="font-medium">{showingDataEnd}</span> {t('of')}{' '}
            <span className="font-medium">
              {typeof totalData === 'undefined' ? '0' : totalData}
            </span>{' '}
            {t('results')}
          </p>
        </div>
        <div>
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination"
          >
            <button
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              onClick={() => {
                paginateBack();
              }}
              disabled={currentPage === 1}
            >
              <span className="sr-only">{t('previous')}</span>
              <ChevronLeftIcon
                className="h-5 w-5"
                aria-hidden="true"
              />
            </button>
            {pageNumbers.map((pageNumber, pageNumberIdx) => {
              return (
                <button
                  key={k(pageNumberIdx)}
                  className={
                    currentPage === pageNumber
                      ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium'
                      : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium'
                  }
                  onClick={() => {
                    paginate(pageNumber);
                  }}
                  disabled={pageNumber === '...'}
                >
                  {pageNumber}
                </button>
              );
            })}
            <button
              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              onClick={() => {
                paginateFront();
              }}
              disabled={disabledFirstOrLastPage}
            >
              <span className="sr-only">{t('next')}</span>
              <ChevronRightIcon
                className="h-5 w-5"
                aria-hidden="true"
              />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
}
