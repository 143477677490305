const TicketSorts = (configuration) =>
  [
    { label: 'creationDateMostRecent', value: '_id', sort: -1, show: true },
    { label: 'creationDateLeastRecent', value: '_id', sort: 1, show: true },
    { label: 'updateDateMostRecent', value: 'updated_at', sort: -1, show: true },
    { label: 'updateDateLeastRecent', value: 'updated_at', sort: 1, show: true },
    { label: 'ascendingAlphabetical', value: 'title', sort: 1, show: true },
    { label: 'descendingAlphabetical', value: 'title', sort: -1, show: true }
    // { label: 'Numéro du Ticket (Ascendant)', value: 'number', sort: 1, show: true },
    // { label: 'Numéro du Ticket (Descendant)', value: 'number', sort: -1, show: true },
  ].filter((e) => e.show);

export default TicketSorts;
