import { makeStyles } from '@material-ui/core/styles';
import { contractStates, CONTRACT_VALIDATION } from 'lists/contractStates';

export default makeStyles((theme) => ({
  listItemPrimaryBadgeOrange: {
    ['& span']: {
      background: contractStates[CONTRACT_VALIDATION].color
    }
  },
  listItemPrimaryBadgeSuccess: {
    ['& span']: {
      background: theme.palette.success.dark
    }
  },
  listItemPrimaryBadgeError: {
    ['& span']: {
      background: theme.palette.error.dark
    }
  },
  pikatchu: {
    color: 'rgb(255, 217, 36)',
    position: 'absolute',
    right: -17,
    fontSize: 8,
    top: -12
  }
}));
