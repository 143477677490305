import {
    Box,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio
} from '@material-ui/core';
import { useField } from 'frmx';
import { useTranslation } from 'react-i18next';

export default function ValidatePeriodFilter({ path }) {
    const { value, setValue } = useField(path);
    const { t } = useTranslation();

    const updateValidationValue = (selectedValue) => {
        setValue({
            ...value,
            with: selectedValue !== 2,
            without: selectedValue !== 1,
        });
    };

    const handleRadioButtonChange = (e) => {
        const selectedValue = parseInt(e.target.value, 10);
        updateValidationValue(selectedValue);
    };

    const getRadioButtonValue = () => {
        if (value.with && value.without) return "0";
        if (value.with) return "1";
        if (value.without) return "2";
        return "0";
    };

    const radioOptions = [
        { value: "0", label: `${t('validatedF')}/${t('notValidatedF')}` },
        { value: "1", label: t('validatedF') },
        { value: "2", label: t('notValidatedF') },
    ];

    return (
        <Box width="100%" height="100%" display="flex" flexDirection="column">
            <FormControl component="fieldset">
                <RadioGroup
                    style={{ marginBottom: 10, flexDirection: 'row', alignItems: 'center' }}
                    value={getRadioButtonValue()}
                    onChange={handleRadioButtonChange}
                >
                    {radioOptions.map((option) => (
                        <FormControlLabel
                            key={option.value}
                            value={option.value}
                            control={<Radio />}
                            label={option.label}
                        />
                    ))}
                </RadioGroup>
            </FormControl>
        </Box>
    );
}
