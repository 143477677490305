import {
  Checkbox,
  Divider,
  AccordionActions,
  AccordionDetails,
  Grid,
  FormControlLabel,
  FormHelperText,
  Button,
  makeStyles,
  TextField,
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemSecondaryAction,
  ListItemText,
  ButtonGroup
} from '@material-ui/core';

import useAsyncDispatch from 'hooks/useAsyncDispatch';
import useNotifications from 'hooks/useNotifications';
import { setConfiguration, uploadFile } from 'store/authSlice';
import { Submit, Field, Reset, useField, useFieldObserver } from 'frmx';

import AccordionForm from 'components/forms/AccordionForm';

import { useConfiguration } from 'hooks/useConfiguration';
import { useAuth } from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@material-ui/lab';
import useKeys from '@flowsn4ke/usekeys';
import { AddPhotoAlternateOutlined, Cancel, FileCopy } from '@material-ui/icons';
import { useState } from 'react';
import BlurredProgress from 'components/BlurredProgress';

const useStyles = makeStyles((t) => ({
  popperHide: {
    display: 'none'
  }
}));

export default function PublicConfiguration({ section }) {
  const config = useConfiguration();
  const { t } = useTranslation();

  const notify = useNotifications();
  const { dispatch, requestStatus } = useAsyncDispatch();

  const dispatchCallbacks = {
    onSuccess: () => notify.success(),
    onError: () => notify.error()
  };

  return (
    <AccordionForm
      defaultClose
      section={section}
      onSubmit={(updates) => dispatch(setConfiguration, updates, dispatchCallbacks)}
      initialValues={{
        ticket_public: config.ticket_public
      }}
    >
      <AccordionDetails>
        <FormFields />
      </AccordionDetails>

      <Divider />

      <AccordionActions>
        <Reset>
          <Button size="small">{t('cancel')}</Button>
        </Reset>

        <Submit disabled={requestStatus === 'loading'}>
          <Button
            size="small"
            color="primary"
          >
            {t('save')}
          </Button>
        </Submit>
      </AccordionActions>
    </AccordionForm>
  );
}
function FormFields() {
  const auth = useAuth();
  const classes = useStyles();
  const k = useKeys();
  const { t } = useTranslation();
  const config = useConfiguration();

  const { value: whiteList, setValue: setWhiteList } = useField(
    'ticket_public.creator_email_domains_whitelist_config'
  );

  const localInterface = useFieldObserver('ticket_public.local');
  const globalInterface = useFieldObserver('ticket_public.global');
  const globalClientInterface = useFieldObserver('ticket_public.global_client');

  const creatorEmailEnabled = useFieldObserver('ticket_public.creator_email_enabled');
  const whiteListEnabled = useFieldObserver(
    'ticket_public.creator_email_domains_whitelist_enabled'
  );
  const publicNotificationsEnabled = useFieldObserver('ticket_public.notifications_enabled');

  const [copyMessage, setCopymessage] = useState(false);

  return (
    <Grid
      container
      spacing={2}
    >
      {config.isClient && <BackgroundImageField />}

      {config.isClient && (
        <Grid
          item
          xs={12}
        >
          <FormControlLabel
            control={
              <Field
                path="ticket_public.global"
                type="checkbox"
              >
                <Checkbox />
              </Field>
            }
            label={t('globalPublicInterface')}
          />
          <FormHelperText>
            {t('globalPublicInterfaceHelperText')}.
            <br />
            {globalInterface && (
              <p>
                URL:{' '}
                <a
                  href={`https://bob-desk.com/public/${auth.interface._company._id}/global`}
                  className="text-blue-600 hover:underline"
                >
                  {`https://bob-desk.com/public/${auth.interface._company._id}/global`}
                </a>
                {
                  <IconButton
                    style={{ marginLeft: 6 }}
                    size={'small'}
                    onClick={() => [
                      navigator.clipboard.writeText(
                        `https://bob-desk.com/public/${auth.interface._company._id}/global`
                      ),
                      setCopymessage(true)
                    ]}
                  >
                    <FileCopy style={{ fontSize: 13, width: 13, height: 13 }} />
                  </IconButton>
                }
                {copyMessage && (
                  <strong
                    style={{
                      color: '#27ae60',
                      fontSize: 11,
                      marginLeft: 6,
                      position: 'relative',
                      top: 2
                    }}
                  >
                    lien copié dans le presse papier
                  </strong>
                )}
              </p>
            )}
          </FormHelperText>
        </Grid>
      )}

      {config.isContractor && (
        <>
          <Grid
            item
            xs={12}
          >
            <FormControlLabel
              control={
                <Field
                  path="ticket_public.global_client"
                  type="checkbox"
                >
                  <Checkbox />
                </Field>
              }
              label={t('globalPublicInterfacePerCustomer')}
            />
            <FormHelperText>{t('publicInterfacePerClientSelectionUrl')}</FormHelperText>
          </Grid>
        </>
      )}

      <Grid
        item
        xs={12}
      >
        <FormControlLabel
          control={
            <Field
              path={`ticket_public.local`}
              type="checkbox"
            >
              <Checkbox />
            </Field>
          }
          label={t('localPublicInterface')}
        />
        <FormHelperText>{t('localPublicInterfaceHelperText')}</FormHelperText>
      </Grid>

      {(localInterface || globalInterface || globalClientInterface) && (
        <>
          <Grid
            item
            xs={12}
          >
            <Divider />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <FormControlLabel
              control={
                <Field
                  path="ticket_public.hide_ticket"
                  type="checkbox"
                >
                  <Checkbox />
                </Field>
              }
              label={t('hideTicketTitle')}
            />
            <FormHelperText>{t('titleFieldHiddenPublicInterface')}</FormHelperText>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <FormControlLabel
              control={
                <Field
                  path="ticket_public.equipment"
                  type="checkbox"
                >
                  <Checkbox />
                </Field>
              }
              label={t('makeEquipmentSelectionAvailable')}
            />
            <FormHelperText>{t('allowsUsersSelectEquipmentConcernedByTicket')}</FormHelperText>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <FormControlLabel
              control={
                <Field
                  path="ticket_public.validation_enabled"
                  type="checkbox"
                >
                  <Checkbox />
                </Field>
              }
              label={t('enableTicketAutoValidation')}
            />
            <FormHelperText>{t('whenOptionActivatedTicketArrivesAlreadyValidated')}</FormHelperText>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <FormControlLabel
              control={
                <Field
                  path="ticket_public.creator_enabled"
                  type="checkbox"
                >
                  <Checkbox />
                </Field>
              }
              label={t('creator')}
            />
            <FormHelperText>{t('ticketCreatorFieldAddedToPublicInterface')}</FormHelperText>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <FormControlLabel
              control={
                <Field
                  path="ticket_public.creator_phone_enabled"
                  type="checkbox"
                >
                  <Checkbox />
                </Field>
              }
              label={t('phoneNumber')}
            />
            <FormHelperText>{t('phoneNumberFieldAddedToPublicInterface')}</FormHelperText>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <FormControlLabel
              control={
                <Field
                  path="ticket_public.show_custom_fields"
                  type="checkbox"
                >
                  <Checkbox />
                </Field>
              }
              label={t('customFields')}
            />
            <FormHelperText>
              {t('customFieldsInterventionsWillBeVisiblePublicInterface')}
            </FormHelperText>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <FormControlLabel
              control={
                <Field
                  path="ticket_public.creator_email_enabled"
                  type="checkbox"
                >
                  <Checkbox />
                </Field>
              }
              label={t('emailAddress')}
            />
            <FormHelperText>{t('emailAddressFieldAddedPublicInterface')}</FormHelperText>
          </Grid>

          {creatorEmailEnabled && (
            <>
              <Grid
                item
                xs={12}
              >
                <FormControlLabel
                  control={
                    <Field
                      path="ticket_public.email_is_required"
                      type="checkbox"
                    >
                      <Checkbox />
                    </Field>
                  }
                  label={t('emailAddressIsRequired')}
                />
                <FormHelperText>{t('emailAddressIsRequired')}</FormHelperText>
              </Grid>

              <Grid
                item
                xs={12}
              >
                <FormControlLabel
                  control={
                    <Field
                      path="ticket_public.creator_email_domains_whitelist_enabled"
                      type="checkbox"
                    >
                      <Checkbox />
                    </Field>
                  }
                  label={t('enableSecurityViaDomainNames')}
                />
                <FormHelperText>{t('selectAuthorizedEmailDomains')}</FormHelperText>
              </Grid>

              {whiteListEnabled && (
                <Grid
                  item
                  xs={12}
                >
                  <Autocomplete
                    multiple
                    freeSolo
                    classes={{ popper: classes.popperHide }}
                    value={whiteList || []}
                    onChange={(e, values) => setWhiteList(values)}
                    options={[]}
                    renderTags={(values, getTagProps) =>
                      whiteList.map((el, index) => (
                        <Chip
                          key={k(index)}
                          label={el}
                          variant="outlined"
                          size="small"
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        variant="outlined"
                        label={t('enterAuthorizedDomainNamesWhithout')}
                        {...params}
                      />
                    )}
                  />
                </Grid>
              )}

              <Grid
                item
                xs={12}
              >
                <FormControlLabel
                  control={
                    <Field
                      path="ticket_public.notifications_enabled"
                      type="checkbox"
                    >
                      <Checkbox />
                    </Field>
                  }
                  label={t('emailNotifications')}
                />
                <FormHelperText>{t('chooseEmailsWillBeSentPublicInterface')}</FormHelperText>
              </Grid>

              {publicNotificationsEnabled && (
                <>
                  <Grid
                    item
                    xs={12}
                  >
                    <FormControlLabel
                      control={
                        <Field
                          path="ticket_public.notifications_config.validate"
                          type="checkbox"
                        >
                          <Checkbox />
                        </Field>
                      }
                      label={t('validatePermission')}
                    />
                    <FormHelperText>{t('emailWillBeSentTicketValidated')}</FormHelperText>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                  >
                    <FormControlLabel
                      control={
                        <Field
                          path="ticket_public.notifications_config.date"
                          type="checkbox"
                        >
                          <Checkbox />
                        </Field>
                      }
                      label={t('rejectedIntervention')}
                    />
                    <FormHelperText>{t('emailWillBeSentTicketScheduledVisitDate')}</FormHelperText>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                  >
                    <FormControlLabel
                      control={
                        <Field
                          path="ticket_public.notifications_config.finish"
                          type="checkbox"
                        >
                          <Checkbox />
                        </Field>
                      }
                      label={t('interventionDone')}
                    />
                    <FormHelperText>{t('emailWillSentTicketInterventionCompleted')}</FormHelperText>
                  </Grid>
                </>
              )}
            </>
          )}
        </>
      )}
    </Grid>
  );
}

function BackgroundImageField() {
  const { dispatch, requestStatus } = useAsyncDispatch();

  const { value: background, setValue: setBackground } = useField('ticket_public.background');

  const handleFileUpload = (e) => {
    if (e.target.files?.length) {
      const formData = new FormData();
      formData.append('background', e.target.files[0]);
      dispatch(uploadFile, { formData }).then(
        ({ data, error }) => !error && setBackground(data.url)
      );
    }
  };

  const { t } = useTranslation();

  return (
    <Grid
      item
      xs={12}
    >
      <List>
        <BlurredProgress in={requestStatus === 'loading'} />
        <ListItem>
          <ListItemAvatar>
            <Avatar
              src={background}
              alt=""
            />
          </ListItemAvatar>
          <ListItemText>{t('backgroundImagePublicInterfaces')}</ListItemText>
          <ListItemSecondaryAction>
            <ButtonGroup variant="text">
              <Button
                disabled={!background || requestStatus === 'loading'}
                onClick={() => setBackground(null)}
              >
                <Cancel />
              </Button>
              <Button
                component="label"
                disabled={requestStatus === 'loading'}
              >
                <input
                  hidden
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileUpload(e, 'header')}
                />
                <AddPhotoAlternateOutlined />
              </Button>
            </ButtonGroup>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </Grid>
  );
}
