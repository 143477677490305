import { factureStates } from 'constants/invoiceStates';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { useConfiguration } from 'hooks/useConfiguration';
import { useRole } from 'hooks/useRole';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import useNotifications from 'hooks/useNotifications';
import { setFactureState } from 'store/accountingSlice';
import { useTranslation } from 'react-i18next';

export default function ValidationColumn({ facture_state, parentId, _id, state, viewId, type }) {
  const config = useConfiguration();
  const role = useRole();

  const { t } = useTranslation();

  const { dispatch } = useAsyncDispatch();
  const notify = useNotifications();

  const handleChange = (_, value) => {
    const dispatchCallbacks = {
      onSuccess: () =>
        notify.success(value === 0 ? t('currentProcessingRecorded') : t('validationSaved')),
      onError: () => notify.error('Something wrong with')
    };

    dispatch(
      setFactureState,
      {
        type,
        value: value === null ? -1 : value
      },
      dispatchCallbacks,
      { id: _id }
    );
  };

  return parentId ? null : (
    <div>
      <ToggleButtonGroup
        style={{ width: '100%' }}
        size="small"
        value={facture_state[type]}
        exclusive
        onChange={handleChange}
      >
        {[
          {
            label: t(factureStates.to_valid.labelAction),
            value: 1,
            backgroundColor: '#31b559'
          },
          {
            label: t(factureStates.analyse.labelAction),
            value: 0,
            backgroundColor: '#827596'
          }
        ].map(({ label, value, backgroundColor }) => (
          <ToggleButton
            disabled={
              !['finished', 'closed'].includes(state) ||
              ['to_complete'].includes(facture_state.key) ||
              !role.permission('accounting', 'check_2') ||
              facture_state.lock ||
              (config?.comptability?.check_1_obligatory && facture_state.check_1 === -1)
            }
            key={`check_1-${label}-${_id}`}
            value={value}
            style={{
              color:
                facture_state.check_2 < 0 || value !== facture_state.check_2 ? 'initial' : 'white',
              backgroundColor:
                facture_state.check_2 < 0 || value !== facture_state.check_2
                  ? 'initial'
                  : backgroundColor,
              opacity:
                !['finished', 'closed'].includes(state) ||
                ['to_complete'].includes(facture_state.key) ||
                !role.permission('accounting', 'check_2') ||
                facture_state.lock ||
                (config?.comptability?.check_1_obligatory && facture_state.check_1 === -1)
                  ? 0.6
                  : 1,
              textTransform: 'initial',
              whiteSpace: 'nowrap'
            }}
          >
            {label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  );
}
