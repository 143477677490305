import Ticket from 'entities/Ticket/Ticket';

export default function InterfaceMaintenancesUsersPage() {
  return (
    <Ticket
      page
      userMaintenance
    />
  );
}
