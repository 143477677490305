export function isActive(facture = null) {
  if (!facture) return false;
  return facture.isActive;
}

export function isDeleted(facture = null) {
  if (!facture) return true;
  return facture.deleted.state;
}

export function isIntervener(facture = null, intervenerId = null) {
  if (!facture || !intervenerId) return false;
  return facture._intervener === intervenerId;
}

export function getTotalPrice({ factures, intervenerId }) {
  return getAll({ factures, intervenerId }).reduce((total, facture) => total + facture.price, 0);
}

export function getAll({ factures, intervenerId }) {
  return factures.filter(
    (d) => !isDeleted(d) && (intervenerId ? isIntervener(d, intervenerId) : isActive(d))
  );
}

const FactureHelper = {
  isDeleted,
  isIntervener,
  isActive,
  getTotalPrice,
  getAll
};

export default FactureHelper;
