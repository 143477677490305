import { createSlice } from '@reduxjs/toolkit';
import generateAsyncThunk from 'utils/generateAsyncThunk';

export const createElement = generateAsyncThunk({ type: 'POST', endpoint: 'fields' });
export const updateElement = generateAsyncThunk({ type: 'PUT', endpoint: 'fields/${id}' });
export const getElement = generateAsyncThunk({ type: 'GET', endpoint: 'fields/${id}' });
export const getElements = generateAsyncThunk({ type: 'POST', endpoint: 'fields/list' });
export const deleteElement = generateAsyncThunk({ type: 'DELETE', endpoint: 'fields/${id}' });
export const restoreElement = generateAsyncThunk({
  type: 'POST',
  endpoint: 'fields/recycle/${id}'
});

const initialState = {
  db: {}
};

export const fieldsSlice = createSlice({
  name: 'fields',
  initialState,
  reducers: {},
  extraReducers: {}
});

export const { flushElements, flushElement } = fieldsSlice.actions;
export default fieldsSlice.reducer;
