import { Fab, Button, Box, Portal } from '@material-ui/core';

import clsx from 'clsx';

import { useIsBelowMd } from 'hooks/useMQ';
import useStyles from './Entity.styles';

function EntityCreate({
  ctaContainer,
  in: _in,
  size,
  label,
  icon,
  onClick,
  noBelowMd,
  fab,
  style = {}
}) {
  const isBelowMd = useIsBelowMd();
  const classes = useStyles();

  return (
    <>
      {((noBelowMd && !isBelowMd) || !noBelowMd) && !fab && _in && (
        <Portal container={ctaContainer?.current}>
          <Box
            className={classes.cta}
            style={style}
          >
            <Button
              variant="contained"
              color="primary"
              className={classes.fabButton}
              onClick={onClick}
            >
              {icon(classes.fabButtonIcon)}
              {!isBelowMd && label}
            </Button>
          </Box>
        </Portal>
      )}

      {fab && _in && (
        <Fab
          size={size || 'small'}
          color="primary"
          className={clsx({
            [classes.fab]: true,
            [classes.fabList]: true
          })}
          onClick={onClick}
        >
          {icon()}
        </Fab>
      )}
    </>
  );
}

export default EntityCreate;
