import {
  getElement,
  getElements,
  getPublicElements,
  createElement,
  updateElement,
  flushElements,
  deleteElement,
  restoreElement
} from 'store/locationsSlice';

import LocationMenus from './LocationMenus';
import LocationPreview from './LocationPreview';
import LocationSkeleton from './LocationSkeleton';
import LocationView from './LocationView';
import LocationTabs from './LocationTabs';
import LocationFilters, { formatFilter } from './LocationFilters';
import LocationFormValues, { LocationValidation, formatForm } from './LocationFormValues';
import LocationTranslations from './LocationTranslations';
import LocationExport from './LocationExport';
import LocationForm from './LocationForm';
import LocationSorts from './LocationSorts';
import LocationTable from './LocationTable';

import EntityWrapper from 'entities/EntityWrapper';

import { Image } from '@material-ui/icons';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'hooks/useAuth';

export default function Location({ hiddenFilters, facturation, ...rest }) {
  const auth = useAuth();

  const { t } = useTranslation();

  const _hiddenFilters = useMemo(() => {
    return {
      ...hiddenFilters,
      ...(facturation ? { section: { facturation: true, warehouse: false, location: false } } : {})
    };
  }, [facturation, hiddenFilters]);

  return (
    <EntityWrapper
      entity="locations"
      entityFieldsSlice="location"
      sublistParentKey="_parentLocations"
      facturation={facturation}
      skeletonComponent={LocationSkeleton}
      previewComponent={LocationPreview}
      viewComponent={LocationView}
      formComponent={LocationForm}
      formatForm={formatForm}
      formatFilter={formatFilter}
      getFilters={LocationFilters}
      getInitialValues={LocationFormValues}
      getFormValidation={LocationValidation}
      getTabs={LocationTabs}
      getTables={LocationTable}
      getMenus={LocationMenus}
      getExportColumns={LocationExport}
      getTranslations={LocationTranslations}
      getSorts={LocationSorts}
      getElement={getElement}
      getElements={auth.interface.isPublic ? getPublicElements : getElements}
      createElement={createElement}
      updateElement={updateElement}
      flushElements={flushElements}
      deleteElement={deleteElement}
      restoreElement={restoreElement}
      formAttachment
      formAttachmentIcon={<Image />}
      formAttachmentLabel={t('coverPicture')}
      hiddenFilters={_hiddenFilters}
      {...rest}
    />
  );
}
