import {
  Box,
  Button,
  Menu,
  MenuItem,
  Tooltip,
  ListItemText,
  IconButton,
  Portal
} from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';

import { flushElement, updateDate, setDate as xhrDate } from 'store/ticketsSlice';

import moment from 'moment';

import FAIcon from 'components/ui/FAIcon';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import frLocale from '@fullcalendar/core/locales/fr';

import { add, isWithinInterval } from 'date-fns';
import { dateToLocalFormat } from 'utils/dates';

import './Calendar.css';
import useStyles from './Calendar.styles';
import clsx from 'clsx';
import { useState, useRef, useEffect, useMemo } from 'react';
import useKeys from '@flowsn4ke/usekeys';
import { useRole } from 'hooks/useRole';
import { useEntity } from 'contexts/entities/entityContext';

import TicketPreview from 'entities/Ticket/TicketPreview';
import Ticket from 'entities/Ticket/Ticket';
import Preview from 'layouts/entities/Preview';
import { useDispatch } from 'react-redux';
import useAsyncDispatch from 'hooks/useAsyncDispatch';

import BlurredProgress from 'components/BlurredProgress';

import CalendarPopover from '../ui/CalendarPopover';
import { useIsBelowSm } from 'hooks/useMQ';
import { useConfiguration } from 'hooks/useConfiguration';

import { isSameDay } from 'date-fns';
import { useTranslation } from 'react-i18next';

const views = {
  timeGridDay: 'Journée',
  timeGridWeek: 'Semaine',
  dayGridMonth: 'Mois'
};

export default function Calendar({
  expand,
  setCalendarRange: setCalendarRangeTickets,
  elements,
  setIsOpenForm,
  headeRef,
  isLoading
}) {
  const { db } = useEntity();
  const config = useConfiguration();
  const { dispatch: asyncDispatch } = useAsyncDispatch();
  const dispatch = useDispatch();

  const role = useRole();
  const calendar = useRef();

  const lsView = localStorage.getItem('calendarView');
  const defaultView = lsView
    ? Object.keys(views).includes(lsView)
      ? lsView
      : 'timeGridDay'
    : 'timeGridDay';

  const [ticketId, setTicketId] = useState(null);
  const [date, setDate] = useState(new Date());
  const [view, setView] = useState(defaultView);
  const [viewsOpen, setViewsOpen] = useState(false);

  const [showDataPickerPopover, setShowDataPickerPopover] = useState(false);

  const [calendarRange, setCalendarRange] = useState({
    start: null,
    end: null
  });

  const makeDate = ({ date, date_type, duration, index }) => {
    const dateValue = new Date(date).getTime();

    return {
      start: date,
      end: add(new Date(date), { minutes: duration }),
      duration,
      dateValue,
      date_type,
      index
    };
  };

  const events = useMemo(() => {
    return (elements || []).reduce((acc, curr) => {
      const element = db[curr._id];
      const contractSummons = element.contract;
      const contractDates = config.isContractor ? element.contractParent : element.contract;
      const { intervention_dates, visit_dates } = contractDates;
      let dates = [];

      const isDuplicate = (left, right) =>
        view.includes('GridMonth')
          ? isSameDay(new Date(left), new Date(right))
          : left === new Date(right).getTime();

      intervention_dates
        ?.filter((i) => !i.deleted.state)
        .forEach(({ date, duration }, index) =>
          dates.push(makeDate({ date, duration, date_type: 'intervention', index }))
        );

      visit_dates
        .filter((v) => !v.deleted.state)
        .forEach(
          ({ date, duration }, index) =>
            !dates.find((d) => isDuplicate(d.dateValue, date)) &&
            dates.push(makeDate({ date, duration, date_type: 'visit', index }))
        );
      const color = !contractSummons._summons?.length
        ? '#dddddd'
        : contractSummons._summons[0].isCollaborator
          ? contractSummons._summons[0].color
          : contractSummons._summons[0].partner
            ? contractSummons._summons[0].partner.color
            : '#535b7c';

      dates = dates
        .filter((date) => isWithinInterval(new Date(date.start), calendarRange))
        .map((date) => ({
          durationEditable: true,
          start: date.start,
          end: date.end,
          backgroundColor: color,
          borderColor: '#dddddd',
          textColor: !contractSummons._summons?.length ? 'black' : 'white',
          extendedProps: {
            ticketId: curr._id,
            type: date.date_type,
            index: date.index
          }
        }));

      return [...acc, ...dates];
    }, []);
  }, [elements, view]);

  const ticketCount = useMemo(
    () =>
      events.reduce((a, b) => {
        const key = moment(b.start).format('DD/MM');

        return {
          ...a,
          [key]: a[key] ? a[key] + 1 : 1
        };
      }, {}),
    [events]
  );

  useEffect(() => {
    if (calendar.current) {
      calendar.current.getApi().changeView(view);
    }
    localStorage.setItem('calendarView', view);
  }, [view]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (calendar.current) {
        window.dispatchEvent(new Event('resize'));
      }
    }, 360);
    return () => clearTimeout(timeoutId);
  }, [expand]);

  const k = useKeys();
  const classes = useStyles();

  const datesSet = (event) => [
    setDate(event.start),
    setCalendarRange({
      start: event.start,
      end: event.end
    }),
    setCalendarRangeTickets({
      start: event.start,
      end: event.end
    })
  ];

  const next = () => calendar.current && calendar.current.getApi().next();
  const prev = () => calendar.current && calendar.current.getApi().prev();

  const { t } = useTranslation();

  const eventContent = (info) => {
    const { ticketId } = info.event.extendedProps;
    const ticket = db[ticketId];

    return (
      <Tooltip
        placement="right"
        title={
          <>
            {ticket.state}
            <br />
            {ticket.title}
            <br />
            <span>
              {dateToLocalFormat(info.event.start)} {info.event.end && 'à'}{' '}
              {info.event.end && dateToLocalFormat(info.event.end, 'pp')}
              <br />
              {ticket?._locations?.length &&
                ticket._locations
                  .filter((l) => !!l.address)
                  .map((l) => l.address.fullAddress)
                  .join(', ')}
              {ticket?._jobs?.length && ticket._jobs.map((j) => t(j.name)).join(', ')}
            </span>
            {!!ticket.description?.length && (
              <>
                <br />
                <br />
                {ticket.description}
              </>
            )}
          </>
        }
      >
        <Box
          onClick={() => setTicketId(ticket._id)}
          height={'100%'}
          width={'100%'}
          borderRadius={8}
          overflow={'hidden'}
          border={info.event.textColor !== 'white' && '1px solid #dddddd'}
        >
          <Preview
            calendar
            disableClick
            variant
            previewComponent={TicketPreview}
            stylePreview={{ alignItems: 'flex-start', padding: '0px' }}
            element={ticket}
          />
        </Box>
      </Tooltip>
    );
  };

  const eventResize = (info) => {
    asyncDispatch(
      updateDate,
      {
        type: info.event.extendedProps.type,
        prop: 'duration',
        value: moment(info.event.end).diff(info.event.start, 'minutes'),
        index: info.event.extendedProps.index,
        isParent: config.isContractor ? true : false
      },
      {},
      { id: info.event.extendedProps.ticketId }
    );
  };

  const eventDrop = (info) => {
    const extendedProps = info.event.extendedProps;
    const value = info.event.start;
    asyncDispatch(
      updateDate,
      {
        type: extendedProps.type,
        prop: 'date',
        value,
        index: extendedProps.index,
        isParent: config.isContractor ? true : false
      },
      {},
      { id: extendedProps.ticketId }
    );
  };

  const drop = (info) => {
    if (info.draggedEl) {
      const id = info.draggedEl.dataset.id;
      const value = info.date;
      const extendedProps = {
        ...{
          type: 'visit',
          ticketId: id
        },
        ...(info.event ? { index: info.event.extendedProps.index } : {})
      };

      asyncDispatch(
        xhrDate,
        {
          type: extendedProps.type,
          date: value,
          isParent: config.isContractor ? true : false
        },
        {},
        { id: extendedProps.ticketId }
      );
      dispatch(flushElement({ id: info.draggedEl.dataset.id }));
    }

    return false;
  };

  let showedMonthDate = date;
  if (date.getDate() >= 25) {
    showedMonthDate = new Date(date).setMonth(date.getMonth() + 1);
  }
  const showedMonth = dateToLocalFormat(
    showedMonthDate,
    view === 'timeGridDay' ? 'eee dd LLL' : view === 'dayGridMonth' ? 'MMMM yyyy' : 'MMMM'
  );

  const isBelowSm = useIsBelowSm();
  return (
    <Box className={classes.root}>
      <Portal container={headeRef?.current}>
        <header className={classes.header}>
          <Button
            onClick={(e) => setShowDataPickerPopover(e.currentTarget)}
            className={classes.headerLeft}
          >
            <FAIcon
              className={classes.headerLeftIcon}
              collection={'fal'}
              icon={'calendar'}
              size={'small'}
            />
            <strong className={classes.title}>{showedMonth}</strong>
          </Button>
          <CalendarPopover
            anchorEl={showDataPickerPopover}
            date={date}
            open={!!showDataPickerPopover}
            closeOnChange
            onChange={({ date }) => [calendar.current.getApi().gotoDate(date)]}
            onClose={() => setShowDataPickerPopover(null)}
          />
          <Box className={classes.headerRight}>
            <IconButton
              className={classes.navigation}
              onClick={() => prev()}
            >
              <ChevronLeft />
            </IconButton>
            <IconButton
              className={classes.navigation}
              onClick={() => next()}
            >
              <ChevronRight />
            </IconButton>
            <Button
              className={classes.views}
              onClick={(e) => setViewsOpen(e.currentTarget)}
            >
              {views[view]}
            </Button>
          </Box>
        </header>
      </Portal>

      <Menu
        transitionDuration={0}
        anchorEl={viewsOpen}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        open={!!viewsOpen}
        onClose={() => setViewsOpen(false)}
      >
        {views &&
          Object.keys(views).map((v, i) => (
            <MenuItem
              dense
              key={k(i)}
              onClick={() => [setViewsOpen(false), setView(v)]}
            >
              <ListItemText primary={views[v]} />
            </MenuItem>
          ))}
      </Menu>
      <Box
        className={clsx(classes.calendar, {
          ['indicator']: view === 'timeGridDay',
          ['week']: ['timeGridWeek', 'timeGridNoWeek'].includes(view)
        })}
      >
        {isLoading && <BlurredProgress in />}
        <FullCalendar
          events={events}
          eventContent={eventContent}
          ref={calendar}
          datesSet={datesSet}
          slotMinTime="06:00:00"
          dayHeaderContent={({ date }) => {
            const count = ticketCount && ticketCount[moment(date).format('DD/MM')];

            return (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <Box
                  fontSize={10}
                  fontWeight={400}
                  margin={'6px 0px'}
                >
                  {dateToLocalFormat(date, !isBelowSm ? 'EEEE' : 'EEEEE')}
                </Box>
                <Box
                  fontSize={18}
                  fontWeight={500}
                  color="#66788a"
                  marginBottom="6px"
                >
                  {dateToLocalFormat(date, 'd')}
                </Box>
                {!!count && (
                  <Box
                    color="secondary"
                    fontSize="11px"
                    textTransform="lowercase"
                  >
                    {count} intervention{count > 1 ? 's' : ''}
                  </Box>
                )}
              </Box>
            );
          }}
          dayHeaderFormat={{ day: 'numeric', omitCommas: true }}
          drop={drop}
          eventDrop={eventDrop}
          locale={frLocale}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          //Check if update permissions
          editable={role.permission('tickets', 'update')}
          //Check if create permissions
          select={(a) => {
            if (role.permission('tickets', 'create')) {
              setIsOpenForm({
                visit_date: a.startStr,
                duration: moment(a.endStr).diff(a.startStr, 'minutes')
              });
            }
          }}
          eventResize={eventResize}
          selectable={true}
          handleWindowResize={true}
          footer={false}
          header={false}
          initialView={view}
          allDaySlot={false}
          nowIndicator={true}
          droppable={true}
          titleFormat={{ year: 'numeric', month: 'long' }}
          height={'100%'}
          minTime={'06:00:00'}
          maxTime={'30:00:00'}
        />
      </Box>

      {ticketId && (
        <Ticket
          isDialog
          defaultOpenView
          selectedId={ticketId}
          afterDialogClose={() => setTicketId(null)}
        >
          <></>
        </Ticket>
      )}
    </Box>
  );
}
