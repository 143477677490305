const DELAYS = [
  {
    label: '1h',
    value: 1
  },
  {
    label: '2h',
    value: 2
  },
  {
    label: '4h',
    value: 4
  },
  {
    label: '6h',
    value: 6
  },
  {
    label: '8h',
    value: 8
  },
  {
    label: '12h',
    value: 12
  },
  {
    label: '24h',
    value: 24
  },
  {
    label: '48h',
    value: 48
  },
  {
    label: '72h',
    value: 72
  }
];

export default DELAYS;
