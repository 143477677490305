import { Box, List } from '@material-ui/core';
import ViewItem from 'components/views/ViewItem';
import { PhoneItem, EmailItem } from 'components/views/ViewItems';
import { useTranslation } from 'react-i18next';
import CustomFields from '../CustomFields';

export default function SupplierView({ element }) {
  const { t } = useTranslation();

  return (
    <>
      <Box>
        <Box
          flexDirection="column"
          alignItems="flex-start"
        >
          <Box className="flex flex-col font-semibold text-sm gap-2">
            {element.reference && <p className="text-[#003366] text-xs">#{element.reference}</p>}
            <p className="font-medium">{element.companyName}</p>
          </Box>
        </Box>
      </Box>
      <List>
        {element?.lastName && (
          <ViewItem
            icon="user"
            secondaryIcon
            primary={t('informationContactSupplier')}
            secondary={`${element?.firstName} ${element?.lastName?.toUpperCase()}`}
          />

        )}
        <CustomFields element={element}>
          {!!element.phone?.length && <PhoneItem element={element} />}
          {!!element.email?.length && <EmailItem element={element} />}
        </CustomFields>
      </List>
    </>
  );
}
