import { ChevronRight } from '@material-ui/icons';
import Dialog from 'components/Dialog';
import FAIcon from 'components/ui/FAIcon';
import { useRole } from 'hooks/useRole';
import { SET_RANGE_EVENT_ID } from 'modules/calendar/config';
import { trigger } from 'react-events-utils';
import { useTranslation } from 'react-i18next';

export default function DialogActionsCreate({
  open_dialog_actions,
  set_open_dialog_actions,
  set_open_ticket_form,
  set_open_unavailability_creation_dialog
}) {
  const { t } = useTranslation();
  const role = useRole();

  const user_has_permission_to_create_ticket = role.permission('tickets', 'create');

  const handle_create_ticket = () => {
    if (user_has_permission_to_create_ticket) set_open_ticket_form(true);
  };

  const handle_close = () => {
    set_open_dialog_actions(false);
    trigger(SET_RANGE_EVENT_ID, { start: null, start_idx: null, end: null, end_idx: null });
  };

  return (
    <Dialog
      isOpen={open_dialog_actions}
      onClose={() => handle_close()}
      title={t('whatDoYouWantToDo')}
      icon=""
    >
      <div className="flex flex-col mt-4">
        {user_has_permission_to_create_ticket ? (
          <div
            className="border-2 rounded-lg flex items-center w-full hover:cursor-pointer hover:bg-sky-100/40"
            onClick={() => [set_open_dialog_actions(false), handle_create_ticket()]}
          >
            <div className="mr-1 p-2">
              <FAIcon
                icon="calendar-circle-plus"
                collection="fas"
                size="lg"
                className="text-sky-900"
              />
            </div>
            <div className="w-full text-base font-normal leading-6 text-sky-900 whitespace-nowrap">
              {t('anIntervention')}
            </div>
            <div className="w-full flex items-center justify-end">
              <ChevronRight />
            </div>
          </div>
        ) : null}
        <div
          className="mt-2 border-2 rounded-lg flex items-center hover:cursor-pointer hover:bg-sky-100/40"
          onClick={() => [
            set_open_dialog_actions(false),
            set_open_unavailability_creation_dialog(true)
          ]}
        >
          <div className="mr-1 p-2">
            <FAIcon
              icon="calendar-circle-plus"
              collection="fas"
              size="lg"
              className="text-sky-900"
            />
          </div>
          <div className="w-full text-base font-normal leading-6 text-sky-900 whitespace-nowrap">
            {t('anUnavailability')}
          </div>
          <div className="w-full flex items-center justify-end">
            <ChevronRight />
          </div>
        </div>
      </div>
    </Dialog>
  );
}
