import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  accordionAvatar: {},
  file: {
    display: 'none'
  },
  avatar: {
    position: 'absolute',
    top: 10,
    right: 23,
    display: 'flex',
    justifiyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    zIndex: 1
  },
  avatarButton: {
    marginTop: 10
  },
  avatarButtonSelect: {
    color: theme.palette.info.main
  },
  avatarButtonErease: {
    color: theme.palette.error.light,
    marginTop: 10
  }
}));
