import Event from './Event';
import { compute_events_with_x_overlap_offset } from '../utils';
import { useMemo } from 'react';
import { nanoid } from 'nanoid';
import { INTERVENTION_TYPE, UNAVAILABILITY_TYPE } from '../config';

export default function Events({
  events = [],
  compute_initial_position,
  set_ticket_in_modal,
  set_unavailability_id_to_edit
}) {
  const intervention_events_nodes = useMemo(() => {
    const intervention_events = events.filter((event) => event.type === INTERVENTION_TYPE);
    const events_with_positions = compute_events_with_x_overlap_offset({
      events: intervention_events,
      compute_initial_position
    });
    return events_with_positions.map((event) => (
      <Event
        key={nanoid()}
        {...event}
        set_ticket_in_modal={set_ticket_in_modal}
      />
    ));
  }, [events, compute_initial_position]);

  const unavailability_events_nodes = useMemo(() => {
    const unavailability_events = events.filter((event) => event.type === UNAVAILABILITY_TYPE);
    const events_with_positions = compute_events_with_x_overlap_offset({
      events: unavailability_events,
      compute_initial_position
    });
    return events_with_positions.map((event) => (
      <Event
        key={nanoid()}
        {...event}
        set_unavailability_id_to_edit={set_unavailability_id_to_edit}
      />
    ));
  }, [events, compute_initial_position]);

  return (
    <div>
      <ol className="isolate">{unavailability_events_nodes}</ol>
      <ol className="isolate">{intervention_events_nodes}</ol>
    </div>
  );
}
