import { MONTH_VIEW_TYPE } from 'modules/calendar/config';
import classNames from 'utils/classNames';
import { get_month_events, middle_date } from '../utils';
import { isSameDay } from 'date-fns';
import { useState } from 'react';
import DaysLargeGrid from './DaysLargeGrid';
import DaysSmallGrid from './DaysSmallGrid';
import EventList from './EventList';

export default function MonthlyView({
  view_type,
  now,
  start,
  end,
  events,
  set_ticket_in_modal,
  set_unavailability_id_to_edit
}) {
  const is_visible = view_type === MONTH_VIEW_TYPE;

  const middle_of_the_month = middle_date(start, end);

  const days = get_month_events(start).map((day) => ({ ...day, events: [] }));
  const get_day_idx = (date) => days.findIndex((day) => isSameDay(day.date, date));
  events.forEach((event) => {
    const start = new Date(event.start);
    const day_idx = get_day_idx(start);
    days[day_idx].events.push(event);
  });

  const [selected_day_idx, set_selected_day_idx] = useState(get_day_idx(now));

  return (
    <div
      className={classNames('flex flex-col w-full h-full rounded-b-xl', !is_visible && 'hidden')}
    >
      <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
        <div className="bg-white py-2">
          M<span className="sr-only sm:not-sr-only">on</span>
        </div>
        <div className="bg-white py-2">
          T<span className="sr-only sm:not-sr-only">ue</span>
        </div>
        <div className="bg-white py-2">
          W<span className="sr-only sm:not-sr-only">ed</span>
        </div>
        <div className="bg-white py-2">
          T<span className="sr-only sm:not-sr-only">hu</span>
        </div>
        <div className="bg-white py-2">
          F<span className="sr-only sm:not-sr-only">ri</span>
        </div>
        <div className="bg-white py-2">
          S<span className="sr-only sm:not-sr-only">at</span>
        </div>
        <div className="bg-white py-2">
          S<span className="sr-only sm:not-sr-only">un</span>
        </div>
      </div>
      <div className="flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto">
        <DaysLargeGrid
          days={days}
          middle_of_the_month={middle_of_the_month}
          set_ticket_in_modal={set_ticket_in_modal}
          set_unavailability_id_to_edit={set_unavailability_id_to_edit}
        />
        <DaysSmallGrid
          now={now}
          days={days}
          selected_day_idx={selected_day_idx}
          set_selected_day_idx={set_selected_day_idx}
          middle_of_the_month={middle_of_the_month}
        />
      </div>
      <EventList
        events={days[selected_day_idx]?.events || []}
        set_ticket_in_modal={set_ticket_in_modal}
        set_unavailability_id_to_edit={set_unavailability_id_to_edit}
      />
    </div>
  );
}
