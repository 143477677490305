import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  calendar: {
    position: 'relative',
    width: '100%',
    height: '100%',
    ['& .fc-scrollgrid-liquid']: {
      border: 'none !important'
    },
    ['&:not(.week) .fc-scrollgrid-section-header']: {
      display: 'none'
    },
    ['& .fc-timegrid-event .fc-event-main']: {
      padding: 0
    },
    ['& .fc-scrollgrid-sync-inner']: {
      justifyContent: 'center'
    },
    ['& th, .fc-head-container.fc-widget-header']: {
      padding: '10px 0px',
      border: 'none !important'
    },
    ['& .fc-col-header-cell-cushion']: {
      fontSize: 13,
      textTransform: 'capitalize'
    },
    ['& .fc-timegrid-slot']: {
      height: '23px !important'
    },
    ['& .fc-timegrid-now-indicator-line']: {
      borderColor: `#9499a0 !important`,
      borderTop: `#9499a0 solid 2px`,
      boxShadow: theme.shadows[1]
    },
    ['& .fc-timegrid-now-indicator-container']: {
      overflow: 'visible'
    },
    ['& .fc-timegrid-now-indicator-arrow']: {
      borderRadius: '50%',
      width: 26,
      height: 26,
      transform: 'translateY(-6px)',
      bottom: 20,
      left: 24,
      marginTop: -6,
      zIndex: 200,
      position: 'relative',
      boxShadow: theme.shadows[1],
      display: 'none',
      border: 'none',
      backgroundColor: `#9499a0`,
      color: 'white'
    },
    ['&.indicator .fc-timegrid-now-indicator-arrow']: {
      display: 'none'
    }
  },
  interveners: {
    height: '100%'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '6px 6px 6px 16px',
    height: 48,
    background: 'white',
    boxShadow: 'none',
    zIndex: 300,
    borderRadius: '16px 16px 0px 0px',
    position: 'relative'
  },
  navigation: {
    padding: 6
  },
  headerRight: {
    display: 'flex',
    alignItems: 'center'
  },
  headerLeft: {
    display: 'flex',
    position: 'relative',
    right: 10,
    alignItems: 'center',
    fontSize: 12
  },
  headerLeftIcon: {
    position: 'relative',
    right: 14,
    fontSize: 20,
    width: 20,
    height: 20,
    bottom: 0
  },

  title: {
    userSelect: 'none',
    fontSize: 14,
    fontWeight: '500',
    marginRight: 6,
    textTransform: 'capitalize'
  },
  today: {
    position: 'relative',
    top: -1,
    left: 10,
    marginLeft: 'auto'
  },
  views: {
    marginLeft: 'auto'
  }
}));
