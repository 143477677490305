export default function IntervenerColumn({ parentId, interveners }) {
  return parentId || !interveners.length ? null : (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end'
        }}
      >
        {interveners[0].isContractor
          ? interveners[0].companyName
          : `${interveners[0].firstName} ${interveners[0].lastName}`}
      </div>
    </>
  );
}
