import { useState, useEffect, useMemo } from 'react';
import { TextField, Grid, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import { isArray, throttle } from 'lodash-es';
import { useForm } from 'frmx';
import useKeys from '@flowsn4ke/usekeys';
import { useTranslation } from 'react-i18next';

const autocompleteService = { current: null };

export default function GoogleMapsLocation({ placeholder, autoFocus, path, className, setValue: setField }) {
  const { getOneField } = useForm();
  const { t } = useTranslation();
  const k = useKeys();

  const initialFieldValue = useMemo(() => {
    const field = getOneField(path);
    return field?.fullAddress?.length > 0 ? field.fullAddress : null;
  }, [path, getOneField]);

  const [value, setValue] = useState(initialFieldValue);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);

  const fetchPredictions = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  useEffect(() => {
    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) return;

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return;
    }

    let active = true;
    fetchPredictions({ input: inputValue }, (results) => {
      if (active) {
        const newOptions = value ? [value, ...results] : results;
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, value, fetchPredictions]);

  const handleAddressSelection = (event, address) => {
    if (address) {
      const map = new window.google.maps.Map(document.createElement('div'));
      const places = new window.google.maps.places.PlacesService(map);

      places.getDetails({ placeId: address.place_id }, (details, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          const addressComponents = details.address_components.reduce((acc, component) => {
            acc[component.types[0]] = component.long_name;
            return acc;
          }, {});

          setField({
            name: details.formatted_address,
            streetNumber: addressComponents.street_number,
            route: addressComponents.route,
            city: addressComponents.locality,
            country: addressComponents.country,
            postalCode: addressComponents.postal_code,
            fullAddress: details.formatted_address,
            lat: details.geometry.location.lat(),
            lng: details.geometry.location.lng()
          });
        }
      });
    }

    setOptions(address ? [address, ...options] : options);
    setValue(address);
  };

  const handleInputChange = (event, newInputValue) => setInputValue(newInputValue);

  const renderOption = (option) => {
    try {
      const matches = option.structured_formatting.main_text_matched_substrings;
      const parts = parse(
        option.structured_formatting.main_text,
        matches.map((match) => [match.offset, match.offset + match.length])
      );

      return (
        <Grid
          container
          alignItems="center"
        >
          <Grid
            item
            xs
          >
            {parts.map((part, i) => (
              <span
                key={k(i)}
                style={{ fontWeight: part.highlight ? 700 : 400 }}
              >
                {part.text}
              </span>
            ))}
            <Typography
              variant="body2"
              color="textSecondary"
            >
              {option.structured_formatting.secondary_text}
            </Typography>
          </Grid>
        </Grid>
      );
    } catch {
      return null;
    }
  };

  const noOptionsText = t('startToType');

  return (
    <Autocomplete
      id="google-map-autocomplete"
      options={isArray(options) ? options : []}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description || '')}
      filterOptions={(x) => x}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText={noOptionsText}
      onChange={handleAddressSelection}
      onInputChange={handleInputChange}
      className={className}
      style={{ width: '100%' }}
      renderInput={(params) => (
        <TextField
          autoFocus={autoFocus}
          {...params}
          variant="outlined"
          label={placeholder || t('address')}
          fullWidth
        />
      )}
      renderOption={renderOption}
    />
  );
}
