import FAIcon from 'components/ui/FAIcon';

export default function Skeleton() {
  return (
    <>
      <div className="w-22 flex flex-col items-center text-center m-2 animate-pulse">
        <div className="h-22 w-22 flex flex-col items-center text-black text-xl font-black rounded">
          <FAIcon
            draggable="false"
            collection="fad"
            icon="file"
            className="pointer-events-none text-slate-500"
            size="3xl"
          />
        </div>
        {/* <p className="selection:bg-transparent w-16 break-words text-xs rounded px-1 py-0.5 mt-0.5">
      Loading...
    </p> */}
      </div>
    </>
  );
}
