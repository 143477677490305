const ContractorTranslations = (configuration) => ({
  pick: configuration.isContractor ? 'entityContractorPick1' : 'entityContractorPick2',
  create: configuration.isContractor ? 'entityContractorCreate1' : 'entityContractorCreate2',
  update: configuration.isContractor ? 'entityContractorUpdate1' : 'entityContractorUpdate2',
  entityName: configuration.isContractor ? 'entityContractorName1' : 'entityContractorName2',
  noResultLabel: configuration.isContractor
    ? 'entityContractornoResultLabe1'
    : 'entityContractornoResultLabe2',
  createDialog: configuration.isContractor
    ? 'entityContractorCreateDialog1'
    : 'entityContractorCreateDialog2',
  updateDialog: configuration.isContractor
    ? 'entityContractorUpdateDialog1'
    : 'entityContractorUpdateDialog2'
});

export default ContractorTranslations;
