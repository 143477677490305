import Dialog from 'components/Dialog';
import { useContext, useRef } from 'react';
import { createContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

const InfoContext = createContext();

export default function useInfo() {
  return useContext(InfoContext);
}

const defaultTitle = 'carefulThisEnvironmentIsForLearning';

function Info({ isOpen, onConfirm, icon, title, helperText, confirmLabel = 'validate' }) {
  const { t } = useTranslation();

  return (
    <Dialog
      isOpen={isOpen}
      title={t(title)}
      icon={icon}
      width="lg"
      onClose={() => {}}
      className="z-[1400]"
    >
      {Boolean(helperText) && <p className="my-4 text-sm">{t(helperText)}</p>}
      <div className="flex justify-end mt-4">
        <div className="space-x-2">
          <button
            onClick={() => onConfirm()}
            className="bg-sky-900 disabled:opacity-50 text-white px-3 py-2 rounded-full"
          >
            {t(confirmLabel)}
          </button>
        </div>
      </div>
    </Dialog>
  );
}

export function InfoProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);
  const [onConfirm, setOnConfirm] = useState(null);
  const [title, setTitle] = useState(defaultTitle);
  const [helperText, setHelperText] = useState();
  const [confirmLabel, setConfirmLabel] = useState();
  const [icon, setIcon] = useState();

  const confirm = useRef(
    ({ onConfirm, title = defaultTitle, icon = 'warning', helperText, confirmLabel }) => {
      setOnConfirm(() => onConfirm);
      setTitle(title);
      setHelperText(helperText);
      setIsOpen(true);
      setConfirmLabel(confirmLabel);
      setIcon(icon);
    }
  );

  const close = useRef(() => [setIsOpen(false), setOnConfirm(null)]);

  return (
    <InfoContext.Provider value={confirm.current}>
      <Info
        isOpen={isOpen}
        onConfirm={() => [onConfirm(), close.current()]}
        icon={icon}
        title={title}
        helperText={helperText}
        confirmLabel={confirmLabel}
      />
      {children}
    </InfoContext.Provider>
  );
}
