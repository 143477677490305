import { Empty } from 'layouts/entities/List';
import {
  Box,
  Chip,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  IconButton,
  Paper,
  Typography
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import DialogTitle from 'components/dialogs/DialogTitle';
import { useMemo, useState } from 'react';
import FAIcon from 'components/ui/FAIcon';
import { Field, Form, Submit } from 'frmx';
import Tabs from 'components/tabs/Tabs';
import { createNote, updateNote, deleteNote } from 'store/ticketsSlice';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import BlurredProgress from 'components/BlurredProgress';
import useNotifications from 'hooks/useNotifications';
import useKeys from '@flowsn4ke/usekeys';
import { dateToLocalFormat } from 'utils/dates';
import { useConfiguration } from 'hooks/useConfiguration';
import { useAuth } from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { useEntity } from 'contexts/entities/entityContext';

export default function NotesTab(props) {
  const { ticket } = props;

  const [noteDialogIsOpen, setNoteDialogIsOpen] = useState(false);
  const [note, setNote] = useState(null);
  const [type, setType] = useState(null);

  const config = useConfiguration();
  const open = (type) => [setType(type), setNoteDialogIsOpen(true)];
  const close = () => [setType(null), setNote(null), setNoteDialogIsOpen(false)];
  const edit = (note) => [setNote(note), setNoteDialogIsOpen(true)];

  const tabs = useMemo(() => {
    return getNoteSections(ticket, config, { open, edit, close });
  }, [ticket.notes, ticket.viewNotes, ticket.viewNotesManagers]);

  return (
    <Paper
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%'
      }}
    >
      <NoteEditor
        open={noteDialogIsOpen}
        close={close}
        ticket={ticket}
        note={note}
        type={type}
      />
      <Tabs
        noPosition
        fullWidth
        tabs={tabs}
      />
    </Paper>
  );
}

function HeaderLabel({ title, len }) {
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        width: '100%'
      }}
    >
      {t(title)}
      {!!len && (
        <Chip
          style={{ width: '3em', marginRight: '.5em' }}
          label={len}
          color="primary"
          size="small"
        />
      )}
    </div>
  );
}

const sortNotes = (n) => [...n].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

const getNoteSections = (ticket, config, { open, edit, close }) => [
  getNoteSection({
    label: (
      <HeaderLabel
        title="visibleForAll"
        len={ticket?.notes?.length || 0}
      />
    ),
    elements: ticket?.notes ? sortNotes(ticket?.notes) : [],
    type: 'ticket',
    emptyTitle: 'publicNote',
    emptyDescription: 'publicNoteDes',
    open,
    edit,
    close,
    ticket
  }),
  getNoteSection({
    label: (
      <HeaderLabel
        title="intern"
        len={ticket?.viewNotes?.length || 0}
      />
    ),
    elements: ticket?.viewNotes ? sortNotes(ticket?.viewNotes) : [],
    type: 'view',
    emptyTitle: 'internalNote',
    emptyDescription: 'internalNoteDes',
    open,
    edit,
    close,
    ticket
  }),
  ...(config.isManager
    ? [
        getNoteSection({
          label: (
            <HeaderLabel
              title="managers"
              len={ticket?.viewNotesManagers?.length || 0}
            />
          ),
          elements: ticket?.viewNotesManagers ? sortNotes(ticket?.viewNotesManagers) : [],
          type: 'managers',
          emptyTitle: 'managersNote',
          emptyDescription: 'managersNoteDes',
          open,
          edit,
          close,
          ticket
        })
      ]
    : [])
];

function getNoteSection({
  label,
  elements,
  type,
  emptyDescription,
  open,
  close,
  edit,
  ticket,
  emptyTitle
}) {
  return {
    label,
    content: !elements?.length ? (
      <EmptyNoteSection
        title={emptyTitle}
        description={emptyDescription}
        open={() => open(type)}
      />
    ) : (
      <NoteSection
        elements={elements}
        type={type}
        open={open}
        close={close}
        edit={edit}
        ticket={ticket}
      />
    )
  };
}

function NoteSection({ elements, type, open, close, edit, ticket }) {
  const k = useKeys();
  const { page } = useEntity();

  return (
    <>
      <Fab
        style={{
          margin: 0,
          position: page ? 'fixed' : 'absolute',
          right: 24,
          bottom: 16,
          zIndex: 999
        }}
        color="primary"
        onClick={() => open(type)}
      >
        <Add style={{ color: 'white' }} />
      </Fab>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: '0 1em'
        }}
      >
        {elements.map((n, i) => {
          return (
            <NoteItem
              key={k(i)}
              note={n}
              close={close}
              edit={edit}
              ticket={ticket}
            />
          );
        })}
      </Box>
    </>
  );
}

function NoteItem({ note, close, edit, ticket }) {
  const { t } = useTranslation();

  const userId = useAuth().user._id;

  const notify = useNotifications();
  const { dispatch, requestStatus } = useAsyncDispatch();

  const dispatchCallbacks = {
    onSuccess: () => [notify.success(t('deletedNote')), close()],
    onError: () => notify.error()
  };

  const first_name_or_last_name_known = !!note._user?.firstName || !!note._user?.lastName;
  const note_from_api = !!note?.fromApi;

  const author_note = note_from_api
    ? t('fromApi')
    : first_name_or_last_name_known
      ? note?._user?.firstName + ' ' + note?._user?.lastName
      : note?._user?.email;

  return (
    <>
      <Paper
        elevation={1}
        style={{
          padding: '0 1em',
          margin: '.8em 0',
          position: 'relative'
        }}
      >
        <BlurredProgress in={requestStatus === 'loading'} />
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '1em 0'
          }}
        >
          <Typography variant="body1">
            {/* TODO: Some notes don't have a user */}
            <strong>{!!note.bobdepannage ? 'Bob! Dépannage' : author_note}</strong>, le{' '}
            {dateToLocalFormat(new Date(note.created_at), 'PPPPp')}
          </Typography>
          <Box>
            {note._user?._id === userId && (
              <>
                <IconButton onClick={() => edit(note)}>
                  <FAIcon
                    collection="fal"
                    icon="pen-to-square"
                    size="small"
                  />
                </IconButton>
                <IconButton
                  onClick={() =>
                    dispatch(deleteNote, undefined, dispatchCallbacks, {
                      id: ticket._id,
                      noteIdWithSlash: '/' + note._id
                    })
                  }
                >
                  <FAIcon
                    collection="fal"
                    icon="trash-can"
                    size="small"
                  />
                </IconButton>
              </>
            )}
          </Box>
        </Box>

        <Typography
          style={{
            margin: '1em 0',
            whiteSpace: 'pre-line'
          }}
          variant="body1"
        >
          {note.note}
        </Typography>
      </Paper>
    </>
  );
}

function EmptyNoteSection(props) {
  const { open, description, title } = props;
  const { t } = useTranslation();
  return (
    <Empty
      icon="note"
      translations={{
        noResultLabel: title,
        noResultText: description
      }}
      cta={
        <Button
          onClick={open}
          variant="outlined"
          endIcon={<Add />}
        >
          {t('addNote')}
        </Button>
      }
    />
  );
}

function NoteEditor(props) {
  const { open, close, ticket, note, type } = props;
  const isCreate = !note?._id;
  const { t } = useTranslation();
  const notify = useNotifications();
  const { dispatch, requestStatus } = useAsyncDispatch();

  const dispatchCallbacks = {
    onSuccess: () => [notify.success(t('savedNote')), close()],
    onError: () => notify.error()
  };

  return (
    <Dialog
      open={open}
      onClose={close}
      fullWidth
      maxWidth={'sm'}
    >
      <BlurredProgress in={requestStatus === 'loading'} />
      <Form
        disabled={requestStatus === 'loading'}
        initialValues={{
          id: ticket._id,
          view: isCreate ? type : note?.view,
          note: note?.note || ''
        }}
        onSubmit={(data) => {
          dispatch(isCreate ? createNote : updateNote, data, dispatchCallbacks, {
            id: ticket._id,
            noteIdWithSlash: isCreate ? '' : '/' + note?._id
          });
        }}
      >
        <DialogTitle
          title={t('addNote')}
          onClose={close}
        />
        <DialogContent>
          <Field path="note">
            <TextField
              label={t('noteContent')}
              style={{ margin: '1em 0' }}
              variant="outlined"
              fullWidth
              multiline
              autoFocus
              rows={4}
            />
          </Field>
        </DialogContent>
        <DialogActions>
          <Submit>
            <Button
              endIcon={
                <FAIcon
                  collection="fad"
                  icon="save"
                />
              }
            >
              {t('save2')}
            </Button>
          </Submit>
        </DialogActions>
      </Form>
    </Dialog>
  );
}
