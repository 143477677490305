import parsePhoneNumber from 'libphonenumber-js';
import countries from 'constants/countries';

export default function formatPhoneNumber(phone) {
  if (!phone) return null;

  if (parsePhoneNumber(phone) === undefined) {
    return <></>;
  }

  const phoneNumber = parsePhoneNumber(phone);

  if (!phoneNumber.isValid()) {
    return null;
  }

  const formattedNumber = phoneNumber.format('INTERNATIONAL');

  const parseCountry = phoneNumber?.country;
  const flag = countries.find((e) => e.code === parseCountry)?.flag;

  return (
    <>
      {flag} {formattedNumber}
    </>
  );
}
