import { useEffect } from 'react';
import {
  AccordionActions,
  AccordionDetails,
  Box,
  Button,
  FormControlLabel,
  Grid,
  InputAdornment,
  Switch,
  TextField,
  Tooltip
} from '@material-ui/core';
import { createBudget, deleteBudget, getBudgets, updateBudget, updateBudgets } from 'store/budgetsSlice';
import useNotifications from 'hooks/useNotifications';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import AccordionForm from 'components/forms/AccordionForm';
import IconButton from 'components/IconButton';
import FAIcon from 'components/ui/FAIcon';
import { useTranslation } from 'react-i18next';
import useKeys from '@flowsn4ke/usekeys';
import useConfirm from 'hooks/useConfirm';
import { useDispatch, useSelector } from 'react-redux';
import { useConfiguration } from 'hooks/useConfiguration';
import { useAuth } from 'hooks/useAuth';

export const TYPOLOGY_OPTIONS = [
  {
    label: 'Capex',
    value: 'capex'
  },
  {
    label: 'Opex',
    value: 'opex'
  }
];

export default function BudgetsConfiguration({ section }) {
  const { t } = useTranslation();
  const notify = useNotifications();
  const { dispatch } = useAsyncDispatch();
  const k = useKeys();
  const k1 = useKeys();
  const confirm = useConfirm();

  const dispatchCallbacks = {
    onSuccess: () => notify.success(),
    onError: () => notify.error()
  };

  useEffect(() => {
    dispatch(getBudgets, { active_only: false }, {}, {});
  }, []);

  const budgets = useSelector((store) => store.budgets);
  const syncDispatch = useDispatch();
  const syncUpdateBudget = (budget) => syncDispatch(updateBudget({ budget }));

  const auth = useAuth();
  const company = auth.interface._company;

  return (
    <AccordionForm
      defaultClose
      section={section}
      // onSubmit={(budgets) => console.log(budgets)}
      initialValues={{ budgets }}
    >
      <AccordionDetails>
        <Grid
          container
          spacing={2}
        >
          {Boolean(budgets?.length) &&
            budgets?.map((budget, idx) => {
              return (
                <Grid
                  key={budget._id}
                  item
                  xs={12}
                  className="p-1.5"
                >
                  <Grid
                    container
                    className="w-full flex justify-between"
                  >
                    <Grid
                      item
                      xs={1}
                      className="flex justify-center items-center"
                    >
                      <div className="w-6 h-6 flex-none">
                        <input
                          type="color"
                          value={budget.color}
                          onChange={(event) => syncUpdateBudget({ ...budget, color: event.target.value })}
                          className="color-input cursor-pointer"
                        />
                      </div>
                    </Grid>

                    <Grid className="py-4 lg:py-0">
                      <TextField
                        value={budget.typology}
                        onChange={(e) => syncUpdateBudget({ ...budget, typology: e.target.value })}
                        select
                        variant="outlined"
                        label={t('typologyBudget')}
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ native: true }}
                      >
                        <option value={null}></option>
                        {TYPOLOGY_OPTIONS.map((opt, opt_idx) => {
                          return (
                            <option
                              key={k1(opt_idx)}
                              value={opt.value}
                            >
                              {opt.label}
                            </option>
                          );
                        })}
                      </TextField>
                    </Grid>

                    <Grid
                      xs={12}
                      md={4}
                      className="py-4 lg:py-0"
                    >
                      <TextField
                        value={budget.title}
                        onChange={(e) => syncUpdateBudget({ ...budget, title: e.target.value })}
                        variant="outlined"
                        fullWidth
                        label={t('budgetName')}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={4}
                      md={1}
                      className="pr-2 lg:pr-0"
                    >
                      <TextField
                        value={budget.year}
                        onChange={(e) => syncUpdateBudget({ ...budget, year: e.target.value })}
                        select
                        variant="outlined"
                        fullWidth
                        label={t('budgetYear')}
                        SelectProps={{ native: true }}
                      >
                        {[...Array(5)].map((_, idx) => {
                          const year = new Date().getFullYear() + idx - 1;
                          return (
                            <option
                              key={`${k(idx)}-${year}`}
                              value={year}
                            >
                              {year}
                            </option>
                          );
                        })}
                      </TextField>
                    </Grid>

                    <Grid
                      item
                      xs={4}
                      md={2}
                      className="pr-2 lg:pr-0"
                    >
                      <TextField
                        value={budget.total}
                        onChange={(e) => syncUpdateBudget({ ...budget, total: e.target.value })}
                        number
                        variant="outlined"
                        fullWidth
                        label={t('budgetAmount')}
                        InputProps={{
                          endAdornment: <InputAdornment>{ company?.currency?.symbol ? company?.currency?.symbol : '€'}</InputAdornment>
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={1}
                      md={1}
                      className="flex items-center justify-center"
                    >
                      <Tooltip
                        placement="top"
                        title={budget.is_active ? t('desactivateBudget') : t('activateBudget')}
                      >
                        <FormControlLabel
                          control={
                            <Switch
                              onChange={(e) => syncUpdateBudget({ ...budget, is_active: e.target.checked })}
                              checked={budget.is_active}
                            />
                          }
                        />
                      </Tooltip>
                    </Grid>

                    <Grid
                      item
                      xs={1}
                      md={1}
                      className="flex items-center"
                    >
                      <IconButton
                        onClick={() =>
                          confirm({
                            onConfirm: () =>
                              dispatch(deleteBudget, undefined, undefined, {
                                budget_id: budget._id
                              }),
                            title: 'deleteBudgetMessageConfirmation'
                          })
                        }
                      >
                        <FAIcon
                          collection="fal"
                          icon="trash-can"
                        />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}

          <Grid
            item
            xs={12}
          >
            <Box style={{ width: 'full', textAlign: 'center', margin: '1em 0' }}>
              <Button
                endIcon={
                  <FAIcon
                    collection="fal"
                    icon="circle-plus"
                  />
                }
                variant="contained"
                onClick={() => dispatch(createBudget)}
              >
                {t('addBudget')}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </AccordionDetails>

      <AccordionActions>
        <Button
          onClick={() => dispatch(updateBudgets, { budgets }, dispatchCallbacks)}
          size="small"
          color="primary"
        >
          {t('save')}
        </Button>
      </AccordionActions>
    </AccordionForm>
  );
}
