import useKeys from '@flowsn4ke/usekeys';
import {
  Button,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Menu,
  MenuItem,
  TextField
} from '@material-ui/core';
import { ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons';
import DateField from 'components/inputs/DateField';
import countries from 'constants/countries';
import { Field, Form, Reset, Submit, useField, useFieldObserver } from 'frmx';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import useNotifications from 'hooks/useNotifications';
import { useRef, useState } from 'react';
import Flag from 'react-flagkit';
import { useTranslation } from 'react-i18next';
import { add_pricing, update_pricing } from 'store/metersSlice';

export default function PricingForm({ meter_id, on_close, pricing_to_edit, is_editing = false }) {
  const { t } = useTranslation();

  const { dispatch, requestStatus } = useAsyncDispatch();
  const notify = useNotifications();

  const dispatchCallbacks = {
    onSuccess: () =>
      is_editing ? notify.success(t('pricingUpdated')) : notify.success(t('pricingAdded')),
    onError: () => notify.error()
  };

  const handle_submit = (data) => {
    dispatch(is_editing ? update_pricing : add_pricing, data, dispatchCallbacks, {
      id: meter_id,
      ...(is_editing && { pricing_id: pricing_to_edit._id })
    }).then(() => on_close());
  };

  return (
    <Form
      initialValues={{
        price: pricing_to_edit?.price || 0,
        currency: pricing_to_edit?.currency || 'EUR',
        country_code: pricing_to_edit?.country_code || 'FR',
        start: pricing_to_edit?.start ? new Date(pricing_to_edit.start) : new Date(),
        end: pricing_to_edit?.end ? new Date(pricing_to_edit.end) : null,
        ...(!is_editing && { is_end_of_previous_pricing: true })
      }}
      onSubmit={(data) => handle_submit(data)}
    >
      <label className="my-2 block text-sm font-medium text-gray-700">{t('price')}</label>
      <Field
        path="price"
        type="number"
      >
        <TextField
          className="w-full"
          variant="outlined"
          type="number"
          InputProps={{
            startAdornment: <SelectCurrency path="currency" />,
            endAdornment: <SymbolCurrency path="currency" />
          }}
          inputProps={{
            min: 0
          }}
        />
      </Field>
      <div className="mt-4">
        <label className="mb-2 block text-sm font-medium text-gray-700">{t('start')}</label>
        <DateField
          clearable={false}
          path="start"
        />
      </div>
      {!is_editing && (
        <div className="mt-4 flex items-center">
          <FormControlLabel
            control={
              <Field
                path="is_end_of_previous_pricing"
                type="checkbox"
              >
                <Checkbox />
              </Field>
            }
            label={t('thisDateIsEndOfPreviousPricing')}
          />
        </div>
      )}
      <div className="mt-4">
        <label className="mb-2 block text-sm font-medium text-gray-700">{t('end')}</label>
        <DateField
          clearable={false}
          path="end"
        />
      </div>
      <div className="flex justify-end items-center mt-3">
        <Reset onClick={on_close}>
          <Button
            variant="contained"
            color="secondary"
            size="small"
          >
            {t('cancel')}
          </Button>
        </Reset>
        <div className="ml-2">
          <Submit disabled={requestStatus === 'loading'}>
            <Button
              variant="contained"
              color="primary"
              size="small"
            >
              {is_editing ? t('edit') : t('add')}
            </Button>
          </Submit>
        </div>
      </div>
    </Form>
  );
}

function SelectCurrency({ path }) {
  const k = useKeys();
  const { setValue: set_currency } = useField(path);
  const { value: country_code, setValue: set_country_code } = useField('country_code');
  const [anchor_el, set_anchor_el] = useState(null);

  const initial_country = countries.find((country) => country_code === country.code);
  const [country, set_country] = useState(initial_country); // DEFAULT COUNTRY

  const closeMenu = useRef(() => set_anchor_el(null));

  return (
    <>
      <InputAdornment position="start">
        <Button onClick={(e) => set_anchor_el(e.currentTarget)}>
          <Flag country={country.code} />
          <ArrowDropDownIcon />
        </Button>
      </InputAdornment>
      <Menu
        anchorEl={anchor_el}
        keepMounted
        open={!!anchor_el}
        onClose={closeMenu.current}
      >
        {countries.map((country, country_idx) => (
          <MenuItem
            key={k(country_idx)}
            onClick={() => {
              set_country(country);
              set_country_code(country.code);
              set_currency(country.currency);
              closeMenu.current();
            }}
          >
            {country.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

function SymbolCurrency({ path }) {
  const currency = useFieldObserver(path);
  const country = countries.find((c) => c.currency === currency);
  return <InputAdornment position="end">{country.currency_symbol}</InputAdornment>;
}
