import EntityTabPicker from 'components/inputs/EntityTabPicker';
import User from 'entities/User/User';
import { updateElement } from 'store/ticketsSlice';

export default function UsersTab({ element, ...rest }) {
  return (
    <EntityTabPicker
      Entity={User}
      defaultFilters={{ locations: element?._locations }}
      initialValues={{ _contacts: element?._contacts || [] }}
      fieldPath="_contacts"
      elementId={element._id}
      action={updateElement}
      {...rest}
    />
  );
}
