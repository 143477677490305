import { Slider, Typography } from '@material-ui/core';
import { Field } from 'frmx';
import { useTranslation } from 'react-i18next';
import FieldBase from './FieldBase';

export default function EmptyField() {
  const { t } = useTranslation();
  return (
    <FieldBase>
      <Typography gutterBottom>{t('numberOfLInes')}</Typography>
      <Field
        path="extra.lines"
        type="range"
        getValueFromArgs={(args) => args[1]}
      >
        <Slider
          min={1}
          max={10}
          step={1}
          marks
          valueLabelDisplay="auto"
        />
      </Field>
    </FieldBase>
  );
}
