import {
  getElement,
  getElements,
  createElement,
  updateElement,
  flushElements,
  deleteElement,
  restoreElement
} from 'store/supplierSlice';

import SupplierMenus from './SupplierMenus';
import SupplierPreview from './SupplierPreview';
import SupplierSkeleton from './SupplierSkeleton';
import SupplierView from './SupplierView';
import SupplierTabs from './SupplierTabs';
import SupplierFilters, { formatFilter } from './SupplierFilters';
import SupplierFormValues, { SupplierValidation, formatForm } from './SupplierFormValues';
import SupplierTranslations from './SupplierTranslations';
import SuppplierExport from './SuppplierExport';
import SupplierForm from './SupplierForm';
import SupplierSorts from './SupplierSorts';
import SupplierTable from './SupplierTable';

import EntityWrapper from 'entities/EntityWrapper';

import { useMemo } from 'react';

export default function Supplier({ hiddenFilters, facturation, ...rest }) {
  const _hiddenFilters = useMemo(() => {
    return {
      ...hiddenFilters,
      ...(facturation ? { section: { facturation: true, warehouse: false, location: false } } : {})
    };
  }, [facturation, hiddenFilters]);

  return (
    <EntityWrapper
      entity="suppliers"
      entityFieldsSlice="supplier"
      facturation={facturation}
      skeletonComponent={SupplierSkeleton}
      previewComponent={SupplierPreview}
      viewComponent={SupplierView}
      formComponent={SupplierForm}
      formatForm={formatForm}
      formatFilter={formatFilter}
      getFilters={SupplierFilters}
      getInitialValues={SupplierFormValues}
      getFormValidation={SupplierValidation}
      getTabs={SupplierTabs}
      getTables={SupplierTable}
      getMenus={SupplierMenus}
      getExportColumns={SuppplierExport}
      getTranslations={SupplierTranslations}
      getSorts={SupplierSorts}
      getElement={getElement}
      getElements={getElements}
      createElement={createElement}
      updateElement={updateElement}
      flushElements={flushElements}
      deleteElement={deleteElement}
      restoreElement={restoreElement}
      hiddenFilters={_hiddenFilters}
      {...rest}
    />
  );
}
