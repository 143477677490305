import { Avatar as MuiAvatar, Box, ButtonBase, Fade } from '@material-ui/core';
import FAIcon from 'components/ui/FAIcon';

import { useState } from 'react';

import useStyles from './Avatar.styles';

export default function Avatar(props) {
  const {
    entity,
    size = 'medium',
    shadow = true,
    border = false,
    round = false,
    button = false,
    className,
    white,
    isUser,
    onClick,
    isLoading,
    isUpdate,
    ...rest
  } = props;

  const classes = useStyles({
    white,
    hasAvatar: !!entity?.avatar?.length,
    avatar: entity?.avatar,
    size,
    round,
    border,
    shadow,
    isUser
  });
  const [isHovered, setIsHovered] = useState(false);

  const _Avatar = (
    <Box
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      {...rest}
    >
      {isUpdate && (
        <Fade in={isHovered || isLoading}>
          <Box className={classes.isUpdateBox}>
            <Box className={classes.isUpdateScale}>
              {isLoading && (
                <FAIcon
                  size="large"
                  collection={isUpdate ? 'fas' : 'fad'}
                  icon="spinner"
                  className={isLoading ? 'fa-spin' : classes[isUpdate ? 'isUpdate' : null]}
                />
              )}
            </Box>
          </Box>
        </Fade>
      )}
      <MuiAvatar
        imgProps={{ className: classes.image }}
        className={classes.avatar}
      >
        {entity?.name ? entity.name[0] : isUser ? null : ' '}
      </MuiAvatar>
    </Box>
  );

  return (
    <Box className={className}>
      {(() => {
        if (button) {
          return <ButtonBase className={classes.avatarButton}>{_Avatar}</ButtonBase>;
        } else {
          return _Avatar;
        }
      })()}
    </Box>
  );
}
