import { AppBar, Slide, Toolbar, IconButton, Box } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectAuth } from 'store/authSlice';
import { useIsBelowMd, useIsBelowSm } from 'hooks/useMQ';

import { SIDEBAR_IS_LOCKED } from 'constants/localStorage';
import Avatar from 'components/Avatar';
import InterfacePopover from 'layouts/dashboard/InterfacePopover';
import useStyles from './TopBar.styles';
import logo from 'images/logo.svg';

import { STARTUP, STARTUP_MOBILE } from 'config/menus';

export default function TopBar({ setSidebarIsOpen, sidebarIsLocked, setSidebarIsLocked }) {
  const auth = useSelector(selectAuth);
  const isBelowSm = useIsBelowSm();
  const isBelowMd = useIsBelowMd();

  const [profilePopoverAnchor, setProfilePopoverAnchor] = useState(null);

  const openProfilePopover = (e) => setProfilePopoverAnchor(e.currentTarget);
  const closeProfilePopover = (e) => setProfilePopoverAnchor(null);

  const classes = useStyles();

  return (
    <>
      {auth.user && (
        <>
          <InterfacePopover
            id="profile-popover"
            anchorEl={profilePopoverAnchor}
            open={!!profilePopoverAnchor}
            onOpen={openProfilePopover}
            onClose={closeProfilePopover}
          />
        </>
      )}

      <AppBar
        position="fixed"
        elevation={2}
        className={classes.AppBar}
        color="secondary"
      >
        {auth.user && (
          <Slide
            direction={'down'}
            in
          >
            <Toolbar className={classes.toolbar}>
              <IconButton
                edge="start"
                onClick={(e) => {
                  if (sidebarIsLocked) {
                    localStorage.removeItem(SIDEBAR_IS_LOCKED);
                    setSidebarIsLocked(false);
                    setSidebarIsOpen(false);
                  } else {
                    if (!isBelowSm) {
                      localStorage.setItem(SIDEBAR_IS_LOCKED, 1);
                    }
                    setSidebarIsLocked(true);
                    setSidebarIsOpen(true);
                  }
                }}
                aria-label="taskbar"
                className={classes.drawerButton}
              >
                <MenuIcon className={classes.drawerButtonIcon} />
              </IconButton>

              <Box className={classes.left}>
                <Link
                  className={classes.logoLink}
                  to={(isBelowMd ? STARTUP_MOBILE : STARTUP)[auth.interface.type].url}
                >
                  <img
                    alt="bob! desk logo"
                    src={logo}
                    className={classes.logo}
                  />
                </Link>
              </Box>

              <Box
                aria-label="user account"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                onClick={openProfilePopover}
                className={classes.interfaceButton}
              >
                <Avatar
                  size={isBelowSm ? 'small' : 'medium'}
                  border
                  white
                  button
                  entity={auth.interface._company}
                />
              </Box>
            </Toolbar>
          </Slide>
        )}
      </AppBar>
      <div className={classes.toolbarMargin} />
    </>
  );
}
