import SuspenseLoader from 'components/SuspenseLoader';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
// import { MarkerClusterer } from "react-google-maps/lib/components/addons/MarkerClusterer"

// TODO: Switch to @react-google-maps/api ?

const MapGoogle = withGoogleMap(({ address, containerElement, mapElement }) => {
  return (
    <GoogleMap
      options={{ mapTypeControl: false }}
      defaultZoom={16}
      center={{ lat: address.lat, lng: address.lng }}
    >
      <Marker position={{ lat: address.lat, lng: address.lng }} />
    </GoogleMap>
  );
});

export default function LocationMap({ address }) {
  return (
    <MapGoogle
      loadingElement={<SuspenseLoader />}
      containerElement={
        <div
          className="gmap"
          style={{ width: '100%', height: '100%' }}
        />
      }
      mapElement={<div style={{ width: '100%', height: '100%' }} />}
      address={address}
    />
  );
}
