import { useField } from 'frmx';
import { DateTimePicker } from '@material-ui/pickers';

export default function DateTimeField({ path, errorHelperText = '', ...rest }) {
  const fldx = useField(path);

  return (
    <DateTimePicker
      value={fldx?.value ? new Date(fldx?.value) : null}
      onChange={(d) => fldx?.setValue(d)}
      inputVariant="outlined"
      invalidLabel=""
      ampm={false}
      clearable
      invalidDateMessage={<></>}
      format="PPPp"
      error={!!fldx?.error}
      helperText={!!fldx?.error ? errorHelperText : null}
      {...rest}
    />
  );
}
