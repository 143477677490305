import { isArray } from 'lodash-es';
import { isEmail } from 'validator';
import parsePhoneNumber from 'libphonenumber-js';

const TicketFormValues = (config, payload, user, isCreate, _interface) => {
  return {
    ...(!!_interface.isPublic
      ? {
          isPublic: true,
          creator_phone: '',
          creator_email: ''
        }
      : {
          _managers: config.isManager && isCreate ? [user] : []
        }),
    title: '',
    description: '',
    view: '',
    urgency: '1',
    draft: false,
    _client: _interface.publicClient || null,
    visit_date: payload?.visit_date || null,
    duration: payload?.duration || config.default_intervention_date_duration,
    type: '',
    //Hard to read..
    _locations: _interface._company._configuration.monolocation
      ? _interface._company._configuration._monolocation
        ? [{ _id: _interface._company._configuration._monolocation }]
        : []
      : _interface?._locations?.length === 1 && isCreate
      ? _interface?._locations
      : config?.ticket_location_unique
      ? []
      : [],
    _equipments: [],
    _contacts: config.isUser && _interface._contact && isCreate ? [_interface._contact._id] : [],
    _jobs: config?.default_job ? [config?.default_job] : [],
    category: null,
    ...(config.feature.seuil
      ? {
          seuil_enable: config?.default_seuil_enable || false,
          seuil: config?.default_seuil_enable ? config?.default_seuil : 0
        }
      : {}),
    ...(config.isTechnician && isCreate ? { _summons: [_interface._collaborator] } : {}),
    ...(config.isBlachere ? { domaine: null } : {})
  };
};

const isTruish = (v) => !!v;
const hasLength = (v) => v?.length > 0;
const returnTrue = () => true;
const isDomaineValid = (value, data) => (data.category === 'production' ? isTruish(value) : true);
const isValidPhoneNumber = (val) => {
  const n = parsePhoneNumber(val);
  return !!n && n?.isValid();
};

export default TicketFormValues;

export const TicketValidation = (config, auth, element) => {
  const is_preventif_ticket = Boolean(element?.period);

  return {
    ...(!!auth.interface.isPublic
      ? {
          creator: hasLength,
          creator_email: isEmail,
          creator_phone: !!config?.ticket_public?.creator_phone_enabled ? isValidPhoneNumber : returnTrue
        }
      : {}),
    urgency: config?.ticket_criticity_mandatory ? isTruish : returnTrue,
    title: config?.ticket_title_mandatory ? hasLength : returnTrue,
    description: config?.ticket_description_mandatory ? hasLength : returnTrue,
    _locations:
      config?.ticket_location_mandatory && config?.ticket_location_unique
        ? (val) => (isArray(val) ? !!val?.length : !!val)
        : config?.ticket_location_mandatory && !config.monolocation
        ? hasLength
        : returnTrue,
    _jobs: config?.job_mandatory ? hasLength : returnTrue,
    _equipments: config.isBlachere && !config.isManager ? (val) => (isArray(val) ? !!val?.length : !!val) : returnTrue,
    domaine: config.isBlachere && !config.isManager ? isDomaineValid : returnTrue,
    category:
      (config.isBlachere && !config.isManager) || (config.ticket_domain_mandatory && !is_preventif_ticket)
        ? isTruish
        : returnTrue
  };
};

export const formatForm = (dataFromForm, isCreate, attachments, _interface, { customFields }) => {
  if (customFields?.length > 0) {
    const selectedDomainId = dataFromForm.category;

    const selectedDomainFieldsIds = customFields.reduce((acc, curr) => {
      const fieldsIds = curr?.fields
        .filter((field) => (!field.domain_id ? field : field.domain_id === selectedDomainId ? field : null))
        .map((field) => field._id);

      return [...acc, ...fieldsIds];
    }, []);

    dataFromForm.fields = dataFromForm.fields?.filter((field) => {
      const customFieldSelected = selectedDomainFieldsIds.includes(field._field);
      return customFieldSelected ? field : null;
    });
  }

  return {
    ...dataFromForm,
    attachments
  };
};

export const injectFormValues = (initialValues, ticket) => {
  if (ticket.contractParent) initialValues._client = ticket.contractParent._company;

  if (ticket?.contract?.seuil) initialValues.seuil = ticket.contract.seuil;

  if (ticket?.contract?._managers) initialValues._managers = ticket?.contract?._managers;

  if (ticket?.contract?.seuil_enable) initialValues.seuil_enable = ticket.contract.seuil_enable;

  if (ticket.category) initialValues.category = ticket.category;

  return initialValues;
};
