import { palettes } from 'config/palettes';

export const pickPalette = (palette) => ({
  ...palettes[palette],
  error: {
    light: red[300],
    main: red[500],
    dark: red[700]
  },
  warning: {
    light: orange[300],
    main: orange[500],
    dark: orange[700]
  },
  info: {
    light: blue[300],
    main: blue[500],
    dark: blue[700]
  },
  success: {
    light: green[300],
    main: green[500],
    dark: green[700]
  },
  common: {
    black: '#000000',
    white: '#ffffff',
    neutral: '#E4E7EB',
    muted: '#9EA0A4'
  },
  text: {
    primary: '#4f5b62',
    secondary: '#66788A',
    disabled: '#A6B1BB'
  },
  background: {
    default: '#f1f3f4',
    dark: '#172B4D',
    paper: '#ffffff'
  },
  border: '#DFE3E8',
  divider: '#DFE3E8'
});

const red = {
  50: '#ffebee',
  100: '#ffcdd2',
  200: '#ef9a9a',
  300: '#e57373',
  400: '#ef5350',
  500: '#f44336',
  600: '#e53935',
  700: '#d32f2f',
  800: '#c62828',
  900: '#b71c1c',
  A100: '#ff8a80',
  A200: '#ff5252',
  A400: '#ff1744',
  A700: '#d50000'
};

const orange = {
  50: '#fff3e0',
  100: '#ffe0b2',
  200: '#ffcc80',
  300: '#ffb74d',
  400: '#ffa726',
  500: '#ff9800',
  600: '#fb8c00',
  700: '#f57c00',
  800: '#ef6c00',
  900: '#e65100',
  A100: '#ffd180',
  A200: '#ffab40',
  A400: '#ff9100',
  A700: '#ff6d00'
};

const green = {
  50: '#e8f5e9',
  100: '#c8e6c9',
  200: '#a5d6a7',
  300: '#81c784',
  400: '#66bb6a',
  500: '#4caf50',
  600: '#43a047',
  700: '#388e3c',
  800: '#2e7d32',
  900: '#1b5e20',
  A100: '#b9f6ca',
  A200: '#69f0ae',
  A400: '#00e676',
  A700: '#00c853'
};
const blue = {
  50: '#e3f2fd',
  100: '#bbdefb',
  200: '#90caf9',
  300: '#64b5f6',
  400: '#42a5f5',
  500: '#2196f3',
  600: '#1e88e5',
  700: '#1976d2',
  800: '#1565c0',
  900: '#0d47a1',
  A100: '#82b1ff',
  A200: '#448aff',
  A400: '#2979ff',
  A700: '#2962ff'
};
