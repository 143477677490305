import { useRef, useState } from 'react';
import useOutsideClick from 'hooks/useOutsideClick';
import useKeys from '@flowsn4ke/usekeys';
import classNames from 'utils/classNames';
import FAIcon from 'components/ui/FAIcon';
import { useMemo } from 'react';

export default function VersionSelector({ revisions, disabled, document, setDocument, isLoading }) {
  const [dropDownIsOpen, setDropDownIsOpen] = useState(false);
  const dropDownRef = useRef();
  const buttonRef = useRef();

  const selectedRevisionIdx = useMemo(
    () =>
      Array.isArray(revisions)
        ? revisions.findIndex((revision) => revision._id === document._id)
        : document.version,
    [document, revisions]
  );

  useOutsideClick(dropDownRef, () => setDropDownIsOpen(false));
  const k = useKeys();

  return (
    <div className="isolate relative z-10">
      <button
        ref={buttonRef}
        onMouseDown={(event) => event.stopPropagation()}
        onClick={() => setDropDownIsOpen((open) => (open ? false : true))}
        disabled={disabled || isLoading || revisions?.length < 2}
        className={classNames(
          'border border-gray-300 disabled:cursor-default disabled:opacity-75 rounded-md py-2 px-3 flex justify-between items-center text-base',
          isLoading ? 'animate-pulse' : ''
        )}
      >
        v{parseFloat(revisions?.at(selectedRevisionIdx)?.version).toFixed(1)}
        <FAIcon
          collection="fal"
          icon="sort"
          className="text-gray-400 pointer-events-none"
        />
      </button>
      <div className="relative w-full">
        <div
          ref={dropDownRef}
          className={classNames(
            dropDownIsOpen ? '' : 'hidden',
            'z-10 p-1 top-1 left-0 rounded border bg-white border-gray-300 w-full h-auto absolute flex flex-col text-center space-y-1'
          )}
        >
          {revisions?.map((revision, i) => (
            <button
              onClick={(event) => {
                setDocument(revision);
                setDropDownIsOpen(false);
              }}
              key={k(i)}
              className="flex items-center text-base font-medium text-sky-900 border-gray-300"
            >
              <div className="w-8 flex items-center justify-center text-center">
                <FAIcon
                  collection="fal"
                  icon="check"
                  className={classNames(
                    'mx-0.5',
                    revision._id === document._id ? 'text-sky-500' : 'hidden'
                  )}
                />
              </div>
              v{parseFloat(revision.version).toFixed(1)}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
