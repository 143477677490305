import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography
} from '@material-ui/core';
import DialogTitle from 'components/dialogs/DialogTitle';
import { useTranslation } from 'react-i18next';

export const LiftTheReserveDialog = ({ open, onClose, reserve, action }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      onClose={onClose}
      disableEnforceFocus
      open={open}
    >
      <DialogTitle
        title={t('reserveLift')}
        onClose={onClose}
        className='text-base'

      />
      <DialogContent>
        <p className='mt-3 text-sm'>{t('wouldYouAlsoReserveTicket', { reserveNb: reserve.number })}</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => [action(false), onClose()]}>{t('no')}</Button>
        <Button onClick={() => [action(true), onClose()]}>{t('yes')}</Button>
      </DialogActions>
    </Dialog>
  );
};
