export const CONTRACT_DRAFT = 'draft';
export const CONTRACT_OPENED = 'opened';
export const CONTRACT_WAITING = 'waiting';
export const CONTRACT_PROPOSED = 'proposed';
export const CONTRACT_TOREPLAN = 'toreplan';
export const CONTRACT_TOPLAN = 'toplan';
export const CONTRACT_WAITINGPLAN = 'waitingplan';
export const CONTRACT_WAITING_CONTRACTOR = 'waiting_contractor';
export const CONTRACT_ASSIGNED = 'assigned';
export const CONTRACT_VISIT = 'visit';
export const CONTRACT_VISIT_DEVIS = 'visit_devis';
export const CONTRACT_VALIDATION = 'validation';
export const CONTRACT_VALIDATED = 'validated';
export const CONTRACT_DROPPED = 'dropped';
export const CONTRACT_INTERVENTION = 'intervention';
export const CONTRACT_INTERVENTION_FINISH = 'intervention_finish';
export const CONTRACT_FINISHED = 'finished';
export const CONTRACT_CLOSED = 'closed';
export const CONTRACT_CANCELED = 'canceled';
export const CONTRACT_DECLINED = 'declined';
export const CONTRACT_CASHEDBY = 'cashedby';
export const CONTRACT_REMOVED = 'removed';

export const PROPOSITION_CREATED = 'created';
export const PROPOSITION_DECLINED = 'declined';
export const PROPOSITION_ACCEPTED = 'accepted';
export const PROPOSITION_EXPIRED = 'expired';

export const contractStates = {
  [PROPOSITION_CREATED]: {
    label: 'waiting',
    color: '#8e44ad'
  },
  [PROPOSITION_DECLINED]: {
    label: 'declined',
    color: '#e63d53'
  },
  [PROPOSITION_ACCEPTED]: {
    label: 'accepted',
    color: '#31b55a'
  },
  [PROPOSITION_EXPIRED]: {
    label: 'expired',
    color: '#ef7a1f'
  },
  [CONTRACT_DRAFT]: {
    label: 'draft',
    color: 'black'
  },
  [CONTRACT_OPENED]: {
    label: 'waitingForValidation',
    color: '#7f8c8d'
  },
  [CONTRACT_WAITING]: {
    label: 'waitingForAssignation',
    color: '#2c3e50'
  },
  [CONTRACT_PROPOSED]: {
    label: 'sentProposition',
    color: '#827696'
  },
  [CONTRACT_TOREPLAN]: {
    label: 'toBeReplaned',
    color: '#ce4413'
  },
  [CONTRACT_TOPLAN]: {
    label: 'toPlan',
    color: '#ee754a'
  },
  [CONTRACT_WAITINGPLAN]: {
    label: 'waitingToBePlaned',
    color: '#7f8c8d'
  },
  [CONTRACT_WAITING_CONTRACTOR]: {
    label: 'toBePlanedOrAssigned',
    color: '#2c3e50'
  },
  [CONTRACT_ASSIGNED]: {
    label: 'affectedIntervener',
    color: '#ee754a'
  },
  [CONTRACT_VISIT]: {
    label: 'plannedVisit',
    color: '#1fadda'
  },
  [CONTRACT_VISIT_DEVIS]: {
    label: 'waitingForReturnVisit',
    color: '#2288a8'
  },
  [CONTRACT_VALIDATION]: {
    label: 'quoteWaitingToBeValidated',
    color: '#ef7a1f'
  },
  [CONTRACT_VALIDATED]: {
    label: 'acceptedQuote',
    color: '#2ecc71'
  },
  [CONTRACT_DROPPED]: {
    label: 'quoteRejected',
    color: '#e74c3c'
  },
  [CONTRACT_INTERVENTION]: {
    label: 'rejectedIntervention',
    color: '#16a085'
  },
  [CONTRACT_INTERVENTION_FINISH]: {
    label: 'waitingForReturnIntervention',
    color: '#399472'
  },
  [CONTRACT_FINISHED]: {
    label: 'interventionDone',
    color: '#31b55a'
  },
  [CONTRACT_CLOSED]: {
    label: 'ticketClosed',
    color: '#2c3e50'
  },
  [CONTRACT_CANCELED]: {
    label: 'ticketCanceled',
    color: '#e63d53'
  },
  [CONTRACT_DECLINED]: {
    label: 'rejectedTicket',
    color: '#e74c3c'
  },
  [CONTRACT_CASHEDBY]: {
    label: 'waitingForPayloadAffectation',
    color: '#1c3962'
  },
  [CONTRACT_REMOVED]: {
    label: 'deletedTicket',
    color: '#c0392b'
  }
};

export const ticket_states = Object.keys(contractStates).map((state) => ({
  label: contractStates[state].label,
  _id: contractStates[state].label,
  color: contractStates[state].color
}));
