import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  success: {
    color: theme.palette.success.main
  },
  listEmpty: {
    fontWeight: 500,
    textAlign: 'center',
    padding: '24px 0px'
  }
}));
