const fieldSectionsEndpoints = [
  'ticket',
  'location',
  'equipment',
  'collaborator',
  'contractor',
  'contact',
  'preventif',
  'client',
  'interventionReport',
  'manager',
  'contract',
  'lease',
  'guarantee'
];

export default fieldSectionsEndpoints;
