import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faGlobe,
  faLayerMinus,
  faTrash,
  faShapes,
  faUserSecret,
  faFileSearch,
  faIdCard,
  faFiles,
  faBoxTaped,
  faPenField,
  faBarcode,
  faPercent,
  faChartLine,
  faQuoteLeft,
  faMailbox,
  faUserCheck,
  faCircle,
  faHardHat,
  faBookSpells,
  faCog,
  faToolbox,
  faSirenOn,
  faEngineWarning,
  faCalendarDay,
  faCarrot,
  faFileInvoice,
  faAsterisk,
  faCogs,
  faRocket,
  faKey,
  faIdBadge,
  faFingerprint,
  faBoxBallot,
  faCalendar,
  faMapMarkerAlt,
  faMapMarker,
  faUserTie,
  faChartPie,
  faUserHardHat,
  faUser,
  faChessClockAlt,
  faEuroSign,
  faDollarSign,
  faYenSign,
  faSterlingSign,
  faFrancSign,
  faChfSign,
  faCalculator,
  faLifeRing,
  faHouseBuilding,
  faInfoCircle,
  faShieldCheck,
  faHeart,
  faPhoneRotary,
  faPhone,
  faHeartbeat,
  faWrenchSimple,
  faUserHeadset,
  faBellOn,
  faAt,
  faSitemap,
  faExclamationCircle,
  faPen,
  faLink,
  faAdd,
  faCloudUpload,
  faCloudDownload,
  faCalendarWeek,
  faLayerGroup,
  faEnvelope,
  faAddressCard,
  faFileChartPie,
  faHeading,
  faFileAlt,
  faFile,
  faLineHeight,
  faParagraph,
  faSortNumericDown,
  faCheckSquare,
  faList,
  faEye,
  faFileImage,
  faExclamationSquare,
  faStar,
  faNewspaper,
  faText,
  faTypewriter,
  faListOl,
  faCheckCircle,
  faMinusCircle,
  faBolt,
  faInfinity,
  faInventory,
  faFilePlus,
  faThumbsUp,
  faPencil,
  faBells,
  faThumbsDown,
  faThunderstorm,
  faMoneyCheckEdit,
  faBan,
  faWindowClose,
  faCalendarEdit,
  faCalendarPlus,
  faTrashAlt,
  faPaperclip,
  faClipboardCheck,
  faArchive,
  faRecycle,
  faHeartRate,
  faAlarmExclamation,
  faAlarmPlus,
  faWrench,
  faPaperPlane,
  faClock,
  faCircleCheck,
  faTrashCan,
  faArrowUpRightFromSquare,
  faPenToSquare,
  faCheck,
  faCheckDouble,
  faHourglassStart,
  faCirclePlus,
  faCircleMinus,
  faFloppyDisk,
  faFileContract,
  faHouse,
  faUnlock,
  faTags,
  faTasks,
  faSquareCheck,
  faDistributeSpacingVertical,
  faBallotCheck,
  faArrowUp19,
  faListUl,
  faTriangleExclamation,
  faBoxesStacked,
  faBullseyeArrow,
  faBox,
  faClipboardList,
  faBoxCircleCheck,
  faWarehouse,
  faTruck,
  faParachuteBox,
  faTimes,
  faBoxCheck,
  faLock,
  faLockOpen,
  faComment,
  faSend,
  faHistory,
  faClockRotateLeft,
  faForwardFast,
  faScrewdriverWrench,
  faMessageLines,
  faToggleOn,
  faSort,
  faSquare,
  faCommentAlt,
  faMessage,
  faMoneyBillTransfer,
  faWallet,
  faMeter,
  faChartMixed,
  faBookOpen,
  faXmark,
  faRuler,
  faCashRegister,
  faFileInvoiceDollar
} from '@fortawesome/pro-light-svg-icons';

library.add(
  faClipboardList,
  faBoxCircleCheck,
  faBox,
  faTags,
  faBoxesStacked,
  faBullseyeArrow,
  faTriangleExclamation,
  faFileContract,
  faClockRotateLeft,
  faFloppyDisk,
  faHouse,
  faCirclePlus,
  faCircleMinus,
  faHourglassStart,
  faCheck,
  faPercent,
  faSort,
  faCheckDouble,
  faPenToSquare,
  faArrowUpRightFromSquare,
  faArrowUp19,
  faTrashCan,
  faBolt,
  faCircleCheck,
  faMailbox,
  faInfinity,
  faEnvelope,
  faListOl,
  faListUl,
  faPenField,
  faForwardFast,
  faFile,
  faDistributeSpacingVertical,
  faFileSearch,
  faTypewriter,
  faBellOn,
  faText,
  faNewspaper,
  faFileImage,
  faHeartRate,
  faExclamationSquare,
  faCarrot,
  faFileAlt,
  faUserCheck,
  faStar,
  faSquareCheck,
  faCheckSquare,
  faList,
  faSortNumericDown,
  faCalendarDay,
  faLineHeight,
  faParagraph,
  faHeading,
  faFileChartPie,
  faPhone,
  faUserSecret,
  faGlobe,
  faLayerMinus,
  faTrash,
  faCloudUpload,
  faCloudDownload,
  faCalendarWeek,
  faAdd,
  faShapes,
  faIdCard,
  faPhoneRotary,
  faFiles,
  faHouseBuilding,
  faBoxTaped,
  faCalendar,
  faChartLine,
  faCircle,
  faHardHat,
  faSirenOn,
  faPencil,
  faEngineWarning,
  faChartPie,
  faBookSpells,
  faEye,
  faCog,
  faToolbox,
  faFileInvoice,
  faFileInvoiceDollar,
  faAsterisk,
  faCogs,
  faQuoteLeft,
  faRocket,
  faKey,
  faBarcode,
  faBallotCheck,
  faIdBadge,
  faCheckCircle,
  faMinusCircle,
  faFingerprint,
  faBoxBallot,
  faMapMarkerAlt,
  faMapMarker,
  faUserTie,
  faUserHardHat,
  faUser,
  faChessClockAlt,
  faEuroSign,
  faDollarSign,
  faYenSign,
  faSterlingSign,
  faFrancSign,
  faChfSign,
  faCalculator,
  faLifeRing,
  faInfoCircle,
  faShieldCheck,
  faHeart,
  faHeartbeat,
  faWrenchSimple,
  faUserHeadset,
  faAt,
  faSitemap,
  faExclamationCircle,
  faPen,
  faLink,
  faLayerGroup,
  faAddressCard,
  faInventory,
  faFilePlus,
  faThumbsUp,
  faBells,
  faThumbsDown,
  faThunderstorm,
  faMoneyCheckEdit,
  faBan,
  faWindowClose,
  faCalendarEdit,
  faCalendarPlus,
  faTrashAlt,
  faPaperclip,
  faClipboardCheck,
  faArchive,
  faRecycle,
  faAlarmExclamation,
  faAlarmPlus,
  faWrench,
  faPaperPlane,
  faClock,
  faUnlock,
  faTasks,
  faWarehouse,
  faTruck,
  faParachuteBox,
  faTimes,
  faBoxCheck,
  faLock,
  faLockOpen,
  faComment,
  faSend,
  faHistory,
  faScrewdriverWrench,
  faMessageLines,
  faToggleOn,
  faSquare,
  faCommentAlt,
  faMessage,
  faMoneyBillTransfer,
  faWallet,
  faMeter,
  faChartMixed,
  faBookOpen,
  faXmark,
  faRuler,
  faCashRegister
);
