import {
  getElement,
  getElements,
  createElement,
  updateElement,
  flushElements,
  deleteElement,
  restoreElement
} from 'store/leasesSlice';

import LeasePreview from './LeasePreview';
import LeaseSkeleton from './LeaseSkeleton';
import LeaseValues, { LeaseValidation, formatForm, injectFormValues } from './LeaseValues';
import LeaseTranslations from './LeaseTranslations';
import LeaseForm from './LeaseForm';
import LeaseSorts from './LeaseSorts';

import EntityWrapper from 'entities/EntityWrapper';
import { useTranslation } from 'react-i18next';
import { NoteAdd } from '@material-ui/icons';

export default function Guarantee({ ...rest }) {
  const { t } = useTranslation();

  return (
    <EntityWrapper
      entity="leases"
      entityFieldsSlice="lease"
      updateOnClick
      skeletonComponent={LeaseSkeleton}
      previewComponent={LeasePreview}
      viewComponent={null}
      formComponent={LeaseForm}
      formAttachment
      formAttachmentIcon={<NoteAdd />}
      formAttachmentLabel={t('document')}
      formatFilter={(filters) => filters}
      formatForm={formatForm}
      getFilters={() => []}
      getInitialValues={LeaseValues}
      injectFormValues={injectFormValues}
      getFormValidation={LeaseValidation}
      getTabs={() => []}
      getTables={() => []}
      getMenus={() => []}
      getExportColumns={() => []}
      getTranslations={LeaseTranslations}
      getSorts={LeaseSorts}
      getElement={getElement}
      getElements={getElements}
      createElement={createElement}
      updateElement={updateElement}
      flushElements={flushElements}
      deleteElement={deleteElement}
      restoreElement={restoreElement}
      {...rest}
    />
  );
}
