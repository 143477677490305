const TicketExports = (configuration) =>
  [
    { label: 'ticketNumber', key: 'number', show: true },
    { label: 'createdDate', key: 'created_at', show: true },
    { label: 'priority', key: 'urgency', show: true },
    { label: 'status', key: 'state', show: true },
    { label: 'job', key: '_jobs', show: true },
    { label: 'address', key: 'address', show: true },
    { label: 'zipCode', key: 'postalCode', show: true },
    { label: 'city', key: 'city', show: true },
    {
      label: configuration.isContractor ? 'client' : 'creationClient',
      key: configuration.isContractor ? '_owner' : '_user',
      show: true
    },
    { label: 'exportLocation_reference', key: 'location_reference', show: true },
    { label: 'locations', key: '_locations', show: true },
    { label: 'address', key: 'address', show: true },
    { label: 'managersTitle', key: '_managers', show: true },
    { label: 'title', key: 'title', show: true },
    { label: 'description', key: 'description', show: true },
    { label: 'typology', key: '_typology', show: true },
    { label: 'menuItemEquipments', key: '_equipments', show: true },
    { label: 'intervenersTitle', key: '_summons', show: true },
    {
      label: 'attributionTicketIntervener',
      key: 'attribution_ticket_intervener',
      show: configuration.isClient ? true : false
    },
    { label: 'programmedVisitDate', key: 'visit_dates', show: true },
    {
      label: 'indicationScheduledVisitDate',
      key: 'indication_scheduled_visit_date',
      show: configuration.isClient ? true : false
    },
    { label: 'replanReason', key: 'replan_reason', show: true },
    { label: 'interventionDate', key: 'intervention_dates', show: true },
    { label: 'htInterventionAmount', key: 'intervention_price', show: true },
    {
      label: 'totalCostsConsumptionsStock',
      key: 'total_cost_consumptions',
      show: configuration?.feature?.inventory ? true : false
    },
    { label: 'vatRate2', key: '_tva', show: true },
    { label: 'history', key: 'history', show: true },
    {
      label: 'menuItemComptability',
      key: 'state_facture',
      show: configuration.isContractor ? false : true
    },
    {
      label: 'blachCategories',
      key: 'category_blachere',
      show: configuration.isBlachere ? true : false
    },
    {
      label: 'blachDomains',
      key: 'domaine_blachere',
      show: configuration.isBlachere ? true : false
    },
    { label: 'creator', key: 'creator', show: configuration.isBlachere ? true : false },
    { label: 'quotesPrice', key: 'devis_price', show: configuration.isContractor ? false : true },
    {
      label: 'quotesReference',
      key: 'reference_devis',
      show: configuration.isContractor ? false : true
    },
    { label: 'interventionDuration', key: 'intervention_total_duration', show: true },
    { label: 'facturationPrice', key: 'facturation_price', show: true },
    { label: 'billingReference', key: 'reference_facture', show: true },
    { label: 'quotesPrice', key: 'devis_price', show: true },
    { label: 'quotesPricewaitingValidation', key: 'devis_price_waiting_validation', show: true },
    { label: 'dateValidationQuotes', key: 'validation_date_devis', show: true },


    { label: 'quotesReference', key: 'reference_devis', show: true },
    {
      label: 'visitDatesContractor',
      key: 'visit_dates_contractor',
      show: configuration.isContractor ? true : false
    },
    {
      label: 'interventionDatesContractor',
      key: 'intervention_dates_contractor',
      show: configuration.isContractor ? true : false
    },
    {
      label: 'billingPriceContractor',
      key: 'facturation_price_contractor',
      show: configuration.isContractor ? true : false
    },
    {
      label: 'billingReferenceContractor',
      key: 'reference_facture_contractor',
      show: configuration.isContractor ? true : false
    },
    {
      label: 'quotesPriceContractor',
      key: 'devis_price_contractor',
      show: configuration.isContractor ? true : false
    },
    {
      label: 'quotesReferenceContractor',
      key: 'reference_devis_contractor',
      show: configuration.isContractor ? true : false
    },
    { label: 'ticketTermination', key: 'flag_finished', show: true },
    { label: 'userFullNamesTickets', key:'user_full_names_tickets', show: true }
  ].filter((e) => e.show);

export default TicketExports;
