import { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import Dialog from 'components/Dialog';
import { apiBaseURL } from 'index';
import axios from 'axios';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import useNotifications from 'hooks/useNotifications';
import { useTranslation } from 'react-i18next';
import { addBudgetOnTicket } from 'store/budgetsSlice';
import useConfirm from 'hooks/useConfirm';

export default function BudgetsSelectDialog({
  openModalBudgetsSelect,
  setOpenModalBudgetsSelect,
  budgets,
  ticket_id
}) {
  const { t } = useTranslation();
  const confirm = useConfirm();

  const [budgetsToActivate, setBudgetsToActivate] = useState([]);
  const { dispatch } = useAsyncDispatch();
  const notify = useNotifications();

  const get_budgets = async () => {
    return await axios.get(`${apiBaseURL}/budgets`, { params: { active_only: true } });
  };

  const add_budget_on_ticket = ({ budget_id }) => {
    return dispatch(
      addBudgetOnTicket,
      { budget_id, ticket_id },
      {
        onSuccess: () => notify.success(t('budgetAdded')),
        onError: () => notify.error()
      },
      {}
    );
  };

  useEffect(async () => {
    get_budgets().then(({ data }) => {
      const budgetsAvailable = data.budgets.filter((budget) => {
        return !budgets.find(({ budget_id }) => budget._id === budget_id);
      });

      setBudgetsToActivate(budgetsAvailable);
    });
  }, [budgets]);

  return (
    <Dialog
      icon="wallet"
      isOpen={openModalBudgetsSelect}
      onClose={() => setOpenModalBudgetsSelect(false)}
      title={t('budgetsSelect')}
      className="z-[1400]"
    >
      <div className="max-h-[250px] overflow-auto">
        {budgetsToActivate.length === 0 ? (
          <p className="text-gray-500 font-bold text-lg">{t('noBudgetsAvailable')}</p>
        ) : (
          budgetsToActivate.map((budget) => {
            return (
              <div
                className="flex items-center justify-around my-4"
                key={budget._id}
              >
                <p className="w-full font-bold text-base">
                  {t('budgetTitle')} : {budget.title?.toUpperCase()} - {budget.year}
                </p>
                <div className="bg-[#003366] mr-2 rounded-md">
                  <Button
                    endIcon={<Add className="text-white" />}
                    onClick={() =>
                      confirm({
                        title: 'confirmAddBudgetOnTicket',
                        onConfirm: () => add_budget_on_ticket({ budget_id: budget._id })
                      })
                    }
                  >
                    <p className="text-white">{t('add')}</p>
                  </Button>
                </div>
              </div>
            );
          })
        )}
      </div>
    </Dialog>
  );
}
