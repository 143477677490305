import { Checkbox, FormControlLabel, Grid, MenuItem, Select } from '@material-ui/core';
import { Field } from 'frmx';
import { useTranslation } from 'react-i18next';

export default function FieldBase({ children, lineSizeOption, mandatoryOption }) {
  const { t } = useTranslation();
  return (
    <>
      {children && (
        <Grid
          item
          xs={12}
        >
          {children}
        </Grid>
      )}
      {lineSizeOption && (
        <Grid
          item
          xs={12}
        >
          <Field path="fullWidth">
            <Select
              fullWidth
              variant="outlined"
            >
              <MenuItem value={true}>{t('allTheLign')}</MenuItem>
              <MenuItem value={false}>{t('halfTheLine')}</MenuItem>
            </Select>
          </Field>
        </Grid>
      )}

      {mandatoryOption && (
        <Grid
          item
          xs={12}
        >
          <FormControlLabel
            control={
              <Field
                path="required"
                type="checkbox"
              >
                <Checkbox />
              </Field>
            }
            label={t('mandatory')}
          />
        </Grid>
      )}
    </>
  );
}
