import { trigger } from 'react-events-utils';
import DialogTitle from 'components/dialogs/DialogTitle';
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Paper,
  TextField,
  Typography,
  useTheme
} from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { Field, Form, Submit, useField } from 'frmx';
import FAIcon from 'components/ui/FAIcon';
import { addToDate } from 'utils/dates';
import { formatISO } from 'date-fns';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import { createDeadline, updateDeadline } from 'store/ticketsSlice';
import useNotifications from 'hooks/useNotifications';
import BlurredProgress from 'components/BlurredProgress';
import { useTranslation } from 'react-i18next';
import DateTimeField from 'components/inputs/DateTimeField';

export default function TicketAlertEditor({ ticket, alert, isCreate }) {
  const { t } = useTranslation();

  const notify = useNotifications();
  const { dispatch, requestStatus } = useAsyncDispatch();

  const dispatchCallbacks = {
    onSuccess: () => notify.success(t('savedALert')),
    onError: () => notify.error()
  };

  const isCheckedAlertEmail = alert?.email ? alert?.email : alert?.email === false ? false : true;

  const close = () => trigger(`ticket-modal-action-close-${ticket._id}`);

  return (
    <>
      <BlurredProgress in={requestStatus === 'loading'} />
      <Form
        disabled={requestStatus === 'loading'}
        initialValues={{
          date: alert?.date ? new Date(alert.date) : new Date(),
          add: alert?.date ? 'perso' : 24,
          description: alert?.description || '',
          email: isCheckedAlertEmail,
          type: alert?.type || 'LOW'
        }}
        onSubmit={(data) => {
          const date = formatISO(
            data.add !== 'perso' ? addToDate(data.date, data.add, 'hours') : data.date
          );
          dispatch(
            isCreate ? createDeadline : updateDeadline,
            {
              date,
              description: data.description,
              email: data.email,
              type: data.type
            },
            dispatchCallbacks,
            {
              id: ticket._id
            }
          ).then(() => close());
        }}
      >
        <DialogTitle
          title={t('defineDeadline')}
          onClose={close}
        />
        <DialogContent>
          <FormContents />
        </DialogContent>
        <DialogActions
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '1em 2em'
          }}
        >
          <FormControlLabel
            control={
              <Field
                path="email"
                type="checkbox"
              >
                <Checkbox />
              </Field>
            }
            label={t('notifyByEmail')}
          />

          <Submit>
            <Button>{t('saveTheAlert')}</Button>
          </Submit>
        </DialogActions>
      </Form>
    </>
  );
}

function FormContents() {
  const theme = useTheme();
  const { t } = useTranslation();

  const { value: add, setValue: setAdd } = useField('add');
  const { value: type, setValue: setType } = useField('type');

  return (
    <>
      <ToggleButtonGroup
        style={{ width: '100%', margin: '1em 0' }}
        exclusive
        value={type}
        onChange={(e, next) => setType(next)}
      >
        <ToggleButton
          style={{
            width: '100%',
            fontSize: 11,
            color: type === 'LOW' ? 'white' : theme.palette.secondary.main,
            backgroundColor: type === 'LOW' ? theme.palette.secondary.main : ''
          }}
          value="LOW"
        >
          {t('weak')}
        </ToggleButton>
        <ToggleButton
          style={{
            width: '100%',
            fontSize: 11,
            color: type === 'HIGHT' ? 'white' : theme.palette.secondary.main,
            backgroundColor: type === 'HIGHT' ? theme.palette.secondary.main : ''
          }}
          value="HIGHT"
        >
          {t('strong')}
        </ToggleButton>
      </ToggleButtonGroup>

      <ToggleButtonGroup
        style={{ width: '100%', margin: '1em 0' }}
        exclusive
        value={add}
        onChange={(e, next) => setAdd(next)}
      >
        <ToggleButton
          style={{
            width: '100%',
            fontSize: 11,
            color: add === 24 ? 'white' : '#FBB034',
            backgroundColor: add === 24 ? '#FBB034' : ''
          }}
          value={24}
        >
          {t('twentyFourHours')}
        </ToggleButton>
        <ToggleButton
          style={{
            width: '100%',
            fontSize: 11,
            color: add === 48 ? 'white' : '#EE744A',
            backgroundColor: add === 48 ? '#EE744A' : ''
          }}
          value={48}
        >
          {t('fourtyEightHours')}
        </ToggleButton>
        <ToggleButton
          style={{
            width: '100%',
            fontSize: 11,
            color: add === 72 ? 'white' : '#E63D53',
            backgroundColor: add === 72 ? '#E63D53' : ''
          }}
          value={72}
        >
          {t('seventyTwoHours')}
        </ToggleButton>
        <ToggleButton
          style={{
            width: '100%',
            fontSize: 11,
            color: add === 'perso' ? 'white' : '#827596',
            backgroundColor: add === 'perso' ? '#827596' : ''
          }}
          value="perso"
        >
          {t('personalized')}
        </ToggleButton>
      </ToggleButtonGroup>

      {add === 'perso' && (
        <DateTimeField
          path="date"
          style={{ margin: '1em 0', width: '100%' }}
        />
      )}

      <Field path="description">
        <TextField
          variant="outlined"
          label={t('moreDetails')}
          fullWidth
          multiline
          rows={4}
          style={{ margin: '1em 0' }}
        />
      </Field>

      <Paper
        elevation={2}
        style={{
          display: 'flex',
          width: '100%',
          margin: '1em 0',
          position: 'relative',
          padding: '1em .5em'
        }}
      >
        <FAIcon
          collection="fal"
          icon="info-circle"
          style={{ margin: '.5em' }}
        />
        <Typography>
          {type === 'LOW' ? t('whenWeakAlertsDisappear') : t('whenStrongAlertDisappear')}
        </Typography>
      </Paper>
    </>
  );
}
