import FAIcon from 'components/ui/FAIcon';
import { VpnKey, Visibility, Cancel } from '@material-ui/icons';

import { attachClient, fetchInterfaceData } from 'store/authSlice';
import { contractorPartner, setPartnership } from 'store/intervenersSlice';
import { resetPassword } from 'utils/generateEntitySlice';

const ContractorMenu = (
  element,
  auth,
  dispatch,
  role,
  companyPermission,
  listId,
  setIsMenuLoading,
  notify
) =>
  [
    {
      label: 'resendConnectionLink',
      action: () =>
        dispatch(
          resetPassword,
          { email: element._user.email },
          { onSuccess: () => notify.success('Email envoyé'), onError: () => notify.error() },
          { id: element._id }
        ),
      icon: <VpnKey />,
      show: !!element._user && (auth.user.isBob || element.companyPermission)
    },
    {
      label: element?.score > 0 ? 'removePartnership' : 'recommendPartnership',
      action: () =>
        dispatch(
          setPartnership,
          { score: element?.score > 0 ? 0 : 30 },
          {
            onSuccess: () =>
              notify.success(element?.score > 0 ? 'removedPartnership' : 'addedPartnership'),
            onError: () => notify.error()
          },
          { id: element._id }
        ),
      icon: (
        <FAIcon
          collection="fas"
          icon={element?.score > 0 ? 'face-frown' : 'crown'}
        />
      ),
      show: !!element._user && auth.user.isBob && auth.interface.isMaster
    },
    {
      label: 'reinitializationLink',
      action: () =>
        prompt(
          'Lien de réinitialisation',
          'https://bob-desk.com/set-password/' + element._user.resetPasswordToken
        ),
      icon: <Visibility />,
      show: !!element._user && auth.user.isBob && !!element._user.resetPasswordToken
    },
    {
      label: 'attachToContractor',
      action: () =>
        dispatch(
          attachClient,
          { attach: true, parentType: '_contractor', type: 'contractor' },
          {},
          { id: element._id }
        ),
      icon: (
        <FAIcon
          collection={'fas'}
          icon={'portal-enter'}
        />
      ),
      show:
        !!element._user &&
        auth.user.isBob &&
        !auth.user.interfaces.find((i) => i._company._id === element._id)
    },
    {
      label: 'unattachFromContractor',
      action: () =>
        dispatch(
          attachClient,
          { attach: false, parentType: '_contractor', type: 'contractor' },
          {},
          { id: element._id }
        ),
      icon: (
        <FAIcon
          collection={'fas'}
          icon={'portal-exit'}
        />
      ),
      show:
        !!element._user &&
        auth.user.isBob &&
        auth.user.interfaces.find((i) => i._company._id === element._id)
    },
    {
      label: auth.interface.isContractor ? 'deleteFromMySucontractors' : 'deleteFromMyEnterprises',
      action: () => {
        /**
         ** Here if we delete a company from our interveners,
         ** we redispatch an action to check if we still have interveners
         */
        return dispatch(contractorPartner, {}, {}, { id: element._id }).then(() =>
          dispatch(fetchInterfaceData)
        );
      },
      icon: <Cancel />,
      show: !!element.partner
    }
  ].filter((e) => e.show);

export default ContractorMenu;
