const isAnImage = (mimetype) => mimetype.startsWith('image/');
const isAPdf = (mimetype) => mimetype === 'application/pdf';

export const getPlaceholder = (mimetype) => {
  if (isAnImage(mimetype)) return '/image_placeholder.webp';
  if (isAPdf(mimetype)) return '/pdf_placeholder.webp';
  return '/file_placeholder.webp';
};

export const getFileType = (mimetype) => {
  if (isAnImage(mimetype)) return 'img';
  if (isAPdf(mimetype)) return 'pdf';
  return 'file';
};

export const isPlaceholder = (url, mimetype) => url === getPlaceholder(mimetype);
