import useKeys from '@flowsn4ke/usekeys';
import {
  List,
  Box,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles
} from '@material-ui/core';
import IconButton from 'components/IconButton';
import SuspenseLoader from 'components/SuspenseLoader';
import FAIcon from 'components/ui/FAIcon';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import useConfirm from 'hooks/useConfirm';
import useNotifications from 'hooks/useNotifications';
import { Empty } from 'layouts/entities/List';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { delete_reading, get_readings } from 'store/metersSlice';
import { dateToLocalFormat } from 'utils/dates';
import UpdateReadingForm from '../Forms/UpdateReadingForm';
import { useRole } from 'hooks/useRole';

const useStyles = makeStyles((theme) => ({
  list: {
    backgroundColor: 'white',
    minHeight: '100%',
    paddingTop: 0,
    ['& > :nth-child(even)']: {
      background: '#ebebeb'
    }
  }
}));

export default function MeterReadings({ meter_id }) {
  const { dispatch, requestStatus } = useAsyncDispatch();
  const confirm = useConfirm();
  const k = useKeys();
  const notify = useNotifications();
  const classes = useStyles();
  const { t } = useTranslation();
  const role = useRole();

  const dispatchCallbacks = {
    onSuccess: () => notify.success(t('readingDeleted')),
    onError: () => notify.error()
  };

  const readings = useSelector((state) => state.meters.db[meter_id]?.readings);
  const [edit_reading, set_edit_reading] = useState(false);

  useEffect(() => {
    dispatch(get_readings, {}, {}, { id: meter_id });
  }, []);

  const last_reading_by_date = readings?.find((reading) => {
    // * we compare dates as strings, in reduce the array to keep only the last date
    const the_last_date = readings.reduce((acc, curr) => (acc.at > curr.at ? acc : curr))?.at;

    return reading.at === the_last_date;
  });

  const has_permission_to_update_reading = role.permission('meters', 'update-reading');
  const has_permission_to_delete_reading = role.permission('meters', 'delete-reading');

  return requestStatus === 'loading' ? (
    <div className="flex items-center justify-center h-full">
      <SuspenseLoader />
    </div>
  ) : requestStatus === 'error' ? (
    <Box>{t('somethingWentWrongSorry')}</Box>
  ) : requestStatus === 'success' && readings?.length === 0 ? (
    <Empty
      icon="book-open"
      translations={{
        noResultLabel: t('readingsTitle'),
        noResultText: t('noResultsReading')
      }}
    />
  ) : (
    <List className={classes.list}>
      {readings?.map((reading, reading_idx) => {
        const is_last = String(reading._id) === String(last_reading_by_date?._id);

        return (
          <ListItem key={k(reading_idx)}>
            {edit_reading && is_last ? (
              <UpdateReadingForm
                meter_id={meter_id}
                reading_to_update={last_reading_by_date}
                on_close={() => set_edit_reading(false)}
              />
            ) : (
              <ListItemText
                primary={reading.value}
                secondary={dateToLocalFormat(reading.at)}
              />
            )}
            {is_last && !edit_reading && (
              <ListItemSecondaryAction className="flex">
                {has_permission_to_update_reading && (
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    onClick={() => set_edit_reading(true)}
                  >
                    <FAIcon
                      icon="edit"
                      collection="fad"
                      size="small"
                    />
                  </IconButton>
                )}

                {has_permission_to_delete_reading && (
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() =>
                      confirm({
                        title: t('deleteReading'),
                        helperText: t('deleteReadingHelper', {
                          value: reading.value,
                          date: dateToLocalFormat(reading.at)
                        }),
                        onConfirm: () =>
                          dispatch(
                            delete_reading, // action
                            {}, // args
                            dispatchCallbacks, // callbacks
                            {
                              // params
                              id: meter_id,
                              reading_id: reading._id
                            }
                          )
                      })
                    }
                  >
                    <FAIcon
                      icon="trash"
                      collection="fad"
                      size="small"
                    />
                  </IconButton>
                )}
              </ListItemSecondaryAction>
            )}
          </ListItem>
        );
      })}
    </List>
  );
}
