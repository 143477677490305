import { Badge, Box, Button, IconButton } from '@material-ui/core';
import FAIcon from 'components/ui/FAIcon';
import useFileViewer from 'hooks/useFileViewer';
import React from 'react';
import classNames from 'utils/classNames';
import useStyles from 'layouts/entities/View.styles';
import { useTranslation } from 'react-i18next';

const OrderAction = ({ documents_validation, setReasonOpen, actions, isLoading }) => {
  const { openFiles, setFilesActions } = useFileViewer();
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box marginTop={'10px'}>
      {!!documents_validation?.length && (
        <IconButton
          style={{ position: 'relative', left: 4 }}
          onClick={() => {
            openFiles(documents_validation);
            setFilesActions((prev) => (
              <Box
                display={'flex'}
                alignItems={'center'}
              >
                <Button
                  onClick={() => () =>
                    setReasonOpen(() => (reason) => actions.order_validation.action(false, reason))
                  }
                  endIcon={
                    <FAIcon
                      collection="fal"
                      icon="thumbs-down"
                      className={classes.viewActionIcon}
                    />
                  }
                  variant="contained"
                  size="large"
                  className={classNames(classes.viewAction, classes.viewActionError)}
                >
                  {t('decline')}
                </Button>

                <Button
                  onClick={() => actions.order_validation.action(true)}
                  endIcon={
                    <FAIcon
                      collection="fal"
                      icon="thumbs-up"
                      className={classes.viewActionIcon}
                    />
                  }
                  variant="contained"
                  size="large"
                  className={classNames(classes.viewAction, classes.viewActionSuccess)}
                >
                  {t('accept')}
                </Button>
              </Box>
            ));
          }}
        >
          <Badge
            badgeContent={documents_validation?.length}
            color={'secondary'}
          >
            <FAIcon
              collection="fal"
              icon="file"
            />
          </Badge>
        </IconButton>
      )}

      <Button
        onClick={() =>
          setReasonOpen(() => (reason) => actions.order_validation.action(false, reason))
        }
        disabled={isLoading}
        endIcon={
          <FAIcon
            collection={'fal'}
            icon={'thumbs-down'}
            className={classes.viewActionIcon}
          />
        }
        color="secondary"
        variant="contained"
        className={classNames(classes.viewAction, classes.viewActionError)}
      >
        {t('decline')}
      </Button>

      <Button
        onClick={() => actions.order_validation.action(true)}
        disabled={isLoading}
        endIcon={
          <FAIcon
            collection={'fal'}
            icon={'thumbs-up'}
            className={classes.viewActionIcon}
          />
        }
        color={'secondary'}
        variant={'contained'}
        className={classNames(classes.viewAction, classes.viewActionSuccess)}
      >
        {t('accept')}
      </Button>
    </Box>
  );
};

export default OrderAction;
