import { Grid, TextField } from '@material-ui/core';
import Role from 'entities/Role/Role';
import { Field, useField } from 'frmx';
import PhoneField from 'components/inputs/PhoneField';
import EntityX from 'components/inputs/EntityX';
import InterfaceForm from 'components/Interface/InterfaceForm';
import Job from 'entities/Job/Job';
import { ActivityField } from 'settings/Activity';
import { useTranslation } from 'react-i18next';
import { LangSelect } from 'entities/User/UserForm';

export default function TechnicianForm({ isCreate, element }) {
  const { value: email, setValue: setEmail, error: emailError } = useField('email');
  const { t } = useTranslation();

  return (
    <>
      <Grid
        item
        xs={12}
        sm={6}
      >
        <Field path="firstName">
          <TextField
            variant="outlined"
            label={t('firstName')}
            fullWidth
            autoFocus
          />
        </Field>
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
      >
        <Field path="lastName">
          <TextField
            variant="outlined"
            label={t('lastName')}
            fullWidth
          />
        </Field>
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
      >
        <TextField
          error={emailError}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          variant="outlined"
          label="Email"
          helperText={emailError ? t('noEmailForAccountHelperText') : ''}
          fullWidth
        />
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
      >
        <LangSelect
          path="lang"
          label={t('lang')}
        />
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
      >
        <PhoneField
          path="phone"
          label={t('cellphoneNumber')}
          variant="outlined"
          placeholder={t('cellphoneNumber')}
        />
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
      >
        <PhoneField
          path="fix"
          label={t('landlineNumber')}
          variant="outlined"
          placeholder={t('landlineNumber')}
        />
      </Grid>

      <Grid
        item
        xs={12}
      >
        <EntityX
          errorHelperText={t('pleaseSelectARole')}
          path="_role"
          entityName="roles"
          entity={Role}
          entityProps={{ hiddenFilters: { types: ['collaborator'] } }}
          placeholder="Rôle"
          pickerUniq
        />
      </Grid>

      <div className="mx-4 p-2 text-gray-600 text-xs w-full border-b my-2">{t('visibility-assign')}</div>
      <Grid
        item
        xs={12}
      >
        <ActivityField path="zone_ids" />
      </Grid>

      <Grid
        item
        xs={12}
      >
        <EntityX
          path="_jobs"
          entity={Job}
          placeholder={t('jobs')}
        />
      </Grid>

      {
        <Grid
          item
          xs={12}
        >
          <InterfaceForm
            isCreate={isCreate}
            element={element}
          />
        </Grid>
      }
    </>
  );
}
