import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  menu: {
    paddingTop: 0,
    marginRight: 10
  },
  menuItem: {
    borderRadius: theme.radius,
    cursor: 'pointer',
    marginBottom: 4
  },
  menuItemActive: {
    backgroundColor: '#e8eaea'
  },
  menuMobile: {
    width: '100%',
    marginBottom: 10,
    overflow: 'hidden',
    backgroundColor: theme.palette.secondary.main
  },
  menuMobileButtonBase: {
    color: 'white',
    fontWeight: '600',
    fontSize: 14,
    width: '100%',
    height: 48,
    justifyContent: 'flex-start',
    padding: '0px 16px 0px 16px'
  },
  menuMobileIcon: {
    marginRight: 10
  },
  menuMobileExpand: {
    color: 'white',
    marginLeft: 'auto'
  },
  listItemText: {
    fontWeight: '500'
  },
  list: {
    background: 'white'
  },
  listItemIcon: {
    marginRight: 20
  },
  activeTab: {
    color: theme.palette.primary.main
  }
}));
