import { createSlice } from '@reduxjs/toolkit';
import { generateEntityBoilerplate } from 'utils/generateEntitySlice';
import generateAsyncThunk from 'utils/generateAsyncThunk';
import { consumeStock, formatElement } from 'store/ticketsSlice';
import { pick } from 'lodash-es';
import { assign } from '@recursive/assign';

export const createElement = generateAsyncThunk({ type: 'POST', endpoint: 'products' });
export const updateElement = generateAsyncThunk({ type: 'PUT', endpoint: 'products/${id}' });
export const getElement = generateAsyncThunk({ type: 'GET', endpoint: 'products/${id}' });
export const getElements = generateAsyncThunk({ type: 'POST', endpoint: 'products/list' });
export const deleteElement = generateAsyncThunk({ type: 'DELETE', endpoint: 'products/${id}' });
export const restoreElement = generateAsyncThunk({
  type: 'POST',
  endpoint: 'products/recycle/${id}'
});
export const linkEquipmentsToStock = generateAsyncThunk({
  type: 'PUT',
  endpoint: 'products/${id}/linkEquipmentsToStock'
});
/**
 * Actions Stocks
 */
export const adjustementStock = generateAsyncThunk({
  type: 'PUT',
  endpoint: 'products/${id}/adjustement'
});
export const submitNewOrder = generateAsyncThunk({
  type: 'POST',
  endpoint: 'products/${id}/order'
});
export const receiveOrder = generateAsyncThunk({
  type: 'GET',
  endpoint: 'products/${id}/order/receive'
});
export const cancelOrder = generateAsyncThunk({
  type: 'GET',
  endpoint: 'products/${id}/order/cancel'
});

const entityBoilerplate = generateEntityBoilerplate({
  // updateElement,
  getElement,
  getElements,
  deleteElement,
  restoreElement
});
export const uploadCover = generateAsyncThunk({ type: 'FILE', endpoint: 'image' });

const { initialState, reducers, extraReducers } = entityBoilerplate;

export const stocksSlice = createSlice({
  name: 'stocks',
  initialState,
  reducers,
  extraReducers: {
    ...extraReducers,
    [consumeStock.fulfilled]: (state, action) => {
      const { id: ticketId } = action.meta.arg.params;
      const { quantity, _product: productId } = action.meta.arg;
      const { product, ticket } = action.payload.data;

      if (state.db[product._id]) {
        state.db[product._id] = assign(state.db[product._id], product);
      }
      if (state.db[ticket._id]) {
        state.db[ticket._id] = assign(state.db[ticket._id], formatElement(ticket));
      }

      const productFromDB = state.db[productId]._consumptions.find((c) => c._ticket === ticketId);

      if (productFromDB) {
        productFromDB.quantity = quantity;
      }
    },
    [createElement.fulfilled]: (state, action) => {
      const { listId } = action.meta.arg.params;
      const { element } = action.payload.data;

      element.map((el) => {
        if (listId && state.lists[listId]) {
          state.lists[listId] = [pick(el, ['_id']), ...state.lists[listId]];
          state.lists[listId].count += 1;
        }

        return (state.db[el._id] = el);
      });
    },
    [updateElement.fulfilled]: (state, action) => {
      const { id } = action.meta;
      const { element } = action.payload.data;

      state.db[id] = assign(state.db[id], element);
    },
    [adjustementStock.fulfilled]: (state, action) => {
      const { id } = action.meta;
      const { element } = action.payload.data;

      state.db[id] = assign(state.db[id], element);
    },
    [submitNewOrder.fulfilled]: (state, action) => {
      const { id } = action.meta;
      const { element } = action.payload.data;

      state.db[id] = assign(state.db[id], element);
    },
    [receiveOrder.fulfilled]: (state, action) => {
      const { id } = action.meta;
      const { element } = action.payload.data;

      state.db[id] = assign(state.db[id], element);
    },
    [cancelOrder.fulfilled]: (state, action) => {
      const { id } = action.meta;
      const { element } = action.payload.data;

      state.db[id] = assign(state.db[id], element);
    },
    [linkEquipmentsToStock.fulfilled]: (state, action) => {
      const { id } = action.meta;
      const { element } = action.payload.data;

      state.db[id] = assign(state.db[id], element);
    }
  }
});

export const { flushElements, flushElement } = stocksSlice.actions;
export default stocksSlice.reducer;
