import { createElement } from 'react';
import classNames from 'utils/classNames';

export default function IconButton({
  button = false,
  render = 'div',
  children,
  onClick = () => {},
  className,
  ...rest
}) {
  return createElement(button ? 'button' : render, {
    className: classNames(
      'w-auto h-auto flex items-center justify-center hover:bg-gray-100 focus:outline-none m-1 rounded-full p-1.5 cursor-pointer',
      className
    ),
    children,
    onClick,
    ...rest
  });
}
