import { Box, List } from '@material-ui/core';

import useStyles from 'layouts/entities/View.styles';

export default function ApiView({ element }) {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.l1}>
        <Box
          alignItems="center"
          display="flex"
        >
          <Box
            flexDirection="column"
            alignItems="flex-start"
          >
            <Box className={classes.l1left}></Box>
          </Box>
        </Box>
      </Box>

      <List className={classes.list}></List>
    </>
  );
}
