import {
  Select,
  FormControl,
  InputLabel,
  Box,
  Chip,
  Divider,
  InputAdornment,
  FormHelperText,
  Tooltip,
  makeStyles
} from '@material-ui/core';

import { isArray, get, isObject } from 'lodash-es';

import { useEffect, useState } from 'react';
import { useField, useFieldObserver, useForm } from 'frmx';

import PopoverDrawer from 'components/ui/PopoverDrawer';
import { useIsBelowSm } from 'hooks/useMQ';

import useKeys from '@flowsn4ke/usekeys';
import entities from 'config/entities';
import FAIcon from 'components/ui/FAIcon';
import classNames from 'utils/classNames';

const useStyles = makeStyles((theme) => ({
  entityX: {
    border: '1px solid #c4c4c4',
    boxShadow: theme.shadows[1],
    transform: 'translateY(6px) !important'
  },
  header: {
    background: theme.palette.secondary.main,
    color: 'white',
    display: 'flex',
    fontSize: 14,
    fontWeight: 500,
    padding: '10px 10px',
    alignItems: 'center',
    borderRadius: `${theme.radius}px ${theme.radius}px 0px 0px`
  },
  select: {
    display: 'flex',
    flexWrap: 'nowrap'
  },
  chipRoot: {
    justifyContent: 'space-between',
    width: '10em'
  },
  chipLabel: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginLeft: '.3em'
  },
  chipDeletable: {
    cursor: 'pointer'
  }
}));

export default function EntityX({
  path,
  afterChange,
  error: forceError,
  errorHelperText,
  placeholder,
  entity: Entity,
  entityName,
  disabled: manuallyDisabled,
  disabledHelperText,
  pickerUniq,
  entityProps,
  style,
  required,
  refreshValue,
  showAll,
  customStyleDialog = {},
  no_margin_style_select = false
}) {
  const isBelowSm = useIsBelowSm();

  const fldx = useField(path, { afterChange });
  const frmx = useForm();
  // this is so field will get updated when we use "setOneField" somewhere else in the code
  const fieldValue = useFieldObserver(path);

  useEffect(() => {
    refreshValue && fieldValue !== fldx?.value && fldx?.setValue(fieldValue);
  }, [fieldValue]);

  useEffect(() => {
    frmx.setOneError(path, fldx.error);
  }, [fldx?.error]);

  const chipKeys = (entities[entityName] && entities[entityName].chipKeys) || ['name'];
  const icon = entities[entityName] && entities[entityName].icon;

  const [entityPopoverAnchor, setEntityPopoverAnchor] = useState(null);
  const [entityPopoverIsVisible, setEntityPopoverIsVisible] = useState(false);

  const openEntityPopover = (e) => [setEntityPopoverAnchor(e.currentTarget), setEntityPopoverIsVisible(true)];
  const closeEntityPopover = () => {
    setEntityPopoverIsVisible(false);
    fldx?.onBlur();
    setTimeout(() => setEntityPopoverAnchor(null), 300);
  };

  const shrink = (isObject(fldx.value) && !isArray(fldx.value)) || !!fldx.value?.length;
  const k = useKeys();

  const classes = useStyles();

  const isError = forceError || fldx.error;

  return (
    <>
      <Tooltip
        title={
          (manuallyDisabled || fldx.disabled) && !!disabledHelperText && typeof disabledHelperText === 'string'
            ? disabledHelperText
            : ''
        }
        arrow
      >
        <FormControl
          style={{
            opacity: manuallyDisabled || fldx.disabled ? 0.5 : 1,
            ...style
          }}
          fullWidth
          error={isError}
        >
          <InputLabel
            style={{
              ...(shrink
                ? {
                    backgroundColor: 'white',
                    paddingLeft: '10px',
                    paddingRight: '3px'
                  }
                : {})
            }}
            variant="outlined"
            shrink={shrink}
          >
            {!shrink && <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
            {placeholder}
            {required ? ' *' : ''}
          </InputLabel>
          <Select
            startAdornment={
              <InputAdornment position="start">
                <FAIcon
                  style={{ fontSize: 16, height: 16, width: 16, color: isError ? 'red' : '' }}
                  collection="fal"
                  icon={icon || 'asterisk'}
                />
              </InputAdornment>
            }
            disabled={manuallyDisabled || fldx.disabled}
            variant="outlined"
            open={false}
            multiple={!!pickerUniq}
            defaultValue=""
            onClick={(e) => !(manuallyDisabled || fldx.disabled) && openEntityPopover(e)}
            fullWidth
            value={!!fldx.value ? fldx.value : ''}
            onChange={(e) => fldx.setValue(e.target.value)}
            renderValue={(selectedValues) => {
              return (pickerUniq ? (isArray(fldx.value) ? fldx.value : [fldx.value]) : selectedValues).map(
                (selectedItem, i) => (
                  <Chip
                    key={k(i)}
                    color="secondary"
                    size="small"
                    onDelete={() => {
                      if (!(manuallyDisabled || fldx.disabled)) {
                        if (pickerUniq) {
                          fldx.setValue(null);
                        } else {
                          fldx.setValue(fldx.value.filter((v) => v._id !== selectedItem._id));
                        }
                      }
                    }}
                    label={get(
                      selectedItem,
                      !isArray(chipKeys)
                        ? chipKeys
                        : chipKeys.find((k) => get(selectedItem, k) && get(selectedItem, k)?.trim()?.length)
                    )}
                    style={{ margin: !no_margin_style_select && '4px 2px 2px 4px' }}
                    classes={{
                      root: classes.chipRoot,
                      label: classes.chipLabel,
                      deletable: classes.chipDeletable
                    }}
                  />
                )
              );
            }}
            classes={{ root: classNames(showAll ? classes.select : '') }}
          />
          <FormHelperText>{isError ? errorHelperText : ''}</FormHelperText>
        </FormControl>
      </Tooltip>

      <PopoverDrawer
        fullWidth
        fromBottom
        anchorEl={entityPopoverAnchor}
        open={entityPopoverIsVisible}
        onOpen={openEntityPopover}
        onClose={() => [closeEntityPopover(), fldx?.onBlur()]}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        PaperProps={{ className: classes.entityX, style: { overflow: 'hidden' } }}
      >
        <Box style={{ width: '100%', background: 'white', overflow: 'hidden', borderRadius: 8 }}>
          {isBelowSm && (
            <Box className={classes.header}>
              <FAIcon
                className="mr-4"
                size="sm"
                collection="fad"
                icon={icon}
              />
              {placeholder}
            </Box>
          )}
          <Box style={{ width: '100%', background: 'white', borderRadius: 8 }}>
            <Box
              style={{
                height: customStyleDialog?.isMobile ? window.innerHeight : customStyleDialog?.height || 300,
                width: '100%'
              }}
            >
              <Entity
                form
                pickerCreate
                closePicker={closeEntityPopover}
                fldx={fldx}
                picker
                pickerUniq={pickerUniq}
                pickerField={path}
                {...entityProps}
              />
              <Divider />
            </Box>
          </Box>
        </Box>
      </PopoverDrawer>
    </>
  );
}
