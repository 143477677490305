import { Checkbox, Collapse, Box, ButtonBase } from '@material-ui/core';

import { Email } from '@material-ui/icons';

import logo from 'images/logo.svg';
import ConfirmDialog from 'components/dialogs/ConfirmDialog';

import { useField } from 'frmx';
import { useState } from 'react';
import useStyles from './InterfaceForm.styles';
import { useTranslation } from 'react-i18next';

export default function InterfaceForm({ isCreate, element }) {
  const c = useStyles();

  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  const { value: userInterface, setValue: setUserInterface } = useField('userInterface');
  const { value: sendEmail, setValue: setSendEmail } = useField('sendEmail');
  const { t } = useTranslation();

  return (
    <Box className={c.root}>
      <ButtonBase
        className={c.bb}
        onClick={() => {
          if (!isCreate && element?.userInterface && userInterface) {
            setConfirmDeleteOpen(true);
          } else {
            setUserInterface(!userInterface);
          }
        }}
      >
        <Box className={c.top}>
          <Checkbox
            checked={userInterface}
            className={c.checkbox}
            style={{ color: 'white' }}
          />
          <strong>{t('enable-interface')}</strong>
          <img
            alt="bob! desk logo"
            src={logo}
            className={c.logo}
          />
        </Box>

        {
          <Collapse in={true}>
            <p className={c.hint}>{t('activate-interface-text')}</p>
          </Collapse>
        }

        {userInterface && (isCreate || !element?._user) && (
          <Box
            className={c.email}
            onClick={(e) => [e.preventDefault(), e.stopPropagation(), setSendEmail(!sendEmail)]}
          >
            <Checkbox
              checked={sendEmail}
              style={{ color: 'white' }}
              className={c.emailCheckbox}
            />
            <Email className={c.emailIcon} />
            {t('send-email-creation')}
          </Box>
        )}
      </ButtonBase>
      <ConfirmDialog
        text={
          <span>
            {t('doYouWantRemoveInterfaceFor')}
            <strong>{element?.name}</strong>?
          </span>
        }
        open={confirmDeleteOpen}
        onClose={() => [setConfirmDeleteOpen(false)]}
        onConfirm={() => setUserInterface(!userInterface)}
      />
    </Box>
  );
}
