import Dialog from 'components/Dialog';
import { useContext, useRef } from 'react';
import { createContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ConfirmContext = createContext();

export default function useConfirm() {
  return useContext(ConfirmContext);
}

const defaultTitle = 'areYouSureYouWantToProceed';

function Confirm({
  isOpen,
  onConfirm,
  onDecline,
  onCancel,
  icon,
  title,
  helperText,
  confirmText,
  userConfirmText,
  setUserConfirmText,
  declineLabel = 'no',
  cancelLabel = 'cancel',
  confirmLabel = 'validate',
  width = 'md',
  children
}) {
  const { t } = useTranslation();

  return (
    <Dialog
      isOpen={isOpen}
      title={t(title)}
      icon={icon}
      width={width}
      onClose={() => onCancel()}
      className="z-[1400]"
    >
      {children}
      {Boolean(helperText) && <p className="my-4 text-sm">{t(helperText)}</p>}
      {Boolean(confirmText) && (
        <>
          <div className="my-4 text-base">
            <span>{t('toConfirmMessage')}&nbsp;</span>
            <span className="italic break-words">{t(confirmText)}</span>
          </div>
          <input
            className="border w-full h-10 rounded text-base"
            value={userConfirmText}
            onChange={(event) => setUserConfirmText(event.target.value)}
          />
        </>
      )}
      <div className="flex justify-between mt-4">
        <div>
          <button
            className="border border-sky-900 text-sky-900 px-3 py-2 rounded-full"
            onClick={() => onCancel()}
          >
            {t(cancelLabel)}
          </button>
        </div>
        <div className="space-x-2">
          {Boolean(onDecline) && (
            <button
              className="border border-sky-900 text-sky-900 px-3 py-2 rounded-full"
              onClick={() => onDecline()}
            >
              {t(declineLabel)}
            </button>
          )}
          <button
            disabled={Boolean(confirmText) ? userConfirmText !== t(confirmText) : false}
            onClick={() => onConfirm()}
            className="bg-sky-900 disabled:opacity-50 text-white px-3 py-2 rounded-full"
          >
            {t(confirmLabel)}
          </button>
        </div>
      </div>
    </Dialog>
  );
}

export function ConfirmProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);
  const [onConfirm, setOnConfirm] = useState(null);
  const [onCancel, setOnCancel] = useState(null);
  const [onDecline, setOnDecline] = useState(null);
  const [title, setTitle] = useState(defaultTitle);
  const [helperText, setHelperText] = useState();
  const [confirmText, setConfirmText] = useState();
  const [userConfirmText, setUserConfirmText] = useState('');
  const [cancelLabel, setCancelLabel] = useState();
  const [confirmLabel, setConfirmLabel] = useState();
  const [icon, setIcon] = useState();
  const [nodeChildren, setNodeChildren] = useState(null);
  const [width, setWidth] = useState('md');

  const confirm = useRef(
    ({
      onConfirm,
      onCancel = () => {},
      onDecline,
      title = defaultTitle,
      icon = 'circle-check',
      helperText,
      confirmText,
      cancelLabel,
      confirmLabel,
      nodeChildren,
      width = 'md'
    }) => {
      setOnCancel(() => onCancel);
      setOnDecline(() => onDecline);
      setOnConfirm(() => onConfirm);
      setTitle(title);
      setHelperText(helperText);
      setIsOpen(true);
      setConfirmText(confirmText);
      setCancelLabel(cancelLabel);
      setConfirmLabel(confirmLabel);
      setIcon(icon);
      setNodeChildren(nodeChildren);
      setWidth(width);
    }
  );

  const close = useRef(() => [
    setIsOpen(false),
    setOnConfirm(null),
    setConfirmText(null),
    setUserConfirmText('')
  ]);

  return (
    <ConfirmContext.Provider value={confirm.current}>
      <Confirm
        isOpen={isOpen}
        onDecline={onDecline ? () => [onDecline(), close.current()] : null}
        onCancel={() => [onCancel(), close.current()]}
        onConfirm={() => [onConfirm(), close.current()]}
        icon={icon}
        title={title}
        helperText={helperText}
        confirmText={confirmText}
        userConfirmText={userConfirmText}
        setUserConfirmText={setUserConfirmText}
        cancelLabel={cancelLabel}
        confirmLabel={confirmLabel}
        children={nodeChildren}
        width={width}
      />
      {children}
    </ConfirmContext.Provider>
  );
}
