import { Box, Chip } from '@material-ui/core';

import { useAuth } from 'hooks/useAuth';

import { getPermissions, roleTypes } from 'config/role';

import useStyles from 'layouts/entities/Preview.styles';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default function RolePreview({ element, isActive }) {
  const classes = useStyles();
  const auth = useAuth();
  const configuration = auth.interface._company._configuration;
  const { t } = useTranslation();

  const type = roleTypes[auth.interface.type]?.find((r) => r.value === element.type);
  const permissions = useMemo(
    () => getPermissions(configuration, { type: type?.value }),
    [configuration, type?.value]
  );
  const permLength = Object.keys(element.permissions)
    .filter((p) => permissions[p] && permissions[p]?.length)
    .reduce((a, b) => a + element.permissions[b]?.length, 0);

  return (
    <Box style={{ width: '100%' }}>
      <Box className={classes.l}>
        <span className={classes.title}>{element.name}</span>
      </Box>
      {
        <Box className={classes.l}>
          <Box
            display={'flex'}
            alignItems={'center'}
          >
            <strong className={classes.reference}>{permLength} Permissions</strong>
          </Box>
          <Box>
            {type && (
              <Chip
                className={classes.chip}
                color="secondary"
                variant={isActive ? 'default' : 'outlined'}
                size="small"
                label={t(type.label)}
              />
            )}
          </Box>
        </Box>
      }
    </Box>
  );
}
