import { createTheme } from '@material-ui/core';
import overrides from './overrides/overrides';
import { pickPalette } from './palette';
import typography from './typography';

const _theme = {
  typography,
  radius: 8,
  zIndex: {
    snackbar: 1000001
  },
  gradient: {
    background: 'linear-gradient(230deg,#a24bcf,#4b79cf,#4bc5cf)',
    backgroundSize: '300% 300%',
    WebkitAnimation: 'Gradient 9s ease infinite',
    MozAnimation: 'Gradient 9s ease infinite',
    OAnimation: 'Gradient 9s ease infinite',
    animation: 'Gradient 9s ease infinite'
  },
  transitionTheme: {
    transition: 'all 0.2s ease-in'
  },
  sidebar: {
    transition: '250ms cubic-bezier(0.7, 0, 0.84, 0)',
    openSidebarWidth: 242,
    closedSidebarWidth: 72
  },
  spinnerAnimation: {
    animation: 'spin infinite 1s linear'
  }
};

const theme = (palette) => ({
  ..._theme,
  palette: pickPalette(palette),
  overrides: overrides(_theme)
});

const themePicker = (palette) => createTheme(theme(palette));

export default themePicker;
