import { Dialog, DialogContent, DialogActions, Box, DialogTitle, Button } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import entities from 'config/entities';
import { useEntity } from 'contexts/entities/entityContext';
import { useTranslation } from 'react-i18next';

export default function EntityDialog({ open, onClose, entity, trash, children }) {
  const close = (e) => [e.preventDefault(), e.stopPropagation(), onClose()];

  // TODOL Filter by location here

  const {
    defaultFilters,

    hiddenFilters
  } = useEntity();

  const { t } = useTranslation();

  const Entity = entities[entity].entity || (() => null);

  const entityProps = entities[entity].entityProps || {};

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      disableEnforceFocus
      open={open}
      onClose={(e) => close(e)}
    >
      {trash && (
        <DialogTitle>
          <Box
            display="flex"
            alignItems="center"
          >
            <Delete style={{ marginRight: 10 }} />
            {t('trash')}
          </Box>
        </DialogTitle>
      )}
      <DialogContent style={{ padding: 0 }}>
        <Box
          width="100%"
          minHeight="400px"
          height="400px"
        >
          {trash && (
            <Entity
              trash
              defaultFilters={defaultFilters}
              hiddenFilters={hiddenFilters}
              {...entityProps}
            />
          )}
          {!trash && children}
        </Box>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'flex-end' }}>
        <Button
          onClick={(e) => [close(e), onClose()]}
          color="primary"
        >
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
