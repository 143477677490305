export default function ManagerTable(configuration, role, auth) {
  // TODO faire la traduction
  const tables = [
    // { field: 'id', hide: true },
    // {
    //   field: 'name',
    //   headerName: 'Nom du lieu',
    //   type: 'number',
    //   width: 150,
    //   render: (element) => element.name
    // },
    // {
    //   field: 'title',
    //   headerName: 'TItre du ticket',
    //   width: 250
    // },
    // {
    //   field: 'created_at',
    //   headerName: 'Date de création',
    //   type: 'dateTime',
    //   width: 250,
    // },
    // {
    //   field: 'description',
    //   headerName: 'Description',
    //   width: 200
    // },
  ];

  return tables;
}
