class Cacher {
  constructor() {
    this.cache = {};
  }
  cache_data({ key, data }) {
    if (key in this.cache) {
      console.log(`Key ${key} already exists in cache`);
      return false;
    }

    this.cache[key] = data;
    return true;
  }
  get_cached_data({ key }) {
    return this.cache[key];
  }

  clear_cache() {
    this.cache = {};
  }
}

export default new Cacher();
