import {
  getElement,
  getElements,
  createElement,
  updateElement,
  flushElements,
  deleteElement,
  restoreElement
} from 'store/maintenancesSlice';

import {
  flushElements as flushElementsPeriods,
  getElements as getElementsPeriods,
  updateElement as updateElementPeriods
} from 'store/periodsSlice';

import MaintenanceMenus from './MaintenanceMenus';
import MaintenancePreview from './MaintenancePreview';
import MaintenanceSkeleton from './MaintenanceSkeleton';
import MaintenanceView from './MaintenanceView';
import MaintenanceTabs from './MaintenanceTabs';
import MaintenanceFilters, { formatFilter } from './MaintenanceFilters';
import MaintenanceFormValues, { MaintenanceValidation, formatForm } from './MaintenanceFormValues';
import MaintenanceTranslations from './MaintenanceTranslations';
import MaintenanceExport from './MaintenanceExport';
import MaintenanceForm from './MaintenanceForm';
import MaintenanceSorts from './MaintenanceSorts';

import EntityWrapper from 'entities/EntityWrapper';
import MaintenanceTable from './MaintenanceTable';
import useConfirm from 'hooks/useConfirm';
import { useTranslation } from 'react-i18next';

export default function Maintenance({ timeline, hiddenFilters, ...rest }) {
  const confirm = useConfirm();
  const beforeCreateOrEdit = ({ setOneField, handleSubmit, isCreate, getUpdatesList }) => {
    if (isCreate) {
      setOneField('apply_to_all_periods', false);
      handleSubmit();
    } else {
      const updated_keys = getUpdatesList();

      const updatesList = [
        ...new Set(
          (updated_keys || [])
            .map((update_key) => {
              switch (update_key) {
                case 'name':
                  return 'generatorName';
                case 'defaults.config.type':
                  return 'periodicity';
                case 'defaults.intervener':
                  return 'intervener';
                case '_managers':
                  return 'managers';
                case 'defaults.job':
                  return ['jobs', 'managers'];
                case 'description':
                  return 'description';
                case 'defaults.planning.enable':
                  return 'plannification';
                case 'defaults.alert.enable':
                  return 'reminderNotification';
                case 'defaults.planning.day':
                  return 'notificationPlannificationDaysNumber';
                case 'defaults.alert.day':
                  return 'notificationReminderDaysNumber';
                case 'defaults.autoAssign':
                  return 'autoAssignation';
                default:
                  return null;
              }
            })
            .flat() // Aplatir les arrays comme ['jobs', 'managers'] en un seul niveau
            .filter((update) => update !== null)
        )
      ];

      confirm({
        title: 'applyChangesToAllPeriods',
        helperText: 'applyChangesToAllPeriodsHelperText',
        icon: 'triangle-exclamation',
        confirmLabel: 'yes',
        declineLabel: 'no',
        nodeChildren: <UpdatesList updatesList={updatesList} />,
        onConfirm: () => [setOneField('apply_to_all_periods', true), handleSubmit()],
        onDecline: () => [setOneField('apply_to_all_periods', false), handleSubmit()]
      });
    }
  };

  return (
    <EntityWrapper
      entity={timeline ? 'periods' : 'maintenances'}
      entitySlice={timeline ? 'periods' : 'maintenances'}
      entityFieldsSlice="maintenance"
      beforeCreateOrEdit={beforeCreateOrEdit}
      timeline={timeline}
      skeletonComponent={MaintenanceSkeleton}
      previewComponent={MaintenancePreview}
      viewComponent={MaintenanceView}
      formComponent={MaintenanceForm}
      formatForm={formatForm}
      formatFilter={formatFilter}
      getFilters={MaintenanceFilters}
      getInitialValues={MaintenanceFormValues}
      getFormValidation={MaintenanceValidation}
      getTabs={MaintenanceTabs}
      getTables={MaintenanceTable}
      getMenus={MaintenanceMenus}
      getExportColumns={MaintenanceExport}
      getTranslations={MaintenanceTranslations}
      getSorts={MaintenanceSorts}
      getElement={getElement}
      getElements={timeline ? getElementsPeriods : getElements}
      updateElement={timeline ? updateElementPeriods : updateElement}
      flushElements={timeline ? flushElementsPeriods : flushElements}
      createElement={!timeline && createElement}
      deleteElement={!timeline && deleteElement}
      restoreElement={!timeline && restoreElement}
      hiddenFilters={hiddenFilters}
      {...rest}
    />
  );
}

function UpdatesList({ updatesList }) {
  const { t } = useTranslation();

  return (
    Boolean(updatesList.length) && (
      <div className="my-4 text-base">
        <span>{t('changesToBeApplied')}&nbsp;</span>
        <ul className="list-disc list-inside">
          {updatesList.map((update, index) => (
            <li key={index}>{t(update)}</li>
          ))}
        </ul>
      </div>
    )
  );
}
