import { AccordionActions, AccordionDetails, Grid, Button, IconButton } from '@material-ui/core';
// import Add from '@material-ui/icons/Add'
import CreateNewFolder from '@material-ui/icons/CreateNewFolder';
import EditIcon from '@material-ui/icons/Edit';
import DisplayMore from 'components/DisplayMore';

import AccordionForm from 'components/forms/AccordionForm';
import FieldsManager from 'fieldSections/fields/FieldsManager';
import { useSections } from 'hooks/useSections';
import { useDispatch } from 'react-redux';
import { deleteFieldSection, setFields } from 'store/fieldSectionsSlice';
import defaultFields from 'config/defaultFields';
import useKeys from '@flowsn4ke/usekeys';
import SectionEditor from 'fieldSections/fields/SectionEditor';
import { useState } from 'react';
import { DeleteForever } from '@material-ui/icons';
import useNotifications from 'hooks/useNotifications';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import ConfirmDialog from 'components/dialogs/ConfirmDialog';
import { useTranslation } from 'react-i18next';
import { fieldTypes } from 'fieldSections/fieldTypes';

export default function CustomField({ section }) {
  const [element, setElement] = useState();
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  const validFieldTypes = Object.keys(fieldTypes);

  const sections = useSections();
  const syncDispatch = useDispatch();
  const k = useKeys();
  const { t } = useTranslation();

  const notify = useNotifications();
  const { dispatch } = useAsyncDispatch();
  const dispatchCallbacks = ({ success, error }) => ({
    onSuccess: () => notify.success(success),
    onError: () => notify.error(error)
  });

  return (
    <AccordionForm
      section={section}
      defaultClose
      noForm
    >
      <SectionEditor
        open={!!element}
        onClose={() => setElement(undefined)}
        entity={section.entity}
        id={element?.id}
        labelValue={element?.label}
      />
      <ConfirmDialog
        text={<span>{t('doYouReallyWantToDownloadSection')}</span>}
        open={!!confirmDeleteOpen}
        onClose={() => [setConfirmDeleteOpen(undefined)]}
        onConfirm={confirmDeleteOpen?.onConfirm}
      />
      <AccordionDetails>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            {(sections[section.entity] || []).map((s, i) => {
              const fields = defaultFields[section.entity].filter((f) =>
                validFieldTypes.includes(f.valueType)
              );

              return (
                <DisplayMore
                  display={sections[section.entity]?.length < 2}
                  key={k(i)}
                  label={i === 0 && !s?.label ? 'Général' : s.label}
                  secondaryActionButton={
                    <IconButton
                      onMouseDown={(e) => e.stopPropagation()}
                      onClick={(e) => [
                        e.stopPropagation(),
                        setConfirmDeleteOpen({
                          onConfirm: () =>
                            dispatch(
                              deleteFieldSection,
                              undefined,
                              dispatchCallbacks({ success: t('deletedSection') }),
                              { id: s._id, entity: section.entity }
                            )
                        })
                      ]}
                    >
                      <DeleteForever />
                    </IconButton>
                  }
                  thirdActionButton={
                    <IconButton
                      onMouseDown={(e) => e.stopPropagation()}
                      onClick={(e) => {
                        e.stopPropagation();
                        setElement({ id: s._id, label: s.label });
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  }
                >
                  <FieldsManager
                    sectionId={s._id}
                    fields={i === 0 ? fields.concat(s.fields) : s.fields}
                    editFrom={i === 0 ? fields?.length : 0}
                    setFields={(next) =>
                      syncDispatch(setFields({ entity: section.entity, next, index: i }))
                    }
                    sectionEntity={section.entity === 'ticket' ? 'ticket' : undefined}
                  />
                </DisplayMore>
              );
            })}
          </Grid>
        </Grid>
      </AccordionDetails>
      <AccordionActions>
        <Button
          startIcon={<CreateNewFolder />}
          size="small"
          onClick={() => setElement('new')}
        >
          {t('section')}
        </Button>
      </AccordionActions>
    </AccordionForm>
  );
}
