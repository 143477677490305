import { Box, Grid, Chip, List } from '@material-ui/core';
import { useConfiguration } from 'hooks/useConfiguration';

import { equipmentWear } from 'lists/equipmentWear';
import { dateToLocalFormat } from 'utils/dates';
import useStyles from 'layouts/entities/View.styles';
import { ClientItem, DescriptionItem, LocationItem } from 'components/views/ViewItems';
import CustomFields from '../CustomFields';
import ViewItem from 'components/views/ViewItem';
import useKeys from '@flowsn4ke/usekeys';
import { useTranslation } from 'react-i18next';
import { operational_states_equipment } from 'lists/operational_states_equipment';
import { status_of_equipment } from 'lists/status_of_equipment';

export default function EquipmentView({ element }) {
  const classes = useStyles();
  const configuration = useConfiguration();
  const { t } = useTranslation();
  const k = useKeys();

  const label_status = status_of_equipment.find((s) => s.value === element.status)?.label;
  const label_operational_state = operational_states_equipment.find(
    (s) => s.value === element.operational_state
  )?.label;

  return (
    <>
      <Box className={classes.l1}>
        <Box
          alignItems="center"
          display="flex"
        >
          <Box
            flexDirection="column"
            alignItems="flex-start"
          >
            <Box className={classes.l1left}>{element.name}</Box>

            {(element._category || element._subcategory) && (
              <Box
                display="flex"
                alignItems="center"
                marginTop="10px"
              >
                {element._category && (
                  <Chip
                    className={classes.l1chip}
                    color="secondary"
                    variant="outlined"
                    size="small"
                    label={element._category.name}
                  />
                )}
                {element._subcategory && (
                  <Chip
                    className={classes.l1chip}
                    color="secondary"
                    variant="outlined"
                    size="small"
                    label={element._subcategory.name}
                  />
                )}
                {element.isContract && <strong>{t('underContract')}</strong>}
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      <Grid
        className="mt-2"
        container
      >
        <Grid
          item
          xs={12}
          sm={element._cover ? 6 : 12}
        >
          <List>
            <CustomFields element={element}>
              {configuration.isContractor && <ClientItem client={element._client} />}

              <DescriptionItem description={element.description} />

              {!!element._jobs?.length && (
                <ViewItem
                  icon="toolbox"
                  secondaryIcon
                  primary={t('jobs')}
                  secondary={element._jobs.map((j) => t(j.name)).join(', ')}
                />
              )}

              <LocationItem location={element._location} />

              {element.state && t(equipmentWear[element.state]) && (
                <ViewItem
                  icon="asterisk"
                  secondaryIcon
                  primary={t('dilapidationState')}
                  secondary={t(equipmentWear[element.state].label)}
                />
              )}

              {element?.operational_state && t(label_operational_state) && (
                <ViewItem
                  icon="asterisk"
                  secondaryIcon
                  primary={t('operationalState')}
                  secondary={t(label_operational_state)}
                />
              )}

              {element?.status && t(label_status) && (
                <ViewItem
                  icon="asterisk"
                  secondaryIcon
                  primary={t('statusOfEquipment')}
                  secondary={t(label_status)}
                />
              )}

              {!!element.brand?.length && (
                <ViewItem
                  icon="barcode"
                  secondaryIcon
                  primary={t('brand')}
                  secondary={element.brand}
                />
              )}

              {!!element.model?.length && (
                <ViewItem
                  icon="barcode"
                  secondaryIcon
                  primary={t('model')}
                  secondary={element.model}
                />
              )}

              {!!element.serial?.length && (
                <ViewItem
                  icon="barcode"
                  secondaryIcon
                  primary={t('serialNumber')}
                  secondary={element.serial}
                />
              )}

              {element.revision_date && (
                <ViewItem
                  icon="calendar"
                  secondaryIcon
                  primary={t('revisionDate')}
                  secondary={dateToLocalFormat(element.revision_date, 'PP')}
                />
              )}

              {element.replacement_date && (
                <ViewItem
                  icon="calendar"
                  secondaryIcon
                  primary={t('planned-replacement-date')}
                  secondary={dateToLocalFormat(element.replacement_date, 'PP')}
                />
              )}

              {!!element.year?.length && (
                <ViewItem
                  icon="calendar"
                  secondaryIcon
                  primary={t('year')}
                  secondary={element.year}
                />
              )}

              {element.buy_date && (
                <ViewItem
                  icon="calendar"
                  secondaryIcon
                  primary={t('purchaseDate')}
                  secondary={dateToLocalFormat(element.buy_date, 'PP')}
                />
              )}
            </CustomFields>
          </List>
        </Grid>
        {element._cover && (
          <Grid
            item
            xs={12}
            sm={6}
          >
            <div
              style={{
                backgroundImage: `url(${typeof element._cover === 'string' ? element._cover : element._cover.url})`
              }}
              className="w-full h-full bg-contain bg-no-repeat bg-center"
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}
