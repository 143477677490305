import { Dialog as HUIDialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import classNames from 'utils/classNames';
import FAIcon from './ui/FAIcon';

export default function Dialog({
  icon = 'asterisk',
  width,
  className,
  isOpen,
  onClose,
  title,
  titleRight,
  children
}) {
  return (
    <>
      <Transition
        appear
        show={isOpen}
        as={Fragment}
      >
        <HUIDialog
          as="div"
          className={classNames('relative z-10', className)}
          onClose={onClose}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <HUIDialog.Panel
                  className={classNames(
                    'w-full isolate transform rounded-xl bg-white p-6 text-left align-middle shadow-xl transition-all',
                    sizes[width] || sizes.md
                  )}
                >
                  <HUIDialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-sky-900"
                  >
                    <div className="flex justify-between items-center">
                      <div className="flex items-center">
                        <FAIcon
                          collection="fas"
                          icon={icon}
                          size="md"
                          className="mr-2"
                        />
                        {title}
                      </div>
                      {titleRight}
                    </div>
                  </HUIDialog.Title>
                  {children}
                </HUIDialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </HUIDialog>
      </Transition>
    </>
  );
}

const sizes = {
  '3xs': 'max-w-3xs',
  '2xs': 'max-w-2xs',
  xs: 'max-w-xs',
  sm: 'max-w-sm',
  md: 'max-w-md',
  lg: 'max-w-lg',
  xl: 'max-w-xl',
  '2xl': 'max-w-2xl',
  '3xl': 'max-w-3xl',
  '4xl': 'max-w-4xl',
  '5xl': 'max-w-5xl',
  '6xl': 'max-w-6xl',
  '7xl': 'max-w-7xl'
};
