const JobFilters = (configuration, defaultFilters) => [].filter((e) => e.show);

export const formatFilter = (filters, auth) => {
  if (!!auth.interface._jobs.length) {
    filters['only'] = auth.interface._jobs;
  }

  return filters;
};

export default JobFilters;
