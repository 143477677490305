import {
  TextField,
  AccordionActions,
  AccordionDetails,
  Grid,
  Box,
  Button,
  FormControlLabel,
  Checkbox,
  FormHelperText
} from '@material-ui/core';

import { useRef } from 'react';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import useNotifications from 'hooks/useNotifications';
import { useAuth } from 'hooks/useAuth';
import { setInfo, setUserAvatar } from 'store/authSlice';
import { Submit, Field, Reset } from 'frmx';

import AccordionForm from 'components/forms/AccordionForm';
import PhoneField from 'components/inputs/PhoneField';
import Avatar from 'components/Avatar';

import useFormStyles from 'styles/Form.styles';
import useAvatarStyles from './AvatarInfo.styles';
import { useTranslation } from 'react-i18next';
import { useRole } from 'hooks/useRole';
import { useSelector } from 'react-redux';
import { LangSelect } from 'entities/User/UserForm';

export function UserForm({ user_is_root_account }) {
  const { permission } = useRole();

  const userCanModidyHisInfo = permission('bobdesk', 'update_account_info');
  const is_created_by_signup = useSelector((state) => state?.auth?.is_created_by_signup);

  const { t } = useTranslation();
  return (
    <Grid
      container
      spacing={2}
    >
      <Grid
        item
        xs={12}
      >
        <Field
          path="firstName"
          disabled={!userCanModidyHisInfo}
        >
          <TextField
            variant="outlined"
            fullWidth
            label={t('firstName')}
          />
        </Field>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Field
          path="lastName"
          disabled={!userCanModidyHisInfo}
        >
          <TextField
            variant="outlined"
            fullWidth
            label={t('lastName')}
          />
        </Field>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Field
          path="email"
          disabled={!userCanModidyHisInfo}
        >
          <TextField
            variant="outlined"
            fullWidth
            label={t('email')}
          />
        </Field>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <PhoneField
          path="phone"
          label={t('phoneNumber')}
          variant="outlined"
          placeholder={t('phoneNumber')}
          disabled={!userCanModidyHisInfo}
        />
      </Grid>

      <Grid
        item
        xs={12}
      >
        <LangSelect
          path="lang"
          label={t('lang')}
        />
        <FormHelperText>{t('starter-lang-helper')}</FormHelperText>
      </Grid>

      {user_is_root_account && is_created_by_signup && (
        <Grid
          item
          xs={12}
        >
          <FormControlLabel
            control={
              <Field
                path="fake_data"
                type="checkbox"
              >
                <Checkbox />
              </Field>
            }
            label={t('doYouWantToGenerateFakeData')}
          />
        </Grid>
      )}
    </Grid>
  );
}

export default function UserInfo({ section, isFirstConnect }) {
  const { dispatch, requestStatus } = useAsyncDispatch();
  const auth = useAuth();
  const notify = useNotifications();

  const refFile = useRef(null);

  const onChangeFile = (e) => {
    if (e.target.files?.length && e.target.files[0]) {
      const formData = new FormData();
      formData.append('avatar', e.target.files[0]);
      dispatch(setUserAvatar, { formData });
    }
  };

  const dispatchCallbacks = {
    onSuccess: () => notify.success(),
    onError: () => notify.error()
  };

  const formClasses = useFormStyles();
  const avatarClasses = useAvatarStyles();

  const { t } = useTranslation();

  return (
    <AccordionForm
      className={avatarClasses.accordionAvatar}
      section={section}
      initialValues={{
        firstName: auth.user?.firstName || '',
        lastName: auth.user?.lastName || '',
        email: auth.user?.email || '',
        phone: auth.user?.phone || ''
      }}
      onSubmit={(updates) => dispatch(setInfo, updates, dispatchCallbacks)}
      allwaysExpanded
    >
      <AccordionDetails>
        <Box className={avatarClasses.avatar}>
          <Avatar
            isLoading={requestStatus === 'loading'}
            onClick={() => refFile.current.click()}
            isUser
            isUpdate
            button
            round
            entity={auth.user}
            size={'large2'}
          />
        </Box>
        <Box style={{ paddingTop: 42, width: '100%' }}>
          <input
            type="file"
            accept="image/*"
            ref={refFile}
            className={avatarClasses.file}
            onChange={onChangeFile}
          />
          <UserForm />
        </Box>
      </AccordionDetails>

      <AccordionActions>
        <Reset>
          <Button size="small">{t('cancel')}</Button>
        </Reset>

        <Submit disabled={requestStatus === 'loading'}>
          <Button
            className={formClasses.success}
            size="small"
          >
            {t('save')}
          </Button>
        </Submit>
      </AccordionActions>
    </AccordionForm>
  );
}
