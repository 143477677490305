export const zones = [
  {
    zone_id: '634580400765c66927c3b159',
    label: 'France entière',
    children_ids: [
      '634580528224e30b2b7fadba',
      '634580654b0de44a370a6583',
      '6345806c519a7117e26d7fe4',
      '634580722faa407ffaebc968',
      '63458078d3bb94db4c0d2e27',
      '6345807f6d1049e32e2e5354',
      '6345808997bc1f71ace9b7ba',
      '6345808f8851c9e5ecdc3816',
      '634580950acf94535caed33b',
      '6345809bcd140b65ef0b32d9',
      '634580a23f017708f6705cdd',
      '634580a9a66d6a5688f4993e',
      '634580aef8bf06f8a2823f2d',
      '634580ca2b179bb7af7cb613',
      '634580d44e37f5e1918ae561',
      '634580dc47b5023d5145f80a',
      '634580e21f0b228dca5ec7d4',
      '634580ebac45c81126b9a955',
      '634580f2cf6df3e7d9ea3f82',
      '634580f651b97905b48a5652',
      '634580fc3cc5bff1c00259ee',
      '63458102c8175ad6c1d52e3c',
      '63458107b7401f4c942ce8d5',
      '63458111e0eedd978945d9b6',
      '63458119872ac1e36201bcc8',
      '6345811eef88bb103eaaf2e7',
      '634581251d4a6b9bd63b8132',
      '6345812e0ad61cd47c587430',
      '63458134e0642b63c8371939',
      '6345813910dd29ec0ae843a4',
      '63458141e12487bacb6f4d52',
      '634581473e1114cac957b8cf',
      '6345814ca23fc3dbc11c222d',
      '6345815231dbce7026d98e68',
      '6345815823252c5818e374fa',
      '6345815e07334c45631a888c',
      '634581647e1d33e29c5081dd',
      '6345838bddd625ae99516b84',
      '63458176f97c0fe94e688f68',
      '6345817b014af7c9b5560591',
      '6345818139834ddc697512d7',
      '63458187a95de3009329694f',
      '6345818d1b5ab9c3478666b9',
      '63458192ccd54328a86a2d1b',
      '634581972ffea04c8b8c3282',
      '6345819c02317c41757b108d',
      '634581a2d8e16c7111d6cb8c',
      '634581aa4e30b8e1c966879a',
      '634581af0c52f578fe52a44d',
      '634581b5f7e8d355eff28035',
      '634581ba67aac63946b00350',
      '634581c04770d9928c603833',
      '634581c5a6251edcfdd422ff',
      '634581d0cafc62a20cb94c2f',
      '634581dc8334cdf5110631a6',
      '634581e2b75ef8dd9aab04ff',
      '634581eadaeafca13a51034f',
      '634581f1aa42de220a606b5c',
      '634581fb10dd2cba028e84c8',
      '63458205612c8f19ed991536',
      '6345820b6eb869e0f330f64f',
      '63458210673f491871919dc4',
      '634582157f155e36ebbf07de',
      '6345821d56bdb0ca0dfd2755',
      '634582262e50948765b541b1',
      '6345822ca4b26bfdcff3c2fa',
      '63458234aeb282e160f4eeec',
      '63458239a2ee3795d7a7ce71',
      '6345823e1d3b8d289284948a',
      '634582441f955e230a84a945',
      '6345824903bfe30adafc8501',
      '634582543949ba7f2e4bdd7a',
      '6345825d15e80f6057802765',
      '6345826271dfe128bc426ed1',
      '634582678e61447f15073d64',
      '6345826d89f36bf4389bfb52',
      '634582792f46bb3b8131f1aa',
      '6345828002532a13a9fe0316',
      '63458285572130f14282bdf6',
      '6345828bc9373bad87df26d9',
      '63458291f00dbdaece69fa4c',
      '6345829a09afd7ba63b21243',
      '6345829f543e95adc55e01cf',
      '634582a69197873dee60c64c',
      '634582acff140381b5a7279c',
      '634582b3ccec465117669875',
      '634582b924623f0398ec8ca7',
      '634582c0e68a78744693e969',
      '634582c68984e6eb02bb56a4',
      '634582d1534e7d912aa7dfaa',
      '634582d723cf002b4942ef53',
      '634582dd13b436e5b21da325',
      '634582e376447b4c9180dcf7',
      '634582ea65be0c660f6f086f',
      '634582efee04d4e60d193763',
      '634582f83245036b0807c8b9',
      '634582fd56388ad2d4114e56',
      '6345830216debede3626f315',
      '63458307e854ced8616c550a',
      '6345830c9c7f5c4e72a316c4',
      '634583112d75293bae0c7235',
      '63458f4a844263a9efe3f627',
      '63458fefa4dfb8a43d00c52f'
    ]
  },
  {
    zone_id: '634580528224e30b2b7fadba',
    label: 'Ain (01)'
  },
  {
    zone_id: '634580654b0de44a370a6583',
    label: 'Aisne (02)'
  },
  {
    zone_id: '6345806c519a7117e26d7fe4',
    label: 'Allier (03)'
  },
  {
    zone_id: '634580722faa407ffaebc968',
    label: 'Alpes-de-Haute-Provence (04)'
  },
  {
    zone_id: '63458078d3bb94db4c0d2e27',
    label: 'Hautes-Alpes (05)'
  },
  {
    zone_id: '6345807f6d1049e32e2e5354',
    label: 'Alpes-Maritimes (06)'
  },
  {
    zone_id: '6345808997bc1f71ace9b7ba',
    label: 'Ardèche (07)'
  },
  {
    zone_id: '6345808f8851c9e5ecdc3816',
    label: 'Ardennes (08)'
  },
  {
    zone_id: '634580950acf94535caed33b',
    label: 'Ariège (09)'
  },
  {
    zone_id: '6345809bcd140b65ef0b32d9',
    label: 'Aube (10)'
  },
  {
    zone_id: '634580a23f017708f6705cdd',
    label: 'Aude (11)'
  },
  {
    zone_id: '634580a9a66d6a5688f4993e',
    label: 'Aveyron (12)'
  },
  {
    zone_id: '634580aef8bf06f8a2823f2d',
    label: 'Bouches-du-Rhône (13)'
  },
  {
    zone_id: '634580ca2b179bb7af7cb613',
    label: 'Calvados (14)'
  },
  {
    zone_id: '634580d44e37f5e1918ae561',
    label: 'Cantal (15)'
  },
  {
    zone_id: '634580dc47b5023d5145f80a',
    label: 'Charente (16)'
  },
  {
    zone_id: '634580e21f0b228dca5ec7d4',
    label: 'Charente-Maritime (17)'
  },
  {
    zone_id: '634580ebac45c81126b9a955',
    label: 'Cher (18)'
  },
  {
    zone_id: '634580f2cf6df3e7d9ea3f82',
    label: 'Corrèze (19)'
  },
  {
    zone_id: '634580f651b97905b48a5652',
    label: "Côte-d'or (21)"
  },
  {
    zone_id: '634580fc3cc5bff1c00259ee',
    label: "Côtes-d'armor (22)"
  },
  {
    zone_id: '63458102c8175ad6c1d52e3c',
    label: 'Creuse (23)'
  },
  {
    zone_id: '63458107b7401f4c942ce8d5',
    label: 'Dordogne (24)'
  },
  {
    zone_id: '63458111e0eedd978945d9b6',
    label: 'Doubs (25)'
  },
  {
    zone_id: '63458119872ac1e36201bcc8',
    label: 'Drôme (26)'
  },
  {
    zone_id: '6345811eef88bb103eaaf2e7',
    label: 'Eure (27)'
  },
  {
    zone_id: '634581251d4a6b9bd63b8132',
    label: 'Eure-et-Loir (28)'
  },
  {
    zone_id: '6345812e0ad61cd47c587430',
    label: 'Finistère (29)'
  },
  {
    zone_id: '63458134e0642b63c8371939',
    label: 'Corse-du-Sud (2a)'
  },
  {
    zone_id: '6345813910dd29ec0ae843a4',
    label: 'Haute-Corse (2b)'
  },
  {
    zone_id: '63458141e12487bacb6f4d52',
    label: 'Gard (30)'
  },
  {
    zone_id: '634581473e1114cac957b8cf',
    label: 'Haute-Garonne (31)'
  },
  {
    zone_id: '6345814ca23fc3dbc11c222d',
    label: 'Gers (32)'
  },
  {
    zone_id: '6345815231dbce7026d98e68',
    label: 'Gironde (33)'
  },
  {
    zone_id: '6345815823252c5818e374fa',
    label: 'Hérault (34)'
  },
  {
    zone_id: '6345815e07334c45631a888c',
    label: 'Ille-et-Vilaine (35)'
  },
  {
    zone_id: '634581647e1d33e29c5081dd',
    label: 'Indre (36)'
  },
  {
    zone_id: '6345838bddd625ae99516b84',
    label: 'Indre-et-Loire (37)'
  },
  {
    zone_id: '63458176f97c0fe94e688f68',
    label: 'Isère (38)'
  },
  {
    zone_id: '6345817b014af7c9b5560591',
    label: 'Jura (39)'
  },
  {
    zone_id: '6345818139834ddc697512d7',
    label: 'Landes (40)'
  },
  {
    zone_id: '63458187a95de3009329694f',
    label: 'Loir-et-Cher (41)'
  },
  {
    zone_id: '6345818d1b5ab9c3478666b9',
    label: 'Loire (42)'
  },
  {
    zone_id: '63458192ccd54328a86a2d1b',
    label: 'Haute-Loire (43)'
  },
  {
    zone_id: '634581972ffea04c8b8c3282',
    label: 'Loire-Atlantique (44)'
  },
  {
    zone_id: '6345819c02317c41757b108d',
    label: 'Loiret (45)'
  },
  {
    zone_id: '634581a2d8e16c7111d6cb8c',
    label: 'Lot (46)'
  },
  {
    zone_id: '634581aa4e30b8e1c966879a',
    label: 'Lot-et-Garonne (47)'
  },
  {
    zone_id: '634581af0c52f578fe52a44d',
    label: 'Lozère (48)'
  },
  {
    zone_id: '634581b5f7e8d355eff28035',
    label: 'Maine-et-Loire (49)'
  },
  {
    zone_id: '634581ba67aac63946b00350',
    label: 'Manche (50)'
  },
  {
    zone_id: '634581c04770d9928c603833',
    label: 'Marne (51)'
  },
  {
    zone_id: '634581c5a6251edcfdd422ff',
    label: 'Haute-Marne (52)'
  },
  {
    zone_id: '634581d0cafc62a20cb94c2f',
    label: 'Mayenne (53)'
  },
  {
    zone_id: '634581dc8334cdf5110631a6',
    label: 'Meurthe-et-Moselle (54)'
  },
  {
    zone_id: '634581e2b75ef8dd9aab04ff',
    label: 'Meuse (55)'
  },
  {
    zone_id: '634581eadaeafca13a51034f',
    label: 'Morbihan (56)'
  },
  {
    zone_id: '634581f1aa42de220a606b5c',
    label: 'Moselle (57)'
  },
  {
    zone_id: '634581fb10dd2cba028e84c8',
    label: 'Nièvre (58)'
  },
  {
    zone_id: '63458205612c8f19ed991536',
    label: 'Nord (59)'
  },
  {
    zone_id: '6345820b6eb869e0f330f64f',
    label: 'Oise (60)'
  },
  {
    zone_id: '63458210673f491871919dc4',
    label: 'Orne (61)'
  },
  {
    zone_id: '634582157f155e36ebbf07de',
    label: 'Pas-de-Calais (62)'
  },
  {
    zone_id: '6345821d56bdb0ca0dfd2755',
    label: 'Puy-de-Dôme (63)'
  },
  {
    zone_id: '634582262e50948765b541b1',
    label: 'Pyrénées-Atlantiques (64)'
  },
  {
    zone_id: '6345822ca4b26bfdcff3c2fa',
    label: 'Hautes-Pyrénées (65)'
  },
  {
    zone_id: '63458234aeb282e160f4eeec',
    label: 'Pyrénées-Orientales (66)'
  },
  {
    zone_id: '63458239a2ee3795d7a7ce71',
    label: 'Bas-Rhin (67)'
  },
  {
    zone_id: '6345823e1d3b8d289284948a',
    label: 'Haut-Rhin (68)'
  },
  {
    zone_id: '634582441f955e230a84a945',
    label: 'Rhône (69)'
  },
  {
    zone_id: '6345824903bfe30adafc8501',
    label: 'Haute-Saône (70)'
  },
  {
    zone_id: '634582543949ba7f2e4bdd7a',
    label: 'Saône-et-Loire (71)'
  },
  {
    zone_id: '6345825d15e80f6057802765',
    label: 'Sarthe (72)'
  },
  {
    zone_id: '6345826271dfe128bc426ed1',
    label: 'Savoie (73)'
  },
  {
    zone_id: '634582678e61447f15073d64',
    label: 'Haute-Savoie (74)'
  },
  {
    zone_id: '6345826d89f36bf4389bfb52',
    label: 'Paris (75)'
  },
  {
    zone_id: '634582792f46bb3b8131f1aa',
    label: 'Seine-Maritime (76)'
  },
  {
    zone_id: '6345828002532a13a9fe0316',
    label: 'Seine-et-Marne (77)'
  },
  {
    zone_id: '63458285572130f14282bdf6',
    label: 'Yvelines (78)'
  },
  {
    zone_id: '6345828bc9373bad87df26d9',
    label: 'Deux-Sèvres (79)'
  },
  {
    zone_id: '63458291f00dbdaece69fa4c',
    label: 'Somme (80)'
  },
  {
    zone_id: '6345829a09afd7ba63b21243',
    label: 'Tarn (81)'
  },
  {
    zone_id: '6345829f543e95adc55e01cf',
    label: 'Tarn-et-Garonne (82)'
  },
  {
    zone_id: '634582a69197873dee60c64c',
    label: 'Var (83)'
  },
  {
    zone_id: '634582acff140381b5a7279c',
    label: 'Vaucluse (84)'
  },
  {
    zone_id: '634582b3ccec465117669875',
    label: 'Vendée (85)'
  },
  {
    zone_id: '634582b924623f0398ec8ca7',
    label: 'Vienne (86)'
  },
  {
    zone_id: '634582c0e68a78744693e969',
    label: 'Haute-Vienne (87)'
  },
  {
    zone_id: '634582c68984e6eb02bb56a4',
    label: 'Vosges (88)'
  },
  {
    zone_id: '634582d1534e7d912aa7dfaa',
    label: 'Yonne (89)'
  },
  {
    zone_id: '634582d723cf002b4942ef53',
    label: 'Territoire de Belfort (90)'
  },
  {
    zone_id: '634582dd13b436e5b21da325',
    label: 'Essonne (91)'
  },
  {
    zone_id: '634582e376447b4c9180dcf7',
    label: 'Hauts-de-Seine (92)'
  },
  {
    zone_id: '634582ea65be0c660f6f086f',
    label: 'Seine-Saint-Denis (93)'
  },
  {
    zone_id: '634582efee04d4e60d193763',
    label: 'Val-de-Marne (94)'
  },
  {
    zone_id: '634582f83245036b0807c8b9',
    label: "Val-d'oise (95)"
  },
  {
    zone_id: '634582fd56388ad2d4114e56',
    label: 'Guadeloupe (971)'
  },
  {
    zone_id: '6345830216debede3626f315',
    label: 'Martinique (972)'
  },
  {
    zone_id: '63458307e854ced8616c550a',
    label: 'Guyane (973)'
  },
  {
    zone_id: '6345830c9c7f5c4e72a316c4',
    label: 'La Réunion (974)'
  },
  {
    zone_id: '634583112d75293bae0c7235',
    label: 'Mayotte (976)'
  },
  {
    zone_id: '63458f4a844263a9efe3f627',
    label: 'Polynésie française (987)'
  },
  {
    zone_id: '63458fefa4dfb8a43d00c52f',
    label: 'Nouvelle-Calédonie (988)'
  },
  {
    zone_id: '6346904209ec263bc408d8d1',
    label: 'Allemagne'
  },
  {
    zone_id: '63469055e3ed444697387d0a',
    label: 'Belgique'
  },
  {
    zone_id: '6346906c66fac5c806ccdaeb',
    label: 'Espagne'
  },
  {
    zone_id: '6346907fbaa53b522117f655',
    label: 'États-Unis'
  },
  {
    zone_id: '63469092060cf1df929d8707',
    label: 'Italie'
  },
  {
    zone_id: '634690ab908cecd5663ace6d',
    label: 'Luxembourg'
  },
  {
    zone_id: '634690c1be04e75805ec0945',
    label: 'Pays-Bas'
  },
  {
    zone_id: '634690d543647a457e95ecad',
    label: 'Portugal'
  },
  {
    zone_id: '634690e5b5393995ee7189c2',
    label: 'Royaume-Uni'
  },
  {
    zone_id: '634690f9188f47bc1a66051c',
    label: 'Sénégal'
  },
  {
    zone_id: '634572ead9a00d8e3d032da1',
    label: 'International'
  },
  {
    label: 'Maroc',
    zone_id: '6346c6a4951830fed76f4be1'
  },
  {
    label: 'Maurice',
    zone_id: '6346c6ae4a36cacdc20e7c0e'
  },
  {
    label: 'Pakistan',
    zone_id: '6346c6d21865c7dba7a9b5eb'
  },
  {
    label: 'Canada',
    zone_id: '6346c6d8320f02e9a39472d6'
  },
  {
    label: 'Monaco',
    zone_id: '6346c6db9541e0040671f6f5'
  },
  {
    label: 'République du Congo',
    zone_id: '6346c6dee09e5c6d1c31d7fe'
  },
  {
    label: 'Andorre',
    zone_id: '6346c6e488659da8c0935672'
  },
  {
    label: 'Mayotte',
    zone_id: '6346c6e8f0622bdf3fc04e7c'
  },
  {
    label: 'Togo',
    zone_id: '6346c6ec3234ac9231b6b636'
  },
  {
    label: 'Tunisie',
    zone_id: '6346c6f2277d053b38c64818'
  },
  {
    label: 'Chili',
    zone_id: '6346c6f61ae6b39fba8fc2fc'
  },
  {
    label: 'Vietnam',
    zone_id: '6346c6f94e63cf2a00490199'
  },
  {
    label: 'Djibouti',
    zone_id: '6346c6fde55de9c04340ccc0'
  },
  {
    label: 'Gabon',
    zone_id: '6346c701e5d69813ed3eb524'
  },
  {
    label: "Côte d'Ivoire",
    zone_id: '6346c704560b472b5e79804d'
  },
  {
    label: 'Algérie',
    zone_id: '6346c7f46c7bfa731576542c'
  },
  {
    label: 'Suisse',
    zone_id: '655f78fe6ad01bf7921bc2fe'
  },
  {
    zone_id: '65799585439a3c67798ab52d',
    label: 'Danemark'
  },
  {
    zone_id: '6579958c06d72aa8fe73bd33',
    label: 'Irlande'
  },
  {
    zone_id: '6579959ca150ebf17c3daf28',
    label: 'Autriche'
  }
];

export const zonesForFilters = zones.map((z) => {
  return {
    value: z.zone_id,
    label: z.label
  };
});
