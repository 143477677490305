import { useState, useEffect } from 'react';
import { TextField, ListItemText, Button, ListItem, InputAdornment, List } from '@material-ui/core';
import { Add, Save } from '@material-ui/icons';
import PopoverDrawer from 'components/ui/PopoverDrawer';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import { useTranslation } from 'react-i18next';
import useNotifications from 'hooks/useNotifications';
import { updateBudgetsUse } from 'store/budgetsSlice';
import BudgetsSelectDialog from './BudgetsSelectDialog';
import { useSelector } from 'react-redux';
import ConsumptionsBudget from './ConsumptionsBudget';
import FAIcon from 'components/ui/FAIcon';
import { useAuth } from 'hooks/useAuth';

export default function PopoverBudget({
  isOpenBudgets,
  setIsOpenBudgets,
  budgetRef,
  ticket_id,
  intervention_price
}) {
  const { t } = useTranslation();
  const { dispatch } = useAsyncDispatch();
  const notify = useNotifications();
  const budgets = useSelector((store) => store.tickets.db[ticket_id]?.budgets);
  const auth = useAuth();
  const company = auth.interface._company;

  const [budgetsState, setBudgetsState] = useState(budgets);

  const [loadingConsumptions, setLoadingConsumptions] = useState(false);
  const [consumptions, setConsumptions] = useState([]);
  const [isAmountExceeded, setIsAmountExceeded] = useState(false);
  const [openModalBudgetsSelect, setOpenModalBudgetsSelect] = useState(false);

  const handleSubmitAmountBudgets = (data) => {
    const amountBugdetsValues = budgets.map((budget) => {
      const new_amount = data.find(({ budget_id }) => budget_id === budget.budget_id)?.add_amount;

      return {
        ...budget,
        add_amount: Number(new_amount) || 0,
        remove_amount: budget.add_amount
      };
    });

    return dispatch(
      updateBudgetsUse,
      {
        budgets: amountBugdetsValues,
        ticket_id
      },
      {
        onSuccess: () => [notify.success(t('budgetsUpdated')), setIsOpenBudgets(false)],
        onError: () => notify.error()
      }
    );
  };

  useEffect(() => {
    return setIsAmountExceeded(
      (budgetsState || []).reduce((acc, { add_amount }) => {
        return acc + Number(add_amount);
      }, 0) > intervention_price
    );
  }, [budgetsState, intervention_price]);

  return (
    <PopoverDrawer
      open={isOpenBudgets}
      onClose={() => setIsOpenBudgets(false)}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      transitionDuration={0}
      style={{ width: budgetRef.current?.offsetWidth }}
      anchorEl={budgetRef.current}
    >
      <div className="w-full m-1 drop-shadow-2xl">
        <div className="flex items-center">
          <div className="flex items-center m-2">
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenModalBudgetsSelect(true)}
              endIcon={<Add />}
            >
              {t('addBudgets')}
            </Button>
          </div>
          <div className="w-1/2 flex items-center justify-center font-bold text-center text-lg">
            <FAIcon
              collection="fas"
              icon="wallet"
              size="md"
              className="mr-2"
            />
            {t('budgetsTitle')}
          </div>
          <div className="mx-auto flex items-center text-sm">
            {`MT Intervention : ${intervention_price} ${company?.currency?.symbol ? company?.currency?.symbol : '€'}`}
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col">
        <List className="h-[300px] overflow-auto">
          {Boolean(budgets?.length) &&
            budgets?.map((budget, budget_idx) => {
              return (
                <BudgetItem
                  key={budget.budget_id}
                  budget={budget}
                  budget_idx={budget_idx}
                  budgets={budgets}
                  loadingConsumptions={loadingConsumptions}
                  consumptions={consumptions}
                  setLoadingConsumptions={setLoadingConsumptions}
                  setConsumptions={setConsumptions}
                  setBudgetsState={setBudgetsState}
                />
              );
            })}
        </List>
        <div className={`m-1 flex ${isAmountExceeded ? 'justify-around' : 'justify-end'}`}>
          {isAmountExceeded && (
            <div className="w-full mx-auto text-red-500 flex justify-center items-center font-bold text-sm">
              {t('budgetsAmountExceed')}
            </div>
          )}
          <div className="w-1/3 flex justify-end mr-[14px]">
            <Button
              endIcon={<Save />}
              color="primary"
              variant="contained"
              disabled={
                budgetsState.reduce((acc, { add_amount }) => acc + Number(add_amount), 0) < 0 ||
                Boolean(budgetsState.find(({ add_amount }) => add_amount < 0))
              }
              onClick={() => handleSubmitAmountBudgets(budgetsState)}
            >
              {t('save2')}
            </Button>
          </div>
        </div>
      </div>

      <BudgetsSelectDialog
        openModalBudgetsSelect={openModalBudgetsSelect}
        setOpenModalBudgetsSelect={setOpenModalBudgetsSelect}
        budgets={budgets}
        ticket_id={ticket_id}
      />
    </PopoverDrawer>
  );
}

function BudgetItem({
  budget_idx,
  budget,
  budgets,
  loadingConsumptions,
  consumptions,
  setLoadingConsumptions,
  setConsumptions,
  setBudgetsState
}) {
  const { t } = useTranslation();
  const auth = useAuth();
  const company = auth.interface._company;

  const [valueAmount, setValueAmount] = useState(budget.add_amount);
  const budget_title = budget.title?.toUpperCase() || 'Non défini';

  return (
    <ListItem className={`${budget_idx % 2 === 0 ? 'bg-gray-50' : 'bg-gray-100'}`}>
      <ListItemText
        primary={
          <strong>
            {budget_title} - {budget?.year}
          </strong>
        }
        className="w-full"
      />
      <ListItemText className="w-full">
        <ConsumptionsBudget
          budget={budget}
          budgets={budgets}
          loadingConsumptions={loadingConsumptions}
          consumptions={consumptions}
          setLoadingConsumptions={setLoadingConsumptions}
          setConsumptions={setConsumptions}
        />
      </ListItemText>
      <ListItemText className="w-full">
        <TextField
          type="number"
          variant="outlined"
          fullWidth
          label={t('budgetAmount')}
          value={valueAmount}
          error={valueAmount < 0}
          onChange={(e) => {
            setValueAmount(e.target.value);
            const amount_budget = budgets.find(({ budget_id }) => budget_id === budget.budget_id);
            return setBudgetsState((prev_value) =>
              prev_value
                .filter((v) => v.budget_id !== amount_budget.budget_id)
                .concat([{ ...amount_budget, add_amount: e.target.value }])
            );
          }}
          InputProps={{
            inputProps: { min: 0 },
            endAdornment: <InputAdornment>{company?.currency?.symbol ? company?.currency?.symbol : '€'}</InputAdornment>
          }}
          helperText={valueAmount < 0 && t('budgetAmountNegativeError')}
        />
      </ListItemText>
    </ListItem>
  );
}
