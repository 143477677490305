import Ticket from 'entities/Ticket/Ticket';

export default function TicketPage({ maintenancePage }) {
  return (
    <Ticket
      page
      maintenancePage={maintenancePage}
    />
  );
}
