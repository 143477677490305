import { ArrowBack, ArrowBackIos, ArrowForward, ArrowForwardIos } from '@material-ui/icons';

export const isIos =
  ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
    navigator.platform
  ) ||
  (navigator.userAgent.includes('Mac') && 'ontouchend' in document);

export function Back(props) {
  return isIos ? <ArrowBackIos {...props} /> : <ArrowBack {...props} />;
}
export function Forward(props) {
  return isIos ? <ArrowForward {...props} /> : <ArrowForwardIos {...props} />;
}
