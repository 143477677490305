import { useFieldObserver, useForm } from 'frmx';
import { useTranslation } from 'react-i18next';

export default function Bookmark({ label, color, path, value }) {
  const { t } = useTranslation();
  const { setOneField } = useForm();

  const bookmarks = useFieldObserver(path);
  const is_selected = bookmarks.includes(value) || (value === 'reset' && bookmarks.length === 0);

  const handleSelectBookmark = () => {
    if (value === 'reset') {
      setOneField(path, []);
    } else if (bookmarks.includes(value)) {
      setOneField(
        path,
        bookmarks.filter((bookmark) => bookmark !== value)
      );
    } else {
      const next = bookmarks.filter((bookmark) => bookmark !== 'reset');
      setOneField(path, is_selected ? next.filter((b) => b !== value) : [...next, value]);
    }
  };

  return (
    <button
      onClick={() => handleSelectBookmark()}
      className="cursor-pointer inline-flex items-center rounded-full text-xs font-medium"
    >
      <div
        className="flex items-center rounded-full border px-2.5 py-0.5 text-xs font-medium whitespace-nowrap"
        style={{
          backgroundColor: is_selected ? color : 'white',
          border: `1px solid ${color}`,
          color: is_selected ? 'white' : color
        }}
      >
        {t(label)}
      </div>
    </button>
  );
}
