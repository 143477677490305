import Job from 'entities/Job/Job';
import { zonesForFilters } from 'lists/zones';
import FAIcon from 'components/ui/FAIcon';

const IntervenerFilters = (
  configuration,
  { scheduler, form, double, filter, intervenerPickerType, customFields }
) =>
  [
    {
      type: 'Tab',
      icon: (
        <FAIcon
          icon="user-check"
          collection="fad"
        />
      ),
      label: 'techniciansTitle',
      key: 'collaborator',
      color: '#fbb034',
      default: true,
      show: !configuration.isMaster && configuration.feature.collaborator
    },
    {
      type: 'Tab',
      icon: (
        <FAIcon
          icon="user-hard-hat"
          collection="fad"
        />
      ),
      label: configuration.isContractor ? 'entityContractorName1' : 'entityContractorName2',
      key: 'mine',
      color: '#535b7c',
      default: false,
      hide: configuration.isMaster,
      show: !intervenerPickerType || intervenerPickerType !== 'technician'
    },
    {
      type: 'Tab',
      icon: (
        <FAIcon
          icon="book-spells"
          collection="fad"
        />
      ),
      label: 'marketplace',
      key: 'public',
      color: '#31b559',
      hide: configuration.isMaster,
      default: false,
      show:
        (!intervenerPickerType || intervenerPickerType === 'proposition') &&
        configuration.feature.marketplace &&
        !scheduler &&
        !form &&
        !double
    },
    {
      label: 'entityJobName',
      key: 'jobs',
      type: 'Entity',
      params: {
        entity: Job,
        entityProps: {
          type: 'jobs',
          hiddenFilters: {
            section: {
              mine: true,
              bobdesk: true,
              bobdepannage: true
            }
          }
        }
      },
      show: true,
      disabled: false,
      default: []
    },
    {
      label: 'interventionZones',
      key: 'zone_ids',
      type: 'List',
      params: {
        datas: zonesForFilters
      },
      show: true,
      disabled: false,
      default: []
    },
    {
      label: 'field',
      key: 'fields',
      type: 'Field',
      params: {},
      show: customFields?.some((section) => !!section.fields?.length),
      disabled: false,
      default: []
    }
  ].filter((e) => e.show);

export const formatFilter = (filters) => filters;

export default IntervenerFilters;
