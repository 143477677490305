import { Box, Button } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import useStyles from 'layouts/entities/View.styles';
import { useTranslation } from 'react-i18next';
import classNames from 'utils/classNames';

const FinishButton = ({ actions, isLoading, setLiftReserveDialogIsOpen, _ticket_reserve }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box marginTop={'10px'}>
      <Button
        onClick={() => {
          if (_ticket_reserve) {
            setLiftReserveDialogIsOpen(true);
          } else {
            actions.finish.action();
          }
        }}
        disabled={isLoading}
        endIcon={<Check className={classes.viewActionIcon} />}
        color="secondary"
        variant="contained"
        className={classNames(classes.viewAction, classes.viewActionSuccess)}
      >
        {t('endIntervention')}
      </Button>
    </Box>
  );
};

export default FinishButton;
