import { Box, Tooltip } from '@material-ui/core';
import { useMemo } from 'react';
import { isBefore, isAfter, differenceInDays } from 'date-fns';
import { contractStates } from 'lists/contractStates';
import FAIcon from 'components/ui/FAIcon';
import IconButton from 'components/IconButton';
import { useTranslation } from 'react-i18next';
import { dateToLocalFormat } from 'utils/dates';

export function TimelineRenderItem({ ticket, itemContext, item }) {
  const { t } = useTranslation();
  const selected = ticket.period.hasReserve;
  const offSet = 2;

  const { date, isLate, isAlert, alertPosition, planningPosition, datePosition } = useMemo(() => {
    const now = new Date();
    const start_time = new Date(item.period.start_time);
    const end_time = new Date(item.period.end_time);
    const alert_date = new Date(item.period.alert_date);
    const planning_date = new Date(item.period.planning_date);
    const intervention_date = new Date(ticket.contract.intervention_date || ticket.contract.visit_date);

    const isAlert = ticket.period.current && isBefore(alert_date, now) && isBefore(now, end_time);

    const isLate = ticket.period.current && isAfter(now, end_time);

    const calculatePosition = (date) =>
      !isLate &&
      ticket.period.current &&
      date &&
      (itemContext.width * differenceInDays(start_time, date)) / differenceInDays(start_time, end_time) - offSet;

    return {
      date: intervention_date,
      isAlert,
      isLate,
      alertPosition: calculatePosition(alert_date),
      planningPosition: calculatePosition(planning_date),
      datePosition: calculatePosition(intervention_date)
    };
  }, [ticket, itemContext, item]);

  const state = contractStates[ticket.contract.state];

  return (
    <Box style={{ height: itemContext.dimensions.height, width: '100%' }}>
      <span
        className="overflow-hidden text-ellipsis w-full h-full text-sm font-medium flex items-center"
        style={{
          color: !ticket.period.current || selected ? 'white' : state.color,
          fontSize: 12,
          fontWeight: 500
        }}
      >
        {!!isAlert && <AlertIcon selected={selected} />}
        {!!isLate && <LateIcon selected={selected} />}

        <Box className="flex flex-col">
          {!ticket.period.current ? (
            <Box className="flex flex-col justify-center leading-4 py-2">
              <span>
                #{ticket.period.index} {t(state.label)}
                {(!ticket.period.current || isLate) && <Gap period={ticket.period} />}
              </span>
              <Box className="flex items-center">
                <FAIcon
                  collection="fas"
                  icon="calendar"
                  className="text-[10px] mr-1"
                />
                {t('madeOn') + ' ' + dateToLocalFormat(date, 'P')}
              </Box>
            </Box>
          ) : (
            <Box>
              <span>
                #{ticket.period.index} {t(state.label)}
                {(!ticket.period.current || isLate) && <Gap period={ticket.period} />}
              </span>
            </Box>
          )}
        </Box>

        {ticket.period.hasIR && ticket.period.current && (
          <IconButton size="small">
            <FAIcon
              style={{ color: selected ? 'white' : 'inherit' }}
              collection="fas"
              icon="file-chart-pie"
            />
          </IconButton>
        )}
      </span>

      {!!planningPosition && (
        <Tooltip
          placement="left"
          title={t('maintenanceToPlanFrom') + ' ' + dateToLocalFormat(ticket.period.planning_date, 'P')}
        >
          <span
            style={{ left: planningPosition }}
            className={`w-[1px] top-0 h-full ${selected ? 'hidden' : 'block'} z-[5] border-[1px] border-dashed ${
              selected
                ? 'border-white'
                : isLate
                ? 'border-[#c0392b]'
                : isAlert
                ? 'border-[#d35400]'
                : 'border-[#363640]'
            } absolute`}
          ></span>
        </Tooltip>
      )}

      {!!alertPosition && (
        <AlertPostion
          alert_date={ticket.period.alert_date}
          alertPosition={alertPosition}
          selected={selected}
          isLate={isLate}
          isAlert={isAlert}
        />
      )}

      {!!datePosition && (
        <DatePostion
          intervention_date={ticket.contract.intervention_date}
          date={date}
          datePosition={datePosition}
          selected={selected}
          isLate={isLate}
          isAlert={isAlert}
        />
      )}
    </Box>
  );
}

const AlertPostion = ({ alert_date, alertPosition, selected, isLate, isAlert }) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      placement="left"
      title={t('reminderOn') + ' ' + dateToLocalFormat(alert_date, 'P')}
    >
      <span
        style={{ left: alertPosition }}
        className={`w-[1px] top-0 h-full ${selected ? 'hidden' : 'block'} z-[5] border-[1px] ${
          selected ? 'border-white' : isLate ? 'border-[#c0392b]' : isAlert ? 'border-[#d35400]' : 'border-[#363640]'
        } absolute`}
      >
        <FAIcon
          collection="fal"
          icon="bell-on"
          className={`${
            selected ? 'text-white' : isLate ? 'text-[#c0392b]' : isAlert ? 'text-[#d35400]' : 'text-[#363640]'
          } relative translate-x-[-13px] translate-y-[9px] bg-white rounded-[8px]`}
        />
      </span>
    </Tooltip>
  );
};

const DatePostion = ({ intervention_date, date, datePosition, selected, isLate, isAlert }) => {
  const { t } = useTranslation();
  return (
    <Tooltip
      placement="left"
      title={
        (intervention_date ? t('realizationDate') : t('scheduledPassageDate')) + ' ' + dateToLocalFormat(date, 'P')
      }
    >
      <span
        style={{ left: datePosition }}
        className={`w-[1px] top-0 h-full ${selected ? 'hidden' : 'block'} z-[5] border-[1px] ${
          selected ? 'border-white' : isLate ? 'border-[#c0392b]' : isAlert ? 'border-[#d35400]' : 'border-[#363640]'
        } absolute`}
      >
        <FAIcon
          collection="fal"
          icon="calendar-day"
          className={`${
            selected ? 'text-white' : isLate ? 'text-[#c0392b]' : isAlert ? 'text-[#d35400]' : 'text-[#363640]'
          } relative translate-x-[-13px] translate-y-[9px] bg-white rounded-[8px]`}
        />
      </span>
    </Tooltip>
  );
};

const AlertIcon = ({ selected }) => {
  return (
    <Box style={{ marginLeft: 6, color: selected ? 'white' : '#d35400', display: 'flex', alignItems: 'center' }}>
      <FAIcon
        className="relative right-1 mr-0.5"
        style={{ fontSize: 14 }}
        collection="fas"
        icon="exclamation-triangle"
      />
    </Box>
  );
};

const LateIcon = ({ selected }) => {
  return (
    <Box style={{ marginLeft: 6, color: selected ? 'white' : '#c0392b', display: 'flex', alignItems: 'center' }}>
      <FAIcon
        className="relative right-1 mr-0.5"
        collection="fas"
        icon="siren-on"
      />
    </Box>
  );
};

function Gap({ period }) {
  const gap = useMemo(() => {
    const period_gap = period.ecart || differenceInDays(new Date(period.end_time), new Date());
    return period_gap;
  }, [period.ecart, period.end_time]);

  return <span className="ml-2 text-[#81c784]">{gap >= 0 ? `+${Math.abs(gap)}j` : `-${Math.abs(gap)}j`}</span>;
}
