import { Slider, Typography, makeStyles, useTheme } from '@material-ui/core';
import { Field } from 'frmx';
import { useTranslation } from 'react-i18next';
import { addToAndFormatDate, dateToLocalFormat } from 'utils/dates';

const useStyles = makeStyles((theme) => ({
  root: {
    color: 'white'
  },
  valueLabel: {
    color: theme.palette.primary.main
  }
}));

export default function MonitoringPeriodField({ startDate, end_date, alert_deadline }) {
  const { t } = useTranslation();
  const theme = useTheme();

  const classes = useStyles();

  return (
    <div
      style={{
        width: '100%',
        padding: 10,
        justifyContent: 'flex-start',
        borderRadius: theme.radius,
        overflow: 'hidden',
        boxShadow: theme.shadows[1],
        alignItems: 'flex-start',
        textAlign: 'left',
        flexDirection: 'column',
        backgroundImage: `linear-gradient(to right, ${theme.palette.primary.main} 0%, ${theme.palette.primary.light} 100%)`
      }}
    >
      <div className="mb-2 text-white">
        <Typography>{t('monitoringPeriod')}</Typography>
      </div>
      <span className="text-sm text-white">
        {t('documentMonitoringXDaysBefore', { count: alert_deadline })}
        <br />
        {startDate && (
          <span>
            {t('monitoringDate')}&nbsp;
            <span className="font-bold">
              {dateToLocalFormat(new Date(addToAndFormatDate(end_date, -alert_deadline, 'days')))}.
            </span>
          </span>
        )}
      </span>
      <Field
        className="mt-2"
        disabled={!startDate}
        path="alert_deadline"
        type="range"
        getValueFromArgs={(args) => args[1]}
      >
        <Slider
          min={0}
          max={90}
          step={1}
          valueLabelDisplay="auto"
          color="secondary"
          classes={{
            root: classes.root,
            valueLabel: classes.valueLabel
          }}
        />
      </Field>
    </div>
  );
}
