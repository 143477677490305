import { useRef, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import Tabs from './Tabs';
import ViewMenu from './ViewMenu';
import TimelineView from 'entities/Ticket/View/TimelineView';
import useStyles from './View.styles';
import { useIsBelowMd } from 'hooks/useMQ';
import { useEntity } from 'contexts/entities/entityContext';

export default function View({ onClose, setIsOpenForm, isDialog, refresh, isLoading, dispatch }) {
  const {
    viewComponentFun,
    viewComponent: _ViewComponent,
    element,
    tabs,
    entity,
    fromTimeline
  } = useEntity();

  const classes = useStyles({ isDialog, hasTabs: !!tabs?.length });

  const ViewComponent = viewComponentFun ? _ViewComponent({ element }) : _ViewComponent;

  const isBelowMd = useIsBelowMd();

  const boxRef = useRef();
  // if the box height is bigger than the window height, we add a top-3 class to the box
  useEffect(() => {
    if (boxRef.current && isBelowMd) {
      const boxHeight = boxRef.current.getBoundingClientRect().height;
      const windowHeight = window.innerHeight;
      if (boxHeight >= windowHeight - 120) {
        // 120 = height of the Tabs component
        boxRef.current.classList.add('top-3');
      }
    }
  }, [boxRef.current, isBelowMd]);

  return element ? (
    <>
      {/* div outside click with Hooks, if we use useOutsideClick the ticket will be close*/}
      <div
        className={`${isBelowMd && 'absolute top-0 left-0 w-full h-full z-10'}`}
        onClick={onClose}
      />

      <Box
        ref={boxRef}
        className={`${
          isBelowMd
            ? 'pt-14 z-50 pb-6 fixed bottom-[90px] left-[50%] translate-x-[-50%] rounded-lg w-[96vw] max-w-[96vw] overflow-y-scroll'
            : 'relative rounded-xl pt-[30px]'
        } bg-white px-[26px] py-[16px] shadow`}
      >
        {isBelowMd && (
          <div className="top-2 left-2 hover:bg-red-50 absolute cursor-pointer p-2 rounded-full transition">
            <Clear onClick={onClose} />
          </div>
        )}

        <Box className={classes.menu}>
          <ViewMenu
            onClose={onClose}
            isLoading={isLoading}
            refresh={refresh}
            setIsOpenForm={setIsOpenForm}
          />
        </Box>

        <ViewComponent
          dispatch={dispatch}
          isLoading={isLoading}
          setIsOpenForm={setIsOpenForm}
          close={onClose}
          element={element}
        />
      </Box>

      {entity === 'tickets' && element.period && !fromTimeline && (
        <TimelineView element={element} />
      )}

      {!!tabs?.length && (
        <div className={`${isBelowMd && 'absolute z-50'}`}>
          <Tabs
            isLoading={isLoading}
            isDialog={isDialog}
            element={element}
          />
        </div>
      )}
    </>
  ) : null;
}
