import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: ({
    settings,
    filter,
    scheduler,
    form,
    calendar,
    timeline,
    maintenances,
    page,
    disableShadow
  }) => ({
    height: '100%',
    position: 'relative',
    flexGrow: 1,
    boxShadow: !calendar && !timeline && !filter && !form && !disableShadow && theme.shadows[1],
    ...(maintenances || page || scheduler
      ? {
        borderRadius: '16px 16px 0px 0px',
        overflow: 'hidden'
      }
      : {}),
    ...(timeline
      ? {
        overflow: 'visible'
      }
      : {}),
    ...(settings
      ? {
        borderRadius: '0px 0px 8px 8px',
        overflow: 'hidden'
      }
      : {})
  }),
  list: ({
    picker,
    trash,
    disableGutters,
    filter,
    form,
    page,
    list,
    scheduler,
    calendar,
    timeline,
    maintenances,
    fullscreen,
    settings
  }) => ({
    padding:
      !trash &&
      !disableGutters &&
      !picker &&
      !filter &&
      !form &&
      !scheduler &&
      !calendar &&
      !timeline &&
      !maintenances &&
      !fullscreen &&
      !settings &&
      !list &&
      10,
    paddingBottom: !calendar && 0
  }),
  listExpandable: {
    width: '460px',
    height: '100%',
    maxWidth: 'auto',
    transition: 'all linear 0.2s',
    opacity: 1,
    ['&.fullExpanded']: {
      width: '100%'
    },
    ['& .listContent']: {
      transition: 'all linear 0.2s',
      opacity: 1
    },
    ['& .expandBtn']: {
      zIndex: 16,
      position: 'absolute',
      transition: 'all linear 0.2s',
      top: 0,
      right: 0,
      transform: 'rotate(0)'
    },
    ['&.isExpanded']: {
      opacity: 0.5,
      width: '64px',
      ['& .expandBtn']: {
        transform: 'rotate(180deg)'
      },
      ['& .listContained']: {
        opacity: 0
      }
    }
  },
  viewWithHeader: ({ leftPane }) => ({
    borderRadius: `16px 16px 8px 8px`,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      padding: 10,
      paddingLeft: leftPane && 0
    }
  }),
  view: {},
  fab: {
    position: 'fixed'
  },
  fabList: {
    position: 'absolute',
    zIndex: 500,
    right: 16,
    bottom: 16
  },
  fabMobile: {
    position: 'absolute',
    zIndex: 1000,
    right: 24,
    bottom: 16
  },
  cta: {
    zIndex: 1200,
    [theme.breakpoints.down('sm')]: {
      top: 13,
      right: 76
    },
    [theme.breakpoints.up('sm')]: {
      top: 16,
      right: 76
    },
    [theme.breakpoints.up('md')]: {
      top: 13,
      right: 76
    },
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    ['& > div']: {
      ['& .MuiCollapse-wrapper']: {
        padding: '0 !important'
      },
      bottom: 'auto',
      left: 0,
      position: 'initial'
    }
  },
  fabButtonPublic: {
    height: 36,
    position: 'relative',
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.primary.dark,
    color: 'white',
    padding: 6,
    paddingLeft: 58,
    paddingRight: 36,
    fontWeight: 500,
    borderRadius: 16
  },
  fabButton: {
    height: 36,
    position: 'relative',
    color: theme.palette.primary.dark,
    backgroundColor: 'white',
    ['&:hover']: {
      backgroundColor: theme.palette.primary.dark,
      color: 'white'
    },
    // fontSize: 13,
    // display: 'flex',
    // gap: "10em",
    // justifyContent: 'space-around',
    padding: 6,
    paddingLeft: 58,
    paddingRight: 36,
    [theme.breakpoints.down('sm')]: {
      color: 'black',
      minWidth: 0,
      height: 1,
      padding: '16px 4px',
      backgroundColor: 'rgba(255,255,255,0.99)',
      boxShadow: theme.shadows[1],
      borderRadius: 9999
    },
    fontWeight: 500,
    borderRadius: 16
  },
  fabButtonIcon: {
    position: 'absolute',
    left: 12,
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      left: 0
    }
  },
  header: {
    background: theme.palette.secondary.main,
    color: 'white',
    display: 'flex',
    fontSize: 14,
    fontWeight: 500,
    padding: '10px 10px',
    alignItems: 'center',
    borderRadius: `${theme.radius}px ${theme.radius}px 0px 0px`
  },
  headerIcon: {
    marginRight: 16,
    fontSize: 16
  }
}));
