import { Box, Chip, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import FAIcon from 'components/ui/FAIcon';
import Avatar from 'components/Avatar';
import useStyles from 'layouts/entities/View.styles';

import { PhoneItem, EmailItem, ZoneItem } from 'components/views/ViewItems';
import { useTranslation } from 'react-i18next';

import Flag from 'react-flagkit';
import { countryLangage } from 'config/languages';

export default function TechnicianView({ element }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const lang = (element || element._user)?.lang || 'fr';

  return (
    <>
      <Box className={classes.l1}>
        <Box
          alignItems={'center'}
          display={'flex'}
        >
          <Avatar
            className={classes.avatarl1}
            isUser={true}
            round
            entity={{
              name: '',
              avatar: element.avatar || element._user?.avatar
            }}
            shadow={false}
            size={'small'}
          />
          <Box
            flexDirection={'column'}
            alignItems={'flex-start'}
          >
            <Box className="text-base font-bold flex gap-2 items-center">
              {(element._user || element).firstName} {(element._user || element).lastName}
              <Flag
                country={countryLangage[lang]}
                className="mb-[2px] rounded-sm"
                size={20}
              />
            </Box>
            {element._role && (
              <Box marginTop={'10px'}>
                {
                  <Chip
                    className={classes.l1chip}
                    color="secondary"
                    size="small"
                    label={element._role.name}
                  />
                }
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      <List className={classes.list}>
        {/* //Duplicate in Technician Form */}

        {!!element.zone_ids?.length && <ZoneItem element={element} />}

        {!!element._jobs?.length && (
          <ListItem
            dense
            className={classes.listItem}
          >
            <ListItemIcon>
              {
                <FAIcon
                  icon={'toolbox'}
                  collection={'fal'}
                  className={classes.listIcon}
                />
              }
            </ListItemIcon>
            <ListItemText
              primary={<strong>Métier(s)</strong>}
              secondary={element._jobs.map((j) => t(j.name)).join(', ')}
            />
          </ListItem>
        )}

        {!!element.description?.length && (
          <ListItem
            dense
            className={classes.listItem}
          >
            <ListItemIcon>
              {
                <FAIcon
                  icon={'quote-left'}
                  collection={'fas'}
                  className={classes.listIcon}
                />
              }
            </ListItemIcon>
            <ListItemText secondary={element.description} />
          </ListItem>
        )}

        {!!element.phone?.length && <PhoneItem element={element} />}
        {!!element.fix?.length && (
          <PhoneItem
            element={element}
            fix
          />
        )}
        {!!element.email?.length && <EmailItem element={element} />}
      </List>
    </>
  );
}
