import FilterAndSearchBarForm from 'components/filters/FilterAndSearchBarForm';
import HibaPreviewComponent from 'entities/Ticket/HibaTicketPreviewNew/HibaPreviewComponent.js';
import useAsyncDispatch from 'hooks/useAsyncDispatch.js';
import { useAuth } from 'hooks/useAuth.js';
import { useConfiguration } from 'hooks/useConfiguration';
import useDebouncedState from 'hooks/useDebouncedState';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getInitialValuesFromFilters, getTicketFilters, ticketSortingOrder } from './baseFilters.js';
import { getBookmarks } from './utils.js';
import {
  createFilterSetsManager,
  createMenuItems,
  fetchTickets,
  generateFilters,
  useFilteredBookmarks
} from './utilsFun.js';

const TicketFile = ({ scheduler, userMaintenance, calendar }) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const config = useConfiguration();
  const customFields = useSelector((store) => store.fieldSections).ticket;
  const { dispatch } = useAsyncDispatch();

  // Initialisation des bookmarks et des états de filtres
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [bookmarkState, setBookmarkState] = useState({
    bookmarks: getBookmarks(scheduler, calendar, auth),
    isLoading: false
  });

  const showableFilters = getTicketFilters({
    configuration: config,
    userMaintenance,
    calendar,
    scheduler,
    customFields
  }).filter((filter) => filter.show);

  // Gestion de l'état des filtres avec debounce
  const [initialValues, setInitialValues] = useState(getInitialValuesFromFilters(showableFilters));
  const [appliedFilters, setAppliedFilters, debouncedAppliedFilters] = useDebouncedState(initialValues);

  const [filterSetsManager, setFilterSetsManager] = useState(createFilterSetsManager(appliedFilters));

  useFilteredBookmarks(calendar, appliedFilters, setBookmarkState, bookmarkState);
  const _fetchTickets = fetchTickets(auth, setData, setLoading, dispatch);

  // Fetch les tickets lors du select d'un filtre
  useEffect(() => {
    _fetchTickets(debouncedAppliedFilters);
  }, [debouncedAppliedFilters]);

  // Création des éléments de menu et des composants de filtre
  const menuItems = createMenuItems(t, _fetchTickets, appliedFilters);
  const filters = generateFilters(filterSetsManager, showableFilters);

  return (
    <div className="bg-white m-2 rounded-xl h-full overflow-hidden">
      <FilterAndSearchBarForm
        withFilters
        initialValues={initialValues}
        setInitialValues={setInitialValues}
        appliedFilters={appliedFilters}
        filterSetsManager={filterSetsManager}
        setFilterSetsManager={setFilterSetsManager}
        onChange={setAppliedFilters}
        menuItems={menuItems}
        filters={filters}
        dataFilters={showableFilters.map((filter) => ({
          label: filter.props.label,
          key: filter.props.path
        }))}
        sortingOrders={ticketSortingOrder}
        bookmarkState={bookmarkState}
      />
      {!loading &&
        (!data?.length ? (
          <div>has no tickets</div>
        ) : (
          <HibaPreviewComponent
            entity="tickets"
            data={data}
          />
        ))}
    </div>
  );
};

export default TicketFile;
