import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField
} from '@material-ui/core';
import BlurredProgress from 'components/BlurredProgress';
import { Submit, Field, Form } from 'frmx';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import useNotifications from 'hooks/useNotifications';
import { useTranslation } from 'react-i18next';
import { createFieldSection, updateFieldSection } from 'store/fieldSectionsSlice';

const mapStateToEndpoint = (entity) => {
  switch (entity) {
    case 'technician':
      return 'collaborator';
    case 'user':
      return 'contact';
    case 'maintenance':
      return 'preventif';
    case 'report':
      return 'interventionReport';
    default:
      return entity;
  }
};

export default function SectionEditor(props) {
  const { t } = useTranslation();

  const { entity, open, onClose, labelValue = '', id, label = t('sectionName') } = props;
  const isCreate = !id;

  const title =
    isCreate && entity === 'interventionReport'
      ? t('entityReportCreate')
      : !isCreate && entity === 'interventionReport'
        ? t('editTheReport')
        : isCreate
          ? t('createASection')
          : t('editTheSection');

  const { dispatch, requestStatus } = useAsyncDispatch();
  const notify = useNotifications();
  const dispatchCallbacks = {
    onSuccess: () => notify.success(isCreate ? t('sectionCreated') : t('sectionEdited')),
    onError: () => notify.error()
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
    >
      <BlurredProgress in={requestStatus === 'loading'} />
      <Form
        initialValues={{
          entity,
          label: labelValue
        }}
        onSubmit={(formData) => {
          formData.entity = mapStateToEndpoint(formData.entity);
          dispatch(
            isCreate ? createFieldSection : updateFieldSection,
            formData,
            dispatchCallbacks,
            isCreate ? undefined : { id }
          ).then(() => onClose());
        }}
      >
        <DialogTitle>{title}</DialogTitle>

        <DialogContent>
          <Field path="label">
            <TextField
              label={label}
              variant="outlined"
              fullWidth
            />
          </Field>
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>{t('cancel')}</Button>

          <Submit>
            <Button>{isCreate ? t('create') : t('save')}</Button>
          </Submit>
        </DialogActions>
      </Form>
    </Dialog>
  );
}
