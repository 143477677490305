import classNames from 'utils/classNames';
import { format, isSameDay, isSameMonth, isToday } from 'date-fns';
import { useUI } from 'hooks/useUI';

export default function DaySmall({
  now,
  day,
  idx,
  selected_day_idx,
  set_selected_day_idx,
  middle_of_the_month
}) {
  const is_today = isToday(day.date);
  const is_selected = idx === selected_day_idx;
  const is_current_month = isSameMonth(day.date, middle_of_the_month);
  const ui = useUI();
  const locale = ui.get_date_time_locale();

  return (
    <button
      key={day.date}
      onClick={() => set_selected_day_idx(idx)}
      className={classNames(
        is_current_month ? 'bg-white' : 'bg-gray-50',
        (is_selected || isSameDay(new Date(day.date), now)) && 'font-semibold',
        is_selected && 'text-white',
        !is_selected && is_today && 'text-red-500',
        !is_selected && is_current_month && !is_today && 'text-gray-900',
        !is_selected && !is_current_month && !is_today && 'text-gray-500',
        'flex h-14 flex-col py-2 px-3 hover:bg-gray-100 focus:z-10 overflow-clip'
      )}
    >
      <time
        dateTime={day.date}
        className={classNames(
          is_selected && 'flex h-6 w-6 items-center justify-center rounded-full',
          is_selected && is_today && 'bg-red-600',
          is_selected && !is_today && 'bg-sky-600',
          'ml-auto'
        )}
      >
        {format(day.date, 'd', { locale })}
      </time>
      <span className="sr-only">{day.events.length} events</span>
      {day.events.length > 0 && (
        <span className="-mx-0.5 mt-auto flex flex-wrap-reverse">
          {day.events.map((event) => (
            <span
              key={event._id}
              className="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full bg-gray-400"
            />
          ))}
        </span>
      )}
    </button>
  );
}
