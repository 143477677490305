import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, FormControlLabel } from '@material-ui/core';
import EntityX from 'components/inputs/EntityX';
import FAIcon from 'components/ui/FAIcon';
import Job from 'entities/Job/Job';
import Location from 'entities/Location/Location';
import { Field, Form, Submit, useFieldObserver } from 'frmx';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import useNotifications from 'hooks/useNotifications';
import { useTranslation } from 'react-i18next';
import { createAutomation, updateAutomation } from 'store/managersSlice';
import DialogTitle from 'components/dialogs/DialogTitle';
import { useEffect, useState } from 'react';
import Client from 'entities/Client/Client';

export default function ModalAutomation({ open, onClose, manager, isContractor, automation }) {
  const { t } = useTranslation();
  const notify = useNotifications();
  const { dispatch } = useAsyncDispatch();
  const [errors, setErrors] = useState({ jobsError: false, locationsError: false, clientsError: false });
  const [modalSubLocations, setModalSubLocations] = useState(false);
  const isCreate = !automation?._id;

  // Initialisation des données de l'automation
  const initialValues = {
    isActive: automation?.isActive || false,
    allLocations: automation?.allLocations || false,
    allJobs: automation?.allJobs || false,
    allClients: automation?.allClients || false,
    _jobs: automation?._jobs || [],
    _locations: automation?._locations || [],
    _clients: automation?._clients || []
  };
  const [datas, setDatas] = useState(initialValues);
  const callbacks = {
    onError: ({ status }) => notify.error(status === 403 && t('automatisationAlreadyExists')),
    onSuccess: () => {
      notify.success(isCreate ? 'createdAutomatisation' : 'editedAutomatisation');
      onClose();
    }
  };
  const params = { id: manager._id, automation_id: isCreate ? undefined : automation._id };
  const createOrUpdate = isCreate ? createAutomation : updateAutomation;

  useEffect(() => {
    if (open) setErrors({ jobsError: false, locationsError: false, clientsError: false });
  }, [open]);

  // Validation des champs
  const validateFields = ({ allJobs, _jobs, allLocations, _locations, allClients, _clients }) => {
    const newErrors = {
      jobsError: !allJobs && !_jobs.length,
      locationsError: !allLocations && !_locations.length,
      clientsError: isContractor && !allClients && !_clients.length
    };
    setErrors(newErrors);
    return Object.values(newErrors).every((error) => !error);
  };

  const handleSubmit = (data) => {
    if (!validateFields(data)) return;

    const updatedData = {
      ...data,
      allClients: isContractor && data.allClients,
      _clients:
        isContractor && data._clients.length && !data.allClients
          ? data._clients.map(({ _id, name }) => ({ _id, name }))
          : manager._scope_clients?.map(({ _id, name }) => ({ _id, name })),
      _jobs:
        data._jobs.length && !data.allJobs
          ? data._jobs.map(({ _id, name }) => ({ _id, name }))
          : manager._jobs?.map(({ _id, name }) => ({ _id, name })),
      _locations:
        data._locations.length && !data.allLocations
          ? data._locations.map(({ _id, name }) => ({ _id, name }))
          : manager._locations.map(({ _id, name }) => ({ _id, name }))
    };

    setDatas(updatedData);
    if (data._locations?.length && !data.allLocations) setModalSubLocations(true);
    else handleModalResponse(updatedData, true);
  };

  const handleModalResponse = (data, subLocations) => {
    dispatch(createOrUpdate, { ...data, subLocations }, callbacks, params);
    setModalSubLocations(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="md"
      >
        <Form
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          <DialogTitle
            title={isCreate ? t('createAutomatisation') : t('editAutomatisation')}
            onClose={onClose}
          />
          <DialogContent>
            <AutomationDialogContents
              manager={manager}
              {...errors}
              isContractor={isContractor}
            />
          </DialogContent>
          <DialogActions>
            <Submit>
              <Button
                endIcon={
                  <FAIcon
                    collection="fas"
                    icon="bolt"
                  />
                }
              >
                {t('save2')}
              </Button>
            </Submit>
          </DialogActions>
        </Form>
      </Dialog>

      <DialogSubLocations
        open={modalSubLocations}
        onClose={() => setModalSubLocations(false)}
        onYes={() => handleModalResponse(datas, true)}
        onNo={() => handleModalResponse(datas, false)}
      />
    </>
  );
}

const DialogSubLocations = ({ open, onClose, onYes, onNo }) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle
        title={t('selectSubLocations')}
        onClose={onClose}
      />
      <DialogContent>
        <div className="text-center text-base mt-2">{t('shouldSelectSubLocations')}</div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onNo}>{t('no')}</Button>
        <Button onClick={onYes}>{t('yes')}</Button>
      </DialogActions>
    </Dialog>
  );
};

const AutomationDialogContents = ({ manager, jobsError, locationsError, clientsError, isContractor }) => {
  const allLocations = useFieldObserver('allLocations');
  const allJobs = useFieldObserver('allJobs');
  const allClients = useFieldObserver('allClients');
  const clients = useFieldObserver('_clients');

  const clientsIds = manager?._scope_clients?.map((c) => c._id);
  const managerJobIds = manager?._jobs?.map((j) => j._id);
  const managerLocationIds = manager?._locations?.map((l) => l._id);
  const defaultFilterLocations = {
    ...(clients?.length > 0
      ? { clients: clients.map((c) => c._id) }
      : manager?._scope_clients?.length
      ? { clients: manager?._scope_clients?.map((c) => c._id) }
      : {})
  };

  const { t } = useTranslation();

  return (
    <>
      <p className="mt-2 text-sm mb-4">{t('automatisation-jobs-and-locations-are-according-to-manager-scope')}</p>

      {isContractor && (
        <>
          <FormControlLabel
            control={
              <Field
                path="allClients"
                type="checkbox"
                isErrorProp={clientsError}
              >
                <Checkbox />
              </Field>
            }
            label={t('select-all-clients')}
            className="mt-1"
          />
          <p>{clientsError && <span className="text-red-500">{t('selectAtLeastOneClient')}</span>}</p>
          <Box className="mt-1">
            <EntityX
              disabled={allClients}
              refreshValue
              path="_clients"
              entityName="clients"
              entity={Client}
              placeholder={t('entityClientName')}
              entityProps={{ hiddenFilters: { only: clientsIds } }}
            />
          </Box>
        </>
      )}

      <FormControlLabel
        control={
          <Field
            path="allJobs"
            type="checkbox"
            isErrorProp={jobsError}
          >
            <Checkbox />
          </Field>
        }
        label={t('selectAllJobs')}
        className="mt-1"
      />
      {/* select all jobs or select jobs */}
      <p>{jobsError && <span className="text-red-500">{t('selectAtLeastOneJob')}</span>}</p>
      <Box className="mt-1">
        <EntityX
          disabled={allJobs}
          disabledHelperText={t('allJobsAlreadySelected')}
          path="_jobs"
          entityName="jobs"
          entity={Job}
          placeholder={t('entityJobName')}
          entityProps={{ hiddenFilters: { only: managerJobIds } }}
        />
      </Box>

      <FormControlLabel
        control={
          <Field
            path="allLocations"
            type="checkbox"
            isErrorProp={locationsError}
          >
            <Checkbox />
          </Field>
        }
        label={t('selectAllLocations')}
        className="mt-4"
      />
      <p>{locationsError && <span className="text-red-500">{t('selectAtLeastOneLocation')}</span>}</p>
      <Box className="mt-1">
        <EntityX
          disabled={allLocations}
          disabledHelperText={t('allLocationsAlreadySelected')}
          path="_locations"
          entityName="locations"
          entity={Location}
          placeholder={t('locations')}
          entityProps={{
            lockClients: true,
            hiddenFilters: { only: managerLocationIds },
            defaultFilters: defaultFilterLocations
          }}
        />
      </Box>
    </>
  );
};
