export const prevent = (e) => [e?.preventDefault(), e?.stopPropagation()];

const preventAll = {
  onMouseOver: prevent,
  onMouseEnter: prevent,
  onMouseDown: prevent,
  onClick: prevent
};

export default preventAll;
