import { Box, List } from '@material-ui/core';

import useStyles from 'layouts/entities/View.styles';
import { useTranslation } from 'react-i18next';

export default function JobView({ element }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Box className={classes.l1}>
        <Box
          alignItems={'center'}
          display={'flex'}
        >
          <Box
            flexDirection={'column'}
            alignItems={'flex-start'}
          >
            <Box className={classes.l1left}>{t(element.name)}</Box>
          </Box>
        </Box>
      </Box>
      <List className={classes.list} />
    </>
  );
}
