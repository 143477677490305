import {
  AccordionActions,
  AccordionDetails,
  Grid,
  FormControlLabel,
  Checkbox,
  Button
} from '@material-ui/core';

import { Field, Submit, Reset } from 'frmx';

import { useAuth } from 'hooks/useAuth';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import useNotifications from 'hooks/useNotifications';
import { setConfiguration } from 'store/authSlice';

import AccordionForm from 'components/forms/AccordionForm';
import Location from 'entities/Location/Location';

import EntityX from 'components/inputs/EntityX';
import { useTranslation } from 'react-i18next';

export default function LocationsConfiguration({ section }) {
  const notify = useNotifications();
  const auth = useAuth();
  const configuration = auth.interface._company._configuration;

  const { dispatch, requestStatus } = useAsyncDispatch();
  const { t } = useTranslation();

  const dispatchCallbacks = {
    onSuccess: () => notify.success(),
    onError: () => notify.error()
  };

  return (
    <AccordionForm
      defaultClose
      section={section}
      initialValues={{
        _facturation: configuration._facturation,
        location_sectors: configuration.location_sectors,
        sectors: configuration.sectors,
        monolocation: configuration?.monolocation || false,
        _monolocation: configuration?._monolocation || null
      }}
      onSubmit={(updates) => dispatch(setConfiguration, updates, dispatchCallbacks)}
    >
      <AccordionDetails>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <EntityX
              path="_facturation"
              entity={Location}
              entityName="locations"
              entityProps={{
                facturation: true,
                hiddenFilters: { section: { facturation: true, location: false, warehouse: false } }
              }}
              placeholder={t('defaultBillingAddress')}
              pickerUniq
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <FormControlLabel
              control={
                <Field
                  path="monolocation"
                  type="checkbox"
                >
                  <Checkbox />
                </Field>
              }
              label={t('monoSite')}
            />

            {configuration.monolocation && (
              <EntityX
                path="_monolocation"
                entity={Location}
                entityName="locations"
                entityProps={{
                  hiddenFilters: {
                    section: { facturation: false, location: true, warehouse: false }
                  }
                }}
                placeholder={t('defaultAddress')}
                pickerUniq
              />
            )}
          </Grid>
          <Grid
            item
            xs={12}
          >
            <FormControlLabel
              control={
                <Field
                  path="location_sectors"
                  type="checkbox"
                >
                  <Checkbox />
                </Field>
              }
              label={t('activateSectors')}
            />
          </Grid>
        </Grid>
      </AccordionDetails>

      <AccordionActions>
        <Reset>
          <Button size="small">{t('cancel')}</Button>
        </Reset>

        <Submit disabled={requestStatus === 'loading'}>
          <Button
            size="small"
            color="primary"
          >
            {t('save')}
          </Button>
        </Submit>
      </AccordionActions>
    </AccordionForm>
  );
}
