import { Dialog, DialogTitle, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import { useEffect, useMemo, useState } from 'react';

import { SIDEBAR_IS_LOCKED } from 'constants/localStorage';
import TopBar from 'layouts/dashboard/TopBar';
import Sidebar from 'layouts/dashboard/Sidebar';
import { useIsBelowMd } from 'hooks/useMQ';
import FirstConnect from 'layouts/dashboard/FirstConnect';

import { useAuth } from 'hooks/useAuth';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import { getFieldSections } from 'store/fieldSectionsSlice';
import { countElements } from 'store/contractPropositionsSlice';
import fieldSectionsEndpoints from 'config/fieldSectionsEndpoints';
import { getMenus } from 'config/menus';
import Menubar from './Menubar';
import { useSelector } from 'react-redux';
import { INTERFACE_TECHNICIAN } from 'constants/roles';
import { fetchInterfaceData } from 'store/authSlice';
import { useConfiguration } from 'hooks/useConfiguration';
import { useDocumentListener } from 'react-events-utils';
import FAIcon from 'components/ui/FAIcon';
import { useTranslation } from 'react-i18next';
import { getDocumentTags } from 'store/documentssSlice';
import useInfo from 'hooks/useInfo';

export default function Dashboard({ children }) {
  const auth = useAuth();
  const configuration = useConfiguration();
  const isBelowMd = useIsBelowMd();
  const info = useInfo();
  const is_sandbox_server = process.env.REACT_APP_ENVIRONMENT === 'sandbox';

  const contractPropositions = useSelector((state) => state.contractPropositions);

  const [sidebarIsOpen, setSidebarIsOpen] = useState(
    !!localStorage.getItem(SIDEBAR_IS_LOCKED) && !isBelowMd
  );
  const [sidebarIsLocked, setSidebarIsLocked] = useState(
    !!localStorage.getItem(SIDEBAR_IS_LOCKED) && !isBelowMd
  );
  const [sidebarIsMorphing, setSidebarIsMorphing] = useState(false);

  // Trigger if environment is sandbox and warn user actions
  useEffect(() => {
    if (is_sandbox_server) {
      info({
        onConfirm: () => {},
        helperText: 'actionsHereAreNotSent',
        confirmLabel: 'IGetIt'
      });
    }
  }, []);

  //SPECIFIC BLACHERE
  const [isSpiralOpen, setIsSpiralOpen] = useState(null);
  useDocumentListener('openSpiral', (e) => {
    setIsSpiralOpen(e.detail);
  });

  const { dispatch } = useAsyncDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    window.$crisp.push([
      'do',
      (isBelowMd && sidebarIsOpen && auth.interface?._company._configuration.support_chat) ||
      (!isBelowMd && auth.interface?._company._configuration.support_chat)
        ? 'chat:show'
        : 'chat:hide'
    ]);
  }, [isBelowMd, sidebarIsOpen, auth.interface?._company._configuration.support_chat]);

  useEffect(() => {
    dispatch(getDocumentTags);

    for (const section of fieldSectionsEndpoints)
      dispatch(getFieldSections, undefined, undefined, { entity: section });

    if (auth.interface.isContractor || auth.interface.isTechnician) dispatch(countElements);

    if (!auth.interface.data) dispatch(fetchInterfaceData);
  }, [auth.interface?._id]);

  const userMenus = useMemo(() => {
    let menus = getMenus(auth?.interface, auth?.user).filter(
      (menuItem) =>
        menuItem?.entity !== 'contractproposition' ||
        !!contractPropositions.count ||
        auth.interface.type === INTERFACE_TECHNICIAN ||
        !!localStorage.getItem('ContractPropositionPage')
    );
    if (configuration.monolocation) {
      menus = menus.filter((menu) => menu.entity !== 'location');
    }
    return menus;
  }, [
    getMenus,
    auth?.interface?._id,
    contractPropositions.count,
    auth?.interface?._company?._configuration?.feature
  ]);

  return (
    <div className="flex flex-col h-screen">
      <TopBar
        setSidebarIsOpen={setSidebarIsOpen}
        sidebarIsLocked={sidebarIsLocked}
        setSidebarIsLocked={setSidebarIsLocked}
        sidebarIsMorphing={sidebarIsMorphing}
        setSidebarIsMorphing={setSidebarIsMorphing}
      />
      <Sidebar
        contractPropositions={contractPropositions}
        sidebarIsOpen={sidebarIsOpen}
        setSidebarIsOpen={setSidebarIsOpen}
        sidebarIsLocked={sidebarIsLocked}
        setSidebarIsLocked={setSidebarIsLocked}
        sidebarIsMorphing={sidebarIsMorphing}
        setSidebarIsMorphing={setSidebarIsMorphing}
        userMenus={userMenus}
      />

      {isBelowMd && (
        <Menubar
          userMenus={userMenus}
          contractPropositions={contractPropositions}
        />
      )}

      <div
        style={{
          marginLeft: sidebarIsLocked && !isBelowMd ? 242 : !sidebarIsLocked && !isBelowMd ? 72 : 0
        }}
        className="transition-all grow max-h-screen md:pb-0 "
      >
        {children}
      </div>

      <FirstConnect />

      <Dialog
        open={!!isSpiralOpen}
        onClose={(e) => setIsSpiralOpen(false)}
        onMouseDown={(e) => e.stopPropagation()}
        onClick={(e) => e.stopPropagation()}
      >
        <DialogTitle>{t('toWhoDoYouWantToAssignTheTicket')}</DialogTitle>
        <List style={{ padding: 0 }}>
          <ListItem
            button
            onClick={() => [isSpiralOpen.cb(1), setIsSpiralOpen(false)]}
          >
            <ListItemIcon style={{ marginRight: 16 }}>
              <FAIcon
                collection="fad"
                icon="user-hard-hat"
              />
            </ListItemIcon>
            <ListItemText primary={'Spiral France'} />
          </ListItem>
          <ListItem
            button
            onClick={() => [isSpiralOpen.cb(0), setIsSpiralOpen(false)]}
          >
            <ListItemIcon style={{ marginRight: 16 }}>
              <FAIcon
                collection="fas"
                icon="bolt"
              />
            </ListItemIcon>
            <ListItemText primary={t('usualNFDepannageCompany')} />
          </ListItem>
        </List>
      </Dialog>
    </div>
  );
}
