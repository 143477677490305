import { nanoid } from 'nanoid';
import DaySmall from './DaySmall';

export default function DaysSmallGrid({
  days,
  selected_day_idx,
  set_selected_day_idx,
  now,
  middle_of_the_month
}) {
  return (
    <div className="isolate grid w-full grid-cols-7 grid-rows-6 gap-px lg:hidden">
      {days.map((day, idx) => (
        <DaySmall
          key={nanoid()}
          now={now}
          day={day}
          idx={idx}
          selected_day_idx={selected_day_idx}
          set_selected_day_idx={set_selected_day_idx}
          middle_of_the_month={middle_of_the_month}
        />
      ))}
    </div>
  );
}
