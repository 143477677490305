import { Chip, makeStyles, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useField } from 'frmx';
import useKeys from '@flowsn4ke/usekeys';
import { useTranslation } from 'react-i18next';
import FieldBase from './FieldBase';

const useStyles = makeStyles((t) => ({
  popperHide: {
    display: 'none'
  }
}));

export default function OptionsField({ mandatoryOption, lineSizeOption }) {
  const path = 'extra.options';
  const { value: selectedOptions, setValue: setSelectedOptions } = useField(path);
  const { t } = useTranslation();
  const k = useKeys();
  const c = useStyles();

  return (
    <FieldBase
      mandatoryOption={mandatoryOption}
      lineSizeOption={lineSizeOption}
    >
      <Autocomplete
        classes={{ popper: c.popperHide }}
        multiple
        value={selectedOptions || []}
        onChange={(e, values) => {
          setSelectedOptions(values);
        }}
        options={[]}
        freeSolo
        renderTags={(values, getTagProps) => {
          return selectedOptions.map((option, index) => (
            <Chip
              key={k(index)}
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
            />
          ));
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={t('options')}
          />
        )}
      />
    </FieldBase>
  );
}
