import { Box, useTheme } from '@material-ui/core';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

import { useMemo } from 'react';
import randomFlatColor from 'random-flat-colors';
import bobColors from 'constants/bobColors';

export default function LinesChart({
  datasets,
  labels,
  colors = [],
  datasetsLabels,
  formatYAxis,
  tooltipLabels,
  is_overriden_tooltip_label = false,
  format_tooltip_labels
}) {
  const theme = useTheme();
  const memoizedDatasets = useMemo(() => {
    return datasets.map((datapoints, i) => {
      const color = bobColors[i] || randomFlatColor();
      return {
        label: datasetsLabels[i],
        data: datapoints,
        fill: false,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: color,
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: color,
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: color,
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        tension: 0.4
      };
    });
  }, [datasets, datasetsLabels]);

  return (
    <Box style={{ width: '100%', height: '100%' }}>
      <Line
        data={{
          labels,
          datasets: memoizedDatasets
          // labels: dates.map((d, i) => last.value > 1 ? `${d.month}/${d.year}` : ['S-3', 'S-2', 'S-1', 'S en cours'][i])
        }}
        options={{
          maintainAspectRatio: false,
          responsive: true,
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              callbacks: {
                ...(Array.isArray(tooltipLabels) &&
                  tooltipLabels.length > 0 && {
                    label: (ctx) => {
                      if (is_overriden_tooltip_label) {
                        return format_tooltip_labels(ctx, tooltipLabels);
                      }
                      // Default tooltip label, if you want not to override it.
                      return (
                        (Array.isArray(tooltipLabels) && tooltipLabels[ctx.dataIndex]) ||
                        ctx.formattedValue
                      );
                    }
                  })
              }
            }
          },
          tooltips: {
            backgroundColor: theme.palette.primary.dark
          },
          scales: {
            xAxes: {
              ticks: {
                fontColor: theme.palette.primary.main
              }
            },
            yAxes: {
              ticks: {
                fontColor: theme.palette.primary.main,
                callback: formatYAxis
              }
            }
          }
        }}
      />
    </Box>
  );
}
