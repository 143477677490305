import Customer from 'entities/Customer/Customer';

export default function CustomerPage() {
  return (
    <Customer
      page
      off
    />
  );
}
