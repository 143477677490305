import { AppBar, BottomNavigation, Badge, BottomNavigationAction } from '@material-ui/core';
import FAIcon from 'components/ui/FAIcon';

import { useEffect, useMemo, useState } from 'react';
import useStyles from './Sidebar.styles';

import { useTranslation } from 'react-i18next';
import useKeys from '@flowsn4ke/usekeys';
import { useHistory } from 'react-router';
import classNames from 'utils/classNames';

export default function Menubar({ userMenus, contractPropositions }) {
  const { t } = useTranslation();
  const k = useKeys();
  const history = useHistory();

  const menus = useMemo(() => userMenus.filter((menuItem) => menuItem.mobile));

  const [value, handleChange] = useState(null);
  useEffect(() => value && history.push(value), [value]);
  useEffect(() => {
    const menu = menus.find((menuItem) => menuItem.url.includes(history.location.pathname));
    handleChange(menu?.url);
  }, [history.location.pathname]);
  const classes = useStyles({});

  return (
    <AppBar
      position="fixed"
      elevation={4}
      style={{ zIndex: 100, bottom: 0, top: 'auto' }}
      color="secondary"
    >
      <BottomNavigation
        style={{ height: 0, width: '100%' }}
        showLabels
        value={value}
        onChange={(e, v) => handleChange(v)}
      >
        {menus.map((menuItem, i) => (
          <BottomNavigationAction
            label={
              <strong
                style={{
                  fontWeight: 500,
                  marginTop: 6,
                  display: 'block'
                }}
              >
                {t(menuItem.label)}
              </strong>
            }
            key={k(i)}
            value={menuItem.url}
            icon={
              <Badge
                className={classes.listItemBadge}
                badgeContent={
                  menuItem.entity === 'contractproposition' ? contractPropositions.count : 0
                }
              >
                <FAIcon
                  className={classNames(classes.icon, 'icon')}
                  collection="fad"
                  icon={menuItem.icon}
                  size="medium"
                />
              </Badge>
            }
          />
        ))}
      </BottomNavigation>
    </AppBar>
  );
}
