import { ClockIcon } from '@heroicons/react/20/solid';
import { format } from 'date-fns';
import { useUI } from 'hooks/useUI';
import { UNAVAILABILITY_TYPE } from '../config';

export default function EventList({ events, set_ticket_in_modal, set_unavailability_id_to_edit }) {
  const ui = useUI();
  const locale = ui.get_date_time_locale();

  if (!events?.length) return null;

  const handle_click = (event) => {
    return event.type === UNAVAILABILITY_TYPE
      ? set_unavailability_id_to_edit(event.unavailability_id)
      : set_ticket_in_modal(event.ticket_id);
  };

  return (
    <div className="py-10 px-4 sm:px-6 lg:hidden">
      {/* {new Array(5).fill(0).map((_, idx) => <div className="h-12 w-full bg-gray-500" />)} */}
      <ol className="flex flex-col bg-white divide-y divide-gray-100 rounded-lg text-sm shadow ring-1 ring-black ring-opacity-5">
        {/* {new Array(9).fill(0).map((_, idx) => <div className="h-12 w-full bg-gray-500" />)} */}
        {events.map((event) => (
          <li
            key={event._id}
            className="group flex p-4 pr-6 focus-within:bg-gray-50 hover:bg-gray-50 cursor-pointer"
            onClick={() => handle_click(event)}
          >
            <div className="flex-auto">
              <p className="font-semibold text-gray-900">{event.title}</p>
              <time
                dateTime={event.date}
                className="mt-2 flex items-center text-gray-700"
              >
                <ClockIcon
                  className="mr-2 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                {format(event.start, 'p', { locale })}
              </time>
            </div>
          </li>
        ))}
      </ol>
    </div>
  );
}
