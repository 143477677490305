import { Box, ButtonBase } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import useStyles from 'layouts/entities/Preview.styles';

export default function SupplierSkeleton() {
  const classes = useStyles();

  return (
    <Box className={classes.preview}>
      <ButtonBase className={classes.previewButton}>
        <Box className={classes.previewContainer}>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
          >
            <Skeleton
              animation="wave"
              variant="text"
              width={32}
              height={18}
            />
          </Box>

          <Box
            display={'flex'}
            justifyContent={'space-between'}
          >
            <Skeleton
              animation="wave"
              variant="text"
              width={60}
              height={25}
            />
          </Box>

          <Box
            display={'flex'}
            justifyContent={'space-between'}
          >
            <Skeleton
              animation="wave"
              variant="text"
              width={200}
              height={16}
            />
          </Box>
        </Box>
      </ButtonBase>
    </Box>
  );
}
