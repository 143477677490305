import { Grid, TextField } from '@material-ui/core';
import Role from 'entities/Role/Role';
import { useConfiguration } from 'hooks/useConfiguration';
import { Field, useFieldObserver } from 'frmx';
import PhoneField from 'components/inputs/PhoneField';
import EntityX from 'components/inputs/EntityX';
import { useTranslation } from 'react-i18next';
import Location from 'entities/Location/Location';
import { LangSelect } from 'entities/User/UserForm';
import Job from 'entities/Job/Job';
import Client from 'entities/Client/Client';
import { useAuth } from 'hooks/useAuth';

export default function ManagerForm() {
  const configuration = useConfiguration();
  const { t } = useTranslation();
  const auth = useAuth();
  const scope_clients = useFieldObserver('_scope_clients');

  const defaultFilterLocations = {
    ...(scope_clients?.length > 0 ? { clients: scope_clients.map((c) => c._id) } : {})
  };

  const isContractor = auth?.interface?.isContractor;

  return (
    <>
      <Grid
        item
        xs={12}
        sm={6}
      >
        <Field path="firstName">
          <TextField
            autoFocus
            variant="outlined"
            label={t('firstName')}
            fullWidth
          />
        </Field>
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
      >
        <Field path="lastName">
          <TextField
            variant="outlined"
            label={t('lastName')}
            fullWidth
          />
        </Field>
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
      >
        <Field
          isErrorProp="error"
          path="email"
        >
          <TextField
            variant="outlined"
            label={t('email')}
            fullWidth
          />
        </Field>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
      >
        <LangSelect
          path="lang"
          label={t('lang')}
        />
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
      >
        <PhoneField
          path="phone"
          label={t('cellphoneNumber')}
          variant="outlined"
          placeholder={t('cellphoneNumber')}
        />
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
      >
        <PhoneField
          path="fix"
          label={t('landlineNumber')}
          variant="outlined"
          placeholder={t('landlineNumber')}
        />
      </Grid>

      <Grid
        item
        xs={12}
      >
        <EntityX
          path="_role"
          entity={Role}
          entityProps={{
            defaultFilters: {
              types: [configuration.isMaster ? 'master' : configuration.isContractor ? 'contractor' : 'client']
            }
          }}
          placeholder={t('role')}
          pickerUniq
        />
      </Grid>

      <div className="mx-4 p-2 text-gray-600 text-xs w-full border-b my-2">{t('visibility-scope')}</div>

      {isContractor && (
        <Grid
          item
          xs={12}
        >
          <EntityX
            path="_scope_clients"
            entity={Client}
            placeholder={t('clientsTitle')}
          />
        </Grid>
      )}
      <Grid
        item
        xs={12}
      >
        <EntityX
          path="_locations"
          entity={Location}
          placeholder={t('locations')}
          entityProps={{
            lockClients: true,
            defaultFilters: defaultFilterLocations
          }}
        />
      </Grid>

      <Grid
        item
        xs={12}
      >
        <EntityX
          path="_jobs"
          entity={Job}
          placeholder={t('jobsTitle')}
        />
      </Grid>
    </>
  );
}
