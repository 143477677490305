import { createSlice } from '@reduxjs/toolkit';
import { generateEntityBoilerplate } from 'utils/generateEntitySlice';
import generateAsyncThunk from 'utils/generateAsyncThunk';

// * Old version
export const createElement = generateAsyncThunk({ type: 'POST', endpoint: 'equipments' });
export const updateElement = generateAsyncThunk({ type: 'PUT', endpoint: 'equipments/${id}' });
export const getElement = generateAsyncThunk({ type: 'GET', endpoint: 'equipments/${id}' });
export const getElements = generateAsyncThunk({ type: 'POST', endpoint: 'equipments/list' });
export const getPublicElements = generateAsyncThunk({
  type: 'POST',
  endpoint: 'equipments/publicList'
});
export const deleteElement = generateAsyncThunk({ type: 'DELETE', endpoint: 'equipments/${id}' });
export const restoreElement = generateAsyncThunk({
  type: 'POST',
  endpoint: 'equipments/recycle/${id}'
});
export const duplicateElement = generateAsyncThunk({
  type: 'GET',
  endpoint: 'equipments/duplicate/${id}'
});
export const linkStocksToEquipment = generateAsyncThunk({
  type: 'PUT',
  endpoint: 'equipments/${id}/linkStocksToEquipment'
});
export const uploadCover = generateAsyncThunk({ type: 'FILE', endpoint: 'image' });

const entityBoilerplate = generateEntityBoilerplate({
  createElement,
  updateElement,
  getElement,
  getElements,
  getPublicElements,
  deleteElement,
  restoreElement,
  createElementNext: (state, action) => {
    const { element } = action.payload.data;
    const { parentEquipment } = action.meta.arg;

    if (parentEquipment && state.db[parentEquipment]) {
      state.db[parentEquipment]._equipments.push(element._id);
    }
  }
});

const { initialState, reducers, extraReducers } = entityBoilerplate;

export const equipmentsSlice = createSlice({
  name: 'equipments',
  initialState: { ...initialState },
  reducers: {
    ...reducers,
    updateDocuments: (state, action) => {
      const { _id, sub } = action.payload;
      if (state.db[_id]) {
        state.db[_id].tabs.documents = sub ? state.db[_id].tabs.documents - 1 : state.db[_id].tabs.documents + 1;
      }
    }
  },
  extraReducers: {
    ...extraReducers,
    [duplicateElement.fulfilled]: (state, action) => {
      const { listId } = action.meta;
      const { _id } = action.payload.data.element;

      state.db[_id] = action.payload.data.element;

      state.lists[listId] = [{ _id }, ...state.lists[listId]];
      state.lists[listId].count += 1;
    },
    [linkStocksToEquipment.fulfilled]: (state, action) => {}
  }
});

export const { flushElements, flushElement, updateDocuments } = equipmentsSlice.actions;
export default equipmentsSlice.reducer;
