const LocationTranslations = (config, { sublistParent, facturation }) => ({
  pick: 'entitySupplierPick',
  createSuccess: 'entitySupplierCreateSuccess',
  updateSuccess: 'entitySupplierUpdateSuccess',
  createDialog: 'entitySupplierCreateDialog',
  updateDialog: 'entitySupplierUpdateDialog',
  create: 'entitySupplierCreate',
  update: 'entitySupplierUpdate',
  entityName: 'entitySupplierName',
  noResultLabel: 'entitySuppliernoResultLabel',
  searchText: 'entitySupplierSearchText',
  noResultText: 'entitySuppliernoResultText',
  noSelectText: 'entitySuppliernoSelectText'
});

export default LocationTranslations;
