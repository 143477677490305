import { Button } from '@material-ui/core';
import axios from 'axios';
import FAIcon from 'components/ui/FAIcon';
import useFileViewer from 'hooks/useFileViewer';
import { apiBaseURL } from 'index';
import useStyles from 'layouts/entities/View.styles';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'utils/classNames';
import ActionButton from '../buttons/ActionButton';
import ReasonDialog from 'components/dialogs/ReasonDialog';

export const FileActions = ({ company_id, actions, devis }) => {
  const { files, openIndex, setOpenIndex, append_files } = useFileViewer();
  const classes = useStyles();
  const { t } = useTranslation();
  const [bpu, setBpu] = useState(null);
  const [isLoadingBpu, setIsLoadingBpu] = useState(true);
  const [isReasonDialogOpen, setIsReasonDialogOpen] = useState(false);
  const handleDecline = () => {
    setIsReasonDialogOpen(true);
  };

  const handleConfirmReason = (reason) => {
    actions.devis_validation.action(false, reason, devis._id);
    setIsReasonDialogOpen(false);
  };
  useEffect(() => {
    const fetchBpu = async () => {
      if (company_id) {
        try {
          const bpu_url = `${apiBaseURL}/bpus/${company_id}/active`;
          const res = await axios.get(bpu_url);
          setBpu(res.data.document);
          setIsLoadingBpu(false);
        } catch (error) {
          console.error(error);
          setIsLoadingBpu(false);
        }
      }
    };
    fetchBpu();
  }, [company_id]);
  const handleBpuButtonClick = () => {
    if (isLoadingBpu) return;
    if (files.length <= 1) {
      append_files([bpu]);
    }
    setOpenIndex(files[openIndex]?.type === 'bpu' ? 0 : 1);
  };
  return (
    <div className="flex flex-col gap-y-4 items-center">
      {(Boolean(bpu) || isLoadingBpu) && (
        <Button
          onClick={handleBpuButtonClick}
          endIcon={
            <FAIcon
              collection="fal"
              icon="clipboard-list"
            />
          }
          variant="contained"
          size="large"
          className={classNames('w-36', classes.viewActionInfo)}
          style={{ marginBottom: '3rem' }}
          disabled={isLoadingBpu}
        >
          {isLoadingBpu ? (
            <FAIcon
              collection="fas"
              icon="spinner-third"
              className="animate-spin"
            />
          ) : (
            t(files[openIndex]?.type === 'bpu' ? 'Devis' : 'BPU')
          )}
        </Button>
      )}
      {actions.devis_validation?.show && (
        <>
          <ActionButton
            onClick={() => actions.devis_validation.action(true, null, devis._id)}
            icon="thumbs-up"
            label={t('acceptQuote')}
            variant="success"
          />

          <div>
            <ActionButton
              onClick={() => handleDecline()}
              icon="thumbs-down"
              label={t('decline')}
              variant="error"
            />
            <ReasonDialog
              open={isReasonDialogOpen}
              onClose={() => setIsReasonDialogOpen(false)}
              onConfirm={handleConfirmReason}
            />
          </div>
        </>
      )}
    </div>
  );
};
