import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  formContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  spacing: {
    margin: '16px 0px',
    width: '100%',
    borderRadius: 34,
    ['& .MuiOutlinedInput-root']: {
      borderRadius: 34
    }
  },
  submitButtonContainer: {
    paddingLeft: 24,
    position: 'relative',
    padding: '10px 16px',
    borderRadius: 34,
    justifyContent: 'space-between'
  },
  message: {
    marginBottom: 24
  },
  messageError: {
    textAlign: 'center'
  },
  spinner: {
    ...theme.spinnerAnimation
  },
}));
