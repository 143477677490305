const MaintenanceTranslations = (configuration) => ({
  pick: 'entityMaintenancePick',
  createDialog: 'entityMaintenanceCreateDialog',
  updateDialog: 'entityMaintenanceUpdateDialog',
  create: 'entityMaintenanceCreate',
  update: 'entityMaintenanceUpdate',
  entityName: 'entityMaintenanceName',
  noResultLabel: 'entityMaintenancenoResultLabel',
  searchText: 'entityMaintenanceSearchText'
});

export default MaintenanceTranslations;
