import { Button, Badge } from '@material-ui/core';

import { useField } from 'frmx';
import FAIcon from 'components/ui/FAIcon';
import { useTranslation } from 'react-i18next';

export default function AttachmentField({
  path,
  disabled,
  multiple,
  label,
  accept = '*',
  icon = 'paperclip'
}) {
  const { value, setValue } = useField(path);

  const { t } = useTranslation();

  return (
    <Button
      disabled={disabled}
      component="label"
      startIcon={
        <Badge
          color="primary"
          badgeContent={value?.length}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          <FAIcon
            icon={icon}
            collection="fad"
          />
        </Badge>
      }
    >
      <input
        hidden
        accept={accept}
        type="file"
        multiple={multiple}
        onChange={(e) => setValue((prev) => (prev || []).concat(Array.from(e.target.files)))}
      />
      {label ? label : t('attachments')}
    </Button>
  );
}
