// pages for get api key
import { useEffect } from 'react';

import { Box, Button, Grid } from '@material-ui/core';

import useAsyncDispatch from 'hooks/useAsyncDispatch';
import useNotifications from 'hooks/useNotifications';

import { createApiToken, getApiToken } from 'store/authSlice';

import AccordionForm from 'components/forms/AccordionForm';

import useFormStyles from 'styles/Form.styles';
import { useTranslation } from 'react-i18next';

import TokenField from 'components/inputs/TokenField';
import { useAuth } from 'hooks/useAuth';

export default function ApiTokenConfiguration({ section }) {
  const { t } = useTranslation();
  const { dispatch } = useAsyncDispatch();

  // dispach pour recupere les tokens dans la base de données dans la table tokens ?
  useEffect(() => {
    dispatch(getApiToken);
  }, []);

  const auth = useAuth();

  return (
    <Box>
      <AccordionForm
        section={section}
        allwaysExpanded
      >
        {Boolean(auth.api_tokens?.length) && (
          <div>
            {auth.api_tokens?.map((token, index) => {
              return (
                <div
                  key={index}
                  className="mb-12"
                >
                  <Grid
                    item
                    xs={12}
                    className="relative px-5"
                  >
                    <TokenField
                      path="tokenApi"
                      label={t(token.label)} /* {t("tokenApi")} */
                      variant="outlined"
                      id={index}
                      fullWidth
                      value={token.token}
                      index={index.toString()}
                      is_active={token.is_active}
                      token_id={token._id}
                      inputProps={{
                        readOnly: true
                      }}
                    />
                  </Grid>
                </div>
              );
            })}
          </div>
        )}

        <ButtonGenerateToken />
      </AccordionForm>
    </Box>
  );
}

function ButtonGenerateToken() {
  const { t } = useTranslation();
  const formClasses = useFormStyles();
  const { dispatch } = useAsyncDispatch();
  const notify = useNotifications();

  const dispatchCallbacks = ({ success, error }) => ({
    onSuccess: () => notify.success(success),
    onError: () => notify.error(error)
  });

  return (
    <div className="w-100 flex justify-center mb-8">
      <Button
        onClick={() =>
          dispatch(
            createApiToken,
            undefined,
            dispatchCallbacks({
              success: t('tokenGenerated'),
              error: t('tokenGenerateError')
            })
          )
        }
        size="medium"
        className={formClasses.success}
      >
        {t('generateToken')}
      </Button>
    </div>
  );
}
