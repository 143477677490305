import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  makeStyles,
  FormHelperText
} from '@material-ui/core';
import { useField } from 'frmx';
import useKeys from '@flowsn4ke/usekeys';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  errorColor: {
    color: theme.palette.error.main
  }
}));

export default function CustomRadio({ valueControlled, onChange, path, customField, required }) {
  const { label, extra } = customField;

  const { value, setValue, error, disabled } = useField(path);

  const k = useKeys();
  const c = useStyles();
  const { t } = useTranslation();

  return extra?.options ? (
    <FormControl
      component="fieldset"
      style={{ width: '100%' }}
    >
      <FormLabel component="legend">{t(label) + (required ? ' *' : '')}</FormLabel>
      <Box
        maxHeight={'150px'}
        overflow={'auto'}
        width={'100%'}
      >
        <RadioGroup
          value={valueControlled || value}
          onChange={(e) => (onChange ? onChange(e.target.value) : setValue(e.target.value))}
        >
          {extra.options.map((option, i) => {
            return (
              <FormControlLabel
                key={k(i)}
                control={<Radio disabled={disabled} />}
                value={option}
                label={option}
                required={required}
              />
            );
          })}
        </RadioGroup>
      </Box>

      {error && <FormHelperText className={c.errorColor}>{t('pleaseChooseAValue')}</FormHelperText>}
    </FormControl>
  ) : null;
}
