import Popover from 'components/ui/Popover';
import { cloneElement } from 'react';

export default function Menu({ button, items, className }) {
  return (
    <Popover
      offset={[16, 1]}
      placement="bottom-start"
      className="z-10"
      button={button}
      contents={({ close }) => (
        <div className={`flex flex-col z-10 rounded w-auto p-0.5 ${className}`}>
          {items.map((menuItem, menuIdx) => cloneElement(menuItem, { key: menuIdx, close }))}
        </div>
      )}
    />
  );
}
