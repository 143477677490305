import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    marginTop: 16
  },
  top: {
    color: 'white',
    display: 'flex',
    fontWeight: 500,
    alignItems: 'center',
    fontSize: 14
  },
  checkbox: {
    marginRight: 6,
    transform: 'scale(1.3)'
  },
  info: {
    color: 'white',
    marginLeft: 'auto'
  },
  logo: {
    height: 24,
    marginLeft: 4,
    position: 'relative',
    bottom: 4
  },
  bb: {
    width: '100%',
    padding: 10,
    justifyContent: 'flex-start',
    borderRadius: theme.radius,
    overflow: 'hidden',
    boxShadow: theme.shadows[1],
    cursor: 'pointer',
    alignItems: 'flex-start',
    textAlign: 'left',
    flexDirection: 'column',
    backgroundImage: `linear-gradient(to right, ${theme.palette.primary.main} 0%, ${theme.palette.primary.light} 100%)`
  },
  hint: {
    padding: '6px 16px',
    color: 'white'
  },
  email: {
    width: '100%',
    boxShadow: theme.shadows[1],
    background: theme.palette.primary.light,
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: 16,
    cursor: 'pointer',
    borderRadius: theme.radius,
    marginTop: 4
  },
  emailIcon: {
    marginRight: 6
  },
  emailCheckbox: {}
}));
