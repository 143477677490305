import { Fragment } from 'react';
import { useUI } from 'hooks/useUI';
import Flag from 'react-flagkit';
import { languages, languagesObject } from 'config/languages';

import useKeys from '@flowsn4ke/usekeys';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { flag_keys } from 'constants/locales';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import { updateUser } from 'store/usersSlice';
import { useAuth } from 'hooks/useAuth';

export function PreviousLanguageSwitch() {
  const ui = useUI();
  const activeLanguage = languagesObject[ui.getLang()];

  const k = useKeys();

  return (
    <Menu
      as="div"
      className="relative inline-block text-left"
    >
      <div>
        <Menu.Button className="inline-flex justify-center items-center w-20 rounded-md border border-gray-300 shadow-sm px-2 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
          <Flag
            country={flag_keys[activeLanguage.key]}
            className="h-3"
          />
          &nbsp;{activeLanguage.key.toUpperCase()}
          <ChevronDownIcon
            className="-mr-1 ml-2 h-4 w-4"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="z-[1400] origin-top-right absolute right-0 mt-2 w-20 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
          <div className="py-1">
            {languages.map((language, i) => (
              <Menu.Item key={k(i)}>
                {({ active }) => (
                  <button
                    onClick={() => ui.setLang(language)}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'group flex items-center px-4 py-2 text-sm'
                    )}
                  >
                    <Flag
                      country={flag_keys[language.key]}
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    {language.key.toUpperCase()}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export function LanguageSwitch() {
  const ui = useUI();
  const activeLanguage = languagesObject[ui.getLang()];
  const { dispatch } = useAsyncDispatch();
  const auth = useAuth();

  const k = useKeys();

  const handleLanguageChange = (language) => {
    try {
      ui.setLang(language);
      dispatch(updateUser, { lang: language.key }, {}, { id: auth?.user?._id });
    } catch (error) {
      console.error('error', error);
    }
  };

  return (
    <Menu
      as="div"
      className="relative inline-block text-left"
    >
      <div>
        <Menu.Button className="inline-flex justify-center items-center w-20 rounded-md border border-gray-300 shadow-sm px-2 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
          <Flag
            country={flag_keys[activeLanguage.key]}
            className="h-3"
          />
          &nbsp;{activeLanguage.key.toUpperCase()}
          <ChevronDownIcon
            className="-mr-1 ml-2 h-4 w-4"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="z-[1400] origin-top-right absolute right-0 mt-2 w-20 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
          <div className="py-1">
            {languages.map((language, i) => (
              <Menu.Item key={k(i)}>
                {({ active }) => (
                  <button
                    // update element contact updateElement
                    onClick={() => handleLanguageChange(language)}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'group flex items-center px-4 py-2 text-sm'
                    )}
                  >
                    <Flag
                      country={flag_keys[language.key]}
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    {language.key.toUpperCase()}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
