import { Box, Button, Divider } from '@material-ui/core';

import { useIsBelowSm } from 'hooks/useMQ';

import { useForm } from 'frmx';

import PopoverDrawer from 'components/ui/PopoverDrawer';
import { useTranslation } from 'react-i18next';

import FilterEntity from 'components/filters/FilterEntity';
import FilterList from 'components/filters/FilterList';
import FilterField from 'components/filters/FilterField';
import FilterDate from 'components/filters/FilterDate';
import FilterCustom from 'components/filters/FilterCustom';

import React from 'react';

const filterTypes = {
  Entity: (props) => <FilterEntity {...props} />,
  List: (props) => <FilterList {...props} />,
  Custom: (props) => <FilterCustom {...props} />,
  Field: (props) => <FilterField {...props} />,
  Date: (props) => <FilterDate {...props} />
};

export default function PopoverFilter({
  filterBar,
  filterIndex,
  filter,
  setFilterIndex,
  classes,
  setAppliedFilters,
  appliedFilters
}) {
  const { handleSubmit } = useForm();
  const isBelowSm = useIsBelowSm();
  const { t } = useTranslation();

  return (
    <PopoverDrawer
      fromBottom
      transitionDuration={0}
      anchorEl={filterBar.current}
      PaperProps={{ style: { width: filterBar.current?.offsetWidth - 20 }, elevation: 2 }}
      onClose={(e) => [handleSubmit(e), setFilterIndex(null)]}
      open={filterIndex !== null}
    >
      <Box style={{ width: '100%', background: 'white', overflow: 'hidden', borderRadius: 8 }}>
        {isBelowSm && <Box className={classes.header}>{t(filter.label)}</Box>}

        {!!filter && <Box>{filter.type && filterTypes[filter.type]({ filter: filter })}</Box>}
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          padding="4px 6px"
        >
          <Button
            onClick={(e) => {
              setAppliedFilters({
                ...appliedFilters,
                [filter.key]: filter.default
              });
              setFilterIndex(null);
            }}
            color="secondary"
            size="small"
          >
            {t('erase')}
          </Button>

          <Button
            onClick={(e) => [handleSubmit(e), setFilterIndex(null)]}
            color="primary"
            size="small"
          >
            {t('validate')}
          </Button>
        </Box>
      </Box>
    </PopoverDrawer>
  );
}
