import {
  Button,
  Chip,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Typography,
  ListItemSecondaryAction,
  IconButton,
  Dialog,
  DialogContent,
  DialogContentText,
  TextField
} from '@material-ui/core';

import { deleteInterventionReport, liftTheReserve } from 'store/ticketsSlice';
import { useState } from 'react';

import { dateToLocalFormat } from 'utils/dates';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import useNotifications from 'hooks/useNotifications';
import { useRole } from 'hooks/useRole';
import ConfirmDialog from 'components/dialogs/ConfirmDialog';
import FAIcon from 'components/ui/FAIcon';
import Ticket from 'entities/Ticket/Ticket';
import { trim } from 'utils/trim';
import BlurredProgress from 'components/BlurredProgress';
import useFileViewer from 'hooks/useFileViewer';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'hooks/useAuth';
import axios from 'axios';
import { apiBaseURL } from 'index';
import { sendEmail, sendEmailToLocation } from 'store/reportsSlice';
import { Field, Form, Submit } from 'frmx';
import DialogTitle from 'components/dialogs/DialogTitle';

export default function ReportItem({
  ticket,
  deletable,
  report,
  company,
  setFormIsOpen,
  setSelectedTemplate,
  setSelectedReport
}) {
  const [deleteIsOpen, setDeleteIsOpen] = useState(false);
  const [liftReserveIsOpen, setLiftReserveIsOpen] = useState(false);
  const ticketId = ticket?._id;

  const { dispatch, requestStatus } = useAsyncDispatch();
  const notify = useNotifications();
  const dispatchCallbacks = ({ success, error }) => ({
    onSuccess: () => notify.success(success),
    onError: () => notify.error(error)
  });

  const { t } = useTranslation();

  const { openFiles } = useFileViewer();

  const deleteReport = () =>
    dispatch(deleteInterventionReport, undefined, dispatchCallbacks({ success: t('reportWasIndeedDeleted') }), {
      id: report._id,
      ticketId
    });
  const openInEditor = () => [setSelectedReport(report), setSelectedTemplate(report._template), setFormIsOpen(true)];
  const openPDF = () => openFiles([report._document]);

  const liftThisReserve = () =>
    dispatch(liftTheReserve, undefined, dispatchCallbacks({ success: t('reserveWasIndeedLifted') }), {
      id: report._id,
      _ticket: ticketId
    });

  const { permission } = useRole();
  const canEditReports = permission('reports', 'update_report');
  const canDeleteReports = permission('reports', 'delete_report');
  const canLiftReserve = permission('maintenances', 'lift_reserve');

  const isCustomReport = report?.loaded;
  const isContractor = useAuth().interface.isContractor;

  const [isOpenDialog, setIsOpenDialog] = useState(false);

  return (
    <>
      <ConfirmDialog
        open={deleteIsOpen}
        onClose={() => setDeleteIsOpen(false)}
        onConfirm={() => deleteReport()}
        text={t('sureYouWantToDeleteThisReport')}
      />
      {isCustomReport && (
        <ConfirmDialog
          open={liftReserveIsOpen}
          onClose={() => setLiftReserveIsOpen(false)}
          onConfirm={() => liftThisReserve()}
          text={t('sureYouWantToLiftTheReserve')}
        />
      )}

      <ListItem
        button
        disabled={requestStatus === 'loading'}
        onClick={!!report?.finished_at || isCustomReport ? openPDF : openInEditor}
      >
        <BlurredProgress in={requestStatus === 'loading'} />
        <ListItemAvatar style={{ marginLeft: '1em' }}>
          <FAIcon
            style={{
              color:
                (isCustomReport && !report?.reserve) || !!report?.finished_at
                  ? 'green'
                  : isCustomReport && report?.reserve
                  ? 'red'
                  : ''
            }}
            collection="fal"
            icon={
              isCustomReport && report?.reserve
                ? 'ban'
                : isCustomReport && !report?.reserve
                ? 'circle-check'
                : report.signatureDate?.client && report.signatureDate?.contractor
                ? 'check-double'
                : !report.signatureDate?.client && !report.signatureDate?.contractor
                ? 'hourglass-start'
                : 'check'
            }
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            isCustomReport ? (
              <>
                {report._document?.value
                  ? trim(report._document?.value?.slice(0, report._document?.value?.lastIndexOf('.'))) +
                    report._document?.value?.slice(report._document?.value?.lastIndexOf('.'))
                  : report._document?.name}
              </>
            ) : (
              <>
                <Typography
                  variant="subtitle2"
                  component="span"
                >
                  {report._template.label} (
                  {t('createdOnX', {
                    date: dateToLocalFormat(new Date(report.created_at), 'PPPp')
                  })}
                  )
                </Typography>
              </>
            )
          }
          secondary={
            isCustomReport ? (
              <>
                {t(report.reserve ? 'withReserves' : report.reserveDate ? 'reserveLifted' : 'withoutReserves')}
                {report?.urgency > 0 && (
                  <Chip
                    style={{
                      marginLeft: '.5em',
                      color: { 1: '#F39C12', 2: 'red', 3: 'black' }[report?.urgency],
                      borderColor: { 1: '#F39C12', 2: 'red', 3: 'black' }[report?.urgency]
                    }}
                    variant="outlined"
                    label={{ 1: t('weak'), 2: t('notSoWeak'), 3: t('critical') }[report?.urgency]}
                    size="small"
                  />
                )}
              </>
            ) : (
              <>
                {report.signatureDate.client && (
                  <>
                    <Typography
                      variant="caption"
                      component="span"
                    >
                      {t('signedByXOnY', {
                        name: report.signatureName.client + ' ' + company.name,
                        date: dateToLocalFormat(new Date(report.signatureDate.client), 'PPPp')
                      })}
                    </Typography>
                    <br />
                  </>
                )}

                {report.signatureDate.contractor && (
                  <>
                    <Typography
                      variant="caption"
                      component="span"
                      style={{ lineHeight: '.5em' }}
                    >
                      {t('signedByXOnY', {
                        name: report.signatureName.contractor,
                        date: dateToLocalFormat(new Date(report.signatureDate.contractor), 'PPPp')
                      })}
                    </Typography>
                  </>
                )}
              </>
            )
          }
        />
        <ListItemSecondaryAction style={{ marginRight: '1em' }}>
          {canLiftReserve && isCustomReport && !!report?.reserve && (
            <Button
              size="small"
              onClick={() => setLiftReserveIsOpen(true)}
            >
              {t('liftTheReserve')}
            </Button>
          )}
          {isContractor && (
            <IconButton onClick={() => setIsOpenDialog(true)}>
              <FAIcon
                size="small"
                collection="fal"
                icon="paper-plane"
              />
            </IconButton>
          )}
          {report?._ticket_link && (
            <IconButton>
              <Ticket childrenId={report?._ticket_link}>
                <FAIcon
                  size="small"
                  collection="fal"
                  icon="files"
                />
              </Ticket>
            </IconButton>
          )}
          {!!report?.finished_at || isCustomReport ? (
            <IconButton onClick={openPDF}>
              <FAIcon
                size="small"
                collection="fal"
                icon="arrow-up-right-from-square"
              />
            </IconButton>
          ) : !report?.finished_at && !isCustomReport && canEditReports ? (
            <IconButton onClick={openInEditor}>
              <FAIcon
                size="small"
                collection="fal"
                icon="pen-to-square"
              />
            </IconButton>
          ) : null}
          {canDeleteReports && deletable && (
            <IconButton onClick={() => setDeleteIsOpen(true)}>
              <FAIcon
                size="small"
                collection="fal"
                icon="trash-can"
              />
            </IconButton>
          )}
        </ListItemSecondaryAction>
      </ListItem>

      <DialogReport
        isOpen={isOpenDialog}
        onClose={() => setIsOpenDialog(false)}
        report={report}
        ticket={ticket}
      />
    </>
  );
}

export const DialogReport = ({ isOpen, onClose, report, ticket }) => {
  const { t } = useTranslation();
  const ticketId = ticket?._id;

  const report_id = report?._id;

  const { dispatch } = useAsyncDispatch();
  const notify = useNotifications();
  const [emails, setEmails] = useState(null);
  const [error, setError] = useState('');

  const validateEmails = (emails) => {
    if (!emails.length) return t('pleaseFillTheField');

    const invalidEmails = emails.filter((email) => !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email));
    if (invalidEmails.length) return t('invalid-emails') + ": '" + invalidEmails.join(', ') + "'";
  };

  const sendReportToClient = async () => {
    return axios
      .post(`${apiBaseURL}/ir2/${report_id}/send-ir-to-client`, { ticket_id: ticketId })
      .then((res) => {
        return res.status === 204
          ? notify.error(t('emailNotSentMakeSureYourRecipientHasEmail'))
          : notify.success(t('reportWasSent'));
      })
      .catch(() => notify.error());
  };

  const sendReportToLocation = async () => {
    dispatch(
      sendEmailToLocation,
      { ticket_id: ticketId },
      { onError: () => notify.error(), onSuccess: () => notify.success(t('reportWasSent')) },
      { reportId: report_id }
    );
  };

  const handleSubmit = (data) => {
    const emails = data.emails ? [data.emails] : [];

    const emailError = validateEmails(emails);
    if (emailError) {
      setError(emailError);
      return;
    }

    dispatch(
      sendEmail,
      { emails, ticket_id: ticketId },
      {
        onError: () => notify.error(),
        onSuccess: () => notify.success(t('reportWasSent'))
      },
      { reportId: report_id }
    );

    setError('');
    onClose();
  };

  const hasContactEmailLocations = ticket?._locations.some((location) => location?.contact_email?.trim());
  const hasContactEmailClient = !!ticket.views[0]._owner?.email?.trim();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
    >
      <DialogTitle
        onClose={onClose}
        title={t('send-report')}
      />
      <DialogContent>
        <DialogContentText className="flex flex-col gap-6 p-2">
          {hasContactEmailClient && (
            <div className="flex justify-between items-center gap-4 border-b py-2">
              <div>
                <Typography variant="h6">{t('send-to-client')}</Typography>
                {ticket.views[0]._owner?.email && <p>{ticket.views[0]._owner?.email}</p>}
              </div>

              <IconButton
                onClick={() => {
                  sendReportToClient();
                  onClose();
                }}
              >
                <FAIcon
                  collection="fal"
                  icon="paper-plane"
                />
              </IconButton>
            </div>
          )}
          {hasContactEmailLocations && (
            <div className="flex justify-between items-center gap-4 border-b py-2">
              <div>
                <Typography variant="h6">{t('send-to-locations')}</Typography>
                <p>
                  {ticket?._locations
                    .map((location) => {
                      return location?.contact_email;
                    })
                    .join(', ')}
                </p>
              </div>

              <IconButton
                variant="contained"
                onClick={() => {
                  sendReportToLocation();
                  onClose();
                }}
              >
                <FAIcon
                  collection="fal"
                  icon="paper-plane"
                />
              </IconButton>
            </div>
          )}

          <div className="flex flex-col justify-between gap-4">
            <Typography variant="h6">{t('send-by-email')}</Typography>

            <Form
              initialValues={{
                emails: null
              }}
              onSubmit={handleSubmit}
              className="w-full flex justify-between items-center gap-2"
            >
              <Field path="emails">
                <TextField
                  error={!!error}
                  helperText={error}
                  label={t('email')}
                  variant="outlined"
                  onChange={(e) => setEmails(e.target.value)}
                  value={emails}
                  className="w-full"
                  InputProps={{
                    startAdornment: (
                      <FAIcon
                        collection="fal"
                        icon="at"
                        size="small"
                        className={`mr-2`}
                      />
                    )
                  }}
                />
              </Field>

              <Submit>
                <IconButton variant="contained">
                  <FAIcon
                    collection="fal"
                    icon="paper-plane"
                  />
                </IconButton>
              </Submit>
            </Form>
          </div>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
