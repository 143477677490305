import { Box, CardContent, Grid, IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import axios from 'axios';
import BlurredProgress from 'components/BlurredProgress';
import { apiBaseURL } from 'index';
import { useEffect, useState, createElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsBelowUp } from 'hooks/useMQ';
import { truncateText } from 'utils/uiUtils';
import { useAuth } from 'hooks/useAuth';

export default function StatCardPreventifs({
  title,
  chart,
  column1Name,
  column2Name,
  endpoint,
  formatYAxis = (n) => n,
  formatXAxis = (n) => n,
  formatDatasetsLabels = (l) => l,
  formatCount = (c) => c,
  formatDatasets = (datasets) => ({ datasets }),
  style = {},
  scroll = {}
}) {
  const [status, setStatus] = useState();
  const [labels, setLabels] = useState();
  const [tooltipLabels, setTooltipLabels] = useState();
  const [datasets, setDatasets] = useState();
  const [datasetsLabels, setDatasetsLabels] = useState();
  const [colors, setColors] = useState([]);
  const [notEnoughData, setNotEnoughData] = useState(false);

  const { t } = useTranslation();

  const auth = useAuth();
  const locations = auth?.interface?._locations || [];

  const [anchorEL, setAnchorEl] = useState(null);
  const open_selected_menu = (e) => [
    e.preventDefault(),
    e.stopPropagation(),
    setAnchorEl(e.currentTarget)
  ];
  const close_selected_menu = (e) => [e.preventDefault(), e.stopPropagation(), setAnchorEl(null)];

  const [isLoading, setIsLoading] = useState(false);
  const isBelowXxl = useIsBelowUp(1690);

  const [preventifs, set_preventifs] = useState([]);
  const [preventif_name, set_preventif_name] = useState('');
  const [preventif_id, set_preventif_id] = useState('');
  const [selected_preventif_id, set_selected_preventif_id] = useState(preventif_id);

  useEffect(() => {
    setIsLoading(true);
    axios
      .post(`${apiBaseURL}/${endpoint}?preventif_id=${selected_preventif_id}`, { locations })
      .then((res) => {
        const data = res.data.data;

        const preventifs = res.data.preventifs;
        set_preventifs(preventifs);

        if (!data?.length && !Object.keys(data).length) {
          setNotEnoughData(true);
          const preventif_name =
            preventifs.find((p) => String(p._id) === String(selected_preventif_id))?.name || '';
          set_preventif_name(preventif_name);
          set_preventif_id(selected_preventif_id);
        } else if (data[0].interveners.length === 1 && data[0].interveners[0].count === 0) {
          setNotEnoughData(true);
          const preventif_name =
            preventifs.find((p) => String(p._id) === String(selected_preventif_id))?.name || '';
          set_preventif_name(preventif_name);
          set_preventif_id(selected_preventif_id);
        } else {
          const {
            datasets,
            datasetsLabels = [],
            labels,
            colors,
            tooltipLabels
          } = formatDatasets(data);
          tooltipLabels && setTooltipLabels(tooltipLabels);
          datasets && setDatasets(datasets);
          labels && setLabels(labels);
          datasetsLabels && setDatasetsLabels(datasetsLabels.map((l) => formatDatasetsLabels(l)));
          Array.isArray(colors) && colors.length && setColors(colors);
          setNotEnoughData(false);

          const preventif_id = data[0]._id;
          const preventif_name =
            preventifs.find((p) => String(p._id) === String(preventif_id))?.name || '';

          set_preventif_id(preventif_id);
          set_preventif_name(preventif_name);
        }

        setStatus(res.status);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, [selected_preventif_id]);

  return (
    <Grid
      item
      xs={12}
      md={6}
      lg={isBelowXxl ? 4 : 6}
      style={style}
    >
      <Box
        style={{
          border: '1px solid #E4E4E4',
          borderRadius: 8,
          height: 350,
          backgroundColor: '#fff'
        }}
        boxShadow={2}
      >
        <CardContent style={{ height: '100%', position: 'relative' }}>
          {title && (
            <Typography
              variant="h6"
              color="textSecondary"
              gutterBottom
              style={{
                margin: '0',
                marginLeft: '10px',
                display: 'flex',
                alignItems: 'center',
                color: '#003366'
              }}
            >
              {title}
            </Typography>
          )}
          <>
            <div style={{ position: 'absolute', top: 5, right: 5 }}>
              <Typography
                variant="body1"
                component="span"
                style={{ color: '#003366', fontSize: '11px' }}
              >
                {truncateText(preventif_name, 20)}
              </Typography>
              <IconButton
                aria-haspopup="true"
                onClick={open_selected_menu}
                style={{ paddingTop: '12px', color: '#003366' }}
              >
                <ArrowDropDownIcon />
              </IconButton>
            </div>

            <Menu
              anchorEl={anchorEL}
              transitionDuration={0}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={!!anchorEL}
              onClose={close_selected_menu}
            >
              {preventifs
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((p, key) => (
                  <MenuItem
                    selected={String(p._id) === String(preventif_id)}
                    key={p._id}
                    onClick={(e) => [set_selected_preventif_id(p._id), close_selected_menu(e)]}
                  >
                    {p.name}
                  </MenuItem>
                ))}
            </Menu>
          </>

          <Box
            style={{
              paddingTop: '1em',
              display: 'flex',
              position: 'relative',
              height: '100%',
              ...scroll
            }}
          >
            <BlurredProgress in={isLoading} />
            {status &&
              (status !== 200 ? (
                <p>{t('anErrorHasOccurredPleaseTryAgainLater')}</p>
              ) : notEnoughData ? (
                <p>{t('youDoNotHaveEnoughDataToDisplayThisTable')}</p>
              ) : (
                createElement(chart, {
                  formatYAxis,
                  formatXAxis,
                  datasets,
                  tooltipLabels,
                  labels,
                  colors,
                  column1Name,
                  column2Name,
                  datasetsLabels,
                  formatCount
                })
              ))}
          </Box>
        </CardContent>
      </Box>
    </Grid>
  );
}
