import { Checkbox, FormControlLabel } from '@material-ui/core';
import { useField } from 'frmx';
import { useTranslation } from 'react-i18next';

export default function CustomCheckbox({ onChange, valueControlled, customField, path, required }) {
  const { label } = customField;
  const { value, setValue, disabled, onBlur } = useField(path);
  const { t } = useTranslation();

  return (
    <FormControlLabel
      control={
        <Checkbox
          disabled={disabled}
          checked={!!(valueControlled || value)}
          onChange={(e) =>
            onChange ? onChange(e.target.checked) : [setValue(e.target.checked), onBlur()]
          }
          required={required}
        />
      }
      label={t(label) + (required ? ' *' : '')}
    />
  );
}
