import { nanoid } from 'nanoid';
import DayLarge from './DayLarge';

export default function DaysLargeGrid({
  days,
  middle_of_the_month,
  set_ticket_in_modal,
  set_unavailability_id_to_edit
}) {
  return (
    <div className="hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-6 lg:gap-px">
      {days.map((day) => (
        <DayLarge
          key={nanoid()}
          day={day}
          middle_of_the_month={middle_of_the_month}
          set_ticket_in_modal={set_ticket_in_modal}
          set_unavailability_id_to_edit={set_unavailability_id_to_edit}
        />
      ))}
    </div>
  );
}
