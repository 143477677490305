import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { EntityContext } from 'contexts/entities/entityContext';
import { useConfiguration } from 'hooks/useConfiguration';
import entities from 'config/entities';
import Entity from 'layouts/entities/Entity';
import { nanoid } from 'nanoid';
import { useAuth } from 'hooks/useAuth';
import { useRole } from 'hooks/useRole';
import { useSections } from 'hooks/useSections';

export default function EntityWrapper({
  entity,
  entitySlice,
  entitySliceView,
  tab,
  page,
  settings,
  filter,
  form,
  picker,
  pickerUniq,
  pickerField,
  closePicker,
  skeletonComponent,
  previewComponent,
  viewComponent,
  formComponent,
  icon,
  getMenus,
  getTabs,
  getTables,
  getExport,
  getFilters,
  getInitialValues,
  getFormValidation,
  getSorts,
  getExportColumns,
  getTranslations,
  formatFilter,
  formatForm = (f) => f,
  disableCreate,
  selectedId: defaultSelectedId,
  getElement,
  getElements,
  createElement,
  updateElement,
  flushElements,
  deleteElement,
  restoreElement,
  defaultFilters = {},
  defaultForm = {},
  hiddenFilters,
  sublistParentKey,
  sublistParent: _sublistParent,
  children,
  childrenId,
  childrenAuto,
  entityFieldsSlice,
  defaultTable,
  on_close_form,
  is_open_form,
  visit_date = null,
  duration = 0,
  on_click_left_arrow,
  on_click_right_arrow,
  period_ids,
  ...rest
}) {
  const auth = useAuth();

  const syncDispatch = useDispatch();
  const config = useConfiguration();

  const role = useRole();

  const { id: urlId } = useParams();
  const slice = useSelector((state) => state[entitySlice || entity]);
  const sliceView = useSelector((state) => state[entitySliceView || entitySlice || entity]);

  const [sublistParent, setSublistParent] = useState(_sublistParent);
  const [selectedId, setSelectedId] = useState(defaultSelectedId || null);
  const _selectedId = page ? urlId : selectedId;

  const _setSublistParent = (p) => [
    syncDispatch(flushElements({ listId, pageFlush: true })),
    setSublistParent(p)
  ];

  const listId = useMemo(() => (page ? entity : nanoid()), []);
  const db = slice.db;
  const elements = slice.lists[listId];
  const element = sliceView.db[_selectedId];

  const sections = useSections();
  const customFields = !!entityFieldsSlice && sections[entityFieldsSlice];

  const {
    initialValues,
    formValidation,
    filters,
    tabs,
    tables,
    sorts,
    exportColumns,
    translations
  } = useMemo(() => {
    return {
      // TODO: Don't spread all props into initialValues, make a pick fn
      formValidation: !disableCreate && getFormValidation(config, auth, element),
      filters: getFilters(config, { tab, ...rest, customFields, entity }, auth, role),
      tabs: getTabs(config, element, role, auth),
      sorts: getSorts(config, { tab, ...rest }),
      exportColumns: getExportColumns(config),
      tables: filter || form || !getTables ? [] : getTables(config, role, auth),
      translations: getTranslations(config, { sublistParent, ...rest })
    };
  }, [element, sublistParent, config, rest, tab, customFields]);

  const [isListTable, setIsListTable] = useState(
    defaultTable
      ? true
      : !tables.length
        ? false
        : JSON.parse(localStorage.getItem('viewListTable')) || false
  );

  useEffect(() => {
    if (!defaultTable && !!tables.length) {
      localStorage.setItem('viewListTable', isListTable);
    }
  }, [isListTable]);

  return (
    <EntityContext.Provider
      value={{
        entity,
        icon: icon || entities[entity]?.icon || 'asterisk',
        entityFieldsSlice,
        customFields,
        sublistParentKey,
        setSublistParent: _setSublistParent,
        sublistParent,
        disableCreate,
        selectedId: _selectedId,
        setSelectedId,
        getInitialValues,
        setIsListTable,
        isListTable,
        db,
        listId,
        elements,
        element,
        tables,
        tab,
        page,
        settings,
        filter,
        picker,
        pickerUniq,
        pickerField,
        closePicker,
        hiddenFilters,
        defaultFilters,
        defaultForm,
        skeletonComponent,
        previewComponent,
        viewComponent,
        formComponent,
        getMenus,
        filters,
        tabs,
        exportColumns,
        initialValues,
        sorts,
        translations,
        formatFilter,
        formatForm,
        formValidation,
        getElement,
        getElements,
        createElement,
        updateElement,
        flushElements,
        deleteElement,
        restoreElement,
        defaultTable,
        on_close_form,
        is_open_form,
        visit_date,
        duration,
        on_click_left_arrow,
        on_click_right_arrow,
        period_ids,
        ...rest
      }}
    >
      <Entity
        childrenId={childrenId}
        childrenAuto={childrenAuto}
      >
        {children}
      </Entity>
    </EntityContext.Provider>
  );
}
