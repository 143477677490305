import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

import randomFlatColor from 'random-flat-colors';
import { Box } from '@material-ui/core';
import { useMemo } from 'react';
import bobColors from 'constants/bobColors';

export default function HorizontalBarsChart({
  datasets,
  labels,
  colors = [],
  formatXAxis,
  tooltipLabels,
  datasetsLabels,
  formatYAxis
}) {
  colors = useMemo(() => {
    if (colors?.length === labels?.length) return colors;

    const additionalColors = labels.map(() => randomFlatColor());
    return [...bobColors.map((c) => c), ...additionalColors];
  }, [labels]);

  return (
    <Box style={{ width: '100%', height: '100%' }}>
      <Bar
        data={{
          labels,
          datasets: [
            {
              data: datasets,
              backgroundColor: colors
            }
          ]
        }}
        options={{
          maintainAspectRatio: false,
          responsive: true,
          indexAxis: 'y',
          plugins: {
            legend: {
              display: false
            },
            title: {
              display: false
            },
            tooltip: {
              callbacks: {
                label: ({ dataIndex, formattedValue }) => {
                  return (
                    (Array.isArray(tooltipLabels) && tooltipLabels[dataIndex]) || formattedValue
                  );
                }
              }
            }
          },
          scales: {
            xAxes: {
              ticks: {
                callback: formatXAxis
              }
            }
          }
        }}
      />
    </Box>
  );
}
