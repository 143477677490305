const MuiFab = {
  root: {
    backgroundColor: '#ffffff',
    color: '#66788A',
    '&:hover': {
      backgroundColor: '#E4E7EB'
    }
  }
};

export default MuiFab;
