const RoleTranslations = (configuration) => ({
  pick: 'entityRolePick',
  create: 'entityRoleCreate',
  update: 'entityRoleUpdate',
  entityName: 'entityRoleName',
  noResultLabel: 'entityRolenoResultLabel',
  searchText: 'entityRoleSearchText',
  createDialog: 'entityRoleCreateDialog',
  updateDialog: 'entityRoleUpdateDialog'
});

export default RoleTranslations;
