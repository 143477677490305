import useKeys from '@flowsn4ke/usekeys';
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import { useField } from 'frmx';
import { useTranslation } from 'react-i18next';

export default function DomainsSelect({ domains }) {
  const { t } = useTranslation();
  const k = useKeys();

  const { value: domain, setValue: setDomain } = useField('domain_id');

  return (
    <Grid
      item
      xs={12}
    >
      <FormControl
        variant="outlined"
        fullWidth
      >
        <InputLabel
          htmlFor="outlined-age-native-simple"
          className="bg-white"
        >
          {t('domainLabel')}
        </InputLabel>
        <Select
          fullWidth
          variant="outlined"
          value={domain === null ? 'allDomains' : domain}
          onChange={(e) => setDomain(e.target.value === 'allDomains' ? null : e.target.value)}
        >
          {[{ _id: null, label: 'allDomains' }].concat(domains).map((domain, domain_idx) => {
            return (
              <MenuItem
                key={k(domain_idx)}
                value={domain._id === null ? 'allDomains' : domain._id}
              >
                {t(domain.label)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Grid>
  );
}
