import {
  getElement,
  getElements,
  createElement,
  updateElement,
  flushElements,
  deleteElement,
  restoreElement
} from 'store/contractsSlice';

import ContractPreview from './ContractPreview';
import ContractSkeleton from './ContractSkeleton';
import ContractFormValues, {
  ContractValidation,
  formatForm,
  injectFormValues
} from './ContractValues';
import ContractTranslations from './ContractTranslations';
import ContractForm from './ContractForm';
import ContractSorts from './ContractSorts';

import EntityWrapper from 'entities/EntityWrapper';
import { NoteAdd } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

export default function Guarantee({ ...rest }) {
  const { t } = useTranslation();

  return (
    <EntityWrapper
      entity="contracts"
      entityFieldsSlice="contract"
      updateOnClick
      skeletonComponent={ContractSkeleton}
      previewComponent={ContractPreview}
      viewComponent={null}
      formComponent={ContractForm}
      formAttachment
      formAttachmentIcon={<NoteAdd />}
      formAttachmentLabel={t('document')}
      formatFilter={(filters) => filters}
      formatForm={formatForm}
      getFilters={() => []}
      getInitialValues={ContractFormValues}
      injectFormValues={injectFormValues}
      getFormValidation={ContractValidation}
      getTabs={() => []}
      getMenus={() => []}
      getTables={() => []}
      getExportColumns={() => []}
      getTranslations={ContractTranslations}
      getSorts={ContractSorts}
      getElement={getElement}
      getElements={getElements}
      createElement={createElement}
      updateElement={updateElement}
      flushElements={flushElements}
      deleteElement={deleteElement}
      restoreElement={restoreElement}
      {...rest}
    />
  );
}
