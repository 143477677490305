import { Table, TableBody, TableCell, TableHead, TableRow, useTheme } from '@material-ui/core';
import 'chart.js/auto';

import useKeys from '@flowsn4ke/usekeys';

export default function TableChart({ datasets, formatCount, column1Name, column2Name }) {
  const theme = useTheme();
  const k = useKeys();
  return (
    <Table
      size="small"
      aria-label="Classement des lieux par nombre de tickets"
      style={{ alignSelf: 'baseline' }}
    >
      <TableHead>
        <TableRow style={{ height: '20px' }}>
          <TableCell style={{ paddingTop: 0, paddingBottom: 0, color: theme.palette.primary.main }}>
            #
          </TableCell>
          <TableCell
            style={{ paddingTop: 0, paddingBottom: 0, color: theme.palette.primary.main }}
            align="left"
          >
            {column1Name}
          </TableCell>
          <TableCell
            style={{ paddingTop: 0, paddingBottom: 0, color: theme.palette.primary.main }}
            align="right"
          >
            {column2Name}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {datasets.map(({ name, count }, i) => (
          <TableRow
            key={k(i)}
            style={{ height: '22px' }}
          >
            <TableCell
              component="th"
              scope="row"
              style={{ paddingTop: 0, paddingBottom: 0, color: theme.palette.primary.main }}
            >
              {i + 1}
            </TableCell>
            <TableCell
              align="left"
              style={{ paddingTop: 0, paddingBottom: 0, color: theme.palette.primary.main }}
            >
              {name}
            </TableCell>
            <TableCell
              align="right"
              style={{ paddingTop: 0, paddingBottom: 0, color: theme.palette.primary.main }}
            >
              {formatCount ? formatCount(count) : count}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
