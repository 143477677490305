import { FormControl, FormHelperText, InputLabel, makeStyles, Select } from '@material-ui/core';
import { useField } from 'frmx';
import useKeys from '@flowsn4ke/usekeys';
import { nanoid } from 'nanoid';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  errorColor: {
    color: theme.palette.error.main
  }
}));

export default function CustomUniqueSelect({ path, customField, required }) {
  const { t } = useTranslation();
  const { value, setValue, error, disabled } = useField(path);
  const k = useKeys();
  const c = useStyles();
  const id = useRef(nanoid());

  return (
    <FormControl
      disabled={disabled}
      variant="outlined"
      style={{ width: '100%' }}
    >
      <InputLabel htmlFor={`${customField.label}-${id.current}`}>{t(customField.label)}</InputLabel>
      <Select
        fullWidth
        native
        value={value || ''}
        onChange={(e) => setValue(e.target.value)}
        label={t(customField.label)}
        inputProps={{
          name: customField.label,
          id: `${customField.label}-${id.current}`
        }}
        required={required}
      >
        <option
          aria-label="None"
          value={null}
        />
        {customField?.extra?.options?.map((option, i) => {
          return (
            <option
              value={option}
              key={k(i)}
            >
              {t(option)}
            </option>
          );
        })}
      </Select>

      {error && <FormHelperText className={c.errorColor}>{t('pleaseChooseAValue')}</FormHelperText>}
    </FormControl>
  );
}
