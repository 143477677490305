import { createSlice } from '@reduxjs/toolkit';
import generateAsyncThunk from 'utils/generateAsyncThunk';
import { generateEntityBoilerplate } from 'utils/generateEntitySlice';
import {
  finishElement as finishTicketOnAccounting,
  addAccounting as addAccountingByTicket,
  cancelIntervener,
  assignIntervener
} from './ticketsSlice';
import { assign } from '@recursive/assign';

export const getElement = generateAsyncThunk({ type: 'GET', endpoint: 'tickets/${id}' });
export const getElements = generateAsyncThunk({ type: 'POST', endpoint: 'tickets/list' });
export const getBookmarks = generateAsyncThunk({ type: 'POST', endpoint: 'tickets/bookmarks' });

export const linkTicketsToFacture = generateAsyncThunk({
  type: 'POST',
  endpoint: 'tickets/${id}/facture/linkTicketsToFacture'
});
export const setPriceToIntervention = generateAsyncThunk({
  type: 'POST',
  endpoint: 'tickets/${id}/price'
});

export const addAccounting = generateAsyncThunk({
  type: 'FILE',
  endpoint: 'tickets/${id}/attachments'
});
export const addQuote = generateAsyncThunk({
  type: 'FILE',
  endpoint: 'ticket_documents/${id}/upload-quote'
});
export const addInvoice = generateAsyncThunk({
  type: 'FILE',
  endpoint: 'ticket_documents/${id}/upload-invoice'
});
export const removeQuote = generateAsyncThunk({
  type: 'POST',
  endpoint: 'ticket_documents/${id}/remove-quote'
});
export const removeInvoice = generateAsyncThunk({
  type: 'POST',
  endpoint: 'ticket_documents/${id}/remove-invoice'
});
export const updateAccounting = generateAsyncThunk({
  type: 'POST',
  endpoint: 'tickets/${id}/attachments/update'
});
export const removeAccounting = generateAsyncThunk({
  type: 'POST',
  endpoint: 'tickets/${id}/removeAttachment'
});

export const acceptDevis = generateAsyncThunk({
  type: 'PUT',
  endpoint: 'tickets/${id}/devis/response'
});

export const setFactureState = generateAsyncThunk({
  type: 'POST',
  endpoint: 'tickets/${id}/facture/state'
});
export const setFactureComment = generateAsyncThunk({
  type: 'POST',
  endpoint: 'tickets/${id}/facture/comment'
});

export const check_paid_invoices = generateAsyncThunk({
  type: 'PUT',
  endpoint: 'tickets2/${id}/check_paid_invoices'
});

const entityBoilerplate = generateEntityBoilerplate({
  getElements,
  getBookmarks
});

const { initialState, reducers, extraReducers } = entityBoilerplate;

export const accountingSlice = createSlice({
  name: 'accounting',
  initialState: { ...initialState },
  reducers: {
    ...reducers
  },
  extraReducers: {
    ...extraReducers,
    [cancelIntervener.fulfilled]: (state, action) => {
      const { element } = action.payload.data;
      const { id } = action.meta;

      state.db[id] = assign(state.db[id], element);
    },
    [assignIntervener.fulfilled]: (state, action) => {
      const { element } = action.payload.data;
      const { id } = action.meta;

      state.db[id] = assign(state.db[id], element);
    },
    [finishTicketOnAccounting.fulfilled]: (state, action) => {
      const { element } = action.payload.data;
      const { id } = action.meta;

      state.db[id] = { ...state.db[id], state: element.contract.state };
    },
    [linkTicketsToFacture.fulfilled]: (state, action) => {
      const { id } = action.meta;

      state.db[id] = action.payload.data.ticket;
    },
    [setPriceToIntervention.fulfilled]: (state, action) => {
      const { id } = action.meta;
      const { element } = action.payload.data;

      state.db[id] = assign(state.db[id], element);
    },
    [getElement.fulfilled]: (state, action) => {
      const { id } = action.meta;

      state.db[id] = action.payload.data.element;
    },
    [addAccounting.fulfilled]: (state, action) => {
      const { element } = action.payload.data;
      const { id } = action.meta;

      state.db[id] = assign(state.db[id], element);
    },
    [updateAccounting.fulfilled]: (state, action) => {
      const { element } = action.payload.data;
      const { id } = action.meta;

      state.db[id] = assign(state.db[id], element);
    },
    [removeAccounting.fulfilled]: (state, action) => {
      const { element } = action.payload.data;
      const { id } = action.meta;

      state.db[id] = assign(state.db[id], element);
    },
    [acceptDevis.fulfilled]: (state, action) => {
      const { element } = action.payload.data;
      const { id } = action.meta;

      state.db[id] = assign(state.db[id], element);
    },
    [setFactureState.fulfilled]: (state, action) => {
      const { ticket } = action.payload.data;
      const { id } = action.meta;

      state.db[id] = ticket;
    },
    [setFactureComment.fulfilled]: (state, action) => {
      const { ticket } = action.payload.data;
      const { id } = action.meta;

      state.db[id] = assign(state.db[id], ticket);
    },
    [addAccountingByTicket.fulfilled]: (state, action) => {
      const { element } = action.payload.data;
      const { id } = action.meta;

      state.db[id] = assign(state.db[id], element);
    },
    [addQuote.fulfilled]: (state, action) => {
      const { element } = action.payload.data;
      const { id } = action.meta;

      state.db[id] = assign(state.db[id], element);
    },
    [addInvoice.fulfilled]: (state, action) => {
      const { element } = action.payload.data;
      const { id } = action.meta;

      state.db[id] = assign(state.db[id], element);
    },
    [removeQuote.fulfilled]: (state, action) => {
      const { element } = action.payload.data;
      const { id } = action.meta;

      state.db[id] = assign(state.db[id], element);
    },
    [removeInvoice.fulfilled]: (state, action) => {
      const { element } = action.payload.data;
      const { id } = action.meta;

      state.db[id] = assign(state.db[id], element);
    },
    [check_paid_invoices.fulfilled]: (state, action) => {
      const { element } = action.payload.data;
      const { id } = action.meta;

      state.db[id] = assign(state.db[id], element);
    }
  }
});

export const { flushElements, flushElement } = accountingSlice.actions;
export default accountingSlice.reducer;
