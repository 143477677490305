const MuiOutlinedInput = {
  root: {
    borderRadius: 8,
    '&:hover:not($disabled)': {
      backgroundColor: '#f1f3f4'
    }
  },
  input: {
    paddingLeft: 18,
    paddingRight: 18
  }
};

export default MuiOutlinedInput;
