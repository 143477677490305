import { TextField } from '@material-ui/core';
import { Field } from 'frmx';

import FieldBase from './FieldBase';

export default function TextAreaField({ lineSizeOption }) {
  return (
    <FieldBase
      mandatoryOption
      lineSizeOption={lineSizeOption}
    >
      <Field path="extra.defaultValue">
        <TextField
          fullWidth
          variant="outlined"
          multiline
          rows={4}
        />
      </Field>
    </FieldBase>
  );
}
