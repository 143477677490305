import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import { VisibilityOff, Visibility } from '@material-ui/icons';

import { useState } from 'react';
import { useField } from 'frmx';

import { useTranslation } from 'react-i18next';

export default function PasswordField({
  path,
  label,
  disabled: locallyDisabled,
  autoComplete,
  ...props
}) {
  const [passwordIsVisible, setPasswordIsVisible] = useState(false);
  const { value, setValue, error, disabled, onBlur } = useField(path);

  const { t } = useTranslation();

  return (
    <TextField
      {...props}
      variant="outlined"
      disabled={disabled || locallyDisabled}
      type={passwordIsVisible ? 'text' : 'password'}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={onBlur}
      error={error}
      label={label || t('passwordInputLabel')}
      autoComplete={autoComplete ? autoComplete : 'current-password'}
      InputProps={{
        endAdornment: !!value?.length && (
          <InputAdornment position="start">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setPasswordIsVisible((prev) => !prev)}
              style={{ opacity: 0.7, position: 'relative', left: 14 }}
            >
              {passwordIsVisible ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
}
