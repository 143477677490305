import { ThemeProvider, CssBaseline } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { useUI } from 'hooks/useUI';
import { locales } from 'i18n';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import Dialog from 'components/Dialog';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import projectMeta from '../package.json';

import AppRouter from 'router/Router';
import theme from 'theme/theme';

import useStyles from './App.styles';
import { FileViewerProvider } from 'hooks/useFileViewer';
import { PortalDiv } from 'components/Portal';
import { ConfirmProvider } from 'hooks/useConfirm';
import { InfoProvider } from 'hooks/useInfo';
import { isMobileOrTablet } from 'utils/isMobile';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as Sentry from '@sentry/react';
import { useAuth } from 'hooks/useAuth';
import { Toaster } from 'sonner';
import { languages } from 'config/languages';

const ONE_MINUTE = 1000 * 60;
const THIRTY_MINUTES = ONE_MINUTE * 30;

export default function App() {
  const ui = useUI();
  const classes = useStyles();
  const auth = useAuth();

  if (auth.user?.id) {
    Sentry.setUser({ id: auth.user?.id, email: auth.user?.email });
    Sentry.setTags({
      username: auth.user?.username,
      interface_id: auth.interface?.id,
      interface_type: auth.interface?.type,
      interface_name: auth.interface?.name
    });
  } else {
    Sentry.setUser({ id: 'User not authenticated' });
  }

  const isMoT = isMobileOrTablet();

  const [update_is_available, set_update_is_available] = useState(false);
  const [update_was_paused, set_update_was_paused] = useState(false);
  const [blocked_before_update, set_blocked_before_update] = useState(
    localStorage.getItem('blocked_before_update') === 'true'
  );

  const current_version = projectMeta.version;

  const get_latest_version_url = process.env.REACT_APP_API_URL + '/versions/app';

  const start_five_minute_timer = () => {
    setTimeout(() => {
      set_blocked_before_update(true);
      localStorage.setItem('blocked_before_update', 'true');
    }, THIRTY_MINUTES);
  };

  useEffect(() => {
    const interval_id = setInterval(() => {
      if (!update_is_available && ['staging', 'sandbox', 'production'].includes(process.env.REACT_APP_ENVIRONMENT)) {
        axios
          .get(get_latest_version_url)
          .then((res) => {
            const latest_version = res.data.version;
            const update_is_available = current_version !== latest_version;

            setTimeout(() => {
              set_update_is_available(update_is_available);
            }, THIRTY_MINUTES);

            if (!update_is_available) localStorage.removeItem('blocked_before_update');
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }, ONE_MINUTE);

    // set USER language
    const lang = languages.find((lang) => lang.key === auth?.user?.lang);

    if (lang) ui.setLang(lang);

    return () => {
      clearInterval(interval_id);
    };
  }, [auth?.user?.lang]);

  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      locale={locales[ui.getLang()]}
    >
      <ThemeProvider theme={theme(ui.getPalette())}>
        <DndProvider backend={isMoT ? TouchBackend : HTML5Backend}>
          <CssBaseline />
          <ConfirmProvider>
            <InfoProvider>
              <FileViewerProvider>
                <Toaster position="top-right" />
                <VersionChangeDialog
                  update_is_available={update_is_available}
                  update_is_paused={update_was_paused}
                  blocked_before_update={blocked_before_update}
                  on_close={() => {
                    start_five_minute_timer();
                    set_update_was_paused(true);
                  }}
                />
                <PortalDiv />
                <AppRouter className={classes['@global']} />
              </FileViewerProvider>
            </InfoProvider>
          </ConfirmProvider>
        </DndProvider>
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
}

function VersionChangeDialog({ update_is_available, update_is_paused, blocked_before_update, on_close }) {
  const { t } = useTranslation();

  return (
    <Dialog
      icon="sparkles"
      isOpen={Boolean((update_is_available && !update_is_paused) || (update_is_available && blocked_before_update))}
      title={t('newVersionAvailable')}
      onClose={on_close}
      width="md"
    >
      <div className="text-sm my-4">
        <div className="my-2">
          <span>{t('pleaseUpdateYourLocalVersion')}&nbsp;</span>
          {!blocked_before_update && (
            <span>
              {t('youHaveAbout')} <span className="font-semibold">{t('fiveMinutesToFinishYourWorkInProgress')}</span>,{' '}
              {t('afterWhichYouNeedToUpdate')}
            </span>
          )}
        </div>
      </div>
      <MyDisclosure />
      {!blocked_before_update && (
        <div className="flex justify-end">
          <button
            onClick={on_close}
            className="text-sm bg-sky-900 text-white rounded-full px-3 py-2"
          >
            {t('iUnderstood')}
          </button>
        </div>
      )}
    </Dialog>
  );
}

function MyDisclosure() {
  const { t } = useTranslation();

  return (
    <div className="w-full">
      <div className="mx-auto w-full max-w-md rounded-2xl bg-white">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between rounded-lg bg-sky-100 px-2 py-2 mb-4 text-left text-sm font-medium text-sky-900 hover:bg-sky-200 focus:outline-none focus-visible:ring focus-visible:ring-sky-500 focus-visible:ring-opacity-75">
                <span>{t('howToUpdateMyVersion')}</span>
                <ChevronUpIcon className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-sky-500`} />
              </Disclosure.Button>
              <Disclosure.Panel className="px-2 pb-2 text-sm text-gray-500">
                <div className="my-2">{t('toUpdateToLatestYouCan')}</div>
                <ol>
                  <li className="my-2">
                    1. <span className="font-semibold">{t('reloadThisPage')}</span>{' '}
                    {t('byClickingTheReloadButtonInYourBrowser')} <span className="font-bold text-2xl">⟳</span>{' '}
                    {t('locatedAtTheLeftOfTheAdressBar')}
                  </li>
                  <li className="my-2">
                    2. <span className="font-semibold">{t('closeAllTabs')}</span> {t('whereTheAppIsOpenThenComeBack')}
                  </li>
                </ol>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
}
