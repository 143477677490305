import {
  getElement,
  getElements,
  createElement,
  updateElement,
  flushElements,
  deleteElement,
  restoreElement
} from 'store/scopesSlice';

import ScopeMenus from './ScopeMenus';
import ScopePreview from './ScopePreview';
import ScopeSkeleton from './ScopeSkeleton';
import ScopeView from './ScopeView';
import ScopeTabs from './ScopeTabs';
import ScopeFilters, { formatFilter } from './ScopeFilters';
import ScopeFormValues, { ScopeValidation } from './ScopeFormValues';
import ScopeTranslations from './ScopeTranslations';
import ScopeExport from './ScopeExport';
import ScopeForm from './ScopeForm';
import ScopeSorts from './ScopeSorts';

import EntityWrapper from 'entities/EntityWrapper';
import { useMemo } from 'react';
import ScopeTable from './ScopeTable';

export default function Scope({ hiddenFilters, type, ...rest }) {
  const _hiddenFilters = useMemo(() => {
    return {
      ...hiddenFilters,
      types: [type]
    };
  }, [type]);

  return (
    <EntityWrapper
      entity={'scopes'}
      skeletonComponent={ScopeSkeleton}
      previewComponent={ScopePreview}
      viewComponent={ScopeView}
      formComponent={ScopeForm}
      formatFilter={formatFilter}
      getFilters={ScopeFilters}
      getInitialValues={ScopeFormValues}
      getFormValidation={ScopeValidation}
      getTabs={ScopeTabs}
      getTables={ScopeTable}
      getMenus={ScopeMenus}
      getExportColumns={ScopeExport}
      getTranslations={ScopeTranslations}
      getSorts={ScopeSorts}
      getElement={getElement}
      getElements={getElements}
      createElement={createElement}
      updateElement={updateElement}
      flushElements={flushElements}
      deleteElement={deleteElement}
      restoreElement={restoreElement}
      hiddenFilters={_hiddenFilters}
      type={type}
      {...rest}
    />
  );
}
