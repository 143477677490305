import { GroupedVirtuoso } from 'react-virtuoso';
import { Box, LinearProgress } from '@material-ui/core';
import { useEntity } from 'contexts/entities/entityContext';
import Preview from './Preview';
import { useRef, useState } from 'react';
import { useField } from 'frmx';
import { format, isToday, isYesterday, isThisMonth, subDays } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useUI } from 'hooks/useUI';

const capitalizeFirstLetter = (string) => {
  if (!string) return string; // Handle empty strings
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export default function GroupedVirtuosoList({ fetchElements, setIsOpenForm, loading }) {
  const { db, fldx, disabled, elements, pickerField, onClick, fieldPath, Draggable } = useEntity();
  const [draggableObj, setDraggableObj] = useState(null);
  const field = useField(pickerField);
  const virtuosoRef = useRef(null);
  const { t } = useTranslation();
  const ui = useUI();
  const locale = ui.get_date_time_locale();

  // GroupItemsByDate function to group elements by date
  const GroupItemsByDate = (elements, db) => {
    const grouped = {
      today: [],
      yesterday: [],
      dayNames: {},
      datesOfMonth: {},
      monthsOfYear: {}
    };

    elements.forEach((element) => {
      const createdAt = new Date(db[element._id].created_at);

      if (isToday(createdAt)) {
        grouped.today.push(element);
      } else if (isYesterday(createdAt)) {
        grouped.yesterday.push(element);
      } else if (createdAt >= subDays(new Date(), 7)) {
        const dayName = capitalizeFirstLetter(format(createdAt, 'EEEE', { locale }));
        if (!grouped.dayNames[dayName]) {
          grouped.dayNames[dayName] = [];
        }
        grouped.dayNames[dayName].push(element);
      } else if (isThisMonth(createdAt)) {
        const dayOfMonth = capitalizeFirstLetter(format(createdAt, 'd/MM/yyyy', { locale }));
        if (!grouped.datesOfMonth[dayOfMonth]) {
          grouped.datesOfMonth[dayOfMonth] = [];
        }
        grouped.datesOfMonth[dayOfMonth].push(element);
      } else {
        const monthOfYear = capitalizeFirstLetter(format(createdAt, 'MMMM yyyy', { locale }));
        if (!grouped.monthsOfYear[monthOfYear]) {
          grouped.monthsOfYear[monthOfYear] = [];
        }
        grouped.monthsOfYear[monthOfYear].push(element);
      }
    });

    return grouped;
  };

  const getGroupNames = (grouped) => {
    const groupNames = [];
    if (grouped.today.length > 0) groupNames.push(t('Today'));
    if (grouped.yesterday.length > 0) groupNames.push(t('yesterday'));

    for (const dayName in grouped.dayNames) {
      if (grouped.dayNames[dayName].length > 0) {
        groupNames.push(dayName);
      }
    }

    for (const date in grouped.datesOfMonth) {
      if (grouped.datesOfMonth[date].length > 0) {
        groupNames.push(date);
      }
    }

    for (const month in grouped.monthsOfYear) {
      if (grouped.monthsOfYear[month].length > 0) {
        groupNames.push(month);
      }
    }
    return groupNames;
  };

  const itemsRendered = (items) => {
    if (!!Draggable && virtuosoRef?.current) {
      if (draggableObj) {
        draggableObj.destroy();
      }

      setDraggableObj(
        new Draggable(virtuosoRef?.current, {
          itemSelector: '.Draggable',
          eventData: (eventEl) => {
            const element = db[eventEl.dataset.id];

            if (element) {
              const color = '#dddddd';
              return {
                backgroundColor: color,
                borderColor: '#dddddd',
                textColor: 'black',
                extendedProps: {
                  ticketId: element._id,
                  index: 0,
                  type: !element.visit_date ? 'visit' : 'intervention'
                }
              };
            }
            return;
          }
        })
      );
    }
  };

  const groupedElements = GroupItemsByDate(elements, db);

  const groupNames = getGroupNames(groupedElements);

  const groupCounts = [
    groupedElements.today.length,
    groupedElements.yesterday.length,
    ...Object.values(groupedElements.dayNames).map((group) => group.length),
    ...Object.values(groupedElements.datesOfMonth).map((group) => group.length),
    ...Object.values(groupedElements.monthsOfYear).map((group) => group.length)
  ].filter((count) => count > 0); // Filter out empty counts

  const flatElements = [
    ...groupedElements.today,
    ...groupedElements.yesterday,
    ...Object.values(groupedElements.dayNames).flat(),
    ...Object.values(groupedElements.datesOfMonth).flat(),
    ...Object.values(groupedElements.monthsOfYear).flat()
  ]; // Ensure that flatElements matches the order of groupCounts

  return (
    <Box
      ref={virtuosoRef}
      height="100%"
      width="100%"
    >
      <GroupedVirtuoso
        itemsRendered={itemsRendered}
        groupCounts={groupCounts} // Number of items in each group
        groupContent={(index) => (
          <div
            style={{
              paddingLeft: '10px',
              paddingTop: '5px',
              paddingBottom: '5px',
              background: '#f5f5f5',
              fontWeight: 'bold'
            }}
          >
            {groupNames[index]} {/* Render group header (e.g., Today, Yesterday) */}
          </div>
        )}
        itemContent={(index) => {
          const element = db[flatElements[index]._id];
          return (
            <Preview
              disabled={disabled}
              onClick={onClick}
              fldx={pickerField || fieldPath ? (!!fldx ? fldx : field) : null}
              setIsOpenForm={setIsOpenForm}
              element={element}
            />
          );
        }}
        endReached={fetchElements}
        style={{ overflowX: 'hidden' }}
        components={{
          Footer: () => {
            return <>{loading && <LinearProgress />}</>;
          }
        }}
      />
    </Box>
  );
}
