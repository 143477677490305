import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  preview: {
    width: '100%',
    overflow: 'hidden'
  },
  online: {
    background: theme.palette.success.main,
    borderRadius: '50%',
    width: 6,
    height: 6,
    position: 'absolute',
    top: 10,
    right: 10
  },
  previewButton: ({ picker, scheduler, updateOnClick }) => ({
    width: '100%',
    padding: 16,
    paddingRight: (picker || scheduler || updateOnClick) && 10,
    height: '100%',
    display: 'flex',
    borderRadius: 0,
    borderBottom: '1px solid #eaeaea',
    lineHeight: '22px'
  }),
  previewContainer: {
    width: '100%'
  },
  previewCheckbox: {
    marginRight: -4,
    position: 'relative',
    left: -12
  },
  l: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  avatar: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    marginRight: 16,
    ['& div']: {
      fontSize: 10
    }
  },
  avatarSmall: {
    ['& div']: {
      fontSize: 10
    },
    marginLeft: 10
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    // whiteSpace: 'nowrap'
    width: '100%',
    fontSize: 14,
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center'
  },
  description: {
    marginTop: 6,
    lineHeight: '16px'
  },
  reference: {
    fontWeight: 600,
    color: theme.palette.secondary.main,
    fontSize: 12,
    display: 'flex',
    alignItems: 'center'
  },
  chipBadge: {
    ['& .MuiBadge-badge']: {
      fontSize: 10,
      height: 15,
      border: '1px solid #363640',
      background: 'white',
      color: '#363640'
    }
  },
  chipBadgeIsActive: {
    ['& .MuiBadge-badge']: {
      fontSize: 10,
      height: 15,
      border: '1px solid white',
      background: '#363640',
      color: 'white'
    }
  },
  chip: {
    fontSize: 10,
    marginLeft: 6
  },
  subButton: {
    position: 'relative',
    left: 2,
    minWidth: 'auto',
    padding: '0px 6px 0px 6px'
  },
  success: {
    color: theme.palette.success.main
  },
  error: {
    color: theme.palette.error.main
  },
  successLight: {
    color: theme.palette.success.light
  },
  errorLight: {
    color: theme.palette.error.light
  },
  indicator: {
    position: 'absolute',
    bottom: -18,
    right: -4
  },
  indicatorON: {
    fontSize: 8,
    color: theme.palette.success.main
  },
  indicatorIDLE: {
    fontSize: 8,
    color: '#f39c12'
  },
  indicatorOFF: {
    fontSize: 8,
    color: theme.palette.grey['400']
  },
  previewVariant: {
    height: '100%',
    padding: '2px 6px',
    borderBottom: 'none',
    //flex-start from style prop to Preview
    alignItems: 'center',
    ['& .title']: {
      fontSize: 10
    }
  },
  copyable: {
    cursor: 'pointer',
    '&:hover': {
      '&::after': {
        marginLeft: 5,
        content: '"\\f0c5"',
        fontFamily: 'FontAwesome',
        fontSize: '10px',
        position: 'absolute'
      }
    }
  },
  tooltip: {
    backgroundColor: 'white',
    boxShadow: '0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)',
    borderBottomLeftRadius: '0.5rem',
    borderBottomRightRadius: '0.5rem',
    color: 'black',
    margin: 0,
    padding: 0
  }
}));
