import { isEmail } from 'validator';

const SupplierFormValues = (configuration, { sublistParent, facturation }) => ({
  companyName: '',
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
  reference: ''
});

export const SupplierValidation = (configuration) => ({
  companyName: (str) => !!str,
  email: (value) => isEmail(value),
  lastName: (str) => !!str
});

export const formatForm = (dataFromForm, isCreate, attachments) => ({
  ...dataFromForm,
  _cover: attachments[0]
});

export default SupplierFormValues;
