import { Box, Grid } from '@material-ui/core';

import {
  DescriptionItem,
  ReasonItem,
  EquipmentItem,
  LocationItem
} from 'components/views/ViewItems';

import { useAuth } from 'hooks/useAuth';

import { useContract } from 'contexts/entities/contractContext';
import useKeys from '@flowsn4ke/usekeys';
import { useRole } from 'hooks/useRole';
import { updateElement } from 'store/ticketsSlice';
import { CONTRACT_CANCELED, CONTRACT_DECLINED } from 'lists/contractStates';
import useAsyncDispatch from 'hooks/useAsyncDispatch';

export default function OrderItems() {
  const auth = useAuth();
  const role = useRole();
  const {
    contract,
    order: { _id, description, _locations, _equipments, guarantees, created_at },
    companyCreator
  } = useContract();
  const k1 = useKeys();
  const k2 = useKeys();

  const { dispatch } = useAsyncDispatch();

  const canUpdate =
    companyCreator._id === auth.interface._company._id && role.permission('tickets', 'update');

  return (
    <>
      {[CONTRACT_CANCELED, CONTRACT_DECLINED].includes(contract.state) && (
        <ReasonItem reason={contract.reason_canceled} />
      )}
      <DescriptionItem
        description={description}
        canUpdate={canUpdate}
        onUpdate={(description) => dispatch(updateElement, { description }, {}, { id: _id })}
      />
      <Grid container>
        {!!_locations?.length && (
          <Grid
            item
            xs={12}
            sm={12}
          >
            {_locations?.map((location, i) => (
              <LocationItem
                key={k1(i)}
                location={location}
                guarantees={guarantees}
                guaranteeFrom={created_at}
              />
            ))}
          </Grid>
        )}

        {!!_equipments?.length && (
          <Grid
            item
            xs={12}
            sm={12}
          >
            {_equipments?.map((equipment, i) => (
              <EquipmentItem
                key={k2(i)}
                equipment={equipment}
                guarantees={guarantees}
                guaranteeFrom={created_at}
              />
            ))}
          </Grid>
        )}
      </Grid>
      <Box marginBottom="10px" />
    </>
  );
}
