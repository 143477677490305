import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  dialogFullScreenContainer: {
    ['& .MuiBackdrop-root']: {
      backgroundColor: 'rgba(0, 0, 0, 0.85)'
    },
    [theme.breakpoints.down('sm')]: {
      ['& .MuiDialog-container']: {
        alignItems: 'flex-start'
      }
    }
  },
  dialogNotFullScreen: {
    background: 'transparent',
    boxShadow: 'none'
  },
  dialogDrawer: {
    padding: theme.breakpoints.down('sm') ? 0 : 10,
    ...(theme.breakpoints.down('sm') && {
      height: '100%'
    }),
    background: 'transparent'
  },
  dialogFullScreen: ({ noTabs }) => ({
    background: 'transparent',
    padding: 10,
    borderRadius: theme.radius,
    boxShadow: 'none',
    height: !noTabs && 'calc(100% - 82px)'
  }),
  drawer: {
    background: 'transparent',
    padding: 12,
    paddingTop: 8,
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  drawerContainer: {
    overflow: 'hidden',
    width: '700px',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start'
  },
  topRadius: {
    borderTopLeftRadius: theme.radius,
    borderTopRightRadius: theme.radius
  },
  bottomRadius: {
    borderBottomLeftRadius: theme.radius,
    borderBottomRightRadius: theme.radius
  },
  content: {
    background: 'white',
    position: 'relative',
    borderBottomLeftRadius: theme.radius,
    borderBottomRightRadius: theme.radius
  },
  publicContent: {
    borderRadius: theme.radius
  },
  actions: {
    padding: 10,
    background: 'white'
  },
  title: {
    height: 58,
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    fontWeight: 500,
    fontSize: 14,
    background: theme.palette.secondary.main,
    paddingRight: 16
  },
  titleIcon: {
    color: 'white',
    marginRight: 10
  },
  titleClose: {
    color: 'white',
    marginLeft: 'auto'
  },
  titleBack: {},
  actionButton: {
    marginLeft: 10
  },
  actionButtonWithIcon: {
    paddingRight: 10
  },
  actionButtonIcon: {
    marginLeft: 64
  }
}));
