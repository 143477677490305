const MaintenanceSorts = (configuration, { timeline }) =>
  timeline
    ? [
        { label: 'dueDateMostClose', value: 'dates', sort: 1, show: true },
        { label: 'dueDateLeastClose', value: 'dates', sort: -1, show: true }
      ]
    : [
        { label: 'ascendingAlphabetical', value: 'name', sort: 1, show: true },
        { label: 'descendingAlphabetical', value: 'name', sort: -1, show: true },
        { label: 'creationDateMostRecent', value: '_id', sort: -1, show: true },
        { label: 'creationDateLeastRecent', value: '_id', sort: 1, show: true }
      ].filter((e) => e.show);

export default MaintenanceSorts;
