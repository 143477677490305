import { TextField, Grid } from '@material-ui/core';

import { useEntity } from 'contexts/entities/entityContext';
import { Field } from 'frmx';
import { useTranslation } from 'react-i18next';

export default function JobForm() {
  const { translations } = useEntity();
  const { t } = useTranslation();

  return (
    <>
      <Grid
        item
        md={12}
      >
        <Field path="name">
          <TextField
            variant="outlined"
            autoFocus
            fullWidth
            label={t(translations.entityName)}
          />
        </Field>
      </Grid>
    </>
  );
}
