import { pink, red, green, blue, purple } from '@material-ui/core/colors';

export const secondary = {
  contrastText: '#ffffff',
  main: '#363640'
};

const primaries = {
  purple: {
    name: 'purple',
    primary: {
      main: purple[700]
    },
    crisp: 'purple'
  },
  black: {
    name: 'black',
    primary: {
      main: '#212121'
    },
    crisp: 'black'
  },
  pink: {
    name: 'pink',
    primary: {
      main: pink[700]
    },
    crisp: 'pink'
  },
  orange: {
    name: 'orange',
    primary: {
      main: '#f57f1a'
    },
    crisp: 'orange'
  },
  red: {
    name: 'red',
    primary: {
      main: red[700]
    },
    crisp: 'red'
  },
  blue: {
    name: 'blue',
    primary: {
      main: '#003366'
    },
    crisp: 'blue'
  },
  lightBlue: {
    name: 'lightBlue',
    primary: {
      main: blue[700]
    },
    crisp: 'light_blue'
  },
  green: {
    name: 'green',
    primary: {
      main: green[800]
    },
    crisp: 'green'
  },
  grey: {
    name: 'grey',
    primary: {
      main: '#9e9e9e'
    },
    crisp: 'grey'
  }
};

export const palettes = Object.keys(primaries).reduce(
  (a, b) => ({
    ...a,
    [b]: {
      name: primaries[b].name,
      crisp: primaries[b].crisp,
      primary: { ...primaries[b].primary, contrastText: '#ffffff' },
      secondary
    }
  }),
  {}
);
