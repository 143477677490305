import { Box, Button } from '@material-ui/core';
import { Archive } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import useStyles from 'layouts/entities/View.styles';

const CloseButton = ({ actions, isLoading }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box marginTop={'10px'}>
      <Button
        onClick={() => actions.close.action()}
        disabled={isLoading}
        endIcon={<Archive className={classes.viewActionIcon} />}
        color="secondary"
        variant="contained"
        className={classes.viewAction}
      >
        {t('closeVerb')}
      </Button>
    </Box>
  );
};

export default CloseButton;
