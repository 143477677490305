import { useEffect, useMemo, useState } from 'react';

import Accounting from 'entities/Accounting/Accounting';

import { Clear, FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from '@material-ui/icons';

import {
  Box,
  Drawer,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  useTheme,
  makeStyles,
  Tooltip
} from '@material-ui/core';

import Ticket from 'entities/Ticket/Ticket';

import DevisHelper from 'utils/devis';
import FactureHelper from 'utils/invoice';

// * Columns
import NumberColumn from 'entities/Accounting/Columns/NumberColumn';
import IntervenerColumn from 'entities/Accounting/Columns/IntervenerColumn';
import QuoteColumn from 'entities/Accounting/Columns/QuoteColumn';
import InterventionColumn from 'entities/Accounting/Columns/InterventionColumn';
import InvoiceColumn from 'entities/Accounting/Columns/InvoiceColumn';
import ReferenceColumn from 'entities/Accounting/Columns/ReferenceColumn';
import StateColumn from 'entities/Accounting/Columns/StateColumn';
import CheckColumn from 'entities/Accounting/Columns/CheckColumn';
import ValidationColumn from 'entities/Accounting/Columns/ValidationColumn';
import PaidInvoicesColumn from 'entities/Accounting/Columns/PaidInvoicesColumn';

import { factureStates } from 'constants/invoiceStates';
import { useIsBelowMd } from 'hooks/useMQ';

import ModalAccounting from 'entities/Accounting/Modal/ModalAccounting';

import { Form, useForm } from 'frmx';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import { useConfiguration } from 'hooks/useConfiguration';
import { linkTicketsToFacture } from 'store/accountingSlice';
import { useEntity } from 'contexts/entities/entityContext';
import AccountingSkeleton from 'entities/Accounting/AccountingSkeleton';
import { useTranslation } from 'react-i18next';
import useNotifications from 'hooks/useNotifications';
import { Empty } from 'layouts/entities/List';
import { nanoid } from 'nanoid';
import { omit } from 'lodash-es';

const DEFAULT_DOCUMENT_VIEWER_CONFIG = {
  isOpen: false,
  ticketId: null,
  anchor: null,
  lock: false,
  type: null
};

// TODO here uncomment the button when testing is ready to

export default function AccountingPage() {
  return (
    <Accounting
      filtersSave
      listComponent={(props) => AccountingTable(props)}
    />
  );
}

function AccountingTable({ elements, fetchElements, isLoading, appliedFilters, appliedBookmarks, sort }) {
  const { t } = useTranslation();

  const [openTicketId, setOpenTicketId] = useState(null);
  const [openedTicket, setOpenedTicket] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [documentViewerConfig, setDocumentViewerConfig] = useState(DEFAULT_DOCUMENT_VIEWER_CONFIG);

  const { requestStatus, dispatch } = useAsyncDispatch();
  const notify = useNotifications();
  const config = useConfiguration();

  const onCloseOpenedTicket = () => {
    setOpenedTicket(null);
  };

  const { db } = useEntity();

  useEffect(() => {
    setPage(0);
  }, [appliedFilters, appliedBookmarks, sort]);

  const count = useMemo(() => (!!elements ? elements.count : 0), [elements?.count]);

  const computeDocumentPrice = (ticket, type, filterFn) => {
    return ticket[type]
      .filter(filterFn)
      .filter((doc) => doc._intervener === (ticket._summons[0]._id || ticket._summons[0]))
      .reduce((price, current) => price + current.price, 0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    const newFetch = page * rowsPerPage + rowsPerPage;

    const fetchBackward = ((newPage * rowsPerPage) % 30) + rowsPerPage;
    const fetchForward = ((page * rowsPerPage) % 30) + rowsPerPage;

    if (newPage === 0) {
      fetchElements(null, true, 0, 30);
    }

    if (fetchForward === 30 && page < newPage) {
      fetchElements(null, true, newFetch, 30);
    }

    if (fetchBackward === 30 && page > newPage) {
      fetchElements(null, true, newFetch - fetchForward - 30, 30);
    }

    if (newPage >= Math.ceil(count / rowsPerPage) - 1) {
      const start = Math.ceil(count / rowsPerPage) * rowsPerPage;

      fetchElements(null, true, count - 30 + (start - count), count);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    fetchElements(null, true, 0, 30);
    setPage(0);
  };

  const columns = [
    {
      title: '#',
      headerStyle: { textAlign: 'center' },
      render: ({ ticket, setChildrenAreOpen, childrenAreOpen }) => (
        <NumberColumn
          ticket={ticket}
          setOpenedTicket={setOpenedTicket}
          setOpenTicketId={setOpenTicketId}
          open={childrenAreOpen}
          setOpen={setChildrenAreOpen}
        />
      )
    },

    {
      title: t('intervener'),
      headerStyle: { textAlign: 'right' },
      render: ({ ticket: { parentId, _summons } }) => (
        <IntervenerColumn
          parentId={parentId}
          interveners={_summons}
        />
      )
    },

    {
      title: `${t('quotes')} HT`,
      headerStyle: { textAlign: 'center' },
      render: ({ ticket }) => (
        <QuoteColumn
          ticket={ticket}
          setDocumentViewerConfig={setDocumentViewerConfig}
        />
      )
    },

    {
      title: `${t('interventionTitle')}(s) HT`,
      headerStyle: { textAlign: 'right' },
      render: ({
        ticket: {
          intervention_price,
          devis_price,
          devis,
          _id,
          parentId,
          _facture_tickets,
          facture_state,
          _facture_ticket,
          viewId
        }
      }) => (
        <InterventionColumn
          interventionPrice={intervention_price}
          devisPrice={devis_price}
          devis={devis}
          ticketId={_id}
          parentId={parentId}
          childrenTickets={_facture_tickets}
          invoiceState={facture_state}
          _facture_ticket={_facture_ticket}
          viewId={viewId}
        />
      )
    },

    {
      title: `${t('invoice')}(s) HT`,
      headerStyle: { textAlign: 'center' },
      render: ({
        ticket: { factures, factures_price, intervention_price, parentId, _facture_tickets, _id, facture_state }
      }) => (
        <InvoiceColumn
          factures={factures}
          facturesPrice={factures_price}
          interventionPrice={intervention_price}
          invoicesFromTicket={_facture_tickets}
          parentId={parentId}
          ticketId={_id}
          stateOfInvoice={facture_state}
          setDocumentViewerConfig={setDocumentViewerConfig}
        />
      )
    },
    {
      title: `${t('reference')}(s)`,
      headerStyle: { textAlign: 'center' },
      render: ({ ticket: { parentId, factures } }) => (
        <ReferenceColumn
          parentId={parentId}
          factures={factures}
        />
      )
    },
    // * CONFIGURATION CUSTOM FOR COLUMNS
    config.comptability.check_1 && {
      title: config.comptability.check_2 ? t(factureStates.to_check.labelTitle) : t(factureStates.to_valid.labelTitle),
      headerStyle: { textAlign: 'center' },
      render: ({ ticket: { facture_state, parentId, _id, state, devis_price, intervention_price } }) => (
        <CheckColumn
          facture_state={facture_state}
          parentId={parentId}
          _id={_id}
          state={state}
          type="check_1"
          devisPrice={devis_price}
          interventionPrice={intervention_price}
        />
      )
    },
    config.comptability.check_2 && {
      title: t(factureStates.to_valid.labelTitle),
      headerStyle: { textAlign: 'center' },
      render: ({ ticket: { facture_state, parentId, _id, state } }) => (
        <ValidationColumn
          facture_state={facture_state}
          parentId={parentId}
          _id={_id}
          state={state}
          type="check_2"
        />
      )
    },
    config?.comptability?.check_paid_invoices && {
      title: t('paidInvoices'),
      headerStyle: { textAlign: 'center' },
      render: ({ ticket }) => (
        <PaidInvoicesColumn
          parentId={ticket.parentId}
          ticket_id={ticket._id}
          invoices={ticket.factures}
          invoice_tickets={ticket._facture_tickets}
        />
      )
    },
    {
      title: t('stateAccounting'),
      headerStyle: { textAlign: 'center' },
      render: ({ ticket }) => (
        <StateColumn
          factures={ticket.factures}
          parentId={ticket.parentId}
          ticket={ticket}
        />
      )
    }
  ].filter((columnConfiguration) => columnConfiguration);

  /**
   * *--- FORMATTED DATA Business LOGIC V4 ---*
   */
  const formattedData = useMemo(() => {
    const tickets = (elements || []).map((e) => db[e._id]);

    return (tickets || []).reduce((rows, currentRow) => {
      const [devis_price, total_devis_price, factures_price] = [
        DevisHelper.getTotalPrice({ devis: currentRow.devis }),
        DevisHelper.getTotalPrice({ devis: currentRow.devis }) +
          currentRow._facture_tickets.reduce((total, t) => total + DevisHelper.getTotalPrice({ devis: t.devis }), 0),
        FactureHelper.getTotalPrice({ factures: currentRow.factures })
      ];

      return [
        ...rows,
        ...(!currentRow._facture_ticket
          ? [
              {
                ...currentRow,
                factures_price,
                devis_price,
                total_devis_price
              }
            ]
          : [])
      ];
    }, []);
  }, [db, elements, page]);

  /**
   * *--- Memoization filters ---*
   */
  const intervenersFilter = useMemo(() => {
    const intervener = formattedData.find((d) => d._id === openedTicket?._id)?._summons[0]?._id;
    return intervener ? [intervener] : [];
  }, [formattedData, openedTicket]);

  const typologiesFilter = useMemo(() => {
    const typology = formattedData.find((d) => d._id === openedTicket?._id);

    if (typology) {
      return [{ _id: formattedData.find((d) => d._id === openedTicket?._id)._typology }];
    } else {
      return [];
    }
  }, [formattedData, openedTicket]);

  const defaultFilters = useMemo(() => {
    return {
      states: ['finished', 'closed'],
      typologies: typologiesFilter,
      interveners: intervenersFilter
    };
  }, [typologiesFilter, intervenersFilter]);

  const hiddenFilters = useMemo(() => {
    return {
      compta: false,
      excepts: [openedTicket?._id]
    };
  }, [openedTicket]);

  const formatChildRows = (rows) => {
    return rows.map((r) => ({
      ...r,
      parentId: r._id,
      facture_state: r.facture_state,
      devis_price: computeDocumentPrice(r, 'devis', (devis) => devis.validated.state && !devis.deleted.state)
    }));
  };

  return (
    <div
      style={{
        borderRadius: 0,
        position: 'absolute',
        top: '0px',
        left: '0px',
        width: '100%',
        height: '100%'
      }}
    >
      {/* ! MODAL TICKET */}
      <Ticket
        isDialog
        noFetch
        defaultOpenView
        childrenId={openTicketId}
        childrenAuto
        afterDialogClose={() => setOpenTicketId(null)}
      >
        <></>
      </Ticket>

      {/* MODAL QUOTES AND INVOICES */}
      {documentViewerConfig.isOpen && (
        <ModalAccounting
          documentViewerConfig={documentViewerConfig}
          tickets={formattedData}
          setDocumentViewerConfig={setDocumentViewerConfig}
        />
      )}

      {openedTicket && (
        <Form
          initialValues={{
            factureToTickets: openedTicket._facture_tickets || [],
            ticketId: openedTicket._id || null
          }}
          onSubmit={({ factureToTickets, ticketId }, updates) => {
            const tickets = factureToTickets.map((ticket) => ticket._id);

            const dispatchCallbacks = {
              onSuccess: () => notify.success(t('ticketsAreLinked')),
              onError: () => notify.error(t('ticketsAreLinkedError'))
            };

            if (!updates?.length) return;

            dispatch(linkTicketsToFacture, { tickets, from: 'compta' }, dispatchCallbacks, {
              id: ticketId
            });
          }}
        >
          <TicketPickerDrawer
            openedTicket={openedTicket}
            onCloseOpenedTicket={onCloseOpenedTicket}
            defaultFilters={defaultFilters}
            hiddenFilters={hiddenFilters}
          />
        </Form>
      )}

      {isLoading || requestStatus === 'loading' ? (
        <>
          {columns.concat(columns).map((_, index) => (
            <AccountingSkeleton key={`table-skeleton-${index}`} />
          ))}
        </>
      ) : (
        <TableContainer style={{ maxHeight: 'calc(100% - 54px )' }}>
          {formattedData.length ? (
            <Table stickyHeader>
              <TableHead>
                <TableRow style={{ height: '4em' }}>
                  {columns.map((column, index) => {
                    return (
                      <TableCell
                        key={`invoice-table-header-${index}`}
                        style={{ ...column.headerStyle, padding: 0 }}
                      >
                        {column.title}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                <Rows
                  formattedData={formattedData}
                  columns={columns}
                  formatChildRows={formatChildRows}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  count={count}
                />
              </TableBody>
            </Table>
          ) : (
            <Empty
              icon="calculator"
              translations={{
                noResultLabel: t('accountingTitle'),
                noResultText: t('noResultFoundAccounting')
              }}
            />
          )}
        </TableContainer>
      )}

      <TablePagination
        component="div"
        count={count}
        rowsPerPageOptions={[5, 10, 15]}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
        labelRowsPerPage={t('labelRowsPerPage')}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} à ${count !== -1 ? count : `more than ${to}`}`}
      />
    </div>
  );
}

function TicketPickerDrawer({ onCloseOpenedTicket, openedTicket, defaultFilters, hiddenFilters }) {
  const isBelowMd = useIsBelowMd();

  const theme = useTheme();

  const { handleSubmit } = useForm();

  return (
    <Drawer
      // disableBackdropTransition={!iOS}
      // disableDiscovery={iOS}
      open={!!openedTicket}
      onClose={(ev) => [ev.preventDefault(), onCloseOpenedTicket(), handleSubmit()]}
      anchor="left"
      elevation={0}
      paperProps={{}}
    >
      <Box
        style={{
          width: !isBelowMd ? '40vw' : '90vw',
          height: '100%'
        }}
      >
        {isBelowMd && (
          <Clear
            onClick={(ev) => [ev.preventDefault(), onCloseOpenedTicket(), handleSubmit()]}
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              margin: 10,
              color: theme.palette.secondary.main,
              cursor: 'pointer'
            }}
          />
        )}
        <Ticket
          picker
          defaultFilters={defaultFilters}
          hiddenFilters={hiddenFilters}
          pickerField={'factureToTickets'}
        />
      </Box>
    </Drawer>
  );
}

function Rows({ formattedData, formatChildRows, columns, page, rowsPerPage, count }) {
  let dataToRetrieve;
  const moduloSlice = (page * rowsPerPage) % formattedData.length;
  const lastElements = page * rowsPerPage - count;

  if (page >= Math.ceil(count / rowsPerPage) - 1) {
    dataToRetrieve = formattedData.slice(lastElements);
  } else if (formattedData.length !== 30) {
    const dataAlreadyRetrieve = formattedData.slice(0, lastElements + rowsPerPage);

    dataToRetrieve = dataAlreadyRetrieve.slice(
      (page * rowsPerPage) % dataAlreadyRetrieve.length,
      ((page * rowsPerPage) % dataAlreadyRetrieve.length) + rowsPerPage
    );
  } else {
    dataToRetrieve = formattedData.slice(moduloSlice, moduloSlice + rowsPerPage);
  }

  return dataToRetrieve.map((ticket, index) => {
    return (
      <Row
        columns={columns}
        key={`ticket-${ticket._id}-invoice-table-row-${index}`}
        index={index}
        formatChildRows={formatChildRows}
        ticket={ticket}
      />
    );
  });
}

function Row({ columns, ticket, formatChildRows, index }) {
  const [childrenAreOpen, setChildrenAreOpen] = useState(false);
  const accounting_email = (ticket._locations || [])[0]?.accounting_email;

  return (
    <>
      <TableRow style={{ backgroundColor: index % 2 === 0 ? 'white' : '#ebebeb' }}>
        {columns.map((column, i) => {
          return (
            <TableCell
              key={`ticket-${ticket._id}-invoice-table-row-${index}-cell-${i}`}
              style={{ padding: 8 }}
            >
              {column.render({ ticket, setChildrenAreOpen, childrenAreOpen })}
            </TableCell>
          );
        })}
      </TableRow>
      {!!ticket._facture_tickets?.length && childrenAreOpen && (
        <>
          {[
            {
              ...ticket,
              parentId: ticket?._id,
              devis_price: DevisHelper.getTotalPrice({ devis: ticket.devis })
            }
          ]
            .concat(formatChildRows(ticket._facture_tickets))
            .map((ticket, i) => (
              <SubTicket
                key={`ticket-${ticket._id}-invoice-table-row-${index}-linked-ticket-${i}`}
                index={i}
                ticket={omit(ticket, ['childrenTickets'])}
                columns={columns}
                accounting_email={accounting_email}
              />
            ))}
        </>
      )}
    </>
  );
}

function SubTicket({ ticket, columns, index, accounting_email }) {
  const sub_accounting_email = (ticket._locations || [])[0]?.accounting_email;
  const accounting_emails_are_identical = accounting_email === sub_accounting_email;
  const has_accounting_email = Boolean(sub_accounting_email);

  return (
    <TableRow style={{ backgroundColor: index % 2 === 0 ? 'white' : '#ebebeb' }}>
      {columns.map((column) => {
        return (
          <TableCell
            key={nanoid()}
            style={{ padding: 14, color: !accounting_emails_are_identical && has_accounting_email ? 'red' : '' }}
          >
            {column.render({ ticket })}
          </TableCell>
        );
      })}
    </TableRow>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5)
  }
}));

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onChangePage } = props;

  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <Tooltip title={t('firstPage')}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
        </IconButton>
      </Tooltip>
      <Tooltip title={t('backLabelPage')}>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
      </Tooltip>
      <Tooltip title={t('nextLabelPage')}>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
      </Tooltip>
      <Tooltip title={t('lastPage')}>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
        </IconButton>
      </Tooltip>
    </div>
  );
}
