import { Field, Form, Submit } from 'frmx';
import { useTranslation } from 'react-i18next';
import { DIR_MIME_TYPE } from '../constants';
import DialogMUI from 'components/DialogMUI';

export default function RenameDocumentDialog({ document, setDocument, renameDocument }) {
  const { t } = useTranslation();

  return (
    <>
      <DialogMUI
        isOpen={!!document}
        title={
          document?.mimetype === DIR_MIME_TYPE
            ? `${t('rename')} ${t('folder')}`
            : `${t('rename')} ${t('Document')}`
        }
        icon="pen-to-square"
        className="w-[450px] z-[1400]"
        onClose={() => setDocument(null)}
      >
        <Form
          initialValues={{ name: document?.name }}
          onSubmit={(values) => [
            renameDocument({ document_id: document._id, name: values.name }),
            setDocument(null)
          ]}
          className="mt-2 flex flex-col"
        >
          <Field
            path="name"
            className="pl-2 my-4 h-10 text-base shadow-sm focus:ring-sky-500 focus:border-sky-500 border-2 border-gray-300 rounded-md"
          >
            <input />
          </Field>
          <div className="flex justify-end space-x-2 mb-3">
            <button
              className="border border-red-500 text-red-500 px-3 py-2 rounded-full"
              onClick={() => setDocument(null)}
            >
              {t('cancel')}
            </button>
            <Submit>
              <button className="bg-sky-900 disabled:opacity-50 text-white px-3 py-2 rounded-full">
                {t('rename')}
              </button>
            </Submit>
          </div>
        </Form>
      </DialogMUI>
    </>
  );
}
