import { Grid, TextField, FormControlLabel, Checkbox, MenuItem } from '@material-ui/core';
import EntityX from 'components/inputs/EntityX';
import Scope from 'entities/Scope/Scope';
import Location from 'entities/Location/Location';

import { Field, useFieldObserver, useForm } from 'frmx';
import Job from 'entities/Job/Job';
import { equipmentWear } from 'lists/equipmentWear';
import { useEntity } from 'contexts/entities/entityContext';
import useKeys from '@flowsn4ke/usekeys';
import DateField from 'components/inputs/DateField';
import { useTranslation } from 'react-i18next';
import { useConfiguration } from 'hooks/useConfiguration';
import Client from 'entities/Client/Client';
import { useAuth } from 'hooks/useAuth';
import { useEffect } from 'react';
import { operational_states_equipment } from 'lists/operational_states_equipment';
import { status_of_equipment } from 'lists/status_of_equipment';

export default function EquipmentForm({ isCreate }) {
  const { sublistParent } = useEntity();
  const auth = useAuth();
  const config = useConfiguration();
  const isClient = auth.interface.isClient;

  const parentCategory = useFieldObserver('_category');
  const subCategory = useFieldObserver('_subcategory');
  const client = useFieldObserver('_client');

  const { setOneField, getOneUpdated } = useForm();

  useEffect(() => {
    if (parentCategory) {
      setOneField('_subcategory', subCategory);
    } else {
      setOneField('_subcategory', null);
    }
  }, [parentCategory]);

  useEffect(() => {
    if (getOneUpdated('_client') && config.isContractor) setOneField('_location', null);
  }, [client]);

  const { t } = useTranslation();

  const k = useKeys();
  const k1 = useKeys();
  const k2 = useKeys();

  return (
    <>
      <Grid
        item
        xs={12}
      >
        <Field path="name">
          <TextField
            autoFocus
            variant="outlined"
            label={t('equipmentName')}
            fullWidth
          />
        </Field>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <Field path="description">
          <TextField
            multiline
            rows={3}
            variant="outlined"
            label={t('description')}
            fullWidth
          />
        </Field>
      </Grid>

      {!sublistParent && (config.isContractor || config.isMaster) && (
        <Grid
          item
          xs={12}
        >
          <EntityX
            path="_client"
            entityName="clients"
            entity={Client}
            placeholder={t('client')}
            pickerUniq
          />
        </Grid>
      )}

      <Grid
        item
        xs={12}
      >
        <EntityX
          path="_category"
          entity={Scope}
          entityProps={{ type: 'equipmentCategory' }}
          placeholder={t('category')}
          pickerUniq
        />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <EntityX
          refreshValue
          disabled={!parentCategory}
          path="_subcategory"
          entity={Scope}
          entityProps={{
            defaultFilters: { ...(client ? { clients: [client] } : {}) },
            type: 'equipmentSubCategory',
            hiddenFilters: {
              only: [],
              parents: [parentCategory?._id]
            }
          }}
          placeholder={t('subCategory')}
          pickerUniq
        />
      </Grid>

      {!sublistParent && (
        <Grid
          item
          xs={12}
        >
          <EntityX
            refreshValue
            disabled={!client && !isClient}
            disabledHelperText={t('pleaseSelectAClientFirst')}
            path="_location"
            entityName="locations"
            entity={Location}
            pickerUniq
            placeholder={t('location')}
            entityProps={{
              hiddenFilters: { ...(client ? { clients: [client] } : {}) }
            }}
          />
        </Grid>
      )}

      <Grid
        item
        xs={12}
      >
        <EntityX
          path="_jobs"
          entityName="jobs"
          entity={Job}
          entityProps={{
            hiddenFilters: {
              section: {
                mine: true,
                bobdesk: true,
                bobdepannage: true
              }
            }
          }}
          placeholder={t('jobs')}
        />
      </Grid>

      <Grid
        item
        xs={12}
      >
        <Field path="brand">
          <TextField
            variant="outlined"
            label={t('brand')}
            fullWidth
          />
        </Field>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <Field path="model">
          <TextField
            variant="outlined"
            label={t('model')}
            fullWidth
          />
        </Field>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <Field path="serial">
          <TextField
            variant="outlined"
            label={t('serialNumber')}
            fullWidth
          />
        </Field>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <Field
          path="year"
          type="number"
        >
          <TextField
            variant="outlined"
            fullWidth
            label={t('year')}
          />
        </Field>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <DateField
          path="buy_date"
          label={t('purchaseDate')}
          variant="outlined"
        />
      </Grid>

      <Grid
        item
        xs={12}
      >
        <DateField
          path="revision_date"
          label={t('revisionDate')}
          variant="outlined"
        />
      </Grid>

      <Grid
        item
        xs={12}
      >
        <Field path="state">
          <TextField
            variant="outlined"
            fullWidth
            select
            label={t('dilapidationState')}
          >
            <MenuItem
              value=""
              aria-label="none"
              className="h-7"
            />
            {equipmentWear.map((state, i) => (
              <MenuItem
                key={k(i)}
                value={state.value}
              >
                {t(state.label)}
              </MenuItem>
            ))}
          </TextField>
        </Field>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <Field path="operational_state">
          <TextField
            variant="outlined"
            fullWidth
            select
            label={t('operationalState')}
            SelectProps={{
              renderValue: (value) => {
                const state = operational_states_equipment.find((s) => s.value === value);
                return (
                  state && (
                    <div className="flex items-center">
                      <div className={`w-2 h-2 rounded-full mr-2 ${state.color}`} />
                      {t(state.label)}
                    </div>
                  )
                );
              }
            }}
          >
            <MenuItem
              value=""
              aria-label="none"
              className="h-7"
            />
            {operational_states_equipment.map((state, state_idx) => (
              <MenuItem
                key={k1(state_idx)}
                value={state.value}
              >
                <span className={`w-2 h-2 rounded-full mr-2 ${state.color}`} />
                {t(state.label)}
              </MenuItem>
            ))}
          </TextField>
        </Field>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <Field path="status">
          <TextField
            variant="outlined"
            fullWidth
            select
            label={t('statusOfEquipment')}
          >
            {status_of_equipment.map((state, state_idx) => (
              <MenuItem
                key={k2(state_idx)}
                value={state.value}
              >
                {t(state.label)}
              </MenuItem>
            ))}
          </TextField>
        </Field>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <DateField
          path="replacement_date"
          label={t('planned-replacement-date')}
          variant="outlined"
        />
      </Grid>

      <Grid
        item
        xs={12}
      >
        <FormControlLabel
          control={
            <Field
              path="isContract"
              type="checkbox"
            >
              <Checkbox />
            </Field>
          }
          label={t('underContract')}
        />
      </Grid>
    </>
  );
}
