export const blachDomains = [
  { value: 'production', label: 'Production' },
  { value: 'batiment', label: 'Batiment' },
  { value: 'derat', label: 'Dératisation' },
  { value: 'dechets', label: 'Déchets' },
  { value: 'clean', label: 'Nettoyage' }
];

export const blachSubDomains = [
  { value: 'froid', label: 'Froid', _id: '5e29edb69cbeb4004a7014b4' },
  { value: 'materiel', label: 'Materiel', _id: '5e29edc19cbeb4004a7014b6' },
  { value: 'four', label: 'Four', id: '5e2c688659edf5004af58e23' },
  { value: 'autres', label: 'Autres', _id: '613086d2b856200096edd351' },
  { value: 'production_preventif', label: 'Maintenance', _id: '613086d2b856200096edd351' }
];
