import { AccordionActions, AccordionDetails, Grid, Button } from '@material-ui/core';

import { zones } from 'lists/zones';

import { useAuth } from 'hooks/useAuth';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import useNotifications from 'hooks/useNotifications';
import { setCompanyInfo } from 'store/authSlice';
import { Submit, Reset } from 'frmx';

import AutoCompleteChips from 'components/inputs/AutoCompleteChips';
import AccordionForm from 'components/forms/AccordionForm';
import { useTranslation } from 'react-i18next';

export function ActivityField({ path }) {
  const { t } = useTranslation();

  return (
    <AutoCompleteChips
      transform={(val) => val.zone_id}
      label={t('interventionZones')}
      path={path}
      options={zones}
    />
  );
}

export default function Activity({ section }) {
  const { t } = useTranslation();
  const auth = useAuth();
  const company = auth.interface._company;
  const { dispatch, requestStatus } = useAsyncDispatch();

  const clientType = auth.interface.isClient ? 'client' : 'contractor';

  const notify = useNotifications();
  const dispatchCallbacks = {
    onSuccess: () => notify.success(),
    onError: () => notify.error()
  };

  return (
    <AccordionForm
      section={section}
      initialValues={{
        [clientType]: {
          zone_ids: company?.zone_ids || []
        }
      }}
      onSubmit={(updates) => dispatch(setCompanyInfo, updates, dispatchCallbacks)}
    >
      <AccordionDetails>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <ActivityField path={`${clientType}.zone_ids`} />
          </Grid>
        </Grid>
      </AccordionDetails>
      <AccordionActions>
        <Reset>
          <Button size="small">{t('cancel')}</Button>
        </Reset>
        <Submit disabled={requestStatus === 'loading'}>
          <Button
            size="small"
            color="primary"
          >
            {t('save')}
          </Button>
        </Submit>
      </AccordionActions>
    </AccordionForm>
  );
}
