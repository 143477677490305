import { Button, Fade, TextField, Typography } from '@material-ui/core';
import { useState, useRef } from 'react';
import { supabase } from '../../utils/supabaseClient';
import useStyles from './Portal.styles';
import SystemsStatus from 'components/SystemsStatus';
import FAIcon from 'components/ui/FAIcon';
import { Field, Form, Submit } from 'frmx';
import { INTERFACE_ID } from 'constants/localStorage';
import { isEmail } from 'validator';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

export const LoginSSOPage = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles();

  let initialValues = useRef({
    email: '',
    interface_id: localStorage.getItem(INTERFACE_ID),
    screen_width: window.screen.width,
    screen_height: window.screen.height
  });
  initialValues = initialValues.current;

  let schemaValidation = useRef({
    email: (val) => isEmail(val)
  });
  schemaValidation = schemaValidation.current;

  const signInWithSSO = async (email) => {
    const domain = email.split('@')[1];

    const { error, data } = await supabase.auth.signInWithSSO({ domain, email });

    if (error) {
      console.error('Error signing in with SSO:', error);
      setError(error.message);
    }

    if (data?.url) {
      // redirect the user to the identity provider's authentication flow
      window.location.href = data.url;
    }

    setIsLoading(false);
  };

  const handleSubmit = async (data) => {
    setIsLoading(true);
    const email = data.email;
    await signInWithSSO(email);
  };

  const { t } = useTranslation();

  return (
    <Form
      render="form"
      initialValues={initialValues}
      schemaValidation={schemaValidation}
      onSubmit={(data) => handleSubmit(data)}
      className={classes.formContainer}
      disableIfInvalid
    >
      <Field
        path="email"
        type="email"
        isErrorProp="error"
      >
        <TextField
          required
          variant="outlined"
          label={t('emailInputLabel')}
          className={classes.spacing}
        />
      </Field>

      <Submit disabled={isLoading}>
        <Button
          className={classNames(classes.spacing, classes.submitButtonContainer)}
          variant="contained"
          color="primary"
          disableElevation
        >
          <Typography>{t('signInButtonLabel')}</Typography>
          <FAIcon
            icon={isLoading ? 'spinner' : 'power-off'}
            className={isLoading && classes.spinner}
            collection={'fas'}
            size="medium"
          />
        </Button>
      </Submit>

      <Fade in={error}>
        <Typography
          variant="body2"
          color="error"
          className={classes.messageError}
        >
          {error === 'No SSO provider assigned for this domain' ? t('no-SSO-provider-error-msg') : error}
        </Typography>
      </Fade>

      <SystemsStatus />
    </Form>
  );
};
