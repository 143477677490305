const JobTranslations = (configuration) => ({
  pick: 'entityJobPick',
  create: 'entityJobCreate',
  update: 'entityJobUpdate',
  entityName: 'entityJobName',
  noResultLabel: 'entityJobnoResultLabel',
  searchText: 'entityJobSearchText',
  createDialog: 'entityJobCreateDialog',
  updateDialog: 'entityJobUpdateDialog'
});

export default JobTranslations;
