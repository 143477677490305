import { isEmail } from 'validator';
import { pick } from 'lodash-es';

export const isUserFeatureActivated = (config) => config?.feature?.contact;

const UserFormValues = (config) => ({
  avatar: '',
  firstName: '',
  lastName: '',
  _locations: [],
  _role: null,
  email: '',
  phone: '',
  fix: '',
  lang: '',
  userInterface: isUserFeatureActivated(config) && config?.contact_userinterface,
  sendEmail: isUserFeatureActivated(config)
});

export default UserFormValues;

export const formatForm = (dataFromForm, isCreate, attachments) => {
  if (!!attachments?.length) {
    dataFromForm.avatar = attachments[0].url;
  }

  return {
    ...dataFromForm,
    _user: dataFromForm.userInterface ? pick(dataFromForm, ['firstName', 'lastName', 'avatar']) : null
  };
};

export const UserValidation = (configuration, formData) => ({
  _role: (value, data) => (data.userInterface ? !!value : true),
  email: (value, data) => (!!(data.userInterface || value?.length) ? isEmail(value) : true),
  userInterface: (value, data) => (!!(value || data.email?.length) ? isEmail(data.email) : true)
});
