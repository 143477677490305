import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  entityMenu: {
    marginRight: 0,
    marginLeft: 6,
    width: 16,
    height: 16,
    padding: 16,
    alignItems: 'center',
    display: 'flex'
  },
  moreMenu: {
    padding: 8,
    minWidth: 180
  },
  moreMenuElement: {
    borderRadius: theme.radius,
    margin: '.2em 0em',
    paddingRight: '1.5em'
  },
  moreMenuIcon: {
    fontSize: 14,
    marginRight: 24
  }
}));
