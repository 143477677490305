import { isEmail } from 'validator';
import { pick } from 'lodash-es';

const ClientFormValues = (configuration) => ({
  partner: {
    permissions: ['ticket', 'date'],
    permissions_given: ['assign', 'propose']
  },
  ref: '',
  isSiege: false,
  name: '',
  phone: '',
  email: '',
  _acquisition: null,
  address: null,
  _category: null,
  fix: '',
  siret: '',
  description: '',
  userInterface: true,
  sendEmail: true,
  firstName: '',
  lastName: '',
  regime: 'pro',
  fields: [],
  lang: ''
});

export default ClientFormValues;

export const formatForm = (dataFromForm, isCreate, attachments) => {
  if (!!attachments?.length) {
    dataFromForm.avatar = attachments[0].url;
  }

  return {
    ...dataFromForm,
    _user: dataFromForm.userInterface ? pick(dataFromForm, ['firstName', 'lastName']) : null
  };
};

export const ClientValidation = (config, data) => {
  return {
    email: (value, data) => (!!(data.userInterface || value?.length) ? isEmail(value) : true),
    userInterface: (value, data) => (!!(value || data.email?.length) ? isEmail(data.email) : true)
  };
};
