import { Box, Tabs, Tab, useTheme, Button, Dialog, CircularProgress } from '@material-ui/core';
import { useMemo, useState } from 'react';
import Ticket from 'entities/Ticket/Ticket';
import { Empty } from 'layouts/entities/List';
import { Add } from '@material-ui/icons';
import { Form, useForm } from 'frmx';
import useNotifications from 'hooks/useNotifications';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import { linkElements } from 'store/ticketsSlice';
import FAIcon from 'components/ui/FAIcon';
import { useTranslation } from 'react-i18next';
import { useConfiguration } from 'hooks/useConfiguration';
import { useIsBelowSm } from 'hooks/useMQ';

export default function TicketLinks({ element, hiddenFilters: hidden }) {
  const [tab, setTab] = useState(0);
  const [isPickerOpen, setIsPickerOpen] = useState(false);

  const notify = useNotifications();
  const { dispatch, requestStatus } = useAsyncDispatch();
  const config = useConfiguration();

  const { t } = useTranslation();

  const defaultFilters = useMemo(
    () =>
      tab === 0
        ? {
            section: { closed: true },
            locations: element?._locations || [],
            jobs: element?._jobs || [],
            equipments: element?._equipments || [],
            ...(element?.category ? { categories: [element.category] } : {}),
            ...(config.isBlachere ? { domains: [element.domaine] } : {})
          }
        : {},
    [element._id, tab]
  );

  const hiddenFilters = useMemo(
    () =>
      tab === 1
        ? {
            excepts: [element._id],
            ids: [
              ...(element?._tickets_linked || []),
              ...(element?._ticket_parents || []).map((t) => t._id)
            ]
          }
        : {
            excepts: [element._id],
            ...hidden
          },
    [element._id, tab]
  );

  const theme = useTheme();
  const count = (element?._tickets_linked?.length || 0) + (element?._ticket_parents?.length || 0);

  const isBelowSm = useIsBelowSm();

  const cta = (
    <Button
      color="secondary"
      size="small"
      style={{ borderRadius: 8 }}
      variant="outlined"
      onClick={() => setIsPickerOpen(true)}
      endIcon={<Add />}
    >
      {t(config.isBlachere ? 'blachLinkAnIntervention' : 'linkAnIntervention')}
    </Button>
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      bgcolor="secondary"
      flexGrow={1}
      height={isBelowSm ? '100%' : '82vh'}
    >
      <Box bgcolor={theme.palette.primary.light}>
        <Tabs
          indicatorColor="primary"
          variant="fullWidth"
          value={tab}
          onChange={(e, index) => setTab(index)}
        >
          <Tab
            style={{ color: 'white' }}
            value={0}
            label={t('similar')}
          />
          <Tab
            style={{ color: 'white' }}
            value={1}
            label={t('linked')}
          />
        </Tabs>
      </Box>
      <Box flexGrow={1}>
        {tab === 0 && (
          <Ticket
            enableForm
            disableCreate
            hiddenFilters={hiddenFilters}
            defaultFilters={defaultFilters}
            disableGutters
            disableShadow
          />
        )}
        {tab === 1 && (
          <>
            {!count && (
              <Empty
                icon="link"
                collection={'fad'}
                translations={{
                  noResultLabel: config.isBlachere
                    ? 'blachNoInterventionLinked'
                    : 'noInterventionLinked'
                }}
                cta={cta}
              />
            )}
            {!!count && (
              <Ticket
                enableForm
                disableCreate
                hiddenFilters={hiddenFilters}
                disableGutters
                disableShadow
                listAction={cta}
              />
            )}
          </>
        )}
      </Box>

      <Dialog
        fullWidth
        maxWidth={'sm'}
        open={isPickerOpen}
        onClose={() => setIsPickerOpen(false)}
      >
        {isPickerOpen && (
          <Form
            style={{ height: '500px' }}
            initialValues={{ tickets: element._tickets_linked.map((t) => ({ _id: t })) }}
            onSubmit={(form) =>
              dispatch(
                linkElements,
                { tickets: form.tickets.map((t) => t._id) },
                {
                  onSuccess: () => [notify.success(), setIsPickerOpen(false)]
                },
                { id: element._id }
              )
            }
          >
            {isPickerOpen && (
              <TicketPicker
                requestStatus={requestStatus}
                element={element}
              />
            )}
          </Form>
        )}
      </Dialog>
    </Box>
  );
}

function TicketPicker({ element, requestStatus }) {
  const hiddenFilters = useMemo(
    () => ({
      excepts: [element._id, ...element._ticket_parents.map((t) => t._id)]
    }),
    []
  );
  const { t } = useTranslation();
  const { handleSubmit } = useForm();
  const isLoading = requestStatus === 'loading';

  return (
    <Box
      height="100%"
      width="100%"
      display="flex"
      flexDirection="column"
    >
      <Ticket
        disableCreate
        hiddenFilters={hiddenFilters}
        disableGutters
        disableShadow
        picker
        pickerField="tickets"
      />
      <Box
        display="flex"
        justifyContent="flex-end"
        padding="4px 6px"
        bgcolor="white"
        alignItems="center"
      >
        {isLoading && <CircularProgress size={20} />}
        <Button
          startIcon={
            <FAIcon
              collection={'fad'}
              icon={'link'}
            />
          }
          style={{ marginLeft: 20 }}
          disabled={isLoading}
          onClick={(e) => [handleSubmit(e)]}
          color="primary"
          size="small"
        >
          {t('save2')}
        </Button>
      </Box>
    </Box>
  );
}
