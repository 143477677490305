import { Dialog, Drawer } from '@material-ui/core';
import { useEntity } from 'contexts/entities/entityContext';

import { useIsBelowMd } from 'hooks/useMQ';

import Element from 'layouts/entities/Element';

import useStyles from 'components/dialogs/Dialog.styles';

export default function EntityView({ open, onClose, setIsOpenForm, isDialog }) {
  const isBelowMd = useIsBelowMd();
  const { tabs, fromTimeline } = useEntity();

  const classes = useStyles({ width: 200, noTabs: !tabs?.length });

  return isBelowMd && !isDialog ? (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="bottom"
      classes={{
        paper: classes.dialogDrawer
      }}
    >
      <Element
        isDrawer
        setIsOpenForm={setIsOpenForm}
        onClose={onClose}
      />
    </Drawer>
  ) : (
    <Dialog
      fullScreen={isBelowMd}
      maxWidth={fromTimeline ? 'lg' : 'md'}
      fullWidth
      open={open}
      onClose={onClose}
      PaperProps={{ className: classes.dialogNotFullScreen }}
      className={classes.dialogFullScreenContainer}
    >
      <Element
        isDialog
        setIsOpenForm={setIsOpenForm}
        onClose={onClose}
      />
    </Dialog>
  );
}
