import { useState, createElement } from 'react';

import Portal from 'components/Portal';
import { Popover as HeadlessPopover } from '@headlessui/react';
import { usePopper } from 'react-popper';
import classNames from 'classnames';

// ! Not working yet (need refactoring entities and filters list)
// * Only works on Menu.js
export default function Popover({ button, contents, className, offset, placement }) {
  const [referenceElement, setReferenceElement] = useState();
  const [popperElement, setPopperElement] = useState();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: placement || 'bottom-start',
    modifiers: [{ name: 'offset', options: { offset: offset || [0, 8] } }]
  });

  return (
    <HeadlessPopover>
      <HeadlessPopover.Button ref={setReferenceElement}>
        {createElement(button)}
      </HeadlessPopover.Button>
      <Portal>
        <HeadlessPopover.Panel
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          className={classNames('rounded-lg bg-white shadow-lg w-auto h-auto', className)}
        >
          {({ close }) => createElement(contents, { close })}
        </HeadlessPopover.Panel>
      </Portal>
    </HeadlessPopover>
  );
}
