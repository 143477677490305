import { Box, Button, Typography, useTheme } from '@material-ui/core';
import Avatar from 'components/Avatar';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { resetPublicTicketCreated } from 'store/authSlice';
import { dateToLocalFormat } from 'utils/dates';
import logo from 'images/logo.svg';
import Check from 'libs/swal/Check';
import { useIsBelowMd } from 'hooks/useMQ';
import FullScreenError from './FullScreenError';
import { useTranslation } from 'react-i18next';
import Splash from 'components/Splash';
import useStyles from '../layouts/entities/Entity.styles';
import FAIcon from 'components/ui/FAIcon';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { apiBaseURL } from 'index';

const prependZero = (n) => (n > 9 ? n : '0' + n);

function getTime(time = new Date()) {
  const hours = prependZero(time.getHours());
  const minutes = prependZero(time.getMinutes());
  const seconds = prependZero(time.getSeconds());
  return `${hours} : ${minutes} : ${seconds}`;
}

function Clock() {
  const clockRef = useRef();

  useEffect(() => {
    const intervalId = setInterval(() => {
      clockRef.current && (clockRef.current.innerText = getTime());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Typography
      ref={clockRef}
      variant="subtitle1"
      style={{ color: 'white' }}
    >
      {getTime()}
    </Typography>
  );
}

const bobDeskLogoURL = 'https://images.bob-desk.com/3c8e0988-9691-4820-877e-69deccd80ea5_v1.false'

export default function QRCodeTarget({ scope }) {
  const { id } = useParams();
  const [statusCode, setStatusCode] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchPublicData = async () => {
      try {
        const response = await fetch(`${apiBaseURL.replace('/api', '')}/public/${scope}/${id}`);
        const data = await response.json()
        setData(data);
        const clientHasEnabledPublicLocalInterface = Boolean(data?.client?.configuration?.ticket_public?.local);
        setStatusCode(!clientHasEnabledPublicLocalInterface ? 404 : response.status);
      } catch (error) {
        console.error(error);
        setStatusCode(500);
      }
    };

    fetchPublicData();
  }, []);

  // todo: we need info about the company, the location, the equipment
  // todo: create ticket redirect url w/ equipment info in query string
  // todo: display location/equipment cover if any

  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const isBelowMd = useIsBelowMd();
  const locationName = data?.location?.name || 'Global';
  const locationId = data?.location?._id;
  const locationCover = data?.location?._cover?.url;
  const locationAddress = data?.location?.address?.fullAddress;
  const avatarURL = data?.client?.avatar || bobDeskLogoURL;
  const equipmentId = data?.equipment?._id;
  const equipmentName = data?.equipment?.name;
  const equipmentCover = data?.equipment?._cover?.url;
  const globalPublicInterfaceCover = data?.client?.configuration?.ticket_public?.background;
  const bgURL = locationCover || globalPublicInterfaceCover;
  const equipmentQueryStringInfo = scope === 'equipments' ? String(new URLSearchParams({ equipmentName, equipmentId })) : ''

  const infoURL = `/${scope}-list/${id}`
  const createTicketURL = `/public/${locationId}/local?${equipmentQueryStringInfo}`

  const background = bgURL
    ? `url(${bgURL}) no-repeat center center fixed`
    : 'linear-gradient(230deg,#a24bcf,#4b79cf,#4bc5cf)';

  return !statusCode ? (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
        background: 'linear-gradient(230deg,#a24bcf,#4b79cf,#4bc5cf)'
      }}
    >
      <Splash
        noAvatar
        text={t('stillLoading')}
      />
    </div>
  ) : (statusCode === 200 || statusCode === 304) ? (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background,
        WebkitBackgroundSize: 'cover',
        MozBackgroundSize: 'cover',
        OBackgroundSize: 'cover',
        backgroundSize: 'cover',
        width: '100vw',
        height: '100vh'
      }}
    >
      <Box
        style={
          !isBelowMd
            ? {
              position: 'absolute',
              top: '3em',
              left: '3em',
              display: 'flex'
            }
            : {
              display: 'flex',
              width: '95vw',
              marginBottom: '1em'
            }
        }
      >
        <Avatar
          size="large2"
          entity={{
            avatar: avatarURL,
            name: data?.client?.name
          }}
        />
        <Box
          style={{
            marginLeft: '1em',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }}
        >
          <Typography
            variant="h4"
            style={{ color: 'white' }}
          >
            {data?.client?.name}
          </Typography>
          <Box>
            <Typography
              variant="subtitle1"
              style={{ color: 'white' }}
            >
              {dateToLocalFormat(new Date())}
            </Typography>
            <Clock />
          </Box>
        </Box>
      </Box>
      {/* <Box className="absolute top-5 right-5">
        <LanguageSwitch />
      </Box> */}
      <Box
        style={{
          ...(isBelowMd
            ? {
              width: '95vw',
              height: '80vh',
              zIndex: 999
            }
            : {
              width: 700,
              maxHeight: '70vh',
              flexGrow: 1
            }),
          // backgroundColor: 'white',
          borderRadius: theme.radius,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-around',
        }}
      >
        <div className='-mt-32 p-10 rounded-lg flex flex-col gap-y-10 w-full bg-white'>
          <div className='flex md:flex-row gap-y-6 flex-col items-center md:items-start md:justify-between'>
            <Avatar
              size="large2"
              entity={{
                avatar: equipmentCover || locationCover,
                name: equipmentName || locationName
              }}
            />
            <div className='text-center md:text-right flex flex-col'>
              <div className='text-lg font-bold'>
                {equipmentName || locationName}
              </div>
              {Boolean(equipmentName) && <div className='text-base'>
                {locationName}
              </div>}
              <div className=''>
                {locationAddress}
              </div>
            </div>
          </div>
          <div className='flex flex-col gap-y-6'>
            <Button
              variant="contained"
              color="primary"
              className={classes.fabButtonPublic}
              href={createTicketURL}
              target="_blank"
            >
              <FAIcon
                collection="fal"
                icon="circle-plus"
                style={{ position: "absolute", left: ".3em" }}
              />
              Créer une intervention
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.fabButton}
              href={infoURL}
              target="_blank"
            >
              <FAIcon
                collection="fal"
                icon="info-circle"
                style={{ position: "absolute", left: ".3em" }}
              />
              Voir la fiche {scope === 'locations' ? 'du lieu' : 'de l\'équipement'}
            </Button>
          </div>
        </div>
      </Box>
      <img
        style={{ position: 'absolute', bottom: '3em', right: '3em', height: '4em' }}
        src={logo}
        alt=""
      />
    </Box>
  ) : statusCode === 401 ? (
    <FullScreenError /> // TODO: Different message for unactivated public interface?
  ) : statusCode === 404 ? (
    <FullScreenError />
  ) : statusCode === 500 ? (
    <FullScreenError />
  ) : null;
}

function SuccessMessage() {
  const syncDispatch = useDispatch();
  const { t } = useTranslation();

  const goBackToForm = () => syncDispatch(resetPublicTicketCreated());

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      goBackToForm();
    }, 3000);
    return () => clearTimeout(timeOutId);
  }, []);

  return (
    <>
      <Box style={{ height: 100 }}>
        <Check />
      </Box>
      <Typography variant="h4">{t('ticketSuccessFullyCreated')}</Typography>
      <Button
        variant="outlined"
        onClick={goBackToForm}
      >
        <Typography variant="subtitle2">← {t('createAnotherTicket')}</Typography>
      </Button>
    </>
  );
}
