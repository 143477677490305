import useStyles from 'layouts/entities/View.styles';
import { fieldTypes } from 'fieldSections/fieldTypes';
import useKeys from '@flowsn4ke/usekeys';
import Tabs from 'components/tabs/Tabs';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import FAIcon from 'components/ui/FAIcon';
import { useEntity } from 'contexts/entities/entityContext';
import { dateToLocalFormat } from 'utils/dates';
import { useTranslation } from 'react-i18next';
import classNames from 'utils/classNames';
import LinkifiedText from 'components/LinkifiedText';
import { isArray } from 'lodash-es';

export default function CustomFields({ children, element }) {
  const { customFields } = useEntity();

  const k = useKeys();
  const c = useStyles();

  const { t } = useTranslation();

  if (!element?.fields || !element.fields.length || !customFields) {
    return children;
  }

  const fieldIds = element.fields.map((f) => f._field);

  const tabs = customFields
    .filter((s, i) => {
      if (i === 0 && children) return true;

      for (let i = 0; i < s.fields.length; i++) if (fieldIds.includes(s.fields[i]._id)) return true;

      return false;
    })
    .map((section, i) => ({
      ...section,
      fields: section.fields
        .filter((f, i) => fieldIds.includes(f._id))
        .map((f, i) => ({
          ...f,
          label: t(f.label),
          value: element.fields.find((el) => el._field === f._id).value
        }))
    }))
    .map((section, i) => ({
      label: t(section.label),
      content: (
        <>
          {i === 0 && children}
          {section.fields.map((field, i) => {
            const type = field.valueType;

            const value =
              !!field.value && type.includes('date')
                ? dateToLocalFormat(
                    field.value,
                    ['datetime', 'date_begin', 'date_finish'].includes(type) ? 'PPPp' : 'PPP'
                  )
                : isArray(field.value)
                ? field.value.join(', ')
                : field.value;

            if (!value) return null;

            return (
              <ListItem
                dense
                key={k(i)}
                className={c.listItem}
              >
                <ListItemIcon>
                  <FAIcon
                    collection="fal"
                    icon={fieldTypes[field.valueType].icon}
                    className={classNames(c.listIcon, c.listIconSecondary)}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={<strong>{field.label}</strong>}
                  secondary={typeof value === 'string' ? <LinkifiedText children={value} /> : value}
                  style={{ whiteSpace: type === 'textarea' ? 'pre-line' : 'normal' }}
                />
              </ListItem>
            );
          })}
        </>
      )
    }));

  if (!tabs.length) return children;

  return (
    <Tabs
      tabs={tabs}
      style={{ width: '95%' }}
    />
  );
}
