const AccountingSorts = (configuration) => {
  return [
    { label: 'creationDateMostRecent', value: '_id', sort: -1, show: true },
    { label: 'creationDateLeastRecent', value: '_id', sort: 1, show: true },
    { label: 'updateDateMostRecent', value: 'updated_at', sort: -1, show: true },
    { label: 'updateDateLeastRecent', value: 'updated_at', sort: 1, show: true }
    // { label: 'Date ajout - Facture (plus récent)', value: 'views.factures._document.date', sort: 1, show: true },
    // { label: 'Date de création', value: 'sort_date', sort: 1, show: true },
    // { label: 'Durée dernier état', value: 'state_date', sort: -1, show: true },
    // { label: 'Date de visite', value: 'visit_date', sort: 1, show: true },
    // { label: 'Priorité', value: 'urgency', sort: 1, show: true }
  ].filter((e) => e.show);
};

export default AccountingSorts;
