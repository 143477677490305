import {
  getElement,
  getElements,
  createElement,
  updateElement,
  flushElements,
  deleteElement,
  restoreElement
} from 'store/guaranteesSlice';

import GuaranteePreview from './GuaranteePreview';
import GuaranteeSkeleton from './GuaranteeSkeleton';
import GuaranteeFormValues, {
  GuaranteeValidation,
  formatForm,
  injectFormValues
} from './GuaranteeValues';
import GuaranteeTranslations from './GuaranteeTranslations';
import GuaranteeForm from './GuaranteeForm';
import GuaranteeSorts from './GuaranteeSorts';

import EntityWrapper from 'entities/EntityWrapper';
import { useTranslation } from 'react-i18next';
import { NoteAdd } from '@material-ui/icons';

export default function Guarantee({ ...rest }) {
  const { t } = useTranslation();

  return (
    <EntityWrapper
      entity={'guarantees'}
      entityFieldsSlice="guarantee"
      updateOnClick
      skeletonComponent={GuaranteeSkeleton}
      previewComponent={GuaranteePreview}
      viewComponent={null}
      formComponent={GuaranteeForm}
      formAttachment
      formAttachmentIcon={<NoteAdd />}
      formAttachmentLabel={t('document')}
      formatFilter={(filters) => filters}
      formatForm={formatForm}
      getFilters={() => []}
      getInitialValues={GuaranteeFormValues}
      injectFormValues={injectFormValues}
      getFormValidation={GuaranteeValidation}
      getTabs={() => []}
      getTables={() => []}
      getMenus={() => []}
      getExportColumns={() => []}
      getTranslations={GuaranteeTranslations}
      getSorts={GuaranteeSorts}
      getElement={getElement}
      getElements={getElements}
      createElement={createElement}
      updateElement={updateElement}
      flushElements={flushElements}
      deleteElement={deleteElement}
      restoreElement={restoreElement}
      {...rest}
    />
  );
}
