import { Box, Button } from '@material-ui/core';
import FAIcon from 'components/ui/FAIcon';
import { JobsPopover } from 'entities/Ticket/View/TicketHeader';
import { Form } from 'frmx';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { updateElement } from 'store/ticketsSlice';
import useStyles from 'layouts/entities/View.styles';

const OrderJob = ({ isLoading, _id, _jobs }) => {
  const { dispatch } = useAsyncDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const [jobsPopover, setJobsPopover] = useState(false);

  return (
    <Box marginTop={'10px'}>
      <Button
        disabled={isLoading}
        onClick={(e) => setJobsPopover(e.currentTarget)}
        endIcon={
          <FAIcon
            collection={'fal'}
            icon={'toolbox'}
            className={classes.viewActionIcon}
          />
        }
        color={'secondary'}
        variant={'contained'}
        className={classes.viewAction}
      >
        {t('jobs')}
      </Button>
      <Form
        disableIfNoUpdates
        initialValues={{
          _jobs
        }}
        onSubmit={(form) => [
          dispatch(updateElement, { _jobs: form._jobs }, {}, { id: _id }),
          setJobsPopover(false)
        ]}
      >
        <JobsPopover
          jobsPopover={jobsPopover}
          setJobsPopover={setJobsPopover}
        />
      </Form>
    </Box>
  );
};

export default OrderJob;
