const ScopeTranslations = (configuration, { type }) =>
  type === 'sectors'
    ? {
        entityName: 'entityScoSecName',
        createDialog: 'entityScoSecCreateDialog',
        updateDialog: 'entityScoSecUpdateDialog',
        create: 'entityScoSecCreate',
        update: 'entityScoSecUpdate',
        noResultLabel: 'entityScoSecnoResultLabel',
        searchText: 'entityScoSecSearchText'
      }
    : type === 'ticketTva'
      ? {
          entityName: 'entityScoTikTvaName',
          createDialog: 'entityScoTikTvaCreateDialog',
          updateDialog: 'entityScoTikTvaUpdateDialog',
          create: 'entityScoTikTvaCreate',
          update: 'entityScoTikTvaUpdate',
          noResultLabel: 'entityScoTikTvanoResultLabel',
          searchText: 'entityScoTikTvaSearchText'
        }
      : type === 'locationCategory'
        ? {
            entityName: 'Type de lieu',
            createDialog: "Création d'une type de lieu",
            updateDialog: 'Modification d`un type de lieu',
            create: 'entityScoLocCatCreate',
            update: 'entityScoLocCatUpdate',
            noResultLabel: 'entityScoLocCatnoResultLabel',
            searchText: 'entityScoLocCatSearchText'
          }
        : type === 'clientAcquisition'
          ? {
              entityName: 'entityScoCliAcqName',
              createDialog: 'entityScoCliAcqCreateDialog',
              updateDialog: 'entityScoCliAcqUpdateDialog',
              create: 'entityScoCliAcqCreate',
              update: 'entityScoCliAcqUpdate',
              noResultLabel: 'entityScoCliAcqnoResultLabel',
              searchText: 'entityScoCliAcqSearchText'
            }
          : {
              entityName: 'entityScoValueName',
              createDialog: 'entityScoValueCreateDialog',
              updateDialog: 'entityScoValueUpdateDialog',
              create: 'entityScoValueCreate',
              update: 'entityScoValueUpdate',
              noResultLabel: 'entityScoValuenoResultLabel',
              searchText: 'entityScoValueSearchText'
            };

export default ScopeTranslations;
