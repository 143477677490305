import Client from 'entities/Client/Client';
import Equipment from 'entities/Equipment/Equipment';
import Location from 'entities/Location/Location';
import Scope from 'entities/Scope/Scope';

const MeterFilters = (configuration, { customFields, lockClients }, auth) =>
  [
    {
      label: 'client',
      key: 'clients',
      type: 'Entity',
      params: {
        entity: Client,
        entityProps: {
          authorisations: [],
          regime: [],
          section: {}
        }
      },
      show: configuration.isContractor || configuration.isMaster,
      disabled: false,
      default: [],
      lock: lockClients
    },
    {
      label: 'locations',
      key: 'locations',
      type: 'Entity',
      params: {
        entity: Location,
        entityProps: {
          type: 'locations',
          hiddenFilters: {
            section: {
              location: true,
              facturation: false
            }
          }
        }
      },
      show: !configuration.monolocation,
      hidden: !!auth.interface.isUser && !!auth.interface?._locations?.length,
      disabled: false,
      default: []
    },
    {
      label: 'equipmentsTitle',
      key: 'equipments',
      type: 'Entity',
      params: {
        entity: Equipment,
        entityProps: {}
      },
      show: true,
      disabled: false,
      default: []
    },
    {
      label: 'typeOfMeter',
      key: 'tags',
      type: 'Entity',
      params: {
        entity: Scope,
        entityProps: {
          type: 'tagMeter'
        }
      },
      show: true,
      disabled: false,
      default: []
    },
    {
      label: 'uses',
      key: 'uses',
      type: 'Entity',
      params: {
        entity: Scope,
        entityProps: {
          type: 'useMeter'
        }
      },
      show: true,
      disabled: false,
      default: []
    }
  ].filter((e) => e.show);

export const formatFilter = (filters, auth) => {
  // TODO: Add your custom filter formatting here
  return filters;
};
export default MeterFilters;
