const GuaranteeTranslations = (configuration, { type }) => ({
  pick: 'entityGuaranteePick',
  entityName: 'entityGuaranteeName',
  createDialog: 'entityGuaranteeCreateDialog',
  updateDialog: 'entityGuaranteeUpdateDialog',
  create: 'entityGuaranteeCreate',
  update: 'entityGuaranteeUpdate',
  noResultLabel: 'entityGuaranteenoResultLabel',
  searchText: 'entityGuaranteeSearchText'
});

export default GuaranteeTranslations;
