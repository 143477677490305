import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export default function CustomTitle({ customField }) {
  const { t } = useTranslation();
  return (
    <Box
      style={{
        width: '100%',
        textAlign: 'center',
        margin: '1em 0'
      }}
    >
      <Typography variant={`h${customField.extra.size}`}>{t(customField.label)}</Typography>
    </Box>
  );
}
