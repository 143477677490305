import Dialog from 'components/Dialog';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { get_last_reading } from 'store/metersSlice';
import { dateToLocalFormat } from 'utils/dates';
import { truncateText } from 'utils/uiUtils';
import AddReadingForm from '../Forms/AddReadingForm';

export default function AddReading({ isOpen, onClose, element }) {
  const { t } = useTranslation();
  const meter_id = element?._id;
  const meter_name = element?.name;
  const [last_reading, set_last_reading] = useState(null);

  const { dispatch, requestStatus } = useAsyncDispatch();

  // * get the last value reading from backend
  useEffect(() => {
    if (isOpen === false) return;

    dispatch(get_last_reading, {}, {}, { id: meter_id }).then(({ data }) => {
      set_last_reading(data.last_reading);
    });
  }, [isOpen]);

  const last_reading_value = Number(last_reading?.value);
  const last_reading_at = last_reading?.at ? dateToLocalFormat(last_reading.at) : null;

  return (
    <Dialog
      icon="pen"
      isOpen={isOpen}
      onClose={onClose}
      title={t('addAReading')}
      className="max-w-[600px]"
    >
      <div>
        <div className="mt-2 text-base font-semibold">
          {t('meterName')} : {truncateText(meter_name, 40)}
        </div>
        <div className="text-sm">
          {requestStatus === 'loading'
            ? t('loading')
            : requestStatus === 'error'
              ? t('errorOnLoadingLastReading')
              : last_reading
                ? t('lastReadingWas', { value: last_reading_value, at: last_reading_at })
                : t('noReadingsYet')}
        </div>
        <AddReadingForm
          meter_id={meter_id}
          onClose={onClose}
          last_reading={last_reading}
        />
      </div>
    </Dialog>
  );
}
