import { useState, useMemo } from 'react';
import { debounce } from 'lodash-es';

export default function useDebouncedState(initialValue, delay = 700) {
  const [state, setState] = useState(initialValue);
  const [debouncedState, setDebouncedState] = useState(initialValue);

  const debouncedSearch = useMemo(
    () =>
      debounce((value) => {
        if (debouncedState !== value) setDebouncedState(value);
      }, delay),
    [debouncedState]
  );

  const setStates = (value) => {
    setState(value);
    debouncedSearch(value);
  };

  return [state, setStates, debouncedState];
}
