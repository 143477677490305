import {
  Chip,
  Checkbox,
  Select,
  ListItemText,
  FormControl,
  MenuItem,
  InputLabel
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isArray } from 'lodash-es';
import { useField } from 'frmx';
import useKeys from '@flowsn4ke/usekeys';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    width: '100%'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3)
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

export default function CustomMultipleSelect({
  onChange,
  valueControlled,
  path,
  customField,
  required
}) {
  const { value, setValue, disabled } = useField(path);
  const { t } = useTranslation();

  const options = customField.extra?.options;

  const k1 = useKeys();
  const k2 = useKeys();
  const classes = useStyles();

  const val = isArray(valueControlled || value) ? valueControlled || value : [];

  return (
    <FormControl
      disabled={disabled}
      className={classes.formControl}
      variant="outlined"
    >
      <InputLabel style={{ backgroundColor: 'white' }}>
        {t(customField?.label) + (required ? ' *' : '')}
      </InputLabel>
      <Select
        value={val}
        onChange={(e) => (onChange ? onChange(e.target.value) : setValue(e.target.value))}
        multiple
        renderValue={() => (
          <div className={classes.chips}>
            {val.map((val, i) => (
              <Chip
                key={k2(i)}
                label={val}
                className={classes.chip}
              />
            ))}
          </div>
        )}
        MenuProps={MenuProps}
        required={required}
      >
        {(options || []).map((name, i) => (
          <MenuItem
            key={k1(i)}
            value={name}
          >
            <Checkbox checked={val?.indexOf(name) > -1} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
