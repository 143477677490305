const countries = [
  {
    name: 'Afghanistan',
    prefix: '+93',
    code: 'AF',
    flag: '🇦🇫',
    currency: 'AFN',
    currency_symbol: 'Af'
  },
  {
    name: 'Albania',
    prefix: '+355',
    code: 'AL',
    flag: '🇦🇱',
    currency: 'ALL',
    currency_symbol: 'L'
  },
  {
    name: 'Algeria',
    prefix: '+213',
    code: 'DZ',
    flag: '🇩🇿',
    currency: 'DZD',
    currency_symbol: 'د.ج'
  },
  {
    name: 'AmericanSamoa',
    prefix: '+1684',
    code: 'AS',
    flag: '🇦🇸',
    currency: 'USD',
    currency_symbol: '$'
  },
  {
    name: 'Andorra',
    prefix: '+376',
    code: 'AD',
    flag: '🇦🇩',
    currency: 'EUR',
    currency_symbol: '€'
  },
  {
    name: 'Angola',
    prefix: '+244',
    code: 'AO',
    flag: '🇦🇴',
    currency: 'AOA',
    currency_symbol: 'Kz'
  },
  {
    name: 'Anguilla',
    prefix: '+1264',
    code: 'AI',
    flag: '🇦🇮',
    currency: 'XCD',
    currency_symbol: '$'
  },
  {
    name: 'Antigua and Barbuda',
    prefix: '+1268',
    code: 'AG',
    flag: '🇦🇬',
    currency: 'XCD',
    currency_symbol: '$'
  },
  {
    name: 'Argentina',
    prefix: '+54',
    code: 'AR',
    flag: '🇦🇷',
    currency: 'ARS',
    currency_symbol: '$'
  },
  {
    name: 'Armenia',
    prefix: '+374',
    code: 'AM',
    flag: '🇦🇲',
    currency: 'AMD',
    currency_symbol: 'Դ'
  },
  { name: 'Aruba', prefix: '+297', code: 'AW', flag: '🇦🇼', currency: 'AWG', currency_symbol: 'ƒ' },
  {
    name: 'Australia',
    prefix: '+61',
    code: 'AU',
    flag: '🇦🇺',
    currency: 'AUD',
    currency_symbol: '$'
  },
  { name: 'Austria', prefix: '+43', code: 'AT', flag: '🇦🇹', currency: 'EUR', currency_symbol: '€' },
  {
    name: 'Azerbaijan',
    prefix: '+994',
    code: 'AZ',
    flag: '🇦🇿',
    currency: 'AZN',
    currency_symbol: 'ман'
  },
  {
    name: 'Bahamas',
    prefix: '+1242',
    code: 'BS',
    flag: '🇧🇸',
    currency: 'BSD',
    currency_symbol: '$'
  },
  {
    name: 'Bahrain',
    prefix: '+973',
    code: 'BH',
    flag: '🇧🇭',
    currency: 'BHD',
    currency_symbol: 'ب.د'
  },
  {
    name: 'Bangladesh',
    prefix: '+880',
    code: 'BD',
    flag: '🇧🇩',
    currency: 'BDT',
    currency_symbol: '৳'
  },
  {
    name: 'Barbados',
    prefix: '+1246',
    code: 'BB',
    flag: '🇧🇧',
    currency: 'BBD',
    currency_symbol: '$'
  },
  {
    name: 'Belarus',
    prefix: '+375',
    code: 'BY',
    flag: '🇧🇾',
    currency: 'BYN',
    currency_symbol: 'Br'
  },
  { name: 'Belgium', prefix: '+32', code: 'BE', flag: '🇧🇪', currency: 'EUR', currency_symbol: '€' },
  { name: 'Belize', prefix: '+501', code: 'BZ', flag: '🇧🇿', currency: 'BZD', currency_symbol: '$' },
  { name: 'Benin', prefix: '+229', code: 'BJ', flag: '🇧🇯', currency: 'XAF', currency_symbol: '₣' },
  {
    name: 'Bermuda',
    prefix: '+1441',
    code: 'BM',
    flag: '🇧🇲',
    currency: 'BMD',
    currency_symbol: '$'
  },
  {
    name: 'Bhutan',
    prefix: '+975',
    code: 'BT',
    flag: '🇧🇹',
    currency: 'BTN',
    currency_symbol: 'Nu'
  },
  {
    name: 'Plurinational State of Bolivia',
    prefix: '+591',
    code: 'BO',
    flag: '🇧🇴',
    currency: 'BOB',
    currency_symbol: 'Bs'
  },
  {
    name: 'Bosnia and Herzegovina',
    prefix: '+387',
    code: 'BA',
    flag: '🇧🇦',
    currency: 'BAM',
    currency_symbol: 'КМ'
  },
  {
    name: 'Botswana',
    prefix: '+267',
    code: 'BW',
    flag: '🇧🇼',
    currency: 'BWP',
    currency_symbol: 'P'
  },
  { name: 'Brazil', prefix: '+55', code: 'BR', flag: '🇧🇷', currency: 'BRL', currency_symbol: 'R$' },
  {
    name: 'British Indian Ocean Territory',
    prefix: '+246',
    code: 'IO',
    flag: '🇮🇴',
    currency: 'GBP',
    currency_symbol: '£'
  },
  {
    name: 'Brunei Darussalam',
    prefix: '+673',
    code: 'BN',
    flag: '🇧🇳',
    currency: 'BND',
    currency_symbol: '$'
  },
  {
    name: 'Bulgaria',
    prefix: '+359',
    code: 'BG',
    flag: '🇧🇬',
    currency: 'BGN',
    currency_symbol: 'лв'
  },
  {
    name: 'Burkina Faso',
    prefix: '+226',
    code: 'BF',
    flag: '🇧🇫',
    currency: 'XAF',
    currency_symbol: '₣'
  },
  {
    name: 'Burundi',
    prefix: '+257',
    code: 'BI',
    flag: '🇧🇮',
    currency: 'BIF',
    currency_symbol: '₣'
  },
  {
    name: 'Cambodia',
    prefix: '+855',
    code: 'KH',
    flag: '🇰🇭',
    currency: 'KHR',
    currency_symbol: '៛'
  },
  {
    name: 'Cameroon',
    prefix: '+237',
    code: 'CM',
    flag: '🇨🇲',
    currency: 'XAF',
    currency_symbol: '₣'
  },
  { name: 'Canada', prefix: '+1', code: 'CA', flag: '🇨🇦', currency: 'CAD', currency_symbol: '$' },
  {
    name: 'Cape Verde',
    prefix: '+238',
    code: 'CV',
    flag: '🇨🇻',
    currency: 'CVE',
    currency_symbol: '$'
  },
  {
    name: 'Cayman Islands',
    prefix: '+345',
    code: 'KY',
    flag: '🇰🇾',
    currency: 'KYD',
    currency_symbol: '$'
  },
  {
    name: 'Central African Republic',
    prefix: '+236',
    code: 'CF',
    flag: '🇨🇫',
    currency: 'XAF',
    currency_symbol: '₣'
  },
  { name: 'Chad', prefix: '+235', code: 'TD', flag: '🇹🇩', currency: 'XAF', currency_symbol: '₣' },
  { name: 'Chile', prefix: '+56', code: 'CL', flag: '🇨🇱', currency: 'CLP', currency_symbol: '$' },
  { name: 'China', prefix: '+86', code: 'CN', flag: '🇨🇳', currency: 'CNY', currency_symbol: '¥' },
  {
    name: 'Christmas Island',
    prefix: '+61',
    code: 'CX',
    flag: '🇨🇽',
    currency: 'AUD',
    currency_symbol: '$'
  },
  {
    name: 'Cocos (Keeling) Islands',
    prefix: '+61',
    code: 'CC',
    flag: '🇨🇨',
    currency: 'AUD',
    currency_symbol: '$'
  },
  {
    name: 'Colombia',
    prefix: '+57',
    code: 'CO',
    flag: '🇨🇴',
    currency: 'COP',
    currency_symbol: '$'
  },
  {
    name: 'Comoros',
    prefix: '+269',
    code: 'KM',
    flag: '🇰🇲',
    currency: 'KMF',
    currency_symbol: 'CF'
  },
  { name: 'Congo', prefix: '+242', code: 'CG', flag: '🇨🇬', currency: 'CDF', currency_symbol: '₣' },
  {
    name: 'Democratic Republic of the Congo',
    prefix: '+243',
    code: 'CD',
    flag: '🇨🇩',
    currency: 'CDF',
    currency_symbol: 'FC'
  },
  {
    name: 'Cook Islands',
    prefix: '+682',
    code: 'CK',
    flag: '🇨🇰',
    currency: 'NZD',
    currency_symbol: '$'
  },
  {
    name: 'Costa Rica',
    prefix: '+506',
    code: 'CR',
    flag: '🇨🇷',
    currency: 'CRC',
    currency_symbol: '₡'
  },
  {
    name: "Cote d'Ivoire",
    prefix: '+225',
    code: 'CI',
    flag: '🇨🇮',
    currency: 'XOF',
    currency_symbol: 'CFA'
  },
  {
    name: 'Croatia',
    prefix: '+385',
    code: 'HR',
    flag: '🇭🇷',
    currency: 'HRK',
    currency_symbol: 'Kn'
  },
  { name: 'Cuba', prefix: '+53', code: 'CU', flag: '🇨🇺', currency: 'CUP', currency_symbol: '$' },
  { name: 'Cyprus', prefix: '+537', code: 'CY', flag: '🇨🇾', currency: 'EUR', currency_symbol: '€' },
  {
    name: 'Czech Republic',
    prefix: '+420',
    code: 'CZ',
    flag: '🇨🇿',
    currency: 'CZK',
    currency_symbol: 'Kč'
  },
  {
    name: 'Denmark',
    prefix: '+45',
    code: 'DK',
    flag: '🇩🇰',
    currency: 'DKK',
    currency_symbol: 'kr'
  },
  {
    name: 'Djibouti',
    prefix: '+253',
    code: 'DJ',
    flag: '🇩🇯',
    currency: 'DJF',
    currency_symbol: '₣'
  },
  {
    name: 'Dominica',
    prefix: '+1767',
    code: 'DM',
    flag: '🇩🇲',
    currency: 'DOP',
    currency_symbol: '$'
  },
  {
    name: 'Dominican Republic',
    prefix: '+1849',
    code: 'DO',
    flag: '🇩🇴',
    currency: 'DOP',
    currency_symbol: '$'
  },
  {
    name: 'Ecuador',
    prefix: '+593',
    code: 'EC',
    flag: '🇪🇨',
    currency: 'USD',
    currency_symbol: '$'
  },
  { name: 'Egypt', prefix: '+20', code: 'EG', flag: '🇪🇬', currency: 'EGP', currency_symbol: '£' },
  {
    name: 'El Salvador',
    prefix: '+503',
    code: 'SV',
    flag: '🇸🇻',
    currency: 'USD',
    currency_symbol: '$'
  },
  {
    name: 'Equatorial Guinea',
    prefix: '+240',
    code: 'GQ',
    flag: '🇬🇶',
    currency: 'XAF',
    currency_symbol: '₣'
  },
  {
    name: 'Eritrea',
    prefix: '+291',
    code: 'ER',
    flag: '🇪🇷',
    currency: 'ERN',
    currency_symbol: 'Nfk'
  },
  {
    name: 'Estonia',
    prefix: '+372',
    code: 'EE',
    flag: '🇪🇪',
    currency: 'EUR',
    currency_symbol: '€'
  },
  {
    name: 'Ethiopia',
    prefix: '+251',
    code: 'ET',
    flag: '🇪🇹',
    currency: 'ETB',
    currency_symbol: 'ብር'
  },
  {
    name: 'Falkland Islands (Malvinas)',
    prefix: '+500',
    code: 'FK',
    flag: '🇫🇰',
    currency: 'FKP',
    currency_symbol: '£'
  },
  {
    name: 'Faroe Islands',
    prefix: '+298',
    code: 'FO',
    flag: '🇫🇴',
    currency: 'FOK',
    currency_symbol: 'kr'
  },
  { name: 'Fiji', prefix: '+679', code: 'FJ', flag: '🇫🇯', currency: 'FJD', currency_symbol: '$' },
  {
    name: 'Finland',
    prefix: '+358',
    code: 'FI',
    flag: '🇫🇮',
    currency: 'EUR',
    currency_symbol: '€'
  },
  { name: 'France', prefix: '+33', code: 'FR', flag: '🇫🇷', currency: 'EUR', currency_symbol: '€' },
  {
    name: 'French Guiana',
    prefix: '+594',
    code: 'GF',
    flag: '🇬🇫',
    currency: 'EUR',
    currency_symbol: '€'
  },
  {
    name: 'French Polynesia',
    prefix: '+689',
    code: 'PF',
    flag: '🇵🇫',
    currency: 'XPF',
    currency_symbol: '₣'
  },
  { name: 'Gabon', prefix: '+241', code: 'GA', flag: '🇬🇦', currency: 'XAF', currency_symbol: '₣' },
  { name: 'Gambia', prefix: '+220', code: 'GM', flag: '🇬🇲', currency: 'GMD', currency_symbol: 'D' },
  {
    name: 'Georgia',
    prefix: '+995',
    code: 'GE',
    flag: '🇬🇪',
    currency: 'GEL',
    currency_symbol: 'ლ'
  },
  { name: 'Germany', prefix: '+49', code: 'DE', flag: '🇩🇪', currency: 'EUR', currency_symbol: '€' },
  { name: 'Ghana', prefix: '+233', code: 'GH', flag: '🇬🇭', currency: 'GHS', currency_symbol: '₵' },
  {
    name: 'Gibraltar',
    prefix: '+350',
    code: 'GI',
    flag: '🇬🇮',
    currency: 'GIP',
    currency_symbol: '£'
  },
  { name: 'Greece', prefix: '+30', code: 'GR', flag: '🇬🇷', currency: 'EUR', currency_symbol: '€' },
  {
    name: 'Greenland',
    prefix: '+299',
    code: 'GL',
    flag: '🇬🇱',
    currency: 'DKK',
    currency_symbol: 'kr.'
  },
  {
    name: 'Grenada',
    prefix: '+1473',
    code: 'GD',
    flag: '🇬🇩',
    currency: 'XCD',
    currency_symbol: '$'
  },
  {
    name: 'Guadeloupe',
    prefix: '+590',
    code: 'GP',
    flag: '🇬🇵',
    currency: 'EUR',
    currency_symbol: '€'
  },
  { name: 'Guam', prefix: '+1671', code: 'GU', flag: '🇬🇺', currency: 'USD', currency_symbol: '$' },
  {
    name: 'Guatemala',
    prefix: '+502',
    code: 'GT',
    flag: '🇬🇹',
    currency: 'GTQ',
    currency_symbol: 'Q'
  },
  {
    name: 'Guernsey',
    prefix: '+44',
    code: 'GG',
    flag: '🇬🇬',
    currency: 'GBP',
    currency_symbol: '£'
  },
  { name: 'Guinea', prefix: '+224', code: 'GN', flag: '🇬🇳', currency: 'GNF', currency_symbol: '₣' },
  {
    name: 'Guinea-Bissau',
    prefix: '+245',
    code: 'GW',
    flag: '🇬🇼',
    currency: 'XAF',
    currency_symbol: '₣'
  },
  { name: 'Guyana', prefix: '+595', code: 'GY', flag: '🇬🇾', currency: 'GYD', currency_symbol: '$' },
  { name: 'Haiti', prefix: '+509', code: 'HT', flag: '🇭🇹', currency: 'HTG', currency_symbol: 'G' },
  {
    name: 'Holy See (Vatican City State)',
    prefix: '+379',
    code: 'VA',
    flag: '🇻🇦',
    currency: 'EUR',
    currency_symbol: '€'
  },
  {
    name: 'Honduras',
    prefix: '+504',
    code: 'HN',
    flag: '🇭🇳',
    currency: 'HNL',
    currency_symbol: 'L'
  },
  {
    name: 'Hong Kong',
    prefix: '+852',
    code: 'HK',
    flag: '🇭🇰',
    currency: 'HKD',
    currency_symbol: '$'
  },
  {
    name: 'Hungary',
    prefix: '+36',
    code: 'HU',
    flag: '🇭🇺',
    currency: 'HUF',
    currency_symbol: 'Ft'
  },
  {
    name: 'Iceland',
    prefix: '+354',
    code: 'IS',
    flag: '🇮🇸',
    currency: 'ISK',
    currency_symbol: 'Kr'
  },
  { name: 'India', prefix: '+91', code: 'IN', flag: '🇮🇳', currency: 'INR', currency_symbol: '₹  ' },
  {
    name: 'Indonesia',
    prefix: '+62',
    code: 'ID',
    flag: '🇮🇩',
    currency: 'IDR',
    currency_symbol: 'Rp'
  },
  { name: 'Iran', prefix: '+98', code: 'IR', flag: '🇮🇷', currency: 'IRR', currency_symbol: '﷼' },
  { name: 'Iraq', prefix: '+964', code: 'IQ', flag: '🇮🇶', currency: 'IQD', currency_symbol: 'ع.د' },
  {
    name: 'Ireland',
    prefix: '+353',
    code: 'IE',
    flag: '🇮🇪',
    currency: 'EUR',
    currency_symbol: '€'
  },
  {
    name: 'Isle of Man',
    prefix: '+44',
    code: 'IM',
    flag: '🇮🇲',
    currency: 'IMP',
    currency_symbol: '£'
  },
  { name: 'Israel', prefix: '+972', code: 'IL', flag: '🇮🇱', currency: 'ILS', currency_symbol: '₪' },
  { name: 'Italy', prefix: '+39', code: 'IT', flag: '🇮🇹', currency: 'EUR', currency_symbol: '€' },
  {
    name: 'Jamaica',
    prefix: '+1876',
    code: 'JM',
    flag: '🇯🇲',
    currency: 'JMD',
    currency_symbol: '$'
  },
  { name: 'Japan', prefix: '+81', code: 'JP', flag: '🇯🇵', currency: 'JPY', currency_symbol: '¥' },
  { name: 'Jersey', prefix: '+44', code: 'JE', flag: '🇯🇪', currency: 'JEP', currency_symbol: '£' },
  {
    name: 'Jordan',
    prefix: '+962',
    code: 'JO',
    flag: '🇯🇴',
    currency: 'JOD',
    currency_symbol: 'د.ا'
  },
  {
    name: 'Kazakhstan',
    prefix: '+77',
    code: 'KZ',
    flag: '🇰🇿',
    currency: 'KZT',
    currency_symbol: '〒'
  },
  { name: 'Kenya', prefix: '+254', code: 'KE', flag: '🇰🇪', currency: 'KES', currency_symbol: 'Sh' },
  {
    name: 'Kiribati',
    prefix: '+686',
    code: 'KI',
    flag: '🇰🇮',
    currency: 'AUD',
    currency_symbol: '$'
  },
  {
    name: "Democratic People's Republic of Korea",
    prefix: '+850',
    code: 'KP',
    flag: '🇰🇵',
    currency: 'KPW',
    currency_symbol: '₩'
  },
  {
    name: 'Republic of Korea',
    prefix: '+82',
    code: 'KR',
    flag: '🇰🇷',
    currency: 'KRW',
    currency_symbol: '₩'
  },
  {
    name: 'Kuwait',
    prefix: '+965',
    code: 'KW',
    flag: '🇰🇼',
    currency: 'KWD',
    currency_symbol: 'د.ك'
  },
  {
    name: 'Kyrgyzstan',
    prefix: '+996',
    code: 'KG',
    flag: '🇰🇬',
    currency: 'KGS',
    currency_symbol: 'Лв'
  },
  {
    name: "Lao People's Democratic Republic",
    prefix: '+856',
    code: 'LA',
    flag: '🇱🇦',
    currency: 'LAK',
    currency_symbol: '₭'
  },
  { name: 'Latvia', prefix: '+371', code: 'LV', flag: '🇱🇻', currency: 'EUR', currency_symbol: '€' },
  {
    name: 'Lebanon',
    prefix: '+961',
    code: 'LB',
    flag: '🇱🇧',
    currency: 'LBP',
    currency_symbol: 'ل.ل'
  },
  {
    name: 'Lesotho',
    prefix: '+266',
    code: 'LS',
    flag: '🇱🇸',
    currency: 'LSL',
    currency_symbol: 'L'
  },
  {
    name: 'Liberia',
    prefix: '+231',
    code: 'LR',
    flag: '🇱🇷',
    currency: 'LRD',
    currency_symbol: '$'
  },
  {
    name: 'Libyan Arab Jamahiriya',
    prefix: '+218',
    code: 'LY',
    flag: '🇱🇾',
    currency: 'LYD',
    currency_symbol: 'ل.د'
  },
  {
    name: 'Liechtenstein',
    prefix: '+423',
    code: 'LI',
    flag: '🇱🇮',
    currency: 'CHF',
    currency_symbol: 'Fr'
  },
  {
    name: 'Lithuania',
    prefix: '+370',
    code: 'LT',
    flag: '🇱🇹',
    currency: 'EUR',
    currency_symbol: '€'
  },
  {
    name: 'Luxembourg',
    prefix: '+352',
    code: 'LU',
    flag: '🇱🇺',
    currency: 'EUR',
    currency_symbol: '€'
  },
  { name: 'Macao', prefix: '+853', code: 'MO', flag: '🇲🇴', currency: 'MOP', currency_symbol: 'P' },
  {
    name: 'Republic of North Macedonia',
    prefix: '+389',
    code: 'MK',
    flag: '🇲🇰',
    currency: 'MKD',
    currency_symbol: 'ден'
  },
  {
    name: 'Madagascar',
    prefix: '+261',
    code: 'MG',
    flag: '🇲🇬',
    currency: 'MGA',
    currency_symbol: 'Ar'
  },
  {
    name: 'Malawi',
    prefix: '+265',
    code: 'MW',
    flag: '🇲🇼',
    currency: 'MWK',
    currency_symbol: 'MK'
  },
  {
    name: 'Malaysia',
    prefix: '+60',
    code: 'MY',
    flag: '🇲🇾',
    currency: 'MYR',
    currency_symbol: 'RM'
  },
  {
    name: 'Maldives',
    prefix: '+960',
    code: 'MV',
    flag: '🇲🇻',
    currency: 'MVR',
    currency_symbol: 'ރ.'
  },
  { name: 'Mali', prefix: '+223', code: 'ML', flag: '🇲🇱', currency: 'SOS', currency_symbol: 'Sh' },
  { name: 'Malta', prefix: '+356', code: 'MT', flag: '🇲🇹', currency: 'EUR', currency_symbol: '€' },
  {
    name: 'Marshall Islands',
    prefix: '+692',
    code: 'MH',
    flag: '🇲🇭',
    currency: 'USD',
    currency_symbol: '$'
  },
  {
    name: 'Martinique',
    prefix: '+596',
    code: 'MQ',
    flag: '🇲🇶',
    currency: 'EUR',
    currency_symbol: '€'
  },
  {
    name: 'Mauritania',
    prefix: '+222',
    code: 'MR',
    flag: '🇲🇷',
    currency: 'MRU',
    currency_symbol: 'UM'
  },
  {
    name: 'Mauritius',
    prefix: '+230',
    code: 'MU',
    flag: '🇲🇺',
    currency: 'MUR',
    currency_symbol: '₨'
  },
  {
    name: 'Mayotte',
    prefix: '+262',
    code: 'YT',
    flag: '🇾🇹',
    currency: 'EUR',
    currency_symbol: '€'
  },
  { name: 'Mexico', prefix: '+52', code: 'MX', flag: '🇲🇽', currency: 'MXN', currency_symbol: '$' },
  {
    name: 'Federated States of Micronesia',
    prefix: '+691',
    code: 'FM',
    flag: '🇫🇲',
    currency: 'USD',
    currency_symbol: '$'
  },
  {
    name: 'Republic of Moldova ',
    prefix: '+373',
    code: 'MD',
    flag: '🇲🇩',
    currency: 'MDL',
    currency_symbol: 'L'
  },
  { name: 'Monaco', prefix: '+377', code: 'MC', flag: '🇲🇨', currency: 'EUR', currency_symbol: '€' },
  {
    name: 'Mongolia',
    prefix: '+976',
    code: 'MN',
    flag: '🇲🇳',
    currency: 'MNT',
    currency_symbol: '₮'
  },
  {
    name: 'Montenegro',
    prefix: '+382',
    code: 'ME',
    flag: '🇲🇪',
    currency: 'EUR',
    currency_symbol: '€'
  },
  {
    name: 'Montserrat',
    prefix: '+1664',
    code: 'MS',
    flag: '🇲🇸',
    currency: 'XCD',
    currency_symbol: '$'
  },
  {
    name: 'Morocco',
    prefix: '+212',
    code: 'MA',
    flag: '🇲🇦',
    currency: 'MAD',
    currency_symbol: 'د.م.'
  },
  {
    name: 'Mozambique',
    prefix: '+258',
    code: 'MZ',
    flag: '🇲🇿',
    currency: 'MZN',
    currency_symbol: 'MTn'
  },
  { name: 'Myanmar', prefix: '+95', code: 'MM', flag: '🇲🇲', currency: 'MMK', currency_symbol: 'K' },
  {
    name: 'Namibia',
    prefix: '+264',
    code: 'NA',
    flag: '🇳🇦',
    currency: 'NAD',
    currency_symbol: '$'
  },
  { name: 'Nauru', prefix: '+674', code: 'NR', flag: '🇳🇷', currency: 'AUD', currency_symbol: '$' },
  { name: 'Nepal', prefix: '+977', code: 'NP', flag: '🇳🇵', currency: 'NPR', currency_symbol: '₨' },
  {
    name: 'Netherlands',
    prefix: '+31',
    code: 'NL',
    flag: '🇳🇱',
    currency: 'EUR',
    currency_symbol: '€'
  },
  {
    name: 'Netherlands Antilles',
    prefix: '+599',
    code: 'AN',
    flag: '🇦🇳',
    currency: 'ANG',
    currency_symbol: 'ƒ'
  },
  {
    name: 'New Caledonia',
    prefix: '+687',
    code: 'NC',
    flag: '🇳🇨',
    currency: 'XPF',
    currency_symbol: '₣'
  },
  {
    name: 'New Zealand',
    prefix: '+64',
    code: 'NZ',
    flag: '🇳🇿',
    currency: 'NZD',
    currency_symbol: '$'
  },
  {
    name: 'Nicaragua',
    prefix: '+505',
    code: 'NI',
    flag: '🇳🇮',
    currency: 'NIO',
    currency_symbol: 'C$'
  },
  { name: 'Niger', prefix: '+227', code: 'NE', flag: '🇳🇪', currency: 'NGN', currency_symbol: '₦' },
  {
    name: 'Nigeria',
    prefix: '+234',
    code: 'NG',
    flag: '🇳🇬',
    currency: 'NGN',
    currency_symbol: '₦'
  },
  { name: 'Niue', prefix: '+683', code: 'NU', flag: '🇳🇺', currency: 'NZD', currency_symbol: '$' },
  {
    name: 'Norfolk Island',
    prefix: '+672',
    code: 'NF',
    flag: '🇳🇫',
    currency: 'AUD',
    currency_symbol: '$'
  },
  {
    name: 'Northern Mariana Islands',
    prefix: '+1670',
    code: 'MP',
    flag: '🇲🇵',
    currency: 'USD',
    currency_symbol: '$'
  },
  { name: 'Norway', prefix: '+47', code: 'NO', flag: '🇳🇴', currency: 'NOK', currency_symbol: 'kr' },
  {
    name: 'Oman',
    prefix: '+968',
    code: 'OM',
    flag: '🇴🇲',
    currency: 'OMR',
    currency_symbol: 'ر.ع.'
  },
  {
    name: 'Pakistan',
    prefix: '+92',
    code: 'PK',
    flag: '🇵🇰',
    currency: 'PKR',
    currency_symbol: '₨'
  },
  { name: 'Palau', prefix: '+680', code: 'PW', flag: '🇵🇼', currency: 'USD', currency_symbol: '$' },
  {
    name: 'Palestine',
    prefix: '+970',
    code: 'PS',
    flag: '🇵🇸',
    currency: 'ILS',
    currency_symbol: '₪'
  },
  {
    name: 'Panama',
    prefix: '+507',
    code: 'PA',
    flag: '🇵🇦',
    currency: 'PAB',
    currency_symbol: 'B/.'
  },
  {
    name: 'Papua New Guinea',
    prefix: '+675',
    code: 'PG',
    flag: '🇵🇬',
    currency: 'PGK',
    currency_symbol: 'K'
  },
  {
    name: 'Paraguay',
    prefix: '+595',
    code: 'PY',
    flag: '🇵🇾',
    currency: 'PYG',
    currency_symbol: '₲'
  },
  { name: 'Peru', prefix: '+51', code: 'PE', flag: '🇵🇪', currency: 'PEN', currency_symbol: 'S/.' },
  {
    name: 'Philippines',
    prefix: '+63',
    code: 'PH',
    flag: '🇵🇭',
    currency: 'PHP',
    currency_symbol: '₱'
  },
  {
    name: 'Pitcairn',
    prefix: '+872',
    code: 'PN',
    flag: '🇵🇳',
    currency: 'NZD',
    currency_symbol: '$'
  },
  { name: 'Poland', prefix: '+48', code: 'PL', flag: '🇵🇱', currency: 'PLN', currency_symbol: 'zł' },
  {
    name: 'Portugal',
    prefix: '+351',
    code: 'PT',
    flag: '🇵🇹',
    currency: 'EUR',
    currency_symbol: '€'
  },
  {
    name: 'Puerto Rico',
    prefix: '+1939',
    code: 'PR',
    flag: '🇵🇷',
    currency: 'USD',
    currency_symbol: '$'
  },
  {
    name: 'Qatar',
    prefix: '+974',
    code: 'QA',
    flag: '🇶🇦',
    currency: 'QAR',
    currency_symbol: 'ر.ق'
  },
  { name: 'Romania', prefix: '+40', code: 'RO', flag: '🇷🇴', currency: 'RON', currency_symbol: 'L' },
  { name: 'Russia', prefix: '+7', code: 'RU', flag: '🇷🇺', currency: 'RUB', currency_symbol: 'р.' },
  { name: 'Rwanda', prefix: '+250', code: 'RW', flag: '🇷🇼', currency: 'RWF', currency_symbol: '₣' },
  {
    name: 'Réunion',
    prefix: '+262',
    code: 'RE',
    flag: '🇷🇪',
    currency: 'EUR',
    currency_symbol: '€'
  },
  {
    name: 'Saint Barthélemy',
    prefix: '+590',
    code: 'BL',
    flag: '🇧🇱',
    currency: 'EUR',
    currency_symbol: '€'
  },
  {
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
    prefix: '+290',
    code: 'SH',
    flag: '🇸🇭',
    currency: 'SHP',
    currency_symbol: '£'
  },
  {
    name: 'Saint Kitts and Nevis',
    prefix: '+1869',
    code: 'KN',
    flag: '🇰🇳',
    currency: 'XCD',
    currency_symbol: '$'
  },
  {
    name: 'Saint Lucia',
    prefix: '+1758',
    code: 'LC',
    flag: '🇱🇨',
    currency: 'XCD',
    currency_symbol: '$'
  },
  {
    name: 'Saint Martin',
    prefix: '+590',
    code: 'MF',
    flag: '🇲🇫',
    currency: 'ANG',
    currency_symbol: 'ƒ'
  },
  {
    name: 'Saint Pierre and Miquelon',
    prefix: '+508',
    code: 'PM',
    flag: '🇵🇲',
    currency: 'EUR',
    currency_symbol: '€'
  },
  {
    name: 'Saint Vincent and the Grenadines',
    prefix: '+1784',
    code: 'VC',
    flag: '🇻🇨',
    currency: 'XCD',
    currency_symbol: '$'
  },
  { name: 'Samoa', prefix: '+685', code: 'WS', flag: '🇼🇸', currency: 'USD', currency_symbol: '$' },
  {
    name: 'San Marino',
    prefix: '+378',
    code: 'SM',
    flag: '🇸🇲',
    currency: 'EUR',
    currency_symbol: '€'
  },
  {
    name: 'Sao Tome and Principe',
    prefix: '+239',
    code: 'ST',
    flag: '🇸🇹',
    currency: 'STN',
    currency_symbol: 'Db'
  },
  {
    name: 'Saudi Arabia',
    prefix: '+966',
    code: 'SA',
    flag: '🇸🇦',
    currency: 'SAR',
    currency_symbol: 'ر.س'
  },
  {
    name: 'Senegal',
    prefix: '+221',
    code: 'SN',
    flag: '🇸🇳',
    currency: 'XAF',
    currency_symbol: '₣'
  },
  {
    name: 'Serbia',
    prefix: '+381',
    code: 'RS',
    flag: '🇷🇸',
    currency: 'RSD',
    currency_symbol: 'din'
  },
  {
    name: 'Seychelles',
    prefix: '+248',
    code: 'SC',
    flag: '🇸🇨',
    currency: 'SCR',
    currency_symbol: '₨'
  },
  {
    name: 'Sierra Leone',
    prefix: '+232',
    code: 'SL',
    flag: '🇸🇱',
    currency: 'SLL',
    currency_symbol: 'Le'
  },
  {
    name: 'Singapore',
    prefix: '+65',
    code: 'SG',
    flag: '🇸🇬',
    currency: 'BND',
    currency_symbol: '$'
  },
  {
    name: 'Slovakia',
    prefix: '+421',
    code: 'SK',
    flag: '🇸🇰',
    currency: 'EUR',
    currency_symbol: '€'
  },
  {
    name: 'Slovenia',
    prefix: '+386',
    code: 'SI',
    flag: '🇸🇮',
    currency: 'EUR',
    currency_symbol: '€'
  },
  {
    name: 'Solomon Islands',
    prefix: '+677',
    code: 'SB',
    flag: '🇸🇧',
    currency: 'SBD',
    currency_symbol: '$'
  },
  {
    name: 'Somalia',
    prefix: '+252',
    code: 'SO',
    flag: '🇸🇴',
    currency: 'SOS',
    currency_symbol: 'Sh'
  },
  {
    name: 'South Africa',
    prefix: '+27',
    code: 'ZA',
    flag: '🇿🇦',
    currency: 'ZAR',
    currency_symbol: 'R'
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    prefix: '+500',
    code: 'GS',
    flag: '🇬🇸',
    currency: 'GBP',
    currency_symbol: '£'
  },
  { name: 'Spain', prefix: '+34', code: 'ES', flag: '🇪🇸', currency: 'EUR', currency_symbol: '€' },
  {
    name: 'Sri Lanka',
    prefix: '+94',
    code: 'LK',
    flag: '🇱🇰',
    currency: 'LKR',
    currency_symbol: 'Rs'
  },
  { name: 'Sudan', prefix: '+249', code: 'SD', flag: '🇸🇩', currency: 'SDG', currency_symbol: '£' },
  {
    name: 'Suriname',
    prefix: '+597',
    code: 'SR',
    flag: '🇸🇷',
    currency: 'SRD',
    currency_symbol: '$'
  },
  {
    name: 'Svalbard and Jan Mayen',
    prefix: '+47',
    code: 'SJ',
    flag: '🇸🇯',
    currency: 'NOK',
    currency_symbol: 'kr'
  },
  {
    name: 'Swaziland',
    prefix: '+268',
    code: 'SZ',
    flag: '🇸🇿',
    currency: 'SZL',
    currency_symbol: 'L'
  },
  { name: 'Sweden', prefix: '+46', code: 'SE', flag: '🇸🇪', currency: 'SEK', currency_symbol: 'kr' },
  {
    name: 'Switzerland',
    prefix: '+41',
    code: 'CH',
    flag: '🇨🇭',
    currency: 'CHF',
    currency_symbol: '₣'
  },
  {
    name: 'Arab Republic of Syria',
    prefix: '+963',
    code: 'SY',
    flag: '🇸🇾',
    currency: 'SYP',
    currency_symbol: '£S'
  },
  {
    name: 'Taiwan',
    prefix: '+886',
    code: 'TW',
    flag: '🇹🇼',
    currency: 'TWD',
    currency_symbol: ' 圓'
  },
  {
    name: 'Tajikistan',
    prefix: '+992',
    code: 'TJ',
    flag: '🇹🇯',
    currency: 'TJS',
    currency_symbol: 'ЅМ'
  },
  {
    name: 'Tanzania',
    prefix: '+255',
    code: 'TZ',
    flag: '🇹🇿',
    currency: 'TZS',
    currency_symbol: 'Tsh'
  },
  {
    name: 'Thailand',
    prefix: '+66',
    code: 'TH',
    flag: '🇹🇭',
    currency: 'THB',
    currency_symbol: '฿'
  },
  {
    name: 'Timor-Leste',
    prefix: '+670',
    code: 'TL',
    flag: '🇹🇱',
    currency: 'USD',
    currency_symbol: '$'
  },
  { name: 'Togo', prefix: '+228', code: 'TG', flag: '🇹🇬', currency: 'XAF', currency_symbol: '₣' },
  {
    name: 'Tokelau',
    prefix: '+690',
    code: 'TK',
    flag: '🇹🇰',
    currency: 'NZD',
    currency_symbol: '$'
  },
  { name: 'Tonga', prefix: '+676', code: 'TO', flag: '🇹🇴', currency: 'TOP', currency_symbol: 'T$' },
  {
    name: 'Trinidad and Tobago',
    prefix: '+1868',
    code: 'TT',
    flag: '🇹🇹',
    currency: 'TTD',
    currency_symbol: '$'
  },
  {
    name: 'Tunisia',
    prefix: '+216',
    code: 'TN',
    flag: '🇹🇳',
    currency: 'TND',
    currency_symbol: 'د.ت'
  },
  { name: 'Turkey', prefix: '+90', code: 'TR', flag: '🇹🇷', currency: 'TRY', currency_symbol: '₺' },
  {
    name: 'Turkmenistan',
    prefix: '+993',
    code: 'TM',
    flag: '🇹🇲',
    currency: 'TMT',
    currency_symbol: 'm'
  },
  {
    name: 'Turks and Caicos Islands',
    prefix: '+1649',
    code: 'TC',
    flag: '🇹🇨',
    currency: 'USD',
    currency_symbol: '$'
  },
  { name: 'Tuvalu', prefix: '+688', code: 'TV', flag: '🇹🇻', currency: 'AUD', currency_symbol: '$' },
  {
    name: 'Uganda',
    prefix: '+256',
    code: 'UG',
    flag: '🇺🇬',
    currency: 'UGX',
    currency_symbol: 'Sh'
  },
  {
    name: 'Ukraine',
    prefix: '+380',
    code: 'UA',
    flag: '🇺🇦',
    currency: 'UAH',
    currency_symbol: '₴'
  },
  {
    name: 'United Arab Emirates',
    prefix: '+971',
    code: 'AE',
    flag: '🇦🇪',
    currency: 'AED',
    currency_symbol: 'د.إ'
  },
  {
    name: 'United Kingdom',
    prefix: '+44',
    code: 'GB',
    flag: '🇬🇧',
    currency: 'GDP',
    currency_symbol: '£'
  },
  {
    name: 'United States',
    prefix: '+1',
    code: 'US',
    flag: '🇺🇸',
    currency: 'USD',
    currency_symbol: '$'
  },
  {
    name: 'Uruguay',
    prefix: '+598',
    code: 'UY',
    flag: '🇺🇾',
    currency: 'UYU',
    currency_symbol: '$'
  },
  {
    name: 'Uzbekistan',
    prefix: '+998',
    code: 'UZ',
    flag: '🇺🇿',
    currency: 'UZS',
    currency_symbol: 'сум'
  },
  {
    name: 'Vanuatu',
    prefix: '+678',
    code: 'VU',
    flag: '🇻🇺',
    currency: 'VUV',
    currency_symbol: 'Vt'
  },
  {
    name: 'Bolivarian Republic of Venezuela',
    prefix: '+58',
    code: 'VE',
    flag: '🇻🇪',
    currency: 'VES',
    currency_symbol: 'Bs'
  },
  { name: 'Vietnam', prefix: '+84', code: 'VN', flag: '🇻🇳', currency: 'VND', currency_symbol: '₫' },
  {
    name: 'Virgin Islands, British',
    prefix: '+1284',
    code: 'VG',
    flag: '🇻🇬',
    currency: 'USD',
    currency_symbol: '$'
  },
  {
    name: 'Virgin Islands, U.S.',
    prefix: '+1340',
    code: 'VI',
    flag: '🇻🇮',
    currency: 'USD',
    currency_symbol: '$'
  },
  {
    name: 'Wallis and Futuna',
    prefix: '+681',
    code: 'WF',
    flag: '🇼🇫',
    currency: 'XPF',
    currency_symbol: '₣'
  },
  { name: 'Yemen', prefix: '+967', code: 'YE', flag: '🇾🇪', currency: 'YER', currency_symbol: '﷼' },
  {
    name: 'Zambia',
    prefix: '+260',
    code: 'ZM',
    flag: '🇿🇲',
    currency: 'ZMW',
    currency_symbol: 'ZK'
  },
  {
    name: 'Zimbabwe',
    prefix: '+263',
    code: 'ZW',
    flag: '🇿🇼',
    currency: 'ZWL',
    currency_symbol: '$'
  },
  {
    name: 'Åland Islands',
    prefix: '+358',
    code: 'AX',
    flag: '🇦🇽',
    currency: 'EUR',
    currency_symbol: '€'
  }
];

export default countries;