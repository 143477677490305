import { Box } from '@material-ui/core';

import useStyles from 'layouts/entities/Preview.styles';

export default function ApiPreview({ element }) {
  const classes = useStyles();

  return (
    <Box style={{ width: '100%' }}>
      <Box className={classes.l}>{element.name}</Box>
    </Box>
  );
}
