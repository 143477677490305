import { IconButton, DialogTitle as MuiDialogTitle, Box, makeStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  title: {
    height: 58,
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    fontWeight: 500,
    fontSize: 14,
    background: theme.palette.secondary.main,
    paddingRight: 16
  },
  titleIconButton: {
    position: 'absolute',
    top: 6,
    right: 6
  },
  titleIcon: {
    color: 'white'
  }
}));

export default function DialogTitle({ title, onClose, className }) {
  const c = useStyles();
  return (
    <MuiDialogTitle className={clsx(c.title, className)}>
      <Box
        display="flex"
        width="100%"
      >
        {title}
        {onClose && (
          <IconButton
            onClick={onClose}
            className={c.titleIconButton}
          >
            <Close className={c.titleIcon} />
          </IconButton>
        )}
      </Box>
    </MuiDialogTitle>
  );
}
