import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  positioning: {
    width: '100%',
    height: '100%',
    margin: '2em 0em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
    // backgroundColor: "mistyrose",
  }
}));
