import useKeys from '@flowsn4ke/usekeys';
import { useTranslation } from 'react-i18next';

export default function DataTable({ columns, rows }) {
  const k1 = useKeys();
  const { t } = useTranslation();

  return (
    <>
      <div className="flex flex-col min-h-full">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8 max-h-[calc(773px-63px)] lg:max-h-full">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="shadow ring-1 ring-black ring-opacity-5 max-h-[750px] overflow-auto">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    {columns.map((columnName, columnsIdx) => {
                      return (
                        <th
                          scope="col"
                          className={`
                        px-3 py-3.5 text-left text-sm
                        font-semibold
                        text-gray-900
                        sm:pl-6
                        ${columnsIdx % 2 === 0 ? '' : 'hidden'}
                        sm:table-cell
                        `}
                          key={k1(columnsIdx)}
                        >
                          {t(columnName)}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody className="bg-white">{rows}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
